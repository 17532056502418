/* eslint-disable new-cap */
import { Box, Card, Stack, CardProps, Typography, IconButton } from '@mui/material';
import { IFolder } from '../../interfaces/folder';
import { useTranslation } from 'react-i18next';
import folderImage from '../../assets/svg/ic_folder.svg';
import { useNavigate } from 'react-router-dom';
import { useMemo, useState } from 'react';
import DeleteIcon from '@mui/icons-material/Delete';
import FetcherService from '../../hooks/FetcherService';
import folderService from '../../services/folderService';
import DialogActions from '../../components/DialogActions';
import { AbilityContext } from '../../casl/can';
import { useAbility } from '@casl/react';
import { AbilitySubjects } from '../../casl/ability';

// ----------------------------------------------------------------------

interface Props extends CardProps {
  folder: IFolder;
  handleUpdateList: () => void;
}

export default function FileFolderCard({
  folder,
  onSelect,
  handleUpdateList,
  sx,
  ...other
}: Props) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [isHover, setIsHover] = useState<boolean>(false);
  const [openDeleteFolderModal, setOpenDeleteFolderModal] = useState<boolean>(false);
  const [loadingDelete, setLoadingDelete] = useState<boolean>(false);
  const ability = useAbility(AbilityContext);

  const isAuthorizedToDeleteFolder = ability.can('delete', AbilitySubjects.FOLDER);
  const onMouseEnter = () => setIsHover(true);
  const onMouseLeave = () => setIsHover(false);

  const openDeleteFolder = () => setOpenDeleteFolderModal(true);

  const closeDeleteFolder = () => setOpenDeleteFolderModal(false);

  const onOpenFolder = () => {
    navigate(`/app/folder/${folder._id}`);
  };

  const handleDeleteFolder = async () => {
    await FetcherService({
      service: folderService.deleteOneById(folder?._id as string),
      errorMessage: t('global.attachments.deleteError'),
      setLoading: setLoadingDelete,
    });
    closeDeleteFolder();
    handleUpdateList();
  };

  const isFolderEmpty = useMemo(() => folder.filesCount === 0, [folder]);

  const isDeleteFolderEnabled = useMemo(
    () => isAuthorizedToDeleteFolder && isFolderEmpty && isHover,
    [isFolderEmpty, isAuthorizedToDeleteFolder, isHover]
  );
  return (
    <>
      <DialogActions
        onClose={closeDeleteFolder}
        open={openDeleteFolderModal}
        dialog={`${t('deleteFolderMessage')}`}
        action={handleDeleteFolder}
        loading={loadingDelete}
      />
      <Card
        onClick={onOpenFolder}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        sx={{
          p: 2.5,
          width: 1,
          maxWidth: 222,
          boxShadow: 0,
          borderRadius: 2,
          bgcolor: 'background.default',
          border: (theme) => `solid 1px ${theme.palette.divider}`,
          ...(isHover && {
            cursor: 'pointer',
            borderColor: 'transparent',
            bgcolor: 'background.paper',
            boxShadow: (theme) => theme.customShadows.z20,
          }),
          ...sx,
        }}
        {...other}
      >
        <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'}>
          <Box component="img" src={folderImage} sx={{ width: 40, height: 40 }} />
          {isDeleteFolderEnabled && (
            <IconButton
              onClick={(e) => {
                e.stopPropagation();
                openDeleteFolder();
              }}
            >
              <DeleteIcon width={24} height={24} color="error" />
            </IconButton>
          )}
        </Stack>

        <Typography variant="h6" sx={{ mt: 1, mb: 0.5 }}>
          {folder.name}
        </Typography>

        <Stack
          direction="row"
          alignItems="center"
          spacing={0.75}
          sx={{ typography: 'caption', color: 'text.disabled' }}
        >
          <Box>
            {folder.filesCount} {t('files')}{' '}
          </Box>
        </Stack>
      </Card>
    </>
  );
}
