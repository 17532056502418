import {
  IHospitalDays,
  IPractice,
  IPracticeField,
  IStaff,
  IUser,
  IUserFlat,
  IVisit,
  IVisitFlat,
  ReportData,
} from '../interfaces/Models';
import { saveAs } from 'file-saver';
import { format, addDays } from 'date-fns';
import {
  ADJUSTMENT_REPEATED,
  DATE_RANGE_FILTERS,
  EMPLOYEE_POSITIONS,
  HOSPITAL_DAYS_TYPE,
  PHONE,
  reportTypes,
  USER_ROLES,
  VISIT_COLOR,
  VISIT_STATUS,
} from '../common/Constants';
import { theme } from '../theme';
import { setRecoil, getRecoil } from 'recoil-nexus';
import { tableAtom } from '../atoms/tableAtom';
import { userAtom } from '../atoms/userAtom';
import { DateRange } from '@mui/lab';
import { NavigateFunction } from 'react-router-dom';
import { getReportRoute } from '../common/ReportsCommon';
import { toast } from 'react-toastify';
import { t } from 'i18next';
import SLSCache from 'sls-cache';
import { IVisitWithProgramTitle } from '../layouts/reports/ReportLayout';
import { IAdditionalData } from '../interfaces/ApiResponseType';
import { practiceSubCategoryRequiredFields } from '../pages/reports/sharedFields/PracticeFields';
import { TFunction } from 'react-i18next';
import { getMonthRange } from './dateFilter';
import { random } from 'lodash';
import { checkVisitType } from '../hooks/isReportMode';

export const handleTableAtomChange = (key: string, value: string | number | undefined) => {
  const newKeyValue: any = {};
  const oldAtom = getRecoil(tableAtom);
  newKeyValue[key] = value;
  setRecoil(tableAtom, { ...oldAtom, ...newKeyValue });
};

export const isValidIdNumber = (id: string): boolean => {
  if (id.length > 9 || isNaN(parseInt(id))) return false;

  return (
    Array.from(id, Number).reduce((counter, digit, i) => {
      const step = digit * ((i % 2) + 1);
      return counter + (step > 9 ? step - 9 : step);
    }) %
      10 ===
    0
  );
};

export const getEmployeeName = (value: string, employees: IUserFlat[]) => {
  const employee: IUserFlat | undefined = employees.find(
    (employee: IUserFlat) => employee._id == value
  );
  return employee?.fullName || '';
};

export const getEmployeesIdsByPosition = (staff: IStaff[], value: string) => {
  const employee = staff.filter((employee: IStaff) => employee.position == value);
  return employee.length > 0 ? employee[0].employee : undefined;
};

export const getSortDirection = (sort: string, id: string) => {
  if (sort[0] === '-' && sort.split('-')[1] == id) {
    return 'desc';
  }
  return 'asc';
};

export const sumValues = (obj: ReportData) => {
  const values = obj ? Object.values(obj) : [];

  return values.reduce((accumulator: number, value: any) => {
    const fieldValue = !!value ? Number(value) : 0;
    return accumulator + fieldValue;
  }, 0);
};

export const getUserAvatar = (firstName: string, lastName: string) => {
  return `https://avatar.oxro.io/avatar.svg?name=${firstName}+${lastName}`;
};

export const prevIsValidPractice = (
  practices: IPractice[],
  onChangePractices: any,
  t: Function
) => {
  const numberOfPractices = practices.length;
  if (numberOfPractices === 0) {
    return true;
  }

  const fieldsEmpty = practices[numberOfPractices - 1].fields.filter(
    (field: IPracticeField) =>
      field.value.length == 0 &&
      practiceSubCategoryRequiredFields.indexOf(field.name) > -1 &&
      !field.conditionName
  );

  if (
    fieldsEmpty.length > 0 ||
    practices[numberOfPractices - 1].category.length == 0 ||
    practices[numberOfPractices - 1].subCategory.length == 0
  ) {
    onChangePractices((prev: IPractice[]) => {
      return prev.map((item: IPractice, i: number) => {
        return {
          ...item,
          categoryErrors: item.category.length > 0 ? '' : t('clinic.form.required'),
          subCategoryErrors: item.subCategory.length > 0 ? '' : t('clinic.form.required'),
          fields: item.fields.map((field: IPracticeField) => {
            if (practiceSubCategoryRequiredFields.indexOf(field.name) == -1) {
              return field;
            }
            return { ...field, errors: field.value.length > 0 ? '' : t('clinic.form.required') };
          }),
        };
      });
    });
    return false;
  }

  return true;
};

export const onDownloadPdf = (res: any, name: string) => {
  const reportName = res.headers.filename == 'report.pdf' ? name : res.headers.filename;
  const pdf = new Blob([res.data], { type: 'application/pdf' });
  saveAs(pdf, decodeURI(reportName));
};

export const getLeumitReportFileName = (billingDate: Date) => {
  const month = (billingDate.getMonth() + 1).toString().padStart(2, '0');
  const year = String(billingDate.getFullYear());
  return `KMAHMM${month}${year[3]}`;
};

export const onDownloadTextFile = (res: any, fileName: string) => {
  const reportName = `${fileName}.txt`;
  const pdf = new Blob([res.data], { type: 'text/plain;charset=utf-8' });
  saveAs(pdf, reportName);
};

export const generateTextFile = (rows: string[], fileName: string) => {
  const blob = new Blob([rows.join('\n')], { type: 'text/plain' });
  saveAs(blob, `${fileName}.txt`);
};

export const saveExcelFile = (data: BlobPart, fileName: string) => {
  const blob = new Blob([data], {
    type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  });
  saveAs(blob, `${fileName}.xlsx`);
};

interface ICurrentMonth {
  startCurrentMonth: Date;
  endCurrentMonthDate: Date;
}

export const getCurrentMonth = (): ICurrentMonth => {
  const currentDate = new Date();
  const startCurrentMonthDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
  const endCurrentMonthDate = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0);
  return {
    startCurrentMonth: startCurrentMonthDate,
    endCurrentMonthDate: endCurrentMonthDate,
  };
};

export const getAdjustmentReportType = (visit: IVisit) => {
  const visitType = visit.visitType?.slice(4, visit.visitType.length);
  const isAdjustmentRepeated = visitType == ADJUSTMENT_REPEATED;
  if (isAdjustmentRepeated && typeof visit.typeIndex === 'number') {
    const isSecondAdjustment = visit.typeIndex == 2;
    return `ADJUSTMENT_${isSecondAdjustment ? 'SECOND' : 'THIRD'}`;
  }
};

export const getVisitType = (visit: IVisit) => {
  const visitType = visit.visitType?.slice(4, visit.visitType.length);
  const isPhoneVisit = checkIfVisitIsPhoneReport(visit);
  const isAdjustmentRepeated = visitType == ADJUSTMENT_REPEATED;

  if (isPhoneVisit) {
    return PHONE;
  }

  if (isAdjustmentRepeated && typeof visit.typeIndex === 'number') {
    return getAdjustmentReportType(visit);
  }
  if (
    visitType == 'GENERAL' ||
    visitType == 'TABLE_FIRST' ||
    visitType == 'TABLE_RELEASE' ||
    visitType == 'ADJUSTMENT_FIRST'
  ) {
    return visitType;
  }
  return visit.isFirst ? 'FIRST' : visit.isRelease ? 'RELEASE' : 'REPEATED';
};

export const getDateFormatted = (date: Date | null, dateFormat?: string) => {
  return format(new Date(date || '1/2/2022'), dateFormat || 'dd-MM-yyyy');
};

export const getVisitColor = (visit: IVisit) => {
  if (visit.status === VISIT_STATUS.DONE) {
    return theme.palette.text.secondary;
  }

  if (visit.status === VISIT_STATUS.SENT_TO_CLINIC) {
    return theme.palette.primary.main;
  }
  if (visit.status === VISIT_STATUS.CANCELED) {
    return theme.palette.error.light;
  }

  return VISIT_COLOR[visit.visitType.slice(0, 3)];
};

export const getThisMonthDate = (addDay = false): DateRange<Date> => {
  const today = new Date();
  const startDay = new Date(today.getFullYear(), today.getMonth(), 1);
  const lastDay = new Date(today.getFullYear(), today.getMonth() + 1, 0);
  if (addDay) {
    return [startDay, addDays(lastDay, 1)];
  }
  return [startDay, lastDay];
};

export const getDateRangeFilter = (filterType: string): DateRange<Date> => {
  const today = new Date();
  let firstDay;
  let lastDay;
  let dateRange: DateRange<Date>;

  switch (filterType) {
    case DATE_RANGE_FILTERS.CURRENT_MONTH:
      dateRange = getThisMonthDate();
      break;
    case DATE_RANGE_FILTERS.NEXT_MONTH:
      firstDay = new Date(today.getFullYear(), today.getMonth() + 1, 1);
      lastDay = new Date(today.getFullYear(), today.getMonth() + 2, 0);
      dateRange = [firstDay, lastDay];
      break;
    case DATE_RANGE_FILTERS.PREVIOUS_MONTH:
      firstDay = new Date(today.getFullYear(), today.getMonth() - 1, 1);
      lastDay = new Date(today.getFullYear(), today.getMonth(), 0);
      dateRange = [firstDay, lastDay];
      break;
    default:
      dateRange = getThisMonthDate();
      break;
  }
  return [dateRange[0], addDays(dateRange[1] || new Date(), 1)];
};

export const checkEmployeeReportAuthorization = (visit: IVisit | IVisitFlat) => {
  const user: IUser | null = getRecoil(userAtom);

  return (
    user?.role == USER_ROLES.ADMIN ||
    user?.role == USER_ROLES.SUPER_ADMIN ||
    (typeof visit.employee == 'string' && visit.employee == user?._id) ||
    (typeof visit.employee == 'object' && visit.employee?._id == user?._id) ||
    (visit?.employeeId && visit?.employeeId == user?._id)
  );
};

export const onVisitClick = (navigate: NavigateFunction, visit: IVisit, editMode = false) => {
  handleTableAtomChange('visitId', visit?._id);
  const BASE_URL = `/app/report/visit/${visit._id}`;
  const url = getReportRoute(visit);
  const route = `${BASE_URL}/${url}`;
  const isVisitDone =
    visit.status == VISIT_STATUS.DONE || visit.status == VISIT_STATUS.SENT_TO_CLINIC;
  const isVisitPending = visit.status == VISIT_STATUS.PENDING;
  const isVisitCanceled = visit.status == VISIT_STATUS.CANCELED;

  const isAuthorized = checkEmployeeReportAuthorization(visit);
  if (isVisitCanceled) {
    toast.error(t('global.errors.visitCanceled'));
  } else if (!isAuthorized && isVisitPending) {
    toast.error(t('global.errors.addReportAuthorization'));
  } else if (editMode) {
    navigate(`${route}/edit`);
  } else if (isVisitDone) {
    navigate(`${route}/view`);
  } else {
    navigate(route);
  }
};

export const getReportMode = () => {
  const path = location.pathname;
  if (path.indexOf('edit') > -1) {
    return 'edit';
  } else if (path.indexOf('view') > -1) {
    return 'view';
  } else {
    return 'add';
  }
};

export const scrollUp = () => {
  window.scrollTo({
    top: 0,
    behavior: 'smooth',
  });
};

export const getPageNumberFromUrlParam = (page?: string) => {
  return page && Number(page) - 1 > -1 ? Number(page) - 1 : 0;
};

export const getStartEndDateDep = (rangeDetails: ReportData, last = false) => {
  const { range, from, to, dateProp, month, year } = rangeDetails;
  const [firstDayOfMonth, lastDayOfMonth] = getThisMonthDate(true);

  if (dateProp) {
    return dateProp;
  }

  if (month && year) {
    const [firstDay, lastDay] = getMonthRange(Number(month), Number(year));
    if (last) {
      return format(addDays(new Date(lastDay || ''), 1), 'MM-dd-yyyy');
    }
    return format(new Date(firstDay || new Date()), 'MM-dd-yyyy');
  }

  if (range) {
    return format(getDateRangeFilter(range)[last ? 1 : 0] || new Date(), 'MM-dd-yyyy');
  }

  if (from && to) {
    if (last) {
      return format(addDays(new Date(to), 1), 'MM-dd-yyyy');
    }
    return format(new Date(from), 'MM-dd-yyyy');
  }

  return format((last ? lastDayOfMonth : firstDayOfMonth) || new Date(), 'MM-dd-yyyy');
};

export const filterGeneralReleaseVisits = (visits: IVisitFlat[]) => {
  return visits.filter((visit: IVisitFlat) => {
    const isPhoneReport = visit?.reportType === 'phone';
    return (
      !visit.isRelease &&
      visit.visitType != reportTypes.HMB_GENERAL &&
      visit.visitType != reportTypes.HMP_GENERAL &&
      visit.visitType != reportTypes.HMP_TABLE_FIRST &&
      visit.visitType != reportTypes.HMP_TABLE_RELEASE &&
      !isPhoneReport
    );
  });
};

export const filterVisitsForDownloadAllAction = (visits: IVisitFlat[]) => {
  return visits.filter(
    (visit: IVisitFlat) =>
      visit.status !== VISIT_STATUS.PENDING &&
      visit.status !== VISIT_STATUS.CANCELED &&
      visit.visitType !== reportTypes.HMB_GENERAL &&
      visit.visitType !== reportTypes.HMP_GENERAL &&
      visit.visitType !== reportTypes.HMP_TABLE_FIRST &&
      visit.visitType !== reportTypes.HMP_TABLE_RELEASE
  );
};

export const filterGeneralAndTableDoneVisits = (visits: IVisitFlat[]) => {
  return visits.filter(
    (visit: IVisitFlat) =>
      visit.status === VISIT_STATUS.DONE &&
      (visit.visitType === reportTypes.HMB_GENERAL ||
        visit.visitType === reportTypes.HMP_GENERAL ||
        visit.visitType === reportTypes.HMP_TABLE_FIRST ||
        visit.visitType === reportTypes.HMP_TABLE_RELEASE)
  );
};

const getHospitalDaysColor = (period: IHospitalDays) => {
  if (period.type && period.type === HOSPITAL_DAYS_TYPE.NO_PAYMENT) {
    return '#1bc135';
  }
  return '#fa4b4b';
};

const getTitle = (period: IHospitalDays) => {
  if (period.type && period.type === HOSPITAL_DAYS_TYPE.NO_PAYMENT) {
    return 'program.hospitalDaysForm.type.noPayment';
  }
  return 'program.hospitalDaysForm.header';
};

export const getHospitalDaysEvents = (hospitalDays: IHospitalDays[]) =>
  hospitalDays
    .map((period: IHospitalDays) => [
      {
        start: format(new Date(period.start), 'yyyy-MM-dd'),
        end: format(addDays(new Date(period.end), 1), 'yyyy-MM-dd'),
        color: getHospitalDaysColor(period),
        display: 'background',
        id: `background_${period?._id}`,
      },
      {
        start: format(new Date(period.start), 'yyyy-MM-dd'),
        end: format(addDays(new Date(period.end), 1), 'yyyy-MM-dd'),
        color: getHospitalDaysColor(period),
        id: period?._id,
        title: t(getTitle(period)),
      },
    ])
    .flat(1);

export const getVisitEventTitle = (
  t: TFunction<'translation', undefined>,
  visit: IVisit,
  employeeName: string
) => {
  let title = '';
  const isPhoneVisit = checkIfVisitIsPhoneReport(visit);
  if (!isPhoneVisit) {
    const isAdjustmentRepeatedReport = visit.visitType.includes(ADJUSTMENT_REPEATED);
    const visitType = isAdjustmentRepeatedReport ? getAdjustmentReportType(visit) : visit.visitType;
    title = `${t(`global.positions.${visitType}`)}: ${employeeName}`;
  }
  if (isPhoneVisit) {
    const employeePosition = visit.visitType.slice(0, 3);
    title = `${t(`global.positions.${employeePosition}`)} (${t(
      `global.visitTypes.${PHONE}`
    )}): ${employeeName}`;
  }
  return title;
};
export const getProgramVisitsEvents = (
  t: TFunction<'translation', undefined>,
  visits: IVisit[],
  hospitalDays?: IHospitalDays[]
) => {
  const visitsEvents = visits.map((visit: any, idx) => {
    const employeeName = `${visit.employee.personalDetails.firstName} ${visit.employee.personalDetails.lastName}`;
    const title = getVisitEventTitle(t, visit, employeeName);
    const isVisitCanceled = visit.status === VISIT_STATUS.CANCELED;
    const { isFirstReport, isReleaseReport, isGeneralReport } = checkVisitType(visit);
    const isVisitDraggable =
      !isVisitCanceled && !isFirstReport && !isReleaseReport && !isGeneralReport;
    return {
      ...visit,
      title,
      date: format(new Date(visit.dueDate), 'yyyy-MM-dd'),
      color: getVisitColor(visit),
      id: visit._id,
      editable: isVisitDraggable,
    };
  });

  const hospitalDaysEvents = hospitalDays ? getHospitalDaysEvents(hospitalDays) : [];
  return [...visitsEvents, ...hospitalDaysEvents];
};

export const removeCachedData = (key: string) => {
  SLSCache.set(key, {}, 0.005);
};

export const getVisitGeneralReportRoute = (
  visit: IVisitWithProgramTitle,
  additionalData: IAdditionalData,
  visitPosition: string
) => {
  const generalVisitPosition = `${
    visitPosition == EMPLOYEE_POSITIONS.HMP ? EMPLOYEE_POSITIONS.HMP : EMPLOYEE_POSITIONS.HMB
  }_GENERAL`;
  const generalVisitId =
    visitPosition == EMPLOYEE_POSITIONS.HMP
      ? additionalData.HmpGeneralVisitId
      : additionalData.HmbGeneralVisitId;
  const BASE_URL = `/app/report/visit/${generalVisitId}`;
  const url = getReportRoute({ ...visit, visitType: generalVisitPosition });
  return `${BASE_URL}/${url}/view`;
};

export const handleShowHideRepoGeneralReportAction = (visit: IVisit, visitPosition: string) => {
  // here we show the tooltip for general report action if the visit type is hmp or hmb and general

  return visit.visitType != reportTypes.HMB_GENERAL && visit.visitType != reportTypes.HMP_GENERAL;
};

export const checkIfReportSectionEmpty = (section: ReportData | undefined) => {
  if (!section) {
    return false;
  }
  const filledFields = [];
  const sectionKeys = Object.keys(section);
  sectionKeys.forEach((key: string) => {
    if (section[key].length > 0) {
      filledFields.push(key);
    }
  });
  return filledFields.length > 0;
};

export async function executeInBatches(promises: Promise<void>[], batchSize: number) {
  let position = 0;

  while (position < promises.length) {
    const batch = promises.slice(position, position + batchSize);
    await Promise.all(batch);
    position += batchSize;
  }
}

export const checkIfUserIsAdmin = (user?: IUser | IUserFlat | null) =>
  !!user && (user.role === USER_ROLES.ADMIN || user.role === USER_ROLES.SUPER_ADMIN);

export const checkIfVisitIsPhoneReport = (visit?: IVisit) => visit?.visitType?.includes(PHONE);
