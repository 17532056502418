/* eslint-disable new-cap */
import { LoadingButton } from '@mui/lab';
import { Stack } from '@mui/material';
import { Field, Form, Formik } from 'formik';
import React from 'react';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import { StyledTextField } from '../../styles/FormFieldsStyles';
import { IRequestRestPassword, IRequestRestPasswordResponse } from '../../interfaces/Models';
import FetcherService from '../../hooks/FetcherService';
import UserService from '../../services/UserService';
import { toast } from 'react-toastify';

interface IResetPasswordForm {
  onSent: (value: boolean) => void;
  onGetEmail: (value: string) => void;
}
const ForgotPasswordForm = ({ onSent, onGetEmail }: IResetPasswordForm) => {
  const { t } = useTranslation();

  const resetPasswordSchema = Yup.object({
    email: Yup.string().email(t('user.form.emailInvaild')).required(t('clinic.form.required')),
  });

  const handelResetPassword = async (
    values: IRequestRestPassword,
    setSubmitting: (value: boolean) => void,
    resetForm: (value: Object) => void
  ) => {
    try {
      await FetcherService<IRequestRestPasswordResponse>({
        service: UserService.requestRestPassword(values),
      });
      onSent(true);
      onGetEmail(values.email);
    } catch {
      setSubmitting(false);
      resetForm({ values: { email: '' } });
      toast.error(t('global.errors.wrongEmail'));
    }
  };

  return (
    <>
      <Formik
        initialValues={{ email: '' }}
        onSubmit={(values: any, { setSubmitting, resetForm }) => {
          handelResetPassword(values, setSubmitting, resetForm);
        }}
        validationSchema={resetPasswordSchema}
      >
        {({ isSubmitting }) => {
          return (
            <Form>
              <Stack spacing={3}>
                <Field
                  fullWidth
                  component={StyledTextField}
                  type="email"
                  label={t('user.form.email')}
                  name="email"
                />
                <LoadingButton
                  fullWidth
                  size="large"
                  type="submit"
                  variant="contained"
                  loading={isSubmitting}
                >
                  {t('global.resetPassword.title')}
                </LoadingButton>
              </Stack>
            </Form>
          );
        }}
      </Formik>
    </>
  );
};

export default ForgotPasswordForm;
