import { Button, Stack } from '@mui/material';
import React from 'react';
import FormModal from './Modals/FormModal';
import { useTranslation } from 'react-i18next';
import { theme } from '../theme';
import { styled } from '@mui/system';
import { LoadingButton } from '@mui/lab';

const StyledStack = styled(Stack, {
  name: 'StyledStack',
  slot: 'Wrapper',
})({
  marginTop: theme.spacing(3),
  marginBottom: theme.spacing(2),
  paddingLeft: theme.spacing(4),
  paddingRight: theme.spacing(4),
});

interface IActionsLabels {
  submit: string;
  cancel: string;
}

interface DialogActionsProps {
  onClose: () => void;
  open: boolean;
  action: Function;
  dialog: string;
  actionsLabels?: IActionsLabels;
  cancelIconAction?: () => void;
  loading?: boolean;
}

const DialogActions = ({
  onClose,
  open,
  action,
  dialog,
  actionsLabels,
  cancelIconAction,
  loading = false,
}: DialogActionsProps) => {
  const { t } = useTranslation();

  const actionHandler = () => {
    action();
  };
  return (
    <FormModal open={open} onClose={onClose} text={dialog} cancelIconAction={cancelIconAction}>
      <StyledStack direction="row" spacing={{ xs: 1 }} justifyContent="space-between">
        <LoadingButton
          loading={loading}
          variant="contained"
          size="large"
          color="primary"
          onClick={actionHandler}
        >
          {actionsLabels?.submit || t('global.form.select.yes')}
        </LoadingButton>
        <Button variant="contained" size="large" color="error" onClick={onClose}>
          {actionsLabels?.cancel || t('global.form.select.no')}
        </Button>
      </StyledStack>
    </FormModal>
  );
};

export default DialogActions;
