import React from 'react';
import { styled } from '@mui/system';
import DateRangeFilter from '../../components/DateRangeFilter';
import { theme } from '../../theme';
import { useTranslation } from 'react-i18next';
import { IEmployeeFinancialReport, IEmployeesFinancialReports } from '../../interfaces/Reports';
import { toast } from 'react-toastify';
import { downloadExcelReport, getSheetData, ISheetColumn } from '../../utils/ExcelReportUtils';
import DownloadButtonWithTooltip from './DownloadButtonWithTooltip';

const RootStyle = styled('div')({
  display: 'flex',
  justifyContent: 'flex-end',
  gap: theme.spacing(1),
});

export const FilterContainer = styled('div')({
  minWidth: '15%',
  direction: 'rtl',
});

interface IFinancialReportsHeader {
  generalReport?: IEmployeesFinancialReports;
  employeesReports?: IEmployeeFinancialReport[];
  columns: ISheetColumn[];
}
const FinancialReportsHeader = ({
  generalReport,
  employeesReports,
  columns,
}: IFinancialReportsHeader) => {
  const { t } = useTranslation();
  const onDownloadReport = () => {
    if (generalReport) {
      const totalValues = [
        t('financialReports.totalCost'),
        '-',
        generalReport.totalPendingVisits,
        generalReport.totalPendingVisitsCost,
        generalReport.totalDoneVisits,
        generalReport.totalDoneVisitsCost,
        generalReport.totalVisits,
        generalReport.totalCost,
      ];

      const employeesReportsData = getSheetData(employeesReports || [], columns, totalValues);

      downloadExcelReport(t('global.excelReports.employeesReports'), [
        { sheetName: t('global.excelReports.employeesReports'), sheetData: employeesReportsData },
      ]);
    } else {
      toast.error(t('global.errors.report'));
    }
  };
  return (
    <RootStyle>
      <FilterContainer>
        <DownloadButtonWithTooltip onDownloadReport={onDownloadReport} />
      </FilterContainer>
      <FilterContainer>
        <DateRangeFilter />
      </FilterContainer>
    </RootStyle>
  );
};

export default FinancialReportsHeader;
