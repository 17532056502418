/* eslint-disable new-cap */
import React, { useState } from 'react';
import ErrorMessage from '../../components/ErrorMessage';
import useService from '../../hooks/HookService';
import { IPatientProgram } from '../../interfaces/Models';
import { useParams } from 'react-router-dom';
import ProgramForm from './ProgramForm';
import { Container } from '@mui/material';
import Page from '../../components/Page';
import { useTranslation } from 'react-i18next';
import PatientProgramService from '../../services/PatientProgramService';
import HeaderBreadcrumbs from '../../components/HeaderBreadcrumbs';
import DialogActions from '../../components/DialogActions';
import FetcherService from '../../hooks/FetcherService';
import OverlaySpinner from '../../components/OverlaySpinner';
import { PATIENT_PROGRAM_STATUS } from '../../common/Constants';
import CancelIcon from '@mui/icons-material/Cancel';
import { AbilitySubjects } from '../../casl/ability';
import useAccess from '../../hooks/useAccess';
import { useAbility } from '@casl/react';
import { AbilityContext } from '../../casl/can';

const EditProgram = () => {
  const { t } = useTranslation();
  const { programId } = useParams();
  const [openCancelProgramModal, setOpeCancelProgramModal] = useState<boolean>(false);
  const [isLoadingReport, setIsLoadingReport] = useState<boolean>(false);
  const [shouldServiceUpdate, setShouldServiceUpdate] = useState<number>(0);
  useAccess('update', AbilitySubjects.ADMIN_ABILITY);
  const ability = useAbility(AbilityContext);

  const { isLoading, data, error, errorMessage } = useService<IPatientProgram>({
    service: PatientProgramService.getOne(programId || 'empty'),
    deps: [shouldServiceUpdate],
  });

  const onCancelProgram = async () => {
    await FetcherService<any>({
      service: PatientProgramService.cancelProgramById(data?._id || 'empty'),
      setLoading: setIsLoadingReport,
    });
    setShouldServiceUpdate((pre) => pre + 1);
    setOpeCancelProgramModal(false);
  };

  const handleModalOpen = () => {
    setOpeCancelProgramModal(true);
  };

  const handleModalClose = () => {
    setOpeCancelProgramModal(false);
  };

  const cancelProgramAbility = ability.can('manage', AbilitySubjects.ADMIN_ABILITY);

  if (isLoading) {
    return <OverlaySpinner />;
  }
  if (error || !data) {
    return <ErrorMessage error={errorMessage} />;
  }
  return (
    <Page title={t('patient.mainView.editProgram')}>
      <Container>
        <HeaderBreadcrumbs
          heading={`${t('patient.mainView.editProgram')} ${data.title}`}
          actions={[
            {
              name: t('global.moreMenu.cancelProgram'),
              icon: <CancelIcon />,
              color: 'error',
              type: 'Action',
              onClick: handleModalOpen,
              isVisible: data.status != PATIENT_PROGRAM_STATUS.CANCELED && cancelProgramAbility,
            },
          ]}
        />
        <ProgramForm
          program={data}
          patient={typeof data.patient === 'string' ? undefined : data.patient}
        />
        <DialogActions
          onClose={handleModalClose}
          open={openCancelProgramModal}
          dialog={`${t('global.reportActions.cancelProgram')} ${data?.title} ?`}
          action={onCancelProgram}
        />
        {isLoadingReport && <OverlaySpinner />}
      </Container>
    </Page>
  );
};

export default EditProgram;
