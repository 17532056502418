import { t } from 'i18next';
import { IStaff, ReportData } from '../interfaces/Models';
import { getEmployeesIdsByPosition } from '../utils/Helper';
import { EMPLOYEE_POSITIONS } from './Constants';

export const ADJUSTMENT_REPORT = 'ADJUSTMENT';

export const allReportPositions = [...Object.values(EMPLOYEE_POSITIONS), ADJUSTMENT_REPORT];

const MEUHEDET_PROGRAM_NAMES_CODES: ReportData = {
  הוספיס: '026',
  'הוספיס - חצי חבילה': '026',
  'איזון סמפטומים': '026',
  'שיקום אורתופדי': '040',
  'שיקום קימום': '040',
  'שיקום נוירולוגי': '040',
  'חולים מורכבים': '040',
  'פנימית בית': '026',
  'פיזיותרפיה בבית': '011',
  'ביקור אח': '040',
  'ביקור רופא': '074',
  'ביקור פרא רפואי': '040',
  'תקשורת טפול בדיבור בבית החולה': '040',
  'ביקור דיאטנית באשפוז בית': '040',
  'ביקור עובד סוציאלי באשפוז בית': '040',
  'ריפוי בעיסוק טיפולי בית': '040',
  'ריפוי בעיסוק בבית החולה לחולים נוירולוגים': '040',
  'התאמת מגורים לצרכי נכה': '040',
  'התאמת מכשירי מיידות בבית החולה': '040',
  'שיקום ביתי לחולים מרותקים-חודשי': '040',
};

const MEUHEDET_PROGRAMS_CODES_ENG = {
  HOSPITALIZATION: '026',
  HALF_PACKAGE_HOSPITALIZATION: '026',
  SYMPTOM_BALANCE: '026',
  ORTHOPEDIC_REHABILITATION: '040',
  CHEMOTHERAPY_REHABILITATION: '040',
  NEUROLOGICAL_REHABILITATION: '040',
  COMPLEX_PATIENTS: '040',
  INTERNAL_MEDICINE_HOME: '026',
  PHYSIOTHERAPY_AT_HOME: '011',
  NURSE_VISIT: '040',
  DOCTOR_VISIT: '074',
  MEDICAL_REHABILITATION_VISIT: '040',
  COMMUNICATION_SPEECH_THERAPY_IN_THE_HOSPITAL: '040',
  DIETITIAN_VISIT_IN_HOSPITAL: '040',
  SOCIAL_WORKER_VISIT_IN_HOSPITAL: '040',
  OCCUPATIONAL_THERAPY_AT_HOME: '040',
  OCCUPATIONAL_THERAPY_AT_HOSPITAL_FOR_NEUROLOGICAL_PATIENTS: '040',
  HOUSING_ADJUSTMENT_FOR_DISABLED: '040',
  ADJUSTMENT_OF_EMERGENCY_DEVICES_AT_HOME: '040',
  HOME_REHABILITATION_FOR_LOCKED_PATIENTS_MONTHLY: '040',
};

export const MEUHEDET_PROGRAM_MAPPING = {
  HOSPITALIZATION: 'הוספיס',
  HALF_PACKAGE_HOSPITALIZATION: 'הוספיס - חצי חבילה',
  SYMPTOM_BALANCE: 'איזון סמפטומים',
  ORTHOPEDIC_REHABILITATION: 'שיקום אורתופדי',
  CHEMOTHERAPY_REHABILITATION: 'שיקום קימום',
  NEUROLOGICAL_REHABILITATION: 'שיקום נוירולוגי',
  COMPLEX_PATIENTS: 'חולים מורכבים',
  INTERNAL_MEDICINE_HOME: 'פנימית בית',
  NURSE_VISIT: 'ביקור אח',
  DOCTOR_VISIT: 'ביקור רופא',
  MEDICAL_REHABILITATION_VISIT: 'ביקור פרא רפואי',
  COMMUNICATION_SPEECH_THERAPY_IN_THE_HOSPITAL: 'תקשורת טפול בדיבור בבית החולה',
  DIETITIAN_VISIT_IN_HOSPITAL: 'ביקור דיאטנית באשפוז בית',
  SOCIAL_WORKER_VISIT_IN_HOSPITAL: 'ביקור עובד סוציאלי באשפוז בית',
  OCCUPATIONAL_THERAPY_AT_HOME: 'ריפוי בעיסוק טיפולי בית',
  OCCUPATIONAL_THERAPY_AT_HOSPITAL_FOR_NEUROLOGICAL_PATIENTS:
    'ריפוי בעיסוק בבית החולה לחולים נוירולוגים',
  PHYSIOTHERAPY_AT_HOME: 'פיזיותרפיה בבית',
  HOUSING_ADJUSTMENT_FOR_DISABLED: 'התאמת מגורים לצרכי נכה',
  ADJUSTMENT_OF_EMERGENCY_DEVICES_AT_HOME: 'התאמת מכשירי מיידות בבית החולה',
  HOME_REHABILITATION_FOR_LOCKED_PATIENTS_MONTHLY: 'שיקום ביתי לחולים מרותקים-חודשי',
};

export const MEUHEDET_PROGRAM_NAMES = Object.values(MEUHEDET_PROGRAM_MAPPING).sort((a, b) =>
  a.localeCompare(b)
);

export const LEUMIT_PROGRAM_NAMES_MAPPING = {
  HOSPITALIZATION: 'הוספיס',
  ACUTE_HOSPITALIZATION: 'אשפוז אקוטי',
  ORTHOPEDIC_REHABILITATION: 'שיקום אורתופדי',
  ORTHOPEDIC_REHABILITATION_LIGHT: 'שיקום אורתופדי  קל',
  CHEMOTHERAPY_REHABILITATION: 'שיקום קימום',
  NEUROLOGICAL_REHABILITATION: 'שיקום נוירולוגי',
  NURSE_VISIT: 'ביקור אח',
  DOCTOR_VISIT: 'ביקור רופא',
  MEDICAL_REHABILITATION_VISIT: 'ביקור פרא רפואי',
  HOUSING_ADJUSTMENT: 'התאמת דיור',
  UNSTABLE_COMPLEX_HOME_HOSPITALIZATION: 'אשפוז בית חולה מורכב לא יציב',
  STABLE_COMPLEX_HOME_HOSPITALIZATION: 'אשפוז בית חולה מורכב יציב',
  NURSE_WEEKEND_HOME_TREATMENT_VISIT: 'ביקור אח - טיפול בית טיפול אחות סופשבוע',
  HOME_PATIENT_TRANSFER_ASSEMBLY_NURSE_VISIT: 'ביקור אח - הרכבתת עירוי למטופל בבית',
  HOME_PATIENT_BANDAGING_VISIT: 'ביקור אח - ביצוע חבישות שונות למטופל בבית',
  HOME_PATIENT_VARIOUS_INJECTIONS_VISIT: 'ביקור אח - הזרקות שונות למטופל בבית',
  HOME_PATIENT_BLOOD_TESTS_VISIT: 'ביקור אח - דיגום בדיקות דם למטופל בבית',
  PHYSIOTHERAPY_AT_THE_PATIENTS_HOME: 'פיזיותרפיה בבית המטופל',
  SPEECH_THERAPY_AT_HOME: 'טיפול בדיבור בבית',
  PHYSIOTHERAPY_REHABILITATION_AT_HOME: 'שיקום פיזיותרפיה בבית',
  OCCUPATIONAL_THERAPY_AT_HOME: 'ריפוי בעיסוק טיפול בבית',
};

export const LEUMIT_PROGRAM_NAMES = Object.values(LEUMIT_PROGRAM_NAMES_MAPPING).sort((a, b) =>
  a.localeCompare(b)
);

export const getMeuhedetProgramCode = (name: string) => {
  return MEUHEDET_PROGRAM_NAMES_CODES[name] || '000';
};

export const getProgramStaff = (staff: IStaff[]) => {
  const staffMembers: ReportData = {};
  allReportPositions.forEach((position: string) => {
    staffMembers[position] = getEmployeesIdsByPosition(staff, position);
  });
  return staffMembers;
};

export const getErrorValue = (value: string | Date | null) => {
  if (value) {
    return value.toString().length > 0 ? '' : t('clinic.form.required');
  }

  return t('clinic.form.required');
};
