import React from 'react';
import { Dialog, DialogTitle, DialogContent, Box, Breakpoint } from '@mui/material';
import CancelIcon from '@mui/icons-material/Cancel';
import { styled } from '@mui/system';
import { theme } from '../theme';

const StyledBox = styled(Box, {
  name: 'StyledBox',
  slot: 'Wrapper',
})({
  alignItems: 'center',
  marginTop: theme.spacing(1),
  marginLeft: theme.spacing(1),
  // borderBottom: '1px solid black',
});

export const StyledTitle = styled(DialogTitle)({
  textAlign: 'center',
  paddingTop: 0,
});

interface ViewModalProps {
  title?: string;
  open: boolean;
  onClose: Function;
  maxWidth?: Breakpoint;
}

const ViewModal: React.FC<ViewModalProps> = ({
  open,
  onClose,
  maxWidth = 'sm',
  children,
  title,
}) => {
  return (
    <>
      <Dialog
        fullWidth
        open={open}
        maxWidth={maxWidth}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <>
          <StyledBox>
            <CancelIcon
              sx={{ cursor: 'pointer', height: '100%' }}
              color="error"
              onClick={() => onClose()}
            />
          </StyledBox>
          {title && <StyledTitle>{title}</StyledTitle>}
          <DialogContent>{children}</DialogContent>
        </>
      </Dialog>
    </>
  );
};

export default ViewModal;
