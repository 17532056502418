import React from 'react';
import { TextField } from 'formik-mui';
import { FastField } from 'formik';
import { useTranslation } from 'react-i18next';
import { Grid, Typography } from '@mui/material';
import FormikDatePickerField from '../../../../components/Form/FormikDatePickerField';

const BloodTest = () => {
  const { t } = useTranslation();
  const id = 'HMDFirst_MedicalInfo_BloodTest';

  const fields = [
    {
      name: 'GFR',
      label: t('report.HMDFIRST.generalMedicalInfo.bloodTest.GFR'),
    },
    {
      name: 'folicAcid',
      label: t('report.HMDFIRST.generalMedicalInfo.bloodTest.folicAcid'),
    },
    {
      name: 'albumin',
      label: t('report.HMDFIRST.generalMedicalInfo.bloodTest.albumin'),
    },
    {
      name: 'sodium',
      label: t('report.HMDFIRST.generalMedicalInfo.bloodTest.sodium'),
    },
    {
      name: 'totalCholesterol',
      label: t('report.HMDFIRST.generalMedicalInfo.bloodTest.totalCholesterol'),
    },
    {
      name: 'hemoglobin',
      label: t('report.HMDFIRST.generalMedicalInfo.bloodTest.hemoglobin'),
    },
    {
      name: 'potassium',
      label: t('report.HMDFIRST.generalMedicalInfo.bloodTest.potassium'),
    },
    {
      name: 'LDLCholesterol',
      label: t('report.HMDFIRST.generalMedicalInfo.bloodTest.LDLCholesterol'),
    },
    {
      name: 'glucose',
      label: t('report.HMDFIRST.generalMedicalInfo.bloodTest.glucose'),
    },
    {
      name: 'phosphorus',
      label: t('report.HMDFIRST.generalMedicalInfo.bloodTest.phosphorus'),
    },
    {
      name: 'HDLCholesterol',
      label: t('report.HMDFIRST.generalMedicalInfo.bloodTest.HDLCholesterol'),
    },
    {
      name: 'HBA1C',
      label: t('report.HMDFIRST.generalMedicalInfo.bloodTest.HBA1C'),
    },
    {
      name: 'Tsh',
      label: t('report.HMDFIRST.generalMedicalInfo.bloodTest.Tsh'),
    },
    {
      name: 'triglycerides',
      label: t('report.HMDFIRST.generalMedicalInfo.bloodTest.triglycerides'),
    },
    {
      name: 'ferritin',
      label: t('report.HMDFIRST.generalMedicalInfo.bloodTest.ferritin'),
    },
    {
      name: 'uricAcid',
      label: t('report.HMDFIRST.generalMedicalInfo.bloodTest.uricAcid'),
    },
    {
      name: 'NonHDL',
      label: t('report.HMDFIRST.generalMedicalInfo.bloodTest.NonHDL'),
    },
    {
      name: 'iron',
      label: t('report.HMDFIRST.generalMedicalInfo.bloodTest.iron'),
    },
    {
      name: 'Wbc',
      label: t('report.HMDFIRST.generalMedicalInfo.bloodTest.Wbc'),
    },
    {
      name: 'VitaminD',
      label: t('report.HMDFIRST.generalMedicalInfo.bloodTest.VitaminD'),
    },
    {
      name: 'Transferin',
      label: t('report.HMDFIRST.generalMedicalInfo.bloodTest.Transferin'),
    },
    {
      name: 'lymp',
      label: t('report.HMDFIRST.generalMedicalInfo.bloodTest.lymp'),
    },
    {
      name: 'creatinine',
      label: t('report.HMDFIRST.generalMedicalInfo.bloodTest.creatinine'),
    },
    {
      name: 'totalProtein',
      label: t('report.HMDFIRST.generalMedicalInfo.bloodTest.totalProtein'),
    },
    {
      name: 'BUN',
      label: t('report.HMDFIRST.generalMedicalInfo.bloodTest.BUN'),
    },
    {
      name: 'VitaminB12',
      label: t('report.HMDFIRST.generalMedicalInfo.bloodTest.VitaminB12'),
    },
    {
      name: 'other',
      label: t('report.HMDFIRST.generalMedicalInfo.bloodTest.other'),
    },
  ];
  return (
    <div id={id}>
      <Typography variant="subtitle2" gutterBottom align="left">
        {t('report.HMDFIRST.generalMedicalInfo.bloodTest.header')}
      </Typography>
      <Grid container spacing={{ xs: 2, md: 4, lg: 4 }} id={`${id}_Container`}>
        <Grid item xs={6} md={6} lg={6}>
          <FormikDatePickerField
            name="data.generalMedicalInfo.bloodTest.testDate"
            label={t('report.HMDFIRST.generalMedicalInfo.bloodTest.testDate')}
            id={`${id}_bloodTestDate`}
            sx={{ marginBottom: 0 }}
          />
        </Grid>
        <Grid item xs={6} md={6} lg={6}>
          <FastField
            id={`${id}_bloodTestSource`}
            fullWidth
            component={TextField}
            type="text"
            name="data.generalMedicalInfo.bloodTest.source"
            label={t('report.HMDFIRST.generalMedicalInfo.bloodTest.source')}
          />
        </Grid>
        {fields.map((field) => {
          return (
            <Grid item xs={6} md={4} lg={4} key={field.name}>
              <FastField
                id={`${id}_${field.label}`}
                fullWidth
                component={TextField}
                type="text"
                name={`data.generalMedicalInfo.bloodTest.${field.name}`}
                label={field.label}
              />
            </Grid>
          );
        })}
      </Grid>
    </div>
  );
};

export default BloodTest;
