/* eslint-disable new-cap */
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useParams, useOutletContext } from 'react-router-dom';
import * as Yup from 'yup';
import { IReport } from '../../../interfaces/Models';
import { reportTypes } from '../../../common/Constants';
import ErrorMessage from '../../../components/ErrorMessage';
import { HMPFirstValues } from '../../../common/reportsFieldsValues/hmp/HMPFirstValues';
import HMPFirstFields from './fields/HMPFirstFields';
import GenericField from '../sharedFields/GenericField';
import StatusFields from './fields/StatusFields';
import ReportsForm from '../../../components/Form/ReportsForm';
import SectionDivider from '../../../components/SectionDivider';

const HMPFirst = () => {
  const { t } = useTranslation();
  const { visitId } = useParams();
  const data: IReport = useOutletContext();

  const initialValues = data
    ? data
    : {
        name: reportTypes.HMP_FIRST,
        visit: visitId,
        data: HMPFirstValues,
      };
  const ReportSchema = Yup.object({
    name: Yup.string().required(t('clinic.form.required')),
    visit: Yup.string().required(t('clinic.form.required')),
    data: Yup.object().shape({}),
  });

  const HtmlId = 'HMPFirst';

  if (data && data.name != reportTypes.HMP_FIRST) {
    return <ErrorMessage error={t('global.errors.somethingWrong')} />;
  }
  return (
    <ReportsForm
      initialValues={initialValues}
      visitId={visitId || ''}
      validationSchema={ReportSchema}
    >
      <GenericField
        id={`${HtmlId}_goals`}
        fields={[
          {
            name: 'data.goals',
            label: 'report.HMPGENERAL.goals',
            type: 'text',
            rows: 4,
            smallSize: 12,
            largeSize: 12,
          },
        ]}
      />
      <HMPFirstFields id={HtmlId} />
      <GenericField
        id={`${HtmlId}_Status`}
        fields={[
          {
            name: 'data.physicalCondition.status',
            label: 'report.HMPFIRST.physicalCondition.status',
            type: 'text',
            rows: 3,
            smallSize: 12,
            largeSize: 12,
          },
        ]}
      />
      <StatusFields id={HtmlId} />
      <SectionDivider />
    </ReportsForm>
  );
};

export default HMPFirst;
