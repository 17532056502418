import { format } from 'date-fns';
import MoreOptions from '../../components/MoreOptions';
import { IPatientFlat } from '../../interfaces/Models';
import { getSortDirection } from '../../utils/Helper';
import editFill from '@iconify/icons-eva/edit-fill';
import { Icon } from '@iconify/react';
import DeleteIcon from '@mui/icons-material/Delete';
import { t } from 'i18next';
import { AbilitySubjects } from '../../casl/ability';
import { useAbility } from '@casl/react';
import { AbilityContext } from '../../casl/can';
import { theme } from '../../theme';
import ClinicNameLabel from '../../components/ClinicNameLabel';

export const getFullNameColumn = (sort: string) => {
  return {
    id: 'fullName',
    Header: `${t('user.form.fullName')}`,
    accessor: 'fullName',
    sortType: 'basic',
    sortDirection: getSortDirection(sort, 'fullName'),
  };
};
export const getPatientColumns = (
  sort: string,
  handelEditReport: (user: IPatientFlat) => void,
  onOpenDeletePatientModal: (user: IPatientFlat) => void
) => {
  const ability = useAbility(AbilityContext);

  const editPatientAbility = ability.can('update', AbilitySubjects.ADMIN_ABILITY);
  const deletePatientAbility = ability.can('delete', AbilitySubjects.ADMIN_ABILITY);
  return [
    getFullNameColumn(sort),
    {
      Header: t('patient.form.passportIdNumber'),
      accessor: 'idNumber',
    },
    {
      Header: `${t('personalDetails.primaryPhone')}`,
      accessor: 'primaryPhone',
    },
    {
      Header: `${t('patient.form.clinic')}`,
      accessor: (patient: any) => <ClinicNameLabel clinicName={patient.clinicName} />,
    },
    {
      Header: `${t('patient.form.programTitle')}`,
      accessor: 'programTitle',
    },
    {
      id: 'createdAt',
      Header: `${t('patient.mainView.joinDate')}`,
      accessor: (User: any) => format(new Date(User.createdAt ? User.createdAt : ''), 'dd-MM-yyyy'),
      sortType: 'basic',
      sortDirection: getSortDirection(sort, 'createdAt'),
    },
    {
      Header: t('clinic.table.actions'),
      accessor: (Patient: any) => {
        return (
          <>
            {editPatientAbility && deletePatientAbility ? (
              <MoreOptions
                actions={[
                  {
                    name: t('global.moreMenu.edit'),
                    handler: handelEditReport,
                    icon: (
                      <Icon
                        icon={editFill}
                        width={24}
                        height={24}
                        color={theme.palette.secondary.main}
                      />
                    ),
                  },
                  {
                    name: t('global.moreMenu.delete'),
                    handler: onOpenDeletePatientModal,
                    icon: <DeleteIcon width={24} height={24} color="error" />,
                    variant: theme.palette.error.main,
                  },
                ]}
                record={Patient}
              />
            ) : null}
          </>
        );
      },
    },
  ];
};
