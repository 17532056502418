import { t } from 'i18next';
import { ReportData } from '../interfaces/Models';
import { IField, IOption } from './HMA';

export const socialInteractionsFields: string[] = [
  'functionalSpeech',
  'informativeSpeech',
  'nonVerbalCommunication',
  'effect',
  'collaboration',
];

export const orientationFunctionsFields: string[] = [
  'orientationInTime',
  'orientationInPlace',
  'orientationInPerson',
  'conditionAwareness',
  'judgment',
];

export const visualPerceptionFunctionsFields: string[] = [
  'objectIdentification',
  'Relationships',
  'figure',
];

export const properOptions = ['full', 'partial', 'short'];

export const validNotValidOptions = ['valid', 'notValid'];

export const memoryFunctionsFields: string[] = ['immediate', 'short', 'long'];

export const getSeatingfields = () => {
  return [
    {
      name: 'data.feelingOfPain.seatingFields.headControl',
      label: t('report.HMMFIRST.feelingOfPain.seatingFields.headControl'),
    },
    {
      name: 'data.feelingOfPain.seatingFields.gw',
      label: t('report.HMMFIRST.feelingOfPain.seatingFields.gw'),
    },
    {
      name: 'data.feelingOfPain.seatingFields.upperLimb',
      label: t('report.HMMFIRST.feelingOfPain.seatingFields.upperLimb'),
    },
  ];
};

export const getOccupationAndActivityFields = () => {
  return [
    {
      name: 'data.feelingOfPain.occupationAndActivity.phone',
      label: t('report.HMMFIRST.feelingOfPain.occupationAndActivity.fields.phone'),
    },
    {
      name: 'data.feelingOfPain.occupationAndActivity.computer',
      label: t('report.HMMFIRST.feelingOfPain.occupationAndActivity.fields.computer'),
    },
    {
      name: 'data.feelingOfPain.occupationAndActivity.remote',
      label: t('report.HMMFIRST.feelingOfPain.occupationAndActivity.fields.remote'),
    },
    {
      name: 'data.feelingOfPain.occupationAndActivity.financialUse',
      label: t('report.HMMFIRST.feelingOfPain.occupationAndActivity.fields.financialUse'),
    },
    {
      name: 'data.feelingOfPain.occupationAndActivity.emergencyButton',
      label: t('report.HMMFIRST.feelingOfPain.occupationAndActivity.fields.emergencyButton'),
    },
  ];
};

export const occupationAndActivityOptions = ['independent', 'needHelp', 'noOperation'];

export const FimOptions = [
  {
    value: 1,
    label: 'BADL.fullHelp',
  },
  {
    value: 2,
    label: 'BADL.almostHelp',
  },
  {
    value: 3,
    label: 'BADL.fiftyHelp',
  },
  {
    value: 4,
    label: 'BADL.quarterHelp',
  },
  {
    value: 5,
    label: 'BADL.supervision',
  },
  {
    value: 6,
    label: 'BADL.accessory',
  },
  {
    value: 7,
    label: 'independent',
  },
];

export const fimFields1 = [
  'fimEating',
  'fimSelfCare',
  'fimBathing',
  'fimTopDress',
  'fimLowerDress',
  'fimServices',
];
export const fimFields2 = ['fimUrine', 'fimFeces'];
export const fimFields3 = ['fimBedChairWheel', 'fimServices1', 'fimShower'];
export const fimFields4 = ['fimWalkingWheel', 'fimStairs'];
export const fimFields5 = ['fimUnderstanding', 'fimExpression'];
export const fimFields6 = ['fimSocialInteraction', 'fimTroubleshooting', 'fimMemory'];

export const fimSection1Fields = [...fimFields1, ...fimFields2, ...fimFields3, ...fimFields4];
export const fimSection2Fields = [...fimFields5, ...fimFields6];

export const getFimSectionSum = (values: ReportData, fields: string[]) => {
  let sum = 0;
  if (fields) {
    fields.map((field: string) => {
      sum += values[field] ? parseInt(isNaN(values[field]) ? 0 : values[field]) : 0;
    });
  }
  return sum;
};

export const hmmFirstTreatmentFields: IField[] = [
  {
    name: 'data.treatmentPlan.treatmentGoals',
    label: 'report.HMMFIRST.treatmentPlan.treatmentGoals',
    type: 'text',
    rows: 5,
    smallSize: 12,
    largeSize: 12,
  },
  {
    name: 'data.treatmentPlan.recommendedTreatment',
    label: 'report.HMMFIRST.treatmentPlan.recommendedTreatment',
    type: 'text',
    rows: 5,
    smallSize: 12,
    largeSize: 12,
  },
  {
    name: 'data.treatmentPlan.adjusting',
    label: 'report.HMMFIRST.treatmentPlan.adjusting',
    type: 'text',
    rows: 2,
    smallSize: 12,
    largeSize: 12,
  },
];

export const hmmFirstFreeTextFields: IField[] = [
  {
    name: 'data.freeTextFields.accessibilityMode',
    label: 'report.HMMFIRST.freeTextFields.accessibilityMode',
    type: 'text',
    rows: 5,
    smallSize: 12,
    largeSize: 12,
  },
  {
    name: 'data.freeTextFields.leisureReference',
    label: 'report.HMMFIRST.freeTextFields.leisureReference',
    type: 'text',
    rows: 5,
    smallSize: 12,
    largeSize: 12,
  },
  {
    name: 'data.freeTextFields.functionalGoals',
    label: 'report.HMMFIRST.freeTextFields.functionalGoals',
    type: 'text',
    rows: 5,
    smallSize: 12,
    largeSize: 12,
  },
];

export const hmmReleaseTreatmentFields: IField[] = [
  {
    name: 'data.releaseSection.initialStatus',
    label: 'report.HMMFIRST.releaseSection.initialStatus',
    type: 'text',
    rows: 3,
    smallSize: 12,
    largeSize: 12,
  },
  {
    name: 'data.releaseSection.treatmentPlan',
    label: 'report.HMMFIRST.releaseSection.treatmentPlan',
    type: 'text',
    rows: 3,
    smallSize: 12,
    largeSize: 12,
  },
  {
    name: 'data.releaseSection.patientCurrentStatus',
    label: 'report.HMMFIRST.releaseSection.patientCurrentStatus',
    type: 'text',
    rows: 3,
    smallSize: 12,
    largeSize: 12,
  },
  {
    name: 'data.releaseSection.recommendations',
    label: 'report.HMMFIRST.releaseSection.recommendations',
    type: 'text',
    rows: 3,
    smallSize: 12,
    largeSize: 12,
  },
];
const painOptions: IOption[] = [
  { value: 'standardization' },
  { value: 'notNormal' },
  { value: 'partialNormality' },
];

export const painFields: IField[] = [
  {
    name: 'data.SensoryFunctions.pain',
    label: 'report.HMMFIRST.SensoryFunctions.fileds.pain',
    type: 'select',
    options: painOptions,
    smallSize: 12,
    largeSize: 6,
  },
  {
    name: 'data.SensoryFunctions.painNotes',
    label: 'report.HMMFIRST.SensoryFunctions.fileds.pain',
    type: 'note',
    rows: 1,
    smallSize: 12,
    largeSize: 6,
  },
];
