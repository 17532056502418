/* eslint-disable new-cap */
import React, { useMemo, useState } from 'react';
import { IUserFlat, IVisit, ReportData } from '../../../interfaces/Models';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import FormModal from '../../../components/Modals/FormModal';
import DueDateFormField from '../../program/hospitalDays/DueDateFormField';
import Form from '../../../components/Form/FormikForm';
import FetcherService from '../../../hooks/FetcherService';
import VisitService from '../../../services/VisitService';
import useService from '../../../hooks/HookService';
import UserService from '../../../services/UserService';
import ErrorMessage from '../../../components/ErrorMessage';
import SwapEmployeeFormFields from './SwapEmployeeFormFields';
import { Box } from '@mui/material';

interface ISwapVisitEmployeeForm {
  visit: IVisit;
  handleTableUpdate: () => void;
  handleCloseModal: () => void;
  originalEmployeeId: string;
}
export default function SwapVisitEmployeeForm({
  visit,
  handleTableUpdate,
  handleCloseModal,
  originalEmployeeId,
}: ISwapVisitEmployeeForm) {
  const { t } = useTranslation();
  const [isLoadingForm, setIsLoadingForm] = useState<boolean>(false);

  const { isLoading, data, error, errorMessage } = useService<IUserFlat[]>({
    service: UserService.getAll({
      page: 0,
      limit: 1000,
      active: true,
    }),
    deps: [],
  });

  const Schema = Yup.object({
    employee: Yup.object()
      .shape({
        _id: Yup.string().required(t('clinic.form.required')),
      })
      .required(t('clinic.form.required')),
  });

  const handleChangeVisitDueDate = async (values: ReportData) => {
    await FetcherService({
      service: VisitService.swapEmployee(visit?._id || 'empty', {
        newEmployeeId: values.employee?._id,
      }),
      setLoading: setIsLoadingForm,
    });
    handleCloseModal();
    handleTableUpdate();
  };

  const originalEmployee = useMemo(() => {
    return (data || [])?.find((user: IUserFlat) => user._id === originalEmployeeId);
  }, [data]);

  return (
    <FormModal
      open={true}
      onClose={handleCloseModal}
      text={t('visit.swapEmployee')}
      loading={isLoading || isLoadingForm}
    >
      {error || !data ? <ErrorMessage error={errorMessage} /> : null}
      <Form
        onCancel={handleCloseModal}
        submit={handleChangeVisitDueDate}
        initialValues={{ employee: originalEmployee || {} }}
        validationSchema={Schema}
        cancelText={t('clinic.form.cancel')}
        submitText={t('global.save')}
      >
        <Box sx={{ py: 1, width: '100%' }}>
          <SwapEmployeeFormFields users={data || []} />
        </Box>
      </Form>
    </FormModal>
  );
}
