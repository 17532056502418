import { IQuery, IService } from '../interfaces/index';
import { HTTP_METHODS } from '../common/APIHelper';
import { IAttachment } from '../interfaces/attachment';

const getAll = (query?: IQuery): IService => {
  return { route: '/api/attachments', method: HTTP_METHODS.GET, query };
};

const add = (payload: IAttachment): IService => {
  return { route: '/api/attachments', method: HTTP_METHODS.POST, payload };
};

const deleteByPublicId = (payload: { publicId: string; visitId?: string }): IService => {
  return { route: `/api/attachments/asset`, method: HTTP_METHODS.POST, payload };
};

const deleteByRecordId = (id: string): IService => {
  return { route: `/api/attachments/record/${id}`, method: HTTP_METHODS.DELETE };
};

const updateByRecordId = (id: string, payload: IAttachment): IService => {
  return { route: `/api/attachments/record/${id}`, method: HTTP_METHODS.PUT, payload };
};

const attachmentService = {
  add,
  getAll,
  deleteByPublicId,
  deleteByRecordId,
  updateByRecordId,
};

export default attachmentService;
