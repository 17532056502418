/* eslint-disable new-cap */
import { Box, Card, Typography, Container, Grid } from '@mui/material';
import React, { useMemo, useState } from 'react';
import { useRecoilValue } from 'recoil';

import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Row } from 'react-table';
import SearchFilter from '../../components/SearchFilter';
import Table from '../../components/Table';
import useService from '../../hooks/HookService';
import { IPatientProgram, IPatientProgramFlat } from '../../interfaces/Models';
import PatientProgramService from '../../services/PatientProgramService';
import ErrorMessage from '../../components/ErrorMessage';
import FetcherService from '../../hooks/FetcherService';
import ReportService from '../../services/ReportService';
import { ApiResponse } from '../../interfaces/ApiResponseType';
import {
  getPageNumberFromUrlParam,
  getStartEndDateDep,
  handleTableAtomChange,
  onDownloadPdf,
} from '../../utils/Helper';
import { styled } from '@mui/system';
import { theme } from '../../theme';
import { toast } from 'react-toastify';
import { downloadExcelReport, getSheetData, ISheetColumn } from '../../utils/ExcelReportUtils';
import { getProgramsColumns } from '../../common/columns/Programs';
import OverlaySpinner from '../../components/OverlaySpinner';
import {
  CLINIC_LIST,
  FILTERS,
  PATIENT_PROGRAM_STATUS,
  PATIENT_PROGRAM_STATUS_TO_HEBREW,
  URL_QUERY_PARAMS,
} from '../../common/Constants';
import DateRangeFilter from '../../components/DateRangeFilter';
import useSearchFilter from '../../hooks/useSearchFilter';
import DropDownFilter from '../../components/DropDownFilter';
import AutoCompleteFilter from '../../components/AutoCompleteFilter';
import PageHeader from '../../components/PageHeader';
import DownloadButtonWithTooltip from '../financialReports/DownloadButtonWithTooltip';
import { t } from 'i18next';
import ProgramsActions from './ProgramsActions';
import { userAtom } from '../../atoms/userAtom';
import { LEUMIT_PROGRAM_NAMES, MEUHEDET_PROGRAM_NAMES } from '../../common/Program';
import { s } from '@fullcalendar/core/internal-common';

const StyledBox = styled(Box, { name: 'StyledBox', slot: 'Wrapper' })({
  padding: theme.spacing(4),
});

const StyledHeader = styled('div')({
  marginBottom: theme.spacing(2),
});

export const searchFilter = {
  filterName: FILTERS.SEARCH,
  component: <SearchFilter />,
};

export const dateRangeFilter = {
  filterName: FILTERS.DATE_RANGE,
  component: <DateRangeFilter />,
};

export const getProgramTitleFilter = (clinic?: string) => {
  const programNamesSetValues = new Set([
    ...(clinic === CLINIC_LIST.MEUHEDET || !clinic ? MEUHEDET_PROGRAM_NAMES : []),
    ...(clinic === CLINIC_LIST.LEUMIT || !clinic ? LEUMIT_PROGRAM_NAMES : []),
  ]).values();
  return {
    filterName: FILTERS.TITLE_FILTER,
    component: (
      <AutoCompleteFilter
        options={Array.from(programNamesSetValues).sort((a, b) => a.localeCompare(b))}
        label={t('patient.form.title')}
        urlParam={URL_QUERY_PARAMS.TITLE}
        width={310}
      />
    ),
  };
};

export const getClinicFilter = () => {
  return {
    filterName: FILTERS.CLINIC_FILTER,
    component: (
      <AutoCompleteFilter
        options={Object.values(CLINIC_LIST)}
        label={t('drawer.sidebar.clinic')}
        urlParam={URL_QUERY_PARAMS.CLINIC}
      />
    ),
  };
};

const getProgramsPageFilters = (t: Function, isEmployee: boolean, clinic?: string) => [
  searchFilter,
  dateRangeFilter,
  ...(isEmployee
    ? []
    : [
        {
          filterName: FILTERS.STATUS_FILTER,
          component: (
            <DropDownFilter
              values={Object.values(PATIENT_PROGRAM_STATUS)}
              keys={Object.values(PATIENT_PROGRAM_STATUS_TO_HEBREW)}
              defaultValue={PATIENT_PROGRAM_STATUS.ACTIVE}
              urlParam={URL_QUERY_PARAMS.STATUS}
            />
          ),
        },
        getProgramTitleFilter(clinic),
        getClinicFilter(),
      ]),
];

interface IPrograms {
  patient?: string;
  showFilters?: boolean;
}
const Programs = ({ patient, showFilters = false }: IPrograms) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { pageInfo } = useSearchFilter();
  const [isLoadingReport, setIsLoadingReport] = useState(false);
  const [shouldTableUpdate, setShouldTableUpdate] = useState<number>(0);
  const [openCompleteProgramModal, setOpenCompleteProgramModal] = useState(false);
  const [record, setRecord] = useState<IPatientProgram | IPatientProgramFlat | undefined>();
  const [openAddHospitalDaysModal, setOpenAddHospitalDaysModal] = useState<boolean>(false);

  const user = useRecoilValue(userAtom);
  const {
    page,
    limit = 10,
    search,
    from,
    to,
    range,
    sort = 'endDate',
    status,
    title,
    clinic,
    month,
    year,
  } = pageInfo;
  const allProgramsStatuses = Object.values(PATIENT_PROGRAM_STATUS);

  const isEmployee = user?.role === 'EMPLOYEE';
  const { isLoading, data, error, additionalData, loadingNumber, errorMessage } = useService<
    IPatientProgramFlat[]
  >({
    service: PatientProgramService.getAll({
      page: getPageNumberFromUrlParam(page),
      limit: limit,
      search: search,
      programTitle: title,
      status:
        status && allProgramsStatuses.indexOf(status) > -1 ? status : PATIENT_PROGRAM_STATUS.ACTIVE,
      patient: patient,
      fromDate: getStartEndDateDep(pageInfo),
      toDate: getStartEndDateDep(pageInfo, true),
      sort: sort,
      clinicName: clinic,
      ...(user && isEmployee && { employee: user._id, position: user.position }),
    }),
    deps: [
      page,
      limit,
      shouldTableUpdate,
      from,
      to,
      search,
      sort,
      range,
      status,
      title,
      clinic,
      month,
      year,
    ],
  });

  const onDownloadGlobalReleaseReport = async (
    program: IPatientProgramFlat | IPatientProgram,
    reportName?: string
  ) => {
    handleTableAtomChange('programId', program._id);
    const onSuccess = (res: ApiResponse<BlobPart>) => {
      onDownloadPdf(res, reportName || '');
    };
    const onFailed = () => {
      toast.error(t('global.errors.report'));
    };
    await FetcherService<any>({
      service: ReportService.generateGlobalReleaseReportPdf(program._id || 'empty', {
        reportName: reportName,
      }),
      onSuccess: onSuccess,
      onFailed: onFailed,
      setLoading: setIsLoadingReport,
    });
  };

  const handelOpenCompleteProgramModal = (program: IPatientProgramFlat | IPatientProgram) => {
    setRecord(program);
    setOpenCompleteProgramModal(true);
  };

  const handleOpenAddHospitalDaysForm = (program: IPatientProgramFlat | IPatientProgram) => {
    setRecord(program);
    setOpenAddHospitalDaysModal(true);
  };

  const columns = getProgramsColumns(
    onDownloadGlobalReleaseReport,
    handelOpenCompleteProgramModal,
    1,
    sort,
    handleOpenAddHospitalDaysForm
  );

  const onRowClick = (row: Row<IPatientProgram>) => {
    handleTableAtomChange('programId', row.original._id);
    navigate(`/app/patient-program/${row.original._id}`);
  };

  const handelClosingModals = () => {
    setOpenAddHospitalDaysModal(false);
    setOpenCompleteProgramModal(false);
  };
  const handleTableUpdate = () => {
    setShouldTableUpdate((prev) => prev + 1);
  };
  const onDownloadReport = () => {
    if (!data) {
      toast.error(t('global.errors.report'));
      return;
    }

    const filteredColumns = columns.filter((column: ISheetColumn) => !column.noExcelSupport);
    data.forEach((program) => {
      program.isExported = true;
    });
    const reportsData = getSheetData(data, filteredColumns);
    const fileName = 'global.excelReports.allProgramsReport';

    downloadExcelReport(t(fileName), [{ sheetName: t(fileName), sheetData: reportsData }]);
  };

  const filterSection = useMemo(() => {
    return showFilters ? getProgramsPageFilters(t, isEmployee, clinic) : [];
  }, [showFilters, t, isEmployee, clinic]);

  if (error) {
    return <ErrorMessage error={errorMessage} />;
  }

  if (isLoading && loadingNumber == 1) {
    return <OverlaySpinner />;
  }

  if (data?.length == 0 && patient) {
    return (
      <Card>
        <StyledBox flexGrow={1}>
          <StyledHeader>
            <Typography variant="h4" gutterBottom>
              {t('patient.info.noPrograms')}
            </Typography>
            <hr />
          </StyledHeader>
        </StyledBox>
      </Card>
    );
  }
  return (
    <Container>
      <Grid container justifyContent="space-between">
        <Grid>
          <PageHeader pageTitle={t('global.pages.programs')} />
        </Grid>
        <Grid>
          <DownloadButtonWithTooltip onDownloadReport={onDownloadReport} />
        </Grid>
      </Grid>

      <Card>
        <ProgramsActions
          record={record}
          handelClosingModals={handelClosingModals}
          handleTableUpdate={handleTableUpdate}
          openAddHospitalDaysModal={openAddHospitalDaysModal}
          openCompleteProgramModal={openCompleteProgramModal}
          tableSessionId="programId"
        />
        <Table
          columns={columns}
          data={data || []}
          count={additionalData.total || 0}
          pageInfo={{
            page: getPageNumberFromUrlParam(page),
            pageSize: Number(limit),
          }}
          isLoading={isLoading}
          onRowClick={onRowClick}
          filters={filterSection}
        />
        {isLoadingReport && <OverlaySpinner />}
      </Card>
    </Container>
  );
};

export default Programs;
