import React from 'react';
import ErrorMessage from '../components/ErrorMessage';
import useService from '../hooks/HookService';
import { IReport, ReportData } from '../interfaces/Models';
import ReportService from '../services/ReportService';
import { useParams } from 'react-router-dom';
import OverlaySpinner from '../components/OverlaySpinner';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';

interface EditReportLayoutProps {
  Outlet: React.FC<any>;
  failedPreviousSubmissionData?: ReportData;
  isViewMode: boolean;
}
const EditReportLayout = ({
  Outlet,
  failedPreviousSubmissionData,
  isViewMode,
}: EditReportLayoutProps) => {
  const { visitId } = useParams();
  const { t } = useTranslation();

  const onLoadingData = () => {
    if (!isViewMode && failedPreviousSubmissionData) {
      toast.info(t('global.notifications.info.reportsPreviousData'));
    }
  };

  const {
    isLoading,
    data: reportData,
    error,
    errorMessage,
  } = useService<IReport>({
    service: ReportService.getReportByVisitId(visitId || 'empty'),
    deps: [],
    onLoadingData: onLoadingData,
  });

  if (isLoading) {
    return <OverlaySpinner />;
  }
  if (error) {
    return <ErrorMessage error={errorMessage} />;
  }

  return <Outlet context={reportData || failedPreviousSubmissionData} />;
};

export default EditReportLayout;
