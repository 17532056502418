import React from 'react';
import { Field, useFormikContext } from 'formik';
import { Autocomplete, AutocompleteRenderInputParams } from 'formik-mui';
import { StyledTextInput } from '../../styles/FormFieldsStyles';
import { useTranslation } from 'react-i18next';
import { IBaseSchema } from '../../interfaces/Models';
import { CircularProgress } from '@mui/material';

function getNestedValue(obj: any, path: string) {
  return path.split('.').reduce((current, key) => {
    return current && current[key] !== undefined ? current[key] : undefined;
  }, obj);
}

interface Props<T> {
  options: T[];
  getOptionLabel?: (option: T) => string;
  name: string;
  label: string;
  isLoading?: boolean;
  freeSolo?: boolean;
  disabled?: boolean;
}
export default function FormikAutocomplete<T extends IBaseSchema | string>({
  options,
  getOptionLabel,
  name,
  label,
  isLoading,
  freeSolo = false,
  disabled = false,
}: Props<T>) {
  const { t } = useTranslation();
  const { touched, errors }: any = useFormikContext();

  return (
    <Field
      fullWidth
      freeSolo={freeSolo}
      name={name}
      disabled={disabled}
      component={Autocomplete}
      options={options}
      getOptionLabel={getOptionLabel}
      renderInput={(params: AutocompleteRenderInputParams) => {
        const fieldError = getNestedValue(errors, name);
        const isFieldTouched = getNestedValue(touched, name);
        return (
          <StyledTextInput
            {...params}
            name={name}
            error={isFieldTouched && !!fieldError}
            helperText={isFieldTouched && fieldError ? t('clinic.form.required') : ''}
            label={label}
            variant="outlined"
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <React.Fragment>
                  {isLoading ? <CircularProgress color="inherit" size={20} /> : null}
                  {params.InputProps.endAdornment}
                </React.Fragment>
              ),
            }}
          />
        );
      }}
    />
  );
}
