import { getIn, useFormikContext } from 'formik';
import React from 'react';
import SectionDivider from '../../../../components/SectionDivider';
import { useTranslation } from 'react-i18next';
import PracticeFields from '../../sharedFields/PracticeFields';
import GenericField from '../../sharedFields/GenericField';

interface HMPRepeatedFieldsProps {
  id: string;
}
const HMPRepeatedFields = ({ id }: HMPRepeatedFieldsProps) => {
  const { t } = useTranslation();
  const { values, setFieldValue }: any = useFormikContext();
  const practicesValues = getIn(values, 'data.exercises');

  return (
    <>
      <GenericField
        id={`${id}_Treatment1`}
        fields={[
          {
            name: 'data.treatment',
            label: 'report.HMPREPEATED.treatment',
            type: 'textareaWithEditor',
          },
          {
            name: 'data.status',
            label: 'report.HMPREPEATED.status',
            type: 'textareaWithEditor',
          },
          {
            name: 'data.recommendations',
            label: 'report.HMPREPEATED.recommendations',
            type: 'textareaWithEditor',
          },
        ]}
      />
      <PracticeFields
        subHeader={t('report.HMPREPEATED.exercises')}
        onChangeFormikValue={setFieldValue}
        fieldName="data.exercises"
        categories={['grossMotorSkills']}
        practicesValues={practicesValues}
      />
      <SectionDivider />
    </>
  );
};

export default HMPRepeatedFields;
