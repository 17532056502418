/* eslint-disable new-cap */
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useParams, useOutletContext } from 'react-router-dom';
import * as Yup from 'yup';
import { IReport } from '../../../../interfaces/Models';
import { reportTypes } from '../../../../common/Constants';
import ReportsForm from '../../../../components/Form/ReportsForm';
import HHDRepeatedFields from './fields/HHDRepeatedFields';

const HHDRepeated = () => {
  const { t } = useTranslation();
  const { visitId } = useParams();
  const data: IReport = useOutletContext();

  const initialValues =
    data && data?.name
      ? data
      : {
          name: reportTypes.HMM_ADJUSTMENT_REPEATED,
          visit: visitId,
          data: {},
        };
  const ReportSchema = Yup.object({
    name: Yup.string().required(t('clinic.form.required')),
    visit: Yup.string().required(t('clinic.form.required')),
    data: Yup.object().shape({}),
  });

  const htmlId = 'HMM_ADJUSTMENT_REPEATED';

  return (
    <ReportsForm
      initialValues={initialValues}
      visitId={visitId || ''}
      validationSchema={ReportSchema}
    >
      <HHDRepeatedFields id={htmlId} visitId={visitId || ''} />
    </ReportsForm>
  );
};

export default HHDRepeated;
