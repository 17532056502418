import React, { useState } from 'react';
import { Grid, TextField, IconButton, Typography, Button } from '@mui/material';
import { useTranslation } from 'react-i18next';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import { StyledDiv, StyledGrid } from '../../../styles/FormFieldsStyles';
import isReportMode from '../../../hooks/isReportMode';

interface IDynamicFields {
  onChangeFormikValue: (field: string, value: any, shouldValidate?: boolean) => void;
  fieldName: string;
  label: string;
  id: string;
  values: Array<string>;
}

type IField = {
  fieldValue: string;
  error?: string;
};

const HabitinputState = {
  fieldValue: '',
};

const getFieldsValues = (values: string[]) => {
  const fieldsValues: IField[] = [];
  values?.map((value: string) => fieldsValues.push({ fieldValue: value, error: '' }));
  return fieldsValues;
};

const DynamicFields = ({ onChangeFormikValue, fieldName, label, id, values }: IDynamicFields) => {
  const { t } = useTranslation();
  const isViewMode = isReportMode('view');
  const [formFields, setFormFields] = useState<IField[]>(
    values?.length > 0 ? getFieldsValues(values) : []
  );

  const handleAddField = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    const newValue = [...formFields, { ...HabitinputState }];
    setFormFields(newValue);
  };

  const onChange = (
    index: number,
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const newValue = formFields.map((item: IField, i: number) => {
      if (i !== index) {
        return item;
      }
      item.fieldValue = event.target.value;
      item.error = event.target.value.length > 0 ? '' : t('clinic.form.required');
      return item;
    });
    setFormFields(newValue);
    onChangeFormikValue(
      fieldName,
      newValue.map((item: IField) => item.fieldValue)
    );
  };

  const onBlur = (index: number, event: any) => {
    const newMedicationsValue = formFields.map((item: IField, i: number) => {
      if (i !== index) {
        return item;
      }

      item.error = item.fieldValue.length > 0 ? '' : t('clinic.form.required');
      return item;
    });
    setFormFields(newMedicationsValue);
  };

  const handleRemoveField = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>, index: number) => {
    const newValue = formFields.filter((item: IField, i: number) => i !== index);
    setFormFields(newValue);
    onChangeFormikValue(
      fieldName,
      newValue.map((item: IField) => item.fieldValue)
    );
  };

  return (
    <div id={id}>
      <Typography variant="subtitle1" gutterBottom align="left">
        {label}
      </Typography>
      <StyledGrid container justifyContent="left" spacing={4} id={`${id}_Container`}>
        <Grid item xs={12} md={12} lg={12}>
          {formFields.map((item: IField, index: number) => (
            <StyledDiv key={`Sensitivity_${index}`}>
              <TextField
                fullWidth
                id={`${id}_TextInput_${index}`}
                value={item.fieldValue}
                label={label}
                disabled={isViewMode}
                onChange={(e) => onChange(index, e)}
                error={!!item.error && item.error.length > 0}
                helperText={item.error}
                onBlur={(event) => onBlur(index, event)}
              />
              <IconButton
                color="error"
                aria-label="delete"
                onClick={(e) => handleRemoveField(e, index)}
                size="large"
                disabled={isViewMode}
              >
                <DeleteIcon />
              </IconButton>
            </StyledDiv>
          ))}

          <Button
            variant="contained"
            disabled={isViewMode}
            endIcon={<AddIcon />}
            onClick={handleAddField}
          >
            {t('global.form.add')}
          </Button>
        </Grid>
      </StyledGrid>
    </div>
  );
};

export default DynamicFields;
