import React, { ReactElement } from 'react';
import { Icon } from '@iconify/react';
import plusFill from '@iconify/icons-eva/plus-fill';
import { Button, Toolbar } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import { styled } from '@mui/system';
import { IFilter } from './Table';
import { FILTERS } from '../common/Constants';
import { Subject } from '../casl/ability';
import { useAbility } from '@casl/react';
import { AbilityContext } from '../casl/can';
import { FlexDiv, FlexDiv1, FlexDiv2 } from '../styles/DateRangeFilterStyles';
import { theme } from '../theme';
import { FiltersPositions, TableHeaderPositions } from './WidgetContainer';
import { ReportData } from '../interfaces/Models';

const RootStyle = styled(Toolbar)(({ theme }) => ({
  display: 'flex',
  padding: theme.spacing(1.5, 1, 0, 3),
  alignItems: 'center',
}));

const StyledDiv = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  [theme.breakpoints.down('sm')]: {
    marginTop: theme.spacing(1.5),
    flexDirection: 'column',
  },
});

const FiltersPositionsValues: ReportData = {
  CENTER: 'center',
  LEFT: 'flex-end',
  RIGHT: 'flex-start',
};
interface TableHeaderProps {
  url?: string;
  buttonText?: string;
  clickHandler?: (value: boolean) => void;
  filters?: IFilter[];
  actionAccess?: Subject;
  TableHeaderFilters?: ReactElement;
  filterPosition?: FiltersPositions;
}

const TableHeader = ({
  url,
  buttonText,
  clickHandler,
  filters,
  actionAccess,
  TableHeaderFilters,
  filterPosition,
}: TableHeaderProps) => {
  const ability = useAbility(AbilityContext);

  const filtersPosition =
    filters && filters?.length > 0 && filters[0].filterName == FILTERS.DATE_RANGE
      ? { justifyContent: 'flex-end' }
      : filterPosition
      ? FiltersPositionsValues[filterPosition]
      : { justifyContent: 'space-between' };

  const filtersFlex =
    (filters && filters.length > 1) || (TableHeaderFilters && (!filters || filters.length < 1))
      ? { flex: 1 }
      : {};

  const showAction = actionAccess ? !ability.can('create', actionAccess) : false;

  return (
    <RootStyle sx={filtersPosition}>
      <StyledDiv sx={filtersFlex}>
        {filters && filters.length > 0 ? (
          <>
            {filters && filters.length > 1 && filterPosition != TableHeaderPositions.LEFT ? (
              <>
                <FlexDiv sx={{ width: '100%' }}>{filters[0].component}</FlexDiv>
                <FlexDiv1>
                  {filters.map((filter: IFilter, idx) => {
                    if (idx > 0 && idx < filters.length) {
                      return <FlexDiv2 key={filter.filterName}>{filter.component}</FlexDiv2>;
                    }
                  })}
                </FlexDiv1>
              </>
            ) : (
              <FlexDiv1>
                {filters.map((filter: IFilter) => {
                  return <FlexDiv2 key={filter.filterName}>{filter.component}</FlexDiv2>;
                })}
              </FlexDiv1>
            )}
          </>
        ) : TableHeaderFilters && (!filters || filters.length < 1) ? (
          <>{TableHeaderFilters}</>
        ) : null}
      </StyledDiv>
      {!!clickHandler && (
        <Button
          variant="contained"
          endIcon={<Icon icon={plusFill} />}
          onClick={() => clickHandler(true)}
          disabled={showAction}
        >
          {buttonText}
        </Button>
      )}
      {url ? (
        <Button
          variant="contained"
          component={RouterLink}
          to={url}
          endIcon={<Icon icon={plusFill} />}
          disabled={showAction}
        >
          {buttonText}
        </Button>
      ) : null}
    </RootStyle>
  );
};

export default TableHeader;
