import React from 'react';
import HDDCommonFields from './HHDCommonFields';
import GenericField from '../../../sharedFields/GenericField';
import SectionDivider from '../../../../../components/SectionDivider';

const decisionOptions = [
  {
    value: 'FITTING',
    label: 'decision.FITTING',
  },
  {
    value: 'NOT_FITTING',
    label: 'decision.NOT_FITTING',
  },
  {
    value: 'PROGRAM_APPROVAL',
    label: 'decision.PROGRAM_APPROVAL',
  },
];

const HMMFirstFields = ({ id, visitId }: { id: string; visitId: string }) => {
  return (
    <>
      <HDDCommonFields id={`${id}_title`} />
      <GenericField
        id={`${id}_decision`}
        fields={[
          {
            name: 'data.decision',
            label: 'report.HHDFIRST.decision',
            type: 'select',
            options: decisionOptions,
            smallSize: 12,
            largeSize: 12,
          },
        ]}
      />
      <SectionDivider />
    </>
  );
};

export default HMMFirstFields;
