/* eslint-disable new-cap */
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Form from '../../components/Form/FormikForm';
import FormModal from '../../components/Modals/FormModal';
import { IPatientProgram, IProgramRef } from '../../interfaces/Models';
import * as Yup from 'yup';
import { Field } from 'formik';
import { StyledTextField } from '../../styles/FormFieldsStyles';
import { Stack } from '@mui/material';
import FormikMonthPicker from '../../components/Form/FormikMonthPicker';
import FetcherService from '../../hooks/FetcherService';
import PatientProgramService from '../../services/PatientProgramService';

interface IRefFormData {
  ref: string;
  date: Date;
}
interface IProgramReferenceNumberForm {
  openModal: boolean;
  programRef?: IProgramRef;
  program: IPatientProgram;
  handleCloseModal: () => void;
  handleProgramUpdate: () => void;
}
const ProgramReferenceNumberForm = ({
  openModal,
  program,
  programRef,
  handleCloseModal,
  handleProgramUpdate,
}: IProgramReferenceNumberForm) => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const isEditMode = !!programRef;

  const schema = Yup.object({
    ref: Yup.number().required(`${t('clinic.form.required')}`),
    date: Yup.date().required(`${t('clinic.form.required')}`),
  });

  const initialValues = {
    ref: programRef?.ref,
    date: isEditMode ? new Date(Number(programRef.year), Number(programRef.month) - 1) : new Date(),
  };

  const handleSubmitRef = async (values: IRefFormData) => {
    const value = {
      ...(programRef || {}),
      ref: values.ref,
      month: values.date.getMonth() + 1,
      year: values.date.getFullYear(),
    };
    if (isEditMode) {
      await FetcherService<any>({
        service: PatientProgramService.editProgramReference({
          id: String(program?._id),
          payload: value,
        }),
        setLoading: setIsLoading,
      });
    } else {
      await FetcherService<any>({
        service: PatientProgramService.addProgramReference(String(program?._id), value),
        setLoading: setIsLoading,
      });
    }

    handleCloseModal();
    handleProgramUpdate();
  };

  const submitText = programRef ? t('clinic.form.edit') : t('clinic.form.add');
  const title = programRef ? t('editClinicReferenceNumber') : t('addClinicReferenceNumber');

  return (
    <FormModal open={openModal} onClose={handleCloseModal} text={title} loading={isLoading}>
      <Form
        onCancel={handleCloseModal}
        submit={handleSubmitRef}
        initialValues={initialValues}
        validationSchema={schema}
        cancelText={t('clinic.form.cancel')}
        submitText={submitText}
      >
        <Stack sx={{ my: 1, width: '100%' }} gap={1}>
          <Field
            fullWidth
            component={StyledTextField}
            type="number"
            name={'ref'}
            label={t('clinicReferenceNumber')}
          />
          {!isEditMode && <FormikMonthPicker label={t('month')} name="date" />}
        </Stack>
      </Form>
    </FormModal>
  );
};

export default ProgramReferenceNumberForm;
