import React from 'react';
import { Grid, Typography } from '@mui/material';
import { FastField as Field } from 'formik';
import { TextField } from 'formik-mui';
import { useTranslation } from 'react-i18next';
import { styled } from '@mui/system';
import { theme } from '../../../../theme';

const StyledTextField = styled(TextField)({
  marginBottom: theme.spacing(2),
});

interface PhysicalExaminationFieldsProps {
  typeOfExamination: string;
  Examinations: string[];
  id: string;
}

const ExaminationFields = ({
  typeOfExamination,
  Examinations,
  id,
}: PhysicalExaminationFieldsProps) => {
  const { t } = useTranslation();

  return (
    <div id={id}>
      <Typography variant="subtitle1" gutterBottom align="left">
        {t(`report.firstVisitReport.${typeOfExamination}.header`)}
      </Typography>
      <Grid container spacing={{ xs: 0, md: 4, lg: 4 }} id={`${id}_Container`}>
        <Grid item xs={12} md={6} lg={6}>
          <Grid item xs={12} md={12} lg={12}>
            <Field
              id={`${id}_${Examinations[0]}`}
              fullWidth
              component={StyledTextField}
              type="text"
              name={`data.${typeOfExamination}.${Examinations[0]}`}
              label={t(`report.firstVisitReport.${typeOfExamination}.${Examinations[0]}`)}
            />
          </Grid>
          <Grid item xs={12} md={12} lg={12}>
            <Field
              id={`${id}_${Examinations[1]}`}
              fullWidth
              component={StyledTextField}
              type="text"
              name={`data.${typeOfExamination}.${Examinations[1]}`}
              label={t(`report.firstVisitReport.${typeOfExamination}.${Examinations[1]}`)}
            />
          </Grid>
          <Grid item xs={12} md={12} lg={12}>
            <Field
              id={`${id}_${Examinations[2]}`}
              fullWidth
              component={StyledTextField}
              type="text"
              name={`data.${typeOfExamination}.${Examinations[2]}`}
              label={t(`report.firstVisitReport.${typeOfExamination}.${Examinations[2]}`)}
            />
          </Grid>
        </Grid>
        <Grid item xs={12} md={6} lg={6}>
          <Grid item xs={12} md={12} lg={12}>
            <Field
              id={`${id}_${Examinations[3]}`}
              fullWidth
              component={StyledTextField}
              type="text"
              name={`data.${typeOfExamination}.${Examinations[3]}`}
              label={t(`report.firstVisitReport.${typeOfExamination}.${Examinations[3]}`)}
            />
          </Grid>
          <Grid item xs={12} md={12} lg={12}>
            <Field
              id={`${id}_${Examinations[4]}`}
              fullWidth
              component={StyledTextField}
              type="text"
              name={`data.${typeOfExamination}.${Examinations[4]}`}
              label={t(`report.firstVisitReport.${typeOfExamination}.${Examinations[4]}`)}
            />
          </Grid>
          <Grid item xs={12} md={12} lg={12}>
            <Field
              id={`${id}_${Examinations[5]}`}
              fullWidth
              component={StyledTextField}
              type="text"
              name={`data.${typeOfExamination}.${Examinations[5]}`}
              label={t(`report.firstVisitReport.${typeOfExamination}.${Examinations[5]}`)}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={12} md={12} lg={12}>
          <Field
            id={`${id}_${Examinations[6]}`}
            fullWidth
            component={StyledTextField}
            type="text"
            name={`data.${typeOfExamination}.${Examinations[6]}`}
            label={t(`report.firstVisitReport.${typeOfExamination}.${Examinations[6]}`)}
          />
        </Grid>
      </Grid>
    </div>
  );
};

export default ExaminationFields;
