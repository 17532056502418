import { TFunction } from 'react-i18next';
import { EMPLOYEE_POSITIONS } from '../../../common/Constants';
import { IHeadersAction } from '../../HeaderBreadcrumbs';
import { IAdditionalData } from '../../../interfaces/ApiResponseType';
import { IVisit } from '../../../interfaces/Models';
import { IVisitWithProgramTitle } from '../../../layouts/reports/ReportLayout';
import {
  getVisitGeneralReportRoute,
  handleShowHideRepoGeneralReportAction,
} from '../../../utils/Helper';
import ListAltIcon from '@mui/icons-material/ListAlt';

interface IGetShowGeneralReportHeaderActionData {
  visitData: IVisitWithProgramTitle;
  additionalData: IAdditionalData;
  t: TFunction<'translation', undefined>;
}
export const getShowGeneralReportHeaderAction = ({
  visitData,
  additionalData,
  t,
}: IGetShowGeneralReportHeaderActionData): IHeadersAction<IVisit>[] => {
  const visitPosition = visitData.visitType.slice(0, 3);
  let route = '';

  const showTooltip = handleShowHideRepoGeneralReportAction(visitData, visitPosition);

  if (showTooltip) {
    route = getVisitGeneralReportRoute(visitData, additionalData, visitPosition);
  }

  return showTooltip
    ? [
        {
          name: 'generalReportView',
          tooltipTitle:
            visitPosition == EMPLOYEE_POSITIONS.HMP
              ? t('global.reportActions.GeneralPhysiotherapyReport')
              : t('global.reportActions.GeneralDoctorReport'),
          href: route,
          type: 'LinkIcon',
          icon: <ListAltIcon />,
          color: 'secondary',
        },
      ]
    : [];
};
