/* eslint-disable new-cap */
import * as Yup from 'yup';
import { useState } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { useFormik, Form, FormikProvider } from 'formik';
import { Icon } from '@iconify/react';
import eyeFill from '@iconify/icons-eva/eye-fill';
import eyeOffFill from '@iconify/icons-eva/eye-off-fill';
import { useTranslation } from 'react-i18next';
import FetcherService from '../../../hooks/FetcherService';
import UserService from '../../../services/UserService';
import { toast } from 'react-toastify';
import { useSetRecoilState } from 'recoil';
// material
import { Link, Stack, TextField, IconButton, InputAdornment } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { ILoginCredentials, ILoginResponse } from '../../../interfaces/Models';
import { userAtom } from '../../../atoms/userAtom';
import { handleTableAtomChange } from '../../../utils/Helper';
import { TABS_NAMES } from '../../../common/Constants';

// ----------------------------------------------------------------------

const LoginForm = () => {
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const { t } = useTranslation();
  const setUser = useSetRecoilState(userAtom);

  const LoginSchema = Yup.object().shape({
    email: Yup.string().email(t('user.form.emailInvaild')).required(t('clinic.form.required')),
    password: Yup.string()
      .min(6, t('user.form.passwordValidation'))
      .required(t('clinic.form.required')),
  });

  const login = async (
    values: ILoginCredentials,
    setSubmitting: (value: boolean) => void,
    resetForm: (value: Object) => void
  ) => {
    try {
      const data: ILoginResponse = await FetcherService<ILoginResponse>({
        service: UserService.login(values),
        showNotification: false,
      });
      setUser(data.user);
      localStorage.setItem('authToken', data.token);
      setSubmitting(false);
      if (data.user.isNewUser) {
        handleTableAtomChange(TABS_NAMES.PROFILE, 'change_password');
        navigate('/app/profile', { replace: true });
        toast.info(t('user.form.changePassword'));
      } else {
        navigate('/app/dashboard', { replace: true });
      }
    } catch {
      resetForm({ values: '' });
      setSubmitting(false);
      toast.error(t('global.errors.wrongCredentials'));
    }
  };

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
      // remember: true,
    },
    validationSchema: LoginSchema,
    onSubmit: (values, { setSubmitting, resetForm }) => {
      login(values, setSubmitting, resetForm);
    },
  });

  const { errors, touched, isSubmitting, handleSubmit, getFieldProps } = formik;

  const handleShowPassword = () => {
    setShowPassword((show) => !show);
  };

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Stack spacing={3}>
          <TextField
            fullWidth
            autoComplete="username"
            type="email"
            label="אימיל"
            {...getFieldProps('email')}
            error={Boolean(touched.email && errors.email)}
            helperText={touched.email && errors.email}
          />

          <TextField
            fullWidth
            autoComplete="current-password"
            type={showPassword ? 'text' : 'password'}
            label="סיסמה"
            {...getFieldProps('password')}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={handleShowPassword} edge="end">
                    <Icon icon={showPassword ? eyeFill : eyeOffFill} />
                  </IconButton>
                </InputAdornment>
              ),
            }}
            error={Boolean(touched.password && errors.password)}
            helperText={touched.password && errors.password}
          />
        </Stack>

        <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }}>
          <Link component={RouterLink} variant="subtitle2" to="/forgot-password">
            שכחתי סיסמה?
          </Link>
        </Stack>

        <LoadingButton
          fullWidth
          size="large"
          type="submit"
          variant="contained"
          loading={isSubmitting}
        >
          כניסה
        </LoadingButton>
      </Form>
    </FormikProvider>
  );
};
export default LoginForm;
