import React from 'react';
import * as Yup from 'yup';
import { Grid, MenuItem, SelectChangeEvent } from '@mui/material';
import { Select } from 'formik-mui';
import { IStaff, IUserFlat } from '../../../../interfaces/Models';
import { useTranslation } from 'react-i18next';
import AddIcon from '@mui/icons-material/Add';
import { Field, Form, Formik } from 'formik';
import { StaffGrid, staffSelectProps, StyledStaffButton } from '../../../../styles/ProgramStyles';
import { EMPLOYEE_POSITIONS } from '../../../../common/Constants';
import { ADJUSTMENT_REPORT } from '../../../../common/Program';

interface StaffFormProps {
  employees: IUserFlat[];
  submitStaffMember: (value: IStaff) => void;
  staffPositions: string[];
}

const StaffForm = ({ employees, submitStaffMember, staffPositions }: StaffFormProps) => {
  const { t } = useTranslation();

  const getEmployees = async (position: string) => {
    const isHouseAdjustmentReport = position === ADJUSTMENT_REPORT;
    return await employees.filter(
      (employee: IUserFlat) =>
        employee.position == (isHouseAdjustmentReport ? EMPLOYEE_POSITIONS.HMM : position)
    );
  };

  const initialValues: IStaff = {
    position: '',
    employee: '',
    employees: [],
  };

  const StaffShema = Yup.object({
    position: Yup.string().required(t('clinic.form.required')),
    employee: Yup.string().required(t('clinic.form.required')),
  });

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={(values: any, { setSubmitting, resetForm }) => {
        submitStaffMember(values);
        resetForm({ values: '' });
        setSubmitting(false);
      }}
      validationSchema={StaffShema}
    >
      {({ values, isValid, dirty, setFieldValue }) => {
        return (
          <Form>
            <StaffGrid container justifyContent="space-between">
              <Grid item xs={12} md={5.8} lg={5.8}>
                <Field
                  component={Select}
                  name="position"
                  disabled={staffPositions.length == 0}
                  label={t('patient.form.visitType')}
                  formControl={{ sx: staffSelectProps }}
                  onChange={async (e: SelectChangeEvent<string>) => {
                    const { value } = e.target;
                    const employees = await getEmployees(value);
                    setFieldValue('position', value);
                    setFieldValue('employees', employees);
                  }}
                >
                  {staffPositions.map((position: string) => {
                    return (
                      <MenuItem key={position} value={position}>
                        {t(`global.positions.${position}`)}
                      </MenuItem>
                    );
                  })}
                </Field>
              </Grid>
              <Grid item xs={12} md={5.8} lg={5.8}>
                <Field
                  component={Select}
                  name="employee"
                  disabled={staffPositions.length == 0}
                  label={t('global.roles.EMPLOYEE')}
                  formControl={{ sx: staffSelectProps }}
                >
                  {values.employees &&
                    values.employees.map((employee: IUserFlat) => {
                      return (
                        <MenuItem key={employee._id + employee.position} value={employee._id}>
                          {`${employee.fullName}`}
                        </MenuItem>
                      );
                    })}
                </Field>
              </Grid>
            </StaffGrid>
            <Grid container justifyContent="right">
              <Grid item xs={11} md={11} lg={11}>
                <StyledStaffButton
                  disabled={!(isValid && dirty)}
                  type="submit"
                  variant="contained"
                  endIcon={<AddIcon />}
                >
                  {t('patient.form.addStaff')}
                </StyledStaffButton>
              </Grid>
            </Grid>
          </Form>
        );
      }}
    </Formik>
  );
};

export default StaffForm;
