import React from 'react';
import { useTranslation } from 'react-i18next';
import BloodTest from './BloodTest';
import GenericField from '../../sharedFields/GenericField';

const MedicalInfoFields = () => {
  const { t } = useTranslation();
  const id = 'HMDFirst_MedicalInfo';

  return (
    <>
      <GenericField
        id={`${id}_generalMedicalInfo`}
        subHeader={t('report.HMDFIRST.generalMedicalInfo.header')}
        fields={[
          {
            name: 'data.generalMedicalInfo.nutritionalCounseling',
            label: 'report.HMDFIRST.generalMedicalInfo.nutritionalCounseling',
            type: 'text',
          },
          {
            name: 'data.generalMedicalInfo.allergies',
            label: 'report.HMDFIRST.generalMedicalInfo.allergies',
            type: 'textareaWithEditor',
          },
          {
            name: 'data.generalMedicalInfo.additionalDetails',
            label: 'report.HMDFIRST.generalMedicalInfo.additionalDetails',
            type: 'textareaWithEditor',
          },
        ]}
      />
      <BloodTest />
    </>
  );
};

export default MedicalInfoFields;
