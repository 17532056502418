import * as Yup from 'yup';
import { ReportData } from '../interfaces/Models';
import { sumValues } from '../utils/Helper';

export const getAssessmentSchema = (t: Function) => {
  return {
    name: Yup.string().required(t('clinic.form.required')),
    visit: Yup.string().required(t('clinic.form.required')),
    data: Yup.object().shape({
      assessment: Yup.object().shape({
        transitionSittingLying: Yup.number().required(t('clinic.form.required')),
        RollingToRight: Yup.number().required(t('clinic.form.required')),
        RollingToLeft: Yup.number().required(t('clinic.form.required')),
        transitionLyingSitting: Yup.number().required(t('clinic.form.required')),
        seatWithoutSupport: Yup.number().required(t('clinic.form.required')),
        movingSittingStanding: Yup.number().required(t('clinic.form.required')),
        standingWithoutSupport: Yup.number().required(t('clinic.form.required')),
        movingStandingSitting: Yup.number().required(t('clinic.form.required')),
        mChair: Yup.number().required(t('clinic.form.required')),
        rightUpperLimb: Yup.number().required(t('clinic.form.required')),
        leftUpperLimb: Yup.number().required(t('clinic.form.required')),
        walkingAbilityTest: Yup.number().required(t('clinic.form.required')),
        walkingInsideBuilding: Yup.number().required(t('clinic.form.required')),
        walkingOutside: Yup.number().required(t('clinic.form.required')),
        walkingOutsideBuilding: Yup.number().required(t('clinic.form.required')),
        useOfStairs: Yup.number().required(t('clinic.form.required')),
        upDownSidewalk: Yup.number().required(t('clinic.form.required')),
        upFromFloor: Yup.number().required(t('clinic.form.required')),
      }),
    }),
  };
};

export const assessmentOptions = [1, 2, 3, 4, 5, 6, 7];

export const assessmentValues = {
  transitionSittingLying: 1,
  RollingToRight: 1,
  RollingToLeft: 1,
  transitionLyingSitting: 1,
  seatWithoutSupport: 1,
  movingSittingStanding: 1,
  standingWithoutSupport: 1,
  movingStandingSitting: 1,
  mChair: 1,
  rightUpperLimb: 1,
  leftUpperLimb: 1,
  walkingAbilityTest: 1,
  walkingInsideBuilding: 1,
  walkingOutside: 1,
  walkingOutsideBuilding: 1,
  useOfStairs: 1,
  upDownSidewalk: 1,
  upFromFloor: 1,
};

export const assessmentFieldsSection1: string[] = [
  'transitionSittingLying',
  'RollingToRight',
  'RollingToLeft',
  'transitionLyingSitting',
  'seatWithoutSupport',
  'movingSittingStanding',
  'standingWithoutSupport',
  'movingStandingSitting',
  'mChair',
];

export const assessmentFieldsSection2: string[] = ['rightUpperLimb', 'leftUpperLimb'];

export const assessmentFieldsSection3: string[] = [
  'walkingAbilityTest',
  'walkingInsideBuilding',
  'walkingOutside',
  'walkingOutsideBuilding',
  'useOfStairs',
  'upDownSidewalk',
  'upFromFloor',
];

export const assessmentFields = [
  ...assessmentFieldsSection1,
  ...assessmentFieldsSection2,
  ...assessmentFieldsSection3,
];

export const getAssessmentSectionsTotal = (values: ReportData) => {
  let total1 = 0;
  let total2 = 0;
  let total3 = 0;
  const allSectionsTotal = sumValues(values);

  assessmentFields.map((field: string) => {
    assessmentFieldsSection1.indexOf(field) > -1
      ? (total1 += parseInt(values[field]))
      : assessmentFieldsSection2.indexOf(field) > -1
      ? (total2 += parseInt(values[field]))
      : (total3 += parseInt(values[field]));
  });
  return {
    total1,
    total2,
    total3,
    allSectionsTotal,
  };
};

export const getAssessmentTotalText = (text: string, value: number) => {
  const textDivided = text.split('/');
  return `${textDivided[0]} ${value} ${textDivided[2]}`;
};
