import React from 'react';
import { useTranslation } from 'react-i18next';
import { IClinic, IPatientProgram, IVisit } from '../../interfaces/Models';
import DetailsCard from '../../components/DetailsCard';
import { VISIT_STATUS } from '../../common/Constants';
import { getDateFormatted } from '../../utils/Helper';

interface ProgramInfoProps {
  program: IPatientProgram;
}
const ProgramInfo = ({ program }: ProgramInfoProps) => {
  const { t } = useTranslation();

  const details = [
    {
      name: t('patient.form.title'),
      icon: 'dashicons:nametag',
      value: program.title,
    },
    {
      name: t('patient.info.patientName'),
      icon: 'eva:person-add-fill',
      value:
        typeof program.patient != 'string'
          ? `${program.patient.personalDetails.firstName} ${program.patient.personalDetails.lastName}`
          : '',
    },
    {
      name: t('patient.form.idNumber'),
      icon: 'arcticons:id-wallet',
      value: typeof program.patient != 'string' ? program.patient.idNumber : '',
    },
    {
      name: t('patient.info.programStatus'),
      icon: 'fluent:status-24-filled',
      value: t(`program.status.${program.status}`),
    },
    {
      name: t('patient.info.startDate'),
      icon: 'bi:calendar-date',
      value: getDateFormatted(program.startDate),
    },
    {
      name: t('patient.info.endDate'),
      icon: 'system-uicons:calendar-date',
      value: getDateFormatted(program.endDate),
    },
    {
      name: t('visit.table.completedVisits'),
      icon: 'ic:round-download-done',
      value: String(
        program.visits.filter((visit: IVisit) => visit.status == VISIT_STATUS.DONE).length
      ),
    },
    {
      name: t('visit.table.sentToClinicVisits'),
      icon: 'healthicons:clinical-f-outline',
      value: String(
        program.visits.filter((visit: IVisit) => visit.status == VISIT_STATUS.SENT_TO_CLINIC).length
      ),
    },
    {
      name: t('drawer.sidebar.plannedVisits'),
      icon: 'material-symbols:pending-actions',
      value: String(
        program.visits.filter((visit: IVisit) => visit.status == VISIT_STATUS.PENDING).length
      ),
    },
    {
      name: t('drawer.sidebar.clinic'),
      icon: 'bx:clinic',
      value:
        typeof program.patient !== 'string' && program.patient.clinic
          ? (program.patient.clinic as IClinic).name
          : '',
    },
    {
      name: t('program.paymentMode'),
      icon: 'mdi:account-payment-outline',
      value: program.paymentMode ? t(`program.paymentModeOptions.${program.paymentMode}`) : '',
    },
    {
      name: t('clinicReferenceNumber'),
      icon: 'material-symbols:quick-reference-all-outline-sharp',
      value: program.clinicReferenceNumber || '',
    },
  ];
  return <DetailsCard title={t('patient.info.programDetails')} details={details} />;
};

export default ProgramInfo;
