/* eslint-disable new-cap */
import { Box, Card, Container } from '@mui/material';
import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import ErrorMessage from '../../components/ErrorMessage';
import Page from '../../components/Page';
import useService from '../../hooks/HookService';
import { IUser } from '../../interfaces/Models';
import UserService from '../../services/UserService';
import { useTranslation } from 'react-i18next';
import InfoCard from '../../components/InfoCard';
import Tabs from '../../components/Tabs';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import HeaderBreadcrumbs from '../../components/HeaderBreadcrumbs';
import EditIcon from '@mui/icons-material/Edit';
import ResetPasswordForm from './ResetPasswordForm';
import FetcherService from '../../hooks/FetcherService';
import DialogActions from '../../components/DialogActions';
import LockResetIcon from '@mui/icons-material/LockReset';
import BlockIcon from '@mui/icons-material/Block';
import VisitsTable from '../visits/VisitsTable';
import { VisitsColumnsKeys } from '../../common/columns/AllVisitsTableColumn';
import { FILTERS, TABS_NAMES, VISIT_STATUS } from '../../common/Constants';
import OverlaySpinner from '../../components/OverlaySpinner';
import DateRangeFilter from '../../components/DateRangeFilter';
import EmployeeAccountingTable from './EmployeeAccountingTable';
import AttachmentsTable from '../../components/AttachmentsTable';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import { AbilityContext } from '../../casl/can';
import { useAbility } from '@casl/react';
import { AbilitySubjects } from '../../casl/ability';
import useAccess from '../../hooks/useAccess';
import { userAtom } from '../../atoms/userAtom';
import { useRecoilValue } from 'recoil';

const EmployeeProfile = () => {
  const user = useRecoilValue(userAtom);
  const ability = useAbility(AbilityContext);
  const [openResetModal, setOpenResetModal] = useState<boolean>(false);
  const [openBlockEmployeeModal, setOpeBlockEmployeeModal] = useState<boolean>(false);
  const { t } = useTranslation();
  const { employeeId } = useParams();
  useAccess('read', AbilitySubjects.USER);

  const { isLoading, data, error, errorMessage } = useService<IUser>({
    service: UserService.getOne(employeeId || 'empty'),
    deps: [],
  });

  const handleResetModal = () => {
    setOpenResetModal(!openResetModal);
  };

  const handleBlockModal = () => {
    setOpeBlockEmployeeModal(!openBlockEmployeeModal);
  };

  const blockEmployee = async () => {
    if (data) {
      await FetcherService<IUser>({
        service: UserService.edit(data?._id as string, {
          ...data,
          active: false,
        }),
      });
      setOpeBlockEmployeeModal(false);
    }
  };

  if (isLoading) {
    return <OverlaySpinner />;
  }
  if (error || !data) {
    return <ErrorMessage error={errorMessage} />;
  }

  const isUserSameEmployee = data?._id === user?._id;

  const isAuthorizedToEdit = ability.can('update', AbilitySubjects.USER) || isUserSameEmployee;
  const isAuthorizedToResetPassword = ability.can('manage', AbilitySubjects.RESET_USER_PASSWORD);
  const isAuthorizedToBlock = ability.can('manage', AbilitySubjects.BLOCK_USER);

  const isAuthorizedToAddAttachment =
    ability.can('create', AbilitySubjects.USER_ATTACHMENTS) || isUserSameEmployee;
  const isAuthorizedToDeleteAttachment =
    ability.can('delete', AbilitySubjects.USER_ATTACHMENTS) || isUserSameEmployee;

  const isAuthorizedToViewFinancialData =
    ability.can('read', AbilitySubjects.VIEW_FINANCIAL_REPORTS) || isUserSameEmployee;

  return (
    <Page title={t('global.pages.employeeProfile')}>
      <Container>
        <HeaderBreadcrumbs
          heading={t('global.pages.employeeProfile')}
          links={[
            { name: t('user.info.users'), href: '/app/user' },
            {
              name: `${data.personalDetails.firstName} ${data.personalDetails.lastName}`,
              href: '',
            },
          ]}
          actions={[
            {
              name: t('patient.info.edit'),
              href: `/app/user/edit/${data._id}`,
              icon: <EditIcon />,
              color: 'secondary',
              type: 'Link',
              isVisible: isAuthorizedToEdit,
            },
            {
              name: t('global.moreMenu.reset'),
              icon: <LockResetIcon />,
              color: 'warning',
              type: 'Action',
              onClick: handleResetModal,
              isVisible: isAuthorizedToResetPassword,
            },
            {
              name: t('global.moreMenu.blockUser'),
              icon: <BlockIcon />,
              color: 'error',
              type: 'Action',
              onClick: handleBlockModal,
              isVisible: isAuthorizedToBlock,
            },
          ]}
        />
        <ResetPasswordForm record={data} onClose={handleResetModal} open={openResetModal} />
        <DialogActions
          onClose={handleBlockModal}
          open={openBlockEmployeeModal}
          dialog={`${t(
            'user.blockUser'
          )} ${`${data.personalDetails.firstName} ${data.personalDetails.lastName}`}?`}
          action={blockEmployee}
        />
        <InfoCard
          title={t('user.info.personalinfo')}
          details={{ ...data.personalDetails, idNumber: data.idNumber, email: data.email }}
        />
        <Tabs
          tabsName={TABS_NAMES.EMPLOYEE}
          tabs={[
            {
              label: t('user.info.visits'),
              component: (
                <Card>
                  <Box flexGrow={1}>
                    <VisitsTable
                      employee={data._id}
                      typeOfColumns={VisitsColumnsKeys.Employee}
                      showPageLoading={false}
                      customFilters={[
                        {
                          filterName: FILTERS.DATE_RANGE,
                          component: <DateRangeFilter resetPageAfterFilter={false} />,
                        },
                      ]}
                      status={`${VISIT_STATUS.DONE},${VISIT_STATUS.SENT_TO_CLINIC},${VISIT_STATUS.PENDING},${VISIT_STATUS.CANCELED}`}
                      pageSizes={[10]}
                      serverSidePagination={false}
                    />
                  </Box>
                </Card>
              ),
              icon: <AccountBoxIcon />,
            },
            {
              label: t('global.profile.accounting'),
              component: (
                <Card>
                  <Box flexGrow={1}>
                    <EmployeeAccountingTable
                      employeeId={data._id || ''}
                      employeeVisitRate={data?.visitRate || 0}
                    />
                  </Box>
                </Card>
              ),
              icon: <AccountBoxIcon />,
              isVisible: isAuthorizedToViewFinancialData,
            },
            {
              label: t('global.attachments.header'),
              component: (
                <AttachmentsTable
                  deletePermission={isAuthorizedToDeleteAttachment}
                  uploadPermission={isAuthorizedToAddAttachment}
                  isLoading={isLoading}
                  employee={data}
                />
              ),
              icon: <AttachFileIcon />,
            },
          ]}
        />
      </Container>
    </Page>
  );
};

export default EmployeeProfile;
