// @ts-nocheck
import React, { useEffect, useRef } from 'react';
import { ButtonProps, SxProps, LoadingButton } from '@mui/lab';
import {
  PRODUCTION_CLOUDINARY_CLOUD_NAME,
  PRODUCTION_CLOUDINARY_UPLOAD_PRESET,
  DEVELOPMENT_CLOUDINARY_CLOUD_NAME,
  DEVELOPMENT_CLOUDINARY_UPLOAD_PRESET,
  isProduction,
} from '../common/Constants';
import { IAttachment } from '../interfaces/attachment';
import { useTranslation } from 'react-i18next';
import { Icon } from '@iconify/react';
import { getFileType } from '../utils/cloudinary';

export const imageFormats = ['png', 'jpg', 'jpeg', 'gif', 'svg', 'webp'];
export const documentFormats = ['xlsx', 'doc', 'pdf', 'docx', 'xls', 'csv', 'txt'];

// Configuration for the Cloudinary upload widget
const CloudinaryConfig = {
  cloudName: isProduction ? PRODUCTION_CLOUDINARY_CLOUD_NAME : DEVELOPMENT_CLOUDINARY_CLOUD_NAME,
  uploadPreset: isProduction
    ? PRODUCTION_CLOUDINARY_UPLOAD_PRESET
    : DEVELOPMENT_CLOUDINARY_UPLOAD_PRESET,
  sources: ['local', 'google_drive'],
  multiple: false,
  clientAllowedFormats: [...imageFormats, ...documentFormats],
  theme: 'minimal',
  language: 'he',
  text: {
    menu: {
      files: 'קבצים שלי',
      camera: 'מצלמה',
      gdrive: 'Google Drive',
    },
  },
  showPoweredBy: false,
};

interface IFileUpload {
  sx?: SxProps;
  submitFileHandler: (val: IAttachment) => void; // Assuming IAttachment is defined elsewhere
  isLoading?: boolean;
  filePath?: string;
  disabled?: boolean;
  clientAllowedFormats?: string[];
  size?: ButtonProps['size'];
  multiple?: boolean;
  UploadButton?: React.FC;
}

const FileUpload: React.FC<IFileUpload> = ({
  sx,
  submitFileHandler,
  isLoading = false,
  filePath,
  disabled = false,
  clientAllowedFormats,
  size = 'small',
  multiple = false,
  UploadButton,
}) => {
  const { t } = useTranslation();
  const myWidget = useRef();

  useEffect(() => {
    myWidget.current = window.cloudinary?.createUploadWidget(
      {
        ...CloudinaryConfig,
        folder: filePath,
        clientAllowedFormats: clientAllowedFormats || CloudinaryConfig.clientAllowedFormats,
        multiple,
      },
      async (error, result) => {
        if (!error && result && result.event === 'success') {
          // eslint-disable-next-line camelcase
          const { public_id, format, url, original_filename } = result.info;
          const fileFormat = getFileType({ public_id, format });
          await submitFileHandler({
            url,
            format: fileFormat,
            publicId: public_id,
            fileName: original_filename,
          });
        }
      }
    );

    return () => {
      // here was the cause for scroll bug
      myWidget.current = null;
    };
  }, []);

  const onOpenFileExplorer = () => {
    if (myWidget.current) {
      myWidget.current.open();
    }
  };

  if (UploadButton) return <UploadButton onClick={onOpenFileExplorer} />;

  return (
    <LoadingButton
      disabled={disabled}
      sx={sx}
      color="secondary"
      variant="contained"
      size={size}
      onClick={onOpenFileExplorer}
      loading={isLoading}
      endIcon={<Icon icon="eva:cloud-upload-fill" />}
    >
      {t('global.attachments.upload')}
    </LoadingButton>
  );
};

export default FileUpload;
