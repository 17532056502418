import { Grid, MenuItem, SxProps, Typography } from '@mui/material';
import { FastField as Field } from 'formik';
import { TextField } from 'formik-mui';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { IField, IOption } from '../../..//common/HMA';
import FormikSelect from '../../../components/FormikSelect';
import { StyledGrid } from '../../../styles/FormFieldsStyles';

const sxFormControl: SxProps = {
  width: '100%',
};

interface GenericFieldProps {
  id: string;
  fields: Array<IField>;
  header?: string;
  subHeader?: string;
  sx?: Object;
}

const GenericField = ({ id, fields, header, subHeader, sx }: GenericFieldProps) => {
  const { t } = useTranslation();

  return (
    <div id={id}>
      {header && (
        <Typography variant="subtitle1" gutterBottom align="left">
          {header}
        </Typography>
      )}
      {subHeader && (
        <Typography variant="subtitle2" gutterBottom align="left">
          {subHeader}
        </Typography>
      )}
      <StyledGrid container spacing={2} id={`${id}_Container`} sx={sx}>
        {fields.map((field) => {
          const defaultSmallScreenSize: number = field.smallSize || 12;
          const helperText = field.helperText ? t(`${field.helperText}`) : '';
          switch (field.type) {
            case 'select':
              return (
                <Grid
                  item
                  xs={defaultSmallScreenSize}
                  md={field.largeSize}
                  lg={field.largeSize}
                  key={field.name}
                >
                  <FormikSelect
                    id={`${id}_${field.name}`}
                    name={`${field.name}`}
                    label={
                      field.number ? `${field.number}- ${t(`${field.label}`)}` : t(`${field.label}`)
                    }
                    formControl={sxFormControl}
                    formHelperText={{ children: helperText }}
                  >
                    {field.options?.map((option: IOption) => {
                      return (
                        <MenuItem key={`${option.value}_${field.name}`} value={option.value}>
                          {!option.label
                            ? typeof option.value != 'string'
                              ? option.value
                              : t(`global.form.select.${option.value}`)
                            : typeof option.label != 'string'
                            ? option.label
                            : t(`global.form.select.${option.label}`)}
                        </MenuItem>
                      );
                    })}
                  </FormikSelect>
                </Grid>
              );
            case 'text':
              return (
                <Grid
                  item
                  xs={defaultSmallScreenSize}
                  md={field.largeSize}
                  lg={field.largeSize}
                  key={field.name}
                >
                  <Field
                    id={`${id}_${field.name}`}
                    fullWidth
                    rows={field.rows}
                    multiline
                    component={TextField}
                    type="text"
                    name={field.name}
                    label={t(`${field.label}`)}
                    helperText={helperText}
                  />
                </Grid>
              );
            case 'textareaWithEditor':
              return (
                <Grid
                  item
                  xs={defaultSmallScreenSize}
                  md={field.largeSize}
                  lg={field.largeSize}
                  key={field.name}
                >
                  <Field
                    id={`${id}_${field.name}`}
                    fullWidth
                    rows={5}
                    multiline
                    component={TextField}
                    type="text"
                    name={field.name}
                    label={t(`${field.label}`)}
                    helperText={helperText}
                  />
                </Grid>
              );
            case 'number':
              return (
                <Grid
                  item
                  xs={defaultSmallScreenSize}
                  md={field.largeSize}
                  lg={field.largeSize}
                  key={field.name}
                >
                  <Field
                    id={`${id}_${field.name}`}
                    fullWidth
                    component={TextField}
                    type="number"
                    name={field.name}
                    label={t(`${field.label}`)}
                    helperText={helperText}
                  />
                </Grid>
              );
            case 'note':
              return (
                <Grid
                  item
                  xs={defaultSmallScreenSize}
                  md={field.largeSize}
                  lg={field.largeSize}
                  key={field.name}
                >
                  <Field
                    id={`${id}_${field.name}`}
                    fullWidth
                    rows={field.rows}
                    multiline
                    component={TextField}
                    type="text"
                    name={field.name}
                    label={`${t(`${field.label}`)} ${t('report.HMKFIRST.remarks')}`}
                    helperText={helperText}
                  />
                </Grid>
              );
          }
        })}
      </StyledGrid>
    </div>
  );
};

export default GenericField;
