/* eslint-disable new-cap */
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ITableBaseSchema } from '../../interfaces/table';
import Table, { ICustomRow } from '../../components/Table';
import { IProgramRef } from '../../interfaces/Models';
import MoreOptions from '../../components/MoreOptions';
import FetcherService from '../../hooks/FetcherService';
import PatientProgramService from '../../services/PatientProgramService';
import DialogActions from '../../components/DialogActions';
import ProgramReferenceNumberForm from './ProgramReferenceNumberForm';
import DeleteIcon from '@mui/icons-material/Delete';
import { theme } from '../../theme';
import { getEditAction } from '../../common/columns/ProgramVisits';

const generateRefsData = ({
  monthRefs,
  month,
  year,
}: {
  monthRefs: string[];
  month: number;
  year: number;
}) => {
  const refsData = monthRefs.map((ref: string, idx: number) => {
    return { ref, index: idx, month, year };
  });

  return refsData;
};
export default function ProgramRefsExpandCard<T extends ITableBaseSchema>({ row }: ICustomRow<T>) {
  const { t } = useTranslation();
  const { program, handleUpdateTable } = row;
  const month: number = row.month || 0;
  const year: number = row.year || 0;
  const monthRefs: string[] = row.refs || [];

  const [record, setRecord] = useState<IProgramRef | null>(null);
  const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false);
  const [openEditModal, setOpenEditModal] = useState<boolean>(false);
  const [loadDelete, setLoadDelete] = useState<boolean>(false);

  const onOpenDeleteModal = (value: IProgramRef) => {
    setRecord(value);
    setOpenDeleteModal(true);
  };

  const onOpenEditForm = (value: IProgramRef) => {
    setRecord(value);
    setOpenEditModal(true);
  };

  const handleCloseModals = () => {
    setOpenDeleteModal(false);
    setOpenEditModal(false);
  };

  const columns = [
    {
      Header: `${t('clinicReferenceNumber')}`,
      accessor: 'ref',
    },
    {
      Header: t('clinic.table.actions'),
      accessor: (val: any) => {
        return (
          <MoreOptions
            actions={[
              getEditAction(onOpenEditForm),
              {
                name: t('global.moreMenu.delete'),
                handler: onOpenDeleteModal,
                icon: <DeleteIcon width={24} height={24} color="error" />,
                variant: theme.palette.error.main,
              },
            ]}
            record={val}
          />
        );
      },
    },
  ];

  const handleDeleteRef = async () => {
    await FetcherService({
      service: PatientProgramService.deleteProgramReference({
        id: String(program?._id),
        payload: record,
      }),
      setLoading: setLoadDelete,
    });
    setOpenDeleteModal(false);
    handleCloseModals();
    handleUpdateTable && handleUpdateTable();
  };

  const data: IProgramRef[] = generateRefsData({ monthRefs, month, year });

  return (
    <>
      <DialogActions
        onClose={handleCloseModals}
        open={openDeleteModal}
        loading={loadDelete}
        dialog={`${t('deleteRefMessage')}`}
        action={handleDeleteRef}
      />
      {record && !!program && !!handleUpdateTable && (
        <ProgramReferenceNumberForm
          program={program}
          openModal={openEditModal}
          programRef={record}
          handleCloseModal={handleCloseModals}
          handleProgramUpdate={handleUpdateTable}
        />
      )}
      <Table
        columns={columns}
        data={data}
        count={data.length}
        isLoading={false}
        showPagination={false}
        showIndex
      />
    </>
  );
}
