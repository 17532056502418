/* eslint-disable new-cap */
import React from 'react';
import Form from '../../components/Form/FormikForm';
import FormModal from '../../components/Modals/FormModal';
import ResetPasswordFields from './ResetPasswordFields';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import FetcherService from '../../hooks/FetcherService';
import UserService from '../../services/UserService';
import { toast } from 'react-toastify';
import { IResetEmployeePasswordCredentials, IUser } from '../../interfaces/Models';

interface IResetPasswordForm {
  record: any;
  onClose: () => void;
  open: boolean;
}
const ResetPasswordForm: React.FC<IResetPasswordForm> = ({ record, onClose, open }) => {
  const { t } = useTranslation();

  const initialValues = {
    newPassword: '',
    confirmNewPassword: '',
  };

  const PasswordSchema = Yup.object({
    newPassword: Yup.string()
      .min(6, t('user.form.passwordValidation'))
      .required(t('clinic.form.required')),
    confirmNewPassword: Yup.string()
      .oneOf([Yup.ref('newPassword'), null], t('user.form.matchPasswords'))
      .required(t('clinic.form.required')),
  });

  const submitResetPassword = async (values: IResetEmployeePasswordCredentials) => {
    await FetcherService<IUser>({
      service: UserService.resetEmployeePassword(record._id, values),
      showNotification: false,
    });
    onClose();
    toast.success(t('global.profile.passwordChangeSuccess'));
  };

  return (
    <FormModal open={open} onClose={onClose} text={t('global.moreMenu.reset')}>
      <Form
        onCancel={onClose}
        submit={submitResetPassword}
        initialValues={initialValues}
        validationSchema={PasswordSchema}
        cancelText={t('clinic.form.cancel')}
        submitText={t('global.moreMenu.reset')}
      >
        <ResetPasswordFields />
      </Form>
    </FormModal>
  );
};

export default ResetPasswordForm;
