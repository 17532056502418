/* eslint-disable new-cap */
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useParams, useOutletContext } from 'react-router-dom';
import * as Yup from 'yup';
import SectionDivider from '../../../components/SectionDivider';
import { HMSFirstValues } from '../../../common/reportsFieldsValues/hms/HMSFirstValues';
import HMSFirstFields from './fields/HMSFirstFields';
import ReportsForm from '../../../components/Form/ReportsForm';

interface HMSProps {
  reportName?: string;
}
const HMSFirst = ({ reportName }: HMSProps) => {
  const { t } = useTranslation();
  const { visitId } = useParams();
  const data: any = useOutletContext();

  const initialValues =
    data && data?.name
      ? data
      : {
          name: reportName ? reportName : 'empty',
          visit: visitId,
          data: data ? data : HMSFirstValues,
        };
  const ReportSchema = Yup.object({
    name: Yup.string().required(t('clinic.form.required')),
    visit: Yup.string().required(t('clinic.form.required')),
    data: Yup.object().shape({
      socioeconomicBackground: Yup.string().required(t('clinic.form.required')),
      socialSituation: Yup.string().required(t('clinic.form.required')),
      cognitiveStatus: Yup.string().required(t('clinic.form.required')),
      socialSecurity: Yup.string().required(t('clinic.form.required')),
      expectations: Yup.string().required(t('clinic.form.required')),
      workerInterventions: Yup.string().required(t('clinic.form.required')),
    }),
  });

  const HtmlId = reportName ? reportName : data?.name || 'empty';

  return (
    <ReportsForm
      initialValues={initialValues}
      visitId={visitId || ''}
      validationSchema={ReportSchema}
      resetForm={data && !data.name ? true : false}
      resetFormValues={data && !data.name ? { ...initialValues, data: HMSFirstValues } : {}}
    >
      <HMSFirstFields id={HtmlId} />
      <SectionDivider />
    </ReportsForm>
  );
};

export default HMSFirst;
