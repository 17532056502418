import React from 'react';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin, { DateClickArg } from '@fullcalendar/interaction';
import { CalenderProps, EventDropData } from './Calender';
import { useTranslation } from 'react-i18next';
import { EventClickArg, EventDropArg } from '@fullcalendar/core';
import multiMonthPlugin from '@fullcalendar/multimonth';

interface MultiMonthCalendarProps extends CalenderProps {
  duration?: number;
  isMonthsScrollable?: boolean;
  monthsViewDivider?: boolean;
  initialDate?: Date;
}
export default function MultiMonthCalendar({
  events,
  duration,
  onOpenDate,
  onOpenEvent,
  isMonthsScrollable = false,
  monthsViewDivider = false,
  initialDate,
  editable = false,
  onEventDrop,
}: MultiMonthCalendarProps) {
  const { t } = useTranslation();
  const handleDateClick = (arg: DateClickArg) => {
    if (onOpenDate) {
      onOpenDate(arg.date);
    }
  };

  const handleEventClick = (arg: EventClickArg) => {
    if (onOpenEvent) {
      onOpenEvent(arg);
    }
  };

  const handleEventDrop = async (data: EventDropData) => {
    if (onEventDrop) {
      await onEventDrop(data);
    }
  };

  const calendarHeight = duration && !isMonthsScrollable ? `${duration * 150}vh` : undefined;
  const initialView = monthsViewDivider ? 'multiMonthYear' : 'dayGrid';
  const mainPlugin = monthsViewDivider ? multiMonthPlugin : dayGridPlugin;
  return (
    <FullCalendar
      initialView={initialView}
      plugins={[mainPlugin, interactionPlugin]}
      duration={{ months: isMonthsScrollable ? 3 : duration || 1 }}
      multiMonthMaxColumns={monthsViewDivider ? 1 : undefined}
      dateClick={handleDateClick}
      eventClick={handleEventClick}
      weekends={true}
      events={events}
      initialDate={initialDate || new Date()}
      locale={'he'}
      direction={'rtl'}
      buttonText={{
        today: t('global.calendar.today'),
      }}
      height={calendarHeight}
      editable={editable}
      eventDrop={(arg: EventDropArg) => {
        const oldStart = arg.oldEvent.start;
        const newStart = arg.event.start;
        const eventId = arg.event.id;
        if (oldStart && newStart) {
          handleEventDrop({ oldStartDate: oldStart, newStartDate: newStart, eventId });
        }
      }}
    />
  );
}
