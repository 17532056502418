import React from 'react';
import { useTranslation } from 'react-i18next';
import { Typography } from '@mui/material';
import GenericField from '../../sharedFields/GenericField';
import { nutritionalHistoryFields, supplementsFields } from '../../../../common/HMDFirst';
import EatingOrderFields from './EatingOrderFields';

const NutritionalConsumptionFields = () => {
  const { t } = useTranslation();
  const id = 'HMDFirst_NutritionalConsumption';

  return (
    <div id={id}>
      <Typography variant="subtitle1" gutterBottom align="left">
        {t('report.HMDFIRST.nutritionalConsumption.header')}
      </Typography>
      <GenericField
        id={`${id}_NutritionalHistory`}
        subHeader={t('report.HMDFIRST.nutritionalConsumption.nutritionalHistory.header')}
        fields={nutritionalHistoryFields}
      />
      <EatingOrderFields />
      <GenericField
        id={`${id}_CompletionHistory`}
        fields={[
          {
            name: 'data.nutritionalConsumption.completionHistory',
            label: 'report.HMDFIRST.nutritionalConsumption.completionHistory.header',
            helperText: 'report.HMDFIRST.nutritionalConsumption.completionHistory.helperText',
            type: 'textareaWithEditor',
          },
          {
            name: 'data.nutritionalConsumption.drink',
            label: 'report.HMDFIRST.nutritionalConsumption.drink.header',
            helperText: 'report.HMDFIRST.nutritionalConsumption.drink.helperText',
            type: 'textareaWithEditor',
          },
        ]}
      />

      <GenericField
        id={`${id}_Supplements`}
        subHeader={t('report.HMDFIRST.nutritionalConsumption.supplements.header')}
        fields={supplementsFields}
      />
      <GenericField
        id={`${id}_Drink`}
        fields={[
          {
            name: 'data.nutritionalConsumption.notes',
            label: 'report.HMDFIRST.nutritionalConsumption.notes',
            type: 'textareaWithEditor',
          },
        ]}
      />
    </div>
  );
};

export default NutritionalConsumptionFields;
