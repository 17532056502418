export interface IOption {
  value: string | number;
  label?: string | number;
}

export interface IField {
  name: string;
  type: 'select' | 'text' | 'number' | 'note' | 'textareaWithEditor';
  label: string;
  options?: IOption[];
  smallSize?: number;
  largeSize?: number;
  rows?: number;
  helperText?: string;
  number?: number;
}

const yesNoFallOptions = [
  {
    value: 'yes',
    label: 'yesFall',
  },
  {
    value: 'no',
    label: 'noFall',
  },
];

const iadlOptions = [
  {
    value: 0,
    label: 'IADL.depends',
  },
  {
    value: 1,
    label: 'IADL.partial',
  },
  {
    value: 2,
    label: 'IADL.independent',
  },
];

const adlOptions = [
  {
    value: 0,
    label: 'ADL.help',
  },
  {
    value: 5,
    label: 'ADL.partial',
  },
  {
    value: 10,
    label: 'ADL.independent',
  },
];

const adlTransfersOptions = [
  {
    value: 0,
    label: 'adlTransitions.notIndependent',
  },
  {
    value: 5,
    label: 'adlTransitions.helpTransfers',
  },
  {
    value: 10,
    label: 'adlTransitions.minimalSupport',
  },
  {
    value: 15,
    label: 'adlTransitions.independent',
  },
];

const stoolControlOptions = [
  {
    value: 0,
    label: 'special.lack',
  },
  {
    value: 5,
    label: 'special.partial',
  },
  {
    value: 10,
    label: 'special.full',
  },
];

export const yesNo = [
  {
    value: 'yes',
    label: 'yes',
  },
  {
    value: 'no',
    label: 'no',
  },
];

const personalGardenOptions = [
  {
    value: 0,
    label: 'ADL.help',
  },
  {
    value: 5,
    label: 'IADL.employed',
  },
];

export const treatmentPlanFields: IField[] = [
  {
    name: 'data.treatmentPlan.pain',
    label: 'report.HMAFIRST.treatmentPlan.pain',
    type: 'select',
    options: yesNo,
    smallSize: 12,
    largeSize: 6,
  },
  {
    name: 'data.treatmentPlan.painNote',
    label: 'report.HMAFIRST.treatmentPlan.pain',
    type: 'note',
    smallSize: 12,
    largeSize: 6,
  },
  {
    name: 'data.treatmentPlan.tiredness',
    label: 'report.HMAFIRST.treatmentPlan.tiredness',
    type: 'select',
    options: yesNo,
    smallSize: 12,
    largeSize: 6,
  },
  {
    name: 'data.treatmentPlan.tirednessNote',
    label: 'report.HMAFIRST.treatmentPlan.tiredness',
    type: 'note',
    smallSize: 12,
    largeSize: 6,
  },
  {
    name: 'data.treatmentPlan.nausea',
    label: 'report.HMAFIRST.treatmentPlan.nausea',
    type: 'select',
    options: yesNo,
    smallSize: 12,
    largeSize: 6,
  },
  {
    name: 'data.treatmentPlan.nauseaNote',
    label: 'report.HMAFIRST.treatmentPlan.nausea',
    type: 'note',
    smallSize: 12,
    largeSize: 6,
  },
  {
    name: 'data.treatmentPlan.depression',
    label: 'report.HMAFIRST.treatmentPlan.depression',
    type: 'select',
    options: yesNo,
    smallSize: 12,
    largeSize: 6,
  },
  {
    name: 'data.treatmentPlan.depressionNote',
    label: 'report.HMAFIRST.treatmentPlan.depression',
    type: 'note',
    smallSize: 12,
    largeSize: 6,
  },
  {
    name: 'data.treatmentPlan.anxiety',
    label: 'report.HMAFIRST.treatmentPlan.anxiety',
    type: 'select',
    options: yesNo,
    smallSize: 12,
    largeSize: 6,
  },
  {
    name: 'data.treatmentPlan.anxietyNote',
    label: 'report.HMAFIRST.treatmentPlan.anxiety',
    type: 'note',
    smallSize: 12,
    largeSize: 6,
  },
  {
    name: 'data.treatmentPlan.neighborhoods',
    label: 'report.HMAFIRST.treatmentPlan.neighborhoods',
    type: 'select',
    options: yesNo,
    smallSize: 12,
    largeSize: 6,
  },
  {
    name: 'data.treatmentPlan.neighborhoodsNote',
    label: 'report.HMAFIRST.treatmentPlan.neighborhoods',
    type: 'note',
    smallSize: 12,
    largeSize: 6,
  },
  {
    name: 'data.treatmentPlan.appetite',
    label: 'report.HMAFIRST.treatmentPlan.appetite',
    type: 'select',
    options: yesNo,
    smallSize: 12,
    largeSize: 6,
  },
  {
    name: 'data.treatmentPlan.appetiteNote',
    label: 'report.HMAFIRST.treatmentPlan.appetite',
    type: 'note',
    smallSize: 12,
    largeSize: 6,
  },
  {
    name: 'data.treatmentPlan.eneralFeeling',
    label: 'report.HMAFIRST.treatmentPlan.eneralFeeling',
    type: 'select',
    options: yesNo,
    smallSize: 12,
    largeSize: 6,
  },
  {
    name: 'data.treatmentPlan.eneralFeelingNote',
    label: 'report.HMAFIRST.treatmentPlan.eneralFeeling',
    type: 'note',
    smallSize: 12,
    largeSize: 6,
  },
  {
    name: 'data.treatmentPlan.breath',
    label: 'report.HMAFIRST.treatmentPlan.breath',
    type: 'select',
    options: yesNo,
    smallSize: 12,
    largeSize: 6,
  },
  {
    name: 'data.treatmentPlan.breathNote',
    label: 'report.HMAFIRST.treatmentPlan.breath',
    type: 'note',
    smallSize: 12,
    largeSize: 6,
  },
];

export const adlFields: IField[] = [
  {
    name: 'data.functionalAssessment.ADL.eatingDrinking',
    label: 'report.HMAFIRST.ADL.eatingDrinking',
    type: 'select',
    options: adlOptions,
    smallSize: 6,
    largeSize: 4,
  },
  {
    name: 'data.functionalAssessment.ADL.bathing',
    label: 'report.HMAFIRST.ADL.bathing',
    type: 'select',
    options: adlOptions,
    smallSize: 6,
    largeSize: 4,
  },
  {
    name: 'data.functionalAssessment.ADL.clothing',
    label: 'report.HMAFIRST.ADL.clothing',
    type: 'select',
    options: adlOptions,
    smallSize: 6,
    largeSize: 4,
  },
  {
    name: 'data.functionalAssessment.ADL.personalGarden',
    label: 'report.HMAFIRST.ADL.personalGarden',
    type: 'select',
    options: personalGardenOptions,
    smallSize: 6,
    largeSize: 4,
  },
  {
    name: 'data.functionalAssessment.ADL.urineControl',
    label: 'report.HMAFIRST.ADL.urineControl',
    type: 'select',
    options: adlOptions,
    smallSize: 6,
    largeSize: 4,
  },
  {
    name: 'data.functionalAssessment.ADL.stoolControl',
    label: 'report.HMAFIRST.ADL.stoolControl',
    type: 'select',
    options: stoolControlOptions,
    smallSize: 6,
    largeSize: 4,
  },
  {
    name: 'data.functionalAssessment.ADL.transitions',
    label: 'report.HMAFIRST.ADL.transitions',
    type: 'select',
    options: adlTransfersOptions,
    smallSize: 6,
    largeSize: 6,
  },
  {
    name: 'data.functionalAssessment.ADL.mobility',
    label: 'report.HMAFIRST.ADL.mobility',
    type: 'select',
    options: adlOptions,
    smallSize: 6,
    largeSize: 6,
  },
  {
    name: 'data.functionalAssessment.ADLNote',
    label: 'report.HMKFIRST.remarks',
    type: 'text',
    rows: 2,
    smallSize: 12,
    largeSize: 12,
  },
];

export const iadlFields: IField[] = [
  {
    name: 'data.functionalAssessment.IADL.household',
    label: 'report.HMAFIRST.IADL.household',
    type: 'select',
    options: iadlOptions,
    smallSize: 6,
    largeSize: 4,
  },
  {
    name: 'data.functionalAssessment.IADL.cooking',
    label: 'report.HMAFIRST.IADL.cooking',
    type: 'select',
    options: iadlOptions,
    smallSize: 6,
    largeSize: 4,
  },
  {
    name: 'data.functionalAssessment.IADL.travel',
    label: 'report.HMAFIRST.IADL.travel',
    type: 'select',
    options: iadlOptions,
    smallSize: 6,
    largeSize: 4,
  },
  {
    name: 'data.functionalAssessment.IADL.takingMedications',
    label: 'report.HMAFIRST.IADL.takingMedications',
    type: 'select',
    options: iadlOptions,
    smallSize: 6,
    largeSize: 4,
  },
  {
    name: 'data.functionalAssessment.IADL.financeManagement',
    label: 'report.HMAFIRST.IADL.financeManagement',
    type: 'select',
    options: iadlOptions,
    smallSize: 6,
    largeSize: 4,
  },
  {
    name: 'data.functionalAssessment.IADL.shopping',
    label: 'report.HMAFIRST.IADL.shopping',
    type: 'select',
    options: iadlOptions,
    smallSize: 6,
    largeSize: 4,
  },
  {
    name: 'data.functionalAssessment.IADL.phones',
    label: 'report.HMAFIRST.IADL.phones',
    type: 'select',
    options: iadlOptions,
    smallSize: 12,
    largeSize: 12,
  },
  {
    name: 'data.functionalAssessment.IADLNote',
    label: 'report.HMKFIRST.remarks',
    type: 'text',
    rows: 2,
    smallSize: 12,
    largeSize: 12,
  },
];

export const fallsfields: IField[] = [
  {
    name: 'data.falls.fallInYear',
    label: 'report.HMAFIRST.falls.fallInYear',
    type: 'select',
    options: yesNo,
    smallSize: 12,
    largeSize: 6,
  },
  {
    name: 'data.falls.numberOfFalls',
    label: 'report.HMAFIRST.falls.numberOfFalls',
    type: 'number',
    rows: 1,
    smallSize: 12,
    largeSize: 6,
  },
  {
    name: 'data.falls.attention',
    label: 'report.HMAFIRST.falls.attention',
    type: 'select',
    options: yesNo,
    smallSize: 12,
    largeSize: 6,
  },
  {
    name: 'data.falls.remarks',
    label: 'report.HMAFIRST.falls.remarks',
    type: 'text',
    rows: 1,
    smallSize: 12,
    largeSize: 6,
  },
  {
    name: 'data.falls.dizziness',
    label: 'report.HMAFIRST.falls.dizziness',
    type: 'select',
    options: yesNo,
    smallSize: 12,
    largeSize: 6,
  },
  {
    name: 'data.falls.afraidOfFalls',
    label: 'report.HMAFIRST.falls.afraidOfFalls',
    type: 'select',
    options: yesNo,
    smallSize: 12,
    largeSize: 6,
  },
  {
    name: 'data.falls.estimate',
    label: 'report.HMAFIRST.falls.estimate',
    type: 'select',
    options: yesNoFallOptions,
    smallSize: 12,
    largeSize: 12,
  },
];

export const treatmentNursingInterventionFields: IField[] = [
  {
    name: 'data.nursingIntervention',
    label: 'report.HMAFIRST.nursingIntervention',
    type: 'text',
    rows: 4,
    smallSize: 12,
    largeSize: 12,
  },
  {
    name: 'data.treatment',
    label: 'report.HMAFIRST.treatment',
    type: 'text',
    rows: 4,
    smallSize: 12,
    largeSize: 12,
  },
];

const physicalConditionOptions = [
  {
    value: 1,
    label: 'norton.bad',
  },
  {
    value: 2,
    label: 'norton.notGood',
  },
  {
    value: 3,
    label: 'norton.likely',
  },
  {
    value: 4,
    label: 'norton.good',
  },
];

const consciousnessOptions = [
  {
    value: 1,
    label: 'norton.unconscious',
  },
  {
    value: 2,
    label: 'norton.vagueness',
  },
  {
    value: 3,
    label: 'norton.slightConfusion',
  },
  {
    value: 4,
    label: 'norton.conscious',
  },
];

const mobilityOptions = [
  {
    value: 1,
    label: 'norton.notMobile',
  },
  {
    value: 2,
    label: 'norton.fullHelp',
  },
  {
    value: 3,
    label: 'norton.easyHelp',
  },
  {
    value: 4,
    label: 'independent',
  },
];

const operationsOptions = [
  {
    value: 1,
    label: 'norton.independent',
  },
  {
    value: 2,
    label: 'greatHelp',
  },
  {
    value: 3,
    label: 'easyHelp',
  },
  {
    value: 4,
    label: 'independent',
  },
];

const controlingOfExcretionOptions = [
  {
    value: 1,
    label: 'norton.fecesControl',
  },
  {
    value: 2,
    label: 'norton.urineControl',
  },
  {
    value: 3,
    label: 'norton.partialControl',
  },
  {
    value: 4,
    label: 'norton.fullControl',
  },
];

const oneOptions = [
  {
    value: 0,
    label: 0,
  },
  {
    value: 1,
    label: 1,
  },
];

const twoOptions = [
  ...oneOptions,
  {
    value: 2,
    label: 2,
  },
];

const threeOptions = [
  ...twoOptions,
  {
    value: 3,
    label: 3,
  },
];

const fiveOptions = [
  ...threeOptions,
  {
    value: 4,
    label: 4,
  },
  {
    value: 5,
    label: 5,
  },
];

const sixOptions = [
  ...fiveOptions,
  {
    value: 6,
    label: 6,
  },
];

export const nortonfields: IField[] = [
  {
    name: 'data.nortonEstimate.physicalCondition',
    label: 'report.HMAFIRST.nortonEstimate.physicalCondition.label',
    helperText: 'report.HMAFIRST.nortonEstimate.physicalCondition.helperText',
    type: 'select',
    options: physicalConditionOptions,
    smallSize: 12,
    largeSize: 6,
  },
  {
    name: 'data.nortonEstimate.consciousness',
    label: 'report.HMAFIRST.nortonEstimate.consciousness.label',
    helperText: 'report.HMAFIRST.nortonEstimate.consciousness.helperText',
    type: 'select',
    options: consciousnessOptions,
    smallSize: 12,
    largeSize: 6,
  },
  {
    name: 'data.nortonEstimate.mobility',
    label: 'report.HMAFIRST.nortonEstimate.mobility.label',
    helperText: 'report.HMAFIRST.nortonEstimate.mobility.helperText',
    type: 'select',
    options: mobilityOptions,
    smallSize: 12,
    largeSize: 6,
  },
  {
    name: 'data.nortonEstimate.operations',
    label: 'report.HMAFIRST.nortonEstimate.operations.label',
    helperText: 'report.HMAFIRST.nortonEstimate.operations.helperText',
    type: 'select',
    options: operationsOptions,
    smallSize: 12,
    largeSize: 6,
  },
  {
    name: 'data.nortonEstimate.controlingOfExcretion',
    label: 'report.HMAFIRST.nortonEstimate.controlingOfExcretion.label',
    helperText: 'report.HMAFIRST.nortonEstimate.controlingOfExcretion.helperText',
    type: 'select',
    options: controlingOfExcretionOptions,
    smallSize: 12,
    largeSize: 12,
  },
  {
    name: 'data.nortonEstimateRemarks',
    label: 'report.HMKFIRST.remarks',
    type: 'text',
    rows: 2,
    smallSize: 12,
    largeSize: 12,
  },
];

// test options to be provided later
export const testsFields: IField[] = [
  {
    name: 'data.testFeatures.tests.time',
    label: 'report.HMAFIRST.testFeatures.tests.time',
    type: 'select',
    options: fiveOptions,
    smallSize: 12,
    largeSize: 6,
  },
  {
    name: 'data.testFeatures.tests.place',
    label: 'report.HMAFIRST.testFeatures.tests.place',
    type: 'select',
    options: fiveOptions,
    smallSize: 12,
    largeSize: 6,
  },
  {
    name: 'data.testFeatures.tests.attention',
    label: 'report.HMAFIRST.testFeatures.tests.attention',
    type: 'select',
    options: fiveOptions,
    smallSize: 12,
    largeSize: 6,
  },
  {
    name: 'data.testFeatures.tests.memory',
    label: 'report.HMAFIRST.testFeatures.tests.memory',
    type: 'select',
    options: sixOptions,
    smallSize: 12,
    largeSize: 6,
  },
  {
    name: 'data.testFeatures.tests.objects',
    label: 'report.HMAFIRST.testFeatures.tests.objects',
    type: 'select',
    options: twoOptions,
    smallSize: 12,
    largeSize: 6,
  },
  {
    name: 'data.testFeatures.tests.sentence',
    label: 'report.HMAFIRST.testFeatures.tests.sentence',
    type: 'select',
    options: oneOptions,
    smallSize: 12,
    largeSize: 6,
  },
  {
    name: 'data.testFeatures.tests.instructions',
    label: 'report.HMAFIRST.testFeatures.tests.instructions',
    type: 'select',
    options: threeOptions,
    smallSize: 12,
    largeSize: 6,
  },
  {
    name: 'data.testFeatures.tests.writtenInstructions',
    label: 'report.HMAFIRST.testFeatures.tests.writtenInstructions',
    type: 'select',
    options: oneOptions,
    smallSize: 12,
    largeSize: 6,
  },
  {
    name: 'data.testFeatures.tests.writing',
    label: 'report.HMAFIRST.testFeatures.tests.writing',
    type: 'select',
    options: oneOptions,
    smallSize: 12,
    largeSize: 6,
  },
  {
    name: 'data.testFeatures.tests.drawing',
    label: 'report.HMAFIRST.testFeatures.tests.drawing',
    type: 'select',
    options: oneOptions,
    smallSize: 12,
    largeSize: 6,
  },
  {
    name: 'data.testFeatures.testsNotes',
    label: 'report.HMKFIRST.remarks',
    type: 'text',
    rows: 2,
    smallSize: 12,
    largeSize: 12,
  },
];

const healingProgressOptions = [
  {
    value: 'worsening',
    label: 'worsening',
  },
  {
    value: 'unchanged',
    label: 'unchanged',
  },
  {
    value: 'partialImprovement',
    label: 'partialImprovement',
  },
  {
    value: 'significantImprovement',
    label: 'significantImprovement',
  },
];

const treatmentAadherenceOptions = [
  {
    value: 'understandsImportance',
    label: 'understandsImportance',
  },
  {
    value: 'notUnderstandsImportance',
    label: 'notUnderstandsImportance',
  },
];

const selfCareOptions = [
  {
    value: 'low',
    label: 'low',
  },
  {
    value: 'reasonable',
    label: 'reasonable',
  },
  {
    value: 'high',
    label: 'high',
  },
];

const collaborationOptions = [
  {
    value: 'exists',
    label: 'exists',
  },
  {
    value: 'notExist',
    label: 'notExist',
  },
  {
    value: 'partial',
    label: 'partial',
  },
];

export const getWoundEstimationFieldsValues = () => {
  const values: string[] = [];
  for (let i = 0; i < 28; i++) {
    values.push('');
  }
  return values;
};

const classificationOptions = [
  {
    value: 'withoutClassification',
    label: 'withoutClassification',
  },
  {
    value: 1,
    label: 1,
  },
  {
    value: 2,
    label: 2,
  },
  {
    value: 3,
    label: 3,
  },
  {
    value: 4,
    label: 4,
  },
];

const getVasOpt = () => {
  const vasOptions: IOption[] = [];
  [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((option) => {
    vasOptions.push({
      value: option,
      label: option,
    });
  });
  return vasOptions;
};

export const thereOrNotOptions = [
  {
    value: 'thereIs',
    label: 'thereIs',
  },
  {
    value: 'nothing',
    label: 'nothing',
  },
];

const marginsOptions = [
  {
    value: 'proper',
    label: 'proper',
  },
  {
    value: 'dryness',
    label: 'dryness',
  },
  {
    value: 'masceration',
    label: 'masceration',
  },
];

const temperatureoptions = [
  {
    value: 'proper',
    label: 'proper',
  },
  {
    value: 'hot',
    label: 'hot',
  },
  {
    value: 'cold',
    label: 'cold',
  },
];

const skinDiscolorationOptions = [
  {
    value: 'brownSkin',
    label: 'brownSkin',
  },
  {
    value: 'proper',
    label: 'proper',
  },
  {
    value: 'unchanged',
    label: 'unchanged',
  },
  {
    value: 'pale',
    label: 'pale',
  },
  {
    value: 'celluliteRed',
    label: 'celluliteRed',
  },
  {
    value: 'dandruff',
    label: 'dandruff',
  },
  {
    value: 'atrophic',
    label: 'atrophic',
  },
  {
    value: 'cyanoticBlue',
    label: 'cyanoticBlue',
  },
  {
    value: 'other',
    label: 'other',
  },
];

const tissueTypeOptions = [
  {
    value: 'epitheliumPink',
    label: 'epitheliumPink',
  },
  {
    value: 'deficitRed',
    label: 'deficitRed',
  },
  {
    value: 'yellow',
    label: 'yellow',
  },
  {
    value: 'necrosisBlack',
    label: 'necrosisBlack',
  },
  {
    value: 'excessDeficit',
    label: 'excessDeficit',
  },
];

const affairOptions = [
  {
    value: 'withNo',
    label: 'withNo',
  },
  {
    value: 'serious',
    label: 'serious',
  },
  {
    value: 'bloody',
    label: 'bloody',
  },
  {
    value: 'simulation',
    label: 'simulation',
  },
  {
    value: 'purulent',
    label: 'purulent',
  },
  {
    value: 'other',
    label: 'other',
  },
];

const provisionOptions = [
  {
    value: 'rig',
    label: 'rig',
  },
  {
    value: 'medium',
    label: 'medium',
  },
  {
    value: 'great',
    label: 'great',
  },
  {
    value: 'veryMuch',
    label: 'veryMuch',
  },
];

export const therapeuticInterventionFields: IField[] = [
  {
    name: 'data.woundEstimate.therapeuticIntervention.healingProgress',
    label: 'report.HMAFIRST.woundEstimate.therapeuticIntervention.healingProgress',
    type: 'select',
    options: healingProgressOptions,
    smallSize: 12,
    largeSize: 6,
  },
  {
    name: 'data.woundEstimate.therapeuticIntervention.treatmentAadherence',
    label: 'report.HMAFIRST.woundEstimate.therapeuticIntervention.treatmentAadherence',
    type: 'select',
    options: treatmentAadherenceOptions,
    smallSize: 12,
    largeSize: 6,
  },
  {
    name: 'data.woundEstimate.therapeuticIntervention.selfCare',
    label: 'report.HMAFIRST.woundEstimate.therapeuticIntervention.selfCare',
    type: 'select',
    options: selfCareOptions,
    smallSize: 12,
    largeSize: 6,
  },
  {
    name: 'data.woundEstimate.therapeuticIntervention.collaboration',
    label: 'report.HMAFIRST.woundEstimate.therapeuticIntervention.collaboration',
    type: 'select',
    options: collaborationOptions,
    smallSize: 12,
    largeSize: 6,
  },
  {
    name: 'data.woundEstimate.therapeuticIntervention.replacementFrequency',
    label: 'report.HMAFIRST.woundEstimate.therapeuticIntervention.replacementFrequency',
    type: 'text',
    smallSize: 12,
    largeSize: 12,
  },
];

export const bedSoreFields: IField[] = [
  {
    name: 'data.woundEstimate.bedsore.woundLocation',
    label: 'report.HMAFIRST.woundEstimate.bedsore.woundLocation',
    type: 'text',
    smallSize: 12,
    largeSize: 6,
  },
  {
    name: 'data.woundEstimate.bedsore.classification',
    label: 'report.HMAFIRST.woundEstimate.bedsore.classification',
    type: 'select',
    options: classificationOptions,
    smallSize: 12,
    largeSize: 6,
  },
];

export const painFields: IField[] = [
  {
    name: 'data.woundEstimate.pain.vas',
    label: 'report.HMAFIRST.woundEstimate.pain.vas',
    type: 'select',
    options: getVasOpt(),
    smallSize: 12,
    largeSize: 6,
  },
  {
    name: 'data.woundEstimate.pain.smell',
    label: 'report.HMAFIRST.woundEstimate.pain.smell',
    type: 'select',
    options: thereOrNotOptions,
    smallSize: 12,
    largeSize: 6,
  },
  {
    name: 'data.woundEstimate.pain.channels',
    label: 'report.HMAFIRST.woundEstimate.pain.channels',
    type: 'select',
    options: thereOrNotOptions,
    smallSize: 12,
    largeSize: 6,
  },
  {
    name: 'data.woundEstimate.pain.canal',
    label: 'report.HMAFIRST.woundEstimate.pain.canal',
    type: 'text',
    smallSize: 12,
    largeSize: 6,
  },
  {
    name: 'data.woundEstimate.pain.dryness',
    label: 'report.HMAFIRST.woundEstimate.pain.dryness',
    type: 'select',
    options: thereOrNotOptions,
    smallSize: 12,
    largeSize: 6,
  },
  {
    name: 'data.woundEstimate.pain.margins',
    label: 'report.HMAFIRST.woundEstimate.pain.margins',
    type: 'select',
    options: marginsOptions,
    smallSize: 12,
    largeSize: 6,
  },
  {
    name: 'data.woundEstimate.pain.little',
    label: 'report.HMAFIRST.woundEstimate.pain.little',
    type: 'select',
    options: thereOrNotOptions,
    smallSize: 12,
    largeSize: 6,
  },
  {
    name: 'data.woundEstimate.pain.temperature',
    label: 'report.HMAFIRST.woundEstimate.pain.temperature',
    type: 'select',
    options: temperatureoptions,
    smallSize: 12,
    largeSize: 6,
  },
  {
    name: 'data.woundEstimate.pain.skinDiscoloration',
    label: 'report.HMAFIRST.woundEstimate.pain.skinDiscoloration',
    type: 'select',
    options: skinDiscolorationOptions,
    smallSize: 12,
    largeSize: 6,
  },
  {
    name: 'data.woundEstimate.pain.descriptionOfSkincolor',
    label: 'report.HMAFIRST.woundEstimate.pain.descriptionOfSkincolor',
    type: 'text',
    smallSize: 12,
    largeSize: 6,
  },
  {
    name: 'data.woundEstimate.pain.typeOfWound',
    label: 'report.HMAFIRST.woundEstimate.pain.typeOfWound',
    type: 'select',
    options: skinDiscolorationOptions,
    smallSize: 12,
    largeSize: 6,
  },
  {
    name: 'data.woundEstimate.pain.appearanceOfTheWound',
    label: 'report.HMAFIRST.woundEstimate.pain.appearanceOfTheWound',
    type: 'text',
    smallSize: 12,
    largeSize: 6,
  },
  {
    name: 'data.woundEstimate.pain.dateOfWound',
    label: 'report.HMAFIRST.woundEstimate.pain.dateOfWound',
    type: 'text',
    smallSize: 12,
    largeSize: 6,
  },
  {
    name: 'data.woundEstimate.pain.location',
    label: 'report.HMAFIRST.woundEstimate.pain.location',
    type: 'text',
    smallSize: 12,
    largeSize: 6,
  },
  {
    name: 'data.woundEstimate.pain.width',
    label: 'report.HMAFIRST.woundEstimate.pain.width',
    type: 'text',
    smallSize: 12,
    largeSize: 6,
  },
  {
    name: 'data.woundEstimate.pain.depth',
    label: 'report.HMAFIRST.woundEstimate.pain.depth',
    type: 'text',
    smallSize: 12,
    largeSize: 6,
  },
  {
    name: 'data.woundEstimate.pain.tissueType',
    label: 'report.HMAFIRST.woundEstimate.pain.tissueType',
    type: 'select',
    options: tissueTypeOptions,
    smallSize: 12,
    largeSize: 6,
  },
  {
    name: 'data.woundEstimate.pain.descriptionTissue',
    label: 'report.HMAFIRST.woundEstimate.pain.descriptionTissue',
    type: 'text',
    smallSize: 12,
    largeSize: 6,
  },
  {
    name: 'data.woundEstimate.pain.affair',
    label: 'report.HMAFIRST.woundEstimate.pain.affair',
    type: 'select',
    options: affairOptions,
    smallSize: 12,
    largeSize: 6,
  },
  {
    name: 'data.woundEstimate.pain.provision',
    label: 'report.HMAFIRST.woundEstimate.pain.provision',
    type: 'select',
    options: provisionOptions,
    smallSize: 12,
    largeSize: 6,
  },
  {
    name: 'data.woundEstimate.pain.familySupport',
    label: 'report.HMAFIRST.woundEstimate.pain.familySupport',
    type: 'select',
    options: [...yesNo, { value: 'partial', label: 'partial' }],
    smallSize: 12,
    largeSize: 12,
  },
];

export const phq9SelectOptions = [
  {
    value: 0,
    label: 'phq9.notAtAll',
  },
  {
    value: 1,
    label: 'phq9.numberOfDays',
  },
  {
    value: 2,
    label: 'phq9.halfAllDays',
  },
  {
    value: 3,
    label: 'phq9.almostEveryDay',
  },
];

export const phq9SelectFields: IField[] = [
  {
    name: 'data.PHQ9.problems.difficultyFallingAsleep',
    label: 'report.HMAFIRST.PHQ9.section2.difficultyFallingAsleep',
    type: 'select',
    options: phq9SelectOptions,
    smallSize: 12,
    largeSize: 6,
    number: 3,
  },
  {
    name: 'data.PHQ9.problems.lowEnergy',
    label: 'report.HMAFIRST.PHQ9.section2.lowEnergy',
    type: 'select',
    options: phq9SelectOptions,
    smallSize: 12,
    largeSize: 6,
    number: 4,
  },
  {
    name: 'data.PHQ9.problems.littleAppetiteOrOvereating',
    label: 'report.HMAFIRST.PHQ9.section2.littleAppetiteOrOvereating',
    type: 'select',
    options: phq9SelectOptions,
    smallSize: 12,
    largeSize: 6,
    number: 5,
  },
  {
    name: 'data.PHQ9.problems.feelingBad',
    label: 'report.HMAFIRST.PHQ9.section2.feelingBad',
    type: 'select',
    options: phq9SelectOptions,
    smallSize: 12,
    largeSize: 6,
    helperText: 'report.HMAFIRST.PHQ9.section2.feelingBadHelperText',
    number: 6,
  },
  {
    name: 'data.PHQ9.problems.difficultyConcentrating',
    label: 'report.HMAFIRST.PHQ9.section2.difficultyConcentrating',
    type: 'select',
    options: phq9SelectOptions,
    smallSize: 12,
    largeSize: 6,
    helperText: 'report.HMAFIRST.PHQ9.section2.difficultyConcentratingHelperText',
    number: 7,
  },
  {
    name: 'data.PHQ9.problems.slownessOfMovement',
    label: 'report.HMAFIRST.PHQ9.section2.slownessOfMovement',
    type: 'select',
    options: phq9SelectOptions,
    smallSize: 12,
    largeSize: 6,
    helperText: 'report.HMAFIRST.PHQ9.section2.slownessOfMovementHelperText',
    number: 8,
  },
  {
    name: 'data.PHQ9.problems.suicideThoughts',
    label: 'report.HMAFIRST.PHQ9.section2.suicideThoughts',
    type: 'select',
    options: phq9SelectOptions,
    smallSize: 12,
    largeSize: 12,
    helperText: 'report.HMAFIRST.PHQ9.section2.suicideThoughtsHelperText',
    number: 9,
  },
];

export const difficultiesOnFunctionSelectField: IField[] = [
  {
    name: 'data.PHQ9.difficultiesOnFunction',
    label: 'report.HMAFIRST.PHQ9.section3.difficultiesOnFunction',
    type: 'select',
    options: phq9SelectOptions,
    smallSize: 12,
    largeSize: 12,
    helperText: 'report.HMAFIRST.PHQ9.section3.difficultiesOnFunctionHelperText',
  },
];

export const interventionDepressionSelectField: IField[] = [
  {
    name: 'data.PHQ9.fulfillingBasicNeeds',
    label: 'report.HMAFIRST.PHQ9.section4.InterventionDepression.fulfillingBasicNeeds',
    type: 'select',
    options: yesNo,
    smallSize: 12,
    largeSize: 6,
    helperText:
      'report.HMAFIRST.PHQ9.section4.InterventionDepression.fulfillingBasicNeedsHelperText',
  },
  {
    name: 'data.PHQ9.fulfillingBasicNeedsNote',
    label: 'report.HMAFIRST.PHQ9.section4.InterventionDepression.explain',
    type: 'text',
    smallSize: 12,
    largeSize: 6,
  },
  {
    name: 'data.PHQ9.functionPromotion',
    label: 'report.HMAFIRST.PHQ9.section4.InterventionDepression.functionPromotion',
    type: 'select',
    options: yesNo,
    smallSize: 12,
    largeSize: 6,
    helperText: 'report.HMAFIRST.PHQ9.section4.InterventionDepression.functionPromotionHelperText',
  },
  {
    name: 'data.PHQ9.functionPromotionNote',
    label: 'report.HMAFIRST.PHQ9.section4.InterventionDepression.explain',
    type: 'text',
    smallSize: 12,
    largeSize: 6,
  },
  {
    name: 'data.PHQ9.locatingResilience',
    label: 'report.HMAFIRST.PHQ9.section4.InterventionDepression.locatingResilience',
    type: 'select',
    options: yesNo,
    smallSize: 12,
    largeSize: 6,
    helperText: 'report.HMAFIRST.PHQ9.section4.InterventionDepression.locatingResilienceHelperText',
  },
  {
    name: 'data.PHQ9.locatingResilienceNote',
    label: 'report.HMAFIRST.PHQ9.section4.InterventionDepression.explain',
    type: 'text',
    smallSize: 12,
    largeSize: 6,
  },
  {
    name: 'data.PHQ9.interventionDepression',
    label: 'report.HMAFIRST.PHQ9.section4.InterventionDepression.interventionDepression',
    type: 'select',
    options: yesNo,
    smallSize: 12,
    largeSize: 6,
    helperText:
      'report.HMAFIRST.PHQ9.section4.InterventionDepression.interventionDepressionHelperText',
  },
  {
    name: 'data.PHQ9.interventionDepressionNote',
    label: 'report.HMAFIRST.PHQ9.section4.InterventionDepression.explain',
    type: 'text',
    smallSize: 12,
    largeSize: 6,
  },
];

export const suicidalInterventionSelectField: IField[] = [
  {
    name: 'data.PHQ9.ImmediateReporting',
    label: 'report.HMAFIRST.PHQ9.section4.suicidalIntervention.ImmediateReporting',
    type: 'select',
    options: yesNo,
    smallSize: 12,
    largeSize: 6,
    helperText: 'report.HMAFIRST.PHQ9.section4.suicidalIntervention.ImmediateReportingHelperText',
  },
  {
    name: 'data.PHQ9.therapeuticContract',
    label: 'report.HMAFIRST.PHQ9.section4.suicidalIntervention.therapeuticContract',
    type: 'select',
    options: yesNo,
    smallSize: 12,
    largeSize: 6,
    helperText: 'report.HMAFIRST.PHQ9.section4.suicidalIntervention.therapeuticContractHelperText',
  },
  {
    name: 'data.PHQ9.securityOfThePatient',
    label: 'report.HMAFIRST.PHQ9.section4.suicidalIntervention.securityOfThePatient',
    type: 'select',
    options: yesNo,
    smallSize: 12,
    largeSize: 12,
    helperText: 'report.HMAFIRST.PHQ9.section4.suicidalIntervention.securityOfThePatientHelperText',
  },
];

export const careProviderSelectField: IField[] = [
  {
    name: 'data.PHQ9.iranianFactor',
    label: 'report.HMAFIRST.PHQ9.section4.careProvider.iranianFactor',
    type: 'select',
    options: yesNo,
    smallSize: 12,
    largeSize: 6,
  },
  {
    name: 'data.PHQ9.districtFactor',
    label: 'report.HMAFIRST.PHQ9.section4.careProvider.districtFactor',
    type: 'select',
    options: yesNo,
    smallSize: 12,
    largeSize: 6,
  },
  {
    name: 'data.PHQ9.familyDoctor',
    label: 'report.HMAFIRST.PHQ9.section4.careProvider.familyDoctor',
    type: 'select',
    options: yesNo,
    smallSize: 12,
    largeSize: 6,
  },
  {
    name: 'data.PHQ9.attorney',
    label: 'report.HMAFIRST.PHQ9.section4.careProvider.attorney',
    type: 'select',
    options: yesNo,
    smallSize: 12,
    largeSize: 6,
  },
];
