import React from 'react';
import PageHeader from '../../components/PageHeader';
import { Box, Card, Container, Stack } from '@mui/material';
import Page from '../../components/Page';
import { useTranslation } from 'react-i18next';
import DateRangeFilter from '../../components/DateRangeFilter';
import { ReportCards } from '../../sections/systemReports/SystemReportItem';
import { AbilitySubjects } from '../../casl/ability';
import useAccess from '../../hooks/useAccess';

const reportsData = [
  {
    title: 'systemReports.patientGeneralInformation',
    downloadUrl: '/api/patient/report/general-information/download',
  },
  {
    title: 'systemReports.employeeGeneralInformation',
    downloadUrl: '/api/user/report/general-information/download',
    dataByDateRange: false,
  },
  {
    title: 'systemReports.patients',
    downloadUrl: '/api/patient/report/basic-information/download',
  },
  {
    title: 'systemReports.releasePatient',
    downloadUrl: '/api/patient/report/release/download',
  },
  {
    title: 'systemReports.visitsReport',
    downloadUrl: '/api/visit/report/download',
  },
];

const SystemReports: React.FC = () => {
  const { t } = useTranslation();
  useAccess('read', AbilitySubjects.VIEW_FINANCIAL_REPORTS);

  return (
    <>
      <Page title={t('systemReports.header')}>
        <Container>
          <PageHeader
            pageTitle={t('systemReports.header')}
            pageFilter={
              <Box>
                <DateRangeFilter />
              </Box>
            }
          />
          <Card sx={{ p: 3, mb: 3 }}>
            <Stack
              width={'100%'}
              direction={'row'}
              alignItems={'center'}
              alignContent={'space-between'}
            >
              <ReportCards reports={reportsData} />
            </Stack>
          </Card>
        </Container>
      </Page>
    </>
  );
};

export default SystemReports;
