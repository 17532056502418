import { Button, IconButton, Tooltip } from '@mui/material';
import React from 'react';

interface Props {
  icon: JSX.Element;
  label: string;
  onClick: () => void;
}
export default function TableActionButton(props: Props) {
  const { icon, label, onClick } = props;
  return (
    <Tooltip title={label}>
      <IconButton onClick={onClick}>{icon}</IconButton>
    </Tooltip>
  );
}
