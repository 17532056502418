// ----------------------------------------------------------------------

import { Theme } from '@mui/material/styles';

export default function Tab(theme: Theme) {
  return {
    MuiTab: {
      styleOverrides: {
        root: {
          minHeight: theme.spacing(3.5),
        },
      },
    },
  };
}
