import { IQuery, IService } from '../interfaces/index';
import { HTTP_METHODS } from '../common/APIHelper';

const BASE_PATH = '/api/statistics';

const getProgramsChartData = (query?: IQuery): IService => {
  return { route: `${BASE_PATH}/programs`, method: HTTP_METHODS.GET, query };
};

const getVisitsChartsData = (query?: IQuery): IService => {
  return { route: `${BASE_PATH}/visits`, method: HTTP_METHODS.GET, query };
};

const getClinicPatientsChartData = (query?: IQuery): IService => {
  return { route: `${BASE_PATH}/clinics-patients`, method: HTTP_METHODS.GET, query };
};

const getClinicsFinancialReports = (query?: IQuery): IService => {
  return { route: `${BASE_PATH}/clinics-reports`, method: HTTP_METHODS.GET, query };
};

const getEmployeesFinancialChartData = (query?: IQuery): IService => {
  return { route: `${BASE_PATH}/employees-reports`, method: HTTP_METHODS.GET, query };
};
const getProgramTypesChartData = (query?: IQuery): IService => {
  return { route: `${BASE_PATH}/program-types`, method: HTTP_METHODS.GET, query };
};
const getProgramsStatusCount = (query?: IQuery): IService => {
  return { route: `${BASE_PATH}/program-status`, method: HTTP_METHODS.GET, query };
};

const StatisticsService = {
  getClinicsFinancialReports,
  getProgramsChartData,
  getVisitsChartsData,
  getClinicPatientsChartData,
  getEmployeesFinancialChartData,
  getProgramTypesChartData,
  getProgramsStatusCount,
};

export default StatisticsService;
