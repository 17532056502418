import { Helmet } from 'react-helmet-async';
import { forwardRef, ReactElement } from 'react';
import { Box } from '@mui/material';

interface PageProps {
  title?: string;
  children: ReactElement;
  sx?: Object;
}
const Page: React.FC<PageProps> = forwardRef(({ title = '', sx, children, ...other }, ref) => (
  <Box ref={ref} {...other} sx={sx}>
    <Helmet>
      <title>{`HMC | ${title}`}</title>
    </Helmet>
    {children}
  </Box>
));

export default Page;
