import { Grid, MenuItem, Typography } from '@mui/material';
import { t } from 'i18next';
import React from 'react';
import { FastField as Field } from 'formik';
import { StyledTextField, sxFormControl1 } from '../../../../styles/FormFieldsStyles';
import { Select } from 'formik-mui';
import { FimOptions } from '../../../../common/HMMFirst';
import { IOption } from '../../../../common/HMA';

interface IFimSubSectionFields {
  fields: string[];
  id: string;
}
const FimSubSectionFields = ({ fields, id }: IFimSubSectionFields) => {
  return (
    <>
      <Typography variant="body1" gutterBottom align="left">
        {t(`report.HMMFIRST.feelingOfPain.occupationAndActivity.fimSubSections.${id}`)}
      </Typography>
      {fields.map((field: string) => {
        return (
          <Grid id={`${id}_Container`} container spacing={{ xs: 2, md: 4, lg: 4 }} key={field}>
            <Grid item xs={12} md={6} lg={6}>
              <Field
                id={`${id}_SelectField_${field}`}
                component={Select}
                name={`data.feelingOfPain.occupationAndActivity.fimAssessments.fields.${field}`}
                label={t(`report.HMMFIRST.feelingOfPain.occupationAndActivity.fimFields.${field}`)}
                formControl={{ sx: sxFormControl1 }}
              >
                {FimOptions.map((option: IOption) => {
                  return (
                    <MenuItem key={option.value} value={option.value}>
                      {t(`global.form.select.${option.label}`)}
                    </MenuItem>
                  );
                })}
              </Field>
            </Grid>
            <Grid item xs={12} md={6} lg={6}>
              <Field
                id={`${id}_TextField_${field}_Note`}
                fullWidth
                component={StyledTextField}
                type="text"
                name={`data.feelingOfPain.occupationAndActivity.fimAssessments.fields.${field}Notes`}
                label={`${t(
                  `report.HMMFIRST.feelingOfPain.occupationAndActivity.fimFields.${field}`
                )} ${t('report.HMKFIRST.remarks')}`}
              />
            </Grid>
          </Grid>
        );
      })}
    </>
  );
};

export default FimSubSectionFields;
