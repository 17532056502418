import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Table from '../../../components/Table';
import { useNavigate } from 'react-router-dom';
import { Row } from 'react-table';
import { getSortDirection, onVisitClick } from '../../../utils/Helper';
import {
  getVisitDueDateColumn,
  getVisitPositionColumn,
  getVisitTypeColumn,
} from '../../../common/columns/ProgramVisits';
import { getVisitStatusColumn } from '../../../common/columns/AllVisitsTableColumn';
import { IVisitWithProgramTitle } from '../../../layouts/reports/ReportLayout';
import { Icon } from '@iconify/react';
import { theme } from '../../../theme';
import EditVisitReferenceNumberForm from '../../visits/EditVisitReferenceNumberForm';
import { IVisit } from '../../../interfaces/Models';
import TableActionButton from './TableActionButton';
import { sortByPatientName } from './MissingProgramsTable';

export const sortLeumitVisits = ({
  visits,
  sort = 'patientName',
}: {
  visits: IVisitWithProgramTitle[];
  sort?: string;
}) => {
  const isSortedByDueDate = sort.includes('dueDate');
  const isSortedByPatientName = sort.includes('patientName');

  if (isSortedByDueDate) {
    const isDesc = sort.includes('-');
    if (isDesc) {
      return visits.sort((a, b) => {
        return new Date(b.dueDate).getTime() - new Date(a.dueDate).getTime();
      });
    }
    return visits.sort((a, b) => {
      return new Date(a.dueDate).getTime() - new Date(b.dueDate).getTime();
    });
  }

  if (isSortedByPatientName) {
    return sortByPatientName(visits, sort);
  }
  return visits;
};
interface Props {
  isLoading: boolean;
  visits: IVisitWithProgramTitle[];
  handleUpdateVisitsTable: (val?: IVisitWithProgramTitle | IVisit) => void;
  sort?: string;
  handleSaveLastActionValue: (value: string) => void;
}
export default function MissingReferenceNumberVisitsTable({
  isLoading,
  visits,
  handleUpdateVisitsTable,
  sort = 'dueDate',
  handleSaveLastActionValue,
}: Props) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [record, setRecord] = useState<IVisitWithProgramTitle | undefined>(undefined);
  const [openChangeClinicReferenceNumber, setOpenChangeClinicReferenceNumber] =
    useState<boolean>(false);

  const handelCloseModal = () => {
    setOpenChangeClinicReferenceNumber(false);
    setRecord(undefined);
  };

  const handleOpenChangeClinicReferenceNumberModal = (value: IVisitWithProgramTitle) => {
    setOpenChangeClinicReferenceNumber(true);
    setRecord(value);
  };

  const isThereNoData = visits.length === 0 && !isLoading;

  const onRowClick = (row: Row<IVisitWithProgramTitle>) => {
    onVisitClick(navigate, row.original);
  };

  const columns = [
    {
      id: 'patientName',
      Header: `${t('patient.info.patientName')}`,
      accessor: (val: any) =>
        typeof val.patient !== 'string'
          ? `${val.patient.personalDetails.firstName} ${val.patient.personalDetails.lastName}`
          : '',
      sortType: sort ? 'basic' : undefined,
      sortDirection: sort ? getSortDirection(sort || '', 'patientName') : undefined,
    },
    getVisitPositionColumn(),
    getVisitTypeColumn(),
    {
      ...getVisitDueDateColumn(),
      sortType: 'basic',
      sortDirection: getSortDirection(sort, 'dueDate'),
    },
    getVisitStatusColumn(),
    {
      Header: `${t('patient.form.title')}`,
      accessor: 'programTitle',
    },
    {
      Header: t('clinic.table.actions'),
      accessor: (visit: any) => {
        return (
          <TableActionButton
            icon={
              <Icon
                icon="zondicons:book-reference"
                width={24}
                height={24}
                color={theme.palette.secondary.main}
              />
            }
            onClick={() => handleOpenChangeClinicReferenceNumberModal(visit)}
            label={t('clinicReferenceNumber')}
          />
        );
      },
    },
  ];

  return (
    <>
      <EditVisitReferenceNumberForm
        record={record}
        openChangeClinicReferenceNumber={openChangeClinicReferenceNumber}
        handleCloseModal={handelCloseModal}
        handleVisitUpdate={handleUpdateVisitsTable}
        handleSaveLastActionValue={handleSaveLastActionValue}
      />
      {!isThereNoData && (
        <Table
          columns={columns}
          data={visits}
          count={visits.length}
          isLoading={isLoading}
          onRowClick={onRowClick}
          showPagination={false}
        />
      )}
    </>
  );
}
