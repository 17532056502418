/* eslint-disable new-cap */
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import useService from '../../hooks/HookService';
import { IVisit, IVisitFlat } from '../../interfaces/Models';
import VisitService from '../../services/VisitService';
import ErrorMessage from '../../components/ErrorMessage';
import Table from '../../components/Table';
import { getReportName } from '../../common/ReportsCommon';
import PageHeader from '../../components/PageHeader';
import { useNavigate } from 'react-router-dom';
import { Row } from 'react-table';
import { IRecordList } from '../../components/WidgetContainer';
import { getAllVisitsColumns, VisitsColumnsKeys } from '../../common/columns/AllVisitsTableColumn';
import { ApiResponse } from '../../interfaces/ApiResponseType';
import {
  executeInBatches,
  filterGeneralAndTableDoneVisits,
  filterVisitsForDownloadAllAction,
  getPageNumberFromUrlParam,
  getStartEndDateDep,
  handleTableAtomChange,
  onDownloadPdf,
  onVisitClick,
} from '../../utils/Helper';
import FetcherService from '../../hooks/FetcherService';
import ReportService from '../../services/ReportService';
import OverlaySpinner from '../../components/OverlaySpinner';
import { toast } from 'react-toastify';
import { VISIT_STATUS } from '../../common/Constants';
import useSearchFilter from '../../hooks/useSearchFilter';
import VisitsActions from './VisitsActions';
import VisitsTableHeader from './VisitsTableHeader';
import { Button, Grid, Card, Container } from '@mui/material';
import DownloadModal from '../../components/Modals/DownloadModal';
import DownloadButtonWithTooltip from '../financialReports/DownloadButtonWithTooltip';
import { downloadExcelReport, getSheetData, ISheetColumn } from '../../utils/ExcelReportUtils';
import VisitsTableFooter from '../../components/VisitsTableFooter';
import { theme } from '../../theme';
import { AbilityContext } from '../../casl/can';
import { useAbility } from '@casl/react';
import { AbilitySubjects } from '../../casl/ability';
const VisitsTable: React.FC<IRecordList> = ({
  status,
  typeOfColumns,
  fromDate,
  toDate,
  showFilters = true,
  showTablePagination = true,
  employee,
  dataFilter,
  rate,
  showPageLoading,
  showStatusFilter = false,
  pageSizes,
  customFilters,
  filterPosition,
  statusFilterValues,
  serverSidePagination = true,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { pageInfo } = useSearchFilter();
  const ability = useAbility(AbilityContext);

  const adminAbility = ability.can('manage', AbilitySubjects.ADMIN_ABILITY);
  const [isLoadingReport, setIsLoadingReport] = useState(false);
  const [shouldTableUpdate, setShouldTableUpdate] = useState<number>(0);
  const [cancelVisitModal, setCancelVisitModal] = useState<boolean>(false);
  const [visitToDoneModal, setVisitToDoneModal] = useState<boolean>(false);
  const [sentToClinicModal, setSentToClinicModal] = useState<boolean>(false);
  const [sentToClinicDownloadModal, setSentToClinicDownloadModal] = useState<boolean>(false);
  const [record, setRecord] = useState<IVisit | undefined>(undefined);
  const [openChangeVisitDueDate, setOpenChangeVisitDueDate] = useState<boolean>(false);
  const [openSwapVisitEmployee, setOpenSwapVisitEmployee] = useState<boolean>(false);

  const [downloadModalOpen, setDownloadModalOpen] = useState(false);
  const [totalItemsToDownload, setTotalItemsToDownload] = useState(0);
  const [totalDownloadedItems, setTotalDownloadedItems] = useState(0);

  const handelCloseModal = () => {
    setCancelVisitModal(false);
    setVisitToDoneModal(false);
    setSentToClinicModal(false);
    setSentToClinicDownloadModal(false);
    setOpenChangeVisitDueDate(false);
    setOpenSwapVisitEmployee(false);
    setRecord(undefined);
  };

  const handelLoading = (value: boolean) => {
    setIsLoadingReport(value);
  };

  const handelOpenSwapVisitEmployee = (value: IVisit) => {
    setOpenSwapVisitEmployee(true);
    setRecord(value);
  };

  const handelChangeVisitDueDateModal = (value: IVisit) => {
    setOpenChangeVisitDueDate(true);
    setRecord(value);
  };

  const handelOpenCancelVisitModal = (value: IVisit) => {
    setCancelVisitModal(true);
    setRecord(value);
  };

  const handelOpenChangeVisitStatusToDone = (value: IVisit) => {
    setVisitToDoneModal(true);
    setRecord(value);
  };

  const handelSentToClinicModal = (value: IVisit) => {
    setSentToClinicModal(true);
    setRecord(value);
  };

  const handelSentToClinicDownloadModal = (value: IVisit) => {
    setSentToClinicDownloadModal(true);
    setRecord(value);
  };

  const downloadReport = async (visit?: IVisit) => {
    handleTableAtomChange('visitId', visit?._id);
    const onSuccess = (res: ApiResponse<BlobPart>) => {
      onDownloadPdf(res, getReportName(visit));
    };
    const onFailed = () => {
      toast.error(t('global.errors.report'));
    };
    await FetcherService<any>({
      service: ReportService.generateReportPdf(visit?._id || ''),
      onSuccess: onSuccess,
      onFailed: onFailed,
      setLoading: setIsLoadingReport,
    });
  };

  const handelTableUpdate = () => {
    setShouldTableUpdate((prev) => prev + 1);
  };
  const {
    page,
    limit = !showTablePagination || !serverSidePagination ? 1000 : pageSizes ? pageSizes[0] : 10,
    search,
    from,
    to,
    range,
    sort = 'dueDate',
    status: urlStatus,
    employee: employeeQuery,
    month,
    year,
  } = pageInfo;

  const { isLoading, data, additionalData, loadingNumber, error, errorMessage } = useService<
    IVisitFlat[]
  >({
    service: VisitService.getAll({
      page: getPageNumberFromUrlParam(page),
      limit: limit,
      search: search,
      sort: sort,
      fromDate: getStartEndDateDep(pageInfo),
      toDate: getStartEndDateDep(pageInfo, true),
      status:
        urlStatus && Object.values({ ...VISIT_STATUS, MISSING: 'MISSING' }).indexOf(urlStatus) > -1
          ? statusFilterValues
            ? statusFilterValues[urlStatus]
            : urlStatus
          : status || VISIT_STATUS.DONE,
      employee: pageInfo.employee || employee,
    }),
    deps: [
      shouldTableUpdate,
      employeeQuery,
      employee,
      sort,
      search,
      page,
      limit,
      status,
      urlStatus,
      from,
      to,
      range,
      month,
      year,
    ],
  });

  const handelEditReport = (visit: IVisit) => {
    onVisitClick(navigate, visit, true);
  };

  const columns = getAllVisitsColumns(
    sort,
    handelEditReport,
    downloadReport,
    handelSentToClinicModal,
    typeOfColumns || VisitsColumnsKeys.All,
    handelOpenCancelVisitModal,
    handelOpenChangeVisitStatusToDone,
    handelSentToClinicDownloadModal,
    handelChangeVisitDueDateModal,
    handelOpenSwapVisitEmployee
  );

  const onRowClick = (row: Row<IVisit>) => {
    onVisitClick(navigate, row.original);
  };

  const getVisitData = (dataArray: IVisitFlat[] | undefined) => {
    if (data && dataFilter) {
      return dataFilter(data);
    } else if (data && !dataFilter) {
      return data;
    }
    return [];
  };

  const filteredData = React.useMemo(() => (dataFilter ? getVisitData(data) : data), [isLoading]);

  const resetDownloadAllReportsStates = () => {
    setTimeout(() => {
      setDownloadModalOpen(false);
      setTotalDownloadedItems(0);
      setTotalItemsToDownload(0);
    }, 1000);
  };

  const downloadReportsInBackground = async () => {
    const reportsToDownload = filterVisitsForDownloadAllAction(data!);
    if (!reportsToDownload.length) {
      toast.info(t('global.reportActions.noReportsToDownload'));
      return 0;
    }

    setTotalItemsToDownload(reportsToDownload.length);
    let completedDownloads = 0;
    const successfullyDownloadedVisits: string[] = filterGeneralAndTableDoneVisits(data!).map(
      (visit) => visit._id!
    );

    const downloadReport = async (visit: IVisit) => {
      const onSuccess = (res: ApiResponse<BlobPart>) => {
        onDownloadPdf(res, getReportName(visit));
        completedDownloads++;
        setTotalDownloadedItems(completedDownloads);
        if (visit.status === VISIT_STATUS.DONE) {
          successfullyDownloadedVisits.push(visit._id!);
        }
      };
      const onFailed = (err: any) => {
        completedDownloads++;
        setTotalDownloadedItems(completedDownloads);
      };
      await FetcherService<any>({
        service: ReportService.generateReportPdf(visit?._id!),
        onSuccess: onSuccess,
        onFailed: onFailed,
      });
    };
    const downloadPromises = reportsToDownload.map(downloadReport);
    setDownloadModalOpen(true);
    await executeInBatches(downloadPromises, 30);
    await updateAllDownloadedReports(successfullyDownloadedVisits);
    resetDownloadAllReportsStates();
    return 1;
  };

  const updateAllDownloadedReports = async (visits: string[]) => {
    if (!visits.length) {
      return;
    }
    const onSuccess = (res: ApiResponse<BlobPart>) => {
      toast.success(t('global.reportActions.sentAllToClinicDone'));
      handelTableUpdate();
    };
    const onFailed = (err: any) => {
      toast.error(t('global.reportActions.sentAllToClinicError'));
    };

    await FetcherService<any>({
      service: VisitService.updateMany(visits, { status: VISIT_STATUS.SENT_TO_CLINIC }),
      onSuccess: onSuccess,
      onFailed: onFailed,
      setLoading: setIsLoadingReport,
      showNotification: false,
    });
  };

  const onDownloadReport = () => {
    if (!data) {
      toast.error(t('global.errors.report'));
      return;
    }
    const filteredColumns: ISheetColumn[] = [];
    columns.forEach((column: ISheetColumn) => {
      if (!column.noExcelSupport) {
        filteredColumns.push(column);
      }
    });

    data.forEach((visit) => {
      visit.isExported = true;
    });

    const reportsData = getSheetData(data, filteredColumns);
    const fileName = 'global.excelReports.allVisitsReport';

    downloadExcelReport(t(fileName), [{ sheetName: t(fileName), sheetData: reportsData }]);
  };

  if (error) {
    return <ErrorMessage error={errorMessage} />;
  }

  if ((isLoading && showPageLoading && loadingNumber == 1) || (!data && showPageLoading)) {
    return <OverlaySpinner />;
  }

  return (
    <Container>
      <Grid container justifyContent="space-between">
        <Grid>
          <PageHeader pageTitle={t('drawer.sidebar.allVisits')} />
        </Grid>
        <Grid>
          <DownloadButtonWithTooltip onDownloadReport={onDownloadReport} />
        </Grid>
      </Grid>
      <Card>
        <VisitsActions
          record={record}
          cancelVisitModal={cancelVisitModal}
          visitToDoneModal={visitToDoneModal}
          sentToClinicModal={sentToClinicModal}
          sentToClinicDownloadModal={sentToClinicDownloadModal}
          handelCloseModal={handelCloseModal}
          handelTableUpdate={handelTableUpdate}
          handelLoadingReport={handelLoading}
          downloadReport={downloadReport}
          openChangeVisitDueDataModal={openChangeVisitDueDate}
          openSwapVisitEmployeeModal={openSwapVisitEmployee}
          openChangeClinicReferenceNumber={false}
        />
        <DownloadModal
          open={downloadModalOpen}
          onClose={(event: any, reason?: string) => {
            if (reason !== 'backdropClick' && reason !== 'escapeKeyDown') {
              setDownloadModalOpen(false);
            }
          }}
          totalItems={totalItemsToDownload}
          completedItems={totalDownloadedItems}
        />
        <Table
          columns={columns}
          data={filteredData || []}
          count={additionalData?.total || 0}
          pageInfo={
            !showTablePagination || !serverSidePagination
              ? undefined
              : {
                  page: getPageNumberFromUrlParam(page),
                  pageSize: Number(limit),
                }
          }
          TableHeaderFilters={
            <VisitsTableHeader
              showStatusFilter={showStatusFilter}
              employee={employee}
              showFilters={showFilters}
              status={status}
            >
              {adminAbility && (
                <Button
                  variant="contained"
                  size="small"
                  sx={{ padding: theme.spacing(1.1) }}
                  onClick={() => {
                    downloadReportsInBackground()
                      .then(async (data) => {
                        if (data) {
                          toast.info(
                            t('global.reportActions.downloadingAllReportsProcessFinished')
                          );
                        }
                      })
                      .catch((error) => {
                        // eslint-disable-next-line no-console
                        console.error('Error downloading reports:', error);
                        toast.error(error.message);
                      });
                  }}
                >
                  {t('global.reportActions.downloadAllReportsAndSendToClinic')}
                </Button>
              )}
            </VisitsTableHeader>
          }
          filters={customFilters}
          showFilters={showFilters || (customFilters && customFilters.length > 0)}
          isLoading={isLoading}
          onRowClick={onRowClick}
          showPagination={showTablePagination}
          customFooter={
            rate || rate === 0 ? (
              <VisitsTableFooter repeatedAndFirstVisits={filteredData || []} rate={rate} />
            ) : null
          }
          pageSizes={pageSizes}
          filterPosition={filterPosition}
        />
        {isLoadingReport && <OverlaySpinner />}
      </Card>
    </Container>
  );
};

export default VisitsTable;
