import { Grid, Typography, MenuItem, SxProps } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { styled } from '@mui/system';
import { theme } from '../../../theme';
import { FastField as Field } from 'formik';
import { TextField } from 'formik-mui';
import painDegree1 from '../../../assets/imgs/painDegree1.png';
import painDegree2 from '../../../assets/imgs/painDegree2.png';
import painDegree3 from '../../../assets/imgs/painDegree3.png';
import painDegree4 from '../../../assets/imgs/painDegree4.png';
import painDegree5 from '../../../assets/imgs/painDegree5.png';
import { assessmentOptions } from '../../../common/assessment';
import FormikSelect from '../../../components/FormikSelect';

const sxFormControl: SxProps = {
  width: '100%',
};

const StyledGrid = styled(Grid)({
  marginBottom: theme.spacing(2),
});

const StyledTextField = styled(TextField)({
  marginBottom: theme.spacing(1.5),
});

const StyledBox = styled('img')({
  width: theme.spacing(13),
  hieght: theme.spacing(13),
  [theme.breakpoints.down('sm')]: {
    width: theme.spacing(7),
    hieght: theme.spacing(7),
  },
});

const StyledDiv = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
});

const StyledDiv2 = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  flexDirection: 'column',
});

const StyledPainDegree = styled('div')({
  flexDirection: 'column',
  textAlign: 'center',
  fontWeight: 'bold',
  fontSize: theme.spacing(1.8),
  [theme.breakpoints.down('sm')]: {
    fontSize: theme.spacing(1.3),
  },
});

const StyledLine = styled('div')({
  flex: 1,
  height: 2,
  background: theme.palette.action.selected,
  alignSelf: 'center',
});

const StyledSpan = styled('span')({
  fontWeight: 'bold',
  fontSize: theme.spacing(2),
  [theme.breakpoints.down('sm')]: {
    fontSize: theme.spacing(1.5),
  },
});

interface PainAssessmentProps {
  id: string;
}
const PainAssessment = ({ id }: PainAssessmentProps) => {
  const { t } = useTranslation();

  const painDegreeOptions = [0, ...assessmentOptions, 8, 9, 10];

  return (
    <div id={id}>
      <Typography variant="subtitle1" gutterBottom align="left">
        {t('report.PainAssessment.painSection')}
      </Typography>
      <StyledGrid container justifyContent="center" id={`${id}_Container`}>
        <Grid item xs={12} md={10} lg={10}>
          <StyledDiv>
            <StyledPainDegree>
              <StyledBox src={painDegree1} alt={t('report.PainAssessment.painDegrees')} />
              <StyledDiv2>
                <span>{t('report.PainAssessment.painDegree1')}</span>
                <StyledSpan>0</StyledSpan>
              </StyledDiv2>
            </StyledPainDegree>
            <StyledLine />
            <StyledPainDegree>
              <StyledBox src={painDegree2} alt={t('report.PainAssessment.painDegrees')} />
              <StyledDiv2>
                <span>{t('report.PainAssessment.painDegree2')}</span>
                <StyledSpan>2.5</StyledSpan>
              </StyledDiv2>
            </StyledPainDegree>
            <StyledLine />
            <StyledPainDegree>
              <StyledBox src={painDegree3} alt={t('report.PainAssessment.painDegrees')} />
              <StyledDiv2>
                <span>{t('report.PainAssessment.painDegree3')}</span>
                <StyledSpan>5</StyledSpan>
              </StyledDiv2>
            </StyledPainDegree>
            <StyledLine />
            <StyledPainDegree>
              <StyledBox src={painDegree4} alt={t('report.PainAssessment.painDegrees')} />
              <StyledDiv2>
                <span>{t('report.PainAssessment.painDegree4')}</span>
                <StyledSpan>7.5</StyledSpan>
              </StyledDiv2>
            </StyledPainDegree>
            <StyledLine />
            <StyledPainDegree>
              <StyledBox src={painDegree5} alt={t('report.PainAssessment.painDegrees')} />
              <StyledDiv2>
                <span>{t('report.PainAssessment.painDegree5')}</span>
                <StyledSpan>10</StyledSpan>
              </StyledDiv2>
            </StyledPainDegree>
          </StyledDiv>
        </Grid>
      </StyledGrid>
      <Grid container spacing={{ xs: 0, md: 4, lg: 4 }}>
        <Grid item xs={12} md={6} lg={6}>
          <FormikSelect
            id={`${id}_PainDegrees`}
            formControl={sxFormControl}
            name="data.PainAssessment.painDegrees"
            label={t('report.PainAssessment.painSection')}
          >
            {painDegreeOptions.map((option) => {
              return (
                <MenuItem key={option} value={option}>
                  {option}
                </MenuItem>
              );
            })}
          </FormikSelect>
        </Grid>
        <Grid item xs={12} md={6} lg={6}>
          <Field
            id={`${id}_PainNotes`}
            fullWidth
            component={StyledTextField}
            type="text"
            name="data.PainAssessment.painNotes"
            min={0}
            max={10}
            label={t('report.PainAssessment.painNotes')}
          />
        </Grid>
      </Grid>
    </div>
  );
};

export default PainAssessment;
