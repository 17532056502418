import React from 'react';
import PageHeader from '../../components/PageHeader';
import { Card, Container } from '@mui/material';
import Page from '../../components/Page';
import { useTranslation } from 'react-i18next';
import ClinicTable from './ClinicTable';
import useAccess from '../../hooks/useAccess';
import { AbilitySubjects } from '../../casl/ability';

const Clinic: React.FC = () => {
  const { t } = useTranslation();
  useAccess('read', AbilitySubjects.ADMIN_ABILITY);

  return (
    <>
      <Page title={t('clinic.mainView.header')}>
        <Container>
          <PageHeader pageTitle={t('clinic.mainView.header')} />
          <Card>
            <ClinicTable />
          </Card>
        </Container>
      </Page>
    </>
  );
};

export default React.memo(Clinic);
