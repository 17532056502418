import React from 'react';
import { Select, TextField } from 'formik-mui';
import { FastField } from 'formik';
import { useTranslation } from 'react-i18next';
import { Grid, MenuItem, SxProps, Typography } from '@mui/material';
import { styled } from '@mui/system';
import { theme } from '../../../../theme';

const sxFormControl: SxProps = {
  width: '100%',
};

const StyledGrid = styled(Grid)({
  marginBottom: theme.spacing(2),
});
const yesnoOptions = ['yes', 'no'];
const teethOptions = ['yes', 'no', 'dentures', 'partial'];
const appetiteOptions = ['yes', 'no', 'aggravation', 'improvement'];
const bowelMovementsOptions = [
  'standardization',
  'constipation',
  'diarrhea',
  'nausea',
  'gases',
  'reflux',
  'heartburn',
  'vomiting',
];

const DigestiveSystemFields = () => {
  const { t } = useTranslation();
  const id = 'HMDFirst_HealthIndices_DigestiveSystem';

  const fields = [
    {
      name: 'chewingDifficulties',
      options: yesnoOptions,
    },
    {
      name: 'teeth',
      options: teethOptions,
    },
    {
      name: 'difficultySwallowing',
      options: yesnoOptions,
    },
    {
      name: 'appetite',
      options: appetiteOptions,
    },
    {
      name: 'bowelMovements',
      options: bowelMovementsOptions,
    },
  ];
  return (
    <div id={id}>
      <Typography variant="subtitle2" gutterBottom align="left">
        {t('report.HMDFIRST.healthIndices.digestiveSystem.header')}
      </Typography>
      <StyledGrid container spacing={{ xs: 2, md: 4, lg: 4 }} id={`${id}_Container`}>
        {fields.map((field) => {
          return (
            <Grid item xs={12} md={6} lg={6} key={field.name}>
              <FastField
                id={`${id}_${field.name}`}
                component={Select}
                name={`data.healthIndices.digestiveSystem.${field.name}`}
                label={t(`report.HMDFIRST.healthIndices.digestiveSystem.${field.name}`)}
                formControl={{ sx: sxFormControl }}
              >
                {field.options.map((option) => {
                  return (
                    <MenuItem key={option} value={option}>
                      {t(`global.form.select.${option}`)}
                    </MenuItem>
                  );
                })}
              </FastField>
            </Grid>
          );
        })}

        <Grid item xs={12} md={6} lg={6}>
          <FastField
            id={`${id}_OtherBowelMovements`}
            fullWidth
            component={TextField}
            type="text"
            name="data.healthIndices.digestiveSystem.otherBowelMovements"
            label={t('report.HMDFIRST.healthIndices.digestiveSystem.otherBowelMovements')}
          />
        </Grid>
      </StyledGrid>
    </div>
  );
};

export default DigestiveSystemFields;
