import React from 'react';
import { motion } from 'framer-motion';
import { Link as RouterLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
// material
import { styled } from '@mui/material/styles';
import { Box, Button, Typography, Container } from '@mui/material';
// components
import { MotionContainer, varBounceIn } from '../components/animate';
import Page from '../components/Page';
import { theme } from '../theme';

// ----------------------------------------------------------------------

const RootStyle = styled(Page)(({ theme }) => ({
  display: 'flex',
  minHeight: '100%',
  alignItems: 'center',
  paddingTop: theme.spacing(15),
  paddingBottom: theme.spacing(10),
}));

const StyledBox = styled('img')({
  hieght: theme.spacing(260),
  [theme.breakpoints.down('sm')]: {
    hieght: theme.spacing(200),
  },
});

// ----------------------------------------------------------------------
const Page403 = () => {
  const { t } = useTranslation();

  return (
    <RootStyle title={t('global.pages.page403')}>
      <Container>
        <MotionContainer initial="initial" open>
          <Box sx={{ maxWidth: 480, margin: 'auto', textAlign: 'center' }}>
            <motion.div variants={varBounceIn}>
              <Typography variant="h3" paragraph>
                {t('global.page403.header')}
              </Typography>
            </motion.div>
            <Typography sx={{ color: 'text.secondary' }}>{t('global.page403.text')}</Typography>

            <motion.div variants={varBounceIn}>
              <StyledBox src={require('../assets/imgs/illustration403.jpeg').default} />
            </motion.div>

            <Button to="/app/dashboard" size="large" variant="contained" component={RouterLink}>
              {t('global.page404.home')}
            </Button>
          </Box>
        </MotionContainer>
      </Container>
    </RootStyle>
  );
};

export default Page403;
