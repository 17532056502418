import Label from '../../components/Label';
import MoreOptions from '../../components/MoreOptions';
import {
  checkEmployeeReportAuthorization,
  getDateFormatted,
  getVisitType,
} from '../../utils/Helper';
import editFill from '@iconify/icons-eva/edit-fill';
import { Icon } from '@iconify/react';
import { VISIT_STATUS } from '../Constants';
import { IVisit, IVisitFlat } from '../../interfaces/Models';
import download from '@iconify/icons-eva/download-outline';
import PublishedWithChangesIcon from '@mui/icons-material/PublishedWithChanges';
import { t } from 'i18next';

import { theme } from '../../theme';
import { useAbility } from '@casl/react';
import { AbilityContext } from '../../casl/can';
import { AbilitySubjects } from '../../casl/ability';
import Iconify from '../../components/Iconify';
import OutboxIcon from '@mui/icons-material/Outbox';
import { getSwapEmployeeTableAction, getVisitStatusColumn } from './AllVisitsTableColumn';
import { checkIfVisitTypeRequireReferenceNumber } from '../../hooks/isReportMode';

export const getVisitIsReleaseColumn = () => {
  return {
    id: 'isRelease',
    Header: `${t('visit.table.isRelease')}`,
    accessor: (visit: any) => {
      return (
        <Label variant="ghost" color={visit.isRelease ? 'success' : 'error'}>
          {visit.isRelease ? 'כן' : 'לא'}
        </Label>
      );
    },
  };
};

export const getVisitTypeColumn = () => {
  return {
    id: 'visitType',
    Header: `${t('patient.form.visitType')}`,
    accessor: (visit: any) => t(`global.visitTypes.${getVisitType(visit)}`),
  };
};

export const getVisitPositionColumn = () => {
  return {
    id: 'position',
    Header: `${t('user.form.position')}`,
    accessor: (visit: any) => t(`global.positions.${visit.visitType.slice(0, 3)}`),
  };
};

export const getVisitEmployeeNameColumn = () => {
  return {
    id: 'employeeName',
    Header: `${t('global.roles.EMPLOYEE')}`,
    accessor: (visit: any) =>
      `${visit.employee?.personalDetails.firstName} ${visit.employee?.personalDetails.lastName}`,
  };
};

export const getVisitDueDateColumn = () => {
  return {
    id: 'dueDate',
    Header: `${t('visit.table.dueDate')}`,
    accessor: (visit: any) => getDateFormatted(visit.dueDate),
  };
};

export const getVisitSentToClinicAction = (
  visit: IVisit,
  sentToClinicHandler: (visit: IVisit) => void,
  manageAdminAbility: boolean,
  download = false
) => {
  return {
    name: t(`global.reportActions.${download ? 'sentToClinicDownload' : 'sentToClinic'}`),
    handler: sentToClinicHandler,
    icon: download ? (
      <OutboxIcon width={24} height={24} color="primary" />
    ) : (
      <Iconify icon="bi:send-fill" width={20} height={20} color={theme.palette.primary.main} />
    ),
    isVisible: visit.status == VISIT_STATUS.DONE && manageAdminAbility,
  };
};

export const getChangeVisitStatusToDoneAction = (
  visit: IVisit,
  manageAdminAbility: boolean,
  visitStatusToDoneHandler: (visit: IVisit) => void
) => {
  return {
    name: t('global.reportActions.sentToClinicDone'),
    handler: visitStatusToDoneHandler,
    icon: <PublishedWithChangesIcon width={24} height={24} color={'secondary'} />,
    isVisible: visit.status == VISIT_STATUS.SENT_TO_CLINIC && manageAdminAbility,
  };
};

export const getEditAction = (handelEdit: (value: any) => void, condition = true) => {
  return {
    name: t('global.moreMenu.edit'),
    handler: handelEdit,
    icon: <Icon icon={editFill} width={24} height={24} color={theme.palette.secondary.main} />,
    isVisible: condition,
  };
};

export const getCancelVisitAction = (
  handelCancelVisit: (visit: IVisit) => void,
  manageAdminAbility: boolean,
  visit: IVisit | IVisitFlat
) => {
  const isCanceledVisit = visit.status === VISIT_STATUS.CANCELED;
  const isSentToClinicVisit = visit.status === VISIT_STATUS.SENT_TO_CLINIC;
  return {
    name: t('global.moreMenu.cancelVisit'),
    handler: handelCancelVisit,
    icon: (
      <Icon icon="ic:baseline-cancel" width={24} height={24} color={theme.palette.error.main} />
    ),
    isVisible: manageAdminAbility && !isCanceledVisit && !isSentToClinicVisit,
    variant: theme.palette.error.main,
  };
};

interface IProgramVisitColumnsData {
  handelEditReport: (visit: IVisit) => void;
  downloadReport: (value: IVisit) => Object;
  sentToClinicHandler: (visit: IVisit) => void;
  handelCancelVisit: (visit: IVisit) => void;
  handelOpenChangeVisitStatusToDone: (visit: IVisit) => void;
  handelSentToClinicDownloadModal: (visit: IVisit) => void;
  handelChangeVisitDueDateModal: (visit: IVisit) => void;
  handleOpenChangeClinicReferenceNumberModal: (visit: IVisit) => void;
  isVisitLevelProgram: boolean;
  isLeumitPatient: boolean;
  handelOpenSwapVisitEmployee: (visit: IVisit) => void;
}
export const getProgramVisitColumns = ({
  handelEditReport,
  downloadReport,
  sentToClinicHandler,
  handelCancelVisit,
  handelOpenChangeVisitStatusToDone,
  handelSentToClinicDownloadModal,
  handelChangeVisitDueDateModal,
  handleOpenChangeClinicReferenceNumberModal,
  isVisitLevelProgram,
  isLeumitPatient,
  handelOpenSwapVisitEmployee,
}: IProgramVisitColumnsData) => {
  const ability = useAbility(AbilityContext);

  const manageAdminAbility = ability.can('manage', AbilitySubjects.ADMIN_ABILITY);
  const updateAdminAbility = ability.can('update', AbilitySubjects.ADMIN_ABILITY);
  const isReferenceNumberColumnVisible = isVisitLevelProgram && isLeumitPatient;

  const columns = [
    getVisitPositionColumn(),
    getVisitTypeColumn(),
    getVisitEmployeeNameColumn(),
    getVisitDueDateColumn(),
    getVisitStatusColumn(),
    getVisitIsReleaseColumn(),
    {
      Header: t('clinic.table.actions'),
      accessor: (visit: any) => {
        const isReferenceNumberRequired =
          checkIfVisitTypeRequireReferenceNumber(visit) && isLeumitPatient && isVisitLevelProgram;
        return (
          <>
            <MoreOptions
              actions={[
                getEditAction(
                  handelEditReport,
                  visit.status == VISIT_STATUS.DONE && !!checkEmployeeReportAuthorization(visit)
                ),
                {
                  name: t('program.hospitalDaysForm.editDueDate'),
                  handler: handelChangeVisitDueDateModal,
                  icon: (
                    <Icon
                      icon="ic:baseline-update"
                      width={24}
                      height={24}
                      color={theme.palette.secondary.main}
                    />
                  ),
                  isVisible: updateAdminAbility,
                },
                getSwapEmployeeTableAction({
                  handelOpenSwapVisitEmployee,
                  adminAbility: manageAdminAbility,
                }),
                {
                  name: t('global.reportActions.downloadReport'),
                  handler: downloadReport,
                  icon: <Icon icon={download} width={24} height={24} color="#3E94A8" />,
                  isVisible:
                    visit.status == VISIT_STATUS.DONE ||
                    visit.status == VISIT_STATUS.SENT_TO_CLINIC,
                },
                getVisitSentToClinicAction(visit, sentToClinicHandler, manageAdminAbility),
                getVisitSentToClinicAction(
                  visit,
                  handelSentToClinicDownloadModal,
                  manageAdminAbility,
                  true
                ),
                getCancelVisitAction(handelCancelVisit, manageAdminAbility, visit),
                getChangeVisitStatusToDoneAction(
                  visit,
                  manageAdminAbility,
                  handelOpenChangeVisitStatusToDone
                ),
                {
                  name: t('clinicReferenceNumber'),
                  icon: (
                    <Icon
                      icon="zondicons:book-reference"
                      width={24}
                      height={24}
                      color={theme.palette.secondary.main}
                    />
                  ),
                  handler: handleOpenChangeClinicReferenceNumberModal,
                  isVisible: isReferenceNumberRequired && manageAdminAbility,
                },
              ]}
              record={visit}
            />
          </>
        );
      },
    },
  ];

  // show clinic reference number column if leumit patient and visit level program
  if (isReferenceNumberColumnVisible) {
    const currentColumnsLength = columns.length;
    const actionsColumn = columns[currentColumnsLength - 1];

    columns[currentColumnsLength - 1] = {
      Header: t('clinicReferenceNumber'),
      accessor: 'clinicReferenceNumber',
    } as any;

    columns[currentColumnsLength] = actionsColumn;
  }

  return columns;
};
