import React from 'react';
import { Dialog, DialogTitle, DialogContent, Box, Breakpoint } from '@mui/material';
import LoadingScreen from '../LoadingScreen';
import CancelIcon from '@mui/icons-material/Cancel';
import { styled } from '@mui/system';
import { theme } from '../../theme';

const StyledBox = styled(Box, {
  name: 'StyledBox',
  slot: 'Wrapper',
})({
  alignItems: 'center',
  marginTop: theme.spacing(1),
  marginLeft: theme.spacing(1),
  // borderBottom: '1px solid black',
});

export const StyledTitle = styled(DialogTitle)({
  textAlign: 'center',
  paddingTop: 0,
});

interface FormModalProps {
  open: boolean;
  onClose: Function;
  text?: string;
  loading?: boolean;
  maxWidth?: Breakpoint;
  cancelIconAction?: () => void;
}

const FormModal: React.FC<FormModalProps> = ({
  loading,
  open,
  onClose,
  text,
  maxWidth = 'sm',
  children,
  cancelIconAction,
}) => {
  return (
    <>
      <Dialog
        fullWidth
        open={open}
        maxWidth={maxWidth}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        {loading ? (
          <LoadingScreen />
        ) : (
          <>
            <StyledBox>
              <CancelIcon
                sx={{ cursor: 'pointer', height: '100%' }}
                color="error"
                onClick={() => (cancelIconAction ? cancelIconAction() : onClose())}
              />
            </StyledBox>
            {text && <StyledTitle>{text}</StyledTitle>}
            <DialogContent>{children}</DialogContent>
          </>
        )}
      </Dialog>
    </>
  );
};

export default FormModal;
