/* eslint-disable new-cap */
import React, { useMemo, useState } from 'react';
import {
  IClinic,
  IPatient,
  IPatientProgram,
  IStaff,
  IUserFlat,
  IVisit,
} from '../../interfaces/Models';
import * as Yup from 'yup';
import { Button, Grid } from '@mui/material';
import { CLINIC_LIST, VISIT_STATUS, reportTypes } from '../../common/Constants';
import AddIcon from '@mui/icons-material/Add';
import Calender, { EventDropData, IEvents } from '../../components/Calender';
import VisitForm from './fields/VisitForm';
import { useNavigate } from 'react-router-dom';
import FetcherService from '../../hooks/FetcherService';
import PatientProgramService from '../../services/PatientProgramService';
import { toast } from 'react-toastify';
import StaffForm from './fields/staff/StaffForm';
import StaffView from './fields/staff/StaffView';
import {
  getEmployeeName,
  getHospitalDaysEvents,
  getVisitColor,
  getVisitEventTitle,
  scrollUp,
} from '../../utils/Helper';
import { format, addHours } from 'date-fns';
import DialogActions from '../../components/DialogActions';
import SelectInput from '../../components/SelectInput';
import { CalendarCard, ProgramCard, ProgramFooter } from '../../styles/ProgramStyles';
import { t } from 'i18next';
import ProgramStartEndFields from './fields/ProgramStartEndFields';
import {
  ADJUSTMENT_REPORT,
  LEUMIT_PROGRAM_NAMES,
  MEUHEDET_PROGRAM_NAMES,
  allReportPositions,
  getErrorValue,
  getMeuhedetProgramCode,
  getProgramStaff,
} from '../../common/Program';
import OverlaySpinner from '../../components/OverlaySpinner';
import { EventClickArg } from '@fullcalendar/core';
import ProgramPaymentModeField from './fields/ProgramPaymentModeField';
import { checkVisitType } from '../../hooks/isReportMode';

interface ProgramFieldsProps {
  patient?: IPatient;
  employees: IUserFlat[];
  program?: IPatientProgram;
  clinic: IClinic;
}
const ProgramFields = ({ patient, employees, program, clinic }: ProgramFieldsProps) => {
  const navigate = useNavigate();
  const programStaff: IStaff[] = [];
  if (program && program.staff) {
    const staff: any = program.staff;
    Object.keys(program?.staff).forEach((staffPosition: string) => {
      programStaff.push({
        position: staffPosition,
        employee: staff[staffPosition],
      });
    });
  }
  const programPositions =
    program && program.staff
      ? allReportPositions.filter(
          (position: string) => Object.keys(program ? program.staff : {}).indexOf(position) === -1
        )
      : [];
  const [staff, setStaff] = useState<IStaff[]>(program ? programStaff : []);
  const [programTitle, setProgramTitle] = useState<string>(program ? program.title : '');
  const [paymentMode, setPaymentMode] = useState<string | undefined>(program?.paymentMode);
  const [clinicReferenceNumber, setClinicReferenceNumber] = useState<string | undefined>(
    program?.clinicReferenceNumber
  );
  const [titleError, setTitleError] = useState<string>('');
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [visitDate, setVisitDate] = useState<Date>(new Date());
  const [patientVisits, setPatientVisits] = useState<IVisit[]>(program ? program.visits : []);
  const [staffPositions, setStaffPositions] = useState<string[]>(
    program && program.staff ? programPositions : allReportPositions
  );
  const [visitRecord, setVisitRecord] = useState<IVisit | null>();
  const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false);
  const [programStart, setProgramStart] = useState<Date | null>(program?.startDate || null);
  const [programEnd, setProgramEnd] = useState<Date | null>(program?.endDate || null);
  const [programStartError, setProgramStartError] = useState<string>('');
  const [programEndError, setProgramEndError] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handleChangePaymentMode = (val: string) => setPaymentMode(val);
  const handleChangeClinicReferenceNumber = (val?: string) => setClinicReferenceNumber(val);

  const isLeumitPatient = clinic.name.trim() === CLINIC_LIST.LEUMIT.trim();

  const initialValues = useMemo(
    () =>
      visitRecord
        ? {
            ...visitRecord,
            employee:
              typeof visitRecord.employee == 'string'
                ? visitRecord.employee
                : visitRecord.employee._id,
            editing: true,
            visitType:
              visitRecord.visitType === reportTypes.HMM_ADJUSTMENT_FIRST ||
              visitRecord.visitType === reportTypes.HMM_ADJUSTMENT_REPEATED
                ? ADJUSTMENT_REPORT
                : visitRecord.visitType,
          }
        : {
            employee: '',
            visitType: '',
            dueDate: addHours(visitDate, 1),
            patient: patient?._id,
            isFirst: false,
            isRelease: false,
            clinic: clinic._id,
            employees: [],
            editing: false,
          },
    [visitRecord, visitDate]
  );
  const VisitSchema = Yup.object({
    employee: Yup.string().required(t('clinic.form.required')),
    visitType: Yup.string().required(t('clinic.form.required')),
    dueDate: Yup.date().required(t('clinic.form.required')),
    patient: Yup.string().required(t('clinic.form.required')),
    clinic: Yup.string().required(t('clinic.form.required')),
  });

  const handleAddEditVisit = async (record: IVisit): Promise<void> => {
    const prevScrollY = document.documentElement.scrollTop;
    record.visitType =
      record.visitType === ADJUSTMENT_REPORT
        ? (record.isFirst && record.editing == true) || record.visitType === ADJUSTMENT_REPORT
          ? reportTypes.HMM_ADJUSTMENT_FIRST
          : reportTypes.HMM_ADJUSTMENT_REPEATED
        : record.visitType;
    delete record.employees;
    if (record.editing == true) {
      const visitsWithoutEditingRecord = patientVisits.filter(
        (visit: IVisit) =>
          visit.visitType != visitRecord?.visitType || visit.dueDate != visitRecord?.dueDate
      );
      delete record.editing;
      setPatientVisits([...visitsWithoutEditingRecord, record]);
    } else {
      setPatientVisits((prev) => [...prev, { ...record, dueDate: visitDate }]);
    }

    setOpenModal(false);
    setVisitRecord(null);
    window.scrollTo(0, prevScrollY);
  };

  const handleAddProgram = async () => {
    const programValues = {
      title: programTitle,
      startDate: programStart,
      endDate: programEnd,
      moahidetCode: getMeuhedetProgramCode(programTitle),
      patient: patient?._id ? patient._id : '',
      staff: getProgramStaff(staff),
      visits: patientVisits,
      status: 'ACTIVE',
      generalReport: null,
      releaseReport: null,
      paymentMode,
      clinicReferenceNumber,
    };
    if (programTitle.length < 1 || !programStart || !programEnd) {
      setTitleError(getErrorValue(programTitle));
      setProgramStartError(getErrorValue(programStart));
      setProgramEndError(getErrorValue(programEnd));
      scrollUp();
    } else if (patientVisits.length < 1) {
      toast.error(t('global.errors.noVisitsAdded'));
    } else {
      if (program?._id) {
        await FetcherService<IPatientProgram>({
          service: PatientProgramService.edit(program._id, programValues),
          setLoading: setIsLoading,
        });
        navigate(`/app/patient-program/${program?._id}`);
      } else {
        await FetcherService<IPatientProgram>({
          service: PatientProgramService.add(programValues),
          setLoading: setIsLoading,
        });
        navigate(`/app/patient/${patient?._id}`);
      }
    }
  };

  const handelProgramTitle = (value: string) => {
    setProgramTitle(value);
    if (value.length < 1) {
      setTitleError(t('clinic.form.required'));
    } else {
      setTitleError('');
    }
  };

  const handleAddStaffMember = (value: IStaff) => {
    setStaffPositions((prev: string[]) => prev.filter((position) => position != value.position));
    setStaff((prev: IStaff[]) => [...prev, value]);
  };

  const removeStaffMember = (selectedStaff: IStaff) => {
    setStaff((prev: IStaff[]) =>
      prev.filter(
        (staffMember: IStaff) =>
          staffMember.employee !== selectedStaff.employee ||
          staffMember.position !== selectedStaff.position
      )
    );
    setStaffPositions((prev: string[]) => [selectedStaff.position, ...prev]);
  };

  const handleModalClose = () => {
    setOpenModal(false);
    setVisitRecord(null);
  };

  const onOpenDate = (value: Date) => {
    setVisitDate(value);
    setOpenModal(true);
  };

  const onOpenVisit = (value: EventClickArg) => {
    const visitToBeEdited = patientVisits.find(
      (visit: IVisit, idx) => visit?._id == value.event.id || String(idx) == value.event.id
    );
    if (visitToBeEdited) {
      if (
        visitToBeEdited.hasOwnProperty('_id') &&
        (visitToBeEdited.status == VISIT_STATUS.DONE ||
          visitToBeEdited.status == VISIT_STATUS.SENT_TO_CLINIC)
      ) {
        toast.error(t('global.errors.visitStatusDone'));
      } else {
        setVisitRecord(visitToBeEdited);
        setOpenModal(true);
      }
    }
  };

  const onOpenDeleteVisit = () => {
    setOpenDeleteModal(true);
  };

  const onDeleteVisit = () => {
    setPatientVisits((prev) =>
      prev.filter(
        (visit) =>
          visit.employee != visitRecord?.employee ||
          visit.visitType != visitRecord?.visitType ||
          format(new Date(visit.dueDate), 'dd-MM-yyyy') !=
            format(new Date(visitRecord?.dueDate || ''), 'dd-MM-yyyy')
      )
    );
    setOpenDeleteModal(false);
    setOpenModal(false);
    setVisitRecord(null);
  };

  const onCloseDeleteVisitModal = () => {
    setOpenDeleteModal(false);
  };

  const handleProgramDatesChange = (name: string, value: Date | null) => {
    if (name == 'programStart') {
      setProgramStart(value);
      setProgramStartError(getErrorValue(programStart));
    } else {
      setProgramEnd(value);
      setProgramEndError(getErrorValue(programEnd));
    }
  };

  const getCalendarVisits = (): IEvents[] => {
    const visitsEvents = patientVisits.map((visit: IVisit, idx) => {
      const employeeName = getEmployeeName(
        typeof visit.employee != 'object' ? visit.employee : visit.employee._id || '',
        employees
      );
      const title = getVisitEventTitle(t, visit, employeeName);
      const isVisitDone =
        visit.status == VISIT_STATUS.DONE || visit.status == VISIT_STATUS.SENT_TO_CLINIC;
      const isVisitCanceled = visit.status == VISIT_STATUS.CANCELED;
      const { isFirstReport, isReleaseReport, isGeneralReport } = checkVisitType(visit);

      const isVisitEditable =
        !isVisitDone && !isVisitCanceled && !isFirstReport && !isReleaseReport && !isGeneralReport;
      return {
        title,
        date: format(new Date(visit.dueDate), 'yyyy-MM-dd'),
        color: getVisitColor(visit),
        id: visit._id || String(idx),
        editable: isVisitEditable,
      };
    });
    const hospitalDaysEvents: IEvents[] = program?.hospitalDays
      ? getHospitalDaysEvents(program?.hospitalDays)
      : [];
    return [...visitsEvents, ...hospitalDaysEvents];
  };

  const calendarEvents = useMemo(() => getCalendarVisits(), [patientVisits]);

  const onEventDrop = ({ oldStartDate, newStartDate, eventId }: EventDropData) => {
    const patientVisitsUpdated = patientVisits.map((visit) => {
      if (visit._id === eventId) {
        return {
          ...visit,
          dueDate: newStartDate,
        };
      }
      return visit;
    });
    setPatientVisits(patientVisitsUpdated);
  };
  return (
    <>
      <DialogActions
        onClose={onCloseDeleteVisitModal}
        open={openDeleteModal}
        dialog={t('global.form.deleteVisit')}
        action={onDeleteVisit}
      />
      <VisitForm
        open={openModal}
        onClose={handleModalClose}
        visitShema={VisitSchema}
        onSubmit={handleAddEditVisit}
        initialValues={initialValues}
        staff={staff}
        employees={employees}
        onDeleteVisit={onOpenDeleteVisit}
      />
      <ProgramCard>
        <Grid container justifyContent="center" spacing={{ xs: 1, md: 1, lg: 2 }}>
          <Grid item xs={11} md={11} lg={11}>
            <SelectInput
              value={programTitle}
              label={t('patient.form.title')}
              onChangeValue={handelProgramTitle}
              options={isLeumitPatient ? LEUMIT_PROGRAM_NAMES : MEUHEDET_PROGRAM_NAMES}
              error={titleError}
            />
          </Grid>
          <Grid item xs={11} md={11} lg={11} sx={{ mt: 2 }}>
            <ProgramStartEndFields
              programStart={programStart}
              programEnd={programEnd}
              programStartError={programStartError}
              programEndError={programEndError}
              handleProgramDatesChange={handleProgramDatesChange}
            />
          </Grid>

          <ProgramPaymentModeField
            paymentMode={paymentMode}
            clinicReferenceNumber={clinicReferenceNumber}
            handleChangeClinicReferenceNumber={handleChangeClinicReferenceNumber}
            handleChangePaymentMode={handleChangePaymentMode}
            isLeumitPatient
          />

          <Grid item xs={11} md={11} lg={11}>
            <StaffForm
              employees={employees}
              submitStaffMember={handleAddStaffMember}
              staffPositions={staffPositions}
            />
          </Grid>
        </Grid>

        <StaffView staffs={staff} employees={employees} handleDelete={removeStaffMember} />
      </ProgramCard>
      <CalendarCard>
        <Calender
          editable
          events={calendarEvents}
          onOpenDate={onOpenDate}
          onOpenEvent={onOpenVisit}
          onEventDrop={onEventDrop}
        />
      </CalendarCard>
      <ProgramFooter>
        <Button
          variant="contained"
          size="large"
          endIcon={program ? null : <AddIcon />}
          onClick={handleAddProgram}
          disabled={isLoading}
        >
          {program ? t('global.moreMenu.edit') : t('patient.form.addProgram')}
        </Button>
        <Button variant="contained" size="large" onClick={() => navigate(-1)} color="error">
          {t('user.form.goBack')}
        </Button>
      </ProgramFooter>
      {isLoading && <OverlaySpinner sx={{ opacity: 0.55 }} />}
    </>
  );
};

export default ProgramFields;
