import { IClinic } from '../interfaces/Models';
import { IQuery, IService } from '../interfaces/index';
import { HTTP_METHODS } from '../common/APIHelper';

const getAll = (query?: IQuery): IService => {
  return { route: '/api/clinic', method: HTTP_METHODS.GET, query };
};

const getOne = (id: string): IService => {
  return { route: `/api/clinic/${id}`, method: HTTP_METHODS.GET };
};

const add = (payload: IClinic): IService => {
  return { route: '/api/clinic', method: HTTP_METHODS.POST, payload };
};

const edit = (id: string, payload: IClinic): IService => {
  return { route: `/api/clinic/${id}`, method: HTTP_METHODS.PUT, payload };
};

const clinicService = {
  add,
  getAll,
  getOne,
  edit,
};

export default clinicService;
