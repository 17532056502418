import { IField } from './HMA';

export const physicalExaminationFields: IField[] = [
  {
    name: 'data.physicalExamination.HeadExam',
    label: 'report.firstVisitReport.physicalExamination.HeadExam',
    type: 'text',
    rows: 2,
    smallSize: 12,
    largeSize: 12,
  },
  {
    name: 'data.physicalExamination.AEGExam',
    label: 'report.firstVisitReport.physicalExamination.AEGExam',
    type: 'text',
    rows: 2,
    smallSize: 12,
    largeSize: 12,
  },
  {
    name: 'data.physicalExamination.EyesExam',
    label: 'report.firstVisitReport.physicalExamination.EyesExam',
    type: 'text',
    rows: 2,
    smallSize: 12,
    largeSize: 12,
  },
  {
    name: 'data.physicalExamination.LungsExam',
    label: 'report.firstVisitReport.physicalExamination.LungsExam',
    type: 'text',
    rows: 2,
    smallSize: 12,
    largeSize: 12,
  },
  {
    name: 'data.physicalExamination.HeartExam',
    label: 'report.firstVisitReport.physicalExamination.HeartExam',
    type: 'text',
    rows: 2,
    smallSize: 12,
    largeSize: 12,
  },
  {
    name: 'data.physicalExamination.BellyExam',
    label: 'report.firstVisitReport.physicalExamination.BellyExam',
    type: 'text',
    rows: 2,
    smallSize: 12,
    largeSize: 12,
  },
  {
    name: 'data.physicalExamination.LimbsExam',
    label: 'report.firstVisitReport.physicalExamination.LimbsExam',
    type: 'text',
    rows: 2,
    smallSize: 12,
    largeSize: 12,
  },
];
