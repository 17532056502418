import React from 'react';
import { useTranslation } from 'react-i18next';
import { Tab, Box, Card, Tabs, Container } from '@mui/material';
import { useRecoilValue } from 'recoil';
import { userAtom } from '../../../atoms/userAtom';
import HeaderBreadcrumbs from '../../../components/HeaderBreadcrumbs';
import Page from '../../../components/Page';
import ProfileCover from '../../../components/ProfileCover';
import { styled } from '@mui/system';
import Iconify from '../../../components/Iconify';
import useTabs from '../../../hooks/useTabs';
import Profile from './Profile';
import ProfileChangePassword from './ProfileChangePassword';
import { a11yProps } from '../../../components/Tabs';
import { tableAtom } from '../../../atoms/tableAtom';
import { TABS_NAMES } from '../../../common/Constants';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import AttachmentsTable from '../../../components/AttachmentsTable';

const tabIconSize = 20;

const TabsWrapperStyle = styled('div')(({ theme }) => ({
  zIndex: 9,
  bottom: 0,
  width: '100%',
  display: 'flex',
  position: 'absolute',
  backgroundColor: theme.palette.background.paper,
  [theme.breakpoints.up('sm')]: {
    justifyContent: 'center',
  },
  [theme.breakpoints.up('md')]: {
    justifyContent: 'flex-end',
    paddingRight: theme.spacing(3),
  },
}));

const UserProfile = () => {
  const { t } = useTranslation();
  const user = useRecoilValue(userAtom);
  const tableSession = useRecoilValue(tableAtom);

  const { currentTab, onChangeTab } = useTabs(tableSession[TABS_NAMES.PROFILE] || 'profile');

  const PROFILE_TABS = [
    {
      label: 'change_password',
      icon: <Iconify icon={'ic:round-vpn-key'} width={tabIconSize} height={tabIconSize} />,
      component: <ProfileChangePassword />,
    },
    {
      label: 'attachments',
      icon: <Iconify icon="majesticons:attachment" width={tabIconSize} height={tabIconSize} />,
      component: (
        <AttachmentsTable isProfileEmployee isLoading={false} employee={user || undefined} />
      ),
    },
    {
      label: 'profile',
      icon: <Iconify icon={'ic:round-account-box'} width={tabIconSize} height={tabIconSize} />,
      component: <Profile user={user} />,
    },
  ];

  return (
    <Page title={t('global.profile.profile')}>
      <Container maxWidth="lg">
        <HeaderBreadcrumbs
          heading={t('global.profile.profile')}
          links={[
            { name: t('global.profile.dashboard'), href: '/app/dashboard' },
            { name: `${user?.personalDetails.firstName} ${user?.personalDetails.lastName}` || '' },
          ]}
        />
        <Card
          sx={{
            mb: 3,
            height: 280,
            position: 'relative',
          }}
        >
          <ProfileCover user={user} />

          <TabsWrapperStyle>
            <Tabs
              allowScrollButtonsMobile
              variant="scrollable"
              scrollButtons="auto"
              value={currentTab}
              onChange={onChangeTab}
            >
              {PROFILE_TABS.map((tab, idx) => (
                <Tab
                  disableRipple
                  key={tab.label}
                  value={tab.label}
                  icon={tab.icon}
                  {...a11yProps(idx)}
                  iconPosition="start"
                  label={t(`global.profile.${tab.label}`)}
                />
              ))}
            </Tabs>
          </TabsWrapperStyle>
        </Card>

        {PROFILE_TABS.map((tab) => {
          const isMatched = tab.label === currentTab;
          return isMatched && <Box key={tab.label}>{tab.component}</Box>;
        })}
      </Container>
    </Page>
  );
};

export default UserProfile;
