import React, { useState } from 'react';
import { IAttachment } from '../interfaces/attachment';
import { Box, Button, IconButton, Stack, Tooltip } from '@mui/material';
import Iconify from './Iconify';
import { useTranslation } from 'react-i18next';
import ViewModal from './ViewModal';
import CloudinaryImage from './CloudinaryImage';
import { downloadCloudinaryAttachment } from '../utils/cloudinary';
import DownloadIcon from '@mui/icons-material/Download';

export default function FileView(props: IAttachment) {
  const { url, publicId, fileName } = props;
  const { t } = useTranslation();
  const [openImageViewer, setOpenImageViewer] = useState<boolean>(false);

  const handleCloseModal = () => setOpenImageViewer(false);

  const handleViewItem = () => {
    const isImage = /\.(jpg|jpeg|png|gif)$/i.test(url);

    if (isImage) {
      setOpenImageViewer(true);
    } else {
      window.open(`${url}?dl=1`, '_blank');
    }
  };
  const downloadImage = () => downloadCloudinaryAttachment(props);
  return (
    <>
      {openImageViewer && (
        <ViewModal onClose={handleCloseModal} open={openImageViewer} title={fileName}>
          <Stack direction={'column'} spacing={2} width={'100%'}>
            <Box>
              <Button
                endIcon={<DownloadIcon />}
                color="secondary"
                variant="contained"
                onClick={downloadImage}
              >
                {t('global.attachments.download')}
              </Button>
            </Box>
            <CloudinaryImage publicId={publicId} />
          </Stack>
        </ViewModal>
      )}
      <Tooltip title={`${t('global.attachments.view')}`}>
        <IconButton onClick={handleViewItem} color="primary">
          <Iconify icon="carbon:view-filled" />
        </IconButton>
      </Tooltip>
    </>
  );
}
