import React from 'react';
import { Grid, MenuItem, Typography } from '@mui/material';
import { Select } from 'formik-mui';
import { Field, useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';
import { EMPLOYEE_FAMILY_STATUS } from '../../common/Constants';
import { StyledTextField, sxFormControl } from '../../styles/FormFieldsStyles';
import PatientClinicField from './PatientClinicField';
import FormikDatePickerField from '../../components/Form/FormikDatePickerField';

const PatientFormFields: React.FC = () => {
  const { t } = useTranslation();
  const { touched, errors, values }: any = useFormikContext();

  const isPassport = typeof values.isPassport == 'string' && values['isPassport'] == 'yes';

  return (
    <>
      <Field
        fullWidth
        component={StyledTextField}
        type="text"
        label={t('personalDetails.firstName')}
        name="personalDetails.firstName"
      />
      <Field
        fullWidth
        component={StyledTextField}
        type="text"
        label={t('personalDetails.lastName')}
        name="personalDetails.lastName"
      />
      <Grid container spacing={2}>
        <Grid item xs={12} md={6} lg={6}>
          <Field
            fullWidth
            component={StyledTextField}
            type="text"
            label={isPassport ? t('patient.form.passport') : t('patient.form.idNumber')}
            name="idNumber"
          />
        </Grid>
        <Grid item xs={12} md={6} lg={6}>
          <Field
            component={Select}
            name="isPassport"
            label={t('patient.form.passportIdNumber')}
            formControl={{ sx: sxFormControl }}
          >
            <MenuItem value="no">{t('patient.form.idNumber')}</MenuItem>
            <MenuItem value="yes">{t('patient.form.passport')}</MenuItem>
          </Field>
        </Grid>
      </Grid>
      <PatientClinicField touched={touched} errors={errors} />

      <FormikDatePickerField
        label={t('personalDetails.birthDate')}
        name="personalDetails.birthDate"
      />

      <Field
        fullWidth
        component={StyledTextField}
        type="text"
        label={t('personalDetails.primaryPhone')}
        name="personalDetails.primaryPhone"
      />

      <Field
        fullWidth
        component={StyledTextField}
        type="text"
        label={t('personalDetails.secondaryPhone')}
        name="personalDetails.secondaryPhone"
      />
      <Field
        component={Select}
        name="personalDetails.martialStatus"
        label={t('personalDetails.martialStatus')}
        formControl={{ sx: sxFormControl }}
      >
        {Object.values(EMPLOYEE_FAMILY_STATUS).map((status, idx) => {
          return (
            <MenuItem key={idx} value={status}>
              {t(`global.familyStatus.${status}`)}
            </MenuItem>
          );
        })}
      </Field>
      <Field
        fullWidth
        component={StyledTextField}
        type="text"
        label={t('personalDetails.address')}
        name="personalDetails.address"
      />

      <Field
        component={Select}
        name="personalDetails.gender"
        label={t('personalDetails.gender')}
        formControl={{ sx: sxFormControl }}
      >
        <MenuItem value="MALE">{t('personalDetails.MALE')}</MenuItem>
        <MenuItem value="FEMALE">{t('personalDetails.FEMALE')}</MenuItem>
      </Field>
      <Typography variant="subtitle2" gutterBottom align="left">
        {t('patient.info.primaryInfo')}
      </Typography>
      <Field
        fullWidth
        component={StyledTextField}
        type="text"
        label={t('primaryContact.firstName')}
        name="primaryContact.firstName"
      />
      <Field
        fullWidth
        component={StyledTextField}
        type="text"
        label={t('primaryContact.lastName')}
        name="primaryContact.lastName"
      />
      <Field
        fullWidth
        component={StyledTextField}
        type="text"
        label={t('primaryContact.primaryPhone')}
        name="primaryContact.primaryPhone"
      />
    </>
  );
};

export default PatientFormFields;
