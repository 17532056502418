import { Link as RouterLink } from 'react-router-dom';
import { Box, Link, Typography, Breadcrumbs as MUIBreadcrumbs } from '@mui/material';

interface BreadcrumbsProps {
  activeLast?: boolean;
  links?: Array<any>;
}

export default function Breadcrumbs({ links, activeLast = false, ...other }: BreadcrumbsProps) {
  if (!links) {
    return null;
  }
  const currentLink = links[links.length - 1].name;

  const listDefault = links.map((link) => <LinkItem key={link.name} link={link} />);

  const listActiveLast = links.map((link) => (
    <div key={link.name}>
      {link.name !== currentLink ? (
        <LinkItem link={link} />
      ) : (
        <Typography
          variant="subtitle2"
          sx={{
            maxWidth: 260,
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            color: 'text.disabled',
            textOverflow: 'ellipsis',
          }}
        >
          {currentLink}
        </Typography>
      )}
    </div>
  ));

  return (
    <MUIBreadcrumbs
      separator={
        <Box
          component="span"
          sx={{ width: 4, height: 4, borderRadius: '50%', bgcolor: 'text.disabled' }}
        />
      }
      {...other}
    >
      {activeLast ? listDefault : listActiveLast}
    </MUIBreadcrumbs>
  );
}

// ----------------------------------------------------------------------

export interface ILink {
  href?: string;
  icon?: any;
  name: string;
}

interface LinkItemProps {
  link: ILink;
}
function LinkItem({ link }: LinkItemProps) {
  const { href, name, icon } = link;
  return (
    <>
      {href ? (
        <Link
          key={name}
          variant="subtitle2"
          component={RouterLink}
          to={href || '#'}
          sx={{
            lineHeight: 2,
            display: 'flex',
            alignItems: 'center',
            color: 'secondary.main',
            '& > div': { display: 'inherit' },
          }}
        >
          {icon && <Box sx={{ mr: 1, '& svg': { width: 20, height: 20 } }}>{icon}</Box>}
          {name}
        </Link>
      ) : (
        <Typography
          variant="subtitle2"
          sx={{
            maxWidth: 260,
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            color: 'text.disabled',
            textOverflow: 'ellipsis',
          }}
        >
          {name}
        </Typography>
      )}
    </>
  );
}
