import React from 'react';
import { Chip } from '@mui/material';
import { theme } from '../../theme';

interface IEmployeeChip {
  employeeName?: string;
  handleDeleteEmployeeFilter: () => void;
}
const EmployeeChip = ({ employeeName, handleDeleteEmployeeFilter }: IEmployeeChip) => {
  return (
    <>
      {employeeName && (
        <Chip
          label={employeeName}
          color="info"
          onDelete={handleDeleteEmployeeFilter}
          sx={{ direction: 'ltr', mt: theme.spacing(0.5) }}
        />
      )}
    </>
  );
};

export default EmployeeChip;
