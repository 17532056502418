/* eslint-disable new-cap */
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { ReportData } from '../../../interfaces/Models';
import FetcherService from '../../../hooks/FetcherService';
import PatientProgramService from '../../../services/PatientProgramService';
import FormModal from '../../../components/Modals/FormModal';
import { IMissingProgramReferenceNumberForm } from './MissingProgramReferenceNumberForm';
import { PROGRAM_PAYMENT_MODE } from '../../../common/Constants';
import Form from '../../../components/Form/FormikForm';
import { Box, MenuItem } from '@mui/material';
import { IOption } from '../../../common/HMA';
import { Select } from 'formik-mui';
import { sxFormControl } from '../../../styles/FormFieldsStyles';
import { Field } from 'formik';

const EditProgramPaymentModeForm = ({
  openModal,
  handleCloseModal,
  record,
  handleProgramsUpdate,
  handleSaveLastActionValue,
}: IMissingProgramReferenceNumberForm) => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const schema = Yup.object({
    paymentMode: Yup.string().required(`${t('clinic.form.required')}`),
  });

  const handleChangeProgramPaymentMode = async (values: ReportData) => {
    await FetcherService<any>({
      service: PatientProgramService.edit(record?._id || 'empty', {
        paymentMode: values.paymentMode,
      }),
      setLoading: setIsLoading,
    });
    handleCloseModal();
    if (handleSaveLastActionValue) {
      handleSaveLastActionValue(t(`program.paymentModeOptions.${values.paymentMode}`));
    }
    if (record) {
      handleProgramsUpdate(record);
    }
  };

  const paymentModeOptions = [
    {
      label: t('program.paymentModeOptions.PROGRAM_LEVEL'),
      value: PROGRAM_PAYMENT_MODE.PROGRAM_LEVEL,
    },
    {
      label: t('program.paymentModeOptions.VISIT_LEVEL'),
      value: PROGRAM_PAYMENT_MODE.VISIT_LEVEL,
    },
    {
      label: t('program.paymentModeOptions.FIXED_PRICE'),
      value: PROGRAM_PAYMENT_MODE.FIXED_PRICE,
    },
  ];

  return (
    <FormModal
      open={openModal}
      onClose={handleCloseModal}
      text={t('program.paymentMode')}
      loading={isLoading}
    >
      <Form
        onCancel={handleCloseModal}
        submit={handleChangeProgramPaymentMode}
        initialValues={{ paymentMode: record?.paymentMode }}
        validationSchema={schema}
        cancelText={t('clinic.form.cancel')}
        submitText={t('clinic.form.edit')}
      >
        <Box sx={{ my: 1, width: '100%' }}>
          <Field
            component={Select}
            name={'paymentMode'}
            label={t('program.paymentMode')}
            formControl={{ sx: sxFormControl }}
          >
            {paymentModeOptions.map((option: IOption, idx: number) => {
              return (
                <MenuItem key={`${idx}_${option.value}`} value={option.value}>
                  {option.label}
                </MenuItem>
              );
            })}
          </Field>
        </Box>
      </Form>
    </FormModal>
  );
};

export default EditProgramPaymentModeForm;
