import { useAbility } from '@casl/react';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { AbilitySubjects } from '../../casl/ability';
import { AbilityContext } from '../../casl/can';
import MoreOptions from '../../components/MoreOptions';
import {
  IPatientProgram,
  IPatientProgramFlat,
  IProgramReleaseReport,
} from '../../interfaces/Models';
import { handleTableAtomChange } from '../../utils/Helper';
import { Icon } from '@iconify/react';
import editFill from '@iconify/icons-eva/edit-fill';
import { theme } from '../../theme';
import { PATIENT_PROGRAM_STATUS } from '../../common/Constants';
import { checkAllProgramVisitsDone, getReleaseReportHandler } from '../../common/columns/Programs';
import done from '@iconify/icons-eva/done-all-fill';
import { getHospitalDaysActions } from './hospitalDays/HospitalDaysColumns';

interface IProgramsMoreOptions {
  program: IPatientProgram | IPatientProgramFlat | IProgramReleaseReport;
  tableSessionId: string;
  handleCompleteProgram: (
    value: IPatientProgram | IPatientProgramFlat | IProgramReleaseReport
  ) => void;
  handleOpenEditReleaseStatusModal?: (
    value: IProgramReleaseReport | IPatientProgram | IPatientProgramFlat
  ) => void;
  handleOpenAddHospitalDaysForm?: (
    value: IPatientProgram | IPatientProgramFlat | IProgramReleaseReport
  ) => void;
  onDownloadGlobalReleaseReport: (
    value: IPatientProgramFlat | IPatientProgram | IProgramReleaseReport,
    reportName?: string
  ) => void;
  isProgramDone?: boolean;
}
const ProgramsMoreOptions = ({
  program,
  tableSessionId,
  handleCompleteProgram,
  handleOpenAddHospitalDaysForm,
  handleOpenEditReleaseStatusModal,
  onDownloadGlobalReleaseReport,
  isProgramDone = false,
}: IProgramsMoreOptions) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const ability = useAbility(AbilityContext);
  const updateAdminAbility = ability.can('update', AbilitySubjects.ADMIN_ABILITY);
  const manageAdminAbility = ability.can('manage', AbilitySubjects.ADMIN_ABILITY);
  const updateAdminAndHmaAbility = ability.can('update', AbilitySubjects.ADMIN_AND_HMA_ABILITY);

  const handelEditReport = (program: IPatientProgramFlat | IPatientProgram) => {
    handleTableAtomChange(tableSessionId, program._id);
    navigate(`/app/patient-program/edit/${program._id}`);
  };

  const getReleaseStatusActionHandler = () => {
    return handleOpenEditReleaseStatusModal
      ? [
          {
            name: t('program.releaseReports.editReleaseStatus'),
            handler: handleOpenEditReleaseStatusModal,
            icon: (
              <Icon icon={editFill} width={24} height={24} color={theme.palette.secondary.main} />
            ),
            isVisible: updateAdminAbility,
          },
        ]
      : [];
  };

  const isMarkedAsDoneVisible = useMemo(
    () =>
      manageAdminAbility &&
      checkAllProgramVisitsDone(program, isProgramDone) &&
      program.status !== PATIENT_PROGRAM_STATUS.CANCELED &&
      program.status !== PATIENT_PROGRAM_STATUS.DONE,
    [program, isProgramDone, manageAdminAbility]
  );

  return (
    <MoreOptions
      actions={[
        {
          name: t('global.moreMenu.edit'),
          handler: handelEditReport,
          icon: (
            <Icon icon={editFill} width={24} height={24} color={theme.palette.secondary.main} />
          ),
          isVisible: program.status != PATIENT_PROGRAM_STATUS.CANCELED && updateAdminAbility,
        },
        ...getReleaseStatusActionHandler(),
        {
          name: t('global.moreMenu.completeProgram'),
          handler: handleCompleteProgram,
          icon: <Icon icon={done} width={24} height={24} />,
          isVisible: isMarkedAsDoneVisible,
        },
        ...getHospitalDaysActions(program, handleOpenAddHospitalDaysForm, updateAdminAndHmaAbility),
        ...getReleaseReportHandler(onDownloadGlobalReleaseReport),
      ]}
      record={program}
    />
  );
};

export default ProgramsMoreOptions;
