/* eslint-disable new-cap */
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useParams, useOutletContext } from 'react-router-dom';
import * as Yup from 'yup';
import { reportTypes } from '../../../common/Constants';
import ErrorMessage from '../../../components/ErrorMessage';
import { HMBRepeatedValues } from '../../../common/reportsFieldsValues/hmb/HMBRepeatedValues';
import HMBRepeatedFields from './fields/HMBRepeatedFields';
import ReportsForm from '../../../components/Form/ReportsForm';

const HMBRepeated = () => {
  const { t } = useTranslation();
  const { visitId } = useParams();
  const data: any = useOutletContext();

  const initialValues =
    data && data.name
      ? data
      : {
          name: reportTypes.HMB_REPEATED,
          visit: visitId,
          data: data ? data : HMBRepeatedValues,
        };
  const ReportSchema = Yup.object({
    name: Yup.string().required(t('clinic.form.required')),
    visit: Yup.string().required(t('clinic.form.required')),
  });

  const HtmlId = 'HMBRepeated';

  if (data?.name && data.name != reportTypes.HMB_REPEATED) {
    return <ErrorMessage error={t('global.errors.somethingWrong')} />;
  }
  return (
    <ReportsForm
      initialValues={initialValues}
      visitId={visitId || ''}
      validationSchema={ReportSchema}
      resetForm={true}
      resetFormValues={{ ...initialValues, data: HMBRepeatedValues }}
      name={data?.name}
    >
      <HMBRepeatedFields id={HtmlId} />
    </ReportsForm>
  );
};

export default HMBRepeated;
