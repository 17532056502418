import React from 'react';
import { Card, Grid, Typography, InputLabel, Box } from '@mui/material';
import { styled } from '@mui/system';
import { theme } from '../theme';
import { format } from 'date-fns';
import { useTranslation } from 'react-i18next';
import { EMPLOYEE_FAMILY_STATUS } from '../common/Constants';
import { getDeletedRecordOriginalValue } from '../utils/table';

const StyledCard = styled(Card, { name: 'StyledCard', slot: 'Wrapper' })({
  marginBottom: theme.spacing(5),
});

const StyledInputLabel = styled(InputLabel, { name: 'StyledInputLabel', slot: 'Wrapper' })({
  marginRight: theme.spacing(1),
});

const StyledFieldInfo = styled('div')({
  display: 'flex',
  marginBottom: theme.spacing(1),
});

const StyledHeader = styled('div')({
  marginBottom: theme.spacing(2),
});

const StyledBox = styled(Box, { name: 'StyledBox', slot: 'Wrapper' })({
  padding: theme.spacing(4),
});

interface PersonalInfoCardProps {
  title: string;
  details: {
    idNumber?: string;
    clinic?: string;
    firstName: string;
    lastName: string;
    birthDate?: Date;
    primaryPhone: string;
    secondaryPhone?: string;
    martialStatus?: string;
    address?: string;
    gender?: string;
    email?: string;
  };
}
const InfoCard = ({ title, details }: PersonalInfoCardProps) => {
  const { t } = useTranslation();

  return (
    <StyledCard>
      <StyledBox flexGrow={1}>
        <StyledHeader>
          <Typography variant="h6" gutterBottom>
            {title}
          </Typography>
          <hr />
        </StyledHeader>
        <Grid container justifyContent="center">
          <Grid item xs={12} md={4} lg={4}>
            <StyledFieldInfo>
              <StyledInputLabel>{t('user.form.fullName')}:</StyledInputLabel>
              <p>{`${details.firstName} ${details.lastName} `}</p>
            </StyledFieldInfo>
            <StyledFieldInfo>
              <StyledInputLabel>{t('personalDetails.primaryPhone')}:</StyledInputLabel>
              <p>{`${details.primaryPhone} `}</p>
            </StyledFieldInfo>
            <StyledFieldInfo>
              <StyledInputLabel>{t('personalDetails.secondaryPhone')}:</StyledInputLabel>
              <p>{`${details.secondaryPhone} `}</p>
            </StyledFieldInfo>
          </Grid>
          <Grid item xs={12} md={4} lg={4}>
            <StyledFieldInfo>
              <StyledInputLabel>{t('personalDetails.birthDate')}</StyledInputLabel>
              <p>{details.birthDate ? format(new Date(details.birthDate), 'dd-MM-yyyy') : null}</p>
            </StyledFieldInfo>
            <StyledFieldInfo>
              <StyledInputLabel>{t('personalDetails.martialStatus')}: </StyledInputLabel>
              <p>
                {details.martialStatus &&
                Object.values(EMPLOYEE_FAMILY_STATUS).indexOf(details.martialStatus) > -1
                  ? t(`global.familyStatus.${details.martialStatus}`)
                  : details.martialStatus}
              </p>
            </StyledFieldInfo>
            <StyledFieldInfo>
              <StyledInputLabel>{t('personalDetails.gender')}: </StyledInputLabel>
              <p>{t(`personalDetails.${details.gender}`)}</p>
            </StyledFieldInfo>
          </Grid>
          <Grid item xs={12} md={4} lg={4}>
            <StyledFieldInfo>
              <StyledInputLabel>{t('patient.form.idNumber')}: </StyledInputLabel>{' '}
              <p>{details.idNumber ? getDeletedRecordOriginalValue(details.idNumber) : ''}</p>
            </StyledFieldInfo>
            <StyledFieldInfo>
              <StyledInputLabel>
                {details.email ? t('user.form.email') : t('patient.form.clinic')}
              </StyledInputLabel>
              <p>{details.email ? getDeletedRecordOriginalValue(details.email) : details.clinic}</p>
            </StyledFieldInfo>
            <StyledFieldInfo>
              <StyledInputLabel>{t('personalDetails.address')}: </StyledInputLabel>
              <p>{details.address}</p>
            </StyledFieldInfo>
          </Grid>
        </Grid>
      </StyledBox>
    </StyledCard>
  );
};

export default InfoCard;
