import { getIn, useFormikContext } from 'formik';
import React from 'react';
import SectionDivider from '../../../../components/SectionDivider';
import { useTranslation } from 'react-i18next';
import PracticeFields from '../../sharedFields/PracticeFields';
import { practiceCategories } from '../../../../common/PracticeData';
import GenericField from '../../sharedFields/GenericField';

interface HMMRepeatedFieldsProps {
  id: string;
}
const HMMRepeatedFields = ({ id }: HMMRepeatedFieldsProps) => {
  const { t } = useTranslation();
  const { values, setFieldValue }: any = useFormikContext();
  const practicesValues = getIn(values, 'data.exercises');

  return (
    <>
      <GenericField
        id={`${id}_Treatment`}
        fields={[
          {
            name: 'data.treatment',
            label: 'report.HMMREPEATED.treatment',
            type: 'textareaWithEditor',
          },
          {
            name: 'data.status',
            label: 'report.HMMREPEATED.status',
            type: 'textareaWithEditor',
          },
          {
            name: 'data.currentStatusDuringTreatment',
            label: 'report.HMMREPEATED.currentStatusDuringTreatment',
            type: 'textareaWithEditor',
          },
          {
            name: 'data.subjectiveNotes',
            label: 'report.HMMREPEATED.subjectiveNotes',
            type: 'textareaWithEditor',
          },
          {
            name: 'data.PatientInstructionsAndRecommendations',
            label: 'report.HMMREPEATED.PatientInstructionsAndRecommendations',
            type: 'textareaWithEditor',
          },
        ]}
      />
      <PracticeFields
        subHeader={t('report.HMMREPEATED.exercises')}
        onChangeFormikValue={setFieldValue}
        fieldName="data.exercises"
        categories={practiceCategories}
        practicesValues={practicesValues}
      />
      <SectionDivider />
    </>
  );
};

export default HMMRepeatedFields;
