import React from 'react';
import { useTranslation } from 'react-i18next';
import BarChart from '../../../components/BarChart';
import ErrorMessage from '../../../components/ErrorMessage';
import BarChartWidgetSkeleton from '../../../components/skeletons/BarChartWidgetSkeleton';
import useService from '../../../hooks/HookService';
import useSearchFilter from '../../../hooks/useSearchFilter';
import StatisticsService from '../../../services/StatisticsService';
import { getStartEndDateDep } from '../../../utils/Helper';

interface IProgramType {
  activePrograms: number;
  donePrograms: number;
}
type ProgramTypesChartData = {
  // eslint-disable-next-line no-unused-vars
  [key in string]: IProgramType;
};

const ProgramsQuantityWidget = () => {
  const { t } = useTranslation();

  const { pageInfo } = useSearchFilter();

  const { from, to, range, month, year } = pageInfo;

  const { isLoading, data, error, errorMessage } = useService<ProgramTypesChartData>({
    service: StatisticsService.getProgramTypesChartData({
      fromDate: getStartEndDateDep(pageInfo),
      toDate: getStartEndDateDep(pageInfo, true),
    }),
    deps: [from, to, range, month, year],
  });

  if (isLoading) {
    return <BarChartWidgetSkeleton />;
  }
  if (error || !data) {
    return <ErrorMessage error={errorMessage} />;
  }

  const chartCategories = Object.keys(data);
  const chartData = [
    {
      name: t('global.dashboard.active'),
      data: data
        ? chartCategories.map((type: string) =>
            data[type].activePrograms == 0 ? null : data[type].activePrograms
          )
        : [],
    },
    {
      name: t('global.dashboard.done'),
      data: data
        ? chartCategories.map((type: string) =>
            data[type].donePrograms == 0 ? null : data[type].donePrograms
          )
        : [],
    },
  ];

  return (
    <BarChart
      title={t('global.dashboard.programTypes')}
      chartCategories={chartCategories}
      chartData={chartData}
    />
  );
};

export default ProgramsQuantityWidget;
