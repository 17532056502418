import React from 'react';
import { IUserFlat } from '../interfaces/Models';
import { Box, TextField } from '@mui/material';
import { Autocomplete, AutocompleteRenderInputParams } from 'formik-mui';
import { t } from 'i18next';
import { Field } from 'formik';
import { theme } from '../theme';

export interface IEmployeesFilter {
  employees?: IUserFlat[];
}
const EmployeesFilter = ({ employees }: IEmployeesFilter) => {
  return (
    <Box flexGrow={1} sx={{ mt: theme.spacing(1), mb: theme.spacing(1), width: '100%' }}>
      <Field
        fullWidth
        id="Auto_Complete_Filter"
        name="employee"
        component={Autocomplete}
        options={employees || []}
        getOptionLabel={(option: IUserFlat) => option.fullName}
        renderInput={(params: AutocompleteRenderInputParams) => (
          <TextField
            label={t('global.roles.EMPLOYEE')}
            {...params}
            name="employee"
            variant="outlined"
          />
        )}
      />
    </Box>
  );
};

export default EmployeesFilter;
