import React from 'react';
import { IEmployeeVisitsDelayCalculationFinancialData } from '../../../interfaces/Reports';
import useSearchFilter from '../../../hooks/useSearchFilter';
import { useTranslation } from 'react-i18next';
import useService from '../../../hooks/HookService';
import ReportsService from '../../../services/FinancialReportsService';
import { getStartEndDateDep } from '../../../utils/Helper';
import ErrorMessage from '../../../components/ErrorMessage';
import OverlaySpinner from '../../../components/OverlaySpinner';
import Table from '../../../components/Table';
import EmployeeVisitsDelayCalculationReportTableExpandCard from './EmployeeVisitsDelayCalculationReportTableExpandCard';
import { Icon, IconButton } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import VisibilityIcon from '@mui/icons-material/Visibility';

export default function EmployeeVisitsDelayCalculationReportTable() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { pageInfo } = useSearchFilter();

  const { range, from, to, month, year } = pageInfo;

  const { isLoading, data, loadingNumber, error, errorMessage } = useService<
    IEmployeeVisitsDelayCalculationFinancialData[]
  >({
    service: ReportsService.getEmployeeVisitsDelayFinancialReport({
      fromDate: getStartEndDateDep(pageInfo),
      toDate: getStartEndDateDep(pageInfo, true),
    }),
    deps: [from, to, range, month, year],
  });

  if (error) {
    return <ErrorMessage error={errorMessage} />;
  }
  if (isLoading && loadingNumber == 1) {
    return <OverlaySpinner />;
  }

  const onRowView = (employeeId: string) => {
    navigate(`/app/user/${employeeId}`);
  };

  const columns = [
    {
      id: 'employeeName',
      Header: `${t('global.roles.EMPLOYEE')}`,
      accessor: 'fullName',
    },
    {
      Header: `${t('user.form.visitRate')}`,
      accessor: 'visitRate',
    },
    {
      id: 'completedVisits',
      Header: `${t('financialReports.visitsDelayCalculation.totalCompletedVisits')}`,
      accessor: 'totalDoneVisits',
    },
    {
      id: 'completedVisitsCost',
      Header: `${t('financialReports.visitsDelayCalculation.originalTotalAmount')}`,
      accessor: 'totalOriginalAmount',
    },
    {
      Header: `${t('financialReports.visitsDelayCalculation.totalFixedVisitsCost')}`,
      accessor: 'totalFixedAmount',
    },
    {
      Header: t('clinic.table.actions'),
      accessor: (data: any) => {
        return (
          <IconButton onClick={() => onRowView(data._id)}>
            <VisibilityIcon color="secondary" />
          </IconButton>
        );
      },
    },
  ];

  return (
    <Table
      columns={columns}
      data={data || []}
      count={data?.length || 0}
      isLoading={isLoading}
      showPagination={false}
      ExpandCard={EmployeeVisitsDelayCalculationReportTableExpandCard}
    />
  );
}
