/* eslint-disable new-cap */
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { VISIT_STATUS } from '../../common/Constants';
import { getReportRoute } from '../../common/ReportsCommon';
import Calender, { EventDropData } from '../../components/Calender';
import { IHospitalDays, IPatientProgram } from '../../interfaces/Models';
import { CalendarCard } from '../../styles/TabsStyles';
import {
  checkEmployeeReportAuthorization,
  checkIfUserIsAdmin,
  getProgramVisitsEvents,
} from '../../utils/Helper';
import { EventClickArg } from '@fullcalendar/core';
import { useTranslation } from 'react-i18next';
import MultiMonthCalendar from '../../components/MultiMonthCalendar';
import { userAtom } from '../../atoms/userAtom';
import { useRecoilValue } from 'recoil';
import { format } from 'date-fns';
import VisitService from '../../services/VisitService';
import FetcherService from '../../hooks/FetcherService';

interface IProgramCalendar {
  program: IPatientProgram;
  handleOpenEditHospitalDaysForm?: (value: IHospitalDays) => void;
  updateAdminAndHmaAbility?: boolean;
  isMonthsScrollable?: boolean;
  handleProgramDataUpdate: (val: IPatientProgram) => void;
}
const ProgramCalendar = ({
  program,
  handleOpenEditHospitalDaysForm,
  updateAdminAndHmaAbility,
  isMonthsScrollable = false,
  handleProgramDataUpdate,
}: IProgramCalendar) => {
  const { visits, hospitalDays } = program;
  const { t } = useTranslation();
  const navigate = useNavigate();
  const user = useRecoilValue(userAtom);
  const isUserAdmin = checkIfUserIsAdmin(user);

  const onHandleVisitOpen = (value: EventClickArg) => {
    const eventId = value.event.id;
    const visitData = visits.find((visit: any) => visit?._id == eventId);
    const hospitalDaysPeriod = hospitalDays?.find((period: IHospitalDays) => period._id == eventId);

    if (hospitalDaysPeriod && handleOpenEditHospitalDaysForm && updateAdminAndHmaAbility) {
      handleOpenEditHospitalDaysForm(hospitalDaysPeriod);
    }
    if (visitData) {
      const BASE_URL = `/app/report/visit/${visitData._id}`;
      const url = getReportRoute(visitData);
      if (visitData.status == VISIT_STATUS.PENDING && checkEmployeeReportAuthorization(visitData)) {
        navigate(`${BASE_URL}/${url}`);
      } else {
        navigate(`${BASE_URL}/${url}/view`);
      }
    }
  };

  const events = getProgramVisitsEvents(t, visits, hospitalDays);

  const onEventDrop = async ({ oldStartDate, newStartDate, eventId }: EventDropData) => {
    const isDateChanged =
      format(new Date(oldStartDate), 'dd-MM-yyyy') !== format(new Date(newStartDate), 'dd-MM-yyyy');
    const visitEdited = visits.find((visit) => visit._id === eventId);

    if (isDateChanged && visitEdited) {
      const programVisitUpdated = visits.map((visit) => {
        if (visit._id === eventId) {
          return { ...visit, dueDate: newStartDate };
        }
        return visit;
      });

      const onSuccess = () => {
        handleProgramDataUpdate &&
          handleProgramDataUpdate({ ...program, visits: programVisitUpdated });
      };
      await FetcherService<any>({
        service: VisitService.edit(visitEdited?._id || 'empty', { dueDate: newStartDate }),
        onSuccess,
      });
    }
  };

  return (
    <CalendarCard>
      {isMonthsScrollable ? (
        <MultiMonthCalendar
          isMonthsScrollable
          events={events}
          editable={isUserAdmin}
          startDate={true}
          onOpenEvent={onHandleVisitOpen}
          monthsViewDivider
          onEventDrop={onEventDrop}
        />
      ) : (
        <Calender
          editable={isUserAdmin}
          events={events}
          startDate={true}
          onOpenEvent={onHandleVisitOpen}
          onEventDrop={onEventDrop}
        />
      )}
    </CalendarCard>
  );
};

export default ProgramCalendar;
