import { MenuItem } from '@mui/material';
import { Select } from 'formik-mui';
import { Field } from 'formik';
import React from 'react';
import { t } from 'i18next';
import { USER_ROLES, EMPLOYEE_POSITIONS, EMPLOYEE_FAMILY_STATUS } from '../../common/Constants';
import { StyledTextField, sxFormControl } from '../../styles/FormFieldsStyles';
import PasswordField from './PasswordField';

import FormikDatePickerField from '../../components/Form/FormikDatePickerField';

interface UserFormFieldsProps {
  edit: boolean;
}

const UserFormFields: React.FC<UserFormFieldsProps> = ({ edit }) => {
  return (
    <>
      <Field
        fullWidth
        component={StyledTextField}
        type="text"
        label={t('personalDetails.firstName')}
        name="personalDetails.firstName"
      />
      <Field
        fullWidth
        component={StyledTextField}
        type="text"
        label={t('personalDetails.lastName')}
        name="personalDetails.lastName"
      />
      <Field
        fullWidth
        component={StyledTextField}
        type="email"
        label={t('user.form.email')}
        name="email"
      />

      {edit && (
        <>
          <PasswordField label={t('user.form.password')} name="password" />
          <Field
            fullWidth
            component={StyledTextField}
            type="password"
            label={t('user.form.confirmPassword')}
            name="confirmPassword"
          />
        </>
      )}
      <Field
        fullWidth
        component={StyledTextField}
        type="string"
        label={t('patient.form.idNumber')}
        name="idNumber"
      />
      <Field
        fullWidth
        component={StyledTextField}
        type="number"
        label={t('user.form.visitRate')}
        name="visitRate"
      />
      <Field
        fullWidth
        component={StyledTextField}
        type="text"
        label={t('user.form.licenseNumber')}
        name="licenseNumber"
      />
      <Field
        component={Select}
        name="role"
        label={t('user.form.role')}
        formControl={{ sx: sxFormControl }}
      >
        {Object.values(USER_ROLES).map((role, idx) => {
          return (
            <MenuItem key={idx} value={role}>
              {t(`global.roles.${role}`)}
            </MenuItem>
          );
        })}
      </Field>

      <Field
        component={Select}
        name="position"
        label={t('user.form.position')}
        formControl={{ sx: sxFormControl }}
      >
        {Object.values(EMPLOYEE_POSITIONS).map((employee, idx) => {
          return (
            <MenuItem key={idx} value={employee}>
              {t(`global.positions.${employee}`)}
            </MenuItem>
          );
        })}
        <MenuItem value="other" key="other">
          {t(`global.positions.other`)}
        </MenuItem>
      </Field>
      <Field
        fullWidth
        component={StyledTextField}
        type="text"
        label={t('user.form.positionDescription')}
        name="positionDescription"
      />
      <Field
        fullWidth
        component={StyledTextField}
        type="string"
        label={t('user.form.employeeNumber')}
        name="employeeNumber"
      />
      <FormikDatePickerField label={t('user.form.startDate')} name="startDate" />
      <FormikDatePickerField label={t('user.form.endDate')} name="endDate" />

      <FormikDatePickerField
        label={t('personalDetails.birthDate')}
        name="personalDetails.birthDate"
      />
      <Field
        fullWidth
        component={StyledTextField}
        type="text"
        label={t('personalDetails.primaryPhone')}
        name="personalDetails.primaryPhone"
      />
      <Field
        fullWidth
        component={StyledTextField}
        type="text"
        label={t('personalDetails.secondaryPhone')}
        name="personalDetails.secondaryPhone"
      />
      <Field
        component={Select}
        name="personalDetails.martialStatus"
        label={t('personalDetails.martialStatus')}
        formControl={{ sx: sxFormControl }}
      >
        {Object.values(EMPLOYEE_FAMILY_STATUS).map((status, idx) => {
          return (
            <MenuItem key={idx} value={status}>
              {t(`global.familyStatus.${status}`)}
            </MenuItem>
          );
        })}
      </Field>
      <Field
        fullWidth
        component={StyledTextField}
        type="text"
        label={t('personalDetails.address')}
        name="personalDetails.address"
      />
      <Field
        component={Select}
        name="personalDetails.gender"
        label={t('personalDetails.gender')}
        formControl={{ sx: sxFormControl }}
      >
        <MenuItem value="MALE">{t('personalDetails.MALE')}</MenuItem>
        <MenuItem value="FEMALE">{t('personalDetails.FEMALE')}</MenuItem>
      </Field>
    </>
  );
};

export default UserFormFields;
