import React from 'react';
import Label from './Label';
import { ColorObject, PROGRAM_PAYMENT_MODE } from '../common/Constants';
import { useTranslation } from 'react-i18next';

const PAYMENT_MODE_COLORS: ColorObject = {
  PROGRAM_LEVEL: 'primary',
  VISIT_LEVEL: 'secondary',
  FIXED_PRICE: 'warning',
};

export default function PaymentLevelLabel({
  paymentMode = PROGRAM_PAYMENT_MODE.PROGRAM_LEVEL,
}: {
  paymentMode?: string;
}) {
  const { t } = useTranslation();
  return (
    <Label variant="ghost" color={PAYMENT_MODE_COLORS[paymentMode.trim()]}>
      {t(`program.paymentModeOptionsShort.${paymentMode}`)}
    </Label>
  );
}
