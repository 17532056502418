import { getIn, useFormikContext } from 'formik';
import React from 'react';
import SectionDivider from '../../../../components/SectionDivider';
import EssentialSignsFields from '../../sharedFields/EssentialSignsFields';
import PainAssessment from '../../sharedFields/PainAssessment';
import GenericField from '../../sharedFields/GenericField';

interface HMBRepeatedFieldsProps {
  id: string;
}
const HMBRepeatedFields = ({ id }: HMBRepeatedFieldsProps) => {
  const { values, setFieldValue }: any = useFormikContext();
  const bloodPressureValue = getIn(values, 'data.essentialSigns.bloodPressure');
  const pressureStandingValue = getIn(values, 'data.essentialSigns.bloodPressureStanding');

  return (
    <>
      <EssentialSignsFields
        id={`${id}_EssentialSigns`}
        onChangeFormikValue={setFieldValue}
        bloodPressureValue={bloodPressureValue}
        pressureStandingValue={pressureStandingValue}
      />
      <SectionDivider />
      <PainAssessment id={`${id}_PainAssessment`} />
      <SectionDivider />
      <GenericField
        id={`${id}_Alpha`}
        fields={[
          {
            name: 'data.S',
            label: 'report.HMBREPEATED.S',
            type: 'textareaWithEditor',
          },
          {
            name: 'data.O',
            label: 'report.HMBREPEATED.O',
            type: 'textareaWithEditor',
          },
          {
            name: 'data.A',
            label: 'report.HMBREPEATED.A',
            type: 'textareaWithEditor',
          },
          {
            name: 'data.P',
            label: 'report.HMBREPEATED.P',
            type: 'textareaWithEditor',
          },
        ]}
      />
      <SectionDivider />
    </>
  );
};

export default HMBRepeatedFields;
