/* eslint-disable new-cap */
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useParams, useOutletContext } from 'react-router-dom';
import * as Yup from 'yup';
import { IReport } from '../../../interfaces/Models';
import { reportTypes } from '../../../common/Constants';
import ErrorMessage from '../../../components/ErrorMessage';
import SectionDivider from '../../../components/SectionDivider';
import GenericField from '../sharedFields/GenericField';
import { HMDReleaseFields } from '../../../common/ReleaseFields';
import ReportsForm from '../../../components/Form/ReportsForm';

const HMDRelease = () => {
  const { t } = useTranslation();
  const { visitId } = useParams();
  const data: IReport | null = useOutletContext();

  const initialValues =
    data && data?.name
      ? data
      : {
          name: reportTypes.HMD_RELEASE,
          visit: visitId,
          data: data
            ? data
            : {
                recommendation: '',
                note: '',
              },
        };
  const ReportSchema = Yup.object({
    name: Yup.string().required(t('clinic.form.required')),
    visit: Yup.string().required(t('clinic.form.required')),
    data: Yup.object().shape({}),
  });

  const HtmlId = 'HMDRelease';

  if (data?.name && data.name != reportTypes.HMD_RELEASE) {
    return <ErrorMessage error={t('global.errors.somethingWrong')} />;
  }

  return (
    <ReportsForm
      initialValues={initialValues}
      visitId={visitId || ''}
      validationSchema={ReportSchema}
    >
      <GenericField
        id={`${HtmlId}_ReleaseFields`}
        fields={HMDReleaseFields}
        subHeader={t(`report.releaseSection.header`)}
      />
      <SectionDivider />
    </ReportsForm>
  );
};

export default HMDRelease;
