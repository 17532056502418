/* eslint-disable new-cap */
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IVisit } from '../../interfaces/Models';
import { IconButton, Tooltip } from '@mui/material';
import PublishedWithChangesIcon from '@mui/icons-material/PublishedWithChanges';
import DialogActions from '../../components/DialogActions';
import FetcherService from '../../hooks/FetcherService';
import VisitService from '../../services/VisitService';
import { VISIT_STATUS } from '../../common/Constants';
import OverlaySpinner from '../../components/OverlaySpinner';

interface ReportClinicStatusActionProps {
  visit: IVisit;
  adminAbility: boolean;
  handlePageUpdate: () => void;
}

const ReportClinicStatusAction = ({
  visit,
  adminAbility,
  handlePageUpdate,
}: ReportClinicStatusActionProps) => {
  const { t } = useTranslation();
  const [visitToDoneModal, setVisitToDoneModal] = useState<boolean>(false);
  const [isLoadingReport, setIsLoadingReport] = useState(false);

  const handelOpenChangeVisitStatusToDone = () => setVisitToDoneModal(true);

  const handelCloseModal = () => setVisitToDoneModal(false);

  const handelLoading = (value: boolean) => {
    setIsLoadingReport(value);
  };

  const handelChangeVisitStatusToDone = async () => {
    await FetcherService<any>({
      service: VisitService.edit(visit?._id || '', { ...visit, status: VISIT_STATUS.DONE }),
      setLoading: handelLoading,
    });
    handelCloseModal();
    handlePageUpdate();
  };

  return (
    <>
      <DialogActions
        onClose={handelCloseModal}
        open={visitToDoneModal}
        dialog={t(`global.actionsConfirmation.visitStatusToDone`)}
        action={handelChangeVisitStatusToDone}
      />
      <Tooltip title={`${t('global.reportActions.sentToClinicDone')}`}>
        <IconButton
          color={'primary'}
          aria-label="go-back"
          onClick={handelOpenChangeVisitStatusToDone}
          disabled={!adminAbility}
        >
          <PublishedWithChangesIcon color="secondary" />
        </IconButton>
      </Tooltip>
      {isLoadingReport && <OverlaySpinner />}
    </>
  );
};

export default ReportClinicStatusAction;
