import React from 'react';
import { IFolder } from '../../interfaces/folder';
import { Box } from '@mui/material';
import FileFolderCard from './FileFolderCard';

export default function FoldersList({
  folders,
  handleUpdateList,
}: {
  folders: IFolder[];
  handleUpdateList: () => void;
}) {
  return (
    <Box
      gap={3}
      display="grid"
      gridTemplateColumns={{
        xs: 'repeat(1, 1fr)',
        sm: 'repeat(2, 1fr)',
        md: 'repeat(3, 1fr)',
      }}
    >
      {folders.map((folder: IFolder, idx: number) => (
        <FileFolderCard
          handleUpdateList={handleUpdateList}
          key={`${folder._id}_${idx}`}
          folder={folder}
          sx={{ maxWidth: 'auto' }}
        />
      ))}
    </Box>
  );
}
