export const HMMFirstValues = {
  diagnosesSection: {
    symptoms: '',
    symptomsNote: '',
    hearing: '',
    hearingNote: '',
    dominantHand: '',
    functionalHand: '',
    mobility: '',
    practiceBefore: '',
    currentFunctionalStatus: '',
    arousalLevel: '',
  },
  socialInteractions: {
    functionalSpeech: '',
    functionalSpeechNotes: '',
    informativeSpeech: '',
    informativeSpeechNotes: '',
    nonVerbalCommunication: '',
    nonVerbalCommunicationNotes: '',
    effect: '',
    effectNotes: '',
    collaboration: '',
    collaborationNotes: '',
  },
  mentalFunctions: {
    orientation: {
      orientationInTime: '',
      orientationInTimeNotes: '',
      orientationInPlace: '',
      orientationInPlaceNotes: '',
      orientationInPerson: '',
      orientationInPersonNotes: '',
      conditionAwareness: '',
      conditionAwarenessNotes: '',
      judgment: '',
      judgmentNotes: '',
    },
    memory: {
      immediate: '',
      immediateNotes: '',
      short: '',
      shortNotes: '',
      long: '',
      longNotes: '',
    },
    visualPerception: {
      objectIdentification: '',
      objectIdentificationNotes: '',
      Relationships: '',
      RelationshipsNotes: '',
      figure: '',
      figureNotes: '',
    },
  },
  muscularMovement: {
    rightActiveShoulder: '',
    leftActiveShoulder: '',
    rightPassiveShoulder: '',
    leftPassiveShoulder: '',
    rightActiveElbow: '',
    leftActiveElbow: '',
    rightPassiveElbow: '',
    leftPassiveElbow: '',
    rightActiveAqueduct: '',
    leftActiveAqueduct: '',
    rightPassiveAqueduct: '',
    leftPassiveAqueduct: '',
    rightActiveSKI: '',
    leftActiveSKI: '',
    rightPassiveSKI: '',
    leftPassiveSKI: '',
    rightActiveFingers: '',
    leftActiveFingers: '',
    rightPassiveFingers: '',
    leftPassiveFingers: '',
    shoulderNotes: '',
    elbowNotes: '',
    aqueductNotes: '',
    SKINotes: '',
    fingersNotes: '',
    rightTone: '',
    leftTone: '',
    toneNotes: '',
    rightForce: '',
    leftForce: '',
    forceNotes: '',
    coordination: '',
    rightCoordination: '',
    leftCoordination: '',
    coordinationNotes: '',
  },
  SensoryFunctions: {
    rightSuperficialFeeling: '',
    leftSuperficialFeeling: '',
    superficialFeelingNotes: '',
    rightDeepFeeling: '',
    leftDeepFeeling: '',
    deepFeelingNotes: '',
    pain: '',
    painNotes: '',
  },
  feelingOfPain: {
    pain: '',
    painNote: '',
    seatingFields: {
      headControl: '',
      headControlNotes: '',
      gw: '',
      gwNotes: '',
      upperLimb: '',
      upperLimbNotes: '',
    },
    occupationAndActivity: {
      phone: '',
      phoneNotes: '',
      computer: '',
      computerNotes: '',
      remote: '',
      remoteNotes: '',
      financialUse: '',
      financialUseNotes: '',
      emergencyButton: '',
      emergencyButtonNotes: '',
      fimAssessments: {
        fields: {
          fimEating: 1,
          fimSelfCare: 1,
          fimBathing: 1,
          fimTopDress: 1,
          fimLowerDress: 1,
          fimServices: 1,
          fimUrine: 1,
          fimFeces: 1,
          fimBedChairWheel: 1,
          fimServices1: 1,
          fimShower: 1,
          fimWalkingWheel: 1,
          fimStairs: 1,
          fimUnderstanding: 1,
          fimExpression: 1,
          fimSocialInteraction: 1,
          fimTroubleshooting: 1,
          fimMemory: 1,
          // -----
          fimEatingNotes: ' ',
          fimSelfCareNotes: ' ',
          fimBathingNotes: ' ',
          fimTopDressNotes: ' ',
          fimLowerDressNotes: ' ',
          fimServicesNotes: ' ',
          fimUrineNotes: ' ',
          fimFecesNotes: ' ',
          fimBedChairWheelNotes: ' ',
          fimServices1Notes: ' ',
          fimShowerNotes: ' ',
          fimWalkingWheelNotes: ' ',
          fimStairsNotes: ' ',
          fimUnderstandingNotes: ' ',
          fimExpressionNotes: ' ',
          fimSocialInteractionNotes: ' ',
          fimTroubleshootingNotes: ' ',
          fimMemoryNotes: ' ',
        },
        assessments: {
          fimTotal1: 0,
          fimTotal2: 0,
          fimGlobalTotal: 0,
        },
      },
    },
  },
  freeTextFields: {
    accessibilityMode: '',
    leisureReference: '',
    functionalGoals: '',
  },
  falls: {
    fallInYear: '',
    numberOfFalls: '',
    attention: '',
    remarks: '',
    dizziness: '',
    afraidOfFalls: '',
    estimate: '',
  },
};
