import { IField } from './HMA';

export const receiptFields: IField[] = [
  {
    name: 'data.receipt.subjective',
    label: 'report.HMPGENERAL.receipt.subjective',
    type: 'text',
    rows: 8,
    smallSize: 12,
    largeSize: 12,
  },
  {
    name: 'data.receipt.objective',
    label: 'report.HMPGENERAL.receipt.objective',
    type: 'text',
    rows: 8,
    smallSize: 12,
    largeSize: 12,
  },
  {
    name: 'data.receipt.cognitive',
    label: 'report.HMPGENERAL.receipt.cognitive',
    type: 'text',
    rows: 4,
    smallSize: 12,
    largeSize: 12,
  },
  {
    name: 'data.conditionBefore',
    label: 'report.HMPGENERAL.conditionBefore',
    type: 'text',
    rows: 4,
    smallSize: 12,
    largeSize: 12,
  },
  {
    name: 'data.afterSurgery',
    label: 'report.HMPGENERAL.afterSurgery',
    type: 'text',
    rows: 4,
    smallSize: 12,
    largeSize: 12,
  },
];
