/* eslint-disable new-cap */
import { useTranslation } from 'react-i18next';
import { useEffect, useMemo, useState } from 'react';
import * as Yup from 'yup';
import { useRecoilValue } from 'recoil';
import axios from 'axios';
import { IPatientProgram, IUserFlat, ReportData } from '../../../interfaces/Models';
import { userAtom } from '../../../atoms/userAtom';
import { checkIfUserIsAdmin } from '../../../utils/Helper';
import FormModal from '../../../components/Modals/FormModal';
import Form from '../../../components/Form/FormikForm';
import programReferralService from '../../../services/programReferralService';
import { IProgramReferralPop } from '../../../interfaces/referral';
import FetcherService from '../../../hooks/FetcherService';
import ReferralFormFields from './ReferralFormFields';

interface Props {
  open: boolean;
  handleTableDataUpdate: () => void;
  handleCloseModal: () => void;
  program: IPatientProgram;
  referral?: IProgramReferralPop | null;
}
const ReferralForm = ({
  referral,
  open,
  handleCloseModal,
  handleTableDataUpdate,
  program,
}: Props) => {
  const isEditMode = !!referral;
  const { t } = useTranslation();
  const user = useRecoilValue(userAtom);
  const [isLoadingForm, setIsLoading] = useState<boolean>(false);

  const [employees, setEmployees] = useState<IUserFlat[]>([]);
  const [isEmployeesDataFetched, setIsEmployeesDataFetched] = useState<boolean>(false);
  const [isLoadingEmployees, setIsLoadingEmployees] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');

  const isUserAdmin = checkIfUserIsAdmin(user);

  useEffect(() => {
    if (!isEmployeesDataFetched && isUserAdmin && open && !isLoadingEmployees) {
      setIsLoadingEmployees(true);
      axios
        .get(`/api/user`, {
          params: {
            page: 0,
            limit: 1000,
          },
        })
        .then((motors) => {
          setIsEmployeesDataFetched(true);
          setEmployees(motors.data.data);
          setIsLoadingEmployees(false);
        })
        .catch((error) => {
          setErrorMessage(error.message);
          setError(true);
        });
    }
  }, [open]);

  const Schema = Yup.object({
    employee: Yup.object()
      .shape({
        _id: Yup.string().required(t('clinic.form.required')),
      })
      .required(t('clinic.form.required')),
    title: Yup.string().required(t('clinic.form.required')),
    body: Yup.string().required(t('clinic.form.required')),
    program: Yup.string().required(t('clinic.form.required')),
    referralReason: Yup.array().required(t('clinic.form.required')),
  });

  const initialValues = useMemo(() => {
    if (referral) return referral;
    return {
      referralReason: [],
      employee: isUserAdmin
        ? undefined
        : {
            _id: user?._id,
          },
      program: program?._id,
    };
  }, [user, isLoadingEmployees, referral]);

  const handleSubmit = async (values: ReportData) => {
    await FetcherService<any>({
      service: isEditMode
        ? programReferralService.edit(String(referral?._id), values as IProgramReferralPop)
        : programReferralService.add(values as IProgramReferralPop),
      setLoading: setIsLoading,
    });

    handleCloseModal();
    handleTableDataUpdate();
  };

  return (
    <FormModal
      open={open}
      onClose={handleCloseModal}
      text={t(isEditMode ? 'editReferral' : 'newReferral')}
      loading={isLoadingForm}
      maxWidth="lg"
      key={user?._id}
    >
      <Form
        onCancel={handleCloseModal}
        submit={handleSubmit}
        initialValues={initialValues}
        validationSchema={Schema}
        cancelText={t('clinic.form.cancel')}
        submitText={t(`clinic.form.${isEditMode ? 'edit' : 'add'}`)}
      >
        <ReferralFormFields
          error={error}
          errorMessage={errorMessage}
          isLoadingEmployees={isLoadingEmployees}
          employees={employees}
        />
      </Form>
    </FormModal>
  );
};

export default ReferralForm;
