import { Field } from 'formik';
import React from 'react';
import { StyledTextField } from '../../styles/FormFieldsStyles';
import { Icon } from '@iconify/react';
import eyeFill from '@iconify/icons-eva/eye-fill';
import eyeOffFill from '@iconify/icons-eva/eye-off-fill';
import { IconButton, InputAdornment } from '@mui/material';

interface IPasswordField {
  name: string;
  label: string;
}
const PasswordField = ({ name, label }: IPasswordField) => {
  const [showPassword, setShowPassword] = React.useState(false);

  const handleShowPassword = () => {
    setShowPassword((show) => !show);
  };
  return (
    <Field
      fullWidth
      component={StyledTextField}
      type={showPassword ? 'text' : 'password'}
      label={label}
      name={name}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton onClick={handleShowPassword} edge="end">
              <Icon icon={showPassword ? eyeFill : eyeOffFill} />
            </IconButton>
          </InputAdornment>
        ),
      }}
    />
  );
};

export default PasswordField;
