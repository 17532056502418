import { IQuery, IService } from '../interfaces/index';
import { HTTP_METHODS } from '../common/APIHelper';
import { IFolder } from '../interfaces/folder';
import { ReportData } from '../interfaces/Models';

const getAll = (query?: IQuery): IService => {
  return { route: '/api/folder', method: HTTP_METHODS.GET, query };
};

const add = (payload: ReportData): IService => {
  return { route: '/api/folder', method: HTTP_METHODS.POST, payload };
};

const getOneById = (folderId: string): IService => {
  return { route: `/api/folder/${folderId}`, method: HTTP_METHODS.GET };
};

const deleteOneById = (folderId: string): IService => {
  return { route: `/api/folder/${folderId}`, method: HTTP_METHODS.DELETE };
};

const folderService = {
  getAll,
  getOneById,
  add,
  deleteOneById,
};

export default folderService;
