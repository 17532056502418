import { Card } from '@mui/material';
import React from 'react';
import { IPatientProgram, IProgramRefsTableData } from '../../interfaces/Models';
import { StyledBox } from '../../styles/TabsStyles';
import { useTranslation } from 'react-i18next';
import Table from '../../components/Table';
import ProgramRefsTableHeader from './ProgramRefsTableHeader';
import { ENGLISH_MONTHS_NAMES, HEBREW_MONTHS_NAMES } from '../../common/months';
import ProgramRefsExpandCard from './ProgramRefsExpandCard';

const generateProgramRefsTableData = ({
  program,
  handleUpdateTable,
}: {
  program: IPatientProgram;
  handleUpdateTable: () => void;
}) => {
  const tableData: IProgramRefsTableData[] = [];

  if (!program.referenceNumbers) return tableData;

  const keys = Object.keys(program.referenceNumbers || {}).reverse();

  keys.forEach((key) => {
    const monthRefs = (program.referenceNumbers || {})[key] || [];
    const [month, year] = key.split('_');
    if (monthRefs.length > 0) {
      tableData.push({
        month: Number(month),
        year: Number(year),
        refs: monthRefs,
        program,
        handleUpdateTable,
      });
    }
  });
  return tableData;
};
interface IProgramRefsCard {
  program: IPatientProgram;
  handleUpdateProgramData: () => void;
}
export default function ProgramRefsCard({ program, handleUpdateProgramData }: IProgramRefsCard) {
  const { t, i18n } = useTranslation();

  const language = i18n.language;
  const isHebrew = language === 'he';

  const columns = [
    {
      Header: `${t('month')}`,
      accessor: (val: any) => {
        const monthName = isHebrew
          ? HEBREW_MONTHS_NAMES[val.month - 1]
          : ENGLISH_MONTHS_NAMES[val.month - 1];

        return `${monthName} ( ${val.month} )`;
      },
    },
    {
      Header: `${t('year')}`,
      accessor: 'year',
    },
  ];

  const data = generateProgramRefsTableData({
    program,
    handleUpdateTable: handleUpdateProgramData,
  });

  return (
    <Card>
      <StyledBox>
        <Table
          columns={columns}
          isLoading={false}
          data={data}
          count={data.length}
          pageSizes={[10]}
          TableHeaderFilters={
            <ProgramRefsTableHeader
              program={program}
              handleUpdateProgramData={handleUpdateProgramData}
            />
          }
          showFilters={true}
          showPagination={false}
          ExpandCard={ProgramRefsExpandCard}
        />
      </StyledBox>
    </Card>
  );
}
