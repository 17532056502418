/* eslint-disable new-cap */
import { merge } from 'lodash';
import Card from './Card';
import Lists from './Lists';
import Paper from './Paper';
import Input from './Input';
import Button from './Button';
import Tooltip from './Tooltip';
import Backdrop from './Backdrop';
import Typography from './Typography';
import IconButton from './IconButton';
import Autocomplete from './Autocomplete';
import TablePagination from './TablePagination';
import DatePicker from './DatePicker';
import Tab from './Tabs';
import StepLabel from './StepLabel';
import { Theme } from '@mui/material';

// ----------------------------------------------------------------------

export default function ComponentsOverrides(theme: Theme) {
  return merge(
    Card(theme),
    Lists(theme),
    Paper(),
    Input(theme),
    Button(theme),
    Tooltip(theme),
    Backdrop(theme),
    Typography(theme),
    IconButton(theme),
    Autocomplete(theme),
    TablePagination(theme),
    DatePicker(theme),
    Tab(theme),
    StepLabel(theme)
  );
}
