import { Avatar, Box, Link, Stack, Typography } from '@mui/material';
import React from 'react';
import { IPatientProgramFlat } from '../interfaces/Models';
import { Link as RouterLink } from 'react-router-dom';
import { format } from 'date-fns';
import { theme } from '../theme';
import { getUserAvatar } from '../utils/Helper';

interface IProgramItem {
  program: IPatientProgramFlat;
}
const ProgramItem = ({ program }: IProgramItem) => {
  const { title, patientName, createdAt, _id, patientId, clinicName } = program;
  return (
    <>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        sx={{ padding: 0, margin: 0 }}
        spacing={0}
      >
        <Box sx={{ width: '80%', display: 'flex', gap: theme.spacing(1), alignItems: 'center' }}>
          <Avatar
            alt={patientName}
            src={getUserAvatar(patientName.split(' ')[0], patientName.split(' ')[1])}
          />
          <Link
            to={`/app/patient-program/${_id}`}
            color="inherit"
            underline="hover"
            component={RouterLink}
          >
            <Typography variant="subtitle2">{title}</Typography>
          </Link>
          {` `}-{` `}
          <Typography variant="subtitle2">{clinicName}</Typography>
          {` `}-{` `}
          <Link
            to={`/app/patient/${patientId}`}
            color="inherit"
            underline="hover"
            component={RouterLink}
          >
            <Typography variant="body2">{patientName}</Typography>
          </Link>
        </Box>
        <Typography
          variant="caption"
          sx={{ pr: 3, flexShrink: 0, color: 'text.secondary', textAlign: 'right' }}
          align="right"
        >
          {format(new Date(createdAt ? createdAt : ''), 'dd-MM-yyyy')}
        </Typography>
      </Stack>
    </>
  );
};

export default ProgramItem;
