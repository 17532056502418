import React, { SyntheticEvent, useState } from 'react';
import { TextField, Autocomplete, Popper } from '@mui/material';
import useSearchFilter from '../hooks/useSearchFilter';
import { RESET_PAGE_QUERY } from '../common/Constants';

interface IAutoCompleteFilter {
  options: string[];
  label: string;
  urlParam: string;
  width?: number;
}
const AutoCompleteFilter = ({ options, urlParam, label, width }: IAutoCompleteFilter) => {
  const { changeQueryParams, pageInfo, deleteSearchQuery } = useSearchFilter();
  const [filterValue, setFilterValue] = useState<string | null>(
    pageInfo[urlParam] && options.indexOf(pageInfo[urlParam]) > -1 ? pageInfo[urlParam] : null
  );

  const handleFilterChange = (e: SyntheticEvent<Element, Event>, value: string | null) => {
    setFilterValue(value);
    if (value) {
      changeQueryParams([RESET_PAGE_QUERY, { name: urlParam, value: value }]);
    } else {
      deleteSearchQuery([urlParam]);
    }
  };

  const PopperMy = function (props: any) {
    return <Popper {...props} placement="bottom-start" />;
  };

  return (
    <Autocomplete
      id="Auto_Complete_Filter"
      options={options}
      value={filterValue}
      PopperComponent={PopperMy}
      getOptionLabel={(option: string) => option}
      sx={{
        width: width || 170,
        direction: 'ltr',
      }}
      size="small"
      onChange={(e, val) => handleFilterChange(e, val)}
      renderInput={(params) => <TextField label={label} {...params} />}
    />
  );
};

export default AutoCompleteFilter;
