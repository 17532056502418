import { Field } from 'formik';
import React from 'react';
import { StyledFormControl } from '../../styles/FormFieldsStyles';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import i18n from 'i18next';
import { he, enUS } from 'date-fns/locale';
import RtlFormikDatePickerField from '../RtlFormikDatePickerField';

interface IDatePickerField {
  id?: string;
  label: string;
  name: string;
  disabled?: boolean;
  sx?: object;
}

const FormikDatePickerField = ({ id, label, name, disabled, sx }: IDatePickerField) => {
  return (
    <StyledFormControl sx={sx}>
      <LocalizationProvider locale={i18n.language == 'he' ? he : enUS} dateAdapter={AdapterDateFns}>
        <Field
          id={id}
          component={RtlFormikDatePickerField}
          disabled={disabled}
          label={label}
          name={name}
          inputFormat="dd/MM/yyyy"
        />
      </LocalizationProvider>
    </StyledFormControl>
  );
};

export default FormikDatePickerField;
