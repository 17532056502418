/* eslint-disable new-cap */
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Form from '../../components/Form/FormikForm';
import FormModal from '../../components/Modals/FormModal';
import { ReportData } from '../../interfaces/Models';
import * as Yup from 'yup';
import FetcherService from '../../hooks/FetcherService';
import folderService from '../../services/folderService';
import DueDateFormField from '../../pages/program/hospitalDays/DueDateFormField';
import { TextField } from 'formik-mui';
import { Field } from 'formik';
import { Box } from '@mui/material';

interface Props {
  isOpen: boolean;
  closeModal: () => void;
  handleFolderListUpdate: () => void;
}
const FolderForm = ({ closeModal, handleFolderListUpdate, isOpen }: Props) => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const FolderSchema = Yup.object({
    name: Yup.string().required(`${t('clinic.form.required')}`),
  });

  const handleSubmitFolder = async (values: ReportData) => {
    await FetcherService<any>({
      service: folderService.add({ name: values.name }),
      setLoading: setIsLoading,
    });
    closeModal();
    handleFolderListUpdate();
  };

  return (
    <FormModal open={isOpen} onClose={closeModal} text={t('newFolder')} loading={isLoading}>
      <Form
        onCancel={closeModal}
        submit={handleSubmitFolder}
        initialValues={{ name: '' }}
        validationSchema={FolderSchema}
        cancelText={t('clinic.form.cancel')}
        submitText={t('clinic.form.add')}
      >
        <Box sx={{ width: '100%', my: 1 }}>
          <Field fullWidth component={TextField} type="text" label={t('name')} name="name" />
        </Box>
      </Form>
    </FormModal>
  );
};

export default FolderForm;
