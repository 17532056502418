/* eslint-disable no-unused-vars */
import { AbilityBuilder, Ability, AbilityClass } from '@casl/ability';
import { USER_ROLES } from '../common/Constants';
import { IUser } from '../interfaces/Models';

export type Actions = 'manage' | 'create' | 'read' | 'update' | 'delete';
export enum AbilitySubjects {
  EMPLOYEES_ABILITY = 'EMPLOYEES_ABILITY',
  ADMIN_ABILITY = 'ADMIN_ABILITY',
  ALL = 'ALL',
  ADMIN_AND_HMA_ABILITY = 'ADMIN_AND_HMA_ABILITY',
  VIEW_FINANCIAL_REPORTS = 'VIEW_FINANCIAL_REPORTS',
  USER = 'USER',
  BLOCK_USER = 'BLOCK_USER',
  RESET_USER_PASSWORD = 'RESET_USER_PASSWORD',
  USER_ATTACHMENTS = 'USER_ATTACHMENTS',
  FOLDER = 'FOLDER',
  FOLDER_ATTACHMENTS = 'FOLDER_ATTACHMENTS',
}

export type Subject = IUser | AbilitySubjects;

export type AppAbility = Ability<[Actions, Subject]>;
export const AppAbility = Ability as AbilityClass<AppAbility>;

export default function defineRulesFor(user: IUser | null) {
  const { can, rules } = new AbilityBuilder(AppAbility);
  const userRole = user?.role;
  const userPosition = user?.position;

  const isEmployee = userRole === USER_ROLES.EMPLOYEE;
  const isAdmin = userRole === USER_ROLES.ADMIN;
  const isSuperAdmin = userRole === USER_ROLES.SUPER_ADMIN;

  can('read', AbilitySubjects.ALL);

  // Abilities for employees
  if (isEmployee) {
    // Abilities for employees with position 'HMA'
    can('read', AbilitySubjects.EMPLOYEES_ABILITY);
    if (userPosition === 'HMA') {
      can('update', AbilitySubjects.ADMIN_AND_HMA_ABILITY);
    }
  }

  // common abilities for admin and super admin
  if (isAdmin || isSuperAdmin) {
    can('read', AbilitySubjects.ADMIN_ABILITY);
    can('update', AbilitySubjects.ADMIN_ABILITY);
    can('create', AbilitySubjects.ADMIN_ABILITY);
    can('delete', AbilitySubjects.ADMIN_ABILITY);
    can('manage', AbilitySubjects.ADMIN_ABILITY);
    can('update', AbilitySubjects.ADMIN_AND_HMA_ABILITY);
    can('update', AbilitySubjects.ALL);
    can('create', AbilitySubjects.FOLDER_ATTACHMENTS);
    can('update', AbilitySubjects.FOLDER_ATTACHMENTS);
    can('read', AbilitySubjects.USER);
  }

  if (isSuperAdmin) {
    can('delete', AbilitySubjects.FOLDER_ATTACHMENTS);
    can('create', AbilitySubjects.FOLDER);
    can('delete', AbilitySubjects.FOLDER);
    can('read', AbilitySubjects.VIEW_FINANCIAL_REPORTS);
    can('create', AbilitySubjects.USER);
    can('update', AbilitySubjects.USER);
    can('manage', AbilitySubjects.BLOCK_USER);
    can('manage', AbilitySubjects.RESET_USER_PASSWORD);
    can('create', AbilitySubjects.USER_ATTACHMENTS);
    can('delete', AbilitySubjects.USER_ATTACHMENTS);
  }

  return rules;
}

export function buildAbilityFor(user: IUser | null): AppAbility {
  return new AppAbility(defineRulesFor(user), {
    // https://casl.js.org/v5/en/guide/subject-type-detection
    // @ts-ignore
    detectSubjectType: (object) => object!.type,
  });
}
