import React from 'react';
import { useTranslation } from 'react-i18next';
import GenericField from '../../sharedFields/GenericField';

const EatingOrderFields = () => {
  const { t } = useTranslation();
  const id = 'HMDFirst_NutritionalConsumption_EatingOrder';
  return (
    <>
      <GenericField
        id={`${id}_eatingOrder`}
        subHeader={t('report.HMDFIRST.nutritionalConsumption.eatingOrder.header')}
        fields={[
          {
            name: 'data.nutritionalConsumption.eatingOrder.morning',
            label: 'report.HMDFIRST.nutritionalConsumption.eatingOrder.morning',
            type: 'text',
          },
          {
            name: 'data.nutritionalConsumption.eatingOrder.intermediate',
            label: 'report.HMDFIRST.nutritionalConsumption.eatingOrder.intermediate',
            type: 'text',
          },
          {
            name: 'data.nutritionalConsumption.eatingOrder.noon',
            label: 'report.HMDFIRST.nutritionalConsumption.eatingOrder.noon',
            type: 'text',
          },
          {
            name: 'data.nutritionalConsumption.eatingOrder.intermediate1',
            label: 'report.HMDFIRST.nutritionalConsumption.eatingOrder.intermediate',
            type: 'text',
          },
          {
            name: 'data.nutritionalConsumption.eatingOrder.evening',
            label: 'report.HMDFIRST.nutritionalConsumption.eatingOrder.evening',
            type: 'text',
          },
          {
            name: 'data.nutritionalConsumption.eatingOrder.night',
            label: 'report.HMDFIRST.nutritionalConsumption.eatingOrder.night',
            type: 'text',
          },
        ]}
      />
    </>
  );
};

export default EatingOrderFields;
