export const HMDRepeatedValues = {
  bloodTests: '',
  anthropometry: '',
  currentAnamnesis: '',
  anamnesis: '',
  dietaryRecommendations: '',
  responding: '',
  additionalDetails: '',
  recommendations: '',
};
