/* eslint-disable new-cap */
import React, { useMemo, useState } from 'react';
import { useAbility } from '@casl/react';
import { useTranslation } from 'react-i18next';
import { Card } from '@mui/material';
import { Icon } from '@iconify/react';
import DeleteIcon from '@mui/icons-material/Delete';
import download from '@iconify/icons-eva/download-outline';
import { toast } from 'react-toastify';
import { IPatientProgram } from '../../../interfaces/Models';
import { AbilityContext } from '../../../casl/can';
import { AbilitySubjects } from '../../../casl/ability';
import MoreOptions from '../../../components/MoreOptions';
import { theme } from '../../../theme';
import FetcherService from '../../../hooks/FetcherService';
import programReferralService from '../../../services/programReferralService';
import DialogActions from '../../../components/DialogActions';
import { StyledBox } from '../../../styles/TabsStyles';
import Table from '../../../components/Table';
import ErrorMessage from '../../../components/ErrorMessage';
import { getDateFormatted, onDownloadPdf } from '../../../utils/Helper';
import { ApiResponse } from '../../../interfaces/ApiResponseType';
import useService from '../../../hooks/HookService';
import OverlaySpinner from '../../../components/OverlaySpinner';
import { IProgramReferralPop } from '../../../interfaces/referral';
import ReferralsTableHeader from './ReferralsTableHeader';
import { useRecoilValue } from 'recoil';
import { userAtom } from '../../../atoms/userAtom';
import editFill from '@iconify/icons-eva/edit-fill';
import ReferralForm from './ReferralForm';
import EditReferralIssueDateForm from './EditReferralIssueDateForm';

interface Props {
  program: IPatientProgram;
  isLoading?: boolean;
}
export default function ProgramReferralsTable({ program, isLoading }: Props) {
  const { t } = useTranslation();
  const user = useRecoilValue(userAtom);
  const ability = useAbility(AbilityContext);
  const adminAbility = ability.can('manage', AbilitySubjects.ADMIN_ABILITY);

  const [shouldTableUpdate, setShouldTableUpdate] = useState<number>(0);
  const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false);
  const [record, setRecord] = useState<IProgramReferralPop | null>(null);
  const [isLoadingDeleteReferral, setIsLoadingDeleteReferral] = useState<boolean>(false);
  const [isLoadingPdf, setIsLoadingPdf] = useState<boolean>(false);
  const [opedEditModal, setOpedEditModal] = useState<boolean>(false);
  const [opedEditIssueDateModal, setOpedEditIssueDateModal] = useState<boolean>(false);

  const handleModalClose = () => {
    setOpenDeleteModal(false);
    setOpedEditModal(false);
    setOpedEditIssueDateModal(false);
    setRecord(null);
  };

  const handelOpenEditModal = (value: IProgramReferralPop) => {
    setRecord(value);
    setOpedEditModal(true);
  };

  const handelOpenEditIssueDateModal = (value: IProgramReferralPop) => {
    setRecord(value);
    setOpedEditIssueDateModal(true);
  };

  const {
    isLoading: tableDataLoading,
    data,
    error,
    errorMessage,
  } = useService<IProgramReferralPop[]>({
    service: programReferralService.getAll({
      program: program?._id,
    }),
    deps: [shouldTableUpdate],
  });

  const onOpenDeleteModal = (value: IProgramReferralPop) => {
    setRecord(value);
    setOpenDeleteModal(true);
  };

  const handleDownloadReferral = async (val: IProgramReferralPop, isWithGeneralData?: boolean) => {
    const reportName = `הפנייה ל ${val.title}-${getDateFormatted(val.createdAt || new Date())}`;
    const onSuccess = (res: ApiResponse<BlobPart>) => {
      onDownloadPdf(res, reportName);
    };
    await FetcherService<any>({
      service: programReferralService.downloadOneById(val?._id || '', {
        isWithGeneralData,
      }),
      onSuccess: onSuccess,
      errorMessage: t('global.errors.report'),
      setLoading: setIsLoadingPdf,
    });
  };

  const columns = [
    {
      Header: `${t('issuer')}`,
      accessor: (val: any) =>
        `${val.employee.personalDetails.firstName} ${val.employee.personalDetails.lastName}`,
    },
    {
      Header: `${t('issueDate')}`,
      accessor: (val: any) => getDateFormatted(val.createdAt),
    },
    {
      Header: t('clinic.table.actions'),
      accessor: (val: any) => {
        const isIssuer = val.issuer === user?._id;

        const isUserAbleToEdit = isIssuer || adminAbility;
        return (
          <MoreOptions
            actions={[
              {
                name: t('global.moreMenu.edit'),
                handler: handelOpenEditModal,
                icon: (
                  <Icon
                    icon={editFill}
                    width={24}
                    height={24}
                    color={theme.palette.secondary.main}
                  />
                ),
                isVisible: isUserAbleToEdit,
              },
              {
                name: t('editReferralIssueDate'),
                handler: handelOpenEditIssueDateModal,
                icon: (
                  <Icon
                    icon="ic:baseline-update"
                    width={24}
                    height={24}
                    color={theme.palette.secondary.main}
                  />
                ),
                isVisible: isUserAbleToEdit,
              },
              {
                name: t('global.reportActions.downloadReport'),
                handler: async () => await handleDownloadReferral(val, false),
                icon: <Icon icon={download} width={24} height={24} color="#3E94A8" />,
              },
              {
                name: t('downloadWithGeneralData'),
                handler: async () => await handleDownloadReferral(val, true),
                icon: <Icon icon={download} width={24} height={24} color="#3E94A8" />,
              },
              {
                name: t('global.moreMenu.delete'),
                handler: onOpenDeleteModal,
                icon: <DeleteIcon width={24} height={24} color="error" />,
                variant: theme.palette.error.main,
                isVisible: isUserAbleToEdit,
              },
            ]}
            record={val}
          />
        );
      },
    },
  ];

  const prescriptions = useMemo(() => data || [], [data]);

  const handleTableDataUpdate = () => setShouldTableUpdate((pre) => pre + 1);

  if (error) return <ErrorMessage error={errorMessage} />;

  const handleDeleteReferral = async () => {
    const onSuccess = () => {
      toast.success(t('deleteSuccess'), {
        rtl: true,
      });
    };
    await FetcherService({
      service: programReferralService.deleteOneById(record?._id as string),
      errorMessage: t('deleteError'),
      onSuccess,
      setLoading: setIsLoadingDeleteReferral,
    });
    setOpenDeleteModal(false);
    setShouldTableUpdate((prev) => prev + 1);
  };

  return (
    <>
      <EditReferralIssueDateForm
        handleCloseModal={handleModalClose}
        openChangeReferralIssueDate={opedEditIssueDateModal}
        handleTableDataUpdate={handleTableDataUpdate}
        referral={record}
      />
      <ReferralForm
        handleCloseModal={handleModalClose}
        program={program}
        open={opedEditModal}
        handleTableDataUpdate={handleTableDataUpdate}
        referral={record}
      />
      <DialogActions
        onClose={handleModalClose}
        open={openDeleteModal}
        dialog={`${t('deleteReferralConfirmation')}`}
        action={handleDeleteReferral}
        loading={isLoadingDeleteReferral}
      />
      <Card>
        <StyledBox>
          <Table
            columns={columns}
            isLoading={isLoading || tableDataLoading}
            data={prescriptions}
            count={prescriptions.length}
            pageSizes={[10]}
            TableHeaderFilters={
              <ReferralsTableHeader
                program={program}
                handleTableDataUpdate={handleTableDataUpdate}
              />
            }
            showFilters={true}
            showPagination={true}
          />
        </StyledBox>
      </Card>
      {isLoadingPdf && <OverlaySpinner />}
    </>
  );
}
