import { Card, Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { TabTableBox, StyledHeader } from '../../styles/TabsStyles';

interface INoTabMessage {
  message?: string;
}
const NoTabMessage = ({ message }: INoTabMessage) => {
  const { t } = useTranslation();

  return (
    <Card>
      <TabTableBox flexGrow={1}>
        <StyledHeader>
          <Typography variant="h4" gutterBottom>
            {message || t('patient.info.noProgram')}
          </Typography>
          <hr />
        </StyledHeader>
      </TabTableBox>
    </Card>
  );
};

export default NoTabMessage;
