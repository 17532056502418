import React from 'react';
import ErrorMessage from '../../../components/ErrorMessage';
import { t } from 'i18next';
import { ReportData } from '../../../interfaces/Models';
import PiChartWidgetSkeleton from '../../../components/skeletons/PiChartWidgetSkeleton';
import BarChart from '../../../components/BarChart';
import { IServiceResponse } from '../../../hooks/HookService';

const ProgramStatusBarChartWidget = ({
  data,
  error,
  errorMessage,
  isLoading,
}: IServiceResponse<ReportData>) => {
  if (isLoading) {
    return <PiChartWidgetSkeleton />;
  }
  if (error || !data) {
    return <ErrorMessage error={errorMessage} />;
  }

  const chartData = [
    {
      name: t('global.pages.programs'),
      data: Object.values(data || {}),
    },
  ];
  const chartCategories: string[] = [
    t('global.dashboard.active'),
    t('global.dashboard.done'),
    t('global.dashboard.canceled'),
  ];

  return (
    <BarChart
      title={t('global.pages.programs')}
      chartCategories={chartCategories}
      chartData={chartData}
    />
  );
};

export default ProgramStatusBarChartWidget;
