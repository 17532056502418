import { IconButton, TextField } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import useDebounce from '../hooks/useDebounce';
import { theme } from '../theme';
import { Search } from '@mui/icons-material';
import { styled } from '@mui/system';
import { URL_QUERY_PARAMS } from '../common/Constants';
import useSearchFilter from '../hooks/useSearchFilter';

const StyledDiv = styled('div')({
  direction: 'ltr',
  marginRight: theme.spacing(1),
  [theme.breakpoints.down('sm')]: {
    width: '100%',
  },
});

const StyledSearchInput = styled(TextField)({
  [theme.breakpoints.down('sm')]: {
    width: '100%',
  },
});

const GlobalFilter = () => {
  const { t } = useTranslation();
  const [searchNumber, setSearchNumber] = useState<number>(0);
  const { changeQueryParams, pageInfo, deleteSearchQuery } = useSearchFilter();
  const searchParam = pageInfo.search;
  const [value, setValue, actualValue] = useDebounce(searchParam || '', 300);

  useEffect(() => {
    if (value.length > 2 || value.length === 0) {
      if (value.length === 0) {
        if (searchNumber > 0) {
          deleteSearchQuery([URL_QUERY_PARAMS.SEARCH]);
        }
      } else {
        changeQueryParams([{ name: URL_QUERY_PARAMS.SEARCH, value }]);
      }
      setSearchNumber((prev) => prev + 1);
    }
  }, [value]);
  return (
    <StyledDiv>
      <StyledSearchInput
        onChange={(e) => setValue(e.target.value)}
        value={actualValue}
        type="search"
        placeholder={t('global.search')}
        size="small"
        InputProps={{
          endAdornment: (
            <IconButton>
              <Search />
            </IconButton>
          ),
        }}
      />
    </StyledDiv>
  );
};

export default GlobalFilter;
