import React from 'react';
import SectionDivider from '../../../../components/SectionDivider';
import GenericField from '../../sharedFields/GenericField';
import PhysicalConditionFields from './PhysicalConditionFields';
import TransitionsWalkFields from './TransitionsWalkFields';
import { useTranslation } from 'react-i18next';
import { functionalStateFields } from '../../../../common/HMPFirst';

interface HMPFirstFieldsProps {
  id: string;
}
const HMPFirstFields = ({ id }: HMPFirstFieldsProps) => {
  const { t } = useTranslation();

  return (
    <>
      <GenericField
        id={`${id}_FunctionalState`}
        subHeader={t('report.HMPFIRST.functionalState.header')}
        fields={functionalStateFields}
      />
      <SectionDivider />
      <PhysicalConditionFields />
      <TransitionsWalkFields />
    </>
  );
};

export default HMPFirstFields;
