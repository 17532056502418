import React from 'react';
import ErrorMessage from '../../components/ErrorMessage';
import { IPatientProgram } from '../../interfaces/Models';
import ProgramVisits from '../program/ProgramVisits';
import NoActiveProgram from '../program/NoActiveProgram';
import ProgramCalendar from '../program/ProgramCalendar';
import TableSkeleton from '../../components/skeletons/TableSkeleton';

interface ActiveProgramViewProps {
  viewType: 'Table' | 'Calendar';
  activeProgram?: IPatientProgram;
  handlePageReload: () => void;
  error: boolean;
  isLoading: boolean;
  errorMessage: string;
  isLeumitPatient?: boolean;
  handleProgramDataUpdate: (val: IPatientProgram) => void;
}
const ActiveProgramView = ({
  activeProgram,
  handlePageReload,
  viewType,
  error,
  isLoading,
  errorMessage,
  isLeumitPatient = false,
  handleProgramDataUpdate,
}: ActiveProgramViewProps) => {
  if (!activeProgram) {
    return <NoActiveProgram />;
  }

  if (isLoading && viewType == 'Table') {
    return <TableSkeleton filtersLength={0} topPagination={true} bottomPagination={true} />;
  }
  if (isLoading && viewType == 'Calendar') {
    return <TableSkeleton filtersLength={0} topPagination={false} bottomPagination={false} />;
  }
  if (error) {
    return <ErrorMessage error={errorMessage} />;
  }

  if (viewType == 'Table') {
    return (
      <ProgramVisits
        program={activeProgram}
        visits={activeProgram.visits}
        handleTableUpdate={handlePageReload}
        isLeumitPatient={isLeumitPatient}
      />
    );
  }
  return (
    <ProgramCalendar program={activeProgram} handleProgramDataUpdate={handleProgramDataUpdate} />
  );
};

export default ActiveProgramView;
