/* eslint-disable new-cap */
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useParams, useOutletContext } from 'react-router-dom';
import * as Yup from 'yup';
import { IReport } from '../../../interfaces/Models';
import { reportTypes } from '../../../common/Constants';
import ErrorMessage from '../../../components/ErrorMessage';
import { HMkReleaseValues } from '../../../common/reportsFieldsValues/hmk/HMKReleaseValues';
import SectionDivider from '../../../components/SectionDivider';
import GenericField from '../sharedFields/GenericField';
import { HMKReleaseFields } from '../../../common/ReleaseFields';
import ReportsForm from '../../../components/Form/ReportsForm';

const HMKRelease = () => {
  const { t } = useTranslation();
  const { visitId } = useParams();
  const data: IReport | null = useOutletContext();

  const initialValues =
    data && data?.name
      ? data
      : {
          name: reportTypes.HMK_RELEASE,
          visit: visitId,
          data: data ? data : HMkReleaseValues,
        };

  const ReportSchema = Yup.object({
    name: Yup.string().required(t('clinic.form.required')),
    visit: Yup.string().required(t('clinic.form.required')),
    data: Yup.object().shape({
      receipt: Yup.string().required(t('clinic.form.required')),
      difficulties: Yup.string().required(t('clinic.form.required')),
      swallowing: Yup.string().required(t('clinic.form.required')),
      oralTest: Yup.string().required(t('clinic.form.required')),
      intervention: Yup.string().required(t('clinic.form.required')),
      statusChange: Yup.string().required(t('clinic.form.required')),
      recommendation: Yup.string().required(t('clinic.form.required')),
    }),
  });

  const HtmlId = 'HMKRelease';

  if (data?.name && data.name != reportTypes.HMK_RELEASE) {
    return <ErrorMessage error={t('global.errors.somethingWrong')} />;
  }

  return (
    <ReportsForm
      initialValues={initialValues}
      visitId={visitId || ''}
      validationSchema={ReportSchema}
    >
      <GenericField
        id={`${HtmlId}_ReleaseFields`}
        fields={HMKReleaseFields}
        subHeader={t(`report.releaseSection.header`)}
      />
      <SectionDivider />
    </ReportsForm>
  );
};

export default HMKRelease;
