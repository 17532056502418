import { useFormikContext } from 'formik';
import { useEffect } from 'react';

export const FormikDisabler = () => {
  const { setSubmitting } = useFormikContext();

  useEffect(() => {
    setSubmitting(true);
  }, []);
  return null;
};
