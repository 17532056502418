export const HMBRepeatedValues = {
  essentialSigns: {
    bloodPressure: '',
    pulse: '',
    temperature: '',
    bloodPressureStanding: '',
    pulseStanding: '',
    sturgeon: '',
    sturgeonNote: '',
  },
  PainAssessment: {
    painDegrees: '',
    painNotes: '',
  },
  S: '',
  O: '',
  A: '',
  P: '',
};
