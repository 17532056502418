import { Box, Divider, Typography } from '@mui/material';
import { t } from 'i18next';
import React, { useEffect, useState } from 'react';
import CustomizedTable from '../../../components/CustomizedTable';
import { IEditedVisits, IVisit } from '../../../interfaces/Models';
import { theme } from '../../../theme';
import {
  checkIfVisitChanged,
  getHospitalDaysVisitsColumnsWithoutActions,
} from './HospitalDaysColumns';

interface IHospitalDaysFormStep3 {
  visitsWithPossibleChanges: IVisit[];
  toBeCanceledVisits: IVisit[];
  editedVisits: IEditedVisits[];
}
const HospitalDaysFormStep3 = ({
  visitsWithPossibleChanges,
  toBeCanceledVisits,
  editedVisits,
}: IHospitalDaysFormStep3) => {
  const [visitsWithChanges, setVisitsWithChanges] = useState<IVisit[]>([]);
  const columns = getHospitalDaysVisitsColumnsWithoutActions(editedVisits, toBeCanceledVisits);

  useEffect(() => {
    const getVisitsWithChanges = () => {
      const visits: IVisit[] = [];
      visitsWithPossibleChanges.map((visit: IVisit) => {
        const checksOfChanges = checkIfVisitChanged(visit, editedVisits, toBeCanceledVisits);
        if (checksOfChanges.isCanceled || checksOfChanges.isEdit) {
          visits.push(visit);
        }
      });
      setVisitsWithChanges(visits);
    };
    getVisitsWithChanges();
  }, []);
  return (
    <div>
      <Box flexGrow={1} sx={{ mb: theme.spacing(3) }}>
        <Typography variant="subtitle2" gutterBottom sx={{ mt: 2 }}>
          {t('program.hospitalDaysForm.visitsChanges')}
        </Typography>
        <CustomizedTable columns={columns} data={visitsWithChanges} />
      </Box>
      <Divider />
    </div>
  );
};

export default HospitalDaysFormStep3;
