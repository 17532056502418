import { Field } from 'formik';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { StyledTextField, sxFormControl } from '../../../styles/FormFieldsStyles';
import { Select } from 'formik-mui';
import { MenuItem } from '@mui/material';
import FormikDatePickerField from '../../../components/Form/FormikDatePickerField';

interface ProfileFieldsProps {
  isFieldsDisabled: boolean;
}
const ProfileFields = ({ isFieldsDisabled }: ProfileFieldsProps) => {
  const { t } = useTranslation();

  return (
    <>
      <FormikDatePickerField
        label={t('personalDetails.birthDate')}
        name="personalDetails.birthDate"
        disabled={!isFieldsDisabled}
      />
      <Field
        fullWidth
        component={StyledTextField}
        type="text"
        label={t('personalDetails.primaryPhone')}
        name="personalDetails.primaryPhone"
        disabled={!isFieldsDisabled}
      />
      <Field
        fullWidth
        component={StyledTextField}
        type="text"
        label={t('personalDetails.secondaryPhone')}
        name="personalDetails.secondaryPhone"
        disabled={!isFieldsDisabled}
      />
      <Field
        fullWidth
        component={StyledTextField}
        type="text"
        label={t('personalDetails.martialStatus')}
        name="personalDetails.martialStatus"
        disabled={!isFieldsDisabled}
      />
      <Field
        fullWidth
        component={StyledTextField}
        type="text"
        label={t('personalDetails.address')}
        name="personalDetails.address"
        disabled={!isFieldsDisabled}
      />
      <Field
        component={Select}
        name="personalDetails.gender"
        label={t('personalDetails.gender')}
        formControl={{ sx: sxFormControl }}
        disabled={!isFieldsDisabled}
      >
        <MenuItem value="MALE">{t('personalDetails.MALE')}</MenuItem>
        <MenuItem value="FEMALE">{t('personalDetails.FEMALE')}</MenuItem>
      </Field>
    </>
  );
};

export default ProfileFields;
