export const HMPGeneralValues = {
  receipt: {
    subjective: '',
    objective: '',
    cognitive: '',
  },
  conditionBefore: '',
  afterSurgery: '',
  goals: '',
};
