import { Grid, MenuItem, Typography } from '@mui/material';
import React from 'react';
import { styled, SxProps } from '@mui/system';
import { theme } from '../../../../theme';
import { FastField as Field } from 'formik';
import { useTranslation } from 'react-i18next';
import { TextField } from 'formik-mui';
import FormikSelect from '../../../../components/FormikSelect';
import { painFields, properOptions, validNotValidOptions } from '../../../../common/HMMFirst';
import GenericField from '../../sharedFields/GenericField';

const StyledTextField = styled(TextField)({
  marginBottom: theme.spacing(2),
});

const sxFormControl: SxProps = {
  width: '100%',
};

interface RightLeftFieldsProps {
  header?: string;
  subHeader?: string;
}

const RightLeftFields = ({ header, subHeader }: RightLeftFieldsProps) => {
  const { t } = useTranslation();

  const coordinationFields = [
    {
      name: 'tone',
      right: {
        label: ` ${t('report.HMMFIRST.muscularMovement.motor.tone')} ${t(
          'global.form.select.right'
        )}`,
        name: 'rightTone',
      },
      left: {
        label: ` ${t('report.HMMFIRST.muscularMovement.motor.tone')} ${t(
          'global.form.select.left'
        )}`,
        name: 'leftTone',
      },
    },
    {
      name: 'force',
      right: {
        label: ` ${t('report.HMMFIRST.muscularMovement.motor.force')} ${t(
          'global.form.select.right'
        )}`,
        name: 'rightForce',
      },
      left: {
        label: `${t('report.HMMFIRST.muscularMovement.motor.force')} ${t(
          'global.form.select.left'
        )} `,
        name: 'leftForce',
      },
    },
    {
      name: 'coordination',
      right: {
        label: `${t('report.HMMFIRST.muscularMovement.motor.coordination')} ${t(
          'global.form.select.right'
        )} `,
        name: 'rightCoordination',
      },
      left: {
        label: ` ${t('report.HMMFIRST.muscularMovement.motor.coordination')} ${t(
          'global.form.select.left'
        )}`,
        name: 'leftCoordination',
      },
    },
  ];

  const feelingFields = [
    {
      name: 'superficialFeeling',
      right: {
        label: ` ${t('report.HMMFIRST.SensoryFunctions.fileds.superficialFeeling')} ${t(
          'global.form.select.right'
        )}`,
        name: 'rightSuperficialFeeling',
      },
      left: {
        label: ` ${t('report.HMMFIRST.SensoryFunctions.fileds.superficialFeeling')} ${t(
          'global.form.select.left'
        )}`,
        name: 'leftSuperficialFeeling',
      },
    },
    {
      name: 'deepFeeling',
      right: {
        label: ` ${t('report.HMMFIRST.SensoryFunctions.fileds.deepFeeling')} ${t(
          'global.form.select.right'
        )}`,
        name: 'rightDeepFeeling',
      },
      left: {
        label: ` ${t('report.HMMFIRST.SensoryFunctions.fileds.deepFeeling')} ${t(
          'global.form.select.left'
        )}`,
        name: 'leftDeepFeeling',
      },
    },
  ];

  const notePath = !header ? '.muscularMovement.motor' : '.SensoryFunctions.fileds';
  const id = `HMMFirst_${header}` || 'HMMFirst_MuscularMovement';

  return (
    <div id={id}>
      {header && (
        <Typography variant="subtitle1" gutterBottom align="left">
          {header}
        </Typography>
      )}
      {subHeader && (
        <Typography variant="subtitle2" gutterBottom align="left">
          {subHeader}
        </Typography>
      )}
      {(!header ? coordinationFields : feelingFields).map((field) => {
        return (
          <Grid
            container
            spacing={{ xs: 2, md: 4, lg: 4 }}
            key={field.name + field.right.label}
            id={`${id}_Container`}
          >
            <Grid item xs={12} md={4} lg={4}>
              <FormikSelect
                id={`${id}_SelectInput_${field.right.name}`}
                name={`data.${header ? 'SensoryFunctions' : 'muscularMovement'}.${
                  field.right.name
                }`}
                label={field.right.label}
                formControl={sxFormControl}
              >
                {(header ? properOptions : validNotValidOptions).map((option: string) => {
                  return (
                    <MenuItem key={option} value={option}>
                      {t(`global.form.select.${option}`)}
                    </MenuItem>
                  );
                })}
              </FormikSelect>
            </Grid>
            <Grid item xs={12} md={4} lg={4}>
              <FormikSelect
                id={`${id}_SelectInput_${field.left.name}`}
                name={`data.${header ? 'SensoryFunctions' : 'muscularMovement'}.${field.left.name}`}
                label={field.left.label}
                formControl={sxFormControl}
              >
                {(header ? properOptions : validNotValidOptions).map((option: string) => {
                  return (
                    <MenuItem key={option} value={option}>
                      {t(`global.form.select.${option}`)}
                    </MenuItem>
                  );
                })}
              </FormikSelect>
            </Grid>
            <Grid item xs={12} md={4} lg={4}>
              <Field
                id={`${id}_TextInput_${field.name}`}
                fullWidth
                component={StyledTextField}
                type="text"
                name={`data.${header ? 'SensoryFunctions' : 'muscularMovement'}.${field.name}Notes`}
                label={`${t(`report.HMMFIRST${notePath}.${field.name}`)} ${t(
                  'report.HMKFIRST.remarks'
                )}`}
              />
            </Grid>
          </Grid>
        );
      })}
      {header && (
        <GenericField id={`${id}_FeelingOfPain`} fields={painFields} sx={{ paddingTop: 0 }} />
      )}
    </div>
  );
};

export default RightLeftFields;
