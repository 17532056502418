import { Box, Card } from '@mui/material';
import { styled } from '@mui/system';
import { theme } from '../theme';

export const StyledHeader = styled('div')({
  marginBottom: theme.spacing(2),
});

export const CalendarCard = styled(Card)({
  padding: theme.spacing(4),
});

export const TabTableBox = styled(Box)({
  padding: theme.spacing(4),
});

export const StyledBox = styled(Box, { name: 'StyledBox', slot: 'Wrapper' })({
  padding: theme.spacing(4),
});
