import React from 'react';
import { TextField } from 'formik-mui';
import { Field } from 'formik';
import { useTranslation } from 'react-i18next';
import { styled } from '@mui/system';
import { theme } from '../../theme';
import { Box } from '@mui/material';
import PasswordField from './PasswordField';

const StyledTextField = styled(TextField)({
  marginBottom: theme.spacing(2),
});

const ResetPasswordFields = () => {
  const { t } = useTranslation();

  return (
    <Box sx={{ mt: 1, width: '100%' }}>
      <PasswordField label={t('user.form.password')} name="newPassword" />
      <Field
        fullWidth
        component={StyledTextField}
        type="password"
        label={t('user.form.confirmPassword')}
        name="confirmNewPassword"
      />
    </Box>
  );
};

export default ResetPasswordFields;
