/* eslint-disable new-cap */
import React, { useState } from 'react';
import { IPatientProgram, IVisit } from '../../interfaces/Models';
import { useTranslation } from 'react-i18next';
import { Card } from '@mui/material';
import Table from '../../components/Table';
import { Row } from 'react-table';
import { getReportName } from '../../common/ReportsCommon';
import { useNavigate } from 'react-router-dom';
import ReportService from '../../services/ReportService';
import FetcherService from '../../hooks/FetcherService';
import { ApiResponse } from '../../interfaces/ApiResponseType';
import { handleTableAtomChange, onDownloadPdf, onVisitClick } from '../../utils/Helper';
import { getProgramVisitColumns } from '../../common/columns/ProgramVisits';
import OverlaySpinner from '../../components/OverlaySpinner';
import { toast } from 'react-toastify';
import VisitsActions from '../visits/VisitsActions';
import { StyledBox } from '../../styles/TabsStyles';
import { PROGRAM_PAYMENT_MODE } from '../../common/Constants';

interface ProgramVisitsProps {
  visits: IVisit[];
  handleTableUpdate: () => void;
  program: IPatientProgram;
  isLeumitPatient?: boolean;
}
const ProgramVisits = ({
  visits,
  handleTableUpdate,
  program,
  isLeumitPatient = false,
}: ProgramVisitsProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [isLoadingReport, setIsLoadingReport] = useState<boolean>(false);
  const [record, setRecord] = useState<IVisit | undefined>(undefined);
  const [cancelVisitModal, setCancelVisitModal] = useState<boolean>(false);
  const [visitToDoneModal, setVisitToDoneModal] = useState<boolean>(false);
  const [sentToClinicModal, setSentToClinicModal] = useState<boolean>(false);
  const [sentToClinicDownloadModal, setSentToClinicDownloadModal] = useState<boolean>(false);
  const [openChangeVisitDueDate, setOpenChangeVisitDueDate] = useState<boolean>(false);
  const [openSwapVisitEmployee, setOpenSwapVisitEmployee] = useState<boolean>(false);
  const [openChangeClinicReferenceNumber, setOpenChangeClinicReferenceNumber] =
    useState<boolean>(false);

  const handelEditReport = (visit: IVisit) => {
    onVisitClick(navigate, visit, true);
  };
  const handelLoading = (value: boolean) => {
    setIsLoadingReport(value);
  };

  const handelOpenSwapVisitEmployee = (value: IVisit) => {
    setOpenSwapVisitEmployee(true);
    setRecord(value);
  };

  const handelCloseModal = () => {
    setCancelVisitModal(false);
    setVisitToDoneModal(false);
    setSentToClinicModal(false);
    setSentToClinicDownloadModal(false);
    setOpenChangeVisitDueDate(false);
    setOpenSwapVisitEmployee(false);
    setOpenChangeClinicReferenceNumber(false);
    setRecord(undefined);
  };

  const handelChangeVisitDueDateModal = (value: IVisit) => {
    setOpenChangeVisitDueDate(true);
    setRecord(value);
  };

  const handelOpenCancelVisitModal = (value: IVisit) => {
    setCancelVisitModal(true);
    setRecord(value);
  };

  const handelOpenChangeVisitStatusToDone = (value: IVisit) => {
    setVisitToDoneModal(true);
    setRecord(value);
  };

  const handelSentToClinicModal = (value: IVisit) => {
    setSentToClinicModal(true);
    setRecord(value);
  };

  const handelSentToClinicDownloadModal = (value: IVisit) => {
    setSentToClinicDownloadModal(true);
    setRecord(value);
  };
  const handleOpenChangeClinicReferenceNumberModal = (value: IVisit) => {
    setOpenChangeClinicReferenceNumber(true);
    setRecord(value);
  };

  const downloadReport = async (visit?: IVisit) => {
    handleTableAtomChange('visitId', visit?._id);
    const onSuccess = (res: ApiResponse<BlobPart>) => {
      onDownloadPdf(res, getReportName(visit));
    };
    const onFailed = () => {
      toast.error(t('global.errors.report'));
    };
    await FetcherService<any>({
      service: ReportService.generateReportPdf(visit?._id || ''),
      onSuccess: onSuccess,
      onFailed: onFailed,
      setLoading: setIsLoadingReport,
    });
  };

  const isVisitLevelProgram = program.paymentMode === PROGRAM_PAYMENT_MODE.VISIT_LEVEL;

  const columns = getProgramVisitColumns({
    handelEditReport,
    downloadReport,
    sentToClinicHandler: handelSentToClinicModal,
    handelCancelVisit: handelOpenCancelVisitModal,
    handelOpenChangeVisitStatusToDone,
    handelSentToClinicDownloadModal,
    handelChangeVisitDueDateModal,
    handelOpenSwapVisitEmployee,
    handleOpenChangeClinicReferenceNumberModal,
    isVisitLevelProgram,
    isLeumitPatient,
  });

  const onRowClick = (row: Row<IVisit>) => {
    onVisitClick(navigate, row.original);
  };

  return (
    <>
      <VisitsActions
        record={record}
        cancelVisitModal={cancelVisitModal}
        visitToDoneModal={visitToDoneModal}
        sentToClinicModal={sentToClinicModal}
        sentToClinicDownloadModal={sentToClinicDownloadModal}
        handelCloseModal={handelCloseModal}
        handelTableUpdate={handleTableUpdate}
        handelLoadingReport={handelLoading}
        downloadReport={downloadReport}
        openChangeVisitDueDataModal={openChangeVisitDueDate}
        openSwapVisitEmployeeModal={openSwapVisitEmployee}
        openChangeClinicReferenceNumber={openChangeClinicReferenceNumber}
      />
      <Card>
        <StyledBox flexGrow={1}>
          <Table
            columns={columns}
            data={visits}
            count={visits.length}
            isLoading={false}
            onRowClick={onRowClick}
            pageSizes={[10]}
            showTopPagination={true}
          />
        </StyledBox>
      </Card>
      {isLoadingReport && <OverlaySpinner />}
    </>
  );
};

export default ProgramVisits;
