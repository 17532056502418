import React from 'react';
import { StyledDivider, StyledGrid } from '../styles/FormFieldsStyles';
import { IMedicine } from '../interfaces/Models';
import { Box, Button, Grid, IconButton, MenuItem, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { medicationDosages, medicationGivingMethod, medicinesNames } from '../common/Medicines';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import { FieldArray } from 'formik';
import FormikAutocomplete from './Form/FormikAutocomplete';
import FormikSelect from './FormikSelect';
import { FastField as Field } from 'formik';
import { TextField } from 'formik-mui';

const sxFormControl = {
  width: '100%',
};

const MedicationTranslationOptions = ['day', 'week', 'month'];

const medicineInputState = {
  givingMethod: 'P.O',
  quantityUnit: 'MG',
  frequencyPeriod: 'day',
  MedicationPeriodUnit: 'week',
  name: null,
  quantity: '',
  frequency: '',
  MedicationPeriod: '',
};

interface Props {
  id: string;
  isViewMode?: boolean;
  value: Array<IMedicine>;
  fieldName: string;
}
export default function MedicationsListField({ id, isViewMode = false, value, fieldName }: Props) {
  const { t } = useTranslation();

  return (
    <Box>
      <Typography variant="subtitle1" gutterBottom align="left">
        {t('report.chronicDiseases.medications')}
      </Typography>
      <FieldArray name={fieldName}>
        {({ remove, push }) => (
          <StyledGrid container justifyContent="left" id={`${id}_Container`}>
            <StyledGrid item xs={12} md={12} lg={12}>
              {value.map((item: IMedicine, index: number) => (
                <div id={`ChronicMedications__Container`} key={`ChronicMedications_${index}`}>
                  <Box
                    sx={{
                      display: 'flex',
                      width: '100%',
                      flexDirection: { xs: 'column', md: 'row' },
                    }}
                  >
                    <StyledGrid container spacing={2}>
                      <Grid item xs={12}>
                        <Grid container spacing={2}>
                          <Grid item xs={12} md={4}>
                            <FormikAutocomplete
                              disabled={isViewMode}
                              options={medicinesNames}
                              freeSolo
                              label={t('report.chronicDiseases.name')}
                              name={`${fieldName}.${index}.name`}
                            />
                          </Grid>
                          <Grid item xs={12} md={4}>
                            <FormikSelect
                              name={`${fieldName}.${index}.givingMethod`}
                              label={t('report.chronicDiseases.givingMethod')}
                              formControl={sxFormControl}
                              isClearable={false}
                            >
                              {medicationGivingMethod.map((option: string) => {
                                return (
                                  <MenuItem key={`${option}_${index}`} value={option}>
                                    {option}
                                  </MenuItem>
                                );
                              })}
                            </FormikSelect>
                          </Grid>
                          <Grid item xs={12} md={4}>
                            <Field
                              fullWidth
                              multiline
                              component={TextField}
                              name={`${fieldName}.${index}.quantity`}
                              label={t('report.chronicDiseases.dosage')}
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                      {/* end or row 1 */}
                      <Grid item xs={12}>
                        <Grid container spacing={2}>
                          <Grid item xs={12} md={4}>
                            <FormikSelect
                              name={`${fieldName}.${index}.quantityUnit`}
                              label={t('report.chronicDiseases.dosageUnit')}
                              formControl={sxFormControl}
                              isClearable={false}
                            >
                              {medicationDosages.map((option: string) => {
                                return (
                                  <MenuItem key={`${option}_${index}`} value={option}>
                                    {option}
                                  </MenuItem>
                                );
                              })}
                            </FormikSelect>
                          </Grid>
                          <Grid item xs={12} md={4}>
                            <Field
                              fullWidth
                              multiline
                              component={TextField}
                              name={`${fieldName}.${index}.frequency`}
                              label={t('report.chronicDiseases.frequency')}
                            />
                          </Grid>
                          <Grid item xs={12} md={4}>
                            <FormikSelect
                              name={`${fieldName}.${index}.frequencyPeriod`}
                              label={t('report.chronicDiseases.frequencyPeriod')}
                              formControl={sxFormControl}
                              isClearable={false}
                            >
                              {MedicationTranslationOptions.map((option: string) => {
                                return (
                                  <MenuItem key={`${option}_${index}`} value={option}>
                                    {option}
                                  </MenuItem>
                                );
                              })}
                            </FormikSelect>
                          </Grid>
                        </Grid>
                      </Grid>
                      {/* end or row 2 */}
                      <Grid item xs={12}>
                        <Grid container spacing={2}>
                          <Grid item xs={12} md={6}>
                            <Field
                              fullWidth
                              multiline
                              component={TextField}
                              name={`${fieldName}.${index}.MedicationPeriod`}
                              label={t('report.chronicDiseases.MedicationPeriod')}
                            />
                          </Grid>
                          <Grid item xs={12} md={6}>
                            <FormikSelect
                              name={`${fieldName}.${index}.MedicationPeriodUnit`}
                              label={t('report.chronicDiseases.MedicationPeriodUnit')}
                              formControl={sxFormControl}
                              isClearable={false}
                            >
                              {MedicationTranslationOptions.map((option: string) => {
                                return (
                                  <MenuItem key={`${option}_${index}`} value={option}>
                                    {option}
                                  </MenuItem>
                                );
                              })}
                            </FormikSelect>
                          </Grid>
                        </Grid>
                      </Grid>
                      {/* end or row 3 */}
                    </StyledGrid>
                    <Box sx={{ display: 'grid', placeItems: 'center', marginBottom: 0 }}>
                      <IconButton
                        color="error"
                        aria-label="delete"
                        onClick={() => remove(index)}
                        size="large"
                        disabled={isViewMode}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </Box>
                  </Box>
                  <StyledDivider />
                </div>
              ))}

              <Button
                variant="contained"
                disabled={isViewMode}
                endIcon={<AddIcon />}
                onClick={() => push({ ...medicineInputState })}
              >
                {t('global.form.add')}
              </Button>
            </StyledGrid>
          </StyledGrid>
        )}
      </FieldArray>
    </Box>
  );
}
