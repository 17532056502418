import { IQuery, IService } from '../interfaces/index';
import { HTTP_METHODS } from '../common/APIHelper';
import { IPatientPrescription } from '../interfaces/prescription';

const BASE_PATH = '/api/patient-prescription';

const getAll = (query?: IQuery): IService => {
  return { route: `${BASE_PATH}`, method: HTTP_METHODS.GET, query };
};

const add = (payload: IPatientPrescription): IService => {
  return { route: `${BASE_PATH}`, method: HTTP_METHODS.POST, payload };
};

const deleteOneById = (id: string): IService => {
  return { route: `${BASE_PATH}/${id}`, method: HTTP_METHODS.DELETE };
};

const downloadOneById = (id: string): IService => {
  return { route: `${BASE_PATH}/${id}/download`, method: HTTP_METHODS.GET, responseType: 'blob' };
};

const prescriptionService = {
  add,
  getAll,
  downloadOneById,
  deleteOneById,
};

export default prescriptionService;
