/* eslint-disable new-cap */
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useParams, useOutletContext } from 'react-router-dom';
import * as Yup from 'yup';
import { reportTypes } from '../../../common/Constants';
import ErrorMessage from '../../../components/ErrorMessage';
import { HMPRepeatedValues } from '../../../common/reportsFieldsValues/hmp/HMPRepeatedValues';
import HMPRepeatedFields from './fields/HMPRepeatedFields';
import ReportsForm from '../../../components/Form/ReportsForm';

const HMPRepeated = () => {
  const { t } = useTranslation();
  const { visitId } = useParams();
  const data: any = useOutletContext();

  const initialValues =
    data && data.name
      ? data
      : {
          name: reportTypes.HMP_REPEATED,
          visit: visitId,
          data: data ? data : HMPRepeatedValues,
        };
  const ReportSchema = Yup.object({
    name: Yup.string().required(t('clinic.form.required')),
    visit: Yup.string().required(t('clinic.form.required')),
    data: Yup.object().shape({
      treatment: Yup.string().required(t('clinic.form.required')),
      status: Yup.string().required(t('clinic.form.required')),
      recommendations: Yup.string().required(t('clinic.form.required')),
    }),
  });

  const HtmlId = 'HMPRepeated';

  if (data?.name && data.name != reportTypes.HMP_REPEATED) {
    return <ErrorMessage error={t('global.errors.somethingWrong')} />;
  }
  return (
    <ReportsForm
      initialValues={initialValues}
      visitId={visitId || ''}
      validationSchema={ReportSchema}
      name={data?.name}
      resetForm={true}
      resetFormValues={{ ...initialValues, data: HMPRepeatedValues }}
    >
      <HMPRepeatedFields id={HtmlId} />
    </ReportsForm>
  );
};

export default HMPRepeated;
