import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
} from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { englishPracticeOptions } from '../../../common/PracticeData';
import { IPractice, IPracticeField } from '../../../interfaces/Models';

interface PracticeFieldSelectInputProps {
  field: IPracticeField;
  practice: IPractice;
  onChangeSubCategoryField: (
    fieldIdx: number,
    subFieldIdx: number,
    event: SelectChangeEvent<string> | React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
  subFieldIdx: number;
  fieldIdx: number;
}
const PracticeFieldSelectInput = ({
  field,
  practice,
  onChangeSubCategoryField,
  subFieldIdx,
  fieldIdx,
}: PracticeFieldSelectInputProps) => {
  const { t } = useTranslation();

  return (
    <FormControl
      sx={{
        width: '100%',
      }}
      error={(field.errors?.length || false) > 1}
    >
      <InputLabel>{t(`report.practiceFields.fields.${field.name}`)}</InputLabel>
      <Select
        sx={{ width: '100%' }}
        id={`${field.name}_Filter`}
        disabled={practice.added}
        value={practice.fields[subFieldIdx].value}
        name={field.name}
        label={t(`report.practiceFields.fields.${field.name}`)}
        onChange={(e) => onChangeSubCategoryField(fieldIdx, subFieldIdx, e)}
      >
        {field.options?.map((option: string, fieldOptionIdx: number) => {
          if (englishPracticeOptions.indexOf(option) > -1) {
            return (
              <MenuItem key={`${option}_${fieldOptionIdx}`} value={option}>
                {option}
              </MenuItem>
            );
          }
          return (
            <MenuItem key={`${option}_${fieldOptionIdx}`} value={option}>
              {field.optionLable
                ? `${t(`report.practiceFields.options.${field.optionLable}`)} - ${t(
                    `report.practiceFields.options.${option}`
                  )}`
                : t(`report.practiceFields.options.${option}`)}
            </MenuItem>
          );
        })}
      </Select>
      <FormHelperText>{field.errors && field.errors.length > 1 ? field.errors : ''}</FormHelperText>
    </FormControl>
  );
};

export default PracticeFieldSelectInput;
