import React, { ReactElement } from 'react';
import { Stack, Typography } from '@mui/material';

interface props {
  pageTitle: string;
  mb?: number;
  pageFilter?: ReactElement;
  smallScreenDirection?: 'row' | 'column';
}

const PageHeader: React.FC<props> = ({ pageTitle, mb, pageFilter, smallScreenDirection }) => {
  return (
    <Stack
      direction={{ xs: smallScreenDirection || 'column', md: 'row' }}
      alignItems={{ xs: 'flex-start', md: 'center' }}
      justifyContent="space-between"
      mb={mb || 5}
    >
      <Typography variant="h3" gutterBottom>
        {pageTitle}
      </Typography>
      {pageFilter && pageFilter}
    </Stack>
  );
};

export default PageHeader;
