import { Button, Dialog, DialogActions } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { theme } from '../theme';
import { StyledTitle } from './Modals/FormModal';

interface IAutoLogoutModal {
  open: boolean;
}
const AutoLogoutModal = ({ open }: IAutoLogoutModal) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const handleLogout = () => {
    navigate('/login');
  };
  return (
    <Dialog fullWidth open={open}>
      <StyledTitle sx={{ pt: theme.spacing(3) }}>{t('global.dashboard.idleUser')}</StyledTitle>
      <DialogActions sx={{ justifyContent: 'center', mb: theme.spacing(3) }}>
        <Button variant="contained" size="large" color="error" onClick={handleLogout}>
          {t('global.dashboard.logout')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AutoLogoutModal;
