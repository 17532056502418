import React from 'react';
import { TextField } from 'formik-mui';
import { FastField } from 'formik';
import { useTranslation } from 'react-i18next';
import { Grid, MenuItem, Typography } from '@mui/material';
import DigestiveSystemFields from './DigestiveSystemFields';
import { styled } from '@mui/system';
import { theme } from '../../../../theme';
import AdditionalInfoFields from './AdditionalInfoFields';
import { sxFormControl } from '../../../../styles/FormFieldsStyles';
import FormikSelect from '../../../../components/FormikSelect';

const StyledGrid = styled(Grid)({
  marginBottom: theme.spacing(2),
});

const HealthIndicesFields = () => {
  const { t } = useTranslation();
  const id = 'HMDFirst_HealthIndices_Anthropometry';

  const fields = [
    {
      name: 'height',
      label: t('report.HMDFIRST.healthIndices.anthropometry.height'),
    },
    {
      name: 'heightMeasureMethod',
      label: t('report.HMDFIRST.healthIndices.anthropometry.heightMeasureMethod'),
      type: 'select',
      options: ['measure', 'estimated', 'notKnow', 'toPatient'],
    },
    {
      name: 'heelLength',
      label: t('report.HMDFIRST.healthIndices.anthropometry.heelLength'),
    },
    {
      name: 'weight',
      label: t('report.HMDFIRST.healthIndices.anthropometry.weight'),
    },
    {
      name: 'weightMeasureMethod',
      label: t('report.HMDFIRST.healthIndices.anthropometry.weightMeasureMethod'),
      type: 'select',
      options: ['measure', 'estimated', 'notKnow', 'toPatient'],
    },
    {
      name: 'BMI',
      label: t('report.HMDFIRST.healthIndices.anthropometry.BMI'),
    },
    {
      name: 'weightLoss',
      label: t('report.HMDFIRST.healthIndices.anthropometry.weightLoss'),
    },
    {
      name: 'estimatedWeight',
      label: t('report.HMDFIRST.healthIndices.anthropometry.estimatedWeight'),
    },
    {
      name: 'percentagefixedWeight',
      label: t('report.HMDFIRST.healthIndices.anthropometry.percentagefixedWeight'),
    },
    {
      name: 'waistCircumference',
      label: t('report.HMDFIRST.healthIndices.anthropometry.waistCircumference'),
    },
    {
      name: 'armCircumference',
      label: t('report.HMDFIRST.healthIndices.anthropometry.armCircumference'),
    },
    {
      name: 'scope',
      label: t('report.HMDFIRST.healthIndices.anthropometry.scope'),
    },
  ];
  return (
    <>
      <div id={id}>
        <Typography variant="subtitle1" gutterBottom align="left">
          {t('report.HMDFIRST.healthIndices.header')}
        </Typography>
        <Typography variant="subtitle2" gutterBottom align="left">
          {t('report.HMDFIRST.healthIndices.anthropometry.header')}
        </Typography>
        <StyledGrid container spacing={{ xs: 2, md: 4, lg: 4 }} id={`${id}_Container`}>
          {fields.map((field) => {
            switch (field.type) {
              case 'select':
                return (
                  <Grid item xs={12} md={4} lg={4} key={field.name}>
                    <FormikSelect
                      id={`${id}_${field.name}`}
                      name={`data.healthIndices.anthropometry.${field.name}`}
                      label={t(`${field.label}`)}
                      formControl={sxFormControl || {}}
                    >
                      {field.options?.map((option: string) => {
                        return (
                          <MenuItem key={`${option}_${field.name}`} value={option}>
                            {t(`global.form.select.${option}`)}
                          </MenuItem>
                        );
                      })}
                    </FormikSelect>
                  </Grid>
                );
              default:
                return (
                  <Grid item xs={12} md={4} lg={4} key={field.name}>
                    <FastField
                      id={`${id}_${field.name}`}
                      fullWidth
                      component={TextField}
                      type="text"
                      name={`data.healthIndices.anthropometry.${field.name}`}
                      label={field.label}
                    />
                  </Grid>
                );
            }
          })}
        </StyledGrid>
      </div>
      <DigestiveSystemFields />
      <AdditionalInfoFields />
    </>
  );
};

export default HealthIndicesFields;
