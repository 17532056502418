export const HMSFirstValues = {
  socioeconomicBackground: '',
  socialSituation: '',
  cognitiveStatus: '',
  socialSecurity: '',
  patientExpectations: '',
  expectations: '',
  guidelines: '',
  workerInterventions: '',
};
