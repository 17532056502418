import { Grid } from '@mui/material';
import { t } from 'i18next';
import React from 'react';
import DateInput from '../../../components/DateInput';
import { ProgramDatesGrid } from '../../../styles/ProgramStyles';

export type ProgramDatesTypes = 'programStart' | 'programEnd';

interface IProgramStartEndFields {
  programStart: Date | null;
  programEnd: Date | null;
  programStartError: string;
  programEndError: string;
  handleProgramDatesChange: (name: string, value: Date | null) => void;
}
const ProgramStartEndFields = ({
  programStart,
  programEnd,
  programStartError,
  programEndError,
  handleProgramDatesChange,
}: IProgramStartEndFields) => {
  return (
    <ProgramDatesGrid container item justifyContent="space-between" spacing={{ xs: 1 }}>
      <Grid item xs={12} md={5.84} lg={5.84}>
        <DateInput
          value={programStart}
          name="programStart"
          handleChange={handleProgramDatesChange}
          label={t('patient.info.startDate')}
          error={programStartError}
        />
      </Grid>
      <Grid item xs={12} md={5.84} lg={5.84}>
        <DateInput
          value={programEnd}
          name="programEnd"
          handleChange={handleProgramDatesChange}
          label={t('patient.info.endDate')}
          error={programEndError}
        />
      </Grid>
    </ProgramDatesGrid>
  );
};

export default ProgramStartEndFields;
