// @ts-nocheck
import React from 'react';
import { he, enUS } from 'date-fns/locale';
import i18n from 'i18next';
import { LocalizationProvider, MobileDatePicker } from '@mui/lab';
import PickersDay, { PickersDayProps, pickersDayClasses } from '@mui/lab/PickersDay';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { TextField, TextFieldProps } from '@mui/material';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { useTranslation } from 'react-i18next';
import { theme } from '../theme';

const renderWeekPickerDay = (
  date: Date,
  selectedDates: Array<Date | null>,
  pickersDayProps: PickersDayProps<Date>
) => {
  return (
    <PickersDay
      {...pickersDayProps}
      sx={{
        [`&&.${pickersDayClasses?.disabled}`]: {
          backgroundColor: theme.palette.error.light,
        },
      }}
    />
  );
};

interface IDateInput {
  value: Date | null;
  handleChange: (name: string, value: Date | null) => void;
  label: string;
  name: string;
  error: string;
  shouldDisableDate?: (value: Date) => boolean;
}

const DateInput = ({ value, name, handleChange, label, error, shouldDisableDate }: IDateInput) => {
  const { t } = useTranslation();

  return (
    <LocalizationProvider locale={i18n.language == 'he' ? he : enUS} dateAdapter={AdapterDateFns}>
      <MobileDatePicker
        label={label}
        renderDay={renderWeekPickerDay}
        inputFormat="dd/MM/yyyy"
        value={value}
        cancelText={t('clinic.form.cancel')}
        okText={t('global.filters.ok')}
        components={{
          LeftArrowIcon: KeyboardArrowRightIcon,
          RightArrowIcon: KeyboardArrowLeftIcon,
        }}
        onChange={(newValue: Date | null) => handleChange(name, newValue)}
        renderInput={(params: JSX.IntrinsicAttributes & TextFieldProps) => (
          <TextField {...params} fullWidth error={error.length > 0} helperText={error} />
        )}
      />
    </LocalizationProvider>
  );
};

export default DateInput;
