import { Field } from 'formik';
import React from 'react';
import { FormFieldsProps } from '../../components/Form/FormikForm';
import { Autocomplete, AutocompleteRenderInputParams } from 'formik-mui';
import OverlaySpinner from '../../components/OverlaySpinner';
import ErrorMessage from '../../components/ErrorMessage';
import clinicService from '../../services/ClinicService';
import useService from '../../hooks/HookService';
import { IClinic } from '../../interfaces/Models';
import { StyledTextInput } from '../../styles/FormFieldsStyles';
import { t } from 'i18next';

const PatientClinicField = ({ touched, errors }: FormFieldsProps) => {
  const { isLoading, data, error, errorMessage } = useService<IClinic[]>({
    service: clinicService.getAll(),
    deps: [],
  });
  if (isLoading) {
    return <OverlaySpinner />;
  }
  if (error || !data) {
    return <ErrorMessage error={errorMessage} />;
  }
  return (
    <Field
      fullWidth
      name="clinic"
      component={Autocomplete}
      options={data}
      getOptionLabel={(option: IClinic) => option.name || ''}
      renderInput={(params: AutocompleteRenderInputParams) => (
        <StyledTextInput
          {...params}
          name="clinic"
          error={touched['clinic'] && !!errors['clinic']}
          helperText={touched['clinic'] && errors['clinic'] ? t('clinic.form.required') : ''}
          label={t('patient.form.clinic')}
          variant="outlined"
        />
      )}
    />
  );
};

export default PatientClinicField;
