export const HMDFirstValues = {
  generalMedicalInfo: {
    treattingFactor: '',
    nutritionalCounseling: '',
    allergies: '',
    additionalDetails: '',
    bloodTest: {
      testDate: '',
      source: '',
      GFR: '',
      folicAcid: '',
      albumin: '',
      sodium: '',
      totalCholesterol: '',
      hemoglobin: '',
      potassium: '',
      LDLCholesterol: '',
      glucose: '',
      phosphorus: '',
      HDLCholesterol: '',
      HBA1C: '',
      Tsh: '',
      triglycerides: '',
      ferritin: '',
      uricAcid: '',
      NonHDL: '',
      iron: '',
      Wbc: '',
      VitaminD: '',
      Transferin: '',
      lymp: '',
      creatinine: '',
      totalProtein: '',
      BUN: '',
      VitaminB12: '',
      other: '',
    },
  },
  healthIndices: {
    anthropometry: {
      height: '',
      heightMeasureMethod: '',
      heelLength: '',
      weight: '',
      weightMeasureMethod: '',
      BMI: '',
      weightLoss: '',
      estimatedWeight: '',
      percentagefixedWeight: '',
      waistCircumference: '',
      armCircumference: '',
      scope: '',
    },
    digestiveSystem: {
      chewingDifficulties: '',
      teeth: '',
      difficultySwallowing: '',
      appetite: '',
      bowelMovements: '',
      otherBowelMovements: '',
    },
    additionalInformation: {
      feedForm: '',
      dependenceFeeding: '',
      alternateFeed: '',
      TPNAspirations: '',
      stoma: '',
      stoma1: '',
      bedsores: {
        bedsoresValue: '',
        woundTax: '',
        location: '',
        rank: '',
      },
      edema: {
        edemaValue: '',
        location: '',
        severity: '',
      },
      breathing: '',
      smoking: '',
      mobility: '',
    },
    nutritionIndices: '',
  },
  nutritionalConsumption: {
    nutritionalHistory: {
      anamnesis: '',
      patient: '',
      textureFood: '',
    },
    eatingOrder: {
      morning: '',
      intermediate: '',
      noon: '',
      intermediate1: '',
      evening: '',
      night: '',
    },
    completionHistory: '',
    drink: '',
    supplements: {
      calcium: '',
      iron: '',
      vitaminD: '',
      multiVitamin: '',
      other: '',
    },
    notes: '',
  },
  evaluationBeforeTreatment: {
    designatedFood: '',
    amount: '',
    feedRate: '',
    forHoures: '',
    waterPerDay: '',
    notes: '',
  },
  evaluationBeforeAsssistance: {
    food: {
      energy: '',
      protein: '',
      liquids: '',
    },
    medicalFood: {
      energy: '',
      protein: '',
      liquids: '',
    },
    total: {
      energy: '',
      protein: '',
      liquids: '',
    },
    notes: '',
  },
  nutritionalGoals: {
    nutritionalNeeds: {
      desiredWeight: '',
      standardWeight: '',
      basicEnergy: '',
      totalPatientNeed: {
        energy: '',
        protein: '',
        fluids: '',
      },
      details: '',
      restrictions: '',
      nutritionalGoals: {
        purpose: '',
        objectives: '',
        weightTargets: '',
      },
      nutritionalTreatment: [],
    },
  },
  recommendations: {
    eatingOrally: {
      foodTexture: '',
      sample: '',
      dietary: '',
      useMedical: '',
      estimationRecommendations: {
        energy: '',
        protein: '',
        fluids: '',
      },
      dedicatedFood: {
        energy: '',
        protein: '',
        fluids: '',
      },
      notesOral: '',
    },
    alternativeFeeding: {
      medicalName: '',
      feedVolume: {
        volume: '',
        energy: '',
        protein: '',
        fluids: '',
      },
      addedFluid: '',
      totalFluids: '',
      electricPump: '',
      feedRate: {
        rate: '',
        houers: '',
      },
      alternativeFeedingNotes: '',
    },
  },
  summary: {
    generalSummary: '',
    recommended: '',
    reunion: '',
    setDate: '',
  },
};
