import React, { ReactElement } from 'react';
import { TableContainer, TablePagination } from '@mui/material';
import TableContent from './TableContent';
import { Column, useGlobalFilter, useTable, usePagination } from 'react-table';
import TableHeader from './TableHeader';
import { IPageInfo } from '../pages/clinic/ClinicTable';
import { Subject } from '../casl/ability';
import TableSkeleton from './skeletons/TableSkeleton';
import { URL_QUERY_PARAMS } from '../common/Constants';
import useSearchFilter from '../hooks/useSearchFilter';
import { FiltersPositions } from './WidgetContainer';
import TablePaginationActions from './TablePaginationActions';
import { IBaseSchema } from '../interfaces/Models';

export interface IFilter {
  filterName: string;
  component: ReactElement;
}

export interface ICustomRow<T> {
  row: T;
}

interface TableProps<T> {
  columns: Array<Column>;
  data: Array<T>;
  pageSizes?: number[];
  count: number;
  pageInfo?: IPageInfo;
  setOpen?: (value: boolean) => void;
  url?: string;
  buttonText?: string;
  onRowClick?: (value: any) => void;
  isLoading: boolean;
  filters?: IFilter[];
  showPagination?: boolean;
  customFooter?: JSX.Element | null;
  showTopPagination?: boolean;
  actionAccess?: Subject;
  TableHeaderFilters?: ReactElement;
  showIndex?: boolean;
  showFilters?: boolean;
  filterPosition?: FiltersPositions;
  ExpandCard?: React.FC<ICustomRow<T>>;
}
export default function Table<T extends IBaseSchema>({
  columns,
  data,
  pageSizes,
  count,
  setOpen,
  url,
  buttonText,
  pageInfo,
  onRowClick,
  isLoading,
  filters,
  showPagination = true,
  customFooter,
  showTopPagination,
  actionAccess,
  TableHeaderFilters,
  showIndex,
  showFilters,
  filterPosition,
  ExpandCard,
}: TableProps<T>) {
  const sizes = [10, 20, 30, 50];
  const { changeQueryParams } = useSearchFilter();

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    page,
    gotoPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data: data,
      manualGlobalFilter: true,
      initialState: {
        pageSize:
          !pageSizes && !showPagination
            ? 1000
            : pageSizes && showPagination
            ? pageSizes[0]
            : sizes[0],
      },
    },
    useGlobalFilter,
    usePagination
  );

  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    if (pageInfo) {
      changeQueryParams([{ name: URL_QUERY_PARAMS.PAGE, value: String(newPage + 1) }]);
    } else {
      gotoPage(newPage);
    }
  };

  const handlePageSizeChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    if (pageInfo) {
      changeQueryParams([
        { name: URL_QUERY_PARAMS.LIMIT, value: e.target.value },
        { name: URL_QUERY_PARAMS.PAGE, value: '1' },
      ]);
    } else {
      setPageSize(Number(e.target.value));
    }
  };

  return (
    <>
      <TableContainer sx={{ minWidth: 200 }}>
        {(filters && filters.length > 0) || showFilters ? (
          <TableHeader
            url={url}
            buttonText={buttonText}
            clickHandler={setOpen}
            filters={filters}
            actionAccess={actionAccess}
            TableHeaderFilters={TableHeaderFilters}
            filterPosition={filterPosition}
          />
        ) : null}
        {showTopPagination && (
          <>
            <TablePagination
              rowsPerPageOptions={pageSizes || sizes}
              component="div"
              count={count}
              rowsPerPage={pageInfo ? pageInfo.pageSize : pageSize}
              page={pageInfo ? pageInfo.page : pageIndex}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handlePageSizeChange}
              sx={{ display: isLoading ? 'none' : '' }}
              ActionsComponent={TablePaginationActions}
            />
          </>
        )}
        <TableContent
          ExpandCard={ExpandCard}
          showIndex={showIndex}
          headerGroups={headerGroups}
          getTableBodyProps={getTableBodyProps}
          rows={pageInfo ? rows : page}
          prepareRow={prepareRow}
          getTableProps={getTableProps}
          onRowClick={onRowClick}
          customFooter={customFooter}
          sx={{ display: isLoading ? 'none' : '' }}
        />
        {showPagination && (
          <>
            <TablePagination
              rowsPerPageOptions={pageSizes || sizes}
              component="div"
              count={count}
              rowsPerPage={pageInfo ? pageInfo.pageSize : pageSize}
              page={pageInfo ? pageInfo.page : pageIndex}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handlePageSizeChange}
              sx={{ display: isLoading ? 'none' : '' }}
              ActionsComponent={TablePaginationActions}
              labelRowsPerPage={'שורות בעמוד'}
            />
          </>
        )}
        <TableSkeleton
          filtersLength={filters?.length || 0}
          topPagination={showTopPagination}
          bottomPagination={showPagination}
          sx={isLoading ? { display: 'block' } : { display: 'none' }}
        />
      </TableContainer>
    </>
  );
}
