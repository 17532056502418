import React from 'react';
import { useTranslation } from 'react-i18next';
import ErrorMessage from '../../../components/ErrorMessage';
import PiChart from '../../../components/PiChart';
import PiChartWidgetSkeleton from '../../../components/skeletons/PiChartWidgetSkeleton';
import { IServiceResponse } from '../../../hooks/HookService';
import { IEmployeesFinancialReports } from '../../../interfaces/Reports';
import { theme } from '../../../theme';

export interface IChartToolTipFormatter {
  series: number[];
  seriesIndex: number;
  dataPointIndex: number;
  w: any;
}
const EmployeesFinancialSummaryWidget = ({
  data,
  error,
  errorMessage,
  isLoading,
}: IServiceResponse<IEmployeesFinancialReports>) => {
  const { t } = useTranslation();

  const chartLabels = [
    t('financialReports.employees.pendingVisits'),
    t('visit.table.completedVisits'),
  ];
  const chartToolTipFormatter = ({
    series,
    seriesIndex,
    dataPointIndex,
    w,
  }: IChartToolTipFormatter) => {
    const amount = seriesIndex == 0 ? data?.totalPendingVisitsCost : data?.totalDoneVisitsCost;

    return (
      `<div class="tooltip_box">` +
      `<span >` +
      `${chartLabels[seriesIndex]}` +
      '</span>' +
      '<hr/>' +
      `<span >` +
      `${t('financialReports.employees.totalCount')} = ${series[seriesIndex]}` +
      '</span>' +
      '<div/>' +
      `<span >` +
      `${t('financialReports.employees.totalAmount')} = ${amount} ${t(
        'global.currencyUnits.nis'
      )}` +
      '</span>' +
      '</div>'
    );
  };

  if (isLoading) {
    return <PiChartWidgetSkeleton />;
  }
  if (error || !data) {
    return <ErrorMessage error={errorMessage} />;
  }
  return (
    <PiChart
      title={t('global.dashboard.visitsFinancialSummary')}
      colors={theme.palette.dashboardCharts}
      labels={chartLabels}
      chartData={[data.totalPendingVisits || 0, data.totalDoneVisits || 0]}
      chartToolTipFormatter={chartToolTipFormatter}
    />
  );
};

export default EmployeesFinancialSummaryWidget;
