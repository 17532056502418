import numeral from 'numeral';

export const getChartPercentage = (num1?: number, num2?: number) => {
  if (num1 && num2) {
    const percentage = (num1 / num2) * 100;
    return Math.round(percentage);
  }
  return 0;
};

export function fNumber(number: number) {
  return numeral(number).format();
}
