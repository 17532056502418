/* eslint-disable new-cap */
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useParams, useOutletContext } from 'react-router-dom';
import * as Yup from 'yup';
import { reportTypes } from '../../../common/Constants';
import ErrorMessage from '../../../components/ErrorMessage';
import { HMMRepeatedValues } from '../../../common/reportsFieldsValues/hmm/HMMRepeatedValues';
import HMMRepeatedFields from './fields/HMMRepeatedFields';
import ReportsForm from '../../../components/Form/ReportsForm';

const HMMRepeated = () => {
  const { t } = useTranslation();
  const { visitId } = useParams();
  const data: any = useOutletContext();

  const initialValues =
    data && data.name
      ? data
      : {
          name: reportTypes.HMM_REPEATED,
          visit: visitId,
          data: data ? data : HMMRepeatedValues,
        };
  const ReportSchema = Yup.object({
    name: Yup.string().required(t('clinic.form.required')),
    visit: Yup.string().required(t('clinic.form.required')),
    data: Yup.object().shape({
      treatment: Yup.string().required(t('clinic.form.required')),
      status: Yup.string().required(t('clinic.form.required')),
    }),
  });

  if (data?.name && data.name != reportTypes.HMM_REPEATED) {
    return <ErrorMessage error={t('global.errors.somethingWrong')} />;
  }

  const htmlId = 'HMMRepeated';
  return (
    <ReportsForm
      initialValues={initialValues}
      visitId={visitId || ''}
      validationSchema={ReportSchema}
      name={data?.name}
      resetForm={true}
      resetFormValues={{ ...initialValues, data: HMMRepeatedValues }}
    >
      <HMMRepeatedFields id={htmlId} />
    </ReportsForm>
  );
};

export default HMMRepeated;
