/* eslint-disable new-cap */
import { Container } from '@mui/material';
import React, { useCallback, useMemo, useState } from 'react';
import Page from '../../components/Page';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import useService from '../../hooks/HookService';
import PatientService from '../../services/PatientService';
import { IClinic, IPatient, IPatientProgram } from '../../interfaces/Models';
import ErrorMessage from '../../components/ErrorMessage';
import PatientInfo from './PatientInfo';
import ActiveProgramView from './ActiveProgramView';
import Tabs from '../../components/Tabs';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import EventNoteIcon from '@mui/icons-material/EventNote';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import PatientPrograms from './PatientPrograms';
import DateRangeIcon from '@mui/icons-material/DateRange';
import HeaderBreadcrumbs from '../../components/HeaderBreadcrumbs';
import EditIcon from '@mui/icons-material/Edit';
import AddIcon from '@mui/icons-material/Add';
import { AbilitySubjects } from '../../casl/ability';
import { useAbility } from '@casl/react';
import { AbilityContext } from '../../casl/can';
import OverlaySpinner from '../../components/OverlaySpinner';
import PatientActiveProgramActions from './PatientActiveProgramActions';
import PatientProgramService from '../../services/PatientProgramService';
import { CLINIC_LIST, TABS_NAMES } from '../../common/Constants';
import AttachmentsTable from '../../components/AttachmentsTable';
import MedicationIcon from '@mui/icons-material/Medication';
import PatientPrescriptionsTable from '../../sections/patient/PatientPrescriptionsTable';

const PatientProfile: React.FC = () => {
  const { t } = useTranslation();
  const { patientId } = useParams();
  const ability = useAbility(AbilityContext);
  const [isLoadingActiveProgramReport, setIsLoadingActiveProgramReport] = useState<boolean>(false);
  const addProgramAbility = ability.can('create', AbilitySubjects.ADMIN_ABILITY);
  const editPatientAbility = ability.can('update', AbilitySubjects.ADMIN_ABILITY);
  const [shouldTableUpdate, setShouldTableUpdate] = useState<number>(0);
  const [programData, setProgramData] = useState<IPatientProgram>();

  const handlePageUpdate = () => {
    setShouldTableUpdate((prev) => prev + 1);
  };

  const { isLoading, data, error, errorMessage } = useService<IPatient>({
    service: PatientService.getOne(patientId || ''),
    deps: [],
  });

  const handleUpdateProgramData = (value: IPatientProgram) => setProgramData(value);

  const patientActiveProgram = useService<IPatientProgram>({
    service: PatientProgramService.getActiveProgram(patientId || ''),
    deps: [shouldTableUpdate],
    onLoadingData: handleUpdateProgramData,
  });

  const loadingHandler = useCallback(
    (value: boolean) => {
      setIsLoadingActiveProgramReport(value);
    },
    [isLoadingActiveProgramReport]
  );

  const isLeumitPatient = useMemo(() => {
    const patientClinic =
      !!data && data.clinic && typeof data.clinic === 'object'
        ? (data.clinic as IClinic)
        : undefined;

    return patientClinic?.name.trim() === CLINIC_LIST.LEUMIT;
  }, [data]);

  if (isLoading) {
    return <OverlaySpinner />;
  }
  if (error || !data) {
    return <ErrorMessage error={errorMessage} />;
  }

  return (
    <Page title={t('patient.mainView.profile')}>
      <Container>
        <HeaderBreadcrumbs
          heading={t('patient.info.patients')}
          links={[
            { name: t('patient.info.patients'), href: `/app/patient` },
            { name: `${data.personalDetails.lastName} ${data.personalDetails.firstName}` },
          ]}
          actions={[
            {
              name: t('patient.info.newProgram'),
              icon: <AddIcon />,
              type: 'Link',
              href: `/app/patient/program/new/${data._id}`,
              isVisible: addProgramAbility,
            },
            {
              name: t('patient.info.edit'),
              href: `/app/patient/edit/${data._id}`,
              icon: <EditIcon />,
              color: 'secondary',
              type: 'Link',
              isVisible: editPatientAbility,
            },
          ]}
        />
        <PatientInfo patient={data} />
        <Tabs
          tabsName={TABS_NAMES.PATIENT}
          tabs={[
            {
              label: t('patient.info.activeProgram'),
              component: (
                <ActiveProgramView
                  isLeumitPatient={isLeumitPatient}
                  activeProgram={programData}
                  handlePageReload={handlePageUpdate}
                  isLoading={patientActiveProgram.isLoading}
                  error={patientActiveProgram.error}
                  errorMessage={patientActiveProgram.errorMessage}
                  handleProgramDataUpdate={handleUpdateProgramData}
                  viewType="Table"
                />
              ),
              icon: <AccountBoxIcon />,
            },
            {
              label: t('patient.info.patientPrograms'),
              component: <PatientPrograms patientId={data._id || 'empty'} />,
              icon: <EventNoteIcon />,
            },
            {
              label: t('patient.info.activeProgramCalendar'),
              component: (
                <ActiveProgramView
                  activeProgram={programData}
                  handlePageReload={handlePageUpdate}
                  isLoading={patientActiveProgram.isLoading}
                  error={patientActiveProgram.error}
                  errorMessage={patientActiveProgram.errorMessage}
                  handleProgramDataUpdate={handleUpdateProgramData}
                  viewType="Calendar"
                />
              ),
              icon: <DateRangeIcon />,
            },
            {
              label: t('global.attachments.header'),
              component: <AttachmentsTable isLoading={isLoading} patient={data} />,
              icon: <AttachFileIcon />,
            },
            {
              label: t('prescriptions'),
              component: <PatientPrescriptionsTable isLoading={isLoading} patient={data} />,
              icon: <MedicationIcon />,
            },
          ]}
          actions={
            <PatientActiveProgramActions
              loadingHandler={loadingHandler}
              activeProgram={patientActiveProgram.data}
              isLoading={patientActiveProgram.isLoading}
              error={patientActiveProgram.error}
              errorMessage={patientActiveProgram.errorMessage}
            />
          }
        />
        {isLoadingActiveProgramReport && <OverlaySpinner />}
      </Container>
    </Page>
  );
};

export default PatientProfile;
