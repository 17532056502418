/* eslint-disable new-cap */
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams, useOutletContext } from 'react-router-dom';
import * as Yup from 'yup';
import ErrorMessage from '../../../components/ErrorMessage';
import ReportsForm from '../../../components/Form/ReportsForm';
import { useRecoilValue } from 'recoil';
import { visitAtom } from '../../../atoms/visitAtom';
import GenericField from '../sharedFields/GenericField';

const PhoneReport = () => {
  const { t } = useTranslation();
  const { visitId } = useParams();
  const currentVisit = useRecoilValue(visitAtom);
  const data: any = useOutletContext();

  const initialValues = useMemo(() => {
    if (data && data.name) {
      return data;
    }
    return {
      name: currentVisit?.visitType,
      visit: visitId,
      data: data ? data : { title: '', body: '' },
    };
  }, [data, currentVisit]);

  const ReportSchema = Yup.object({
    name: Yup.string().required(t('clinic.form.required')),
    visit: Yup.string().required(t('clinic.form.required')),
    data: Yup.object()
      .shape({
        title: Yup.string().required(t('clinic.form.required')),
        body: Yup.string().required(t('clinic.form.required')),
      })
      .required(t('clinic.form.required')),
  });

  const HtmlId = currentVisit?.visitType || 'PhoneReport';

  if (data?.name && data.name !== currentVisit?.visitType) {
    return <ErrorMessage error={t('global.errors.somethingWrong')} />;
  }
  return (
    <ReportsForm
      initialValues={initialValues}
      visitId={visitId || ''}
      validationSchema={ReportSchema}
      name={data?.name}
      resetForm={false}
    >
      <GenericField
        id={`${HtmlId}_fields`}
        fields={[
          {
            name: 'data.title',
            label: 'title',
            type: 'text',
          },
          {
            name: 'data.body',
            label: 'body',
            type: 'textareaWithEditor',
          },
        ]}
      />
    </ReportsForm>
  );
};

export default PhoneReport;
