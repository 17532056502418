import React from 'react';
import ErrorMessage from '../../components/ErrorMessage';
import OverlaySpinner from '../../components/OverlaySpinner';
import useService from '../../hooks/HookService';
import ReportService from '../../services/ReportService';
import { IReport } from '../../interfaces/Models';

interface IAddReportLayout {
  Outlet: React.FC<any>;
  visitId?: string;
  isDraft?: boolean;
}
const AddReportLayout = ({ Outlet, visitId, isDraft }: IAddReportLayout) => {
  const {
    isLoading,
    data: reportData,
    error,
    errorMessage,
  } = useService<IReport | null>({
    service: isDraft
      ? ReportService.getReportByVisitId(visitId || 'empty')
      : ReportService.getRepeatedReportData(visitId || 'empty'),
    deps: [],
  });

  if (isLoading) {
    return <OverlaySpinner />;
  }
  if (error) {
    return <ErrorMessage error={errorMessage} />;
  }
  return <Outlet context={reportData} />;
};

export default AddReportLayout;
