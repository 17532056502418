/* eslint-disable new-cap */
import React from 'react';
import { useTranslation } from 'react-i18next';
import { PATIENT_PROGRAM_STATUS } from '../../common/Constants';
import DialogActions from '../../components/DialogActions';
import FetcherService from '../../hooks/FetcherService';
import {
  IPatientProgram,
  IPatientProgramFlat,
  IProgramReleaseReport,
} from '../../interfaces/Models';
import PatientProgramService from '../../services/PatientProgramService';
import { handleTableAtomChange } from '../../utils/Helper';
import EditReleaseReportForm from './EditReleaseReportForm';
import ProgramHospitalDaysModal from './ProgramHospitalDaysModal';

interface IProgramsActions {
  record?: IPatientProgram | IPatientProgramFlat | IProgramReleaseReport;
  handelClosingModals: () => void;
  handleTableUpdate: () => void;
  openAddHospitalDaysModal: boolean;
  openCompleteProgramModal: boolean;
  editReleaseReport?: boolean;
  tableSessionId: string;
}
const ProgramsActions = ({
  record,
  handelClosingModals,
  handleTableUpdate,
  openAddHospitalDaysModal,
  openCompleteProgramModal,
  editReleaseReport = false,
  tableSessionId,
}: IProgramsActions) => {
  const { t } = useTranslation();

  const handleCompleteProgram = async () => {
    handleTableAtomChange(tableSessionId, record?._id);
    await FetcherService<any>({
      service: PatientProgramService.edit(record?._id || '', {
        status: PATIENT_PROGRAM_STATUS.DONE,
      }),
    });
    handelClosingModals();
    handleTableUpdate();
  };

  return (
    <>
      <ProgramHospitalDaysModal
        program={record}
        handleCloseModal={handelClosingModals}
        openAddHospitalDaysModal={openAddHospitalDaysModal}
        handleTableUpdate={handleTableUpdate}
      />
      <DialogActions
        onClose={handelClosingModals}
        open={openCompleteProgramModal}
        dialog={t('global.actionsConfirmation.completeProgram')}
        action={handleCompleteProgram}
      />
      <EditReleaseReportForm
        openModal={editReleaseReport}
        handleModalClose={handelClosingModals}
        handlePageUpdate={handleTableUpdate}
        record={record}
      />
    </>
  );
};

export default ProgramsActions;
