import { useTranslation } from 'react-i18next';
import { Grid, Container, Box, Typography } from '@mui/material';
import Page from '../../components/Page';
import { useRecoilValue } from 'recoil';
import { userAtom } from '../../atoms/userAtom';
import EmployeeDashboard from './EmployeeDashboard';
import AdminDashboard from './AdminDashboard';
import { USER_ROLES } from '../../common/Constants';
import DateRangeFilter from '../../components/DateRangeFilter';
import { FilterContainer } from '../financialReports/EmployeesFinancialReportsHeader';

const Dashboard: React.FC = () => {
  const { t } = useTranslation();
  const user = useRecoilValue(userAtom);

  const isUserEmployee = user?.role == USER_ROLES.EMPLOYEE;
  return (
    <Page title={t('global.profile.dashboard')}>
      <Container maxWidth="xl">
        <Box sx={{ pb: 5, display: 'flex', justifyContent: 'space-between' }}>
          <Typography variant="h4">
            <span>{t('global.dashboard.welcome')}</span>
            <span> {user?.personalDetails.firstName}</span>
          </Typography>
          <FilterContainer sx={{ minWidth: '10%' }}>
            <DateRangeFilter saveOptionInLocalHost={true} resetPageAfterFilter={false} />
          </FilterContainer>
        </Box>
        <Grid container spacing={3}>
          {isUserEmployee ? <EmployeeDashboard /> : <AdminDashboard />}
        </Grid>
      </Container>
    </Page>
  );
};
export default Dashboard;
