import { Typography } from '@mui/material';
import { t } from 'i18next';
import React, { useEffect, useState } from 'react';
import { StyledGrid } from '../../../../styles/FormFieldsStyles';
import {
  assessmentFieldsSection1,
  assessmentFieldsSection2,
  assessmentFieldsSection3,
  getAssessmentSectionsTotal,
  getAssessmentTotalText,
} from '../../../../common/assessment';
import SectionDivider from '../../../../components/SectionDivider';
import SummaryMessage from '../../../../components/SummaryMessage';
import AssessmentSection from './AssessmentSection';
import { getIn, useFormikContext } from 'formik';

interface IAssessmentFields {
  id: string;
}
const AssessmentFields = ({ id }: IAssessmentFields) => {
  const { values: formValues, setFieldValue }: any = useFormikContext();
  const values = getIn(formValues, 'data.assessment');

  const [total, setTotal] = useState<number>(0);
  const [summary, setSummary] = useState<string>('');
  const [sectionTotal1, setSectionTotal1] = useState<number>(0);
  const [sectionTotal2, setSectionTotal2] = useState<number>(0);
  const [sectionTotal3, setSectionTotal3] = useState<number>(0);

  const getAssessmentSummary = (sum: number) => {
    let summary = '';
    if (sum <= 128 && sum >= 109) {
      summary = 'assessmentFullIndependence';
    } else if (sum < 109 && sum >= 91) {
      summary = 'assessmentIndependenceAccessory';
    } else if (sum < 91 && sum >= 73) {
      summary = 'assessmentSupervision';
    } else if (sum < 73 && sum >= 37) {
      summary = 'assessmentNeedHelp';
    } else {
      summary = 'assessmentFullDependence';
    }
    return summary;
  };

  useEffect(() => {
    const sectionsTotal = getAssessmentSectionsTotal(values);

    setTotal(sectionsTotal.allSectionsTotal);
    setSectionTotal1(sectionsTotal.total1);
    setSectionTotal2(sectionsTotal.total2);
    setSectionTotal3(sectionsTotal.total3);
    setFieldValue('data.total', sectionsTotal.allSectionsTotal);
    setFieldValue('data.sectionTotal1', sectionsTotal.total1);
    setFieldValue('data.sectionTotal2', sectionsTotal.total2);
    setFieldValue('data.sectionTotal3', sectionsTotal.total3);
    setFieldValue('data.summary', getAssessmentSummary(sectionsTotal.allSectionsTotal));
    setSummary(getAssessmentSummary(sectionsTotal.allSectionsTotal));
  }, [values]);

  return (
    <div id={id}>
      <Typography variant="subtitle1" gutterBottom align="left">
        {t('report.physiotherapyTable.header')}
      </Typography>
      <StyledGrid container justifyContent="left" id={`${id}_Container`}>
        <AssessmentSection
          sectionFields={assessmentFieldsSection1}
          sectionName={t('report.physiotherapyTable.subCategories.bedFunctionality')}
        />
        <SummaryMessage
          messages={[
            getAssessmentTotalText(
              t('report.physiotherapyTable.assessmentsTotal.total1'),
              sectionTotal1
            ),
          ]}
        />
        <AssessmentSection
          sectionFields={assessmentFieldsSection2}
          sectionName={t('report.physiotherapyTable.subCategories.UpperLimbsFunctionality')}
        />
        <SummaryMessage
          messages={[
            getAssessmentTotalText(
              t('report.physiotherapyTable.assessmentsTotal.total2'),
              sectionTotal2
            ),
          ]}
        />
        <AssessmentSection
          sectionFields={assessmentFieldsSection3}
          sectionName={t('report.physiotherapyTable.subCategories.walkingFunctionality')}
        />
        <SummaryMessage
          messages={[
            getAssessmentTotalText(
              t('report.physiotherapyTable.assessmentsTotal.total3'),
              sectionTotal3
            ),
          ]}
        />
      </StyledGrid>
      <SummaryMessage
        messages={[
          getAssessmentTotalText(
            t('report.physiotherapyTable.assessmentsTotal.globalTotal'),
            total
          ),
          `${t('report.HMAFIRST.summary')} : ${
            total > 0 ? t(`report.physiotherapyTable.summary.${summary}`) : ''
          }`,
        ]}
      />
      <SectionDivider />
    </div>
  );
};

export default AssessmentFields;
