import { Theme } from '@mui/material/styles';

export default function TablePagination(theme: Theme) {
  return {
    MuiTablePagination: {
      styleOverrides: {
        root: {
          direction: 'rtl',
        },
        actions: {
          transform: 'rotate(180deg)',
        },
      },
    },
  };
}
