export const HMBGeneralValues = {
  categories: [],
  chronicDiseases: {
    chronic: [],
    chronicMedications: [],
  },
  habits: {
    smoking: '',
    smokingBoxes: '',
    smokingYears: '',
    exercise: '',
    diet: '',
    dietNotes: '',
  },
  sensitivity: [],
  illnessHistory: '',
};
