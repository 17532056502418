import {
  ILoginCredentials,
  IRequestRestPassword,
  IResetEmployeePasswordCredentials,
  IResetPasswordCredentials,
  IUser,
  IUserFlat,
} from '../interfaces/Models';
import { IQuery, IService } from '../interfaces/index';
import { HTTP_METHODS } from '../common/APIHelper';

const getAll = (query?: IQuery): IService => {
  return { route: '/api/user', method: HTTP_METHODS.GET, query };
};

const getOne = (id: string): IService => {
  return { route: `/api/user/${id}`, method: HTTP_METHODS.GET };
};

const add = (payload: IUser): IService => {
  return { route: '/api/user', method: HTTP_METHODS.POST, payload };
};

const edit = (id: string, payload: IUserFlat | IUser): IService => {
  return { route: `/api/user/${id}`, method: HTTP_METHODS.PUT, payload };
};

const login = (payload: ILoginCredentials): IService => {
  return { route: '/api/user/login', method: HTTP_METHODS.POST, payload };
};

const resetPassword = (payload: IResetPasswordCredentials): IService => {
  return { route: '/api/user/my-profile/reset-password', method: HTTP_METHODS.POST, payload };
};

const requestRestPassword = (payload: IRequestRestPassword): IService => {
  return { route: '/api/user/forgot-password', method: HTTP_METHODS.POST, payload };
};

const restPasswordByToken = (token: string): IService => {
  return { route: `/api/user/reset-password/${token}`, method: HTTP_METHODS.GET };
};

const resetEmployeePassword = (
  id: string,
  payload: IResetEmployeePasswordCredentials
): IService => {
  return { route: `/api/user/reset-password/employee/${id}`, method: HTTP_METHODS.POST, payload };
};

const activateOneById = (id: string): IService => {
  return { route: `/api/user/activate/${id}`, method: HTTP_METHODS.PUT };
};

const UserService = {
  add,
  getAll,
  getOne,
  edit,
  login,
  resetPassword,
  requestRestPassword,
  restPasswordByToken,
  resetEmployeePassword,
  activateOneById,
};

export default UserService;
