import { useEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';
import jwt from 'jsonwebtoken';
import axios from 'axios';
import { logOut } from '../../common/APIHelper';
import { useIdleTimer } from 'react-idle-timer';
// material
import { styled } from '@mui/material/styles';
//
import DashboardNavbar from './DashboardNavbar';
import DashboardSidebar from './DashboardSidebar';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { userAtom } from '../../atoms/userAtom';
import AutoLogoutModal from '../../components/AutoLogoutModal';

// ----------------------------------------------------------------------

const APP_BAR_MOBILE = 64;
const APP_BAR_DESKTOP = 92;

const RootStyle = styled('div')({
  display: 'flex',
  minHeight: '100%',
  overflow: 'hidden',
});

const MainStyle = styled('div')(({ theme }) => ({
  flexGrow: 1,
  overflow: 'auto',
  minHeight: '100%',
  paddingTop: APP_BAR_MOBILE + 24,
  paddingBottom: theme.spacing(10),
  [theme.breakpoints.up('lg')]: {
    paddingTop: APP_BAR_DESKTOP + 24,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
}));

// ----------------------------------------------------------------------

const DashboardLayout = () => {
  const [open, setOpen] = useState<boolean>(false);
  const [hasLoaded, setHasLoaded] = useState<boolean>(false);
  const user = useRecoilValue(userAtom);
  const setUser = useSetRecoilState(userAtom);
  const [openIdleModal, setOpenIdleModal] = useState<boolean>(false);

  const MINUTE = 1000 * 60;
  const timeout = 15 * MINUTE;

  const handleOnIdle = () => {
    localStorage.removeItem('authToken');
    axios.defaults.headers.common['Authorization'] = '';
    setOpenIdleModal(true);
  };

  // eslint-disable-next-line no-unused-vars
  const idleTimer = useIdleTimer({
    timeout: timeout,
    onIdle: handleOnIdle,
    debounce: 500,
    crossTab: true,
    startOnMount: true,
    syncTimers: 2000,
  });

  useEffect(() => {
    const token = localStorage.getItem('authToken')?.split(' ')[1];

    if (token) {
      const decodedToken: any = jwt.decode(token);
      const currentDate = new Date();

      if (!user) {
        setUser(decodedToken.user);
      }

      if (currentDate.getTime() > decodedToken.exp * 1000) {
        logOut();
      }
      axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    } else {
      logOut();
    }
    setHasLoaded(true);
  }, []);

  const onClose = () => setOpen(false);

  return (
    <RootStyle>
      {hasLoaded && (
        <>
          <DashboardNavbar onOpenSidebar={() => setOpen(true)} />
          <DashboardSidebar isOpenSidebar={open} onCloseSidebar={onClose} />
          <MainStyle>
            <AutoLogoutModal open={openIdleModal} />
            <Outlet />
          </MainStyle>
        </>
      )}
    </RootStyle>
  );
};
export default DashboardLayout;
