import { getIn, useFormikContext } from 'formik';
import React from 'react';
import { physicalExaminationFields } from '../../../../common/HMBFirst';
import SectionDivider from '../../../../components/SectionDivider';
import EssentialSignsFields from '../../sharedFields/EssentialSignsFields';
import GenericField from '../../sharedFields/GenericField';
import PainAssessment from '../../sharedFields/PainAssessment';
import CognitiveExamFields from './CognitiveExamFields';
import ExaminationFields from './ExaminationFields';
import { useTranslation } from 'react-i18next';

interface HMBFirstFieldsProps {
  id: string;
}
const HMBFirstFields = ({ id }: HMBFirstFieldsProps) => {
  const { t } = useTranslation();
  const { values, setFieldValue }: any = useFormikContext();
  const bloodPressureValue = getIn(values, 'data.essentialSigns.bloodPressure');
  const pressureStandingValue = getIn(values, 'data.essentialSigns.bloodPressureStanding');
  const neurologicalExamination = [
    'consciousnessExam',
    'OrientationExam',
    'forceExam',
    'feelExam',
    'CranialExam',
    'CerebralExam',
    'PyramidalExam',
  ];
  return (
    <>
      <EssentialSignsFields
        id={`${id}_EssentialSigns`}
        onChangeFormikValue={setFieldValue}
        bloodPressureValue={bloodPressureValue}
        pressureStandingValue={pressureStandingValue}
      />
      <SectionDivider />
      <PainAssessment id={`${id}_PainAssessment`} />
      <SectionDivider />
      <CognitiveExamFields />
      <SectionDivider />
      <GenericField
        id={`${id}_PhysicalExamination`}
        fields={physicalExaminationFields}
        subHeader={t(`report.firstVisitReport.physicalExamination.header`)}
      />
      <SectionDivider />
      <ExaminationFields
        typeOfExamination="neurologicalExamination"
        Examinations={neurologicalExamination}
        id={`${id}_NeurologicalExamination`}
      />
      <SectionDivider />
      <GenericField
        id={`${id}_Summary`}
        fields={[
          {
            name: 'data.summary',
            label: 'report.firstVisitReport.summary',
            type: 'textareaWithEditor',
          },
          {
            name: 'data.releasePlan',
            label: 'report.firstVisitReport.releasePlan',
            type: 'textareaWithEditor',
          },
        ]}
      />
      <SectionDivider />
    </>
  );
};

export default HMBFirstFields;
