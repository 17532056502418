import React from 'react';
import { useTranslation } from 'react-i18next';
import Page from '../../../components/Page';
import { Card, Container } from '@mui/material';
import PageHeader from '../../../components/PageHeader';
import EmployeeVisitsDelayCalculationReportTable from './EmployeeVisitsDelayCalculationReportTable';
import EmployeeVisitsDelayCalculationReportTableHeader from './EmployeeVisitsDelayCalculationReportTableHeader';
import { AbilitySubjects } from '../../../casl/ability';
import useAccess from '../../../hooks/useAccess';

export default function EmployeeVisitsDelayCalculationReportPage() {
  const { t } = useTranslation();
  useAccess('read', AbilitySubjects.VIEW_FINANCIAL_REPORTS);

  return (
    <Page title={t('financialReports.visitsDelayCalculation.reportName')}>
      <Container>
        <PageHeader
          pageTitle={t('financialReports.visitsDelayCalculation.reportName')}
          pageFilter={<EmployeeVisitsDelayCalculationReportTableHeader />}
          smallScreenDirection="row"
        />
        <Card>
          <EmployeeVisitsDelayCalculationReportTable />
        </Card>
      </Container>
    </Page>
  );
}
