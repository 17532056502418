/* eslint-disable new-cap */
import React, { useState } from 'react';
import { useRecoilValue } from 'recoil';
import ErrorMessage from '../../components/ErrorMessage';
import useService from '../../hooks/HookService';
import PatientService from '../../services/PatientService';
import { useNavigate, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { IPatientFlat } from '../../interfaces/Models';
import Table from '../../components/Table';
import { Row } from 'react-table';
import SearchFilter from '../../components/SearchFilter';
import DialogActions from '../../components/DialogActions';
import FetcherService from '../../hooks/FetcherService';
import { getPatientColumns } from '../../common/columns/Patients';
import { FILTERS } from '../../common/Constants';
import { AbilitySubjects } from '../../casl/ability';
import OverlaySpinner from '../../components/OverlaySpinner';
import { getPageNumberFromUrlParam, handleTableAtomChange } from '../../utils/Helper';
import useSearchFilter from '../../hooks/useSearchFilter';
import { userAtom } from '../../atoms/userAtom';
import { getClinicFilter } from '../program/ProgramsTable';

const PatientTable: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation();
  const { pageInfo } = useSearchFilter();
  const user = useRecoilValue(userAtom);
  const [record, setRecord] = useState<IPatientFlat | null>(null);
  const [openDeletePatientModal, setOpenDeletePatientModal] = useState<boolean>(false);
  const [shouldTableUpdate, setShouldTableUpdate] = useState<number>(0);
  const { page, limit = 20, sort = '-createdAt', search, clinic } = pageInfo;

  const queryString = location.search;
  const urlParams = new URLSearchParams(queryString);
  const active = urlParams.get('active');

  const serviceOptions = {
    page: getPageNumberFromUrlParam(page),
    limit: limit,
    search: search,
    sort: sort,
    employee: user?._id,
    position: user?.position,
    clinicName: clinic,
  };
  const service = active
    ? PatientService.getAllByEmployee(serviceOptions)
    : PatientService.getAll(serviceOptions);
  const { isLoading, data, additionalData, loadingNumber, error, errorMessage } = useService<
    IPatientFlat[]
  >({
    service,
    deps: [page, limit, search, clinic, shouldTableUpdate, sort, active],
  });

  const onOpenActions = (value: IPatientFlat) => {
    handleTableAtomChange('patientId', value._id);
    navigate(`/app/patient/edit/${value._id}`);
  };

  const onOpenDeletePatientModal = (value: IPatientFlat) => {
    setRecord(value);
    setOpenDeletePatientModal(true);
  };

  const columns = getPatientColumns(sort, onOpenActions, onOpenDeletePatientModal);

  const handleModalClose = () => {
    setOpenDeletePatientModal(false);
    setRecord(null);
  };

  const handleDeletePatient = async () => {
    if (record?._id) {
      await FetcherService<IPatientFlat>({
        service: PatientService.edit(record?._id as string, {
          ...record,
          active: false,
        }),
        errorMessage: `${t('global.errors.deletePatient')} : ${t(
          'global.errors.deletePatientDesc'
        )}`,
      });
      setOpenDeletePatientModal(false);
      setShouldTableUpdate((prev) => prev + 1);
    }
  };

  const onRowClick = (row: Row<IPatientFlat>) => {
    handleTableAtomChange('patientId', row.original._id);
    navigate(`/app/patient/${row.original._id}`);
  };

  if (error) {
    return <ErrorMessage error={errorMessage} />;
  }
  if (isLoading && loadingNumber == 1) {
    return <OverlaySpinner />;
  }

  const tableFilters = [
    {
      filterName: FILTERS.SEARCH,
      component: <SearchFilter />,
    },
    getClinicFilter(),
  ];

  return (
    <>
      <DialogActions
        onClose={handleModalClose}
        open={openDeletePatientModal}
        dialog={`${t('patient.info.deletePatient')} ${record?.fullName} ?`}
        action={handleDeletePatient}
      />
      <Table
        buttonText={t('patient.mainView.add')}
        columns={columns}
        data={data || []}
        count={additionalData?.total || 0}
        pageInfo={{
          page: getPageNumberFromUrlParam(page),
          pageSize: Number(limit),
        }}
        url="/app/patient/new"
        onRowClick={onRowClick}
        isLoading={isLoading}
        filters={tableFilters}
        actionAccess={AbilitySubjects.ADMIN_ABILITY}
      />
    </>
  );
};

export default PatientTable;
