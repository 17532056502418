import React from 'react';
import { styled } from '@mui/material/styles';
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import { Column, useTable } from 'react-table';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#60c9be',
    color: theme.palette.common.black,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

interface ICustomizedTable {
  data: Array<object>;
  columns: Array<Column>;
}
const CustomizedTable = ({ data, columns }: ICustomizedTable) => {
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable({
    columns,
    data,
  });
  return (
    <TableContainer sx={{ minWidth: 200 }} component={Paper}>
      <Table {...getTableProps()} aria-label="customized table">
        <TableHead>
          {headerGroups.map((headerGroup) => (
            <TableRow {...headerGroup.getHeaderGroupProps()}>
              <StyledTableCell>#</StyledTableCell>
              {headerGroup.headers.map((column) => (
                <StyledTableCell {...column.getHeaderProps()}>
                  {column.render('Header')}
                </StyledTableCell>
              ))}
            </TableRow>
          ))}
        </TableHead>
        <TableBody {...getTableBodyProps()}>
          {rows.map((row, i) => {
            prepareRow(row);
            return (
              <StyledTableRow {...row.getRowProps()}>
                <StyledTableCell key={`Cell-index-${i + 1}`} sx={{ fontWeight: 'bold' }}>
                  {i + 1}
                </StyledTableCell>
                {row.cells.map((cell, cellIndex: number) => {
                  return (
                    <StyledTableCell {...cell.getCellProps()}>
                      {cell.render('Cell')}
                    </StyledTableCell>
                  );
                })}
              </StyledTableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default CustomizedTable;
