import { TFunction } from 'react-i18next';
import { getProgramStartEndColumns, getProgramTitleColumn } from './columns/Programs';
import {
  getVisitDueDateColumn,
  getVisitPositionColumn,
  getVisitTypeColumn,
} from './columns/ProgramVisits';
import { getVisitStatusColumn } from './columns/AllVisitsTableColumn';
import { VISIT_STATUS_TO_HEBREW } from './Constants';

const getIdColumn = (t: TFunction<'translation', undefined>) => {
  return {
    Header: t('patient.form.passportIdNumber'),
    accessor: (val: any) => val.patient.idNumber,
  };
};

const getPatientNameColumn = (t: TFunction<'translation', undefined>) => {
  return {
    id: 'patientName',
    Header: `${t('patient.info.patientName')}`,
    accessor: (val: any) =>
      typeof val.patient !== 'string'
        ? `${val.patient.personalDetails.firstName} ${val.patient.personalDetails.lastName}`
        : '',
  };
};
export const getLeumitMissingProgramsColumns = (t: TFunction<'translation', undefined>) => {
  const idColumn = getIdColumn(t);
  const patientNameColumn = getPatientNameColumn(t);
  return [
    { Header: `${t('patient.form.title')}`, accessor: (val: any) => val.title },
    patientNameColumn,
    idColumn,
    ...getProgramStartEndColumns(),
  ];
};

export const getLeumitMissingVisitsColumns = (t: TFunction<'translation', undefined>) => {
  const idColumn = getIdColumn(t);
  const patientNameColumn = getPatientNameColumn(t);
  return [
    patientNameColumn,
    idColumn,
    getVisitPositionColumn(),
    getVisitTypeColumn(),
    {
      ...getVisitDueDateColumn(),
    },
    {
      Header: `${t('visit.table.visitStatus')}`,
      accessor: (visit: any) => {
        const statusText = VISIT_STATUS_TO_HEBREW[visit.status.toString().toUpperCase()];
        return statusText;
      },
    },
    {
      Header: `${t('patient.form.title')}`,
      accessor: 'programTitle',
    },
  ];
};
