import { Box, Card, CardHeader, Divider, Skeleton } from '@mui/material';
import React from 'react';

interface ITableSkeleton {
  filtersLength?: number;
  topPagination?: boolean;
  bottomPagination?: boolean;
  sx?: object;
}
const TableSkeleton = ({
  filtersLength,
  topPagination,
  bottomPagination = true,
  sx,
}: ITableSkeleton) => {
  return (
    <Card sx={{ p: 0.5, ...sx }}>
      {topPagination && (
        <Box sx={{ flexGrow: 1, display: 'flex', justifyContent: 'left' }}>
          <Box sx={{ width: '50%' }}>
            <Skeleton width="100%" sx={{ mt: 2, mb: 2 }}>
              <CardHeader />
            </Skeleton>
          </Box>
        </Box>
      )}
      <Skeleton variant="rectangular" width="100%" height={320}>
        <div style={{ paddingTop: '90%' }} />
      </Skeleton>
      <Divider sx={{ width: '90%', margin: 1 }} />
      {bottomPagination && (
        <Box sx={{ flexGrow: 1, display: 'flex', justifyContent: 'left' }}>
          <Box sx={{ width: '50%' }}>
            <Skeleton width="100%" sx={{ mt: 2, mb: 2 }}>
              <CardHeader />
            </Skeleton>
          </Box>
        </Box>
      )}
    </Card>
  );
};

export default TableSkeleton;
