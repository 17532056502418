import { Box, BoxProps, Typography } from '@mui/material';
import CKeditor from '../editor';
import { styled } from '@mui/material/styles';
import { FastField, useFormikContext, getIn } from 'formik';
import isReportMode from '../../hooks/isReportMode';
import { encodeToBase64WithTextDecoder } from '../../utils/decode';
import { useCallback } from 'react';

export const LabelStyle = styled(Typography)(({ theme }) => ({
  ...theme.typography.subtitle2,
  color: theme.palette.text.secondary,
  marginBottom: theme.spacing(1),
}));

export const HelperTextStyle = styled(Typography)(({ theme }) => ({
  ...theme.typography.caption,
  color: theme.palette.text.disabled,
  marginLeft: theme.spacing(2),
  marginTop: theme.spacing(0.5),
  marginBottom: theme.spacing(1),
}));

interface Props {
  name: string;
  label: string;
  onChange?: (data: string) => void;
  value?: string;
  sx?: BoxProps;
  disabled?: boolean;
  helperText?: string;
}

const EditorField = ({
  name,
  onChange,
  value,
  label,
  disabled,
  helperText,
  sx,
  ...other
}: Props) => {
  const { setFieldValue, values }: any = useFormikContext();
  const fieldValue = getIn(values, name);

  const onChangeValue = useCallback((data: string) => {
    setFieldValue(name, encodeToBase64WithTextDecoder(data || ''));
  }, []);

  const isViewMode = isReportMode('view');

  const isFieldDisabled = isViewMode || disabled;

  return (
    <Box sx={sx}>
      <LabelStyle>{label}</LabelStyle>
      <FastField name={name}>
        {({ field, form, meta }: any) => (
          <CKeditor
            value={fieldValue || value || ''}
            viewMode={isFieldDisabled}
            {...field}
            onChange={onChangeValue}
            {...other}
          />
        )}
      </FastField>
      {helperText && <HelperTextStyle>{helperText}</HelperTextStyle>}
    </Box>
  );
};

export default EditorField;
