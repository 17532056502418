import React from 'react';
import { DatePicker } from 'formik-mui-lab';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';

const RtlFormikDatePickerField = (props: any) => {
  return (
    <DatePicker
      {...props}
      components={{
        LeftArrowIcon: ChevronRightIcon,
        RightArrowIcon: NavigateBeforeIcon,
      }}
    />
  );
};

export default RtlFormikDatePickerField;
