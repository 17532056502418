import { Navigate, useRoutes } from 'react-router-dom';
// layouts
import DashboardLayout from './layouts/dashboard/DashboardLayout';
import LogoOnlyLayout from './layouts/LogoOnlyLayout';
//
import Login from './pages/Login';
import Dashboard from './pages/dashboard/Dashboard';
import NotFound from './pages/Page404';
import Clinic from './pages/clinic';
import User from './pages/user';
import UserForm from './pages/user/UserForm';
import Patient from './pages/patient';
import PatientForm from './pages/patient/PatientForm';
import PatientProfile from './pages/patient/PatientProfile';
import NewProgram from './pages/program/NewProgram';
import HMBFirst from './pages/reports/doctor/HMBFirst';
import HMBGeneral from './pages/reports/general/HMBGeneral';
import HMBRepeated from './pages/reports/doctor/HMBRepeated';
import ReportLayout from './layouts/reports/ReportLayout';
import HMKRepeated from './pages/reports/communicationClinician/HMKRepeated';
import HMSFirst from './pages/reports/socialWorker/HMSFirst';
import HMKFirst from './pages/reports/communicationClinician/HMKFirst';
import HMDRepeated from './pages/reports/dietitian/HMDRepeated';
// import Reports from './components/Reports';
// import ReleaseReports from './components/ReleaseReports';
import HMMRepeated from './pages/reports/occupationalTherapy/HMMRepeated';
import HMMFirst from './pages/reports/occupationalTherapy/HMMFirst';
import HMDFirst from './pages/reports/dietitian/HMDFirst';
import HMPRepeated from './pages/reports/physiotherapy/HMPRepeated';
import HMPFirst from './pages/reports/physiotherapy/HMPFirst';
import HMPGeneral from './pages/reports/physiotherapy/HMPGeneral';
import HMA from './pages/reports/nurse/HMA';
import { reportTypes } from './common/Constants';
import EmployeeProfile from './pages/user/EmployeeProfile';
import Page403 from './pages/Page403';
import ProgramPage from './pages/program/ProgramPage';
import EditProgram from './pages/program/EditProgram';
import AllVisits from './pages/visits/AllVisits';
import PlannedVisits from './pages/visits/PlannedVisits';
import HMBRelease from './pages/reports/doctor/HMBRelease';
import HMARelease from './pages/reports/nurse/HMARelease';
import HMDRelease from './pages/reports/dietitian/HMDRelease';
import HMSRelease from './pages/reports/socialWorker/HMSRelease';
import HMKRelease from './pages/reports/communicationClinician/HMKRelease';
import HMMRelease from './pages/reports/occupationalTherapy/HMMRelease';
import HMPRelease from './pages/reports/physiotherapy/HMPRelease';
import UserProfile from './pages/user/profile/UserProfile';
import MyVisits from './pages/visits/MyVisits';
import ForgotPassword from './pages/auth/ForgotPassword';
import ResetPassword from './pages/auth/ResetPassword';
import ProgramsPage from './pages/program/ProgramsPage';
import HMPTable from './pages/reports/physiotherapy/HMPTable';
import ProgramsReleaseReports from './pages/program/ProgramsReleaseReports';
import EmployeeAccountingPage from './pages/user/EmployeeAccountingPage';
import FinancialReports from './pages/financialReports/EmployeesFinancialReports';
import ProgramsReports from './pages/financialReports/ProgramsReports';
import HHDFirst from './pages/reports/occupationalTherapy/houseAdjustment/HHDFirst';
import HHDRepeated from './pages/reports/occupationalTherapy/houseAdjustment/HHDRepeated';
import SystemReports from './pages/systemReports';
import ProgramScreenshot from './pages/screenshot/program/ProgramScreenshot';
import ScreenshotViewLayout from './layouts/screenshot/ScreenshotViewLayout';
import EmployeeVisitsDelayCalculationReportPage from './pages/financialReports/delay-calculation/EmployeeVisitsDelayCalculationReportPage';
import LeumitReportsPage from './pages/leumitReports';
import FoldersListPage from './pages/folders/FoldersListPage';
import FolderPage from './pages/folders/FolderPage';
import PhoneReport from './pages/reports/phone/PhoneReport';
// ----------------------------------------------------------------------

export default function Router() {
  return useRoutes([
    { path: '/', element: <Navigate to="/login" replace /> },
    {
      path: '/screenshot',
      element: <ScreenshotViewLayout />,
      children: [{ path: 'program/:programId/:mode', element: <ProgramScreenshot /> }],
    },
    {
      path: '/app',
      element: <DashboardLayout />,
      children: [
        { path: 'dashboard', element: <Dashboard /> },
        { path: 'clinic', element: <Clinic /> },
        { path: 'profile', element: <UserProfile /> },
        { path: 'user', element: <User /> },
        { path: 'user/new', element: <UserForm /> },
        { path: 'user/edit/:userId', element: <UserForm /> },
        { path: 'user/:employeeId', element: <EmployeeProfile /> },
        { path: 'patient', element: <Patient /> },
        { path: 'patient/patients-by-employee', element: <Patient /> },
        { path: 'patient/new', element: <PatientForm /> },
        { path: 'patient/:patientId', element: <PatientProfile /> },
        { path: 'patient/edit/:patientId', element: <PatientForm /> },
        { path: 'patient/program/new/:patientId', element: <NewProgram /> },
        { path: 'programs', element: <ProgramsPage /> },
        { path: 'programs/programs-by-employee', element: <ProgramsPage /> },
        { path: 'patient-program/:programId', element: <ProgramPage /> },
        { path: 'patient-program/edit/:programId', element: <EditProgram /> },
        { path: 'visits/my', element: <MyVisits /> },
        { path: 'visits/all', element: <AllVisits /> },
        { path: 'visits/planned', element: <PlannedVisits /> },
        { path: 'accounting', element: <EmployeeAccountingPage /> },
        { path: 'release-reports', element: <ProgramsReleaseReports /> },
        { path: 'release-reports/hospis', element: <ProgramsReleaseReports /> },
        { path: 'financial-reports/employees', element: <FinancialReports /> },
        { path: 'financial-reports/programs', element: <ProgramsReports /> },
        {
          path: 'financial-reports/employee/visits/delay/calculation',
          element: <EmployeeVisitsDelayCalculationReportPage />,
        },
        { path: 'system-reports', element: <SystemReports /> },
        { path: 'leumit-reports', element: <LeumitReportsPage /> },
        { path: 'folder', element: <FoldersListPage /> },
        { path: 'folder/:folderId', element: <FolderPage /> },
        // {
        //   path: 'reports',
        //   element: <Reports />,
        // },
        // {
        //   path: 'release-reports',
        //   element: <ReleaseReports />,
        // },
        {
          path: 'report/visit/:visitId',
          element: <ReportLayout />,
          children: [
            { path: 'doctor/first', element: <HMBFirst /> },
            { path: 'doctor/first/edit', element: <HMBFirst /> },
            {
              path: 'doctor/first/view',
              element: <HMBFirst />,
            },
            { path: 'doctor/general', element: <HMBGeneral /> },
            { path: 'doctor/general/edit', element: <HMBGeneral /> },
            { path: 'doctor/general/view', element: <HMBGeneral /> },
            { path: 'doctor/repeated', element: <HMBRepeated /> },
            { path: 'doctor/repeated/edit', element: <HMBRepeated /> },
            {
              path: 'doctor/repeated/view',
              element: <HMBRepeated />,
            },
            { path: 'doctor/release', element: <HMBRelease /> },
            { path: 'doctor/release/edit', element: <HMBRelease /> },
            { path: 'doctor/release/view', element: <HMBRelease /> },
            { path: 'doctor/phone', element: <PhoneReport /> },
            { path: 'doctor/phone/edit', element: <PhoneReport /> },
            { path: 'doctor/phone/view', element: <PhoneReport /> },

            { path: 'clinician/repeated', element: <HMKRepeated /> },
            { path: 'clinician/repeated/edit', element: <HMKRepeated /> },
            {
              path: 'clinician/repeated/view',
              element: <HMKRepeated />,
            },
            { path: 'clinician/release', element: <HMKRelease /> },
            { path: 'clinician/release/edit', element: <HMKRelease /> },
            {
              path: 'clinician/release/view',
              element: <HMKRelease />,
            },
            { path: 'clinician/first', element: <HMKFirst /> },
            { path: 'clinician/first/edit', element: <HMKFirst /> },
            { path: 'clinician/first/view', element: <HMKFirst /> },
            { path: 'clinician/phone', element: <PhoneReport /> },
            { path: 'clinician/phone/edit', element: <PhoneReport /> },
            { path: 'clinician/phone/view', element: <PhoneReport /> },

            { path: 'dietitian/first', element: <HMDFirst /> },
            { path: 'dietitian/first/edit', element: <HMDFirst /> },
            { path: 'dietitian/first/view', element: <HMDFirst /> },
            { path: 'dietitian/repeated', element: <HMDRepeated /> },
            { path: 'dietitian/repeated/edit', element: <HMDRepeated /> },
            {
              path: 'dietitian/repeated/view',
              element: <HMDRepeated />,
            },
            { path: 'dietitian/release', element: <HMDRelease /> },
            { path: 'dietitian/release/edit', element: <HMDRelease /> },
            {
              path: 'dietitian/release/view',
              element: <HMDRelease />,
            },

            { path: 'dietitian/phone', element: <PhoneReport /> },
            { path: 'dietitian/phone/edit', element: <PhoneReport /> },
            { path: 'dietitian/phone/view', element: <PhoneReport /> },

            { path: 'occupational-therapy/first', element: <HMMFirst /> },
            { path: 'occupational-therapy/first/edit', element: <HMMFirst /> },
            {
              path: 'occupational-therapy/first/view',
              element: <HMMFirst />,
            },
            { path: 'occupational-therapy/first-house-adjustment', element: <HHDFirst /> },
            { path: 'occupational-therapy/first-house-adjustment/edit', element: <HHDFirst /> },
            { path: 'occupational-therapy/first-house-adjustment/view', element: <HHDFirst /> },

            { path: 'occupational-therapy/repeated-house-adjustment', element: <HHDRepeated /> },
            {
              path: 'occupational-therapy/repeated-house-adjustment/edit',
              element: <HHDRepeated />,
            },
            {
              path: 'occupational-therapy/repeated-house-adjustment/view',
              element: <HHDRepeated />,
            },

            { path: 'occupational-therapy/repeated', element: <HMMRepeated /> },
            { path: 'occupational-therapy/repeated/edit', element: <HMMRepeated /> },
            { path: 'occupational-therapy/repeated/view', element: <HMMRepeated /> },
            { path: 'occupational-therapy/release', element: <HMMRelease /> },
            { path: 'occupational-therapy/release/edit', element: <HMMRelease /> },
            { path: 'occupational-therapy/release/view', element: <HMMRelease /> },

            { path: 'occupational-therapy/phone', element: <PhoneReport /> },
            { path: 'occupational-therapy/phone/edit', element: <PhoneReport /> },
            { path: 'occupational-therapy/phone/view', element: <PhoneReport /> },

            { path: 'physiotherapy/general', element: <HMPGeneral /> },
            { path: 'physiotherapy/general/edit', element: <HMPGeneral /> },
            { path: 'physiotherapy/general/view', element: <HMPGeneral /> },
            { path: 'physiotherapy/first', element: <HMPFirst /> },
            { path: 'physiotherapy/first/edit', element: <HMPFirst /> },
            { path: 'physiotherapy/first/view', element: <HMPFirst /> },
            { path: 'physiotherapy/repeated', element: <HMPRepeated /> },
            { path: 'physiotherapy/repeated/edit', element: <HMPRepeated /> },
            { path: 'physiotherapy/repeated/view', element: <HMPRepeated /> },
            { path: 'physiotherapy/release', element: <HMPRelease /> },
            { path: 'physiotherapy/release/edit', element: <HMPRelease /> },
            { path: 'physiotherapy/release/view', element: <HMPRelease /> },
            {
              path: 'physiotherapy/assessment-form/first',
              element: <HMPTable reportName={reportTypes.HMP_TABLE_FIRST} />,
            },
            { path: 'physiotherapy/assessment-form/first/edit', element: <HMPTable /> },
            { path: 'physiotherapy/assessment-form/first/view', element: <HMPTable /> },
            {
              path: 'physiotherapy/assessment-form/release',
              element: <HMPTable reportName={reportTypes.HMP_TABLE_RELEASE} />,
            },
            { path: 'physiotherapy/assessment-form/release/edit', element: <HMPTable /> },
            { path: 'physiotherapy/assessment-form/release/view', element: <HMPTable /> },

            { path: 'physiotherapy/phone', element: <PhoneReport /> },
            { path: 'physiotherapy/phone/edit', element: <PhoneReport /> },
            { path: 'physiotherapy/phone/view', element: <PhoneReport /> },

            { path: 'nurse/first', element: <HMA reportName={reportTypes.HMA_FIRST} /> },
            { path: 'nurse/first/edit', element: <HMA /> },
            { path: 'nurse/first/view', element: <HMA /> },
            { path: 'nurse/repeated', element: <HMA reportName={reportTypes.HMA_REPEATED} /> },
            { path: 'nurse/repeated/edit', element: <HMA /> },
            { path: 'nurse/repeated/view', element: <HMA /> },
            { path: 'nurse/release', element: <HMARelease /> },
            { path: 'nurse/release/edit', element: <HMARelease /> },
            { path: 'nurse/release/view', element: <HMARelease /> },

            { path: 'nurse/phone', element: <PhoneReport /> },
            { path: 'nurse/phone/edit', element: <PhoneReport /> },
            { path: 'nurse/phone/view', element: <PhoneReport /> },

            {
              path: 'social-worker/first',
              element: <HMSFirst reportName={reportTypes.HMS_FIRST} />,
            },
            { path: 'social-worker/first/edit', element: <HMSFirst /> },
            { path: 'social-worker/first/view', element: <HMSFirst /> },
            {
              path: 'social-worker/repeated',
              element: <HMSFirst reportName={reportTypes.HMS_REPEATED} />,
            },
            { path: 'social-worker/repeated/edit', element: <HMSFirst /> },
            { path: 'social-worker/repeated/view', element: <HMSFirst /> },
            { path: 'social-worker/release', element: <HMSRelease /> },
            { path: 'social-worker/release/edit', element: <HMSRelease /> },
            { path: 'social-worker/release/view', element: <HMSRelease /> },

            { path: 'social-worker/phone', element: <PhoneReport /> },
            { path: 'social-worker/phone/edit', element: <PhoneReport /> },
            { path: 'social-worker/phone/view', element: <PhoneReport /> },
          ],
        },
      ],
    },
    {
      path: '/',
      element: <LogoOnlyLayout />,
      children: [
        { path: 'login', element: <Login /> },
        { path: 'register', element: <NotFound /> },
        { path: 'forgot-password', element: <ForgotPassword /> },
        { path: 'reset-password/:token', element: <ResetPassword /> },
        { path: '404', element: <NotFound /> },
        { path: '403', element: <Page403 /> },
        { path: '/', element: <Navigate to="/app/dashboard" /> },
        { path: '*', element: <Navigate to="/404" /> },
      ],
    },
    { path: '*', element: <Navigate to="/404" replace /> },
  ]);
}
