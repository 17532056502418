import { Grid } from '@mui/material';
import React from 'react';
import { IServiceResponse } from '../../../hooks/HookService';
import { IEmployeesFinancialReports } from '../../../interfaces/Reports';
import VisitAmountsReportSummaryWidget from './VisitAmountsReportSummaryWidget';
import VisitQuantityReportSummaryWidget from './VisitQuantityReportSummaryWidget';
import { AbilityContext } from '../../../casl/can';
import { useAbility } from '@casl/react';
import { AbilitySubjects } from '../../../casl/ability';

const EmployeesReportsWidgets = ({
  data,
  error,
  errorMessage,
  isLoading,
  loadingNumber,
}: IServiceResponse<IEmployeesFinancialReports>) => {
  const ability = useAbility(AbilityContext);
  const isAuthorizedToViewFinancialData = ability.can(
    'read',
    AbilitySubjects.VIEW_FINANCIAL_REPORTS
  );
  return (
    <Grid container spacing={3}>
      <Grid item xs={12} md={isAuthorizedToViewFinancialData ? 6 : 12}>
        <VisitQuantityReportSummaryWidget
          data={data}
          isLoading={isLoading}
          error={error}
          errorMessage={errorMessage}
          loadingNumber={loadingNumber}
        />
      </Grid>
      {isAuthorizedToViewFinancialData && (
        <Grid item xs={12} md={6}>
          <VisitAmountsReportSummaryWidget
            data={data}
            isLoading={isLoading}
            error={error}
            errorMessage={errorMessage}
            loadingNumber={loadingNumber}
          />
        </Grid>
      )}
    </Grid>
  );
};

export default EmployeesReportsWidgets;
