/* eslint-disable new-cap */
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { IProgramReferralPop } from '../../../interfaces/referral';
import { ReportData } from '../../../interfaces/Models';
import FetcherService from '../../../hooks/FetcherService';
import programReferralService from '../../../services/programReferralService';
import FormModal from '../../../components/Modals/FormModal';
import Form from '../../../components/Form/FormikForm';
import DueDateFormField from '../../../pages/program/hospitalDays/DueDateFormField';

interface IEditReferralIssueDateForm {
  openChangeReferralIssueDate: boolean;
  handleCloseModal: () => void;
  referral?: IProgramReferralPop | null;
  handleTableDataUpdate: () => void;
}
const EditReferralIssueDateForm = ({
  openChangeReferralIssueDate,
  handleCloseModal,
  referral,
  handleTableDataUpdate,
}: IEditReferralIssueDateForm) => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const IssueDateSchema = Yup.object({
    createdAt: Yup.date().required(`${t('clinic.form.required')}`),
  });

  const handleChangeIssueDate = async (values: ReportData) => {
    await FetcherService<any>({
      service: programReferralService.edit(String(referral?._id), values as IProgramReferralPop),
      setLoading: setIsLoading,
    });

    handleCloseModal();
    handleTableDataUpdate();
  };

  return (
    <FormModal
      open={openChangeReferralIssueDate}
      onClose={handleCloseModal}
      text={t('editReferralIssueDate')}
      loading={isLoading}
    >
      <Form
        onCancel={handleCloseModal}
        submit={handleChangeIssueDate}
        initialValues={{ createdAt: referral?.createdAt }}
        validationSchema={IssueDateSchema}
        cancelText={t('clinic.form.cancel')}
        submitText={t('clinic.form.edit')}
      >
        <DueDateFormField fieldName="createdAt" label={t('issueDate')} />
      </Form>
    </FormModal>
  );
};

export default EditReferralIssueDateForm;
