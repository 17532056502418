import { CardHeader, Stack, Typography, Box } from '@mui/material';
import React from 'react';
import { IClinic, IPatient, IPatientProgram } from '../../../interfaces/Models';
import { useTranslation } from 'react-i18next';
import { getDateFormatted } from '../../../utils/Helper';
import { InfoCard } from '../../../styles/DatailsInfoStyles';
import { theme } from '../../../theme';
import { IconStyle } from '../../user/profile/ProfileAbout';
import { IRecordDetail } from '../../../components/DetailsCard';
import { is } from 'date-fns/locale';
import Label from '../../../components/Label';

const ProgramScreenshotInfoCard = ({
  program,
  isEdit,
}: {
  program: IPatientProgram;
  isEdit: boolean;
}) => {
  const { t } = useTranslation();
  const { title, patient, startDate, endDate } = program;

  const labelText = isEdit ? t('updatedProgram') : t('newProgram');

  const patientDetails = patient as IPatient;
  const details = [
    {
      name: t('patient.form.title'),
      icon: 'dashicons:nametag',
      value: title,
    },
    {
      name: t('patient.info.patientName'),
      icon: 'eva:person-add-fill',
      value:
        typeof patient != 'string'
          ? `${patient.personalDetails.firstName} ${patient.personalDetails.lastName}`
          : '',
    },
    {
      name: t('patient.info.startDate'),
      icon: 'bi:calendar-date',
      value: getDateFormatted(startDate),
    },
    {
      name: t('patient.info.endDate'),
      icon: 'system-uicons:calendar-date',
      value: getDateFormatted(endDate),
    },
    {
      name: t('drawer.sidebar.clinic'),
      icon: 'bx:clinic',
      value:
        typeof program.patient !== 'string' && program.patient.clinic
          ? (program.patient.clinic as IClinic).name
          : '',
    },
    {
      name: t('patientPhone'),
      icon: 'eva:phone-call-fill',
      value: patientDetails.personalDetails.primaryPhone,
    },
    {
      name: t('patientAddress'),
      icon: 'la:address-book',
      value: patientDetails.personalDetails.address,
    },
    {
      name: t('personalDetails.gender'),
      icon: 'material-symbols:wc-sharp',
      value: t(`personalDetails.${patientDetails.personalDetails.gender}`),
    },
  ];
  return (
    <InfoCard>
      <Stack
        direction={'row'}
        gap={2}
        alignItems={'center'}
        sx={{ px: theme.spacing(4), pt: theme.spacing(2) }}
      >
        <Typography variant="h6">{title}</Typography>
        <Label variant="ghost" color={isEdit ? 'info' : 'primary'}>
          {labelText}
        </Label>
      </Stack>
      <Box
        sx={{
          display: 'grid',
          rowGap: 3,
          columnGap: 2,
          gridTemplateColumns: { xs: 'repeat(1, 1fr)', sm: 'repeat(3, 1fr)' },
          padding: theme.spacing(4),
        }}
      >
        {details.map((detail: IRecordDetail) => {
          return detail.value ? (
            <Stack direction="row" key={detail.name}>
              <IconStyle icon={detail.icon} />
              <Typography variant="body2">
                {detail.name && detail.value && detail.value.length > 0
                  ? `${detail.name} : ${detail.value}`
                  : ''}
              </Typography>
            </Stack>
          ) : null;
        })}
      </Box>
    </InfoCard>
  );
};
export default ProgramScreenshotInfoCard;
