import { matchPath, useLocation } from 'react-router-dom';
import { Box, List } from '@mui/material';
import NavItem, { IChildrenPath } from './NavItem';
import { Subject } from '../casl/ability';
import { useAbility } from '@casl/react';
import { AbilityContext } from '../casl/can';

export interface INavItemConfig {
  title: string;
  icon?: JSX.Element;
  path: string;
  info?: string;
  children?: IChildrenPath[];
  abilityName: Subject;
}

interface INavSection {
  navConfig: Array<INavItemConfig>;
}
export default function NavSection({ navConfig, ...other }: INavSection) {
  const { pathname } = useLocation();
  const match = (path: string) => (path ? !!matchPath({ path, end: true }, pathname) : false);
  const ability = useAbility(AbilityContext);

  return (
    <Box {...other}>
      <List disablePadding>
        {navConfig.map((item) => {
          return ability.can('read', item.abilityName) ? (
            <NavItem key={item.path} item={item} active={match} />
          ) : null;
        })}
      </List>
    </Box>
  );
}
