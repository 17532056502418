import React from 'react';
import { Outlet } from 'react-router-dom';

import { styled } from '@mui/material/styles';

const RootStyle = styled('div')({
  display: 'flex',
  minHeight: '100%',
  width: '100%',
});

export default function ScreenshotViewLayout() {
  return (
    <RootStyle>
      <Outlet />
    </RootStyle>
  );
}
