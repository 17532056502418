import React from 'react';
import { Grid, Typography, FormControlLabel, Radio } from '@mui/material';
import { FastField as Field } from 'formik';
import { TextField, RadioGroup } from 'formik-mui';
import { useTranslation } from 'react-i18next';
import { styled } from '@mui/system';
import { theme } from '../../../../theme';
import isReportMode from '../../../../hooks/isReportMode';

const StyledGrid = styled(Grid)({
  marginBottom: theme.spacing(2),
});

const StyledRadioGroup = styled(RadioGroup)({
  marginLeft: theme.spacing(1.5),
});

const CognitiveExamFields = () => {
  const { t } = useTranslation();
  const id = 'HMBFirst_CognitiveExam';

  const isViewMode = isReportMode('view');
  return (
    <div id={id}>
      <Typography variant="subtitle1" gutterBottom align="left">
        {t('report.firstVisitReport.cognitiveTests.header')}
      </Typography>
      <StyledGrid container spacing={2} id={`${id}_Container`}>
        <Grid item xs={12} md={12} lg={12}>
          <Field
            id={`${id}_TextInput_TimeOrientation`}
            fullWidth
            component={TextField}
            type="text"
            name="data.cognitiveTests.timeOrientation"
            label={t('report.firstVisitReport.cognitiveTests.timeOrientation')}
            helperText={t('report.firstVisitReport.cognitiveTests.timeHelperText')}
          />
        </Grid>
        <Grid item xs={12} md={12} lg={12}>
          <Field
            id={`${id}_TextInput_PositionOrientation`}
            fullWidth
            component={TextField}
            type="text"
            name="data.cognitiveTests.positionOrientation"
            label={t('report.firstVisitReport.cognitiveTests.positionOrientation')}
            helperText={t('report.firstVisitReport.cognitiveTests.positionHelperText')}
          />
        </Grid>
      </StyledGrid>
      <Typography variant="subtitle2">
        {t('report.firstVisitReport.cognitiveTests.MINICOG')}
      </Typography>
      <Grid container>
        <Grid item xs={11} md={10} lg={7}>
          <Field component={StyledRadioGroup} name="data.cognitiveTests.MINICOG">
            <FormControlLabel
              id={`${id}_RadioInput_Dementia`}
              value="אפס מילים = דמנציה"
              control={<Radio />}
              label={`${t('report.firstVisitReport.cognitiveTests.dementia')}`}
              disabled={isViewMode}
            />
            <FormControlLabel
              id={`${id}_RadioInput_CognitiveCounseling`}
              value="מילים = צריך בדיקות מעמיקות ( ייעוץ קוגניטיבי )"
              control={<Radio />}
              label={`${t('report.firstVisitReport.cognitiveTests.cognitiveCounseling')}`}
              disabled={isViewMode}
            />
            <FormControlLabel
              id={`${id}_RadioInput_Fine`}
              value="3 מילים = תקין"
              control={<Radio />}
              label={`${t('report.firstVisitReport.cognitiveTests.fine')}`}
              disabled={isViewMode}
            />
          </Field>
        </Grid>
      </Grid>
    </div>
  );
};

export default CognitiveExamFields;
