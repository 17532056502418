/* eslint-disable new-cap */
import React from 'react';
import { IReport } from '../../interfaces/Models';
import { FormikProvider, useFormik } from 'formik';
import FetcherService from '../../hooks/FetcherService';
import ReportService from '../../services/ReportService';
import { useNavigate } from 'react-router-dom';
import SLSCache from 'sls-cache';
import ReportsActions from '../../pages/reports/sharedFields/ReportsActions';
import { assessmentFields } from '../../common/assessment';
import isReportMode, { checkIfReportTypeRequired } from '../../hooks/isReportMode';
import { useTranslation } from 'react-i18next';
import { removeCachedData } from '../../utils/Helper';
import { useRecoilValue } from 'recoil';
import { visitAtom } from '../../atoms/visitAtom';
import GenericField from '../../pages/reports/sharedFields/GenericField';
import { REPORT_TYPE_OPTIONS } from '../../common/Constants';

interface IReportsForm {
  validationSchema: Object;
  initialValues: any;
  visitId: string;
  name?: string;
  resetForm?: boolean;
  resetFormValues?: any;
  hmpBetiReport?: boolean;
  mutateSubmitData?: (val: IReport) => IReport;
}
const ReportsForm: React.FC<IReportsForm> = ({
  validationSchema,
  initialValues,
  visitId,
  name,
  resetForm,
  resetFormValues,
  hmpBetiReport,
  children,
  mutateSubmitData,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const currentVisit = useRecoilValue(visitAtom);
  const isViewMode = isReportMode('view');
  const isEditMode = isReportMode('edit');
  const isReportTypeRequired = checkIfReportTypeRequired(currentVisit);

  const submitText = isEditMode || isViewMode ? t('report.editReport') : t('report.saveReport');

  const initialFormData = {
    ...initialValues,
    data: {
      ...initialValues.data,
      reportType: currentVisit?.reportType || 'physical',
    },
  };

  const formik = useFormik({
    initialValues: initialFormData as any,
    validationSchema: validationSchema,
    onSubmit: async (values: IReport, { setSubmitting }) => {
      if (hmpBetiReport) {
        const assessmentsNotes: string[] = [];

        assessmentFields.map((field: string) => {
          assessmentsNotes.push(values.data[`${field}Note`]);
        });
        values.data.assessmentsNotes = assessmentsNotes;
      }

      if (values?.data?.PHQ9?.mainProblemsSectionSum < 3 && values.data.PHQ9?.problems) {
        values.data.PHQ9 = {
          ...(values.data.PHQ9 || {}),
          problems: {
            interestInDoingThings: values.data.PHQ9.problems.interestInDoingThings,
            despondency: values.data.PHQ9.problems.despondency,
          },
        };
      }

      values.data.isDraft = currentVisit?.isDraft || false;

      let reportData = values;
      if (mutateSubmitData) {
        reportData = mutateSubmitData(values);
      }

      SLSCache.set(visitId, reportData);

      const onSuccessSubmission = () => {
        removeCachedData(visitId || 'empty');
      };

      if (reportData._id) {
        await FetcherService<IReport>({
          service: ReportService.edit(visitId || 'empty', {
            ...reportData,
            isDraft: currentVisit?.isDraft || false,
          }),
          onSuccess: onSuccessSubmission,
        });
      } else {
        await FetcherService<IReport>({
          service: ReportService.add({ ...reportData, isDraft: currentVisit?.isDraft || false }),
          onSuccess: onSuccessSubmission,
        });
      }

      setSubmitting(false);
      navigate(-1);
    },
  });

  return (
    <FormikProvider value={formik}>
      <form onSubmit={formik.handleSubmit}>
        {isReportTypeRequired && (
          <GenericField
            id={`${currentVisit?.visitType}_treatment_goals`}
            fields={[
              {
                name: 'data.reportType',
                label: 'reportType',
                type: 'select',
                options: REPORT_TYPE_OPTIONS,
                smallSize: 12,
                largeSize: 12,
              },
            ]}
          />
        )}
        {children}
        <ReportsActions
          submitText={submitText}
          name={name}
          resetForm={resetForm}
          initialValues={resetFormValues}
        />
      </form>
    </FormikProvider>
  );
};

export default ReportsForm;
