/* eslint-disable new-cap */
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useParams, useOutletContext } from 'react-router-dom';
import * as Yup from 'yup';
import { reportTypes } from '../../../common/Constants';
import ErrorMessage from '../../../components/ErrorMessage';
import { HMDRepeatedValues } from '../../../common/reportsFieldsValues/hmd/HMDRepeatedValues';
import HMDRepeatedFields from './fields/HMDRepeatedFields';
import ReportsForm from '../../../components/Form/ReportsForm';

const HMDRepeated = () => {
  const { t } = useTranslation();
  const { visitId } = useParams();
  const data: any = useOutletContext();

  const initialValues =
    data && data.name
      ? data
      : {
          name: reportTypes.HMD_REPEATED,
          visit: visitId,
          data: data ? data : HMDRepeatedValues,
        };
  const ReportSchema = Yup.object({
    name: Yup.string().required(t('clinic.form.required')),
    visit: Yup.string().required(t('clinic.form.required')),
    data: Yup.object().shape({
      bloodTests: Yup.string().required(t('clinic.form.required')),
      anthropometry: Yup.string().required(t('clinic.form.required')),
      currentAnamnesis: Yup.string().required(t('clinic.form.required')),
      responding: Yup.string().required(t('clinic.form.required')),
      additionalDetails: Yup.string().required(t('clinic.form.required')),
      recommendations: Yup.string().required(t('clinic.form.required')),
    }),
  });

  const HtmlId = 'HMDRepeated';

  if (data?.name && data.name != reportTypes.HMD_REPEATED) {
    return <ErrorMessage error={t('global.errors.somethingWrong')} />;
  }

  return (
    <ReportsForm
      initialValues={initialValues}
      visitId={visitId || ''}
      validationSchema={ReportSchema}
      name={data?.name}
      resetForm={true}
      resetFormValues={{ ...initialValues, data: HMDRepeatedValues }}
    >
      <HMDRepeatedFields id={HtmlId} />
    </ReportsForm>
  );
};

export default HMDRepeated;
