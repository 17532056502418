import { useTranslation } from 'react-i18next';
import { styled } from '@mui/material/styles';
import { Card, Typography, CardHeader, Stack } from '@mui/material';
import { format } from 'date-fns';
import Iconify from '../../../components/Iconify';
import { IUser } from '../../../interfaces/Models';

// ----------------------------------------------------------------------

export const IconStyle = styled(Iconify)(({ theme }) => ({
  width: 20,
  height: 20,
  marginTop: 1,
  flexShrink: 0,
  marginRight: theme.spacing(2),
}));

// ----------------------------------------------------------------------
interface IProfileAbout {
  user: IUser | null;
}
export default function ProfileAbout({ user }: IProfileAbout) {
  const { t } = useTranslation();
  const { idNumber, email, position, licenseNumber, visitRate, employeeNumber, startDate } =
    user || {};

  return (
    <Card>
      <CardHeader title={t('global.profile.about')} />

      <Stack spacing={2} sx={{ p: 3, pb: 7 }}>
        <Stack direction="row">
          <IconStyle icon={'arcticons:id-wallet'} />
          <Typography variant="body2">{idNumber}</Typography>
        </Stack>
        <Stack direction="row">
          <IconStyle icon={'eva:email-fill'} />
          <Typography variant="body2">{email}</Typography>
        </Stack>
        {position && (
          <Stack direction="row">
            <IconStyle icon={'fluent:briefcase-medical-24-regular'} />
            <Typography variant="body2">{t(`global.positions.${position}`)}</Typography>
          </Stack>
        )}
        {licenseNumber && (
          <Stack direction="row">
            <IconStyle icon={'clarity:license-line'} />
            <Typography variant="body2">
              {t('user.form.licenseNumber')} : {licenseNumber}
            </Typography>
          </Stack>
        )}
        <Stack direction="row">
          <IconStyle icon={'fa6-solid:money-check-dollar'} />
          <Typography variant="body2">
            {t('user.form.visitRate')} : {visitRate} {t('global.currencyUnits.nis')}
          </Typography>
        </Stack>
        {employeeNumber && (
          <Stack direction="row">
            <IconStyle icon={'clarity:employee-line'} />
            <Typography variant="body2">
              {t('user.form.employeeNumber')} : {employeeNumber}
            </Typography>
          </Stack>
        )}
        {startDate && (
          <Stack direction="row">
            <IconStyle icon={'uiw:date'} />
            <Typography variant="body2">
              {t('user.form.startDate')} : {format(new Date(startDate), 'dd-MM-yyyy')}
            </Typography>
          </Stack>
        )}
      </Stack>
    </Card>
  );
}
