import React from 'react';
import ErrorMessage from '../../../components/ErrorMessage';
import { t } from 'i18next';
import PiChart from '../../../components/PiChart';
import { theme } from '../../../theme';
import PiChartWidgetSkeleton from '../../../components/skeletons/PiChartWidgetSkeleton';
import { IServiceResponse } from '../../../hooks/HookService';
import { ReportData } from '../../../interfaces/Models';

const ProgramsStatusDonutWidget = ({
  data,
  error,
  errorMessage,
  isLoading,
}: IServiceResponse<ReportData>) => {
  if (isLoading) {
    return <PiChartWidgetSkeleton />;
  }
  if (error) {
    return <ErrorMessage error={errorMessage} />;
  }

  return (
    <PiChart
      title={t('global.pages.programs')}
      colors={theme.palette.dashboardCharts}
      labels={[
        t('global.dashboard.active'),
        t('global.dashboard.done'),
        t('global.dashboard.canceled'),
      ]}
      chartData={Object.values(data || {})}
    />
  );
};

export default ProgramsStatusDonutWidget;
