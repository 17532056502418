/* eslint-disable new-cap */
import { useTranslation } from 'react-i18next';
import { useEffect, useMemo, useState } from 'react';
import * as Yup from 'yup';
import { useRecoilValue } from 'recoil';
import axios from 'axios';
import { IPatientProgram, IUserFlat } from '../../../interfaces/Models';
import { userAtom } from '../../../atoms/userAtom';
import FormModal from '../../../components/Modals/FormModal';
import Form from '../../../components/Form/FormikForm';
import FetcherService from '../../../hooks/FetcherService';
import { checkIfUserIsAdmin } from '../../../utils/Helper';
import PhoneVisitFormFields from './PhoneVisitFormFields';
import PatientProgramService from '../../../services/PatientProgramService';
import { format } from 'date-fns';

interface IFormData {
  employee: IUserFlat;
  dueDate: Date;
}
interface Props {
  open: boolean;
  handleCloseModal: () => void;
  handleProgramUpdate: () => void;
  program: IPatientProgram;
}
const PhoneVisitForm = ({ open, handleCloseModal, handleProgramUpdate, program }: Props) => {
  const { t } = useTranslation();
  const user = useRecoilValue(userAtom);
  const [isLoadingForm, setIsLoading] = useState<boolean>(false);

  const [employees, setEmployees] = useState<IUserFlat[]>([]);
  const [isEmployeesDataFetched, setIsEmployeesDataFetched] = useState<boolean>(false);
  const [isLoadingEmployees, setIsLoadingEmployees] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');

  const isUserAdmin = checkIfUserIsAdmin(user);

  useEffect(() => {
    if (!isEmployeesDataFetched && isUserAdmin && open && !isLoadingEmployees) {
      const fetchEmployees = async () => {
        setIsLoadingEmployees(true);
        try {
          const result = await axios.get(`/api/user?page=0&limit=1000&isMedicalPosition=true`);

          const { data: users } = result;
          setIsEmployeesDataFetched(true);
          setEmployees(users?.data || []);
          setIsLoadingEmployees(false);
        } catch (error: any) {
          setErrorMessage(error.message);
          setError(true);
        }
      };

      fetchEmployees();
    }
  }, [open]);

  const Schema = Yup.object({
    employee: Yup.object()
      .shape({
        _id: Yup.string().required(t('clinic.form.required')),
      })
      .required(t('clinic.form.required')),
    dueDate: Yup.date().required(t('clinic.form.required')),
  });

  const initialValues = useMemo(() => {
    return {
      employee: isUserAdmin
        ? null
        : {
            _id: user?._id,
          },
      dueDate: new Date(),
    };
  }, [user, isLoadingEmployees]);

  const handleSubmit = async (values: IFormData) => {
    await FetcherService<any>({
      service: PatientProgramService.addPhoneVisit(program._id || 'empty', {
        employeeId: String(values.employee._id),
        dueDate: format(new Date(values.dueDate), 'MM-dd-yyyy'),
      }),
      setLoading: setIsLoading,
    });
    handleCloseModal();
    handleProgramUpdate();
  };

  return (
    <FormModal
      open={open}
      onClose={handleCloseModal}
      text={t('newPhoneReport')}
      loading={isLoadingForm}
      key={user?._id}
    >
      <Form
        onCancel={handleCloseModal}
        submit={handleSubmit}
        initialValues={initialValues}
        validationSchema={Schema}
        cancelText={t('clinic.form.cancel')}
        submitText={t('clinic.form.add')}
      >
        <PhoneVisitFormFields
          error={error}
          errorMessage={errorMessage}
          isLoadingEmployees={isLoadingEmployees}
          employees={employees}
        />
      </Form>
    </FormModal>
  );
};

export default PhoneVisitForm;
