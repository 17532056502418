import React from 'react';
import { useTranslation } from 'react-i18next';
import GenericField from '../../sharedFields/GenericField';
import {
  physicalExaminationsFields,
  motionExaminationsFields,
  equilibriumExaminationsFields,
  vasPainFields,
} from '../../../../common/HMPFirst';

const PhysicalConditionFields = () => {
  const { t } = useTranslation();
  const id = 'HMPFirst_PhysicalCondition';
  return (
    <div id={id}>
      <GenericField
        id={`${id}_Examination_UpperLimb`}
        // header={t('report.HMPFIRST.header')}
        subHeader={t('report.HMPFIRST.physicalCondition.header')}
        fields={physicalExaminationsFields}
      />
      <GenericField
        id={`${id}_Examination_Pain_PlaceOfPain`}
        fields={[
          {
            name: 'data.physicalCondition.functionalAbility',
            label: 'report.HMPFIRST.physicalCondition.functionalAbility',
            type: 'text',
            rows: 2,
            smallSize: 12,
            largeSize: 12,
          },
        ]}
      />
      <GenericField
        id={`${id}_Examination_RangeMotion`}
        subHeader={t('report.HMPFIRST.physicalCondition.motion.header')}
        fields={motionExaminationsFields}
      />
      <GenericField id={`${id}_Examination_Equilibrium`} fields={equilibriumExaminationsFields} />
      <GenericField
        id={`${id}_Examination_Pain_PlaceOfPain`}
        subHeader={t('report.HMPFIRST.physicalCondition.pain.header')}
        fields={[
          {
            name: 'data.physicalCondition.pain.placeOfPain',
            label: 'report.HMPFIRST.physicalCondition.pain.placeOfPain',
            type: 'text',
            rows: 1,
            smallSize: 12,
            largeSize: 12,
          },
        ]}
      />
      <GenericField id={`${id}_Examination_Pain_VAS`} fields={vasPainFields} />
      <GenericField
        id={`${id}_GeneralLook`}
        fields={[
          {
            name: 'data.physicalCondition.generalLook',
            label: 'report.HMPFIRST.physicalCondition.generalLook',
            type: 'text',
            rows: 2,
            smallSize: 12,
            largeSize: 12,
          },
        ]}
      />
    </div>
  );
};

export default PhysicalConditionFields;
