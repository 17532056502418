import { Field, useFormikContext } from 'formik';
import React from 'react';
import { Autocomplete, AutocompleteRenderInputParams } from 'formik-mui';
import { IUserFlat } from '../../../interfaces/Models';
import { StyledTextInput } from '../../../styles/FormFieldsStyles';
import { useTranslation } from 'react-i18next';

export default function SwapEmployeeFormFields({ users }: { users: IUserFlat[] }) {
  const { t } = useTranslation();
  const { touched, errors }: any = useFormikContext();

  return (
    <Field
      fullWidth
      name="employee"
      component={Autocomplete}
      options={users}
      getOptionLabel={(option: IUserFlat) => option.fullName || ''}
      renderInput={(params: AutocompleteRenderInputParams) => (
        <StyledTextInput
          {...params}
          name="employee"
          error={touched['employee'] && !!errors['employee']}
          helperText={touched['employee'] && errors['employee'] ? t('clinic.form.required') : ''}
          label={t('global.roles.EMPLOYEE')}
          variant="outlined"
        />
      )}
    />
  );
}
