import { Box, Button, FormControlLabel, Stack, styled, Switch, SwitchProps } from '@mui/material';
import React, { useEffect, useState } from 'react';
import SaveIcon from '@mui/icons-material/Save';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { ReportFormFooter } from '../../../styles/FormFieldsStyles';
import DialogActions from '../../../components/DialogActions';
import { FormikDisabler } from '../../../hooks/FormikDisabler';
import isReportMode from '../../../hooks/isReportMode';
import OverlaySpinner from '../../../components/OverlaySpinner';
import { useFormikContext } from 'formik';
import SLSCache from 'sls-cache';
import { IReport } from '../../../interfaces/Models';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { visitAtom } from '../../../atoms/visitAtom';
import { IVisitWithProgramTitle } from '../../../layouts/reports/ReportLayout';
import { VISIT_STATUS } from '../../../common/Constants';
import ReportAttachmentButton from '../../../components/ReportAttachmentButton';

interface ReportsActionsProps {
  submitText: string;
  initialValues?: Object;
  name?: string;
  resetForm?: boolean;
}

const IOSSwitch = styled((props: SwitchProps) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 42,
  height: 26,
  padding: 0,
  '& .MuiSwitch-switchBase': {
    padding: 0,
    margin: 2,
    transitionDuration: '300ms',
    '&.Mui-checked': {
      transform: 'translateX(16px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        backgroundColor: theme.palette.mode === 'dark' ? '#2BAFA2' : '#1890FF',
        opacity: 1,
        border: 0,
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.5,
      },
    },
    '&.Mui-focusVisible .MuiSwitch-thumb': {
      color: '#33cf4d',
      border: '6px solid #fff',
    },
    '&.Mui-disabled .MuiSwitch-thumb': {
      color: theme.palette.mode === 'light' ? theme.palette.grey[100] : theme.palette.grey[600],
    },
    '&.Mui-disabled + .MuiSwitch-track': {
      opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
    },
  },
  '& .MuiSwitch-thumb': {
    boxSizing: 'border-box',
    width: 22,
    height: 22,
  },
  '& .MuiSwitch-track': {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
    opacity: 1,
    transition: theme.transitions.create(['background-color'], {
      duration: 500,
    }),
  },
}));

const ReportsActions = ({ submitText, initialValues, name, resetForm }: ReportsActionsProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { visitId } = useParams();
  const { isSubmitting, isValid, dirty, resetForm: resetReportData } = useFormikContext();
  const currentVisit = useRecoilValue(visitAtom);
  const setCurrentVisit = useSetRecoilState(visitAtom);
  const [isReportDrafted, setIsReportDrafted] = useState(false);

  const failedPreviousSubmissionData = SLSCache.get<IReport>(visitId || 'empty');

  const [open, setOpen] = useState<boolean>(false);

  const validSubmission = !(
    isValid &&
    (dirty || !name || failedPreviousSubmissionData || isReportDrafted)
  );

  useEffect(() => {
    if (currentVisit?.isDraft) {
      setIsReportDrafted(true);
    }
  }, []);

  const handleDraftToggle = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCurrentVisit({
      ...currentVisit,
      isDraft: event.target.checked,
    } as IVisitWithProgramTitle);
  };

  const resetFormHandler = () => {
    if (initialValues && resetReportData) {
      resetReportData({
        values: { ...initialValues },
      });
    }
    setOpen(false);
  };

  const onOpenModal = () => {
    setOpen(true);
  };

  const onCloseModal = () => {
    setOpen(false);
  };
  const isViewMode = isReportMode('view');

  if (isSubmitting && !isViewMode) {
    return <OverlaySpinner />;
  }

  const isShowResetButton = !!initialValues && !!resetForm && !isViewMode;

  return (
    <>
      {isViewMode ? <FormikDisabler /> : null}
      <DialogActions
        onClose={onCloseModal}
        open={open}
        dialog={t('global.reportActions.resetConfirmation')}
        action={resetFormHandler}
      />
      <Stack direction="column">
        {currentVisit?.status === VISIT_STATUS.PENDING && (
          <Box
            sx={{
              position: 'relative',
              right: 12,
              marginBottom: 2,
            }}
          >
            <FormControlLabel
              control={
                <IOSSwitch
                  sx={{ m: 1 }}
                  onChange={handleDraftToggle}
                  defaultChecked={currentVisit?.isDraft}
                />
              }
              label={t('report.draft') as string}
            />
          </Box>
        )}
        <Stack direction="row" justifyContent="space-between">
          <Button
            disabled={isViewMode || validSubmission || isViewMode}
            variant="contained"
            size="large"
            type="submit"
            sx={{ right: 16 }}
            endIcon={<SaveIcon />}
          >
            {submitText}
          </Button>

          <ReportFormFooter>
            <ReportAttachmentButton />
            <Button
              disabled={!isShowResetButton}
              type="reset"
              variant="contained"
              size="large"
              onClick={onOpenModal}
            >
              {t('global.reportActions.reset')}
            </Button>
            <Button
              disabled={isViewMode}
              variant="contained"
              size="large"
              onClick={() => navigate(-1)}
              color="error"
            >
              {t('user.form.goBack')}
            </Button>
          </ReportFormFooter>
        </Stack>
      </Stack>
    </>
  );
};

export default ReportsActions;
