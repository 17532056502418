import React, { CSSProperties, useState } from 'react';
import { AdvancedImage } from '@cloudinary/react';
import { fill } from '@cloudinary/url-gen/actions/resize';
import { Cloudinary } from '@cloudinary/url-gen';
import {
  PRODUCTION_CLOUDINARY_CLOUD_NAME,
  isProduction,
  DEVELOPMENT_CLOUDINARY_CLOUD_NAME,
} from '../common/Constants';
import { Stack } from '@mui/material';
import CircularProgress from './CircularProgress';

export const CloudId = new Cloudinary({
  cloud: {
    cloudName: isProduction ? PRODUCTION_CLOUDINARY_CLOUD_NAME : DEVELOPMENT_CLOUDINARY_CLOUD_NAME,
  },
});

interface ICloudinaryImage {
  publicId: string;
  style?: CSSProperties;
}
export default function CloudinaryImage({ publicId, style }: ICloudinaryImage) {
  const [loading, setLoading] = useState(true);

  const handleLoad = () => setLoading(false);

  return (
    <>
      {loading && (
        <Stack justifyContent={'center'} alignItems={'center'}>
          <CircularProgress />
        </Stack>
      )}
      <AdvancedImage
        cldImg={CloudId.image(publicId).resize(fill()).format('png')}
        onLoad={handleLoad}
        style={style}
        alt="Cloudinary Image"
      />
    </>
  );
}
