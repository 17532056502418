import React from 'react';
import { evaluationBeforeAsssistanceFields } from '../../../../common/HMDFirst';
import SectionDivider from '../../../../components/SectionDivider';
import GenericField from '../../sharedFields/GenericField';
import EvaluationBeforeTreatmentFields from './EvaluationBeforeTreatmentFields';
import HealthIndicesFields from './HealthIndicesFields';
import MedicalInfoFields from './MedicalInfoFields';
import NutritionalConsumptionFields from './NutritionalConsumptionFields';
import NutritionalGoalsFields from './NutritionalGoalsFields';
import RecommendationsFields from './RecommendationsFields';
import VisitSummaryFields from './VisitSummaryFields';
import { useTranslation } from 'react-i18next';
import { useFormikContext } from 'formik';

interface HMDFirstFieldsProps {
  id: string;
}
const HMDFirstFields = ({ id }: HMDFirstFieldsProps) => {
  const { touched, errors }: any = useFormikContext();

  const { t } = useTranslation();

  return (
    <>
      <MedicalInfoFields />
      <SectionDivider />
      <HealthIndicesFields />
      <SectionDivider />
      <NutritionalConsumptionFields />
      <SectionDivider />
      <EvaluationBeforeTreatmentFields />
      <SectionDivider />
      <GenericField
        id={`${id}_EvaluationBeforeAsssistance`}
        header={t('report.HMDFIRST.evaluationBeforeAsssistance.header')}
        fields={evaluationBeforeAsssistanceFields}
      />
      <SectionDivider />
      <NutritionalGoalsFields id={`${id}_NutritionalGoals`} touched={touched} errors={errors} />
      <SectionDivider />
      <RecommendationsFields />
      <SectionDivider />
      <VisitSummaryFields />
      <SectionDivider />
    </>
  );
};

export default HMDFirstFields;
