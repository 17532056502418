// -------------------
const passiveActivationExerciseOptions = [
  'Shoulder flexion',
  'Shoulder extension',
  'Shoulder abduction',
  'Shoulder adduction',
  'Shoulder internal rotation',
  'Shoulder external rotation',
  'Shoulder horizontal abduction',
  'Shoulder horizontal adduction',
  'Elbow flexion',
  'Elbow extension',
  'Forearm supination',
  'Forearm pronation',
  'Wrist flexion',
  'Wrist extension',
  'Wrist ulnar deviation',
  'Wrist radial deviation',
  'Digits MCP’s',
  'Digits PIP’s',
  'Digits DIP’s',
  'Thumb MCP.',
  'Thumb IP',
  'Thigh flexion',
  'Thigh extension',
  'Thigh abduction',
  'Thigh adduction',
  'Knee flexion',
  'Knee extension',
  'Ankle dorsi flexion',
  'Ankle plantar flexion',
];

export const englishPracticeOptions = [
  'Joint compression',
  'Joint traction',
  'Weight bearing',
  'Tone inhibiting massage',
  'Tone facilitating massage',
  'Applying heat packs',
  'Applying cold packs',
  'Side walking',
  'Reverse walking',
  'Shifting',
  'Palm to fingers',
  'Fingers to palm',
  'Simple rotation',
  'Complex rotation',
  ...passiveActivationExerciseOptions,
];
// ---------------------------------------
// general categories
export const practiceCategories = [
  'selfHelp',
  'dailyLife',
  'motorSkills',
  'graphoMotor',
  'grossMotorSkills',
  'cognitive',
];
// -----------------------------
// sub-categories for self-help category
export const practiceSubCategories: any = {
  selfHelp: ['eatingDrinkingCategory', 'dressed', 'bathing', 'toiletHygiene', 'personalHygiene'],
  dailyLife: ['household', 'mealPreparationCategory', 'communityMobility', 'shopping'],
  motorSkills: [
    'tripod',
    'tipToTop',
    'lateral',
    'sphericalGrasp',
    'cylindricalGrasp',
    'manipulation',
  ],
  graphoMotor: ['drawing', 'painting', 'writing', 'cutting'],
  grossMotorSkills: [
    'passiveActivation',
    'activation',
    'strengtheningMuscles',
    'tone',
    'mobility',
    'stairs',
    'equilibrium',
    'transitionsCategory',
  ],
  cognitive: ['memory', 'troubleshooting', 'identification', 'orientation1'],
};

// ---------------------------

const selfHelpPositions = ['meeting', 'onYourBack', 'resistance'];
const selfHelpLevels = ['independently', 'withAccessory', 'minimalHelp', 'mediumHelp1', 'highHelp'];
const tripodHelpLevels = ['independently', 'minimalHelp', 'mediumHelp1', 'highHelp'];

// -----------------------------------
const selfHelpPositionField = {
  name: 'position',
  options: selfHelpPositions,
  errors: '',
};
const selfHelpLevelField = {
  name: 'helpLevel',
  options: selfHelpLevels,
  errors: '',
};

const tripodHelpLevelField = {
  name: 'helpLevel',
  options: tripodHelpLevels,
  errors: '',
};
const drawingPositionField = {
  name: 'position',
  options: ['meeting', 'resistance'],
  errors: '',
};
// dailyLife options and fields
const dailyLifePositions = ['meeting', 'resistance'];
const dailyLifePositionField = {
  name: 'position',
  options: dailyLifePositions,
  errors: '',
};
// Passive Activation fields
const passiveActivationExerciseField = {
  name: 'exercise',
  options: passiveActivationExerciseOptions,
  errors: '',
};

const passiveActivationPositionOptions = [
  'meeting',
  'onYourBack',
  'onNauticalSide',
  'onLeftSide',
  'onYourStomach',
  'resistance',
];

const passiveActivationPositionField = {
  name: 'position',
  options: passiveActivationPositionOptions,
  errors: '',
};
const passiveActivationTrappingField = {
  name: 'trapping',
  options: ['right1', 'left1', 'twoSides'],
  errors: '',
};

// ---------------------------------------------
// stairs fields and options
const lastPracticeCategoryHelpLevelField = {
  name: 'helpLevel',
  options: [...selfHelpLevels, 'underSupervision', 'fullHelp1'],
  errors: '',
};

// -----------------

const practiceFields: any = {
  fields1: [
    {
      name: 'exercise',
      options: ['independenceEating', 'useOfCutlery', 'useOfDrinking'],
      errors: '',
    },
    selfHelpPositionField,
    selfHelpLevelField,
  ],
  fields2: [
    {
      name: 'exercise',
      options: [
        'upperLimbClothes',
        'lowerLimbClothes',
        'upperLimbRaidClothes',
        'lowerClothesStripping',
        'buttons',
        'zipperOpening',
        'shoeLock',
        'wearingSocks',
        'lacing',
      ],
      errors: '',
    },
    selfHelpPositionField,
    selfHelpLevelField,
  ],
  fields3: [
    {
      name: 'exercise',
      options: ['useOfSoap', 'usingAMarker', 'wiping', 'makingTransitions'],
      errors: '',
    },
    selfHelpPositionField,
    selfHelpLevelField,
  ],
  fields4: [
    {
      name: 'exercise',
      options: ['bracketControl', 'toiletClothes', 'transitions', 'bodyCleansing'],
      errors: '',
    },
    selfHelpLevelField,
  ],
  fields5: [
    {
      name: 'exercise',
      options: ['applyCreams', 'combing', 'nailRingworm', 'deodorant', 'hygieneHere'],
      errors: '',
    },
    selfHelpPositionField,
    selfHelpLevelField,
  ],
  fields6: [
    {
      name: 'exercise',
      options: ['WardrobeArrangement', 'floorCleaning', 'surfaceCleaning', 'laundry'],
      errors: '',
    },
    dailyLifePositionField,
    selfHelpLevelField,
  ],
  fields7: [
    {
      name: 'exercise',
      options: [
        'mealPlanning',
        'mealPreparation',
        'servingMeal',
        'cleaningEnvironment',
        'hotDrink',
      ],
      errors: '',
    },
    dailyLifePositionField,
    selfHelpLevelField,
  ],
  fields8: [
    {
      name: 'exercise',
      options: ['publicTransport', 'communityResources'],
      errors: '',
    },
    selfHelpLevelField,
  ],
  fields9: [
    {
      name: 'exercise',
      options: ['shoppingList', 'purchase', 'payment'],
      errors: '',
    },
    selfHelpLevelField,
  ],
  fields10: [
    {
      name: 'equipment',
      options: ['hard', 'medium1', 'soft1'],
      optionLable: 'ballDrill',
      errors: '',
    },
    selfHelpPositionField,
    tripodHelpLevelField,
  ],
  fields11: [
    {
      name: 'exercise',
      options: [
        'Shifting',
        'Palm to fingers',
        'Fingers to palm',
        'Simple rotation',
        'Complex rotation',
      ],
      errors: '',
    },
    selfHelpPositionField,
    tripodHelpLevelField,
  ],
  fields12: [drawingPositionField, selfHelpLevelField],
  fields13: [
    passiveActivationExerciseField,
    passiveActivationPositionField,
    lastPracticeCategoryHelpLevelField,
    passiveActivationTrappingField,
  ],
  fields14: [
    passiveActivationExerciseField,
    passiveActivationPositionField,
    lastPracticeCategoryHelpLevelField,
    passiveActivationTrappingField,
  ],
  fields15: [
    passiveActivationExerciseField,
    {
      name: 'position',
      options: ['meeting', 'onYourBack', 'onRightSide', 'onLeftSide', 'onBed', 'resistance'],
      errors: '',
    },
    {
      name: 'equipment',
      options: ['terraBand', 'weights'],
      errors: '',
    },
    {
      name: 'equipmentTerraBand',
      options: ['hard', 'soft', 'medium'],
      errors: '',
      conditionName: 'equipment',
      conditionValue: 'terraBand',
    },
    {
      name: 'equipmentWeights',
      type: 'input',
      fieldType: 'number',
      errors: '',
      conditionName: 'equipment',
      conditionValue: 'weights',
    },
    lastPracticeCategoryHelpLevelField,
    passiveActivationTrappingField,
  ],
  fields16: [
    {
      name: 'exercisePurpose',
      options: ['tonus', 'toneReduction'],
      errors: '',
    },
    {
      name: 'position',
      options: [
        'meeting',
        'onYourBack',
        'onRightSide',
        'onLeftSide',
        'onYourStomach',
        'resistance',
      ],
      errors: '',
    },
    {
      name: 'interventionMethod',
      options: [
        'Joint compression',
        'Joint traction',
        'Weight bearing',
        'Tone inhibiting massage',
        'Tone facilitating massage',
        'Applying heat packs',
        'Applying cold packs',
      ],
      errors: '',
    },
  ],
  fields17: [
    {
      name: 'accessory',
      options: [
        'walker1',
        'points4',
        'points3',
        'regularStick',
        'manualWheelchair',
        'motorizedWheelchair',
        'withoutAccessories',
      ],
      errors: '',
    },
    {
      name: 'distance',
      type: 'input',
      fieldType: 'number',
      errors: '',
    },
    lastPracticeCategoryHelpLevelField,
  ],
  fields18: [
    {
      name: 'exercisePurpose',
      options: ['climbingStairs1', 'descendingStairs'],
      errors: '',
    },
    {
      name: 'knittingNeedles',
      type: 'input',
      fieldType: 'number',
      helperText: 'knittingHelperText',
      errors: '',
    },
    lastPracticeCategoryHelpLevelField,
  ],
  fields19: [
    {
      name: 'exercisePurpose',
      options: [
        'onRightFoot',
        'onLeftFoot',
        'onTwoLegs',
        'onNarrowBase',
        'onWideBase',
        'jumpRight',
        'jumpLeft',
        'jumpTwoLegs',
        'Side walking',
        'Reverse walking',
      ],
      errors: '',
    },
    {
      name: 'equipment',
      options: ['pita', 'motorRoute', 'stableArea'],
      errors: '',
    },
    lastPracticeCategoryHelpLevelField,
  ],
  fields20: [
    {
      name: 'exercise',
      options: [
        'rollingInBedRight',
        'rollingInBedLeft',
        'attractionToSitting',
        'sittingToLying1',
        'sittingToStanding1',
        'setsUpSitting',
        'positionsWalking',
        'walkingStanding1',
      ],
      errors: '',
    },
    lastPracticeCategoryHelpLevelField,
  ],
  fields21: [
    {
      name: 'cognitive',
      type: 'input',
      fieldType: 'text',
      errors: '',
    },
  ],
};

export const practiceSubCategoryFields: any = {
  eatingDrinkingCategory: practiceFields.fields1,
  dressed: practiceFields.fields2,
  bathing: practiceFields.fields3,
  toiletHygiene: practiceFields.fields4,
  personalHygiene: practiceFields.fields5,
  household: practiceFields.fields6,
  mealPreparationCategory: practiceFields.fields7,
  communityMobility: practiceFields.fields8,
  shopping: practiceFields.fields9,
  tripod: practiceFields.fields10,
  tipToTop: practiceFields.fields10,
  lateral: practiceFields.fields10,
  sphericalGrasp: practiceFields.fields10,
  cylindricalGrasp: practiceFields.fields10,
  manipulation: practiceFields.fields11,
  drawing: practiceFields.fields12,
  painting: practiceFields.fields12,
  writing: practiceFields.fields12,
  cutting: practiceFields.fields12,
  passiveActivation: practiceFields.fields13,
  activation: practiceFields.fields14,
  strengtheningMuscles: practiceFields.fields15,
  tone: practiceFields.fields16,
  mobility: practiceFields.fields17,
  stairs: practiceFields.fields18,
  equilibrium: practiceFields.fields19,
  transitionsCategory: practiceFields.fields20,
  memory: practiceFields.fields21,
  troubleshooting: practiceFields.fields21,
  identification: practiceFields.fields21,
  orientation1: practiceFields.fields21,
};
