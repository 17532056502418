import React, { useState, useMemo } from 'react';
import rtlPlugin from 'stylis-plugin-rtl';
import { CacheProvider, EmotionCache } from '@emotion/react';
import createCache from '@emotion/cache';
import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { theme } from './theme';
import globalStyle from './theme/globalStyles';
import GlobalStyles from '@mui/material/GlobalStyles';
import ScrollToTop from './components/ScrollToTop';
import Router from './routes';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { AbilityContext } from './casl/can';
import { buildAbilityFor } from './casl/ability';
import { userAtom } from './atoms/userAtom';
import { useRecoilValue } from 'recoil';
import './App.css';

const styleGlobal = <GlobalStyles styles={globalStyle(theme)} />;

const App: React.FC = () => {
  const user = useRecoilValue(userAtom);
  const [rtlCache, setRtlCache] = useState({} as EmotionCache);
  const ability = buildAbilityFor(user);

  useMemo(() => {
    const cacheRtl = createCache({
      key: 'rtl',
      stylisPlugins: [rtlPlugin],
    });

    setRtlCache(cacheRtl);
  }, []);

  return (
    <AbilityContext.Provider value={ability}>
      <CacheProvider value={rtlCache}>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <ScrollToTop />
          {styleGlobal}

          <Router />

          <ToastContainer
            position="top-left"
            autoClose={4000}
            hideProgressBar={false}
            newestOnTop
            closeOnClick
            rtl
            pauseOnFocusLoss
            draggable
            pauseOnHover
          />
        </ThemeProvider>
      </CacheProvider>
    </AbilityContext.Provider>
  );
};

export default App;
