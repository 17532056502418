import React from 'react';
import { useFormik, FormikProvider, FormikTouched, FormikErrors } from 'formik';
import { Grid, Box } from '@mui/material';
import { Button } from '@mui/material';
import { styled } from '@mui/system';
import { useTranslation } from 'react-i18next';
import { theme } from '../../theme';
import { IPatient } from '../../interfaces/Models';
import OverlaySpinner from '../OverlaySpinner';

const StyledDiv = styled('div')({
  display: 'flex',
  flex: 1,
  flexDirection: 'row',
  gap: theme.spacing(2),
  justifyContent: 'space-between',
  marginTop: theme.spacing(2),
  marginBottom: theme.spacing(1),
});

const StyledBox = styled(Box, {
  name: 'StyledBox',
  slot: 'Wrapper',
})({
  flexGrow: 1,
  paddingLeft: theme.spacing(4),
  paddingRight: theme.spacing(4),
});

export interface FormFieldsProps {
  touched: FormikTouched<any>;
  errors: FormikErrors<any>;
  formValues?: IPatient;
}

interface FormProps {
  submit: (values: any) => void;
  onCancel: Function;
  initialValues: any;
  validationSchema: any;
  cancelText?: string;
  submitText?: string;
  footerButtonFullWidth?: boolean;
}

const Form: React.FC<FormProps> = ({
  validationSchema,
  initialValues,
  submit,
  onCancel,
  cancelText,
  submitText,
  children,
  footerButtonFullWidth = false,
}) => {
  const { t } = useTranslation();

  const formik = useFormik({
    initialValues: initialValues as any,
    validationSchema: validationSchema,
    onSubmit: async (values: any, { setSubmitting }) => {
      await submit(values);
      setSubmitting(false);
    },
  });

  return (
    <FormikProvider value={formik}>
      <form onSubmit={formik.handleSubmit}>
        <StyledBox>
          <Grid container>
            {children}
            {formik.isSubmitting && <OverlaySpinner />}
            <StyledDiv>
              <Button
                fullWidth={footerButtonFullWidth}
                variant="contained"
                disabled={!(formik.isValid && formik.dirty)}
                size="large"
                type="submit"
                color="primary"
              >
                {submitText ? submitText : t('clinic.form.add')}
              </Button>
              <Button
                fullWidth={footerButtonFullWidth}
                variant="contained"
                size="large"
                onClick={() => onCancel()}
                color="error"
              >
                {cancelText ? cancelText : t('clinic.form.cancel')}
              </Button>
            </StyledDiv>
          </Grid>
        </StyledBox>
      </form>
    </FormikProvider>
  );
};

export default Form;
