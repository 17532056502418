export const getCurrentDifficulties = (t: Function) => {
  return [
    {
      label: t('report.HMKFIRST.currentDifficulties.hearing'),
      value: 'data.currentDifficulties.hearing',
    },

    {
      label: t('report.HMKFIRST.currentDifficulties.memory'),
      value: 'data.currentDifficulties.memory',
    },
    {
      label: `${t('report.HMKFIRST.currentDifficulties.speech.prefix')} ${t(
        'report.HMKFIRST.currentDifficulties.speech.understanding'
      )}`,
      value: 'data.currentDifficulties.speechUnderstanding',
    },
    {
      label: `${t('report.HMKFIRST.currentDifficulties.speech.prefix')} ${t(
        'report.HMKFIRST.currentDifficulties.speech.expressive'
      )}`,
      value: 'data.currentDifficulties.speechExpressive',
    },
  ];
};

export const getSwallowingDifficulties = (t: Function) => {
  return [
    {
      label: t('report.HMKFIRST.swallowing.swallowLevel'),
      value: 'data.swallowing.swallowLevel',
    },
    {
      label: t('report.HMKFIRST.swallowing.afterSwallow'),
      value: 'data.swallowing.afterSwallow',
    },
    {
      label: t('report.HMKFIRST.swallowing.ingestion'),
      value: 'data.swallowing.ingestion',
    },
    {
      label: t('report.HMKFIRST.swallowing.chewSwallow'),
      value: 'data.swallowing.chewSwallow',
    },
    {
      label: t('report.HMKFIRST.swallowing.voiceSwallow'),
      value: 'data.swallowing.voiceSwallow',
    },
    {
      label: t('report.HMKFIRST.swallowing.airSwallow'),
      value: 'data.swallowing.airSwallow',
    },
    {
      label: t('report.HMKFIRST.swallowing.difficulties'),
      value: 'data.swallowing.difficulties',
    },
    {
      label: t('report.HMKFIRST.swallowing.textureTest'),
      value: 'data.swallowing.textureTest',
    },
  ];
};
