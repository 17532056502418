import { Grid, Typography } from '@mui/material';
import React from 'react';
import { StyledGrid } from '../../../../styles/FormFieldsStyles';
import AssessmentField from './AssessmentField';

interface IAssessmentSection {
  sectionFields: string[];
  sectionName: string;
}
const AssessmentSection = ({ sectionFields, sectionName }: IAssessmentSection) => {
  return (
    <>
      <Typography variant="subtitle2" gutterBottom align="left">
        {sectionName}
      </Typography>
      {sectionFields.map((field: string) => {
        return (
          <Grid item xs={12} key={`${sectionName}_${field}`}>
            <StyledGrid container spacing={{ xs: 2, md: 2, lg: 2 }}>
              <Grid item xs={12} md={6} lg={6}>
                <AssessmentField id={sectionName} field={field} />
              </Grid>
              <Grid item xs={12} md={6} lg={6}>
                <AssessmentField id={sectionName} field={`${field}Note`} />
              </Grid>
            </StyledGrid>
          </Grid>
        );
      })}
    </>
  );
};

export default AssessmentSection;
