import React from 'react';
import { Row } from 'react-table';
import { ICustomRow } from './Table';
import { ITableBaseSchema } from '../interfaces/table';
import { Box, Collapse, TableCell, TableRow } from '@mui/material';
import { theme } from '../theme';

interface ExpandTableRowProps<T> {
  numberOfColumns: number;
  row: Row<any>;
  ExpandCard: React.FC<ICustomRow<T>>;
  isExpanded: boolean;
}
export default function ExpandTableRow<T extends ITableBaseSchema>({
  isExpanded,
  ExpandCard,
  numberOfColumns,
  row,
}: ExpandTableRowProps<T>) {
  const paddingVertical = isExpanded ? 2 : 0;
  return (
    <TableRow>
      <TableCell sx={{ border: '0px', py: paddingVertical }} colSpan={numberOfColumns + 1}>
        <Collapse in={isExpanded} timeout="auto" unmountOnExit>
          <Box
            sx={{
              width: '100%',
              backgroundColor: theme.palette.grey[200],
            }}
          >
            <ExpandCard row={row.original} />
          </Box>
        </Collapse>
      </TableCell>
    </TableRow>
  );
}
