/* eslint-disable new-cap */
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSetRecoilState } from 'recoil';
import { userAtom } from '../../../atoms/userAtom';
import {
  Box,
  Card,
  CardHeader,
  Grid,
  Stack,
  Switch,
  FormControlLabel,
  FormGroup,
} from '@mui/material';
import * as Yup from 'yup';
import { LoadingButton } from '@mui/lab';
import { IUser } from '../../../interfaces/Models';
import UserService from '../../../services/UserService';
import FetcherService from '../../../hooks/FetcherService';
import ProfileFields from './ProfileFields';
import { Form, Formik } from 'formik';
import ProfileAbout from './ProfileAbout';
import { toast } from 'react-toastify';

interface IProfileInfo {
  user: IUser | null;
}
const Profile = ({ user }: IProfileInfo) => {
  const { t } = useTranslation();
  const [isFieldsDisabled, setIsFieldsDisabled] = useState<boolean>(false);
  const setUser = useSetRecoilState(userAtom);

  const enableProfileEdit = () => {
    setIsFieldsDisabled(!isFieldsDisabled);
  };
  const userSchema = Yup.object({
    visitRate: Yup.number().integer().default(0).required(t('clinic.form.required')),
    personalDetails: Yup.object().shape({
      firstName: Yup.string().required(t('clinic.form.required')),
      lastName: Yup.string().required(t('clinic.form.required')),
      primaryPhone: Yup.string().required(t('clinic.form.required')),
    }),
  });

  const handleUpdateUser = async (
    record: IUser,
    setSubmitting: (value: boolean) => void,
    resetForm: (value: Object) => void
  ): Promise<void> => {
    try {
      const data: IUser = await FetcherService<IUser>({
        service: UserService.edit(user?._id || 'empty', {
          ...record,
          idNumber: record.idNumber.toString(),
        }),
      });
      setUser(data);
      setSubmitting(false);
      resetForm({ values: data });
      toast.success(t('global.profile.profileChangeSuccess'));
      setIsFieldsDisabled(false);
    } catch {
      setSubmitting(false);
      toast.error(t('global.errors.failedProfileInfoUpdate'));
    }
  };

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} md={4}>
        <ProfileAbout user={user} />
      </Grid>
      <Grid item xs={12} md={8}>
        <Card sx={{ p: 3, pt: 0 }}>
          <Stack justifyContent="space-between" direction="row" sx={{ mb: 2 }}>
            <CardHeader title={t('global.profile.basicInfo')} sx={{ top: 0, bottom: 0 }} />
            <FormGroup>
              <FormControlLabel
                sx={{ pt: 3 }}
                control={<Switch checked={isFieldsDisabled} onChange={enableProfileEdit} />}
                label={`${t('global.profile.enableEdit')}`}
              />
            </FormGroup>
          </Stack>
          {user && (
            <Formik
              initialValues={{ ...user }}
              onSubmit={(values: any, { setSubmitting, resetForm }) => {
                handleUpdateUser(values, setSubmitting, resetForm);
              }}
              validationSchema={userSchema}
            >
              {({ isSubmitting, dirty }) => {
                return (
                  <Form>
                    <Box
                      sx={{
                        display: 'grid',
                        rowGap: 3,
                        columnGap: 2,
                        gridTemplateColumns: { xs: 'repeat(1, 1fr)', sm: 'repeat(2, 1fr)' },
                      }}
                    >
                      <ProfileFields isFieldsDisabled={isFieldsDisabled} />
                    </Box>
                    {isFieldsDisabled && dirty ? (
                      <Stack direction="row" justifyContent="flex-end">
                        <LoadingButton
                          size="medium"
                          type="submit"
                          variant="contained"
                          loading={isSubmitting}
                        >
                          {t('global.profile.saveChanges')}
                        </LoadingButton>
                      </Stack>
                    ) : null}
                  </Form>
                );
              }}
            </Formik>
          )}
        </Card>
      </Grid>
    </Grid>
  );
};

export default Profile;
