export const HMPFirstValues = {
  goals: '',
  functionalState: {
    collaborator: '',
    vigilance: '',
    position: '',
    ability: '',
  },
  physicalCondition: {
    upperLimb: {
      right: '',
      left: '',
    },
    muscle: {
      right: '',
      left: '',
    },
    functionalAbility: '',
    motion: {
      upperLimb: {
        right: '',
        rightNote: '',
        left: '',
        leftNote: '',
      },
      lowerLimb: {
        right: '',
        rightNote: '',
        left: '',
        leftNote: '',
      },
    },
    equilibrium: '',
    equilibriumNote: '',
    pain: {
      placeOfPain: '',
      VAS: '',
    },
    generalLook: '',
    transitions: {
      sittingToLying: '',
      TransitionSittingToLying: '',
      sittingToStanding: '',
      movingSittingToStanding: '',
    },
    walk: {
      howToWalk: '',
      howToWalkNote: '',
      accessories: '',
      accessoriesNote: '',
      support: '',
      supportNote: '',
      climbing: '',
      climbingNote: '',
      descending: '',
      descendingNote: '',
      descriptionWalking: '',
    },
    difficultiesOfTreatment: '',
    status: '',
    recommendation: '',
  },
};
