/* eslint-disable new-cap */
import { Card, Container, Grid } from '@mui/material';
import React, { useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Row } from 'react-table';
import { getPatientNameColumn } from '../../common/columns/MyVisitsColumns';
import { getEndDateColumn, getProgramTitleColumn } from '../../common/columns/Programs';
import ErrorMessage from '../../components/ErrorMessage';
import Label from '../../components/Label';
import OverlaySpinner from '../../components/OverlaySpinner';
import Table from '../../components/Table';
import useService from '../../hooks/HookService';
import useSearchFilter from '../../hooks/useSearchFilter';
import {
  IPatientProgram,
  IPatientProgramFlat,
  IPositionReleaseReport,
  IProgramReleaseReport,
} from '../../interfaces/Models';
import PatientProgramService from '../../services/PatientProgramService';
import {
  getPageNumberFromUrlParam,
  getStartEndDateDep,
  handleTableAtomChange,
  onDownloadPdf,
} from '../../utils/Helper';
import { dateRangeFilter, getClinicFilter, searchFilter } from './ProgramsTable';
import { theme } from '../../theme';
import {
  BIG_TABLE_SIZES,
  CLINIC_LIST,
  FILTERS,
  PROGRAM_RELEASE_STATUS_COLOR,
  URL_QUERY_PARAMS,
} from '../../common/Constants';
import AutoCompleteFilter from '../../components/AutoCompleteFilter';
import { ApiResponse } from '../../interfaces/ApiResponseType';
import { toast } from 'react-toastify';
import FetcherService from '../../hooks/FetcherService';
import ReportService from '../../services/ReportService';
import ProgramsActions from './ProgramsActions';
import ProgramsMoreOptions from './ProgramsMoreOptions';
import Page from '../../components/Page';
import PageHeader from '../../components/PageHeader';
import useAccess from '../../hooks/useAccess';
import { AbilitySubjects } from '../../casl/ability';
import ProgramsReleaseReportsHeader from './ProgramsReleaseReportsHeader';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { LEUMIT_PROGRAM_NAMES_MAPPING, MEUHEDET_PROGRAM_MAPPING } from '../../common/Program';
import ClinicNameLabel from '../../components/ClinicNameLabel';

const ReleaseReportsTable = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  useAccess('read', AbilitySubjects.ALL);
  const location = useLocation();

  const { pageInfo } = useSearchFilter();
  const [shouldTableUpdate, setShouldTableUpdate] = useState<number>(0);
  const [record, setRecord] = useState<
    IProgramReleaseReport | IPatientProgram | IPatientProgramFlat
  >();
  const [openEditReleaseStatusModal, setOpenEditReleaseStatusModal] = useState<boolean>(false);
  const [openCompleteProgramModal, setOpenCompleteProgramModal] = useState(false);
  const [openAddHospitalDaysModal, setOpenAddHospitalDaysModal] = useState<boolean>(false);
  const [isLoadingReport, setIsLoadingReport] = useState(false);

  const {
    page,
    limit = 100,
    search,
    from,
    to,
    range,
    sort = 'endDate',
    title,
    clinic,
    month,
    year,
  } = pageInfo;
  const isHospis = location.pathname === '/app/release-reports/hospis';

  const { isLoading, data, error, additionalData, loadingNumber, errorMessage } = useService<
    IProgramReleaseReport[]
  >({
    service: PatientProgramService.getPatientsReleaseReports({
      page: getPageNumberFromUrlParam(page),
      limit: limit,
      search: search,
      programTitle: title,
      fromDate: getStartEndDateDep(pageInfo),
      toDate: getStartEndDateDep(pageInfo, true),
      sort: sort,
      isHospis,
      clinicName: clinic,
    }),
    deps: [
      page,
      limit,
      clinic,
      shouldTableUpdate,
      from,
      to,
      search,
      sort,
      range,
      title,
      isHospis,
      month,
      year,
    ],
  });

  const onOpenEditReleaseStatusModal = (
    value: IProgramReleaseReport | IPatientProgram | IPatientProgramFlat
  ) => {
    setRecord(value);
    setOpenEditReleaseStatusModal(true);
  };

  const handleModalClose = () => {
    setRecord(undefined);
    setOpenEditReleaseStatusModal(false);
    setOpenCompleteProgramModal(false);
    setOpenAddHospitalDaysModal(false);
  };

  const onDownloadGlobalReleaseReport = async (
    report: IProgramReleaseReport | IPatientProgram | IPatientProgramFlat,
    reportName?: string
  ) => {
    handleTableAtomChange('releaseReport', report._id);
    const onSuccess = (res: ApiResponse<BlobPart>) => {
      onDownloadPdf(res, reportName || '');
    };
    const onFailed = () => {
      toast.error(t('global.errors.report'));
    };
    await FetcherService<any>({
      service: ReportService.generateGlobalReleaseReportPdf(report._id || 'empty', {
        reportName: reportName,
      }),
      onSuccess: onSuccess,
      onFailed: onFailed,
      setLoading: setIsLoadingReport,
    });
  };

  const handleTableUpdate = () => {
    setShouldTableUpdate((pre) => pre + 1);
  };

  const handelOpenCompleteProgramModal = (
    report: IProgramReleaseReport | IPatientProgram | IPatientProgramFlat
  ) => {
    setRecord(report);
    setOpenCompleteProgramModal(true);
  };

  const handleOpenAddHospitalDaysForm = (
    report: IProgramReleaseReport | IPatientProgram | IPatientProgramFlat
  ) => {
    setRecord(report);
    setOpenAddHospitalDaysModal(true);
  };

  const columns = [
    getPatientNameColumn(sort),
    {
      Header: t('program.releaseReports.clinicName'),
      accessor: (val: any) => {
        return <ClinicNameLabel clinicName={val.clinicName} />;
      },
      noExcelSupport: true,
    },
    getProgramTitleColumn(),
    getEndDateColumn(sort),
    {
      Header: t('program.releaseReports.releaseStatus'),
      accessor: (report: any) => {
        return (
          <Label
            variant="ghost"
            color={
              report.patientReleaseStatus
                ? PROGRAM_RELEASE_STATUS_COLOR[report.patientReleaseStatus]
                : 'default'
            }
          >
            {t(
              `program.releaseReports.releaseStatusValues.${
                report.patientReleaseStatus || 'NOT_DECIDED'
              }`
            )}
          </Label>
        );
      },
      noExcelSupport: true,
    },
    {
      id: 'positionsReleaseReports',
      Header: t('program.releaseReports.releaseReportsStatus'),
      headerPosition: 'center',
      accessor: (report: any) => {
        return (
          <Grid container sx={{ width: theme.spacing(50) }} spacing={1}>
            {report.positionsReleaseReports.map((positionReport: IPositionReleaseReport) => {
              return (
                <Grid item xs={6}>
                  <Label variant="ghost" color={positionReport.isVisitDone ? 'success' : 'error'}>
                    {`${t(`global.releasePositions.${positionReport.position.slice(0, 3)}`)} - ${
                      positionReport.employeeName
                    }`}
                  </Label>
                </Grid>
              );
            })}
          </Grid>
        );
      },
      noExcelSupport: true,
    },
    {
      Header: t('clinic.table.actions'),
      accessor: (report: any) => {
        const isProgramDone = report.isProgramDone;
        return (
          <ProgramsMoreOptions
            program={report}
            tableSessionId="releaseReport"
            handleOpenEditReleaseStatusModal={onOpenEditReleaseStatusModal}
            handleCompleteProgram={handelOpenCompleteProgramModal}
            handleOpenAddHospitalDaysForm={handleOpenAddHospitalDaysForm}
            onDownloadGlobalReleaseReport={onDownloadGlobalReleaseReport}
            isProgramDone={isProgramDone}
          />
        );
      },
      noExcelSupport: true,
    },
  ];

  const programsFilterTypes = useMemo(() => {
    const { HOSPITALIZATION, HALF_PACKAGE_HOSPITALIZATION, ...MEUHEDET_PROGRAM_MAPPING_REST } =
      MEUHEDET_PROGRAM_MAPPING;
    const { HOSPITALIZATION: HOSPITALIZATION1, ...LEUMIT_PROGRAM_NAMES_MAPPING_REST } =
      LEUMIT_PROGRAM_NAMES_MAPPING;

    const programsSetValues = new Set<string>([
      ...(clinic === CLINIC_LIST.MEUHEDET || !clinic
        ? Object.values(MEUHEDET_PROGRAM_MAPPING_REST)
        : []),
      ...(clinic === CLINIC_LIST.LEUMIT || !clinic
        ? Object.values(LEUMIT_PROGRAM_NAMES_MAPPING_REST)
        : []),
    ]).values();

    const programsTypes = Array.from(programsSetValues).sort((a, b) => a.localeCompare(b));
    return isHospis ? [HOSPITALIZATION, HALF_PACKAGE_HOSPITALIZATION] : programsTypes;
  }, [clinic, isHospis]);

  const tableFilters = [
    searchFilter,
    dateRangeFilter,
    {
      filterName: FILTERS.TITLE_FILTER,
      component: (
        <AutoCompleteFilter
          options={programsFilterTypes}
          label={t('patient.form.title')}
          urlParam={URL_QUERY_PARAMS.TITLE}
          width={310}
        />
      ),
    },
    getClinicFilter(),
  ];

  const onRowClick = (row: Row<IProgramReleaseReport>) => {
    handleTableAtomChange('releaseReport', row.original._id);
    navigate(`/app/patient-program/${row.original._id}`);
  };

  if (error) {
    return <ErrorMessage error={errorMessage} />;
  }

  if (isLoading && loadingNumber == 1) {
    return <OverlaySpinner />;
  }
  const header = t('program.releaseReports.header');

  return (
    <Page title={header}>
      <Container>
        <PageHeader
          pageTitle={header}
          pageFilter={<ProgramsReleaseReportsHeader columns={columns} reports={data} />}
          smallScreenDirection="row"
        />
        <ProgramsActions
          record={record}
          handelClosingModals={handleModalClose}
          handleTableUpdate={handleTableUpdate}
          openAddHospitalDaysModal={openAddHospitalDaysModal}
          openCompleteProgramModal={openCompleteProgramModal}
          editReleaseReport={openEditReleaseStatusModal}
          tableSessionId="releaseReport"
        />
        <Card>
          <Table
            columns={columns}
            data={data || []}
            count={additionalData.total || 0}
            pageSizes={BIG_TABLE_SIZES}
            pageInfo={{
              page: getPageNumberFromUrlParam(page),
              pageSize: Number(limit),
            }}
            isLoading={isLoading}
            filters={tableFilters}
            onRowClick={onRowClick}
          />
        </Card>
        {isLoadingReport && <OverlaySpinner />}
      </Container>
    </Page>
  );
};

export default ReleaseReportsTable;
