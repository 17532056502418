import { FormControl, MenuItem, SelectChangeEvent } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { RESET_PAGE_QUERY } from '../common/Constants';
import useSearchFilter from '../hooks/useSearchFilter';
import { SelectFilterInput } from '../styles/DateRangeFilterStyles';
import { theme } from '../theme';

interface IDropDownFilter {
  values: string[];
  keys: string[];
  defaultValue: string;
  urlParam: string;
  color?: string;
  resetPageAfterFilter?: boolean;
}
const DropDownFilter = ({
  values,
  keys,
  defaultValue,
  urlParam,
  color,
  resetPageAfterFilter = true,
}: IDropDownFilter) => {
  const resetQuery = resetPageAfterFilter ? RESET_PAGE_QUERY : {};

  const { changeQueryParams, pageInfo } = useSearchFilter();
  const [filterValue, setFilterValue] = useState<string>(
    pageInfo[urlParam] && values.indexOf(pageInfo[urlParam]) > -1
      ? pageInfo[urlParam]
      : defaultValue
  );

  useEffect(() => {
    setFilterValue(
      pageInfo[urlParam] && values.indexOf(pageInfo[urlParam]) > -1
        ? pageInfo[urlParam]
        : defaultValue
    );
  }, [pageInfo[urlParam]]);

  const onChangeFilter = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | SelectChangeEvent<string>
  ) => {
    setFilterValue(event.target.value);
    changeQueryParams([resetQuery, { name: urlParam, value: event.target.value }]);
  };
  return (
    <FormControl
      sx={{
        width: '100%',
      }}
    >
      <SelectFilterInput
        sx={{ width: '100%', backgroundColor: color || theme.palette.secondary.light }}
        value={filterValue}
        onChange={onChangeFilter}
      >
        {values.map((value: string, idx) => {
          return (
            <MenuItem key={value} value={value}>
              {keys[idx]}
            </MenuItem>
          );
        })}
      </SelectFilterInput>
    </FormControl>
  );
};

export default DropDownFilter;
