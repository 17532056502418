import { MenuItem, Select } from '@mui/material';
import { styled } from '@mui/system';
import Iconify from '../components/Iconify';
import { theme } from '../theme';

export const TotalTableRow = styled(MenuItem)({
  backgroundColor: theme.palette.primary.lighter,
});

export const SelectFilterInput = styled((props: any) => <Select size="small" {...props} />)(
  ({ theme }) => ({
    backgroundColor: theme.palette.primary.main,
    color: 'white',
  })
);

export const StyledDateIcon = styled(Iconify)(({ theme }) => ({
  width: 18,
  height: 18,
  flexShrink: 0,
  marginLeft: theme.spacing(0.7),
  marginTop: theme.spacing(0.25),
}));

export const FlexDiv = styled('div')({
  display: 'flex',
});

export const FlexDiv1 = styled('div')({
  direction: 'rtl',
  display: 'flex',
  justifyContent: 'flex-start',
  width: '100%',
  [theme.breakpoints.down('sm')]: {
    marginTop: theme.spacing(1),
    justifyContent: 'flex-end',
  },
});

export const FlexDiv2 = styled('div')({
  display: 'flex',
  marginRight: theme.spacing(1),
});

export const FlexDiv3 = styled('div')({
  display: 'flex',
  [theme.breakpoints.down('sm')]: {
    marginTop: theme.spacing(1),
  },
});
