import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Typography } from '@mui/material';
import GenericField from './GenericField';
import { IField } from '../../../common/HMA';
import SectionDivider from '../../../components/SectionDivider';
import { sumValues } from '../../../utils/Helper';

interface FieldsWithSummationProps {
  id: string;
  values: Object;
  onChangeFormikValue: (field: string, value: any, shouldValidate?: boolean) => void;
  header?: string;
  subHeader?: string;
  sumValueField: string;
  fields: IField[];
}
const FieldsWithSummation = ({
  id,
  values,
  onChangeFormikValue,
  subHeader,
  header,
  sumValueField,
  fields,
}: FieldsWithSummationProps) => {
  const { t } = useTranslation();
  const [sum, setnSum] = useState<number>(0);

  useEffect(() => {
    setnSum(sumValues(values));
    onChangeFormikValue(sumValueField, sumValues(values));
  }, [values]);

  return (
    <div id={id}>
      <GenericField id={`${id}_Fields`} fields={fields} subHeader={subHeader} header={header} />
      <Typography variant="subtitle1" gutterBottom>
        {t('report.HMAFIRST.summary')} [{sum}]
      </Typography>

      <SectionDivider />
    </div>
  );
};

export default FieldsWithSummation;
