import { setRecoil } from 'recoil-nexus';
import { userAtom } from '../atoms/userAtom';

interface IHTTP_METHODS {
  [key: string]: 'get' | 'post' | 'put' | 'delete';
}
export const HTTP_METHODS: IHTTP_METHODS = {
  GET: 'get',
  POST: 'post',
  PUT: 'put',
  DELETE: 'delete',
};

export const getBaseUrl = () => {
  const location = window.location.href;
  const indices = [];
  for (let i = 0; i < location.length; i++) {
    if (location[i] === '/') indices.push(i);
  }
  return location.slice(0, indices[2]);
};

export const logOut = async () => {
  setRecoil(userAtom, null);
  await localStorage.removeItem('authToken');
  const baseUrl = getBaseUrl();
  window.location.href = `${baseUrl}/login`;
};
