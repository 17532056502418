import React from 'react';
import { receiptFields } from '../../../../common/HMPGeneral';
import GenericField from '../../sharedFields/GenericField';
import { useTranslation } from 'react-i18next';

interface HMPGeneralFieldsProps {
  id: string;
}
const HMPGeneralFields = ({ id }: HMPGeneralFieldsProps) => {
  const { t } = useTranslation();

  return (
    <GenericField
      id={`${id}_Receipt`}
      subHeader={t('report.HMPGENERAL.receipt.header')}
      fields={receiptFields}
    />
  );
};

export default HMPGeneralFields;
