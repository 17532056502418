import React, { useMemo } from 'react';
import { Select } from 'formik-mui';
import { IconButton } from '@mui/material';
import { FastField, useFormikContext, getIn } from 'formik';
import { FlexDiv } from '../styles/DateRangeFilterStyles';
import { Icon } from '@iconify/react';

interface IFormikSelect {
  id?: string;
  label: string;
  name: string;
  formControl: Object;
  formHelperText?: Object;
  isClearable?: boolean;
}
const FormikSelect: React.FC<IFormikSelect> = ({
  id,
  label,
  name,
  formControl,
  formHelperText,
  children,
  isClearable = true,
}) => {
  const { values, setFieldValue } = useFormikContext();

  const handleClearSelection = () => {
    setFieldValue(name, ' ');
  };
  const isNotEmpty1 = useMemo(
    () => (typeof getIn(values, name) == 'string' ? getIn(values, name).length > 1 : false),
    [getIn(values, name)]
  );

  /* @ts-ignore */
  return (
    <FastField
      id={id}
      component={Select}
      name={name}
      label={label}
      formControl={{ sx: formControl }}
      formHelperText={formHelperText || ''}
      IconComponent={
        isClearable
          ? () => (
              <FlexDiv sx={{ paddingRight: 1.3 }}>
                {isNotEmpty1 && (
                  <IconButton
                    edge="end"
                    aria-label="delete"
                    onClick={(e) => handleClearSelection()}
                  >
                    <Icon icon="iconoir:cancel" />
                  </IconButton>
                )}
              </FlexDiv>
            )
          : undefined
      }
    >
      {children}
    </FastField>
  );
};

export default FormikSelect;
