import React from 'react';
import { ITableBaseSchema } from '../../../interfaces/table';
import Table, { ICustomRow } from '../../../components/Table';
import { IVisitsDelayFinancialData } from '../../../interfaces/Reports';
import { useTranslation } from 'react-i18next';

export default function EmployeeVisitsDelayCalculationReportTableExpandCard<
  T extends ITableBaseSchema
>({ row }: ICustomRow<T>) {
  const { t } = useTranslation();
  const financialData: IVisitsDelayFinancialData[] = row.financialData || [];

  const columns = [
    {
      Header: `${t('financialReports.visitsDelayCalculation.delayInDays')}`,
      accessor: 'delayInDays',
    },
    {
      id: 'employeeName',
      Header: `${t('financialReports.visitsDelayCalculation.visitsCount')}`,
      accessor: 'visitsCount',
    },
    {
      Header: `${t('financialReports.visitsDelayCalculation.daysOfPenalty')}`,
      accessor: 'daysOfPenalty',
    },
    {
      Header: `${t('financialReports.visitsDelayCalculation.originalVisitRate')}`,
      accessor: 'originalVisitRate',
    },
    {
      Header: `${t('financialReports.visitsDelayCalculation.visitFixedRate')}`,
      accessor: 'fixedVisitRate',
    },
    {
      Header: `${t('financialReports.visitsDelayCalculation.totalOriginVisitsCost')}`,
      accessor: 'totalOriginalAmount',
    },
    {
      Header: `${t('financialReports.visitsDelayCalculation.totalFixedVisitsCost')}`,
      accessor: 'totalFixedAmount',
    },
  ];

  return (
    <Table
      columns={columns}
      data={financialData}
      count={financialData.length}
      isLoading={false}
      showPagination={false}
    />
  );
}
