import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Row } from 'react-table';
import {
  BIG_TABLE_SIZES,
  CLINIC_LIST,
  PATIENT_PROGRAM_STATUS,
  PROGRAM_PAYMENT_MODE,
  VAT,
} from '../../common/Constants';
import ErrorMessage from '../../components/ErrorMessage';
import OverlaySpinner from '../../components/OverlaySpinner';
import Table from '../../components/Table';
import useService from '../../hooks/HookService';
import useSearchFilter from '../../hooks/useSearchFilter';
import ReportsService from '../../services/FinancialReportsService';
import {
  getPageNumberFromUrlParam,
  getSortDirection,
  getStartEndDateDep,
  handleTableAtomChange,
} from '../../utils/Helper';
import { IProgramFinancialReport } from '../../interfaces/Reports';
import Page from '../../components/Page';
import { Card, Container } from '@mui/material';
import PageHeader from '../../components/PageHeader';
import ClinicsReportsFilter from './ClinicsReportsFilter';
import ClinicsFinancialReports from './ClinicsFinancialReports';
import { ReportData } from '../../interfaces/Models';
import StatisticsService from '../../services/StatisticsService';
import PaymentLevelLabel from '../../components/PaymentLevelLabel';
import useAccess from '../../hooks/useAccess';
import { AbilitySubjects } from '../../casl/ability';

const ProgramsReportsTable = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  useAccess('read', AbilitySubjects.VIEW_FINANCIAL_REPORTS);

  const { pageInfo } = useSearchFilter();
  const {
    page,
    limit = 100,
    from,
    to,
    range,
    status,
    title,
    sort = 'fullName',
    clinic,
    month,
    year,
  } = pageInfo;

  const { isLoading, data, additionalData, loadingNumber, error, errorMessage } = useService<
    IProgramFinancialReport[]
  >({
    service: ReportsService.getProgramsFinancialReports({
      page: getPageNumberFromUrlParam(page),
      limit: limit,
      fromDate: getStartEndDateDep(pageInfo),
      toDate: getStartEndDateDep(pageInfo, true),
      status:
        status && Object.values(PATIENT_PROGRAM_STATUS).indexOf(status) > -1
          ? status
          : `${PATIENT_PROGRAM_STATUS.DONE},${PATIENT_PROGRAM_STATUS.ACTIVE}`,
      programTitle: title,
      search: clinic && Object.values(CLINIC_LIST).indexOf(clinic) > -1 ? clinic : undefined,
      sort: sort,
    }),
    deps: [from, to, range, status, title, page, limit, sort, clinic, month, year],
  });

  const clinicsStatistics = useService<ReportData>({
    service: StatisticsService.getClinicsFinancialReports({
      fromDate: getStartEndDateDep(pageInfo),
      toDate: getStartEndDateDep(pageInfo, true),
      status:
        status && Object.values(PATIENT_PROGRAM_STATUS).indexOf(status) > -1
          ? status
          : `${PATIENT_PROGRAM_STATUS.DONE},${PATIENT_PROGRAM_STATUS.ACTIVE}`,
      programTitle: title,
      search: clinic && Object.values(CLINIC_LIST).indexOf(clinic) > -1 ? clinic : undefined,
    }),
    deps: [from, to, range, status, title, clinic, month, year],
  });

  const onRowClick = (row: Row<any>) => {
    handleTableAtomChange('programReport', row.original._id);
    navigate(`/app/patient-program/${row.original._id}`);
  };

  const columns = [
    {
      id: 'fullName',
      Header: t('financialReports.programs.fullName'),
      accessor: 'fullName',
      sortType: 'basic',
      sortDirection: getSortDirection(sort || '', 'fullName'),
    },
    {
      Header: t('patient.form.passportIdNumber'),
      accessor: 'idNumber',
    },
    {
      Header: `${t('patient.form.title')}`,
      accessor: 'title',
    },
    {
      Header: `${t('clinic.table.clinicName')}`,
      accessor: 'clinicName',
    },
    {
      Header: `${t('program.paymentMode')}`,
      accessor: (val: any) => <PaymentLevelLabel paymentMode={val.paymentMode} />,
    },
    {
      Header: `${t('financialReports.totalVisits')}`,
      accessor: 'totalVisits',
    },
    {
      Header: `${t('financialReports.programs.totalDays')}`,
      accessor: 'totalProgramDays',
    },
    {
      Header: `${t('financialReports.programs.totalHospitalDays')}`,
      accessor: 'totalHospitalDays',
    },
    {
      Header: `${t('financialReports.programs.netDays')}`,
      accessor: 'netDays',
    },
    {
      Header: `${t('financialReports.programs.dayPrice')}`,
      accessor: 'dayPrice',
    },
    {
      id: 'price',
      Header: `${t('financialReports.programs.price')}`,
      accessor: (report: any) => report.price.toFixed(2),
      sortType: 'basic',
      sortDirection: getSortDirection(sort || '', 'price'),
    },
    {
      id: 'priceWithVAT',
      Header: `${t('financialReports.programs.priceVAT')}`,
      accessor: (report: any) => (report.price * VAT).toFixed(2),
      sortType: 'basic',
      sortDirection: getSortDirection(sort || '', 'priceWithVAT'),
    },
  ];

  if (error || clinicsStatistics.error) {
    return <ErrorMessage error={errorMessage || clinicsStatistics.errorMessage} />;
  }
  if (isLoading && loadingNumber == 1) {
    return <OverlaySpinner />;
  }

  return (
    <Page title={t('financialReports.programs.header')}>
      <Container>
        <PageHeader
          pageTitle={t('financialReports.programs.header')}
          pageFilter={<ClinicsReportsFilter reports={data} tableColumns={columns} />}
          smallScreenDirection="column"
        />
        <ClinicsFinancialReports
          data={clinicsStatistics.data}
          isLoading={clinicsStatistics.isLoading}
        />
        <Card>
          <Table
            columns={columns}
            data={data || []}
            count={additionalData?.total || 0}
            pageSizes={BIG_TABLE_SIZES}
            isLoading={isLoading}
            pageInfo={{
              page: getPageNumberFromUrlParam(page),
              pageSize: Number(limit),
            }}
            onRowClick={onRowClick}
          />
        </Card>
      </Container>
    </Page>
  );
};

export default ProgramsReportsTable;
