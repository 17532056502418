import { Grid, MenuItem, Typography } from '@mui/material';
import React from 'react';
import { styled, SxProps } from '@mui/system';
import { theme } from '../../../../theme';
import { FastField as Field } from 'formik';
import { useTranslation } from 'react-i18next';
import { TextField } from 'formik-mui';
import FormikSelect from '../../../../components/FormikSelect';

const StyledTextField = styled(TextField)({
  marginBottom: theme.spacing(2),
});

const sxFormControl: SxProps = {
  width: '100%',
};

interface IField {
  name: string;
  label: string;
}

interface OneSelectNoteProps {
  header?: string;
  subHeader?: string;
  options: string[];
  fields: IField[];
  id: string;
}
const SelectNote = ({ header, subHeader, options, fields, id }: OneSelectNoteProps) => {
  const { t } = useTranslation();

  return (
    <div id={id}>
      {header && (
        <Typography variant="subtitle1" gutterBottom align="left">
          {header}
        </Typography>
      )}
      {subHeader && (
        <Typography variant="subtitle2" gutterBottom align="left">
          {subHeader}
        </Typography>
      )}
      {fields.map((field) => {
        return (
          <Grid
            id={`${id}_Container`}
            container
            spacing={{ xs: 2, md: 4, lg: 4 }}
            key={field.name + field.label}
          >
            <Grid item xs={12} md={6} lg={6}>
              <FormikSelect
                id={`${id}_SelectField_${field.name}`}
                name={field.name}
                label={field.label}
                formControl={sxFormControl}
              >
                {options.map((option: string) => {
                  return (
                    <MenuItem key={option} value={option}>
                      {t(`global.form.select.${option}`)}
                    </MenuItem>
                  );
                })}
              </FormikSelect>
            </Grid>
            <Grid item xs={12} md={6} lg={6}>
              <Field
                id={`${id}_TextField_${field.name}`}
                fullWidth
                component={StyledTextField}
                type="text"
                name={`${field.name}Notes`}
                label={`${t(`${field.label}`)} ${t('report.HMKFIRST.remarks')}`}
              />
            </Grid>
          </Grid>
        );
      })}
    </div>
  );
};

export default SelectNote;
