import React, { useEffect, useMemo } from 'react';
import SelectInput from '../../../components/SelectInput';
import { useTranslation } from 'react-i18next';
import { PROGRAM_PAYMENT_MODE } from '../../../common/Constants';
import { IOption } from '../../../common/HMA';
import { Grid, MenuItem, TextField } from '@mui/material';

interface ProgramPaymentModeFieldProps {
  paymentMode?: string;
  clinicReferenceNumber?: string;
  handleChangePaymentMode: (value: string) => void;
  handleChangeClinicReferenceNumber: (value?: string) => void;
  isLeumitPatient: boolean;
}
export default function ProgramPaymentModeField({
  paymentMode,
  clinicReferenceNumber,
  handleChangePaymentMode,
  handleChangeClinicReferenceNumber,
  isLeumitPatient = false,
}: ProgramPaymentModeFieldProps) {
  const { t } = useTranslation();

  const isPaymentProgramLevel = useMemo(
    () => paymentMode === PROGRAM_PAYMENT_MODE.PROGRAM_LEVEL,
    [paymentMode]
  );

  useEffect(() => {
    if (!isPaymentProgramLevel) {
      handleChangeClinicReferenceNumber(undefined);
    }
  }, [isPaymentProgramLevel]);

  const paymentModeOptions = [
    {
      label: t('program.paymentModeOptions.PROGRAM_LEVEL'),
      value: PROGRAM_PAYMENT_MODE.PROGRAM_LEVEL,
    },
    {
      label: t('program.paymentModeOptions.VISIT_LEVEL'),
      value: PROGRAM_PAYMENT_MODE.VISIT_LEVEL,
    },
    {
      label: t('program.paymentModeOptions.FIXED_PRICE'),
      value: PROGRAM_PAYMENT_MODE.FIXED_PRICE,
    },
  ];
  return (
    <>
      <Grid sx={{ mb: 1 }} item xs={11} md={11} lg={11}>
        <SelectInput
          value={paymentMode || ''}
          label={t('program.paymentMode')}
          onChangeValue={handleChangePaymentMode}
        >
          {paymentModeOptions.map((option: IOption, idx: number) => {
            return (
              <MenuItem key={`${idx}_${option.value}`} value={option.value}>
                {option.label}
              </MenuItem>
            );
          })}
        </SelectInput>
      </Grid>
    </>
  );
}
