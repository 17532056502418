import React from 'react';
import SectionDivider from '../../../../components/SectionDivider';
import { useTranslation } from 'react-i18next';
import GenericField from '../../sharedFields/GenericField';

interface HMDRepeatedFieldsProps {
  id: string;
}
const HMDRepeatedFields = ({ id }: HMDRepeatedFieldsProps) => {
  return (
    <>
      <GenericField
        id={`${id}_BloodTests`}
        fields={[
          {
            name: 'data.bloodTests',
            label: 'report.HMDREPEATED.bloodTests',
            type: 'textareaWithEditor',
          },
          {
            name: 'data.anthropometry',
            label: 'report.HMDREPEATED.anthropometry',
            type: 'textareaWithEditor',
          },
          {
            name: 'data.currentAnamnesis',
            label: 'report.HMDREPEATED.currentAnamnesis',
            type: 'textareaWithEditor',
          },
          {
            name: 'data.anamnesis',
            label: 'report.HMDREPEATED.anamnesis',
            type: 'textareaWithEditor',
          },
          {
            name: 'data.dietaryRecommendations',
            label: 'report.HMDREPEATED.dietaryRecommendations',
            type: 'textareaWithEditor',
          },
          {
            name: 'data.responding',
            label: 'report.HMDREPEATED.responding',
            type: 'textareaWithEditor',
          },
          {
            name: 'data.additionalDetails',
            label: 'report.HMDREPEATED.additionalDetails',
            type: 'textareaWithEditor',
          },
          {
            name: 'data.recommendations',
            label: 'report.HMDREPEATED.recommendations',
            type: 'textareaWithEditor',
          },
        ]}
      />
      <SectionDivider />
    </>
  );
};

export default HMDRepeatedFields;
