import { FormControl, Grid, InputLabel, MenuItem, Select } from '@mui/material';
import { format } from 'date-fns';
import { t } from 'i18next';
import React from 'react';
import DateInput from '../../../components/DateInput';
import { IHospitalDays } from '../../../interfaces/Models';
import { ProgramDatesGrid } from '../../../styles/ProgramStyles';
import { getDatesInRange } from '../../../utils/HospitalDaysHelper';
import { HOSPITAL_DAYS_TYPE } from '../../../common/Constants';

interface IHospitalDaysFormFields {
  hospitalDays?: IHospitalDays[];
  start: Date | null;
  end: Date | null;
  handleDatesChange: (name: string, value: Date | null) => void;
  startError?: string;
  endError?: string;
  editedPeriodId?: string;
  type?: string;
  setPeriodType: (value: string) => void;
}
const HospitalDaysFormFields = ({
  start,
  end,
  handleDatesChange,
  startError,
  endError,
  hospitalDays,
  editedPeriodId,
  type,
  setPeriodType,
}: IHospitalDaysFormFields) => {
  const disablePreviousHospitalDays = (date: Date) => {
    let disabledDates: string[] = [];
    if (hospitalDays) {
      hospitalDays.forEach((period: IHospitalDays) => {
        if (!editedPeriodId || editedPeriodId != period._id) {
          const periodDates = getDatesInRange(new Date(period.start), new Date(period.end));
          disabledDates = [...disabledDates, ...periodDates];
        }
      });

      return disabledDates.indexOf(format(new Date(date), 'dd-MM-yyyy')) > -1;
    }

    return true;
  };

  return (
    <ProgramDatesGrid container spacing={2}>
      <Grid item xs={12} md={4} lg={4}>
        <FormControl
          sx={{
            minWidth: '100%',
          }}
        >
          <InputLabel>{t('program.hospitalDaysForm.type.label')}</InputLabel>
          <Select
            sx={{ width: '100%' }}
            id="Select_Filter"
            value={type ?? HOSPITAL_DAYS_TYPE.HOSPITAL}
            label={t('program.hospitalDaysForm.type.label')}
            onChange={(e) => setPeriodType(e.target.value)}
          >
            {Object.values(HOSPITAL_DAYS_TYPE).map((option) => {
              return (
                <MenuItem key={option} value={option}>
                  {t(`program.hospitalDaysForm.type.${option}`)}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={12} md={4} lg={4}>
        <DateInput
          value={start}
          name="start"
          handleChange={handleDatesChange}
          label={t('patient.info.startDate')}
          error={startError || ''}
          shouldDisableDate={disablePreviousHospitalDays}
        />
      </Grid>
      <Grid item xs={12} md={4} lg={4}>
        <DateInput
          value={end}
          name="end"
          handleChange={handleDatesChange}
          label={t('patient.info.endDate')}
          error={endError || ''}
          shouldDisableDate={disablePreviousHospitalDays}
        />
      </Grid>
    </ProgramDatesGrid>
  );
};

export default HospitalDaysFormFields;
