import { Button, Grid, IconButton, SelectChangeEvent, Typography } from '@mui/material';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { StyledDivider, StyledGrid } from '../../../styles/FormFieldsStyles';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import { IPractice, IPracticeField } from '../../../interfaces/Models';
import { prevIsValidPractice } from '../../../utils/Helper';
import PracticeFieldSelectInput from './PracticeFieldSelectInput';
import PracticeTextFieldInput from './PracticeTextFieldInput';
import PracticeCategorySelect from './PracticeCategorySelect';
import { practiceInputState } from '../../../common/Constants';
import { practiceSubCategories, practiceSubCategoryFields } from '../../../common/PracticeData';
import isReportMode from '../../../hooks/isReportMode';

export const practiceSubCategoryRequiredFields = ['exercise', 'position'];
interface IPracticeFields {
  header?: string;
  subHeader?: string;
  onChangeFormikValue: (field: string, value: any, shouldValidate?: boolean) => void;
  fieldName: string;
  categories: string[];
  practicesValues: IPractice[];
}
const PracticeFields = ({
  header,
  subHeader,
  onChangeFormikValue,
  fieldName,
  categories,
  practicesValues,
}: IPracticeFields) => {
  const [practices, setPractices] = useState<IPractice[]>(
    practicesValues && practicesValues.length > 0 ? practicesValues : [practiceInputState]
  );

  const { t } = useTranslation();
  const isViewMode = isReportMode('view');

  const onChangeCategories = (index: number, event: SelectChangeEvent<string>) => {
    setPractices((prev) => {
      return prev.map((item: IPractice, i: number) => {
        if (i !== index) {
          return item;
        }
        if (event.target.name == 'category') {
          return {
            ...item,
            category: event.target.value,
            categoryErrors: event.target.value.length > 0 ? '' : t('clinic.form.required'),
          };
        }
        return {
          ...item,
          subCategory: event.target.value,
          fields: [
            ...practiceSubCategoryFields[event.target.value],
            { value: '', name: 'note', type: 'input' },
          ].map((field: IPracticeField) => {
            return { ...field, value: '' };
          }),
          subCategoryErrors: event.target.value.length > 0 ? '' : t('clinic.form.required'),
        };
      });
    });
  };

  const onChangeSubCategoryField = (
    fieldIdx: number,
    subFieldIdx: number,
    event: SelectChangeEvent<string> | React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setPractices((prev) => {
      return prev.map((item: IPractice, i: number) => {
        if (i !== fieldIdx) {
          return item;
        }

        item.fields[subFieldIdx].value = event.target.value;
        if (practiceSubCategoryRequiredFields.indexOf(event.target.name) > -1) {
          item.fields[subFieldIdx].errors =
            event.target.value.length > 0 ? '' : t('clinic.form.required');
        }
        return item;
      });
    });
  };

  const handleAddField = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    const isPracticesValid = prevIsValidPractice(practices, setPractices, t);
    if (isPracticesValid) {
      setPractices((prev) => {
        return prev.map((item: IPractice, i: number) => {
          if (i == practices.length - 1) {
            item.added = true;
            return item;
          }
          return item;
        });
      });
      setPractices((prev: IPractice[]) => [...prev, practiceInputState]);
    }
    onChangeFormikValue(fieldName, practices);
  };

  const handleRemoveField = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>, index: number) => {
    setPractices((prev) => prev.filter((item) => item !== prev[index]));
    onChangeFormikValue(fieldName, practices);
  };

  return (
    <div>
      {header && (
        <Typography variant="subtitle1" gutterBottom align="left">
          {header}
        </Typography>
      )}
      {subHeader && (
        <Typography variant="subtitle2" gutterBottom align="left">
          {subHeader}
        </Typography>
      )}

      {practices.map((practice: IPractice, fieldIdx: number) => {
        return (
          <div key={`${practice.category}_${fieldIdx}`}>
            <StyledGrid container spacing={2} id={`Practice_Container`}>
              <Grid item xs={5} md={3} lg={3}>
                <PracticeCategorySelect
                  practice={practice}
                  onChangeCategories={onChangeCategories}
                  fieldIdx={fieldIdx}
                  fieldName="category"
                  label={t('report.categories.header')}
                  categories={categories}
                  categoryPath="categories"
                />
              </Grid>
              <Grid item xs={5} md={3} lg={3}>
                <PracticeCategorySelect
                  practice={practice}
                  onChangeCategories={onChangeCategories}
                  fieldIdx={fieldIdx}
                  fieldName="subCategory"
                  label={t(`report.practiceFields.subCategory`)}
                  categories={practiceSubCategories[practice.category] || []}
                  categoryPath="subCategories"
                />
              </Grid>
              {practice.fields.length > 0 && (
                <>
                  {practice.fields.map((field: IPracticeField, subFieldIdx: number) => {
                    const conditionField = field.conditionName
                      ? practice.fields.filter(
                          (field: IPracticeField) => field.name == 'equipment'
                        )[0]
                      : null;
                    const conditionValue =
                      !conditionField ||
                      (conditionField && conditionField.value == field.conditionValue);
                    const gridSmallSize = conditionValue ? 5 : 0;
                    const gridSize = conditionValue ? 3 : 0;
                    switch (field.type) {
                      case 'input':
                        return conditionValue ? (
                          <Grid
                            item
                            xs={gridSmallSize}
                            md={gridSize}
                            lg={gridSize}
                            key={`${field.name}_${subFieldIdx}_ ${fieldIdx}`}
                          >
                            <PracticeTextFieldInput
                              practice={practice}
                              field={field}
                              subFieldIdx={subFieldIdx}
                              onChangeSubCategoryField={onChangeSubCategoryField}
                              fieldIdx={fieldIdx}
                            />
                          </Grid>
                        ) : null;
                      default:
                        return conditionValue ? (
                          <Grid
                            item
                            xs={gridSmallSize}
                            md={gridSize}
                            lg={gridSize}
                            key={`${field.name}_${subFieldIdx}_ ${fieldIdx}`}
                          >
                            <PracticeFieldSelectInput
                              field={field}
                              practice={practice}
                              onChangeSubCategoryField={onChangeSubCategoryField}
                              subFieldIdx={subFieldIdx}
                              fieldIdx={fieldIdx}
                            />
                          </Grid>
                        ) : null;
                    }
                  })}
                </>
              )}
              <IconButton
                color="error"
                aria-label="delete"
                onClick={(e) => handleRemoveField(e, fieldIdx)}
                size="large"
                disabled={isViewMode}
              >
                <DeleteIcon />
              </IconButton>
            </StyledGrid>
            <StyledDivider />
          </div>
        );
      })}
      <Button
        variant="contained"
        disabled={isViewMode}
        endIcon={<AddIcon />}
        onClick={handleAddField}
      >
        {t('global.form.add')}
      </Button>
    </div>
  );
};

export default PracticeFields;
