import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-xhr-backend';
import { initReactI18next } from 'react-i18next';

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    backend: {
      loadPath: `${process.env.PUBLIC_URL}/locales/{{lng}}/translation.json`,
    },
    react: {
      useSuspense: false,
    },
    lng: 'he',
    fallbackLng: 'he',
    interpolation: { escapeValue: false },
    supportedLngs: ['he', 'en'],
  });
