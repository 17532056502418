import { IconButton, Tooltip } from '@mui/material';
import { t } from 'i18next';
import * as Yup from 'yup';
import React from 'react';
import { ReportData } from '../interfaces/Models';
import Form from './Form/FormikForm';
import FormModal from './Modals/FormModal';
import Iconify from './Iconify';
import ErrorMessage from './ErrorMessage';

interface IFilterList {
  openModal: boolean;
  handleModalClose: () => void;
  handleOpenModal: () => void;
  loading: boolean;
  error: boolean;
  errorMessage: string;
  handelSubmit: (value: ReportData) => void;
  initialValues: object;
}

const FilterList: React.FC<IFilterList> = ({
  handelSubmit,
  initialValues,
  openModal,
  loading,
  handleModalClose,
  handleOpenModal,
  error,
  errorMessage,
  children,
}) => {
  const FiltersSchema = Yup.object({});

  const handelFilterSubmit = (value: ReportData) => {
    handelSubmit(value);
    handleModalClose();
  };

  if (error) {
    return <ErrorMessage error={errorMessage} />;
  }

  return (
    <>
      <FormModal
        loading={loading}
        text={t('global.filters.header')}
        open={openModal}
        onClose={handleModalClose}
      >
        <Form
          onCancel={handleModalClose}
          submit={handelFilterSubmit}
          initialValues={initialValues}
          validationSchema={FiltersSchema}
          submitText={t('global.filters.search')}
        >
          {children}
        </Form>
      </FormModal>
      <Tooltip title={`${t('global.filters.tooltip')}`}>
        <IconButton onClick={handleOpenModal}>
          <Iconify icon="ic:round-filter-list" />
        </IconButton>
      </Tooltip>
    </>
  );
};

export default FilterList;
