import React from 'react';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin, { DateClickArg } from '@fullcalendar/interaction';
import i18n, { t } from 'i18next';
import { EventClickArg, EventDropArg } from '@fullcalendar/core';

export interface IEvents {
  title?: string;
  date?: string;
  color?: string;
  id?: string;
  start?: string;
  end?: string;
  display?: string;
  editable?: boolean;
}

export interface EventDropData {
  oldStartDate: Date;
  newStartDate: Date;
  eventId: string;
}

export interface CalenderProps {
  events: IEvents[];
  onOpenDate?: (value: Date) => void;
  onOpenEvent?: (value: any) => void;
  onEventDrop?: (data: EventDropData) => void;
  startDate?: boolean;
  editable?: boolean;
}
const Calender = ({
  events,
  onOpenDate,
  onOpenEvent,
  startDate = false,
  onEventDrop,
  editable = false,
}: CalenderProps) => {
  const handleDateClick = (arg: DateClickArg) => {
    if (onOpenDate) {
      onOpenDate(arg.date);
    }
  };

  const handleEventClick = (arg: EventClickArg) => {
    if (onOpenEvent) {
      onOpenEvent(arg);
    }
  };

  const handleEventDrop = async (data: EventDropData) => {
    if (onEventDrop) {
      await onEventDrop(data);
    }
  };

  return (
    <>
      <FullCalendar
        initialView="dayGridMonth"
        plugins={[dayGridPlugin, interactionPlugin]}
        weekends={true}
        events={events}
        dateClick={handleDateClick}
        eventClick={handleEventClick}
        initialDate={new Date()}
        // initialDate={startDate ? new Date(events[0].date || '') : new Date()}
        locale={i18n.language == 'he' ? 'he' : 'en'}
        direction={i18n.language == 'he' ? 'rtl' : 'ltr'}
        buttonText={{
          today: t('global.calendar.today'),
        }}
        editable={editable}
        eventStartEditable={false}
        eventDrop={(arg: EventDropArg) => {
          const oldStart = arg.oldEvent.start;
          const newStart = arg.event.start;
          const eventId = arg.event.id;
          if (oldStart && newStart) {
            handleEventDrop({ oldStartDate: oldStart, newStartDate: newStart, eventId });
          }
        }}
      />
    </>
  );
};

export default Calender;
