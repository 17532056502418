import { Box, Skeleton } from '@mui/material';
import React from 'react';

const ProgramActionsSkeleton = () => {
  return (
    <>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          width: '60%',
          float: 'left',
          p: 0.5,
          height: '100%',
        }}
      >
        <Skeleton variant="rectangular" width="40%">
          <div style={{ width: '100%', padding: '10%' }} />
        </Skeleton>
        <Skeleton variant="rectangular" width="40%">
          <div style={{ width: '100%', padding: '10%' }} />
        </Skeleton>
      </Box>
    </>
  );
};

export default ProgramActionsSkeleton;
