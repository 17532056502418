import { Stack } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';
import { IUser, IUserFlat } from '../../../interfaces/Models';
import { userAtom } from '../../../atoms/userAtom';
import ErrorMessage from '../../../components/ErrorMessage';
import { checkIfUserIsAdmin } from '../../../utils/Helper';
import { StyledDivider } from '../../../styles/FormFieldsStyles';
import FormikAutocomplete from '../../../components/Form/FormikAutocomplete';
import { getIn, useFormikContext } from 'formik';
import DiseaseAutoComplete from '../../../pages/reports/sharedFields/DiseaseAutoComplete';
import EditorField from '../../../components/Form/EditorField';
import { REFERRAL_REPORT_TITLE_OPTIONS } from '../../../common/Constants';

interface Props {
  employees: IUserFlat[];
  isLoadingEmployees: boolean;
  error: boolean;
  errorMessage: string;
}
export default function ReferralFormFields({
  employees,
  isLoadingEmployees,
  error,
  errorMessage,
}: Props) {
  const { t } = useTranslation();
  const user = useRecoilValue(userAtom);

  const { values } = useFormikContext();
  if (error) {
    return <ErrorMessage error={errorMessage} />;
  }

  const getUserLabel = (user: IUserFlat | IUser): string => {
    if ('personalDetails' in user) {
      return `${user.personalDetails.firstName} ${user.personalDetails.lastName}`;
    } else {
      return user.fullName;
    }
  };

  const isUserAdmin = checkIfUserIsAdmin(user);

  const bodyValue = getIn(values, 'body');

  return (
    <Stack width={'100%'} gap={2} sx={{ pt: 2 }}>
      {isUserAdmin && (
        <FormikAutocomplete
          options={employees}
          getOptionLabel={getUserLabel}
          isLoading={isLoadingEmployees}
          name="employee"
          label={t('employee')}
        />
      )}
      <FormikAutocomplete
        options={REFERRAL_REPORT_TITLE_OPTIONS}
        freeSolo
        label={t('referralTitlePrefix')}
        name="title"
      />
      <DiseaseAutoComplete label={t('referralReason')} fieldName="referralReason" />
      <EditorField label={t('body')} value={bodyValue} name={'body'} sx={{ mb: 0 }} />
      <StyledDivider />
    </Stack>
  );
}
