import { Button, Card, Grid, SxProps } from '@mui/material';
import { theme } from '../theme';
import { styled } from '@mui/system';

export const CalendarCard = styled(Card, { name: 'StyledCard', slot: 'Wrapper' })({
  padding: theme.spacing(5.5),
  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(1),
  },
});

export const ProgramCard = styled(Card, { name: 'StyledCard', slot: 'Wrapper' })({
  paddingTop: theme.spacing(5),
  marginBottom: theme.spacing(4),
});

export const ProgramFooter = styled('div')({
  display: 'flex',
  marginTop: theme.spacing(6),
  justifyContent: 'space-between',
});

export const staffSelectProps: SxProps = {
  mb: 1,
  width: '100%',
};

export const StyledStaffButton = styled(Button)({
  float: 'right',
});

export const StaffGrid = styled(Grid)({
  marginBottom: theme.spacing(2),
});

export const ProgramDatesGrid = styled(Grid)({
  marginBottom: theme.spacing(2),
  marginTop: theme.spacing(2),
});

export const ProgramEditorRoot = styled('div')({
  marginLeft: theme.spacing(2),
});
