import React, { useEffect } from 'react';
import { IVisitWithProgramTitle } from '../../../layouts/reports/ReportLayout';
import { IAdditionalData } from '../../../interfaces/ApiResponseType';
import { IPatient } from '../../../interfaces/Models';
import ReportLayoutHeader from './ReportLayoutHeader';
import { AbilityContext } from '../../../casl/can';
import { useAbility } from '@casl/react';
import { AbilitySubjects } from '../../../casl/ability';
import isReportMode from '../../../hooks/isReportMode';
import { useSetRecoilState } from 'recoil';
import { visitAttachmentsAtom } from '../../../atoms/visitAttachmentsAtom';
import useService from '../../../hooks/HookService';
import attachmentService from '../../../services/attachemntService';
import { IAttachment } from '../../../interfaces/attachment';
import OverlaySpinner from '../../OverlaySpinner';
import ErrorMessage from '../../ErrorMessage';

interface Props {
  visitData: IVisitWithProgramTitle;
  additionalData: IAdditionalData;
  patient: IPatient;
  handlePageUpdate: () => void;
  handleLoadingReport: (isLoading: boolean) => void;
}
export default function ReportLayoutHeaderContainer({
  visitData,
  additionalData,
  patient,
  handleLoadingReport,
  handlePageUpdate,
}: Props) {
  const setVisitAttachments = useSetRecoilState(visitAttachmentsAtom);

  const isViewMode = isReportMode('view');
  const ability = useAbility(AbilityContext);

  const adminAbility = ability.can('manage', AbilitySubjects.ADMIN_ABILITY);
  const editVisitDueDateAbility = ability.can('update', AbilitySubjects.ADMIN_ABILITY);

  const {
    isLoading: isLoadingAttachments,
    data: attachments,
    error: attachmentError,
    errorMessage: attachmentErrorMessage,
  } = useService<IAttachment[]>({
    service: attachmentService.getAll({
      visit: visitData?._id,
    }),
    deps: [],
  });

  useEffect(() => {
    setVisitAttachments(attachments || []);
  }, [attachments]);

  if (isLoadingAttachments) {
    return <OverlaySpinner />;
  }
  if (attachmentError) {
    return <ErrorMessage error={attachmentErrorMessage} />;
  }

  return (
    <ReportLayoutHeader
      visitData={visitData}
      additionalData={additionalData}
      handlePageUpdate={handlePageUpdate}
      handleLoadingReport={handleLoadingReport}
      patient={patient}
      adminAbility={adminAbility}
      editVisitDueDateAbility={editVisitDueDateAbility}
      isViewMode={isViewMode}
    />
  );
}
