import { Grid } from '@mui/material';
import { t } from 'i18next';
import React from 'react';
import { PATIENT_PROGRAM_STATUS, VISIT_STATUS } from '../../../common/Constants';
import { theme } from '../../../theme';
import ProgramStatusCounterWidget from './ProgramStatusCounterWidget';
import VisitStatusCounterWidget from './VisitStatusCounterWidget';

interface IVisitsSummaryWidget {
  employeeId?: string;
}
const SummariesWidgets = ({ employeeId }: IVisitsSummaryWidget) => {
  const isEmployeeDashboard = !!employeeId;
  const summaryWidgetMediumWidth = isEmployeeDashboard ? 2.4 : 3;

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={summaryWidgetMediumWidth}>
        <VisitStatusCounterWidget
          title={t('global.dashboard.clinicVisits')}
          icon="bi:send-fill"
          color={theme.palette.primary.darker || ''}
          visitStatus={VISIT_STATUS.SENT_TO_CLINIC}
          employeeId={employeeId}
        />
      </Grid>
      <Grid item xs={12} md={summaryWidgetMediumWidth}>
        <VisitStatusCounterWidget
          title={t('global.dashboard.doneVisits')}
          icon="carbon:task-complete"
          color={theme.palette.primary.light}
          visitStatus={VISIT_STATUS.DONE}
          employeeId={employeeId}
        />
      </Grid>
      <Grid item xs={12} md={summaryWidgetMediumWidth}>
        <VisitStatusCounterWidget
          title={t('global.dashboard.canceledVisits')}
          icon="ic:round-cancel"
          color={theme.palette.error.main}
          visitStatus={VISIT_STATUS.CANCELED}
          employeeId={employeeId}
        />
      </Grid>
      <Grid item xs={12} md={summaryWidgetMediumWidth}>
        <VisitStatusCounterWidget
          title={t('global.dashboard.missingVisits')}
          icon="ic:round-pending-actions"
          color={theme.palette.error.darker}
          employeeId={employeeId}
        />
      </Grid>
      <Grid item xs={12} md={isEmployeeDashboard ? 2.4 : 4}>
        <VisitStatusCounterWidget
          title={t('global.dashboard.pendingVisits')}
          icon="material-symbols:pending-actions-sharp"
          color={theme.palette.info.main}
          employeeId={employeeId}
          visitStatus={VISIT_STATUS.PENDING}
        />
      </Grid>
      {!isEmployeeDashboard && (
        <>
          <Grid item xs={12} md={4}>
            <ProgramStatusCounterWidget
              title={t('global.dashboard.completedPrograms')}
              icon="fluent:clipboard-task-list-rtl-24-regular"
              color={theme.palette.primary.light}
              status={PATIENT_PROGRAM_STATUS.DONE}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <ProgramStatusCounterWidget
              title={t('global.dashboard.activePrograms')}
              icon="carbon:task-view"
              color={theme.palette.info.main}
              status={PATIENT_PROGRAM_STATUS.ACTIVE}
            />
          </Grid>
        </>
      )}
    </Grid>
  );
};

export default SummariesWidgets;
