import { Box, Card, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { fNumber } from '../utils/formatNumber';
import { styled } from '@mui/system';
import Iconify from './Iconify';

export const WidgetIcon = styled(Iconify)(({ theme }) => ({
  width: 80,
  height: 80,
  opacity: 0.12,
  right: theme.spacing(5),
  color: theme.palette.common.white,
}));

interface ISummaryWidget {
  title: string;
  total: number;
  icon: string;
  color: string;
  route: string;
}
export default function SummaryWidget({ title, total, icon, color, route }: ISummaryWidget) {
  const navigate = useNavigate();
  return (
    <Card
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        p: 3,
        cursor: 'pointer',
      }}
      onClick={() => navigate(route)}
    >
      <Box component="div">
        <Typography
          variant="subtitle2"
          align="left"
          sx={{
            lineHeight: 1.3,
            textDecoration: 'none',
            color: color,
            wordWrap: 'break-word',
          }}
        >
          {title}
        </Typography>
        <Typography variant="h3">{fNumber(total)}</Typography>
      </Box>
      <Box component="div">
        <WidgetIcon icon={icon} sx={{ color: color, opacity: 0.6 }} />
      </Box>
    </Card>
  );
}
