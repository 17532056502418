import { t } from 'i18next';
import React from 'react';
import ErrorMessage from '../../../components/ErrorMessage';
import PiChart from '../../../components/PiChart';
import PiChartWidgetSkeleton from '../../../components/skeletons/PiChartWidgetSkeleton';
import { IServiceResponse } from '../../../hooks/HookService';
import { ReportData } from '../../../interfaces/Models';
import { theme } from '../../../theme';

const ClinicPatientsDonutWidget = ({
  data,
  error,
  errorMessage,
  isLoading,
}: IServiceResponse<ReportData>) => {
  if (isLoading) {
    return <PiChartWidgetSkeleton />;
  }
  if (error || !data) {
    return <ErrorMessage error={errorMessage} />;
  }

  return (
    <PiChart
      title={t('global.dashboard.clinicPatients')}
      colors={theme.palette.dashboardCharts}
      labels={data.clinicNames}
      chartData={data.clinicCounter}
    />
  );
};

export default ClinicPatientsDonutWidget;
