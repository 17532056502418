/* eslint-disable new-cap */
import merge from 'lodash/merge';
import ReactApexChart from 'react-apexcharts';
// @mui
import { Card, CardHeader } from '@mui/material';
import BaseOptionChart from './chart/BaseOptionChart';
import { theme } from '../theme';
import { IBarChart } from './BarChart';

interface IBasicColumnChart extends IBarChart {
  yTooltipFormatter?: (value: number) => string;
  enableDataLabels?: boolean;
}
const BasicColumnChart = ({
  title,
  chartData,
  chartCategories,
  yTooltipFormatter,
  enableDataLabels = true,
}: IBasicColumnChart) => {
  const chartOptions: any = merge(BaseOptionChart(), {
    chart: {
      type: 'bar',
      height: 350,
    },
    plotOptions: {
      bar: {
        horizontal: false,
        endingShape: 'rounded',
        dataLabels: {
          show: true,
          position: 'top', // top, center, bottom
        },
      },
    },
    dataLabels: {
      enabled: enableDataLabels,
    },
    stroke: {
      show: true,
      width: 2,
      colors: ['transparent'],
    },
    xaxis: {
      categories: chartCategories,
      labels: {
        style: {
          fontSize: '15px',
          fontWeight: 900,
          color: 'black',
        },
      },
    },
    yaxis: {
      labels: {
        style: {
          fontSize: '13px',
          fontWeight: 900,
          color: 'black',
        },
      },
    },
    legend: {
      position: 'top',
      horizontalAlign: 'left',
      offsetY: 15,
      offsetX: 15,
    },
    fill: {
      opacity: 1,
    },
    tooltip: {
      y: {
        formatter: yTooltipFormatter,
      },
    },
  });

  return (
    <Card>
      <CardHeader title={title} sx={{ mb: theme.spacing(1) }} />
      {/* @ts-ignore*/}
      <ReactApexChart type="bar" series={chartData} options={chartOptions} height={410} />
    </Card>
  );
};

export default BasicColumnChart;
