import { IField, yesNo } from './HMA';

const feedFormOptions = ['food', 'noEating'];

const dependenceFeedingOptions = ['independent', 'full', 'needHelp'];

const alternateFeedOptions = ['no', 'PEJ', 'PEG', 'zonda'];

const TPNAspirationsOptions = ['yes', 'no', 'reasonableSuspicion'];

const stomaOptions = ['no', 'jagonostomy', 'ileostomy', 'colostomy'];

const stoma1Options = ['temporarily', 'fixed', 'unknown'];

export const severtyOptions = ['unknown', 'easy', 'medium', 'severe'];

const breathingOptions = ['proper', 'intracranialTube', 'oxygenMask'];

const smokingOptions = ['unknown', 'noSmooking', 'smoker', 'passiveSmoker'];

const mobilityoptions = [
  'independent',
  'needHelp',
  'walker',
  'wheelchair',
  'boundBed',
  'walkingStick',
];

export const feedingFields = [
  {
    name: 'feedForm',
    options: feedFormOptions,
  },
  {
    name: 'dependenceFeeding',
    options: dependenceFeedingOptions,
  },
  {
    name: 'alternateFeed',
    options: alternateFeedOptions,
  },
  {
    name: 'TPNAspirations',
    options: TPNAspirationsOptions,
  },
  {
    name: 'stoma',
    options: stomaOptions,
  },
  {
    name: 'stoma1',
    options: stoma1Options,
  },
];

export const HabitsFields = [
  {
    name: 'breathing',
    options: breathingOptions,
  },
  {
    name: 'smoking',
    options: smokingOptions,
  },
  {
    name: 'mobility',
    options: mobilityoptions,
  },
];
export const yesNoOptions = ['yes', 'no'];
const nutritionalHistoryOptions1 = [
  { value: 'full', label: 'full' },
  { value: 'partial', label: 'partial' },
  { value: 'notAccepted', label: 'notAccepted' },
];

const nutritionalHistoryOptions2 = [
  { value: 'allFood', label: 'allFood' },
  { value: 'vegetarian', label: 'vegetarian' },
  { value: 'vegan', label: 'vegan' },
  { value: 'unknown', label: 'unknown' },
];

const nutritionalHistoryOptions3 = [
  { value: 'regular', label: 'regular' },
  { value: 'soft', label: 'soft' },
  { value: 'ground', label: 'ground' },
  { value: 'iPorridge', label: 'iPorridge' },
  { value: 'liquid', label: 'liquid' },
];

export const nutritionalHistoryFields: IField[] = [
  {
    name: 'data.nutritionalConsumption.nutritionalHistory.anamnesis',
    label: 'report.HMDFIRST.nutritionalConsumption.nutritionalHistory.anamnesis',
    type: 'select',
    options: nutritionalHistoryOptions1,
    smallSize: 12,
    largeSize: 4,
  },
  {
    name: 'data.nutritionalConsumption.nutritionalHistory.patient',
    label: 'report.HMDFIRST.nutritionalConsumption.nutritionalHistory.patient',
    type: 'select',
    options: nutritionalHistoryOptions2,
    smallSize: 12,
    largeSize: 4,
  },
  {
    name: 'data.nutritionalConsumption.nutritionalHistory.textureFood',
    label: 'report.HMDFIRST.nutritionalConsumption.nutritionalHistory.textureFood',
    type: 'select',
    options: nutritionalHistoryOptions3,
    smallSize: 12,
    largeSize: 4,
  },
];

export const supplementsFields: IField[] = [
  {
    name: 'data.nutritionalConsumption.supplements.calcium',
    label: 'report.HMDFIRST.nutritionalConsumption.supplements.calcium',
    type: 'text',
    rows: 1,
    smallSize: 12,
    largeSize: 4,
  },
  {
    name: 'data.nutritionalConsumption.supplements.iron',
    label: 'report.HMDFIRST.nutritionalConsumption.supplements.iron',
    type: 'text',
    rows: 1,
    smallSize: 12,
    largeSize: 4,
  },
  {
    name: 'data.nutritionalConsumption.supplements.vitaminD',
    label: 'report.HMDFIRST.nutritionalConsumption.supplements.vitaminD',
    type: 'text',
    rows: 1,
    smallSize: 12,
    largeSize: 4,
  },
  {
    name: 'data.nutritionalConsumption.supplements.multiVitamin',
    label: 'report.HMDFIRST.nutritionalConsumption.supplements.multiVitamin',
    type: 'text',
    rows: 1,
    smallSize: 12,
    largeSize: 4,
  },
  {
    name: 'data.nutritionalConsumption.supplements.other',
    label: 'report.HMDFIRST.nutritionalConsumption.supplements.other',
    type: 'text',
    rows: 1,
    smallSize: 12,
    largeSize: 4,
  },
];

const foodTextureOptions = [
  { value: 'regular', label: 'regular' },
  { value: 'soft', label: 'soft' },
  { value: 'ground', label: 'ground' },
  { value: 'liquid', label: 'liquid' },
];

export const eatingOralyUpperFields: IField[] = [
  {
    name: 'data.recommendations.eatingOrally.foodTexture',
    label: 'report.HMDFIRST.recommendations.eatingOrally.foodTexture',
    type: 'select',
    options: foodTextureOptions,
    smallSize: 12,
    largeSize: 4,
  },
  {
    name: 'data.recommendations.eatingOrally.sample',
    label: 'report.HMDFIRST.recommendations.eatingOrally.sample',
    type: 'select',
    options: yesNo,
    smallSize: 12,
    largeSize: 4,
  },
  {
    name: 'data.recommendations.eatingOrally.dietary',
    label: 'report.HMDFIRST.recommendations.eatingOrally.dietary',
    type: 'select',
    options: yesNo,
    smallSize: 12,
    largeSize: 4,
  },
];

export const evaluationBeforeTreatmentFields: IField[] = [
  {
    name: 'data.evaluationBeforeTreatment.designatedFood',
    label: 'report.HMDFIRST.evaluationBeforeTreatment.designatedFood',
    type: 'text',
    rows: 1,
    smallSize: 12,
    largeSize: 6,
  },
  {
    name: 'data.evaluationBeforeTreatment.amount',
    label: 'report.HMDFIRST.evaluationBeforeTreatment.amount.header',
    type: 'text',
    helperText: 'report.HMDFIRST.evaluationBeforeTreatment.amount.helperText',
    rows: 1,
    smallSize: 12,
    largeSize: 6,
  },
  {
    name: 'data.evaluationBeforeTreatment.feedRate',
    label: 'report.HMDFIRST.evaluationBeforeTreatment.feedRate',
    type: 'text',
    rows: 1,
    smallSize: 12,
    largeSize: 4,
  },
  {
    name: 'data.evaluationBeforeTreatment.forHoures',
    label: 'report.HMDFIRST.evaluationBeforeTreatment.forHoures',
    type: 'text',
    rows: 1,
    smallSize: 12,
    largeSize: 4,
  },
  {
    name: 'data.evaluationBeforeTreatment.waterPerDay',
    label: 'report.HMDFIRST.evaluationBeforeTreatment.waterPerDay',
    type: 'text',
    rows: 1,
    smallSize: 12,
    largeSize: 4,
  },
  {
    name: 'data.evaluationBeforeTreatment.notes',
    label: 'report.HMDFIRST.evaluationBeforeTreatment.notes.header',
    helperText: 'report.HMDFIRST.evaluationBeforeTreatment.notes.helperText',
    type: 'text',
    rows: 3,
    smallSize: 12,
    largeSize: 12,
  },
];

export const evaluationBeforeAsssistanceFields: IField[] = [
  {
    name: 'data.evaluationBeforeAsssistance.food.energy',
    label: 'report.HMDFIRST.evaluationBeforeAsssistance.food.energy',
    type: 'text',
    rows: 1,
    smallSize: 12,
    largeSize: 4,
  },
  {
    name: 'data.evaluationBeforeAsssistance.food.protein',
    label: 'report.HMDFIRST.evaluationBeforeAsssistance.food.protein',
    type: 'text',
    rows: 1,
    smallSize: 12,
    largeSize: 4,
  },
  {
    name: 'data.evaluationBeforeAsssistance.food.liquids',
    label: 'report.HMDFIRST.evaluationBeforeAsssistance.food.liquids',
    type: 'text',
    rows: 1,
    smallSize: 12,
    largeSize: 4,
  },
  {
    name: 'data.evaluationBeforeAsssistance.medicalFood.energy',
    label: 'report.HMDFIRST.evaluationBeforeAsssistance.medicalFood',
    type: 'text',
    rows: 1,
    smallSize: 12,
    largeSize: 4,
  },
  {
    name: 'data.evaluationBeforeAsssistance.medicalFood.protein',
    label: 'report.HMDFIRST.evaluationBeforeAsssistance.food.protein',
    type: 'text',
    rows: 1,
    smallSize: 12,
    largeSize: 4,
  },
  {
    name: 'data.evaluationBeforeAsssistance.medicalFood.liquids',
    label: 'report.HMDFIRST.evaluationBeforeAsssistance.food.liquids',
    type: 'text',
    rows: 1,
    smallSize: 12,
    largeSize: 4,
  },
  {
    name: 'data.evaluationBeforeAsssistance.total.energy',
    label: 'report.HMDFIRST.evaluationBeforeAsssistance.total',
    type: 'text',
    rows: 1,
    smallSize: 12,
    largeSize: 4,
  },
  {
    name: 'data.evaluationBeforeAsssistance.total.protein',
    label: 'report.HMDFIRST.evaluationBeforeAsssistance.food.protein',
    type: 'text',
    rows: 1,
    smallSize: 12,
    largeSize: 4,
  },
  {
    name: 'data.evaluationBeforeAsssistance.total.liquids',
    label: 'report.HMDFIRST.evaluationBeforeAsssistance.food.liquids',
    type: 'text',
    rows: 1,
    smallSize: 12,
    largeSize: 4,
  },
  {
    name: 'data.evaluationBeforeAsssistance.notes',
    label: 'report.HMDFIRST.evaluationBeforeAsssistance.notes.header',
    type: 'text',
    helperText: 'report.HMDFIRST.evaluationBeforeAsssistance.notes.header',
    rows: 3,
    smallSize: 12,
    largeSize: 12,
  },
];

export const nutritionalGoalsFields: IField[] = [
  {
    name: 'data.nutritionalGoals.nutritionalNeeds.desiredWeight',
    label: 'report.HMDFIRST.nutritionalGoals.nutritionalNeeds.desiredWeight',
    type: 'text',
    rows: 1,
    smallSize: 12,
    largeSize: 4,
  },
  {
    name: 'data.nutritionalGoals.nutritionalNeeds.standardWeight',
    label: 'report.HMDFIRST.nutritionalGoals.nutritionalNeeds.standardWeight',
    type: 'text',
    rows: 1,
    smallSize: 12,
    largeSize: 4,
  },
  {
    name: 'data.nutritionalGoals.nutritionalNeeds.basicEnergy',
    label: 'report.HMDFIRST.nutritionalGoals.nutritionalNeeds.basicEnergy',
    type: 'text',
    rows: 1,
    smallSize: 12,
    largeSize: 4,
  },
];

export const patientNeedsFields: IField[] = [
  {
    name: 'data.nutritionalGoals.nutritionalNeeds.totalPatientNeed.energy',
    label: 'report.HMDFIRST.nutritionalGoals.nutritionalNeeds.totalPatientNeed.energy',
    type: 'text',
    rows: 1,
    smallSize: 12,
    largeSize: 4,
  },
  {
    name: 'data.nutritionalGoals.nutritionalNeeds.totalPatientNeed.protein',
    label: 'report.HMDFIRST.nutritionalGoals.nutritionalNeeds.totalPatientNeed.protein',
    type: 'text',
    rows: 1,
    smallSize: 12,
    largeSize: 4,
  },
  {
    name: 'data.nutritionalGoals.nutritionalNeeds.totalPatientNeed.fluids',
    label: 'report.HMDFIRST.nutritionalGoals.nutritionalNeeds.totalPatientNeed.fluids',
    type: 'text',
    rows: 1,
    smallSize: 12,
    largeSize: 4,
  },
];

export const nutritionalGoalsNeedsFields: IField[] = [
  {
    name: 'data.nutritionalGoals.nutritionalNeeds.nutritionalGoals.purpose',
    label: 'report.HMDFIRST.nutritionalGoals.nutritionalNeeds.nutritionalGoals.purpose',
    type: 'text',
    rows: 1,
    smallSize: 12,
    largeSize: 12,
  },
  {
    name: 'data.nutritionalGoals.nutritionalNeeds.nutritionalGoals.objectives',
    label: 'report.HMDFIRST.nutritionalGoals.nutritionalNeeds.nutritionalGoals.objectives',
    type: 'text',
    rows: 1,
    smallSize: 12,
    largeSize: 12,
  },
  {
    name: 'data.nutritionalGoals.nutritionalNeeds.nutritionalGoals.weightTargets',
    label: 'report.HMDFIRST.nutritionalGoals.nutritionalNeeds.nutritionalGoals.weightTargets',
    type: 'text',
    rows: 1,
    smallSize: 12,
    largeSize: 12,
  },
];

export const estimationRecommendationsFields: IField[] = [
  {
    name: 'data.recommendations.eatingOrally.estimationRecommendations.energy',
    label: 'report.HMDFIRST.nutritionalGoals.nutritionalNeeds.totalPatientNeed.energy',
    type: 'text',
    rows: 1,
    smallSize: 12,
    largeSize: 4,
  },
  {
    name: 'data.recommendations.eatingOrally.estimationRecommendations.protein',
    label: 'report.HMDFIRST.nutritionalGoals.nutritionalNeeds.totalPatientNeed.protein',
    type: 'text',
    rows: 1,
    smallSize: 12,
    largeSize: 4,
  },
  {
    name: 'data.recommendations.eatingOrally.estimationRecommendations.fluids',
    label: 'report.HMDFIRST.nutritionalGoals.nutritionalNeeds.totalPatientNeed.fluids',
    type: 'text',
    rows: 1,
    smallSize: 12,
    largeSize: 4,
  },
];

export const dedicatedFoodFields: IField[] = [
  {
    name: 'data.recommendations.eatingOrally.dedicatedFood.energy',
    label: 'report.HMDFIRST.nutritionalGoals.nutritionalNeeds.totalPatientNeed.energy',
    type: 'text',
    rows: 1,
    smallSize: 12,
    largeSize: 4,
  },
  {
    name: 'data.recommendations.eatingOrally.dedicatedFood.protein',
    label: 'report.HMDFIRST.nutritionalGoals.nutritionalNeeds.totalPatientNeed.protein',
    type: 'text',
    rows: 1,
    smallSize: 12,
    largeSize: 4,
  },
  {
    name: 'data.recommendations.eatingOrally.dedicatedFood.fluids',
    label: 'report.HMDFIRST.nutritionalGoals.nutritionalNeeds.totalPatientNeed.fluids',
    type: 'text',
    rows: 1,
    smallSize: 12,
    largeSize: 4,
  },
];

export const alternativeFeedingVolumefields: IField[] = [
  {
    name: 'data.recommendations.alternativeFeeding.feedVolume.volume',
    label: 'report.HMDFIRST.recommendations.alternativeFeeding.feedVolume',
    type: 'text',
    rows: 1,
    smallSize: 12,
    largeSize: 6,
  },
  {
    name: 'data.recommendations.alternativeFeeding.feedVolume.energy',
    label: 'report.HMDFIRST.nutritionalGoals.nutritionalNeeds.totalPatientNeed.energy',
    type: 'text',
    rows: 1,
    smallSize: 12,
    largeSize: 6,
  },
  {
    name: 'data.recommendations.alternativeFeeding.feedVolume.protein',
    label: 'report.HMDFIRST.nutritionalGoals.nutritionalNeeds.totalPatientNeed.protein',
    type: 'text',
    rows: 1,
    smallSize: 12,
    largeSize: 6,
  },
  {
    name: 'data.recommendations.alternativeFeeding.feedVolume.fluids',
    label: 'report.HMDFIRST.nutritionalGoals.nutritionalNeeds.totalPatientNeed.fluids',
    type: 'text',
    rows: 1,
    smallSize: 12,
    largeSize: 6,
  },
];

export const addedFluidsFields: IField[] = [
  {
    name: 'data.recommendations.alternativeFeeding.addedFluid',
    label: 'report.HMDFIRST.recommendations.alternativeFeeding.addedFluid',
    type: 'text',
    rows: 1,
    smallSize: 12,
    largeSize: 6,
  },
  {
    name: 'data.recommendations.alternativeFeeding.totalFluids',
    label: 'report.HMDFIRST.recommendations.alternativeFeeding.totalFluids',
    type: 'text',
    rows: 1,
    smallSize: 12,
    largeSize: 6,
  },
];

export const recomindationsBottomFields: IField[] = [
  {
    name: 'data.recommendations.alternativeFeeding.feedRate.rate',
    label: 'report.HMDFIRST.recommendations.alternativeFeeding.feedRate.rate',
    type: 'text',
    rows: 1,
    smallSize: 12,
    largeSize: 6,
  },
  {
    name: 'data.recommendations.alternativeFeeding.feedRate.houers',
    label: 'report.HMDFIRST.recommendations.alternativeFeeding.feedRate.houers',
    type: 'text',
    rows: 1,
    smallSize: 12,
    largeSize: 6,
  },
  {
    name: 'data.recommendations.alternativeFeeding.alternativeFeedingNotes',
    label: 'report.HMDFIRST.recommendations.alternativeFeeding.alternativeFeedingNotes.header',
    type: 'text',
    helperText: 'report.HMDFIRST.recommendations.alternativeFeeding.alternativeFeedingNotes.header',
    rows: 3,
    smallSize: 12,
    largeSize: 12,
  },
];
