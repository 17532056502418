import React from 'react';
import { useTranslation } from 'react-i18next';
import { Typography } from '@mui/material';
import { evaluationBeforeTreatmentFields } from '../../../../common/HMDFirst';
import GenericField from '../../sharedFields/GenericField';

const EvaluationBeforeTreatmentFields = () => {
  const { t } = useTranslation();
  const id = 'HMDFirst_EvaluationBeforeTreatment';
  return (
    <div id={id}>
      <Typography variant="subtitle1" gutterBottom align="left">
        {t('report.HMDFIRST.evaluationBeforeTreatment.header')}
      </Typography>
      <GenericField id={`${id}_Upperfields`} fields={evaluationBeforeTreatmentFields} />
    </div>
  );
};

export default EvaluationBeforeTreatmentFields;
