import React, { useCallback } from 'react';
import { ICustomRow } from './Table';
import { StyledTableRow } from '../styles/TableStyles';
import { ITableBaseSchema } from '../interfaces/table';
import { useRecoilValue } from 'recoil';
import { tableAtom } from '../atoms/tableAtom';
import { theme } from '../theme';
import { TableCell, TableRow } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Cell, Row } from 'react-table';
import { Icon } from '@iconify/react';
import { styled } from '@mui/system';
import ExpandTableRow from './ExpandTableRow';

export const ExpandableStyledTableRow = styled(TableRow)`
  &:hover {
    cursor: pointer;
  }
`;

export interface TableContentCommonProps<T> {
  onRowClick?: (value: any) => void;
  showIndex?: boolean;
  ExpandCard?: React.FC<ICustomRow<T>>;
}

interface GenericTableRowProps<T> extends TableContentCommonProps<T> {
  row: Row<any>;
  index: number;
  numberOfColumns: number;
}

export default function GenericTableRow<T extends ITableBaseSchema>({
  row,
  onRowClick,
  showIndex,
  ExpandCard,
  index,
  numberOfColumns,
}: GenericTableRowProps<T>) {
  const { t } = useTranslation();
  const [isExpanded, setIsExpanded] = React.useState(false);

  const tableSession = useRecoilValue(tableAtom);
  const sessionKeys = Object.values(tableSession);

  const isRowExpandable = !!ExpandCard;

  const handleRowExpand = () => setIsExpanded((prev) => !prev);

  const handleRowClick = () => {
    onRowClick && onRowClick(row);
  };

  const cellClicker = (cell: Cell<any, any>) => {
    if (cell.column.Header != t('clinic.table.actions')) {
      if (isRowExpandable) {
        handleRowExpand();
      }
      handleRowClick();
    }
  };

  return (
    <>
      <StyledTableRow
        {...row.getRowProps()}
        sx={
          sessionKeys.indexOf(row.original._id) == -1
            ? {}
            : { backgroundColor: theme.palette.action.selected }
        }
      >
        {isRowExpandable ? (
          <TableCell>
            <Icon
              icon={isExpanded ? 'ic:twotone-keyboard-arrow-up' : 'ic:twotone-keyboard-arrow-down'}
              onClick={handleRowExpand}
              width={24}
              height={24}
            />
          </TableCell>
        ) : null}
        {showIndex && (
          <TableCell key={`Cell-index-${index + 1}`} sx={{ fontWeight: 'bold' }}>
            {index + 1}
          </TableCell>
        )}

        {row.cells.map((cell: any) => {
          return (
            <TableCell
              {...cell.getCellProps()}
              onClick={(e) => {
                cellClicker(cell);
                e.stopPropagation();
              }}
              sx={cell.column.cellStyle ? cell.column.cellStyle : {}}
            >
              {cell.render('Cell')}
            </TableCell>
          );
        })}
      </StyledTableRow>
      {isRowExpandable && (
        <ExpandTableRow
          ExpandCard={ExpandCard}
          numberOfColumns={numberOfColumns}
          isExpanded={isExpanded}
          row={row}
        />
      )}
    </>
  );
}
