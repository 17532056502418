import { getIn, useFormikContext } from 'formik';
import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  fallsfields,
  nortonfields,
  testsFields,
  treatmentNursingInterventionFields,
  treatmentPlanFields,
} from '../../../../common/HMA';
import SectionDivider from '../../../../components/SectionDivider';
import EssentialSignsFields from '../../sharedFields/EssentialSignsFields';
import FieldsWithSummation from '../../sharedFields/FieldsWithSummation';
import MultipleSelectFields from '../../sharedFields/MultipleSelectFields';
import GenericField from '../../sharedFields/GenericField';
import PainAssessment from '../../sharedFields/PainAssessment';
import FunctionalAssessmentFields from './FunctionalAssessmentFields';
import WoundEstimateFields from './WoundEstimateFields';
import { getSwallowingDifficulties } from '../../../../common/HMKFirst';
import PHQ9SectionFields from './PHQ9SectionFields';
import NutritionalEstimateFields from './NutritionalEstimateFields';

interface HMAFieldsProps {
  id: string;
}
const HMAFields = ({ id }: HMAFieldsProps) => {
  const { t } = useTranslation();
  const { values, setFieldValue }: any = useFormikContext();
  const IADLValues = getIn(values, 'data.functionalAssessment.IADL');
  const ADLValues = getIn(values, 'data.functionalAssessment.ADL');
  const nortonValues = getIn(values, 'data.nortonEstimate');
  const testsValues = getIn(values, 'data.testFeatures.tests');
  const bloodPressureValue = getIn(values, 'data.essentialSigns.bloodPressure');
  const pressureStandingValue = getIn(values, 'data.essentialSigns.bloodPressureStanding');
  const woundEstimateValues = getIn(values, 'data.woundEstimation');
  const oldWoundEstimateValues = getIn(values, 'data.woundEstimate');
  const nutritionalDisorderValues = getIn(values, 'data.nutritionalEstimate.nutritionalDisorder');
  const swallowingDifficulties = getSwallowingDifficulties(t);

  return (
    <>
      <GenericField
        id={`${id}_vaccinations`}
        fields={[
          {
            name: 'data.vaccinations',
            label: 'report.HMAFIRST.vaccinations',
            type: 'text',
            rows: 2,
            smallSize: 12,
            largeSize: 12,
          },
        ]}
      />
      <EssentialSignsFields
        id={id}
        onChangeFormikValue={setFieldValue}
        bloodPressureValue={bloodPressureValue}
        pressureStandingValue={pressureStandingValue}
      />
      <SectionDivider />
      <PainAssessment id={id} />
      <SectionDivider />
      <GenericField
        id={`${id}_RiskOfFalls'`}
        fields={fallsfields}
        subHeader={t('report.HMAFIRST.falls.header')}
      />
      <SectionDivider />
      <FunctionalAssessmentFields
        id={`${id}_FunctionalAssessment`}
        IADLValues={IADLValues}
        ADLValues={ADLValues}
        onChangeFormikValue={setFieldValue}
      />
      <SectionDivider />
      <GenericField
        id={`${id}_TreatmentPlan'`}
        fields={treatmentPlanFields}
        subHeader={t('report.HMAFIRST.treatmentPlan.subHeader')}
      />
      <SectionDivider />
      <FieldsWithSummation
        id={`${id}_NortonEstimate`}
        values={nortonValues}
        onChangeFormikValue={setFieldValue}
        sumValueField="data.nortonEstimateSummary"
        subHeader={t('report.HMAFIRST.nortonEstimate.header')}
        fields={nortonfields}
      />
      <MultipleSelectFields
        id={`${id}_Swallowing`}
        Examinations={swallowingDifficulties}
        header={t('report.HMKFIRST.swallowing.header')}
      />
      <FieldsWithSummation
        id={`${id}_Tests`}
        values={testsValues}
        onChangeFormikValue={setFieldValue}
        sumValueField="data.testFeatures.testsSummary"
        subHeader={t('report.HMAFIRST.testFeatures.header')}
        fields={testsFields}
      />
      <WoundEstimateFields
        id={`${id}_WoundEstimate`}
        onChangeFormikValue={setFieldValue}
        woundEstimateValues={woundEstimateValues}
        oldWoundEstimateValues={oldWoundEstimateValues}
      />
      <SectionDivider />
      <NutritionalEstimateFields
        id={`${id}_NutritionalEstimate`}
        nutritionalDisorderValues={nutritionalDisorderValues}
        onChangeFormikValue={setFieldValue}
      />
      <PHQ9SectionFields id={`${id}_phq9`} />
      <GenericField
        id={`${id}_TreatmentNursingIntervention`}
        fields={treatmentNursingInterventionFields}
      />
      <SectionDivider />
    </>
  );
};

export default HMAFields;
