// generic service
import { useEffect, useState } from 'react';
import axios, { AxiosRequestConfig } from 'axios';
import { IService } from '../interfaces/index';
import { ApiResponse, IAdditionalData } from '../interfaces/ApiResponseType';
import { getBaseUrl, logOut } from '../common/APIHelper';

interface IUseService<T> {
  service: IService;
  deps?: any[];
  onLoadingData?: (value: T) => void;
}

export interface IServiceResponse<T> {
  data?: T;
  isLoading: boolean;
  error: boolean;
  errorMessage: string;
  additionalData?: IAdditionalData;
  loadingNumber: number;
}

const baseURL = getBaseUrl();

axios.defaults.baseURL = process.env.PUBLIC_URL || 'http://localhost:5700';

export default function useService<T>({ service, deps = [], onLoadingData }: IUseService<T>) {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState<T>();
  const [additionalData, setAdditionalData] = useState<IAdditionalData>({});
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [loadingNumber, setLoadingNumber] = useState<number>(0);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true);
        setLoadingNumber((pre) => pre + 1);

        const request: AxiosRequestConfig = {
          url: service.route,
          method: service.method,
          params: service.query,
          data: service.payload,
        };

        const response: ApiResponse<T> = (await axios(request)) as any;

        if (response.data.status === 'error') {
          setError(true);
        }

        setData(response.data.data);
        setAdditionalData(response.data.additionalData);
        if (onLoadingData) {
          onLoadingData(response.data.data);
        }
      } catch (err: any) {
        if (err.status === 401 || err.response?.status === 401) {
          logOut();
        }
        if (err.status === 403 || err.response?.status === 403) {
          window.location.href = `${baseURL}/403`;
        }
        setError(true);
        setErrorMessage(err.message);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps);

  return {
    data,
    isLoading,
    error,
    errorMessage,
    additionalData,
    loadingNumber,
  };
}
