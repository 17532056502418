import React, { useEffect, useState } from 'react';
import { Typography } from '@mui/material';
import BADLSubSectionFields from './FimSubSectionFields';
import SummaryMessage from '../../../../components/SummaryMessage';
import { t } from 'i18next';
import { getAssessmentTotalText } from '../../../../common/assessment';
import {
  fimFields1,
  fimFields2,
  fimFields3,
  fimFields4,
  fimFields5,
  fimFields6,
  fimSection1Fields,
  fimSection2Fields,
  getFimSectionSum,
} from '../../../../common/HMMFirst';

interface IFimActivityFields {
  id: string;
  values: any;
  onChangeFormikValue: (field: string, value: any, shouldValidate?: boolean) => void;
}

const FimActivityFields = ({ id, values, onChangeFormikValue }: IFimActivityFields) => {
  const [total1, setTotal1] = useState<number>(0);
  const [total2, setTotal2] = useState<number>(0);
  const [globalTotal, setGlobalTotal] = useState<number>(0);

  useEffect(() => {
    const fimTotal1: number = getFimSectionSum(values || {}, fimSection1Fields) || 0;
    const fimTotal2: number = getFimSectionSum(values || {}, fimSection2Fields) || 0;
    const fimGlobalTotal = fimTotal1 + fimTotal2;
    setTotal1(fimTotal1);
    setTotal2(fimTotal2);
    setGlobalTotal(fimGlobalTotal);
    onChangeFormikValue(
      'data.feelingOfPain.occupationAndActivity.fimAssessments.assessments.fimTotal1',
      fimTotal1
    );
    onChangeFormikValue(
      'data.feelingOfPain.occupationAndActivity.fimAssessments.assessments.fimTotal2',
      fimTotal2
    );
    onChangeFormikValue(
      'data.feelingOfPain.occupationAndActivity.fimAssessments.assessments.fimGlobalTotal',
      fimGlobalTotal
    );
  }, [values]);
  return (
    <div id={id}>
      <Typography variant="subtitle2" gutterBottom align="left">
        הערכת FIM
      </Typography>
      <BADLSubSectionFields fields={fimFields1} id="fimSelfHelp" />
      <BADLSubSectionFields fields={fimFields2} id="fimBracketControl" />
      <BADLSubSectionFields fields={fimFields3} id="fimTransitions" />
      <BADLSubSectionFields fields={fimFields4} id="fimMobility" />
      <SummaryMessage
        messages={[
          getAssessmentTotalText(
            t(`report.HMMFIRST.feelingOfPain.occupationAndActivity.fimAssessments.fimTotal1`),
            total1
          ),
        ]}
      />
      <BADLSubSectionFields fields={fimFields5} id="fimCommunication" />
      <BADLSubSectionFields fields={fimFields6} id="fimSocialCognition" />
      <SummaryMessage
        messages={[
          getAssessmentTotalText(
            t(`report.HMMFIRST.feelingOfPain.occupationAndActivity.fimAssessments.fimTotal2`),
            total2
          ),
        ]}
      />
      <SummaryMessage
        messages={[
          getAssessmentTotalText(
            t(`report.HMMFIRST.feelingOfPain.occupationAndActivity.fimAssessments.fimGlobalTotal`),
            globalTotal
          ),
        ]}
      />
    </div>
  );
};

export default FimActivityFields;
