import React from 'react';
import { Card, Container } from '@mui/material';
import Page from '../../components/Page';
import PageHeader from '../../components/PageHeader';
import { t } from 'i18next';
import { useRecoilValue } from 'recoil';
import { userAtom } from '../../atoms/userAtom';
import EmployeeAccountingTable from './EmployeeAccountingTable';

const EmployeeAccountingPage = () => {
  const user = useRecoilValue(userAtom);
  return (
    <Page title={t('global.profile.accounting')}>
      <Container>
        <PageHeader pageTitle={t('global.profile.accounting')} />
        <Card>
          <EmployeeAccountingTable
            employeeId={user?._id || ''}
            employeeVisitRate={user?.visitRate || 0}
          />
        </Card>
      </Container>
    </Page>
  );
};

export default EmployeeAccountingPage;
