import React from 'react';
import { styled } from '@mui/system';
import { useTranslation } from 'react-i18next';
import DownloadButtonWithTooltip from '../financialReports/DownloadButtonWithTooltip';
import { downloadExcelReport, ISheetColumn } from '../../utils/ExcelReportUtils';
import { IPositionReleaseReport, IProgramReleaseReport, ReportData } from '../../interfaces/Models';
import { toast } from 'react-toastify';
import { EMPLOYEE_POSITIONS, VISIT_STATUS_TO_HEBREW } from '../../common/Constants';

const RootStyle = styled('div')({
  display: 'flex',
  justifyContent: 'flex-end',
});

export const FilterContainer = styled('div')({
  minWidth: '15%',
});

interface IProgramsReleaseReportsHeader {
  reports?: IProgramReleaseReport[];
  columns: ISheetColumn[];
}
const ProgramsReleaseReportsHeader = ({ reports, columns }: IProgramsReleaseReportsHeader) => {
  const { t } = useTranslation();
  const onDownloadReport = () => {
    if (reports) {
      const sheetData: ReportData[] = [];

      reports.forEach((data: ReportData) => {
        const sheetRow: any = {};
        columns.forEach((column: ISheetColumn) => {
          if (!column.noExcelSupport) {
            sheetRow[column.duplicateHeader ? `${column.Header} 1` : column.Header] =
              typeof column.accessor == 'string' ? data[column.accessor] : column.accessor(data);
          }
        });
        sheetRow[t('program.releaseReports.releaseStatus')] = t(
          `program.releaseReports.releaseStatusValues.${data.patientReleaseStatus || 'NOT_DECIDED'}`
        );

        Object.values(EMPLOYEE_POSITIONS).forEach((position: string) => {
          const positionReport = data.positionsReleaseReports.find(
            (report: IPositionReleaseReport) => report.position == position
          );
          if (positionReport) {
            sheetRow[t(`global.positions.${position}`)] = positionReport.isVisitDone
              ? VISIT_STATUS_TO_HEBREW.DONE
              : VISIT_STATUS_TO_HEBREW.PENDING;
          } else {
            sheetRow[t(`global.positions.${position}`)] = '-';
          }
        });

        sheetData.push(sheetRow);
      });

      downloadExcelReport(t('global.excelReports.programsReleaseReports'), [
        { sheetName: t('global.excelReports.programsReleaseReports'), sheetData },
      ]);
    } else {
      toast.error(t('global.errors.report'));
    }
  };
  return (
    <RootStyle>
      <FilterContainer>
        <DownloadButtonWithTooltip onDownloadReport={onDownloadReport} />
      </FilterContainer>
    </RootStyle>
  );
};

export default ProgramsReleaseReportsHeader;
