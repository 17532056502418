/* eslint-disable new-cap */
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useParams, useOutletContext } from 'react-router-dom';
import * as Yup from 'yup';
import { IReport } from '../../../../interfaces/Models';
import { reportTypes } from '../../../../common/Constants';
import HHDFirstFields from './fields/HHDFirstFields';
import ReportsForm from '../../../../components/Form/ReportsForm';
import ErrorMessage from '../../../../components/ErrorMessage';

const HHDFirst = () => {
  const { t } = useTranslation();
  const { visitId } = useParams();
  const data: IReport = useOutletContext();

  const initialValues =
    data && data?.name
      ? data
      : {
          name: reportTypes.HMM_ADJUSTMENT_FIRST,
          visit: visitId,
          data: data ? data : {},
        };

  const ReportSchema = Yup.object({
    name: Yup.string().required(t('clinic.form.required')),
    visit: Yup.string().required(t('clinic.form.required')),
    data: Yup.object().shape({}),
  });

  if (data && data.name != reportTypes.HMM_ADJUSTMENT_FIRST) {
    return <ErrorMessage error={t('global.errors.somethingWrong')} />;
  }

  const htmlId = 'HMM_ADJUSTMENT_FIRST';
  return (
    <ReportsForm
      initialValues={initialValues}
      visitId={visitId || ''}
      validationSchema={ReportSchema}
    >
      <HHDFirstFields id={htmlId} visitId={visitId || ''} />
    </ReportsForm>
  );
};

export default HHDFirst;
