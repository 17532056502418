import React from 'react';
import { IPatientProgram } from '../../../interfaces/Models';
import { IVisitWithProgramTitle } from '../../../layouts/reports/ReportLayout';
import { Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import DownloadButtonWithTooltip from '../../financialReports/DownloadButtonWithTooltip';
import { LEUMIT_REPORT_TYPES, LeumitReportType } from './MissingLeumitRecordsTableHeader';
import {
  getLeumitMissingProgramsColumns,
  getLeumitMissingVisitsColumns,
} from '../../../common/leumitReport';
import { downloadExcelReport, getSheetData } from '../../../utils/ExcelReportUtils';
import { toast } from 'react-toastify';

interface MissingLeumitRecordsReportHeaderProps {
  programs: IPatientProgram[];
  visits: IVisitWithProgramTitle[];
  reportType?: LeumitReportType;
}
export default function MissingLeumitRecordsReportHeader({
  programs,
  visits,
  reportType,
}: MissingLeumitRecordsReportHeaderProps) {
  const { t } = useTranslation();

  const sheetName = 'report';
  const reportName = t(`report.leumitReportTypes.${reportType}`);

  const getProgramExcelReportData = () => {
    const columns = getLeumitMissingProgramsColumns(t);

    const reportData = getSheetData(programs, columns);

    return reportData;
  };

  const getVisitExcelReportData = () => {
    const columns = getLeumitMissingVisitsColumns(t);

    const reportData = getSheetData(visits, columns);
    return reportData;
  };

  const onDownloadReport = () => {
    if (reportType) {
      const isProgramsReport =
        reportType === LEUMIT_REPORT_TYPES.MISSING_PAYMENT_METHOD ||
        reportType === LEUMIT_REPORT_TYPES.MISSING_PROGRAMS_REFERENCE_NUMBER;

      const isVisitReport = reportType === LEUMIT_REPORT_TYPES.MISSING_VISITS_REFERENCE_NUMBER;

      let reportData: any;

      if (isProgramsReport) {
        reportData = getProgramExcelReportData();
      }
      if (isVisitReport) {
        reportData = getVisitExcelReportData();
      }

      downloadExcelReport(reportName, [{ sheetName, sheetData: reportData }]);
    } else {
      toast.info(t('plsSelectReportType'));
    }
  };

  return (
    <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'}>
      <Typography variant={'h6'}>{t('report.missingLeumitReportRecords')}</Typography>
      <DownloadButtonWithTooltip onDownloadReport={onDownloadReport} />
    </Stack>
  );
}
