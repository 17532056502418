import { SelectChangeEvent, TextField } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { IPractice, IPracticeField } from '../../../interfaces/Models';

interface PracticeTextFieldInputProps {
  practice: IPractice;
  field: IPracticeField;
  subFieldIdx: number;
  onChangeSubCategoryField: (
    fieldIdx: number,
    subFieldIdx: number,
    event: SelectChangeEvent<string> | React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
  fieldIdx: number;
}
const PracticeTextFieldInput = ({
  practice,
  field,
  subFieldIdx,
  onChangeSubCategoryField,
  fieldIdx,
}: PracticeTextFieldInputProps) => {
  const { t } = useTranslation();

  return (
    <TextField
      fullWidth
      id={`${field.name}_Filter`}
      value={practice.fields[subFieldIdx].value}
      name={field.name}
      type={field.fieldType || 'text'}
      disabled={practice.added}
      label={t(`report.practiceFields.fields.${field.name}`)}
      onChange={(e) => onChangeSubCategoryField(fieldIdx, subFieldIdx, e)}
      error={(field.errors?.length || false) > 1}
      helperText={
        field.errors && field.errors.length > 1
          ? field.errors
          : field.helperText
          ? t(`report.practiceFields.fields.${field.helperText}`)
          : ''
      }
    />
  );
};

export default PracticeTextFieldInput;
