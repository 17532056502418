/* eslint-disable new-cap */
import React, { useMemo } from 'react';
import { IUser } from '../../interfaces/Models';
import { useParams, useNavigate } from 'react-router-dom';
import UserService from '../../services/UserService';
import useService from '../../hooks/HookService';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import FetcherService from '../../hooks/FetcherService';
import Form from '../../components/Form/FormikForm';
import UserFormFields from './UserFormFields';
import { styled } from '@mui/system';
import { theme } from '../../theme';
import { Box, Grid, Typography } from '@mui/material';
import ErrorMessage from '../../components/ErrorMessage';
import { isValidIdNumber } from '../../utils/Helper';
import OverlaySpinner from '../../components/OverlaySpinner';
import { STRONG_PASSWORD_REGEX } from '../../common/Constants';

const StyledBox = styled(Box, { name: 'StyledBox', slot: 'Wrapper' })({
  flexGrow: 1,
  paddingTop: theme.spacing(7),
});

const StyledTypography = styled(Typography)({
  textAlign: 'center',
  marginBottom: theme.spacing(3),
});

const UserForm: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { userId } = useParams();

  const { isLoading, data, error, errorMessage } = useService<IUser>({
    service: UserService.getOne(userId || ''),
    deps: [],
  });

  const initialValues = useMemo(
    () =>
      userId
        ? data
        : {
            email: '',
            password: '',
            confirmPassword: '',
            idNumber: '',
            visitRate: '',
            licenseNumber: '',
            role: '',
            position: '',
            personalDetails: {
              firstName: '',
              lastName: '',
              birthDate: null,
              primaryPhone: '',
              secondaryPhone: '',
              martialStatus: '',
              address: '',
              gender: '',
            },
            startDate: null,
            endDate: null,
          },
    [isLoading]
  );

  const UserSchema = Yup.object({
    email: Yup.string().email(t('user.form.emailInvaild')).required(t('clinic.form.required')),
    password: userId
      ? Yup.string().test('', t('clinic.form.required'), () => true)
      : Yup.string()
          .required(t('clinic.form.required'))
          .matches(STRONG_PASSWORD_REGEX, t('user.form.strongPasswordValidation')),
    confirmPassword: userId
      ? Yup.string().test('', t('clinic.form.required'), () => true)
      : Yup.string()
          .oneOf([Yup.ref('password'), null], t('user.form.matchPasswords'))
          .required(t('clinic.form.required')),
    idNumber: Yup.string()
      .min(9, t('global.errors.idNumberLength'))
      .test('idNumber', t('patient.form.inValidIdNumber'), function (value) {
        return isValidIdNumber(value || '');
      })
      .required(t('clinic.form.required')),
    visitRate: Yup.number().integer().default(0).required(t('clinic.form.required')),
    role: Yup.string().required(t('clinic.form.required')),
    personalDetails: Yup.object().shape({
      firstName: Yup.string().required(t('clinic.form.required')),
      lastName: Yup.string().required(t('clinic.form.required')),
      primaryPhone: Yup.string().required(t('clinic.form.required')),
    }),
  });

  const handleAddEditClinic = async (record: IUser): Promise<void> => {
    if (record._id) {
      await FetcherService<IUser>({
        service: UserService.edit(record._id as string, {
          ...record,
          idNumber: record.idNumber.toString(),
        }),
      });
    } else {
      await FetcherService<IUser>({
        service: UserService.add({ ...record, idNumber: record.idNumber.toString() }),
      });
    }

    navigate('/app/user');
  };

  const handleCancel = () => {
    navigate(-1);
  };

  const formHeader = useMemo(
    () =>
      userId
        ? `${t('user.form.editUser')}
          ${data?.personalDetails.firstName}
          ${data?.personalDetails.lastName}`
        : t('user.form.addUser'),
    [isLoading]
  );

  if (isLoading) {
    return <OverlaySpinner />;
  }
  if (error) {
    return <ErrorMessage error={errorMessage} />;
  }
  return (
    <StyledBox>
      <StyledTypography variant="h3">{formHeader}</StyledTypography>
      <Grid container justifyContent="center">
        <Grid item xs={10} md={8} lg={6}>
          <Form
            onCancel={handleCancel}
            submit={handleAddEditClinic}
            initialValues={initialValues}
            validationSchema={UserSchema}
            cancelText={t('user.form.goBack')}
            submitText={userId ? t('clinic.form.edit') : t('clinic.form.add')}
          >
            <UserFormFields edit={!userId} />
          </Form>
        </Grid>
      </Grid>
    </StyledBox>
  );
};

export default UserForm;
