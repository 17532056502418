import React, { useEffect, useMemo, useState } from 'react';
import GenericField from '../../sharedFields/GenericField';
import { useTranslation } from 'react-i18next';
import {
  IOption,
  careProviderSelectField,
  difficultiesOnFunctionSelectField,
  interventionDepressionSelectField,
  phq9SelectFields,
  phq9SelectOptions,
  suicidalInterventionSelectField,
  yesNo,
} from '../../../../common/HMA';
import FormikDatePickerField from '../../../../components/Form/FormikDatePickerField';
import { StyledGrid } from '../../../../styles/FormFieldsStyles';
import { Grid, MenuItem, Stack, Typography } from '@mui/material';
import FormikSelect from '../../../../components/FormikSelect';
import SectionDivider from '../../../../components/SectionDivider';
import { sumValues } from '../../../../utils/Helper';
import { getIn, useFormikContext } from 'formik';

export default function PHQ9SectionFields({ id }: { id: string }) {
  const { t } = useTranslation();
  const { values, setFieldValue }: any = useFormikContext();
  const phq9PValues = getIn(values, 'data.PHQ9');
  const problemsValues = phq9PValues?.problems || {};
  const followUpMeetingValue = phq9PValues?.followUpMeeting;
  const recognizedMentalHealthValue = phq9PValues?.recognizedMentalHealth;

  const [problemsSum, setProblemsSum] = useState<number>(0);
  const [mainProblemsSectionSum, setMainProblemsSectionSum] = useState<number>(0);

  useEffect(() => {
    const { interestInDoingThings = 0, despondency = 0 } = problemsValues;
    const mainProblemsSectionSumValue = sumValues({ interestInDoingThings, despondency });
    const newSum =
      mainProblemsSectionSumValue > 2 ? sumValues(problemsValues) : mainProblemsSectionSumValue;
    setFieldValue('data.PHQ9.problemsSum', newSum);
    setFieldValue('data.PHQ9.mainProblemsSectionSum', mainProblemsSectionSumValue);
    setProblemsSum(newSum);
    setMainProblemsSectionSum(mainProblemsSectionSumValue);
  }, [phq9PValues]);

  const isThereFollowUpMeeting = useMemo(
    () => phq9PValues?.followUpMeeting === 'yes',
    [followUpMeetingValue]
  );
  const isThereRecognizedMentalHealth = useMemo(
    () => phq9PValues?.recognizedMentalHealth === 'yes',
    [recognizedMentalHealthValue]
  );

  useEffect(() => {
    if (!isThereRecognizedMentalHealth) {
      setFieldValue('data.PHQ9.lastVisitDate', null);
    }
    if (!isThereFollowUpMeeting) {
      setFieldValue('data.PHQ9.followUpMeetingDate', null);
    }
  }, [isThereFollowUpMeeting, isThereRecognizedMentalHealth]);

  return (
    <div id={id}>
      <Typography variant="subtitle1" gutterBottom align="left">
        {t('report.HMAFIRST.PHQ9.header')}
      </Typography>
      <StyledGrid container spacing={2} id={`${id}_Container`}>
        <Grid item xs={12} sm={6}>
          <FormikSelect
            id={`${id}_recognizedMentalHealth`}
            name={`data.PHQ9.recognizedMentalHealth`}
            label={t(`report.HMAFIRST.PHQ9.section1.recognizedMentalHealth`)}
            formControl={{
              width: '100%',
            }}
            formHelperText={{
              children: t(`report.HMAFIRST.PHQ9.section1.recognizedMentalHealthHelperText`),
            }}
          >
            {yesNo.map((option: IOption) => {
              return (
                <MenuItem key={`recognizedMentalHealth_${option.label}`} value={option.value}>
                  {t(`global.form.select.${option.label}`)}
                </MenuItem>
              );
            })}
          </FormikSelect>
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormikDatePickerField
            disabled={!isThereRecognizedMentalHealth}
            name="data.PHQ9.lastVisitDate"
            label={t('report.HMAFIRST.PHQ9.section1.lastVisitDate')}
            id={`${id}_lastVisitDate`}
            sx={{ marginBottom: 0 }}
          />
        </Grid>
      </StyledGrid>
      <GenericField
        id={`${id}_section1`}
        fields={[
          {
            name: 'data.PHQ9.recentUrgentEvent',
            label: 'report.HMAFIRST.PHQ9.section1.recentUrgentEvent',
            type: 'select',
            options: yesNo,
            smallSize: 12,
            largeSize: 6,
          },
          {
            name: 'data.PHQ9.urgentEventDetails',
            label: 'report.HMAFIRST.PHQ9.section1.urgentEventDetails',
            type: 'text',
            smallSize: 12,
            largeSize: 6,
          },
        ]}
      />
      <Typography variant="subtitle2" gutterBottom align="left">
        {t('report.HMAFIRST.PHQ9.section2.header')}
      </Typography>
      <Typography variant="body2" gutterBottom align="left">
        {t('report.HMAFIRST.PHQ9.section2.despondencyHelperText')}
      </Typography>
      <GenericField
        id={`${id}_section2_sub1`}
        fields={[
          {
            name: 'data.PHQ9.problems.interestInDoingThings',
            label: 'report.HMAFIRST.PHQ9.section2.interestInDoingThings',
            type: 'select',
            options: phq9SelectOptions,
            smallSize: 12,
            largeSize: 6,
            number: 1,
          },
          {
            name: 'data.PHQ9.problems.despondency',
            label: 'report.HMAFIRST.PHQ9.section2.despondency',
            type: 'select',
            options: phq9SelectOptions,
            smallSize: 12,
            largeSize: 6,
            number: 2,
          },
        ]}
      />
      {mainProblemsSectionSum > 2 ? (
        <GenericField id={`${id}_section2_sub2`} fields={phq9SelectFields} />
      ) : null}
      <Typography variant="subtitle1" gutterBottom>
        {t('report.HMAFIRST.PHQ9.section2.finalGradeCalculation')}: [{problemsSum}]
      </Typography>
      <Stack direction={'row'} spacing={1}>
        {[...Array(4)].map((_, idx: number) => {
          return (
            <Typography variant="subtitle2" gutterBottom>
              {t(`report.HMAFIRST.PHQ9.section2.gradeSummery.option${idx + 1}`)}
            </Typography>
          );
        })}
      </Stack>
      <SectionDivider />
      <GenericField
        id={`${id}_section3}`}
        fields={difficultiesOnFunctionSelectField}
        subHeader={t('report.HMAFIRST.PHQ9.section3.header')}
      />
      <SectionDivider />
      <GenericField
        id={`${id}_section4`}
        fields={[
          {
            name: 'data.PHQ9.desireToDie',
            label: 'report.HMAFIRST.PHQ9.section4.desireToDie',
            type: 'select',
            options: yesNo,
            smallSize: 12,
            largeSize: 6,
          },
          {
            name: 'data.PHQ9.suicidalThought',
            label: 'report.HMAFIRST.PHQ9.section4.suicidalThought',
            type: 'select',
            options: yesNo,
            smallSize: 12,
            largeSize: 6,
            helperText: 'report.HMAFIRST.PHQ9.section4.suicidalThoughtHelperText',
          },
        ]}
        subHeader={t('report.HMAFIRST.PHQ9.section4.header')}
      />
      <GenericField
        id={`${id}_section4_InterventionDepression`}
        fields={interventionDepressionSelectField}
        subHeader={t('report.HMAFIRST.PHQ9.section4.InterventionDepression.header')}
      />
      <GenericField
        id={`${id}_section4_suicidalIntervention`}
        fields={suicidalInterventionSelectField}
        subHeader={t('report.HMAFIRST.PHQ9.section4.suicidalIntervention.header')}
      />
      <GenericField
        id={`${id}_section4_careProvider`}
        fields={careProviderSelectField}
        subHeader={t('report.HMAFIRST.PHQ9.section4.careProvider.header')}
      />
      <StyledGrid container spacing={2} id={`${id}_Container`}>
        <Grid item xs={12} sm={6}>
          <FormikSelect
            id={`${id}_followUpMeeting`}
            name={`data.PHQ9.followUpMeeting`}
            label={t(`report.HMAFIRST.PHQ9.section4.careProvider.followUpMeeting`)}
            formControl={{
              width: '100%',
            }}
          >
            {yesNo.map((option: IOption) => {
              return (
                <MenuItem value={option.value}>{t(`global.form.select.${option.label}`)}</MenuItem>
              );
            })}
          </FormikSelect>
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormikDatePickerField
            disabled={!isThereFollowUpMeeting}
            name="data.PHQ9.followUpMeetingDate"
            label={t('report.HMAFIRST.PHQ9.section4.careProvider.followUpMeetingDate')}
            id={`${id}_followUpMeetingDate`}
            sx={{ marginBottom: 0 }}
          />
        </Grid>
      </StyledGrid>
      <GenericField
        id={`${id}_section4_careProvider_note`}
        fields={[
          {
            name: 'data.PHQ9.careProviderNote',
            label: 'report.HMKFIRST.remarks',
            type: 'text',
            rows: 2,
            smallSize: 12,
            largeSize: 12,
          },
          {
            name: 'data.PHQ9.training',
            label: 'report.HMAFIRST.PHQ9.section4.careProvider.training',
            type: 'text',
            rows: 2,
            smallSize: 12,
            largeSize: 12,
          },
        ]}
      />
      <SectionDivider />
    </div>
  );
}
