import React from 'react';
import FormModal from '../../../components/Modals/FormModal';
import { IStaff, IUserFlat, IVisit } from '../../../interfaces/Models';
import { useTranslation } from 'react-i18next';
import { Field, Formik } from 'formik';
import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  SelectChangeEvent,
  Stack,
  SxProps,
  TextField,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { Select, Switch } from 'formik-mui';
import { styled } from '@mui/system';
import { theme } from '../../../theme';
import { EMPLOYEE_POSITIONS, PHONE } from '../../../common/Constants';
import { format } from 'date-fns';
import { ReportFormFooter } from '../../../styles/FormFieldsStyles';
import { FormikDisabler } from '../../../hooks/FormikDisabler';
import { ADJUSTMENT_REPORT, allReportPositions } from '../../../common/Program';
import { checkIfVisitIsPhoneReport } from '../../../utils/Helper';

const StyledBox = styled(Box, {
  name: 'StyledBox',
  slot: 'Wrapper',
})({
  paddingLeft: theme.spacing(4),
  paddingRight: theme.spacing(4),
  marginTop: theme.spacing(1.2),
});

const sxFormControl: SxProps = {
  mb: 2,
  width: '100%',
};

interface VisitFormProps {
  open: boolean;
  onClose: () => void;
  visitShema: any;
  onSubmit: (value: IVisit) => void;
  initialValues: any;
  staff: IStaff[];
  employees: IUserFlat[];
  onDeleteVisit: () => void;
}
const VisitForm = ({
  open,
  onClose,
  visitShema,
  onSubmit,
  initialValues,
  staff,
  employees,
  onDeleteVisit,
}: VisitFormProps) => {
  const { t } = useTranslation();

  const getEmployees = async (position: string) => {
    return await staff.filter((employee: IStaff) => employee.position == position);
  };

  const automaticPositions = ['HMB_GENERAL', 'HMP_TABLE_FIRST', 'HMP_TABLE_RELEASE'];

  return (
    <FormModal
      open={open}
      onClose={onClose}
      text={
        initialValues.employee
          ? `${t('patient.form.editVisit')} (${format(
              new Date(initialValues.dueDate),
              'dd-MM-yyyy'
            )})`
          : `${t('patient.form.addVisit')} (${format(
              new Date(initialValues.dueDate),
              'dd-MM-yyyy'
            )})`
      }
    >
      <Formik
        initialValues={initialValues}
        onSubmit={(values: IVisit) => {
          onSubmit(values);
        }}
        validationSchema={visitShema}
      >
        {(props) => {
          const { values, isValid, dirty, handleSubmit, setFieldValue } = props;
          const employeePosition = values?.visitType?.slice(0, 3);
          const isPhoneVisit = checkIfVisitIsPhoneReport(values);
          const isAdjustmentVisit = values.visitType.includes('ADJUSTMENT');

          return (
            <form onSubmit={handleSubmit}>
              <StyledBox>
                {!isPhoneVisit && (
                  <Field
                    fullWidth
                    component={Select}
                    name="visitType"
                    label={t('patient.form.visitType')}
                    formControl={{ sx: sxFormControl }}
                    disabled={isPhoneVisit}
                    onChange={async (e: SelectChangeEvent<string>) => {
                      const { value } = e.target;
                      const isHouseAdjustmentReport = value === ADJUSTMENT_REPORT;
                      const employees = await getEmployees(value);

                      setFieldValue('visitType', value);
                      if (isHouseAdjustmentReport) {
                        setFieldValue('isRelease', false);
                      }
                      setFieldValue(
                        'employee',
                        employees && employees.length > 0 ? employees[0].employee : ''
                      );
                      setFieldValue('employees', employees);
                    }}
                  >
                    {allReportPositions.map((position: string) => {
                      return (
                        <MenuItem key={position} value={position}>
                          {t(`global.positions.${position}`)}
                        </MenuItem>
                      );
                    })}
                  </Field>
                )}
                {isPhoneVisit && (
                  <TextField
                    disabled
                    fullWidth
                    value={`${t(`global.positions.${employeePosition}`)} (${t(
                      `global.visitTypes.${PHONE}`
                    )}`}
                    label={t('patient.form.visitType')}
                    sx={{ mb: 2 }}
                  />
                )}
                <Field
                  component={Select}
                  name="employee"
                  label={t('global.roles.EMPLOYEE')}
                  formControl={{ sx: sxFormControl }}
                  fullWidth
                >
                  {employees
                    .filter((employee: IUserFlat) => {
                      const visitType = values.visitType.slice(0, 3);
                      const isHouseAdjustmentVisit = values.visitType === ADJUSTMENT_REPORT;
                      return (
                        employee.position ==
                        (isHouseAdjustmentVisit ? EMPLOYEE_POSITIONS.HMM : visitType)
                      );
                    })
                    .map((StaffMember: IUserFlat) => {
                      return (
                        <MenuItem
                          key={StaffMember._id + StaffMember.position}
                          value={StaffMember._id}
                        >
                          {`${StaffMember.fullName}`}
                        </MenuItem>
                      );
                    })}
                </Field>
                <FormControl fullWidth sx={{ marginTop: 3 }}>
                  <InputLabel shrink={true} htmlFor="tags">
                    {t('visit.table.isRelease')}
                  </InputLabel>
                  <Field
                    disabled={isAdjustmentVisit || isPhoneVisit}
                    component={Switch}
                    name="isRelease"
                    type="checkbox"
                  />
                </FormControl>
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="space-between"
                  mb={1}
                  mt={2}
                >
                  <Button
                    variant="contained"
                    disabled={!(isValid && dirty)}
                    size="large"
                    type="submit"
                    color="primary"
                  >
                    {initialValues.editing ? t('clinic.form.edit') : t('clinic.form.add')}
                  </Button>
                  <ReportFormFooter>
                    {initialValues.editing && (
                      <Button
                        sx={{ mr: initialValues.editing ? 1 : 0 }}
                        size="large"
                        variant="outlined"
                        onClick={onDeleteVisit}
                        color="error"
                      >
                        <DeleteIcon />
                      </Button>
                    )}
                    {automaticPositions.indexOf(values.visitType) > -1 ? <FormikDisabler /> : null}
                    <Button variant="contained" size="large" onClick={onClose} color="error">
                      {t('clinic.form.cancel')}
                    </Button>
                  </ReportFormFooter>
                </Stack>
              </StyledBox>
            </form>
          );
        }}
      </Formik>
    </FormModal>
  );
};

export default VisitForm;
