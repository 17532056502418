/* eslint-disable @typescript-eslint/no-var-requires */
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { Box, BoxProps } from '@mui/material';
import { styled } from '@mui/material/styles';
import { decodeBase64ToUtf8, isStringBase64Encoded } from '../../utils/decode';

const RootStyle = styled(Box)(({ theme }) => ({
  '& .ck-rounded-corners': {
    minHeight: 200,
    maxHeight: 640,
    '&:focus-within': {
      '& .ck.ck-editor__top .ck-sticky-panel .ck-toolbar, .ck.ck-editor__top .ck-sticky-panel .ck-toolbar.ck-rounded-corners':
        {
          border: `solid 2px ${theme.palette.primary.main}`,
          borderBottom: `solid 1px ${theme.palette.grey[500_32]}`,
        },
    },
  },
  '& .ck-rounded-corners .ck.ck-editor__main>.ck-editor__editable, .ck.ck-editor__main> .ck-editor__editable .ck-rounded-corners':
    {
      borderRadius: theme.shape.borderRadius,
      border: `solid 1px ${theme.palette.grey[500_32]}`,
      borderTopLeftRadius: 0,
      borderTopRightRadius: 0,
      borderTopWidth: 0,
      '&:focus': {
        border: `solid 2px ${theme.palette.primary.main}`,
        borderTop: `solid 1px ${theme.palette.grey[500_32]}`,
        boxShadow: 'none',
      },
    },
  '& .ck-rounded-corners .ck.ck-editor__top .ck-sticky-panel .ck-toolbar, .ck.ck-editor__top .ck-sticky-panel .ck-toolbar.ck-rounded-corners':
    {
      borderRadius: theme.shape.borderRadius,
      border: `solid 1px ${theme.palette.grey[500_32]}`,
      borderBottomLeftRadius: 0,
      borderBottomRightRadius: 0,
    },
  '& .ck.ck-content ol, .ck.ck-content ol li ': {
    listStyleType: 'decimal',
  },
  '& .ck.ck-content ol,.ck.ck-content ul': {
    paddingLeft: '20px',
  },
  '& .ck .ck-editor__main > .ck-editor__editable.ck-read-only': {
    color: theme.palette.text.disabled,
  },
}));

export interface CKeditorProps {
  onChange?: (data: string) => void;
  value?: string;
  viewMode?: boolean;
  sx?: BoxProps;
}

export default function CKeditor({ onChange, value, viewMode = false, sx }: CKeditorProps) {
  const editorValue = value || '';

  const isBase64 = isStringBase64Encoded(editorValue);

  return (
    <>
      <RootStyle sx={sx}>
        <CKEditor
          disabled={viewMode}
          editor={ClassicEditor as any}
          data={isBase64 ? decodeBase64ToUtf8(editorValue) : editorValue}
          onChange={(event: any, editor: any) => {
            const data = editor.getData();
            if (onChange) {
              onChange(data || '');
            }
          }}
          config={{
            language: {
              ui: 'en',
              content: 'he',
            },
            toolbar: [
              'undo',
              'redo',
              '|',
              'bold',
              'italic',
              'bulletedList',
              'numberedList',
              'blockQuote',
            ],
          }}
        />
      </RootStyle>
    </>
  );
}
