/* eslint-disable new-cap */
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useOutletContext, useParams } from 'react-router-dom';
import * as Yup from 'yup';
import { IReport } from '../../../interfaces/Models';
import { reportTypes } from '../../../common/Constants';
import ErrorMessage from '../../../components/ErrorMessage';
import HMAFields from './fields/HMAFields';
import { HMAValues } from '../../../common/reportsFieldsValues/hma/HMAValues';
import ReleaseFields from '../sharedFields/ReleaseFields';
import ReportsForm from '../../../components/Form/ReportsForm';
import SectionDivider from '../../../components/SectionDivider';

const HMARelease = () => {
  const { t } = useTranslation();
  const { visitId } = useParams();
  const data: IReport = useOutletContext();

  const initialValues =
    data && data?.name
      ? data
      : {
          name: reportTypes.HMA_RELEASE,
          visit: visitId,
          data: data ? data : HMAValues,
        };

  const ReportSchema = Yup.object().shape({
    name: Yup.string().required(t('clinic.form.required')),
    visit: Yup.string().required(t('clinic.form.required')),
    data: Yup.object().shape({
      PainAssessment: Yup.object().shape({
        painDegrees: Yup.string().required(t('clinic.form.required')),
      }),
    }),
  });

  const HtmlId = 'HMARelease';

  if (data?.name && data.name != reportTypes.HMA_RELEASE) {
    return <ErrorMessage error={t('global.errors.somethingWrong')} />;
  }
  return (
    <ReportsForm
      initialValues={initialValues}
      visitId={visitId || ''}
      validationSchema={ReportSchema}
    >
      <HMAFields id={HtmlId} />
      <ReleaseFields id={HtmlId} />
      <SectionDivider />
    </ReportsForm>
  );
};

export default HMARelease;
