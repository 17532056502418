import { MenuItem } from '@mui/material';
import { Field } from 'formik';
import { TextField } from 'formik-mui';
import { t } from 'i18next';
import React from 'react';
import { assessmentOptions } from '../../../../common/assessment';
import FormikSelect from '../../../../components/FormikSelect';
import { sxFormControl } from '../../../../styles/FormFieldsStyles';

interface IAssessmentField {
  id: string;
  field: string;
}
const AssessmentField = ({ id, field }: IAssessmentField) => {
  return (
    <>
      {field.slice(field.length - 4, field.length) != 'Note' ? (
        <FormikSelect
          id={`${id}_${field}`}
          name={`data.assessment.${field}`}
          label={t(`report.physiotherapyTable.functionalAbilityTest.${field}`)}
          formControl={sxFormControl || {}}
        >
          {assessmentOptions.map((value: number) => {
            return (
              <MenuItem key={`${id}_${field}_${value}`} value={value}>
                {value}
              </MenuItem>
            );
          })}
        </FormikSelect>
      ) : (
        <Field
          id={`${id}_${field}_Note`}
          fullWidth
          component={TextField}
          type="text"
          name={`data.${field}`}
          label={t('report.practiceFields.fields.note')}
        />
      )}
    </>
  );
};

export default AssessmentField;
