/* eslint-disable new-cap */
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Form from '../../components/Form/FormikForm';
import FormModal from '../../components/Modals/FormModal';
import { IVisit, IVisitFlat, ReportData } from '../../interfaces/Models';
import DueDateFormField from '../program/hospitalDays/DueDateFormField';
import * as Yup from 'yup';
import { IVisitWithProgramTitle } from '../../layouts/reports/ReportLayout';
import FetcherService from '../../hooks/FetcherService';
import VisitService from '../../services/VisitService';

interface IEditVisitDueDateForm {
  openChangeVisitDueDate: boolean;
  handleCloseChangeVisitDueDateModal: () => void;
  record?: IVisit | IVisitFlat | IVisitWithProgramTitle;
  handlePageUpdate: () => void;
}
const EditVisitDueDateForm = ({
  openChangeVisitDueDate,
  handleCloseChangeVisitDueDateModal,
  record,
  handlePageUpdate,
}: IEditVisitDueDateForm) => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const DueDateSchema = Yup.object({
    dueDate: Yup.date().required(`${t('clinic.form.required')}`),
  });

  const handleChangeVisitDueDate = async (values: ReportData) => {
    await FetcherService<any>({
      service: VisitService.edit(record?._id || 'empty', { dueDate: values.dueDate }),
      setLoading: setIsLoading,
    });
    handleCloseChangeVisitDueDateModal();
    handlePageUpdate();
  };

  return (
    <FormModal
      open={openChangeVisitDueDate}
      onClose={handleCloseChangeVisitDueDateModal}
      text={t('program.hospitalDaysForm.editVisitDueDate')}
      loading={isLoading}
    >
      <Form
        onCancel={handleCloseChangeVisitDueDateModal}
        submit={handleChangeVisitDueDate}
        initialValues={{ dueDate: record?.dueDate }}
        validationSchema={DueDateSchema}
        cancelText={t('clinic.form.cancel')}
        submitText={t('clinic.form.edit')}
      >
        <DueDateFormField />
      </Form>
    </FormModal>
  );
};

export default EditVisitDueDateForm;
