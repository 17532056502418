import { FormControl, Grid, SxProps, Divider, TextField as TextInput } from '@mui/material';
import { styled } from '@mui/system';
import { TextField } from 'formik-mui';
import { theme } from '../theme';

export const StyledTextField = styled(TextField)({
  marginBottom: theme.spacing(2),
});

export const sxFormControl: SxProps = {
  mb: 2,
  width: '100%',
};

export const sxFormControl1: SxProps = {
  width: '100%',
};

export const StyledFormControl = styled(FormControl, {
  name: 'StyledFormControl',
  slot: 'Wrapper',
})({
  width: '100%',
  marginBottom: theme.spacing(2),
});

export const StyledGrid = styled(Grid)({
  marginBottom: theme.spacing(2),
  paddingTop: theme.spacing(1),
});

export const StyledDiv = styled('div')({
  display: 'flex',
  marginBottom: theme.spacing(1.5),
  alignItems: 'center',
  justifyContent: 'space-between',
});
export const StyledDivider = styled(Divider)({
  marginTop: theme.spacing(2),
  marginBottom: theme.spacing(2),
});

export const StyledTextInput = styled(TextInput)({
  marginBottom: theme.spacing(2),
});

export const ReportFormFooter = styled('div')({
  display: 'flex',
  gap: theme.spacing(1),
});
