/* eslint-disable new-cap */
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useParams, useOutletContext } from 'react-router-dom';
import * as Yup from 'yup';
import { IReport } from '../../../interfaces/Models';
import { reportTypes } from '../../../common/Constants';
import ErrorMessage from '../../../components/ErrorMessage';
import SectionDivider from '../../../components/SectionDivider';
import { HMSFirstValues } from '../../../common/reportsFieldsValues/hms/HMSFirstValues';
import HMSFirstFields from './fields/HMSFirstFields';
import GenericField from '../sharedFields/GenericField';
import ReportsForm from '../../../components/Form/ReportsForm';

const HMSRelease = () => {
  const { t } = useTranslation();
  const { visitId } = useParams();
  const data: IReport | null = useOutletContext();

  const initialValues =
    data && data.name
      ? data
      : {
          name: reportTypes.HMS_RELEASE,
          visit: visitId,
          data: data ? data : HMSFirstValues,
        };
  const ReportSchema = Yup.object({
    name: Yup.string().required(t('clinic.form.required')),
    visit: Yup.string().required(t('clinic.form.required')),
    data: Yup.object().shape({
      socioeconomicBackground: Yup.string().required(t('clinic.form.required')),
      socialSituation: Yup.string().required(t('clinic.form.required')),
      cognitiveStatus: Yup.string().required(t('clinic.form.required')),
      socialSecurity: Yup.string().required(t('clinic.form.required')),
      expectations: Yup.string().required(t('clinic.form.required')),
      workerInterventions: Yup.string().required(t('clinic.form.required')),
      recommendation: Yup.string().required(t('clinic.form.required')),
    }),
  });

  if (data?.name && data.name != reportTypes.HMS_RELEASE) {
    return <ErrorMessage error={t('global.errors.somethingWrong')} />;
  }
  const HtmlId = 'HMSRelease';
  return (
    <ReportsForm
      initialValues={initialValues}
      visitId={visitId || ''}
      validationSchema={ReportSchema}
    >
      <HMSFirstFields id={HtmlId} />
      <SectionDivider />
      <GenericField
        id={`${HtmlId}_ReleaseFields`}
        fields={[
          {
            name: 'data.recommendation',
            label: 'report.releaseSection.recommendation',
            type: 'text',
            rows: 3,
            smallSize: 12,
            largeSize: 12,
          },
        ]}
        subHeader={t(`report.releaseSection.header`)}
      />
      <SectionDivider />
    </ReportsForm>
  );
};

export default HMSRelease;
