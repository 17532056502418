import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Stack, Typography } from '@mui/material';
import GenericField from '../../sharedFields/GenericField';
import { yesNo } from '../../../../common/HMA';
import SectionDivider from '../../../../components/SectionDivider';
import { sumValues } from '../../../../utils/Helper';

const illnessOptions = [
  {
    value: 0,
    label: 0,
  },
  {
    value: 2,
    label: 2,
  },
];

const weightLossOptions = [
  {
    value: 0,
    label: 0,
  },
  {
    value: 1,
    label: 1,
  },
  {
    value: 2,
    label: 2,
  },
];

const bmiOptions = [
  {
    value: 0,
    label: 0,
  },
  {
    value: 1,
    label: 1,
  },
  {
    value: 2,
    label: 2,
  },
];
interface NutritionalEstimateFieldsProps {
  id: string;
  nutritionalDisorderValues?: Object;
  onChangeFormikValue: (field: string, value: any, shouldValidate?: boolean) => void;
}
const NutritionalEstimateFields = ({
  id,
  nutritionalDisorderValues,
  onChangeFormikValue,
}: NutritionalEstimateFieldsProps) => {
  const { t } = useTranslation();
  const [nutritionalDisorderSum, setNutritionalDisorderSum] = useState<number>(0);

  useEffect(() => {
    const newSum = sumValues(nutritionalDisorderValues || {});
    setNutritionalDisorderSum(newSum);
    onChangeFormikValue('data.nutritionalEstimate.nutritionalEstimateSummery', newSum);
  }, [nutritionalDisorderValues]);

  return (
    <div id={id}>
      <GenericField
        id={`${id}_followingAccident`}
        fields={[
          {
            name: 'data.nutritionalEstimate.followingAccident',
            label: 'report.HMAFIRST.nutritionalEstimate.section1.followingAccident',
            type: 'select',
            options: yesNo,
            smallSize: 12,
            largeSize: 6,
          },
          {
            name: 'data.nutritionalEstimate.reason',
            label: 'report.HMAFIRST.nutritionalEstimate.section1.reason',
            type: 'text',
            rows: 1,
            smallSize: 12,
            largeSize: 6,
          },
        ]}
        header={t('report.HMAFIRST.nutritionalEstimate.header')}
      />
      <GenericField
        id={`${id}_nutritionalDisorder`}
        fields={[
          {
            name: 'data.nutritionalEstimate.nutritionalDisorder.illness',
            label: 'report.HMAFIRST.nutritionalEstimate.section2.illness',
            type: 'select',
            options: illnessOptions,
            smallSize: 12,
            largeSize: 12,
            helperText: `0- עד 5 ימים (מחלה חריפה / חוסר אכילה) .\n
            2- מעל 5 ימים (בניקוד 2 או יותר הפנו להערכה תזונתית או לרופא, המשיכו במעקב )  .\n
            `,
          },
          {
            name: 'data.nutritionalEstimate.nutritionalDisorder.weightLoss',
            label: 'report.HMAFIRST.nutritionalEstimate.section2.weightLoss',
            type: 'select',
            options: weightLossOptions,
            smallSize: 12,
            largeSize: 12,
            helperText: `0- 5% ומטה (איבוד משקל ב 3-6 חודשים אחרונים) .\n
            1- 5-10% (איבוד משקל ב 3-6 חודשים אחרונים) .\n
            2- ומעלה 10% .\n
            `,
          },
        ]}
        sx={{ p: 0 }}
      />
      <Typography variant="subtitle1" gutterBottom>
        {t('report.HMAFIRST.nutritionalEstimate.finalGrade')} [{nutritionalDisorderSum}]
      </Typography>
      <Stack direction={'row'} spacing={1}>
        <Typography variant="subtitle2" gutterBottom>
          {'0 - ללא סיכון,'}
        </Typography>
        <Typography variant="subtitle2" gutterBottom>
          {'1- סיכון נמוך - מחייב טיפול שגרתי,'}
        </Typography>
        <Typography variant="subtitle2" gutterBottom>
          {'2- סיכון בינוני - מחייב השגחה רפואי,'}
        </Typography>
        <Typography variant="subtitle2" gutterBottom>
          {'מעל 2 - סיכון גבוה מחייב טיפול'}
        </Typography>
      </Stack>
      <GenericField
        id={`${id}_bmi`}
        fields={[
          {
            name: 'data.nutritionalEstimate.bmi',
            label: 'report.HMAFIRST.nutritionalEstimate.section3.bmi',
            type: 'select',
            options: bmiOptions,
            smallSize: 12,
            largeSize: 12,
            helperText: 'report.HMAFIRST.nutritionalEstimate.section3.bmiHelperText',
          },
          {
            name: 'data.nutritionalEstimate.tutorial',
            label: 'report.HMAFIRST.nutritionalEstimate.section3.tutorial',
            type: 'text',
            rows: 1,
            smallSize: 12,
            largeSize: 12,
          },
        ]}
      />
      <SectionDivider />
    </div>
  );
};

export default NutritionalEstimateFields;
