import { Box, Stack, Typography } from '@mui/material';
import React from 'react';
import { ILeumitReportDataSummery } from './LeumitClinicReportForm';
import { useTranslation } from 'react-i18next';
import { styled } from '@mui/system';

const StyledBox = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.grey[300],
  padding: theme.spacing(2),
  color: theme.palette.text.primary,
  width: '100%',
}));

interface ILeumitClinicReportSummeryProps {
  summery: ILeumitReportDataSummery;
}

export default function LeumitClinicReportSummery(props: ILeumitClinicReportSummeryProps) {
  const { t } = useTranslation();
  const { summery } = props;
  const { totalProgramRows, totalVisitsRows, totalAmount, totalPatients } = summery;

  const summeryRows = [
    {
      title: t('leumitReport.totalProgramRows'),
      value: totalProgramRows,
    },
    {
      title: t('leumitReport.totalVisitsRows'),
      value: totalVisitsRows,
    },
    {
      title: t('leumitReport.totalPatients'),
      value: totalPatients,
    },
    {
      title: t('financialReports.employees.totalAmount'),
      value: `${totalAmount} ${t('global.currencyUnits.nis')}`,
    },
  ];
  return (
    <StyledBox>
      <Stack direction={{ xs: 'column', sm: 'row' }} gap={2} justifyContent={'space-between'}>
        {summeryRows.map((row, index) => (
          <Typography
            key={`${index}-${row.title}`}
            // variant="h6"
          >{`${row.title} : ${row.value}`}</Typography>
        ))}
      </Stack>
    </StyledBox>
  );
}
