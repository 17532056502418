import { useAbility } from '@casl/react';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Actions, Subject } from '../casl/ability';
import { AbilityContext } from '../casl/can';

export default function useAccess(abilityType: Actions, abilityName: Subject) {
  const navigate = useNavigate();
  const ability = useAbility(AbilityContext);

  const userAbility = ability.can(abilityType, abilityName);
  useEffect(() => {
    if (!userAbility) {
      navigate('/403');
    }
  }, []);
  return {};
}
