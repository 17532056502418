import React from 'react';
import { styled } from '@mui/system';
import { theme } from '../../../theme';
import DateRangeFilter from '../../../components/DateRangeFilter';

const RootStyle = styled('div')({
  display: 'flex',
  justifyContent: 'flex-end',
  gap: theme.spacing(1),
});

export const FilterContainer = styled('div')({
  minWidth: '15%',
  direction: 'rtl',
});
export default function EmployeeVisitsDelayCalculationReportTableHeader() {
  return (
    <RootStyle>
      <FilterContainer>
        <DateRangeFilter />
      </FilterContainer>
    </RootStyle>
  );
}
