import React from 'react';
import GenericField from '../../../sharedFields/GenericField';
import { useTranslation } from 'react-i18next';
import EditorField from '../../../../../components/Form/EditorField';
import { useFormikContext, getIn } from 'formik';

const HHDCommonFields = ({ id }: { id: string }) => {
  const { t } = useTranslation();
  const { values }: any = useFormikContext();
  const reportBodyValue = getIn(values, 'data.reportBody');

  return (
    <>
      <GenericField
        id={`${id}_title`}
        fields={[
          {
            name: 'data.title',
            label: 'report.HHDFIRST.title',
            type: 'text',
            rows: 1,
            smallSize: 12,
            largeSize: 12,
          },
        ]}
      />
      <EditorField
        label={t('report.HHDFIRST.reportBody')}
        value={reportBodyValue}
        name={'data.body'}
        sx={{ mb: 2 }}
      />
    </>
  );
};

export default HHDCommonFields;
