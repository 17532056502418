import { IField } from './HMA';

export const releaseSummaryAndRecommendationsFields: IField[] = [
  {
    name: 'data.summary',
    label: 'report.releaseSection.summary',
    type: 'text',
    rows: 5,
    smallSize: 12,
    largeSize: 12,
  },
  {
    name: 'data.recommendation',
    label: 'report.releaseSection.recommendation',
    type: 'text',
    rows: 5,
    smallSize: 12,
    largeSize: 12,
  },
];

export const HMDReleaseFields: IField[] = [
  {
    name: 'data.note',
    label: 'report.releaseSection.note',
    type: 'text',
    rows: 5,
    smallSize: 12,
    largeSize: 12,
  },
  {
    name: 'data.recommendation',
    label: 'report.releaseSection.recommendation',
    type: 'text',
    rows: 5,
    smallSize: 12,
    largeSize: 12,
  },
];

export const HMKReleaseFields: IField[] = [
  {
    name: 'data.receipt',
    label: 'report.releaseSection.receipt',
    type: 'text',
    rows: 5,
    smallSize: 12,
    largeSize: 12,
  },
  {
    name: 'data.difficulties',
    label: 'report.releaseSection.difficulties',
    type: 'text',
    rows: 5,
    smallSize: 12,
    largeSize: 12,
  },
  {
    name: 'data.swallowing',
    label: 'report.releaseSection.swallowing',
    type: 'text',
    rows: 5,
    smallSize: 12,
    largeSize: 12,
  },
  {
    name: 'data.oralTest',
    label: 'report.releaseSection.oralTest',
    type: 'text',
    rows: 5,
    smallSize: 12,
    largeSize: 12,
  },
  {
    name: 'data.intervention',
    label: 'report.releaseSection.intervention',
    type: 'text',
    rows: 5,
    smallSize: 12,
    largeSize: 12,
  },
  {
    name: 'data.statusChange',
    label: 'report.releaseSection.statusChange',
    type: 'text',
    rows: 5,
    smallSize: 12,
    largeSize: 12,
  },
  {
    name: 'data.recommendation',
    label: 'report.releaseSection.recommendation',
    type: 'text',
    rows: 5,
    smallSize: 12,
    largeSize: 12,
  },
];

export const HMPReleaseFields: IField[] = [
  {
    name: 'data.physicalCondition.status',
    label: 'report.HMPFIRST.physicalCondition.status1',
    type: 'text',
    rows: 5,
    smallSize: 12,
    largeSize: 12,
  },
  {
    name: 'data.summary',
    label: 'report.releaseSection.summary',
    type: 'text',
    rows: 5,
    smallSize: 12,
    largeSize: 12,
  },
];

export const statusReleaseFields = [
  'walk',
  'stairs',
  'sittingLyingDown',
  'lyingToSitting',
  'sittingStanding',
  'standingSitting',
  'coarseUpperRight',
  'coarseUpperLeft',
  'coarseLowerRight',
  'coarseLowerLeft',
  'rangeMotion',
  'equilibrium',
  'dangerFalls',
  'cooperation',
  'helpBADL',
  'helpIADL',
];
