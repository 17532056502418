import { useSearchParams } from 'react-router-dom';
import { URL_QUERY_PARAMS } from '../common/Constants';
import { ReportData } from '../interfaces/Models';
import useMatchRoute from './useMatchRoute';

interface IQueryParam {
  name?: string;
  value?: string;
}
export default function useSearchFilter() {
  const [searchParams, setSearchParams] = useSearchParams();
  const isDashboardPage = useMatchRoute('/app/:param', 'dashboard');

  const pageInfo: ReportData = {};
  const params = searchParams.entries();
  const localStorageQueryParams = [
    URL_QUERY_PARAMS.FROM,
    URL_QUERY_PARAMS.TO,
    URL_QUERY_PARAMS.RANGE,
    URL_QUERY_PARAMS.MONTH,
    URL_QUERY_PARAMS.YEAR,
  ];

  const changeQueryParams = (params: IQueryParam[], deletedParams?: string[]) => {
    params.map((param: IQueryParam) => {
      if (param.name) {
        searchParams.set(param.name, encodeURIComponent(param.value || ''));
      }
    });

    if (deletedParams) {
      deletedParams.map((param: string) => {
        searchParams.delete(param);
      });
    }
    setSearchParams(searchParams);
  };

  const getSearchParam = (param: string) => {
    return searchParams.get(param);
  };
  const deleteSearchQuery = (deletedParams: string[]) => {
    deletedParams.map((param: string) => {
      searchParams.delete(param);
    });
    setSearchParams(searchParams);
  };

  const range = localStorage.getItem(URL_QUERY_PARAMS.RANGE);
  const fromDate = localStorage.getItem(URL_QUERY_PARAMS.FROM);
  const toDate = localStorage.getItem(URL_QUERY_PARAMS.TO);
  const month = localStorage.getItem(URL_QUERY_PARAMS.MONTH);
  const year = localStorage.getItem(URL_QUERY_PARAMS.YEAR);

  const filtersNames: string[] = [];
  for (const entry of params as unknown as string[]) {
    filtersNames.push(entry[0]);
  }

  filtersNames.map((filter: string) => {
    pageInfo[filter] = searchParams.get(filter) ? decodeURI(searchParams.get(filter) || '') : null;
  });

  if (isDashboardPage && (range || (fromDate && toDate) || month || year)) {
    localStorageQueryParams.forEach((param: string) => {
      const localStorageValue = localStorage.getItem(param);
      pageInfo[param] = localStorageValue || null;
    });
  }

  const clearQueryParams = () => {
    setSearchParams({});
  };

  return {
    pageInfo,
    deleteSearchQuery,
    changeQueryParams,
    getSearchParam,
    clearQueryParams,
  };
}
