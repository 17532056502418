export const HMBFirstValues = {
  essentialSigns: {
    bloodPressure: '',
    pulse: '',
    temperature: '',
    bloodPressureStanding: '',
    pulseStanding: '',
    sturgeon: '',
    sturgeonNote: '',
  },
  PainAssessment: {
    painDegrees: '',
    painNotes: '',
  },
  cognitiveTests: {
    timeOrientation: '',
    positionOrientation: '',
    MINICOG: '',
  },
  physicalExamination: {
    HeadExam: '',
    AEGExam: '',
    EyesExam: '',
    LungsExam: '',
    HeartExam: '',
    BellyExam: '',
    LimbsExam: '',
  },
  neurologicalExamination: {
    consciousnessExam: '',
    OrientationExam: '',
    forceExam: '',
    feelExam: '',
    CranialExam: '',
    CerebralExam: '',
    PyramidalExam: '',
  },
  summary: '',
  releasePlan: '',
};
