export const HMkFirstValues = {
  currentDifficulties: {
    hearing: '',
    hearingNotes: '',
    swallowing: '',
    swallowingNotes: '',
    memory: '',
    memoryNotes: '',
    speech: '',
    speechNotes: '',
    speechUnderstanding: '',
    speechUnderstandingNotes: '',
    speechExpressive: '',
    speechExpressiveNotes: '',
  },
  swallowing: {
    swallowLevel: '',
    swallowLevelNotes: '',
    afterSwallow: '',
    afterSwallowNotes: '',
    ingestion: '',
    ingestionNotes: '',
    chewSwallow: '',
    chewSwallowNotes: '',
    voiceSwallow: '',
    voiceSwallowNotes: '',
    airSwallow: '',
    airSwallowNotes: '',
    difficulties: '',
    difficultiesNotes: '',
    textureTest: '',
    textureTestNotes: '',
  },
  oralTest: '',
  swallowingReflex: '',
  rehabilitation: '',
  furtherTreatment: '',
};
