import { IField, yesNo } from './HMA';

const collaboratorOptions = [
  ...yesNo,
  {
    value: 'partial',
    label: 'partial',
  },
];
const vigilanceOptions = [
  {
    value: 'alert',
    label: 'alert',
  },
  {
    value: 'sleepy',
    label: 'sleepy',
  },
  {
    value: 'unconscious',
    label: 'unconscious',
  },
  {
    value: 'mildDementia',
    label: 'mildDementia',
  },
  {
    value: 'severeDementia',
    label: 'severeDementia',
  },
];
const abilityOptions = [
  {
    value: 'independent',
    label: 'independent',
  },
  {
    value: 'monitoring',
    label: 'monitoring',
  },
  // removed from list on 21.10.2023 -- client request
  // {
  //   value: 'needHelp',
  //   label: 'needHelp',
  // },
  {
    value: 'partialHelp',
    label: 'partialHelp',
  },
  {
    value: 'fullHelp',
    label: 'fullHelp',
  },
  {
    value: 'restrained',
    label: 'restrained',
  },
];

const examinationsOptions = [
  { value: 1 },
  { value: 2 },
  { value: 3 },
  { value: 4 },
  { value: 5 },
  { value: 'plusOne' },
  { value: 'plusTwo' },
  { value: 'plusThree' },
  { value: 'plusFour' },
  { value: 'plusFive' },
];

const motionOptions = [
  { value: 'proper', label: 'proper' },
  { value: 'notValid', label: 'notValid' },
  { value: 'full', label: 'full' },
];

const equilibriumOptions = [
  { value: 'proper', label: 'proper' },
  { value: 'notValid', label: 'notValid' },
  { value: 'risk', label: 'risk' },
];
const vasOptions = [
  { value: 1 },
  { value: 2 },
  { value: 3 },
  { value: 4 },
  { value: 5 },
  { value: 6 },
  { value: 7 },
  { value: 8 },
  { value: 9 },
  { value: 10 },
];
const transitionsOptions = [
  { value: 'independent' },
  { value: 'easyHelp' },
  { value: 'mediumHelp' },
  { value: 'greatHelp' },
];
const wakingMethodsoptions = [
  { value: 'independent' },
  { value: 'fourSticks' },
  { value: 'anotherPerson' },
  { value: 'onePersonHelp' },
  { value: 'twoPeopleHelp' },
];

const accessoriesOptions = [
  { value: 'walkingStick' },
  { value: 'stick' },
  { value: 'walker' },
  { value: 'wheelchair' },
];

// TODO: check why this not used
// eslint-disable-next-line no-unused-vars
const climbingDescendingOptions = [{ value: 'independent' }, { value: 'withEscort' }];

export const lowerBottomFields: IField[] = [
  {
    name: 'data.physicalCondition.walk.descriptionWalking',
    label: 'report.HMPFIRST.physicalCondition.walk.descriptionWalking',
    type: 'text',
    rows: 3,
    smallSize: 12,
    largeSize: 12,
  },
  {
    name: 'data.physicalCondition.difficultiesOfTreatment',
    label: 'report.HMPFIRST.physicalCondition.difficultiesOfTreatment',
    type: 'text',
    rows: 3,
    smallSize: 12,
    largeSize: 12,
  },
  {
    name: 'data.physicalCondition.recommendation',
    label: 'report.HMPFIRST.physicalCondition.recommendations',
    type: 'text',
    rows: 3,
    smallSize: 12,
    largeSize: 12,
  },
];
export const walkingFields: IField[] = [
  {
    name: 'data.physicalCondition.walk.howToWalk',
    label: 'report.HMPFIRST.physicalCondition.walk.howToWalk',
    type: 'select',
    options: wakingMethodsoptions,
    smallSize: 12,
    largeSize: 6,
  },
  {
    name: 'data.physicalCondition.walk.howToWalkNote',
    label: 'report.HMPFIRST.physicalCondition.walk.howToWalk',
    type: 'note',
    smallSize: 12,
    largeSize: 6,
  },
  {
    name: 'data.physicalCondition.walk.accessories',
    label: 'report.HMPFIRST.physicalCondition.walk.accessories',
    type: 'select',
    options: accessoriesOptions,
    smallSize: 12,
    largeSize: 6,
  },
  {
    name: 'data.physicalCondition.walk.accessoriesNote',
    label: 'report.HMPFIRST.physicalCondition.walk.accessories',
    type: 'note',
    smallSize: 12,
    largeSize: 6,
  },
  {
    name: 'data.physicalCondition.walk.support',
    label: 'report.HMPFIRST.physicalCondition.walk.support',
    type: 'select',
    options: yesNo,
    smallSize: 12,
    largeSize: 6,
  },
  {
    name: 'data.physicalCondition.walk.supportNote',
    label: 'report.HMPFIRST.physicalCondition.walk.support',
    type: 'note',
    smallSize: 12,
    largeSize: 6,
  },
  {
    name: 'data.physicalCondition.walk.climbing',
    label: 'report.HMPFIRST.physicalCondition.walk.climbing',
    type: 'select',
    options: yesNo,
    smallSize: 12,
    largeSize: 6,
  },
  {
    name: 'data.physicalCondition.walk.climbingNote',
    label: 'report.HMPFIRST.physicalCondition.walk.climbing',
    type: 'note',
    smallSize: 12,
    largeSize: 6,
  },
  {
    name: 'data.physicalCondition.walk.descending',
    label: 'report.HMPFIRST.physicalCondition.walk.descending',
    type: 'select',
    options: yesNo,
    smallSize: 12,
    largeSize: 6,
  },
  {
    name: 'data.physicalCondition.walk.descendingNote',
    label: 'report.HMPFIRST.physicalCondition.walk.descending',
    type: 'note',
    smallSize: 12,
    largeSize: 6,
  },
];

export const transitionsFields: IField[] = [
  {
    name: 'data.physicalCondition.transitions.sittingToLying',
    label: 'report.HMPFIRST.physicalCondition.transitions.sittingToLying',
    type: 'select',
    options: transitionsOptions,
    smallSize: 12,
    largeSize: 12,
  },
  {
    name: 'data.physicalCondition.transitions.TransitionSittingToLying',
    label: 'report.HMPFIRST.physicalCondition.transitions.TransitionSittingToLying',
    type: 'select',
    options: transitionsOptions,
    smallSize: 12,
    largeSize: 12,
  },
  {
    name: 'data.physicalCondition.transitions.sittingToStanding',
    label: 'report.HMPFIRST.physicalCondition.transitions.sittingToStanding',
    type: 'select',
    options: transitionsOptions,
    smallSize: 12,
    largeSize: 12,
  },
  {
    name: 'data.physicalCondition.transitions.movingSittingToStanding',
    label: 'report.HMPFIRST.physicalCondition.transitions.movingSittingToStanding',
    type: 'select',
    options: transitionsOptions,
    smallSize: 12,
    largeSize: 12,
  },
];
export const vasPainFields: IField[] = [
  {
    name: 'data.physicalCondition.pain.VAS',
    label: 'report.HMPFIRST.physicalCondition.pain.VAS',
    type: 'select',
    options: vasOptions,
    smallSize: 12,
    largeSize: 12,
  },
];

export const equilibriumExaminationsFields: IField[] = [
  {
    name: 'data.physicalCondition.equilibrium',
    label: 'report.HMPFIRST.physicalCondition.equilibrium',
    type: 'select',
    options: equilibriumOptions,
    smallSize: 12,
    largeSize: 6,
  },
  {
    name: 'data.physicalCondition.equilibriumNote',
    label: 'report.HMPFIRST.physicalCondition.equilibrium',
    type: 'note',
    smallSize: 12,
    largeSize: 6,
  },
];

export const physicalExaminationsFields: IField[] = [
  {
    name: 'data.physicalCondition.upperLimb.right',
    label: 'report.HMPFIRST.physicalCondition.upperLimb.right',
    type: 'select',
    options: examinationsOptions,
    smallSize: 12,
    largeSize: 6,
  },
  {
    name: 'data.physicalCondition.upperLimb.left',
    label: 'report.HMPFIRST.physicalCondition.upperLimb.left',
    type: 'select',
    options: examinationsOptions,
    smallSize: 12,
    largeSize: 6,
  },
  {
    name: 'data.physicalCondition.muscle.right',
    label: 'report.HMPFIRST.physicalCondition.muscle.right',
    type: 'select',
    options: examinationsOptions,
    smallSize: 12,
    largeSize: 6,
  },
  {
    name: 'data.physicalCondition.muscle.left',
    label: 'report.HMPFIRST.physicalCondition.muscle.left',
    type: 'select',
    options: examinationsOptions,
    smallSize: 12,
    largeSize: 6,
  },
];

export const motionExaminationsFields: IField[] = [
  {
    name: 'data.physicalCondition.motion.upperLimb.right',
    label: 'report.HMPFIRST.physicalCondition.motion.upperLimb.right',
    type: 'select',
    options: motionOptions,
    smallSize: 12,
    largeSize: 6,
  },
  {
    name: 'data.physicalCondition.motion.upperLimb.rightNote',
    label: 'report.HMPFIRST.physicalCondition.motion.upperLimb.right',
    type: 'note',
    smallSize: 12,
    largeSize: 6,
  },
  {
    name: 'data.physicalCondition.motion.upperLimb.left',
    label: 'report.HMPFIRST.physicalCondition.motion.upperLimb.left',
    type: 'select',
    options: motionOptions,
    smallSize: 12,
    largeSize: 6,
  },
  {
    name: 'data.physicalCondition.motion.upperLimb.leftNote',
    label: 'report.HMPFIRST.physicalCondition.motion.upperLimb.left',
    type: 'note',
    smallSize: 12,
    largeSize: 6,
  },
  {
    name: 'data.physicalCondition.motion.lowerLimb.right',
    label: 'report.HMPFIRST.physicalCondition.motion.lowerLimb.right',
    type: 'select',
    options: motionOptions,
    smallSize: 12,
    largeSize: 6,
  },
  {
    name: 'data.physicalCondition.motion.lowerLimb.rightNote',
    label: 'report.HMPFIRST.physicalCondition.motion.lowerLimb.right',
    type: 'note',
    smallSize: 12,
    largeSize: 6,
  },
  {
    name: 'data.physicalCondition.motion.lowerLimb.left',
    label: 'report.HMPFIRST.physicalCondition.motion.lowerLimb.left',
    type: 'select',
    options: motionOptions,
    smallSize: 12,
    largeSize: 6,
  },
  {
    name: 'data.physicalCondition.motion.lowerLimb.leftNote',
    label: 'report.HMPFIRST.physicalCondition.motion.lowerLimb.left',
    type: 'note',
    smallSize: 12,
    largeSize: 6,
  },
];

export const functionalStateFields: IField[] = [
  {
    name: 'data.functionalState.collaborator',
    label: 'report.HMPFIRST.functionalState.collaborator',
    type: 'select',
    options: collaboratorOptions,
    smallSize: 12,
    largeSize: 6,
  },
  {
    name: 'data.functionalState.vigilance',
    label: 'report.HMPFIRST.functionalState.vigilance',
    type: 'select',
    options: vigilanceOptions,
    smallSize: 12,
    largeSize: 6,
  },
  {
    name: 'data.functionalState.ability',
    label: 'report.HMPFIRST.functionalState.ability',
    type: 'select',
    options: abilityOptions,
    smallSize: 12,
    largeSize: 12,
  },
  {
    // keep property name as position for backward compatibility
    name: 'data.functionalState.position',
    // old value position
    label: 'report.HMPFIRST.functionalState.notes',
    type: 'text',
    rows: 6,
    smallSize: 12,
    largeSize: 12,
  },
];
