import { t } from 'i18next';
import React from 'react';
import BarChart from '../../../components/BarChart';
import ErrorMessage from '../../../components/ErrorMessage';
import PiChartWidgetSkeleton from '../../../components/skeletons/PiChartWidgetSkeleton';
import { IServiceResponse } from '../../../hooks/HookService';
import { ReportData } from '../../../interfaces/Models';

const ClinicsPatientsBarChartWidget = ({
  data,
  error,
  errorMessage,
  isLoading,
}: IServiceResponse<ReportData>) => {
  if (isLoading) {
    return <PiChartWidgetSkeleton />;
  }
  if (error || !data) {
    return <ErrorMessage error={errorMessage} />;
  }

  const chartData = [
    {
      name: t('financialReports.employees.totalCount'),
      data: data.clinicCounter,
    },
  ];
  const chartCategories: string[] = data.clinicNames || [];

  return (
    <BarChart
      title={t('global.dashboard.clinicPatients')}
      chartCategories={chartCategories}
      chartData={chartData}
    />
  );
};

export default ClinicsPatientsBarChartWidget;
