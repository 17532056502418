import React from 'react';
import { useTranslation } from 'react-i18next';
import Page from '../../components/Page';
import Programs from './ProgramsTable';
import { AbilitySubjects } from '../../casl/ability';
import useAccess from '../../hooks/useAccess';

const ProgramsPage = () => {
  const { t } = useTranslation();
  useAccess('read', AbilitySubjects.ALL);

  return (
    <Page title={t('global.pages.programs')}>
      <Programs showFilters={true} />
    </Page>
  );
};

export default ProgramsPage;
