import React from 'react';
import { styled } from '@mui/system';
import DateRangeFilter from '../../components/DateRangeFilter';
import { theme } from '../../theme';
import DropDownFilter from '../../components/DropDownFilter';
import {
  CLINIC_LIST,
  PATIENT_PROGRAM_STATUS,
  PATIENT_PROGRAM_STATUS_TO_HEBREW,
  PROGRAM_PAYMENT_MODE,
  URL_QUERY_PARAMS,
} from '../../common/Constants';
import { useTranslation } from 'react-i18next';
import { allValue, allValueKey } from './EmployeesFinancialReports';
import { IProgramFinancialReport } from '../../interfaces/Reports';
import { downloadExcelReport, getSheetData, ISheetColumn } from '../../utils/ExcelReportUtils';
import { toast } from 'react-toastify';
import DownloadButtonWithTooltip from './DownloadButtonWithTooltip';

export const PageFiltersContainer = styled('div')({
  display: 'flex',
  justifyContent: 'flex-end',
  gap: theme.spacing(1),
  // direction: 'rtl',
  minWidth: '30%',
  [theme.breakpoints.down('sm')]: {
    justifyContent: 'space-between',
    flexWrap: 'wrap',
    gap: theme.spacing(0.8),
  },
});

const FilterContainer = styled('div')({
  minWidth: '20%',
  [theme.breakpoints.down('sm')]: {
    minWidth: '49%',
  },
});

const DownloadReportContainer = styled('div')({
  minWidth: '10%',
  [theme.breakpoints.down('sm')]: {
    display: 'none',
  },
});
const DownloadReportContainer1 = styled('div')({
  display: 'none',
  [theme.breakpoints.down('sm')]: {
    display: 'flex',
    justifyContent: 'center',
    minWidth: '49%',
  },
});

interface IClinicsReportsFilter {
  reports?: IProgramFinancialReport[];
  tableColumns: ISheetColumn[];
}
const ClinicsReportsFilter = ({ reports, tableColumns }: IClinicsReportsFilter) => {
  const { t } = useTranslation();

  const columns = tableColumns.map((column) => {
    const isPaymentModeColumn = column.Header === t('program.paymentMode');
    if (isPaymentModeColumn) {
      return {
        ...column,
        accessor: (val: any) =>
          t(
            `program.paymentModeOptionsShort.${
              val.paymentMode || PROGRAM_PAYMENT_MODE.PROGRAM_LEVEL
            }`
          ),
      };
    }
    return column;
  });

  const onDownloadReport = () => {
    if (reports) {
      const clinicsReportsData = getSheetData(reports, columns);

      downloadExcelReport(t('global.excelReports.programsFinancialReports'), [
        {
          sheetName: t('global.excelReports.programsFinancialReports'),
          sheetData: clinicsReportsData,
        },
      ]);
    } else {
      toast.error(t('global.errors.report'));
    }
  };

  const statusFilterValues = [allValue, PATIENT_PROGRAM_STATUS.ACTIVE, PATIENT_PROGRAM_STATUS.DONE];
  const statusFilterKeys = [
    allValueKey,
    PATIENT_PROGRAM_STATUS_TO_HEBREW.ACTIVE,
    PATIENT_PROGRAM_STATUS_TO_HEBREW.DONE,
  ];

  return (
    <PageFiltersContainer>
      <DownloadReportContainer>
        <DownloadButtonWithTooltip onDownloadReport={onDownloadReport} />
      </DownloadReportContainer>
      <FilterContainer>
        <DropDownFilter
          values={[allValue, ...Object.values(CLINIC_LIST)]}
          keys={[t('financialReports.programs.allClinics'), ...Object.values(CLINIC_LIST)]}
          defaultValue={allValue}
          urlParam={URL_QUERY_PARAMS.CLINIC}
          color="#FFC107"
        />
      </FilterContainer>
      <FilterContainer>
        <DropDownFilter
          values={statusFilterValues}
          keys={statusFilterKeys}
          defaultValue={allValue}
          urlParam={URL_QUERY_PARAMS.STATUS}
        />
      </FilterContainer>
      <FilterContainer>
        <DateRangeFilter />
      </FilterContainer>
      <DownloadReportContainer1>
        <DownloadButtonWithTooltip onDownloadReport={onDownloadReport} />
      </DownloadReportContainer1>
    </PageFiltersContainer>
  );
};

export default ClinicsReportsFilter;
