import React from 'react';
import { Box, Button, Grid, IconButton, MenuItem, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import { FieldArray } from 'formik';
import { StyledDivider, StyledGrid } from '../../../styles/FormFieldsStyles';
import {
  medicationDosages,
  medicationGivingMethod,
  medicationGivingMethod1Options,
  medicinesNames,
} from '../../../common/Medicines';
import {
  IPrescriptionMedication,
  IPrescriptionNewMedicationField,
} from '../../../interfaces/prescription';
import FormikAutocomplete from '../../../components/Form/FormikAutocomplete';
import { TextField } from 'formik-mui';
import { FastField as Field } from 'formik';
import FormikSelect from '../../../components/FormikSelect';

const sxFormControl = {
  width: '100%',
};

const MedicationTranslationOptions = ['day', 'week', 'month'];

const medicineInputState = {
  givingMethod: 'P.O',
  givingMethod1Unit: 'OP',
  dosageUnit: 'MG',
  frequencyPeriod: 'day',
  MedicationPeriodUnit: 'week',
  name: null,
};

interface Props {
  id: string;
  isViewMode?: boolean;
  value?: Array<IPrescriptionMedication>;
  fieldName: string;
}
export default function PrescriptionListField({
  id,
  isViewMode = false,
  value = [],
  fieldName,
}: Props) {
  const { t } = useTranslation();

  return (
    <Box>
      <Typography variant="subtitle1" gutterBottom align="left">
        {t('report.chronicDiseases.medications')}
      </Typography>
      <StyledGrid container justifyContent="left" id={`${id}_Container`}>
        <FieldArray name={fieldName}>
          {({ remove, push }) => (
            <StyledGrid item xs={12} md={12} lg={12}>
              {value.map((item: IPrescriptionNewMedicationField, index: number) => (
                <React.Fragment key={`Medications_${index}`}>
                  <Box
                    sx={{
                      display: 'flex',
                      width: '100%',
                      flexDirection: { xs: 'column', md: 'row' },
                    }}
                  >
                    <StyledGrid container spacing={2}>
                      <Grid item xs={12}>
                        <Grid container spacing={2}>
                          <Grid item xs={12} md={4}>
                            <FormikAutocomplete
                              options={medicinesNames}
                              freeSolo
                              label={t('report.chronicDiseases.name')}
                              name={`${fieldName}.${index}.name`}
                            />
                          </Grid>
                          <Grid item xs={6} md={4}>
                            <Field
                              fullWidth
                              component={TextField}
                              name={`${fieldName}.${index}.quantity`}
                              label={t('report.chronicDiseases.quantity')}
                            />
                          </Grid>
                          <Grid item xs={6} md={4}>
                            <FormikSelect
                              name={`${fieldName}.${index}.dosageUnit`}
                              label={t('report.chronicDiseases.dosageUnit')}
                              formControl={sxFormControl}
                              isClearable={false}
                            >
                              {medicationDosages.map((option: string) => {
                                return (
                                  <MenuItem key={`${option}_${index}`} value={option}>
                                    {option}
                                  </MenuItem>
                                );
                              })}
                            </FormikSelect>
                          </Grid>
                        </Grid>
                      </Grid>
                      {/* End of Row1 */}
                      <Grid item xs={12}>
                        <Grid container spacing={2}>
                          <Grid item xs={6} md={2.4}>
                            <FormikSelect
                              name={`${fieldName}.${index}.givingMethod`}
                              label={t('report.chronicDiseases.givingMethod')}
                              formControl={sxFormControl}
                              isClearable={false}
                            >
                              {medicationGivingMethod.map((option: string) => {
                                return (
                                  <MenuItem key={`${option}_${index}`} value={option}>
                                    {option}
                                  </MenuItem>
                                );
                              })}
                            </FormikSelect>
                          </Grid>

                          <Grid item xs={6} md={2.4}>
                            <Field
                              fullWidth
                              multiline
                              component={TextField}
                              name={`${fieldName}.${index}.frequency`}
                              label={t('report.chronicDiseases.frequency')}
                            />
                          </Grid>
                          <Grid item xs={6} md={2.4}>
                            <FormikSelect
                              name={`${fieldName}.${index}.frequencyPeriod`}
                              label={t('report.chronicDiseases.frequencyPeriod')}
                              formControl={sxFormControl}
                              isClearable={false}
                            >
                              {MedicationTranslationOptions.map((option: string) => {
                                return (
                                  <MenuItem key={`${option}_${index}`} value={option}>
                                    {option}
                                  </MenuItem>
                                );
                              })}
                            </FormikSelect>
                          </Grid>
                          <Grid item xs={6} md={2.4}>
                            <Field
                              fullWidth
                              multiline
                              component={TextField}
                              name={`${fieldName}.${index}.MedicationPeriod`}
                              label={t('report.chronicDiseases.MedicationPeriod')}
                            />
                          </Grid>
                          <Grid item xs={12} md={2.4}>
                            <FormikSelect
                              name={`${fieldName}.${index}.MedicationPeriodUnit`}
                              label={t('report.chronicDiseases.MedicationPeriodUnit')}
                              formControl={sxFormControl}
                              isClearable={false}
                            >
                              {MedicationTranslationOptions.map((option: string) => {
                                return (
                                  <MenuItem key={`${option}_${index}`} value={option}>
                                    {option}
                                  </MenuItem>
                                );
                              })}
                            </FormikSelect>
                          </Grid>
                        </Grid>
                      </Grid>
                      {/* End of Row2 */}
                      <Grid item xs={12}>
                        <Grid container spacing={2}>
                          <Grid item xs={6}>
                            <FormikSelect
                              name={`${fieldName}.${index}.givingMethod1Unit`}
                              label={t('report.chronicDiseases.givingMethod')}
                              formControl={sxFormControl}
                              isClearable={false}
                            >
                              {medicationGivingMethod1Options.map((option: string) => {
                                return (
                                  <MenuItem key={`${option}_${index}`} value={option}>
                                    {t(`global.form.select.${option}`)}
                                  </MenuItem>
                                );
                              })}
                            </FormikSelect>
                          </Grid>
                          <Grid item xs={6}>
                            <Field
                              fullWidth
                              multiline
                              component={TextField}
                              name={`${fieldName}.${index}.givingMethod1`}
                              label={t('report.chronicDiseases.quantity')}
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                    </StyledGrid>
                    <Box
                      sx={{ display: 'grid', placeItems: 'center', marginBottom: { xs: 0, md: 2 } }}
                    >
                      <IconButton
                        color="error"
                        aria-label="delete"
                        onClick={() => remove(index)}
                        size="large"
                        disabled={isViewMode}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </Box>
                  </Box>
                  <StyledDivider />
                </React.Fragment>
              ))}

              <Button
                variant="contained"
                disabled={isViewMode}
                endIcon={<AddIcon />}
                onClick={() => push({ ...medicineInputState })}
              >
                {t('global.form.add')}
              </Button>
            </StyledGrid>
          )}
        </FieldArray>
      </StyledGrid>
    </Box>
  );
}
