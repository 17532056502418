import React, { useEffect } from 'react';
import { Field, useFormikContext, getIn } from 'formik';
import { Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { TextField } from 'formik-mui';
import { LoadingButton } from '@mui/lab';
import FormikMonthPicker from '../../../components/Form/FormikMonthPicker';
import { ILeumitReportDataSummery } from './LeumitClinicReportForm';

export default function LeumitClinicReportFormFields({
  isLoading,
  handleChangeReportSummery,
}: {
  isLoading: boolean;
  handleChangeReportSummery: (val: ILeumitReportDataSummery | null) => void;
}) {
  const { t } = useTranslation();
  const { isValid, dirty, setFieldValue, values } = useFormikContext();
  const billingDate = getIn(values, 'billingDate');

  useEffect(() => {
    handleChangeReportSummery(null);
  }, [billingDate]);

  const onChangeInvoiceNumber = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    const value = Number(e.target.value);
    if (value > 0 && value < 9999999999) {
      setFieldValue('invoiceNumber', e.target.value);
    }
  };

  return (
    <Grid
      sx={{ mb: 2 }}
      justifyContent={'space-between'}
      alignItems={'flex-start'}
      container
      minHeight={100}
      spacing={2}
    >
      <Grid item xs={12} sm={5}>
        <FormikMonthPicker label={t('report.billingDate')} name="billingDate" />
      </Grid>
      <Grid item xs={12} sm={5}>
        <Field
          fullWidth
          component={TextField}
          type="number"
          label={t('report.invoiceNumber')}
          name="invoiceNumber"
          onChange={onChangeInvoiceNumber}
        />
      </Grid>
      <Grid item xs={12} sm={2}>
        <LoadingButton
          disabled={!(isValid && dirty)}
          type="submit"
          variant="contained"
          loading={isLoading}
          size="large"
          sx={{ width: '100%', py: 3.5 }}
        >
          {t('report.generate')}
        </LoadingButton>
      </Grid>
    </Grid>
  );
}
