/* eslint-disable new-cap */
import { t } from 'i18next';
import React, { useState } from 'react';
import FormModal from '../../components/Modals/FormModal';
import { IPatientProgram, IProgramReleaseReport } from '../../interfaces/Models';
import * as Yup from 'yup';
import Form from '../../components/Form/FormikForm';
import PatientProgramService from '../../services/PatientProgramService';
import FetcherService from '../../hooks/FetcherService';
import { Field } from 'formik';
import { Select } from 'formik-mui';
import { sxFormControl } from '../../styles/FormFieldsStyles';
import { PATIENT_PROGRAM_RELEASE_STATUS } from '../../common/Constants';
import { Box, MenuItem } from '@mui/material';

interface IEditReleaseReportForm {
  openModal: boolean;
  handleModalClose: () => void;
  handlePageUpdate: () => void;
  record?: IProgramReleaseReport | IPatientProgram;
}
const EditReleaseReportForm = ({
  openModal,
  handleModalClose,
  handlePageUpdate,
  record,
}: IEditReleaseReportForm) => {
  const [formLoading, setFormLoading] = useState<boolean>(false);
  const schema = Yup.object({});

  const handleEditSubmission = async (values: IProgramReleaseReport): Promise<void> => {
    await FetcherService<IPatientProgram>({
      service: PatientProgramService.updateGenericData(record?._id as string, {
        patientReleaseStatus: values.patientReleaseStatus,
      }),
      setLoading: setFormLoading,
    });
    handleModalClose();
    handlePageUpdate();
  };

  return (
    <FormModal
      loading={formLoading}
      open={openModal}
      onClose={handleModalClose}
      text={t('program.releaseReports.editReleaseStatus')}
    >
      <Form
        onCancel={handleModalClose}
        submit={handleEditSubmission}
        initialValues={{
          ...record,
          patientReleaseStatus: record?.patientReleaseStatus
            ? record?.patientReleaseStatus
            : PATIENT_PROGRAM_RELEASE_STATUS.NOT_DECIDED,
        }}
        validationSchema={schema}
        cancelText={t('clinic.form.cancel')}
        submitText={t('clinic.form.edit')}
      >
        <Box flexGrow={1} sx={{ mt: 1, width: '100%' }}>
          <Field
            component={Select}
            name="patientReleaseStatus"
            label={t('program.releaseReports.releaseStatus')}
            formControl={{ sx: sxFormControl }}
          >
            {Object.values(PATIENT_PROGRAM_RELEASE_STATUS).map((status, idx) => {
              return (
                <MenuItem key={idx} value={status}>
                  {t(`program.releaseReports.releaseStatusValues.${status}`)}
                </MenuItem>
              );
            })}
          </Field>
        </Box>
      </Form>
    </FormModal>
  );
};

export default EditReleaseReportForm;
