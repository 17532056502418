import React from 'react';
import { Overlay, OverlayContent, OverlayInner } from '../styles/OverlayStyles';
import CircularProgress from './CircularProgress';

interface IOverlaySpinner {
  sx?: object;
}
const OverlaySpinner = ({ sx }: IOverlaySpinner) => {
  return (
    <Overlay sx={sx}>
      <OverlayInner>
        <OverlayContent>
          <CircularProgress />
        </OverlayContent>
      </OverlayInner>
    </Overlay>
  );
};

export default OverlaySpinner;
