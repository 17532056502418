import { t } from 'i18next';
import React from 'react';
import { IPatient } from '../../interfaces/Models';
import { format } from 'date-fns';
import DetailsCard from '../../components/DetailsCard';

interface PatientInfoProps {
  patient: IPatient;
}
const PatientInfo = ({ patient }: PatientInfoProps) => {
  const details = [
    {
      name: t('patient.info.patientName'),
      icon: 'eva:person-add-fill',
      value: `${patient.personalDetails.firstName} ${patient.personalDetails.lastName}`,
    },
    {
      name: t('patient.form.idNumber'),
      icon: 'arcticons:id-wallet',
      value: patient.idNumber,
    },
    {
      name: t('personalDetails.gender'),
      icon: 'material-symbols:wc-sharp',
      value: t(`personalDetails.${patient.personalDetails.gender}`),
    },
    {
      name: t('patient.form.clinic'),
      icon: 'healthicons:ambulatory-clinic-negative',
      value: patient.clinic.name,
    },
    {
      name: t('personalDetails.primaryPhone'),
      icon: 'eva:phone-call-fill',
      value: patient.personalDetails.primaryPhone,
    },
    {
      name: t('personalDetails.secondaryPhone'),
      icon: 'eva:phone-call-fill',
      value: patient.personalDetails.secondaryPhone,
    },
    {
      name: t('personalDetails.address'),
      icon: 'la:address-book',
      value: patient.personalDetails.address,
    },
    {
      name: t('personalDetails.birthDate'),
      icon: 'eva:calendar-fill',
      value: patient.personalDetails.birthDate
        ? format(new Date(patient.personalDetails.birthDate), 'dd-MM-yyyy')
        : undefined,
    },
    {
      name: t('personalDetails.martialStatus'),
      icon: 'eva:people-fill',
      value: t(`global.familyStatus.${patient.personalDetails.martialStatus}`),
    },
    {
      name: t('personalDetails.contactFullName'),
      icon: 'eva:person-add-fill',
      value:
        patient.primaryContact.firstName.length > 0 && patient.primaryContact.lastName.length > 0
          ? `${patient.primaryContact.firstName} ${patient.primaryContact.lastName}`
          : undefined,
    },
    {
      name: t('personalDetails.contactPhoneNumber'),
      icon: 'eva:phone-call-fill',
      value: patient.primaryContact.primaryPhone,
    },
  ];

  return (
    <>
      <DetailsCard title={t('patient.info.personalinfo')} details={details} />
    </>
  );
};

export default PatientInfo;
