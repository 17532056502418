import React from 'react';
import { TextField } from 'formik-mui';
import { FastField } from 'formik';
import { useTranslation } from 'react-i18next';
import { styled } from '@mui/system';
import { theme } from '../../../../theme';
import GenericField from '../../sharedFields/GenericField';
import {
  eatingOralyUpperFields,
  estimationRecommendationsFields,
  dedicatedFoodFields,
  alternativeFeedingVolumefields,
  addedFluidsFields,
  recomindationsBottomFields,
} from '../../../../common/HMDFirst';
import { yesNo } from '../../../../common/HMA';

const StyledTextField = styled(TextField)({
  marginBottom: theme.spacing(2),
});

const RecommendationsFields = () => {
  const { t } = useTranslation();
  const id = 'HMDFirst_Recommendations';

  return (
    <div id={id}>
      <GenericField
        id={`${id}_RecommendationsEatingOrally`}
        header={t('report.HMDFIRST.recommendations.header')}
        subHeader={t('report.HMDFIRST.recommendations.eatingOrally.header')}
        fields={eatingOralyUpperFields}
      />
      <GenericField
        id={`${id}_UseMedical`}
        fields={[
          {
            name: 'data.recommendations.eatingOrally.useMedical',
            label: 'report.HMDFIRST.recommendations.eatingOrally.useMedical.header',
            helperText: 'report.HMDFIRST.recommendations.eatingOrally.useMedical.helperText',
            type: 'textareaWithEditor',
          },
        ]}
      />
      <GenericField
        id={`${id}_EstimationRecommendations`}
        subHeader={t('report.HMDFIRST.recommendations.eatingOrally.estimationRecommendations')}
        fields={estimationRecommendationsFields}
      />
      <GenericField
        id={`${id}_DedicatedFood`}
        subHeader={t('report.HMDFIRST.recommendations.eatingOrally.dedicatedFood')}
        fields={dedicatedFoodFields}
      />
      <GenericField
        id={`${id}_Details`}
        fields={[
          {
            name: 'data.recommendations.eatingOrally.notesOral',
            label: 'report.HMDFIRST.recommendations.eatingOrally.notesOral',
            type: 'textareaWithEditor',
          },
        ]}
      />
      <GenericField
        id={`${id}_AlternativeFeeding`}
        subHeader={t('report.HMDFIRST.recommendations.alternativeFeeding.header')}
        fields={[
          {
            name: 'data.recommendations.alternativeFeeding.medicalName',
            label: 'report.HMDFIRST.recommendations.alternativeFeeding.medicalName.header',
            type: 'text',
            helperText: 'report.HMDFIRST.recommendations.alternativeFeeding.medicalName.helperText',
            rows: 1,
            smallSize: 12,
            largeSize: 12,
          },
        ]}
      />
      <GenericField id={`${id}_AlternativeFeeding`} fields={alternativeFeedingVolumefields} />
      <GenericField id={`${id}_AddedFluids`} fields={addedFluidsFields} />
      <GenericField
        id={`${id}_ElectricPump`}
        fields={[
          {
            name: 'data.recommendations.alternativeFeeding.electricPump',
            label: 'report.HMDFIRST.recommendations.alternativeFeeding.electricPump',
            type: 'select',
            options: yesNo,
            smallSize: 12,
            largeSize: 12,
          },
        ]}
      />
      <GenericField id={`${id}_BottomFields`} fields={recomindationsBottomFields} />
    </div>
  );
};

export default RecommendationsFields;
