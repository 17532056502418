/* eslint-disable new-cap */
import React, { useState, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import useService from '../../hooks/HookService';
import clinicService from '../../services/ClinicService';
import { IClinic } from '../../interfaces/Models';
import FetcherService from '../../hooks/FetcherService';
import Form from '../../components/Form/FormikForm';
import ClinicFormFields from './ClinicFormFields';
import FormModal from '../../components/Modals/FormModal';
import * as Yup from 'yup';
import { format } from 'date-fns';
import editFill from '@iconify/icons-eva/edit-fill';
import Label from '../../components/Label';
import Table from '../../components/Table';
import ErrorMessage from '../../components/ErrorMessage';
import MoreOptions from '../../components/MoreOptions';
import { Icon } from '@iconify/react';
import SearchFilter from '../../components/SearchFilter';
import { FILTERS } from '../../common/Constants';
import { AbilitySubjects } from '../../casl/ability';
import OverlaySpinner from '../../components/OverlaySpinner';
import useSearchFilter from '../../hooks/useSearchFilter';
import { theme } from '../../theme';
import { getPageNumberFromUrlParam } from '../../utils/Helper';

export interface IPageInfo {
  page: number;
  pageSize: number;
}
const ClinicTable: React.FC = () => {
  const { t } = useTranslation();
  const { pageInfo } = useSearchFilter();
  const [open, setOpen] = useState<boolean>(false);
  const [shouldTableUpdate, setShouldTableUpdate] = useState<number>(0);
  const [record, setRecord] = useState<IClinic | null>(null);
  const [formLoading, setFormLoading] = useState<boolean>(false);

  const onOpenActions = (value: IClinic) => {
    setRecord(value);
    setOpen(true);
  };

  const columns = [
    {
      Header: `${t('clinic.table.clinicName')}`,
      accessor: 'name',
    },
    {
      Header: `${t('clinic.table.clinicUsername')}`,
      accessor: 'username',
    },
    {
      Header: `${t('clinic.table.password')}`,
      accessor: () => '******',
    },
    {
      Header: `${t('clinic.table.vaultLink')}`,
      accessor: 'vaultLink',
    },
    {
      id: 'isFormatNeeded',
      Header: `${t('clinic.table.formateStatus')}`,
      accessor: (clinic: any) => {
        return (
          <Label variant="ghost" color={clinic.isFormatNeeded ? 'success' : 'error'}>
            {clinic.isFormatNeeded.toString().toLocaleUpperCase()}
          </Label>
        );
      },
    },
    {
      id: 'createdAt',
      Header: `${t('clinic.table.creationDate')}`,
      accessor: (clinic: any) =>
        format(new Date(clinic.createdAt ? clinic.createdAt : ''), 'dd-MM-yyyy'),
    },
    {
      Header: t('clinic.table.actions'),
      accessor: (clinic: any) => {
        return (
          <MoreOptions
            actions={[
              {
                name: t('global.moreMenu.edit'),
                handler: onOpenActions,
                icon: (
                  <Icon
                    icon={editFill}
                    width={24}
                    height={24}
                    color={theme.palette.secondary.main}
                  />
                ),
              },
            ]}
            record={clinic}
          />
        );
      },
    },
  ];

  const { page, limit = 10, search } = pageInfo;

  const { isLoading, data, additionalData, loadingNumber, error, errorMessage } = useService<
    IClinic[]
  >({
    service: clinicService.getAll({
      page: getPageNumberFromUrlParam(page),
      limit: limit,
      search: search,
    }),
    deps: [page, limit, , search, shouldTableUpdate],
  });

  const initialValues: IClinic = useMemo(
    () =>
      record
        ? record
        : {
            name: '',
            vaultLink: '',
            username: '',
            password: '',
            isFormatNeeded: false,
          },
    [record]
  );
  const ClinicSchema = Yup.object({
    // name: Yup.string().required(`${t('clinic.form.required')}`),
  });

  const handleModalClose = () => {
    setOpen(false);
    setRecord(null);
  };

  const handleAddEditClinic = async (record: IClinic): Promise<void> => {
    if (record._id) {
      await FetcherService<IClinic>({
        service: clinicService.edit(record._id as string, record),
        setLoading: setFormLoading,
      });
    } else {
      await FetcherService<IClinic>({
        service: clinicService.add(record),
        setLoading: setFormLoading,
      });
    }

    setOpen(false);
    setShouldTableUpdate((prev) => prev + 1);
  };

  if (error) {
    return <ErrorMessage error={errorMessage} />;
  }

  if (isLoading && loadingNumber == 1) {
    return <OverlaySpinner />;
  }

  return (
    <>
      {open ? (
        <FormModal
          loading={formLoading}
          open={open}
          onClose={handleModalClose}
          text={
            record ? t('clinic.popup.editClinic') + ' ' + record?.name : t('clinic.popup.addClinic')
          }
        >
          <Form
            onCancel={handleModalClose}
            submit={handleAddEditClinic}
            initialValues={initialValues}
            validationSchema={ClinicSchema}
            cancelText={t('clinic.form.cancel')}
            submitText={record ? t('clinic.form.edit') : t('clinic.form.add')}
          >
            <ClinicFormFields />
          </Form>
        </FormModal>
      ) : null}
      <Table
        buttonText={t('clinic.mainView.add')}
        columns={columns}
        data={data || []}
        count={additionalData?.total || 0}
        pageInfo={{
          page: getPageNumberFromUrlParam(page),
          pageSize: Number(limit),
        }}
        setOpen={setOpen}
        isLoading={isLoading}
        filters={[
          {
            filterName: FILTERS.SEARCH,
            component: <SearchFilter />,
          },
        ]}
        actionAccess={AbilitySubjects.ADMIN_ABILITY}
      />
    </>
  );
};

export default ClinicTable;
