/* eslint-disable new-cap */
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useParams, useOutletContext } from 'react-router-dom';
import * as Yup from 'yup';
import { IReport } from '../../../interfaces/Models';
import { reportTypes } from '../../../common/Constants';
import ErrorMessage from '../../../components/ErrorMessage';
import { HMkFirstValues } from '../../../common/reportsFieldsValues/hmk/HMkFirstValues';
import HMKFirstFields from './fields/HMKFirstFields';
import ReportsForm from '../../../components/Form/ReportsForm';

const HMKFirst = () => {
  const { t } = useTranslation();
  const { visitId } = useParams();
  const data: IReport = useOutletContext();

  const initialValues = data
    ? data
    : {
        name: reportTypes.HMK_FIRST,
        visit: visitId,
        data: HMkFirstValues,
      };

  const ReportSchema = Yup.object({
    name: Yup.string().required(t('clinic.form.required')),
    visit: Yup.string().required(t('clinic.form.required')),
  });

  const HtmlId = 'HMKFirst';

  if (data && data.name != reportTypes.HMK_FIRST) {
    return <ErrorMessage error={t('global.errors.somethingWrong')} />;
  }
  return (
    <ReportsForm
      initialValues={initialValues}
      visitId={visitId || ''}
      validationSchema={ReportSchema}
    >
      <HMKFirstFields id={HtmlId} />
    </ReportsForm>
  );
};

export default HMKFirst;
