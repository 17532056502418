import React from 'react';
import { useTranslation } from 'react-i18next';
import GenericField from '../../sharedFields/GenericField';
import { transitionsFields, walkingFields, lowerBottomFields } from '../../../../common/HMPFirst';

const TransitionsWalkFields = () => {
  const { t } = useTranslation();
  const id = 'HMPFirst';
  return (
    <div id={id}>
      <GenericField
        id={`${id}_TransitionsExamination`}
        subHeader={t('report.HMPFIRST.physicalCondition.transitions.header')}
        fields={transitionsFields}
      />
      <GenericField
        id={`${id}_WalkExamination`}
        subHeader={t('report.HMPFIRST.physicalCondition.walk.header')}
        fields={walkingFields}
      />
      <GenericField id={`${id}_Examination`} fields={lowerBottomFields} />
    </div>
  );
};

export default TransitionsWalkFields;
