/* eslint-disable new-cap */
import Container from '@mui/material/Container';
import React, { useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import ErrorMessage from '../../components/ErrorMessage';
import Page from '../../components/Page';
import useService from '../../hooks/HookService';
import {
  IClinic,
  IHospitalDays,
  IPatientProgram,
  IPatientProgramFlat,
  IVisit,
} from '../../interfaces/Models';
import PatientProgramService from '../../services/PatientProgramService';
import { useTranslation } from 'react-i18next';
import Tabs from '../../components/Tabs';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import ProgramVisits from './ProgramVisits';
import ProgramInfo from './ProgramInfo';
import HeaderBreadcrumbs from '../../components/HeaderBreadcrumbs';
import EditIcon from '@mui/icons-material/Edit';
import MoreActions from '../../components/MoreActions';
import { getReleaseReportHandler } from '../../common/columns/Programs';
import { ApiResponse } from '../../interfaces/ApiResponseType';
import { checkIfUserIsAdmin, onDownloadPdf } from '../../utils/Helper';
import FetcherService from '../../hooks/FetcherService';
import ReportService from '../../services/ReportService';
import { toast } from 'react-toastify';
import OverlaySpinner from '../../components/OverlaySpinner';
import DateRangeIcon from '@mui/icons-material/DateRange';
import ProgramCalendar from './ProgramCalendar';
import { AbilitySubjects } from '../../casl/ability';
import { useAbility } from '@casl/react';
import { AbilityContext } from '../../casl/can';
import LocalHospitalIcon from '@mui/icons-material/LocalHospital';
import ProgramHospitalDaysModal from './ProgramHospitalDaysModal';
import HospitalDaysTable from './HospitalDaysTable';
import ListAltIcon from '@mui/icons-material/ListAlt';
import {
  CLINIC_LIST,
  EMPLOYEE_POSITIONS,
  PATIENT_PROGRAM_STATUS,
  reportTypes,
} from '../../common/Constants';
import { TABS_NAMES } from '../../common/Constants';
import AttachmentsTable from '../../components/AttachmentsTable';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import ProgramRefsCard from '../../sections/program/ProgramRefsCard';
import PhoneVisitForm from '../../sections/program/phone-visit/PhoneVisitForm';
import ContactPhoneIcon from '@mui/icons-material/ContactPhone';
import ProgramReferralsTable from '../../sections/program/referral/ProgramReferralsTable';
import DescriptionIcon from '@mui/icons-material/Description';
import { userAtom } from '../../atoms/userAtom';
import { useRecoilValue } from 'recoil';

const ProgramPage = () => {
  const user = useRecoilValue(userAtom);
  const { programId } = useParams();
  const { t } = useTranslation();
  const [isLoadingReport, setIsLoadingReport] = useState(false);
  const [openHospitalDaysModal, setOpenHospitalDaysModal] = useState<boolean>(false);
  const [openPhoneReportModal, setOpenPhoneReportModal] = useState<boolean>(false);
  const [shouldPageUpdate, setShouldPageUpdate] = useState<number>(0);
  const [period, setPeriod] = useState<IHospitalDays>();
  const [programData, setProgramData] = useState<IPatientProgram>();

  const ability = useAbility(AbilityContext);

  const updateAdminAndHmaAbility = ability.can('update', AbilitySubjects.ADMIN_AND_HMA_ABILITY);
  const updateAdminAbility = ability.can('update', AbilitySubjects.ADMIN_ABILITY);

  const handleAddNewPhoneReport = () => setOpenPhoneReportModal(true);

  const handleOpenEditMode = (value: IHospitalDays) => {
    setPeriod(value);
    setOpenHospitalDaysModal(true);
  };

  const handleUpdateProgramData = (value: IPatientProgram) => setProgramData(value);

  const { isLoading, data, error, errorMessage } = useService<IPatientProgram>({
    service: PatientProgramService.getOne(programId || 'empty'),
    deps: [shouldPageUpdate],
    onLoadingData: handleUpdateProgramData,
  });

  const handleCloseModals = () => {
    setPeriod(undefined);
    setOpenHospitalDaysModal(false);
    setOpenPhoneReportModal(false);
  };

  const onDownloadGlobalReleaseReport = async (
    program: IPatientProgramFlat | IPatientProgram,
    reportName?: string
  ) => {
    const onSuccess = (res: ApiResponse<BlobPart>) => {
      onDownloadPdf(res, reportName || '');
    };
    const onFailed = () => {
      toast.error(t('global.errors.report'));
    };
    await FetcherService<any>({
      service: ReportService.generateGlobalReleaseReportPdf(program._id || 'empty', {
        reportName: reportName,
      }),
      onSuccess: onSuccess,
      onFailed: onFailed,
      setLoading: setIsLoadingReport,
    });
  };

  const showAddPhoneReport = useMemo(() => {
    const isUserAdmin = checkIfUserIsAdmin(user);
    const isUserWithMedicalPosition = user?.position !== 'other';
    const isProgramActive = data?.status === PATIENT_PROGRAM_STATUS.ACTIVE;
    return isProgramActive && (isUserAdmin || isUserWithMedicalPosition);
  }, [data, user]);

  const handleProgramUpdate = () => setShouldPageUpdate((prev) => prev + 1);

  const handleOpenAddHospitalDaysModal = () => {
    setOpenHospitalDaysModal(true);
  };

  if (isLoading) {
    return <OverlaySpinner />;
  }
  if (error || !data || !programData || typeof data.patient === 'string') {
    return <ErrorMessage error={errorMessage} />;
  }

  const generalVisits = data.visits.filter(
    (visit: IVisit) =>
      visit.visitType == reportTypes.HMB_GENERAL ||
      visit.visitType == reportTypes.HMP_GENERAL ||
      (visit.visitType == EMPLOYEE_POSITIONS.HMP && visit.isFirst) ||
      (visit.visitType == EMPLOYEE_POSITIONS.HMB && visit.isFirst)
  );
  const patientName = `${data.patient.personalDetails.firstName} ${data.patient.personalDetails.lastName}`;

  const clinicName =
    typeof data.patient !== 'string' && data.patient.clinic
      ? (data.patient.clinic as IClinic).name
      : '';

  const isLeumitProgram = clinicName.trim() === CLINIC_LIST.LEUMIT;

  const tabs = [
    {
      label: t('patient.info.programCalendar'),
      component: (
        <ProgramCalendar
          program={programData}
          handleOpenEditHospitalDaysForm={handleOpenEditMode}
          updateAdminAndHmaAbility={updateAdminAndHmaAbility}
          handleProgramDataUpdate={handleUpdateProgramData}
        />
      ),
      icon: <DateRangeIcon />,
    },
    {
      label: t('user.info.visits'),
      component: (
        <ProgramVisits
          isLeumitPatient={isLeumitProgram}
          visits={programData.visits}
          handleTableUpdate={handleProgramUpdate}
          program={programData}
        />
      ),
      icon: <AccountBoxIcon />,
    },
    {
      label: t('program.hospitalDaysForm.header'),
      component: (
        <HospitalDaysTable
          program={data}
          handleOpenEditMode={handleOpenEditMode}
          updateAdminAndHmaAbility={updateAdminAndHmaAbility}
          updateAdminAbility={updateAdminAbility}
          handleTableUpdate={handleProgramUpdate}
        />
      ),
      icon: <LocalHospitalIcon />,
    },
    {
      label: t('program.generalReports'),
      component: (
        <ProgramVisits
          program={data}
          visits={generalVisits}
          handleTableUpdate={handleProgramUpdate}
        />
      ),
      icon: <ListAltIcon />,
    },
    {
      label: t('global.attachments.header'),
      component: <AttachmentsTable isLoading={isLoading} program={data} />,
      icon: <AttachFileIcon />,
    },
    {
      label: t('referrals'),
      component: <ProgramReferralsTable isLoading={isLoading} program={data} />,
      icon: <DescriptionIcon />,
    },
  ];

  if (isLeumitProgram) {
    tabs.push({
      label: t('references'),
      component: <ProgramRefsCard handleUpdateProgramData={handleProgramUpdate} program={data} />,
      icon: <ReceiptLongIcon />,
    });
  }

  return (
    <>
      <Page title={t('global.pages.program')}>
        <Container>
          <HeaderBreadcrumbs
            heading={t('global.pages.program')}
            links={[
              { name: patientName, href: `/app/patient/${data.patient._id}` },
              { name: data.title },
            ]}
            actions={[
              {
                type: 'Component',
                component: (
                  <MoreActions
                    key="Release_Actions"
                    record={data}
                    actions={getReleaseReportHandler(onDownloadGlobalReleaseReport)}
                  />
                ),
              },
              {
                name: t('addTelephoneReport'),
                icon: <ContactPhoneIcon />,
                color: 'info',
                type: 'Action',
                onClick: handleAddNewPhoneReport,
                isVisible: showAddPhoneReport,
              },
              {
                name: t('program.hospitalDaysForm.addHospitalDays'),
                icon: <LocalHospitalIcon />,
                color: 'warning',
                type: 'Action',
                onClick: handleOpenAddHospitalDaysModal,
                isVisible: updateAdminAndHmaAbility,
              },
              {
                name: t('patient.info.editProgram'),
                href: `/app/patient-program/edit/${data._id}`,
                icon: <EditIcon />,
                color: 'secondary',
                type: 'Link',
                isVisible: updateAdminAbility,
              },
            ]}
          />
          <ProgramHospitalDaysModal
            program={data}
            handleCloseModal={handleCloseModals}
            openAddHospitalDaysModal={openHospitalDaysModal}
            period={period}
            handleTableUpdate={handleProgramUpdate}
          />
          <ProgramInfo program={data} />
          <Tabs tabsName={TABS_NAMES.PROGRAM} tabs={tabs} />
          {isLoadingReport && <OverlaySpinner />}
        </Container>
      </Page>
      <PhoneVisitForm
        open={openPhoneReportModal}
        program={data}
        handleCloseModal={handleCloseModals}
        handleProgramUpdate={handleProgramUpdate}
      />
    </>
  );
};

export default ProgramPage;
