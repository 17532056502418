import { Grid } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Row } from 'react-table';
import { getProgramTitleColumn } from '../../../common/columns/Programs';
import { DATE_RANGE_FILTERS, PROGRAM_RELEASE_STATUS_COLOR } from '../../../common/Constants';
import ErrorMessage from '../../../components/ErrorMessage';
import Label from '../../../components/Label';
import Table from '../../../components/Table';
import useService from '../../../hooks/HookService';
import { IPositionReleaseReport, IProgramReleaseReport } from '../../../interfaces/Models';
import PatientProgramService from '../../../services/PatientProgramService';
import { theme } from '../../../theme';
import { getDateFormatted, getStartEndDateDep } from '../../../utils/Helper';

const ReleaseReportsWidget = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { isLoading, data, error, additionalData, errorMessage } = useService<
    IProgramReleaseReport[]
  >({
    service: PatientProgramService.getPatientsReleaseReports({
      page: 0,
      limit: 3,
      fromDate: getStartEndDateDep({ range: DATE_RANGE_FILTERS.CURRENT_MONTH }),
      toDate: getStartEndDateDep({ range: DATE_RANGE_FILTERS.CURRENT_MONTH }, true),
      sort: 'endDate',
      dateFilterField: 'endDate',
    }),
    deps: [],
  });
  const columns = [
    {
      id: 'patientName',
      Header: `${t('patient.info.patientName')}`,
      accessor: 'patientName',
    },
    {
      Header: t('program.releaseReports.clinicName'),
      accessor: 'clinicName',
    },
    getProgramTitleColumn(),
    {
      id: 'endDate',
      Header: t('patient.info.endDate'),
      accessor: (program: any) => getDateFormatted(program.endDate),
    },
    {
      Header: t('program.releaseReports.releaseStatus'),
      accessor: (report: any) => {
        return (
          <Label
            variant="ghost"
            color={
              report.patientReleaseStatus
                ? PROGRAM_RELEASE_STATUS_COLOR[report.patientReleaseStatus]
                : 'default'
            }
          >
            {t(
              `program.releaseReports.releaseStatusValues.${
                report.patientReleaseStatus || 'NOT_DECIDED'
              }`
            )}
          </Label>
        );
      },
    },
    {
      id: 'positionsReleaseReports',
      Header: t('program.releaseReports.releaseReportsStatus'),
      headerPosition: 'center',
      accessor: (report: any) => {
        return (
          <Grid container sx={{ width: theme.spacing(50) }} spacing={1}>
            {report.positionsReleaseReports.map((positionReport: IPositionReleaseReport) => {
              return (
                <Grid item xs={6}>
                  <Label variant="ghost" color={positionReport.isVisitDone ? 'success' : 'error'}>
                    {`${t(`global.releasePositions.${positionReport.position.slice(0, 3)}`)} - ${
                      positionReport.employeeName
                    }`}
                  </Label>
                </Grid>
              );
            })}
          </Grid>
        );
      },
    },
  ];

  const onRowClick = (row: Row<IProgramReleaseReport>) => {
    navigate(`/app/patient-program/${row.original._id}`);
  };

  if (error) {
    return <ErrorMessage error={errorMessage} />;
  }
  return (
    <Table
      columns={columns}
      data={data || []}
      count={additionalData.total || 0}
      isLoading={isLoading}
      onRowClick={onRowClick}
      showPagination={false}
    />
  );
};

export default ReleaseReportsWidget;
