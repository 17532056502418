import React from 'react';
import { useParams } from 'react-router-dom';
import useService from '../../../hooks/HookService';
import { IPatientProgram } from '../../../interfaces/Models';
import PatientProgramService from '../../../services/PatientProgramService';
import ErrorMessage from '../../../components/ErrorMessage';
import OverlaySpinner from '../../../components/OverlaySpinner';
import CalendarScreenshot from './CalendarScreenshot';

export default function ProgramScreenshot() {
  const { programId, mode } = useParams();

  const isEdit = mode === 'edit';

  const { isLoading, data, error, errorMessage } = useService<IPatientProgram>({
    service: PatientProgramService.getScreenshotData(programId || 'empty'),
    deps: [],
  });

  if (isLoading) {
    return <OverlaySpinner />;
  }
  if (error || !data) {
    return <ErrorMessage error={errorMessage} />;
  }

  return <CalendarScreenshot isEdit={isEdit} program={data} />;
}
