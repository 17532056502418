/* eslint-disable no-unused-vars */
import { format } from 'date-fns';
import Label from '../../components/Label';
import MoreOptions from '../../components/MoreOptions';
import { IVisit } from '../../interfaces/Models';
import { VISIT_STATUS, VISIT_STATUS_COLOR, VISIT_STATUS_TO_HEBREW } from '../Constants';
import {
  checkEmployeeReportAuthorization,
  getSortDirection,
  getVisitType,
} from '../../utils/Helper';
import { Icon } from '@iconify/react';
import editFill from '@iconify/icons-eva/edit-fill';
import download from '@iconify/icons-eva/download-outline';
import {
  getVisitSentToClinicAction,
  getChangeVisitStatusToDoneAction,
  getVisitIsReleaseColumn,
  getVisitTypeColumn,
} from './ProgramVisits';
import { getCancelVisitAction } from '../columns/ProgramVisits';
import { useAbility } from '@casl/react';
import { AbilityContext } from '../../casl/can';
import { AbilitySubjects } from '../../casl/ability';
import { t } from 'i18next';
import { getPatientNameColumn } from './MyVisitsColumns';

import { theme } from '../../theme';

const iconSize = 24;

export enum VisitsColumnsKeys {
  All = 'AllVisitsColumns',
  Today = 'TodayVisitsColumns',
  Accounting = 'Accounting',
  Employee = 'Employee',
}

export type VisitAction = (visit: IVisit) => void;

export const getSwapEmployeeTableAction = ({
  handelOpenSwapVisitEmployee,
  adminAbility,
}: {
  handelOpenSwapVisitEmployee: VisitAction;
  adminAbility: boolean;
}) => {
  return {
    name: t('visit.swapEmployee'),
    handler: handelOpenSwapVisitEmployee,
    icon: (
      <Icon
        icon="eva:swap-fill"
        width={iconSize}
        height={iconSize}
        color={theme.palette.secondary.main}
      />
    ),
    isVisible: adminAbility,
  };
};

export const getVisitDueDateColumn = (sort: string) => {
  return {
    id: 'dueDate',
    Header: `${t('visit.table.dueDate')}`,
    accessor: (visit: any) => format(new Date(visit.dueDate), 'dd-MM-yyyy'),
    sortType: 'basic',
    sortDirection: getSortDirection(sort, 'dueDate'),
  };
};

export const getVisitStatusColumn = () => {
  return {
    id: 'status',
    Header: `${t('visit.table.visitStatus')}`,
    accessor: (visit: any) => {
      const statusText = VISIT_STATUS_TO_HEBREW[visit.status.toString().toUpperCase()];
      return visit.isExported ? (
        statusText
      ) : (
        <Label variant="ghost" color={VISIT_STATUS_COLOR[visit.status]}>
          {statusText}
        </Label>
      );
    },
  };
};

export const getAllVisitsColumns = (
  sort: string,
  handelEditReport: VisitAction,
  downloadReport: VisitAction,
  sentToClinicHandler: VisitAction,
  typeOfColumns: string,
  handelCancelVisit: VisitAction,
  handelOpenChangeVisitStatusToDone: VisitAction,
  handelSentToClinicDownloadModal: VisitAction,
  handelChangeVisitDueDateModal: VisitAction,
  handelOpenSwapVisitEmployee: VisitAction
) => {
  const ability = useAbility(AbilityContext);

  const adminAbility = ability.can('manage', AbilitySubjects.ADMIN_ABILITY);

  switch (typeOfColumns) {
    case VisitsColumnsKeys.All:
      return [
        {
          id: 'position',
          Header: `${t('user.form.position')}`,
          accessor: (visit: any) => t(`global.positions.${visit.visitType.slice(0, 3)}`),
        },
        {
          id: 'visitType',
          Header: `${t('patient.form.visitType')}`,
          accessor: (visit: any) => t(`global.visitTypes.${getVisitType(visit)}`),
        },
        getPatientNameColumn(sort),
        {
          id: 'employeeName',
          Header: `${t('global.roles.EMPLOYEE')}`,
          accessor: 'employeeName',
          sortType: 'basic',
          sortDirection: getSortDirection(sort, 'employeeName'),
        },
        getVisitStatusColumn(),
        getVisitDueDateColumn(sort),
        // {
        //   id: 'updatedAt',
        //   Header: `${t('visit.table.visitDate')}`,
        //   accessor: (visit: any) => format(new Date(visit.updatedAt), 'dd-MM-yyyy'),
        //   sortType: 'basic',
        //   sortDirection: getSortDirection(sort, 'updatedAt'),
        // },
        {
          Header: t('clinic.table.actions'),
          accessor: (visit: any) => {
            return (
              <>
                <MoreOptions
                  actions={[
                    {
                      name: t('global.moreMenu.edit'),
                      handler: handelEditReport,
                      icon: (
                        <Icon
                          icon={editFill}
                          width={iconSize}
                          height={iconSize}
                          color={theme.palette.secondary.main}
                        />
                      ),
                      isVisible:
                        visit.status == VISIT_STATUS.DONE &&
                        !!checkEmployeeReportAuthorization(visit),
                    },
                    getSwapEmployeeTableAction({ handelOpenSwapVisitEmployee, adminAbility }),
                    {
                      name: t('program.hospitalDaysForm.editDueDate'),
                      handler: handelChangeVisitDueDateModal,
                      icon: (
                        <Icon
                          icon="ic:baseline-update"
                          width={iconSize}
                          height={iconSize}
                          color={theme.palette.secondary.main}
                        />
                      ),
                      isVisible: adminAbility,
                    },
                    {
                      name: t('global.reportActions.downloadReport'),
                      handler: downloadReport,
                      icon: (
                        <Icon icon={download} width={iconSize} height={iconSize} color="#3E94A8" />
                      ),
                      isVisible:
                        visit.status == VISIT_STATUS.DONE ||
                        visit.status == VISIT_STATUS.SENT_TO_CLINIC,
                    },
                    getCancelVisitAction(handelCancelVisit, adminAbility, visit),
                    getVisitSentToClinicAction(visit, sentToClinicHandler, adminAbility),
                    getVisitSentToClinicAction(
                      visit,
                      handelSentToClinicDownloadModal,
                      adminAbility,
                      true
                    ),
                    getChangeVisitStatusToDoneAction(
                      visit,
                      adminAbility,
                      handelOpenChangeVisitStatusToDone
                    ),
                  ]}
                  record={visit}
                />
              </>
            );
          },
          noExcelSupport: true,
        },
      ];

    case VisitsColumnsKeys.Accounting:
      return [
        {
          id: 'visitType',
          Header: `${t('patient.form.visitType')}`,
          accessor: (visit: any) =>
            visit.isFirst ? t('global.visitTypes.FIRST') : t('global.visitTypes.REPEATED'),
        },
        {
          id: 'patientName',
          Header: `${t('patient.info.patientName')}`,
          accessor: 'patientName',
          sortType: 'basic',
          sortDirection: getSortDirection(sort, 'patientName'),
        },
        {
          id: 'dueDate',
          Header: `${t('visit.table.dueDate')}`,
          accessor: (visit: any) => format(new Date(visit.dueDate), 'dd-MM-yyyy'),
        },
        {
          id: 'reportType',
          Header: `${t('reportType')}`,
          accessor: (visit: any) =>
            visit.reportType ? t(`global.form.select.${visit.reportType}`) : '',
        },
      ];
    case VisitsColumnsKeys.Employee:
      return [
        getVisitTypeColumn(),
        {
          id: 'patientName',
          Header: `${t('patient.info.patientName')}`,
          accessor: 'patientName',
          sortType: 'basic',
          sortDirection: getSortDirection(sort, 'patientName'),
        },
        getVisitDueDateColumn(sort),
        getVisitStatusColumn(),
        getVisitIsReleaseColumn(),
      ];
    default:
      return [
        {
          id: 'position',
          Header: `${t('user.form.position')}`,
          accessor: (visit: any) => t(`global.positions.${visit.visitType.slice(0, 3)}`),
        },
        {
          id: 'visitType',
          Header: `${t('patient.form.visitType')}`,
          accessor: (visit: any) => t(`global.visitTypes.${getVisitType(visit)}`),
        },
        {
          Header: `${t('patient.info.patientName')}`,
          accessor: 'patientName',
        },
        {
          Header: `${t('global.roles.EMPLOYEE')}`,
          accessor: 'employeeName',
        },
        getVisitStatusColumn(),
      ];
  }
};
