import { useMatch } from 'react-router-dom';

export default function useMatchRoute(routePattern: string, routeNameCheck: string) {
  let result = false;

  const patternResult = useMatch(routePattern);

  if (patternResult) {
    result = patternResult.params['param'] == routeNameCheck;
  }
  return result;
}
