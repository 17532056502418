import { Grid, Typography } from '@mui/material';
import { Field } from 'formik';
import { TextField } from 'formik-mui';
import { t } from 'i18next';
import React from 'react';
import { statusReleaseFields } from '../../../../common/ReleaseFields';
import { ReportData } from '../../../../interfaces/Models';
import { StyledGrid } from '../../../../styles/FormFieldsStyles';

const StatusFields = ({ id }: ReportData) => {
  return (
    <div id={id}>
      <Typography variant="subtitle1" gutterBottom align="left">
        {t('report.statusReleaseSection.header')}
      </Typography>
      {statusReleaseFields.map((field: string) => {
        return (
          <Grid item xs={12} key={`${id}_${field}`}>
            <StyledGrid container spacing={{ xs: 2, md: 2, lg: 2 }}>
              <Grid item xs={12} md={6} lg={6}>
                <Field
                  id={`${id}_${field}_Before`}
                  fullWidth
                  component={TextField}
                  type="text"
                  name={`data.releaseStatuses.${field}Before`}
                  label={`${t(`report.statusReleaseSection.fields.${field}`)} ${t(
                    'report.statusReleaseSection.statusBefore'
                  )}`}
                />
              </Grid>
              <Grid item xs={12} md={6} lg={6}>
                <Field
                  id={`${id}_${field}_After`}
                  fullWidth
                  component={TextField}
                  type="text"
                  name={`data.releaseStatuses.${field}After`}
                  label={`${t(`report.statusReleaseSection.fields.${field}`)} ${t(
                    'report.statusReleaseSection.statusAfter'
                  )}`}
                />
              </Grid>
            </StyledGrid>
          </Grid>
        );
      })}
    </div>
  );
};

export default StatusFields;
