/* eslint-disable new-cap */
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useParams, useOutletContext } from 'react-router-dom';
import * as Yup from 'yup';
import { reportTypes } from '../../../common/Constants';
import ErrorMessage from '../../../components/ErrorMessage';
import { HMkRepeatedValues } from '../../../common/reportsFieldsValues/hmk/HMKRepeatedValues';
import HMKRepeatedFields from './fields/HMKRepeatedFields';
import ReportsForm from '../../../components/Form/ReportsForm';

const HMKRepeated = () => {
  const { t } = useTranslation();
  const { visitId } = useParams();
  const data: any = useOutletContext();

  const initialValues =
    data && data.name
      ? data
      : {
          name: reportTypes.HMK_REPEATED,
          visit: visitId,
          data: data ? data : HMkRepeatedValues,
        };

  const ReportSchema = Yup.object({
    name: Yup.string().required(t('clinic.form.required')),
    visit: Yup.string().required(t('clinic.form.required')),
    data: Yup.object().shape({
      medicalCondition: Yup.string().required(t('clinic.form.required')),
      therapeuticIntervention: Yup.string().required(t('clinic.form.required')),
      treatmentPlan: Yup.string().required(t('clinic.form.required')),
    }),
  });

  const HtmlId = 'HMKRepeated';

  if (data?.name && data.name != reportTypes.HMK_REPEATED) {
    return <ErrorMessage error={t('global.errors.somethingWrong')} />;
  }

  return (
    <ReportsForm
      initialValues={initialValues}
      visitId={visitId || ''}
      validationSchema={ReportSchema}
      name={data?.name}
      resetForm={true}
      resetFormValues={{ ...initialValues, data: HMkRepeatedValues }}
    >
      <HMKRepeatedFields id={HtmlId} />
    </ReportsForm>
  );
};

export default HMKRepeated;
