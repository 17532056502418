import { CardHeader, Stack, Typography, Box } from '@mui/material';
import React from 'react';
import { IconStyle } from '../pages/user/profile/ProfileAbout';
import { InfoCard } from '../styles/DatailsInfoStyles';
import { theme } from '../theme';

export interface IRecordDetail {
  name?: string;
  icon: string;
  value?: string;
}
interface DetailsCardProps {
  title: string;
  details: IRecordDetail[];
}

const DetailsCard = ({ title, details }: DetailsCardProps) => {
  return (
    <InfoCard>
      <CardHeader title={title} />
      <Box
        sx={{
          display: 'grid',
          rowGap: 3,
          columnGap: 2,
          gridTemplateColumns: { xs: 'repeat(1, 1fr)', sm: 'repeat(3, 1fr)' },
          padding: theme.spacing(4),
        }}
      >
        {details.map((detail: IRecordDetail) => {
          return detail.value ? (
            <Stack direction="row" key={detail.name}>
              <IconStyle icon={detail.icon} />
              <Typography variant="body2">
                {detail.name && detail.value && detail.value.length > 0
                  ? `${detail.name} : ${detail.value}`
                  : ''}
              </Typography>
            </Stack>
          ) : null;
        })}
      </Box>
    </InfoCard>
  );
};
export default DetailsCard;
