import React from 'react';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import { he, enUS } from 'date-fns/locale';
import i18n from 'i18next';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { SxProps, TextField, Theme } from '@mui/material';
import { DatePicker } from '@mui/lab';

interface IMonthPickerProps {
  label: string;
  handleChange: (date: Date | null) => void;
  value?: Date | null;
  sx?: SxProps<Theme> | undefined;
  autoOpen?: boolean;
}
export default function MonthPicker({
  label,
  handleChange,
  value,
  sx,
  autoOpen = false,
}: IMonthPickerProps) {
  const [open, setOpen] = React.useState(autoOpen);

  const onChange = (value: Date | null) => {
    handleChange(value);
    setOpen(false);
  };

  return (
    <LocalizationProvider locale={i18n.language == 'he' ? he : enUS} dateAdapter={AdapterDateFns}>
      <DatePicker
        open={open}
        onOpen={() => setOpen(true)}
        onChange={onChange}
        value={value}
        label={label}
        inputFormat="MM/yyyy" // Set the input format to display only month and year
        views={['year', 'month']} // Restrict the views to only year and month
        openTo="month" // Open the picker directly to the month view
        renderInput={(params: any) => <TextField sx={sx} size="small" label={label} {...params} />}
      />
    </LocalizationProvider>
  );
}
