export function encodeToBase64WithTextDecoder(string: string) {
  // Ensure string is encoded in UTF-8
  const encoder = new TextEncoder();
  const encodedBytes = encoder.encode(string);

  // Encode bytes to Base64
  const base64String = btoa(
    encodedBytes.reduce((acc, byte) => acc + String.fromCharCode(byte), '')
  );

  return base64String;
}

export function decodeBase64ToUtf8(base64String: string): string {
  // Node.js Buffer provides a way to convert base64 encoded strings to binary data
  const buffer = Buffer.from(base64String, 'base64');

  // TextDecoder, available globally in Node.js (v11 onwards), can then be used to decode the binary data to a UTF-8 string
  const decoder = new TextDecoder('utf-8');
  const decodedString = decoder.decode(buffer);

  return decodedString;
}

export const isStringBase64Encoded = (str: string) => {
  try {
    if (!str || str.length === 0) return false;
    const base64Regex = /^(?:[A-Za-z0-9+/]{4})*(?:[A-Za-z0-9+/]{2}==|[A-Za-z0-9+/]{3}=)?$/;
    return base64Regex.test(str.slice(0, 60));
  } catch (err) {
    return false;
  }
};
