import { Box, Button, Divider } from '@mui/material';
import React from 'react';
import { Icon } from '@iconify/react';
import arrowIosForwardFill from '@iconify/icons-eva/arrow-ios-back-fill';
import { useTranslation } from 'react-i18next';

interface IShowMore {
  showMoreRecords: Function | null;
}
const ShowMore = ({ showMoreRecords }: IShowMore) => {
  const { t } = useTranslation();

  const handelShowMoreRecords = () => {
    showMoreRecords ? showMoreRecords() : null;
  };
  return (
    <>
      <Divider />
      <Box sx={{ p: 2, textAlign: 'right' }}>
        <Button
          size="small"
          color="inherit"
          endIcon={<Icon icon={arrowIosForwardFill} />}
          onClick={handelShowMoreRecords}
        >
          {t('global.dashboard.showMore')}
        </Button>
      </Box>
    </>
  );
};

export default ShowMore;
