import React from 'react';
import { IVisitFlat } from '../interfaces/Models';
import { StyledTableCell, TotalTableRow } from '../styles/TableStyles';
import { t } from 'i18next';
interface ITableTotalFooter {
  repeatedAndFirstVisits: IVisitFlat[];
  rate: number;
}

const VisitsTableFooter = ({ repeatedAndFirstVisits, rate }: ITableTotalFooter) => {
  const sum = repeatedAndFirstVisits.length * rate;

  return (
    <TotalTableRow>
      <StyledTableCell>{t('global.profile.total')}</StyledTableCell>
      <StyledTableCell colSpan={3}>{sum} NIS</StyledTableCell>
    </TotalTableRow>
  );
};

export default VisitsTableFooter;
