import React from 'react';
import { useTranslation } from 'react-i18next';
import { styled, SxProps } from '@mui/system';
import { theme } from '../../../../theme';
import { Grid, MenuItem } from '@mui/material';
import { FastField as Field } from 'formik';
import { Select, TextField } from 'formik-mui';
import FormikSelect from '../../../../components/FormikSelect';
import GenericField from '../../sharedFields/GenericField';

const StyledGrid = styled(Grid)({
  marginBottom: theme.spacing(2),
});

const sxFormControl: SxProps = {
  width: '100%',
  mb: 2,
};

const StyledTextField = styled(TextField)({
  marginBottom: theme.spacing(2),
});

const DiagnosesSection = () => {
  const { t } = useTranslation();

  return (
    <div id="diagnosesSection">
      <StyledGrid container spacing={{ xs: 0, md: 4, lg: 4 }}>
        <Grid item xs={12} md={6} lg={6}>
          <Grid item xs={12} md={12} lg={12}>
            <FormikSelect
              id="diagnoses_selectfield1"
              name="data.diagnosesSection.symptoms"
              formControl={sxFormControl}
              label={t('report.HMMFIRST.diagnosesSection.symptoms')}
            >
              <MenuItem value="valid">{t('global.form.select.valid')}</MenuItem>
              <MenuItem value="notValid">{t('global.form.select.notValid')}</MenuItem>
            </FormikSelect>
          </Grid>
          <Grid item xs={12} md={12} lg={12}>
            <FormikSelect
              id="diagnoses_selectfield3"
              name="data.diagnosesSection.hearing"
              formControl={sxFormControl}
              label={t('report.HMMFIRST.diagnosesSection.hearing')}
            >
              <MenuItem value="valid">{t('global.form.select.valid')}</MenuItem>
              <MenuItem value="notValid">{t('global.form.select.notValid')}</MenuItem>
            </FormikSelect>
          </Grid>
          <Grid item xs={12} md={12} lg={12}>
            <FormikSelect
              id="diagnoses_selectfield2"
              name="data.diagnosesSection.dominantHand"
              formControl={sxFormControl}
              label={t('report.HMMFIRST.diagnosesSection.dominantHand')}
            >
              <MenuItem value="right">{t('global.form.select.right')}</MenuItem>
              <MenuItem value="left">{t('global.form.select.left')}</MenuItem>
            </FormikSelect>
          </Grid>
        </Grid>
        <Grid item xs={12} md={6} lg={6}>
          <Grid item xs={12} md={12}>
            <Field
              fullWidth
              rows={1}
              multiline
              component={StyledTextField}
              type="text"
              name="data.diagnosesSection.symptomsNote"
              label={`${t('report.HMMFIRST.diagnosesSection.symptoms')} ${t(
                'report.HMKFIRST.remarks'
              )}`}
            />
          </Grid>
          <Grid item xs={12} md={12}>
            <Field
              fullWidth
              rows={1}
              multiline
              component={StyledTextField}
              type="text"
              name="data.diagnosesSection.hearingNote"
              label={`${t('report.HMMFIRST.diagnosesSection.hearing')} ${t(
                'report.HMKFIRST.remarks'
              )}`}
            />
          </Grid>
          <Grid item xs={12} md={12} lg={12}>
            <Field
              id="diagnoses_field"
              component={Select}
              name="data.diagnosesSection.functionalHand"
              formControl={{ sx: sxFormControl }}
              label={t('report.HMMFIRST.diagnosesSection.functionalHand')}
            >
              <MenuItem value="right">{t('global.form.select.right')}</MenuItem>
              <MenuItem value="left">{t('global.form.select.left')}</MenuItem>
            </Field>
          </Grid>
        </Grid>
      </StyledGrid>
      <GenericField
        id={`${'diagnosesSection'}_mobility`}
        fields={[
          {
            name: 'data.diagnosesSection.mobility',
            label: 'report.HMMFIRST.diagnosesSection.mobility',
            type: 'text',
          },
          {
            name: 'data.diagnosesSection.practiceBefore',
            label: 'report.HMMFIRST.diagnosesSection.practiceBefore',
            type: 'textareaWithEditor',
          },
          {
            name: 'data.diagnosesSection.currentFunctionalStatus',
            label: 'report.HMMFIRST.diagnosesSection.currentFunctionalStatus',
            type: 'textareaWithEditor',
          },
        ]}
      />
      <FormikSelect
        name="data.diagnosesSection.arousalLevel"
        formControl={sxFormControl}
        label={t('report.HMMFIRST.diagnosesSection.arousalLevel')}
      >
        <MenuItem value="alert1">{t('global.form.select.alert1')}</MenuItem>
        <MenuItem value="confusion">{t('global.form.select.confusion')}</MenuItem>
        <MenuItem value="dementia1">{t('global.form.select.dementia1')}</MenuItem>
        <MenuItem value="progressiveDementia">
          {t('global.form.select.progressiveDementia')}
        </MenuItem>
      </FormikSelect>
    </div>
  );
};

export default DiagnosesSection;
