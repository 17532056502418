import React, { useEffect, useState } from 'react';
import { Dialog, DialogTitle, DialogContent, LinearProgress, Typography, Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import { theme } from '../../theme';
import CancelIcon from '@mui/icons-material/Cancel';

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 10,
  borderRadius: 5,
  backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
  '& .MuiLinearProgress-bar': {
    borderRadius: 5,
    backgroundColor: theme.palette.mode === 'light' ? '#1a90ff' : '#308fe8',
  },
}));

const StyledDialogTitle = styled(DialogTitle)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: '16px', // Increased padding
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.primary.contrastText,
  '& .MuiTypography-root': {
    fontSize: '1.5rem',
  },
}));

const StyledBox = styled(Box, {
  name: 'StyledBox',
  slot: 'Wrapper',
})({
  alignItems: 'center',
  marginTop: theme.spacing(1),
  marginLeft: theme.spacing(1),
});
const StyledText = styled(Typography)(({ theme }) => ({
  fontSize: '1.2rem',
  marginBottom: theme.spacing(3), // Increased spacing
  color: theme.palette.text.primary,
}));

const StyledContentBox = styled(Box, {
  name: 'StyledContentBox',
  slot: 'Wrapper',
})({
  display: 'flex',
  gap: '2%',
  justifyContent: 'center',
  paddingTop: 36,
});

interface DownloadModalProps {
  open: boolean;
  totalItems: number;
  completedItems: number;
  onClose: (event: {}, reason?: 'backdropClick' | 'escapeKeyDown') => void;
}

const DownloadModal: React.FC<DownloadModalProps> = ({
  open,
  onClose,
  totalItems,
  completedItems,
}) => {
  const [progress, setProgress] = useState(0);
  const { t } = useTranslation();

  useEffect(() => {
    const currentProgress = (completedItems / totalItems) * 100;
    setProgress(currentProgress);
  }, [totalItems, completedItems]);

  return (
    <Dialog
      fullWidth
      open={open}
      onClose={onClose}
      disableEscapeKeyDown
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <StyledDialogTitle>
        {t('global.downloadModal.header')}
        <StyledBox>
          <CancelIcon
            sx={{ cursor: 'pointer', height: '100%' }}
            onClick={() => onClose({})}
            fontSize="large"
          />
        </StyledBox>
      </StyledDialogTitle>
      <DialogContent sx={{ padding: '5%' }}>
        <StyledContentBox>
          <StyledText> {completedItems}</StyledText>
          <StyledText> {t('global.downloadModal.out')}</StyledText>
          <StyledText> {totalItems}</StyledText>
          <StyledText> {t('global.downloadModal.completed')}</StyledText>
        </StyledContentBox>

        <Box>
          <BorderLinearProgress variant="determinate" value={progress} />
        </Box>
      </DialogContent>
    </Dialog>
  );
};

DownloadModal.displayName = 'DownloadModal';
export default DownloadModal;
