import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  therapeuticInterventionFields,
  bedSoreFields,
  painFields,
  getWoundEstimationFieldsValues,
} from '../../../../common/HMA';
import { Autocomplete, Button, IconButton, TextField, Typography } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import isReportMode from '../../../../hooks/isReportMode';
import { theme } from '../../../../theme';
import WoundEstimateSection from './WoundEstimateSection';
import { toast } from 'react-toastify';
import DeleteIcon from '@mui/icons-material/Delete';
import { FlexDiv } from '../../../../styles/DateRangeFilterStyles';
import { StyledDivider } from '../../../../styles/FormFieldsStyles';
import OldWoundEstimateSection from './OldWoundEstimateSection';
import SectionDivider from '../../../../components/SectionDivider';
import { checkIfReportSectionEmpty } from '../../../../utils/Helper';
import { ReportData } from '../../../../interfaces/Models';

const woundEstimateSectionInput = {
  title: '',
  fields: [],
  titleErrors: '',
  added: false,
};
export interface IWoundEstimateSection {
  title: string;
  fields: string[];
  titleErrors: string;
  added: boolean;
}

interface WoundEstimateFieldsProps {
  id: string;
  onChangeFormikValue: (field: string, value: any, shouldValidate?: boolean) => void;
  woundEstimateValues: IWoundEstimateSection[];
  oldWoundEstimateValues: ReportData;
}
const WoundEstimateFields = ({
  id,
  onChangeFormikValue,
  woundEstimateValues,
  oldWoundEstimateValues,
}: WoundEstimateFieldsProps) => {
  const { t } = useTranslation();
  const isViewMode = isReportMode('view');
  const fieldName = 'data.woundEstimation';

  const [woundEstimateFields, setWoundEstimateFields] = useState<IWoundEstimateSection[]>(
    woundEstimateValues && woundEstimateValues.length > 0
      ? woundEstimateValues
      : [woundEstimateSectionInput]
  );

  const woundEstimateSectionNamesOptions = [
    'bedsore',
    'diabeticUlcer',
    'burn',
    'WoundAfterSurgery',
  ];

  const prevIsValidWoundEstimate = () => {
    const numberOfSections = woundEstimateFields.length;
    if (numberOfSections === 0) {
      return true;
    }

    const fieldsFilled = woundEstimateFields[numberOfSections - 1].fields.filter(
      (field: string) => field.length > 0
    );

    if (fieldsFilled.length == 0) {
      setWoundEstimateFields((prev: IWoundEstimateSection[]) => {
        return prev.map((item: IWoundEstimateSection, i: number) => {
          return {
            ...item,
            titleErrors: item.title.length > 0 ? '' : t('clinic.form.required'),
          };
        });
      });
      if (woundEstimateFields[woundEstimateFields.length - 1].title.length > 0) {
        toast.error(t('report.HMAFIRST.woundEstimate.fieldsEmpty'));
      }
      return false;
    }

    return true;
  };

  const onChangeSectionTitle = (value: string, index: number) => {
    const fieldsValues = getWoundEstimationFieldsValues();
    setWoundEstimateFields((prev) => {
      return prev.map((item: IWoundEstimateSection, i: number) => {
        if (i !== index) {
          return item;
        }
        return {
          ...item,
          title: value,
          titleErrors: value.length > 0 ? '' : t('clinic.form.required'),
          fields: value.length > 0 ? fieldsValues : [],
        };
      });
    });
  };

  const onChangeSectionFieldValue = (sectionIdx: number, fieldIdx: number, value: string) => {
    setWoundEstimateFields((prev) => {
      return prev.map((item: IWoundEstimateSection, i: number) => {
        if (i !== sectionIdx) {
          return item;
        }

        item.fields[fieldIdx] = value;
        return item;
      });
    });
  };

  const addNewWoundEstimateSection = () => {
    const isWoundEstimatesSectionsValid = prevIsValidWoundEstimate();
    if (isWoundEstimatesSectionsValid) {
      setWoundEstimateFields((prev) => {
        return prev.map((item: IWoundEstimateSection, i: number) => {
          if (i == woundEstimateFields.length - 1) {
            item.added = true;
            return item;
          }
          return item;
        });
      });

      setWoundEstimateFields((prev: IWoundEstimateSection[]) => [
        ...prev,
        woundEstimateSectionInput,
      ]);
    }
    onChangeFormikValue(fieldName, woundEstimateFields);
  };

  const handleRemoveSection = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    index: number
  ) => {
    setWoundEstimateFields((prev) => prev.filter((item) => item !== prev[index]));
    onChangeFormikValue(fieldName, woundEstimateFields);
  };

  const isThereOldDataType =
    Object.keys(oldWoundEstimateValues || {}).length > 0 &&
    checkIfReportSectionEmpty(oldWoundEstimateValues['therapeuticIntervention']);

  return (
    <>
      <div id={id}>
        <Typography variant="subtitle1" gutterBottom align="left">
          {t('report.HMAFIRST.woundEstimate.header')}
        </Typography>
        {isThereOldDataType && (
          <>
            <OldWoundEstimateSection /> <SectionDivider />{' '}
          </>
        )}
        {woundEstimateFields.map((section: IWoundEstimateSection, sectionIdx: number) => {
          return (
            <div key={`${section.title}_${sectionIdx}`} style={{ marginBottom: theme.spacing(2) }}>
              <Typography variant="subtitle2" gutterBottom align="left">
                {section.title.length > 0
                  ? `${t('report.HMAFIRST.woundEstimate.header')} ${
                      woundEstimateSectionNamesOptions.indexOf(section.title) > -1
                        ? t(`global.form.select.${section.title}`)
                        : section.title
                    }`
                  : ''}
              </Typography>
              <FlexDiv sx={{ justifyContent: 'space-between' }}>
                <Autocomplete
                  disablePortal
                  id={`Section_Title`}
                  freeSolo
                  options={woundEstimateSectionNamesOptions}
                  getOptionLabel={(option: string) =>
                    woundEstimateSectionNamesOptions.indexOf(option) > -1
                      ? t(`global.form.select.${option}`)
                      : option || ''
                  }
                  sx={{ width: '100%' }}
                  disabled={section.added || isViewMode}
                  value={section.title}
                  onChange={(ev, newValue) => {
                    if (typeof newValue == 'string') {
                      onChangeSectionTitle(newValue as string, sectionIdx);
                    } else {
                      onChangeSectionTitle('', sectionIdx);
                    }
                  }}
                  renderInput={(params) => (
                    <TextField
                      error={section.titleErrors.length > 1}
                      {...params}
                      label={t('report.categories.header')}
                      helperText={
                        section.titleErrors && section.titleErrors.length > 1
                          ? section.titleErrors
                          : ''
                      }
                    />
                  )}
                />
                <IconButton
                  color="error"
                  aria-label="delete"
                  onClick={(e) => handleRemoveSection(e, sectionIdx)}
                  size="large"
                  disabled={isViewMode}
                >
                  <DeleteIcon />
                </IconButton>
              </FlexDiv>
              {section.fields.length > 0 && (
                <>
                  <WoundEstimateSection
                    header={t('report.HMAFIRST.woundEstimate.therapeuticIntervention.header')}
                    section={section}
                    onChangeFieldValue={onChangeSectionFieldValue}
                    sectionIdx={sectionIdx}
                    fieldsOptions={therapeuticInterventionFields}
                    therapeuticIntervention={true}
                  />
                  <WoundEstimateSection
                    header={
                      woundEstimateSectionNamesOptions.indexOf(section.title) > -1
                        ? t(`global.form.select.${section.title}`)
                        : section.title
                    }
                    section={section}
                    onChangeFieldValue={onChangeSectionFieldValue}
                    sectionIdx={sectionIdx}
                    fieldsOptions={[...bedSoreFields, ...painFields]}
                  />
                  <StyledDivider />
                </>
              )}
            </div>
          );
        })}
        <Button
          variant="contained"
          disabled={isViewMode}
          endIcon={<AddIcon />}
          onClick={addNewWoundEstimateSection}
        >
          {t('global.form.add')}
        </Button>
      </div>
    </>
  );
};

export default WoundEstimateFields;
