import React from 'react';
import { Autocomplete, AutocompleteRenderInputParams } from 'formik-mui';
import { FastField } from 'formik';
import { useTranslation } from 'react-i18next';
import { TextField as TextInput } from '@mui/material';
import { styled } from '@mui/system';
import { theme } from '../../../../theme';
import { FormikErrors, FormikTouched } from 'formik';
import GenericField from '../../sharedFields/GenericField';
import {
  nutritionalGoalsFields,
  patientNeedsFields,
  nutritionalGoalsNeedsFields,
} from '../../../../common/HMDFirst';

const StyledTextInput = styled(TextInput)({
  marginBottom: theme.spacing(2),
});

interface NutritionalGoalsFieldsProps {
  id: string;
  touched: FormikTouched<any>;
  errors: FormikErrors<any>;
}

const NutritionalGoalsFields = ({ id, touched, errors }: NutritionalGoalsFieldsProps) => {
  const { t } = useTranslation();
  const dietsTypes: string[] = [
    'highCalories',
    'highProtein',
    'thinness',
    'diabetics',
    'potassium',
    'sodium',
  ];

  return (
    <div id={id}>
      <GenericField
        id={`${id}_Needs`}
        header={t('report.HMDFIRST.nutritionalGoals.header')}
        fields={nutritionalGoalsFields}
      />
      <GenericField
        id={`${id}_PatientNeeds`}
        subHeader={t('report.HMDFIRST.nutritionalGoals.nutritionalNeeds.totalPatientNeed.header')}
        fields={patientNeedsFields}
      />
      <GenericField
        id={`${id}_Details`}
        fields={[
          {
            name: 'data.nutritionalGoals.nutritionalNeeds.details',
            label: 'report.HMDFIRST.nutritionalGoals.nutritionalNeeds.details.header',
            helperText: 'report.HMDFIRST.nutritionalGoals.nutritionalNeeds.details.helperText',
            type: 'textareaWithEditor',
          },
          {
            name: 'data.nutritionalGoals.nutritionalNeeds.restrictions',
            label: 'report.HMDFIRST.nutritionalGoals.nutritionalNeeds.restrictions.header',
            helperText: 'report.HMDFIRST.nutritionalGoals.nutritionalNeeds.restrictions.helperText',
            type: 'textareaWithEditor',
          },
        ]}
      />
      <GenericField
        id={`${id}_NutritionalGoals`}
        subHeader={t('report.HMDFIRST.nutritionalGoals.nutritionalNeeds.header')}
        fields={nutritionalGoalsNeedsFields}
      />
      <FastField
        fullWidth
        name="data.nutritionalGoals.nutritionalNeeds.nutritionalTreatment"
        component={Autocomplete}
        options={dietsTypes}
        multiple
        freeSolo
        getOptionLabel={(option: string) =>
          t(`report.HMDFIRST.nutritionalGoals.nutritionalNeeds.nutritionalTreatment.${option}`) ||
          option
        }
        renderInput={(params: AutocompleteRenderInputParams) => (
          <StyledTextInput
            {...params}
            name="data.nutritionalGoals.nutritionalNeeds.nutritionalTreatment"
            error={
              touched['data.nutritionalGoals.nutritionalNeeds.nutritionalTreatment'] &&
              !!errors['data.nutritionalGoals.nutritionalNeeds.nutritionalTreatment']
            }
            helperText={
              touched['data.nutritionalGoals.nutritionalNeeds.nutritionalTreatment'] &&
              errors['data.nutritionalGoals.nutritionalNeeds.nutritionalTreatment']
                ? t('clinic.form.required')
                : ''
            }
            label={t('report.HMDFIRST.nutritionalGoals.nutritionalNeeds.nutritionalTreatment.diet')}
            variant="outlined"
          />
        )}
      />
    </div>
  );
};

export default NutritionalGoalsFields;
