import { format } from 'date-fns';
import { t } from 'i18next';
import Label from '../../components/Label';
import { getSortDirection, getVisitType } from '../../utils/Helper';
import { VISIT_STATUS_COLOR, VISIT_STATUS_TO_HEBREW } from '../../common/Constants';

export const getPatientNameColumn = (sort?: string) => {
  return {
    id: 'patientName',
    Header: `${t('patient.info.patientName')}`,
    accessor: 'patientName',
    sortType: 'basic',
    sortDirection: getSortDirection(sort || '', 'patientName'),
  };
};

export const getMyVisitsColumns = (sort: string) => {
  return [
    {
      id: 'visitType',
      Header: `${t('patient.form.visitType')}`,
      accessor: (visit: any) => t(`global.visitTypes.${getVisitType(visit)}`),
    },
    getPatientNameColumn(sort),
    {
      Header: `${t('patient.info.patientPhone')}`,
      accessor: 'patientPhone',
    },
    {
      id: 'status',
      Header: `${t('visit.table.visitStatus')}`,
      accessor: (visit: any) => {
        return (
          <Label variant="ghost" color={VISIT_STATUS_COLOR[visit.status]}>
            {VISIT_STATUS_TO_HEBREW[visit.status.toString().toLocaleUpperCase()]}
          </Label>
        );
      },
    },
    {
      id: 'dueDate',
      Header: `${t('visit.table.dueDate')}`,
      accessor: (visit: any) => format(new Date(visit.dueDate), 'dd-MM-yyyy'),
      sortType: 'basic',
      sortDirection: getSortDirection(sort, 'dueDate'),
    },

    {
      id: 'isRelease',
      Header: `${t('visit.table.isRelease')}`,
      accessor: (visit: any) => {
        return (
          <Label variant="ghost" color={visit.isRelease ? 'success' : 'error'}>
            {visit.isRelease ? 'כן' : 'לא'}
          </Label>
        );
      },
    },
  ];
};
