import { Grid, MenuItem, Typography } from '@mui/material';
import React from 'react';
import { styled, SxProps } from '@mui/system';
import { theme } from '../../../../theme';
import { FastField as Field } from 'formik';
import { useTranslation } from 'react-i18next';
import { TextField } from 'formik-mui';
import { properOptions } from '../../../../common/HMMFirst';
import FormikSelect from '../../../../components/FormikSelect';

const StyledTextField = styled(TextField)({
  marginBottom: theme.spacing(2),
});

const sxFormControl: SxProps = {
  width: '100%',
};

const MuscularMovementFields = () => {
  const { t } = useTranslation();

  const fields = [
    {
      name: 'shoulder',
      rightActive: {
        label: `${t('report.HMMFIRST.muscularMovement.motor.active')} ${t(
          'report.HMMFIRST.muscularMovement.motor.shoulder'
        )} ${t('report.HMMFIRST.muscularMovement.motor.right')}`,
        name: 'rightActiveShoulder',
      },
      leftActive: {
        label: `${t('report.HMMFIRST.muscularMovement.motor.active')} ${t(
          'report.HMMFIRST.muscularMovement.motor.shoulder'
        )} ${t('report.HMMFIRST.muscularMovement.motor.left')}`,
        name: 'leftActiveShoulder',
      },
      rightPassive: {
        label: ` ${t('report.HMMFIRST.muscularMovement.motor.passive')} ${t(
          'report.HMMFIRST.muscularMovement.motor.shoulder'
        )} ${t('report.HMMFIRST.muscularMovement.motor.right')}`,
        name: 'rightPassiveShoulder',
      },
      leftPassive: {
        label: ` ${t('report.HMMFIRST.muscularMovement.motor.passive')}  ${t(
          'report.HMMFIRST.muscularMovement.motor.shoulder'
        )} ${t('report.HMMFIRST.muscularMovement.motor.left')}`,
        name: 'leftPassiveShoulder',
      },
    },
    {
      name: 'elbow',
      rightActive: {
        label: ` ${t('report.HMMFIRST.muscularMovement.motor.active')}  ${t(
          'report.HMMFIRST.muscularMovement.motor.elbow'
        )} ${t('report.HMMFIRST.muscularMovement.motor.right')}`,
        name: 'rightActiveElbow',
      },
      leftActive: {
        label: ` ${t('report.HMMFIRST.muscularMovement.motor.active')}  ${t(
          'report.HMMFIRST.muscularMovement.motor.elbow'
        )} ${t('report.HMMFIRST.muscularMovement.motor.left')}`,
        name: 'leftActiveElbow',
      },
      rightPassive: {
        label: `  ${t('report.HMMFIRST.muscularMovement.motor.passive')} ${t(
          'report.HMMFIRST.muscularMovement.motor.elbow'
        )} ${t('report.HMMFIRST.muscularMovement.motor.right')}`,
        name: 'rightPassiveElbow',
      },
      leftPassive: {
        label: ` ${t('report.HMMFIRST.muscularMovement.motor.passive')} ${t(
          'report.HMMFIRST.muscularMovement.motor.elbow'
        )} ${t('report.HMMFIRST.muscularMovement.motor.left')}`,
        name: 'leftPassiveElbow',
      },
    },
    {
      name: 'aqueduct',
      rightActive: {
        label: ` ${t('report.HMMFIRST.muscularMovement.motor.active')} ${t(
          'report.HMMFIRST.muscularMovement.motor.aqueduct'
        )} ${t('report.HMMFIRST.muscularMovement.motor.right')}`,
        name: 'rightActiveAqueduct',
      },
      leftActive: {
        label: `${t('report.HMMFIRST.muscularMovement.motor.active')} ${t(
          'report.HMMFIRST.muscularMovement.motor.aqueduct'
        )} ${t('report.HMMFIRST.muscularMovement.motor.left')}`,
        name: 'leftActiveAqueduct',
      },
      rightPassive: {
        label: ` ${t('report.HMMFIRST.muscularMovement.motor.passive')} ${t(
          'report.HMMFIRST.muscularMovement.motor.aqueduct'
        )} ${t('report.HMMFIRST.muscularMovement.motor.right')}`,
        name: 'rightPassiveAqueduct',
      },
      leftPassive: {
        label: ` ${t('report.HMMFIRST.muscularMovement.motor.passive')} ${t(
          'report.HMMFIRST.muscularMovement.motor.aqueduct'
        )} ${t('report.HMMFIRST.muscularMovement.motor.left')}`,
        name: 'leftPassiveAqueduct',
      },
    },
    {
      name: 'SKI',
      rightActive: {
        label: ` ${t('report.HMMFIRST.muscularMovement.motor.active')} ${t(
          'report.HMMFIRST.muscularMovement.motor.SKI'
        )} ${t('report.HMMFIRST.muscularMovement.motor.right')}`,
        name: 'rightActiveSKI',
      },
      leftActive: {
        label: ` ${t('report.HMMFIRST.muscularMovement.motor.active')} ${t(
          'report.HMMFIRST.muscularMovement.motor.SKI'
        )} ${t('report.HMMFIRST.muscularMovement.motor.left')}`,
        name: 'leftActiveSKI',
      },
      rightPassive: {
        label: ` ${t('report.HMMFIRST.muscularMovement.motor.passive')} ${t(
          'report.HMMFIRST.muscularMovement.motor.SKI'
        )} ${t('report.HMMFIRST.muscularMovement.motor.right')}`,
        name: 'rightPassiveSKI',
      },
      leftPassive: {
        label: ` ${t('report.HMMFIRST.muscularMovement.motor.passive')} ${t(
          'report.HMMFIRST.muscularMovement.motor.SKI'
        )} ${t('report.HMMFIRST.muscularMovement.motor.left')}`,
        name: 'leftPassiveSKI',
      },
    },
    {
      name: 'fingers',
      rightActive: {
        label: ` ${t('report.HMMFIRST.muscularMovement.motor.active')} ${t(
          'report.HMMFIRST.muscularMovement.motor.fingers'
        )} ${t('report.HMMFIRST.muscularMovement.motor.right')}`,
        name: 'rightActiveFingers',
      },
      leftActive: {
        label: ` ${t('report.HMMFIRST.muscularMovement.motor.active')} ${t(
          'report.HMMFIRST.muscularMovement.motor.fingers'
        )} ${t('report.HMMFIRST.muscularMovement.motor.left')}`,
        name: 'leftActiveFingers',
      },
      rightPassive: {
        label: ` ${t('report.HMMFIRST.muscularMovement.motor.passive')} ${t(
          'report.HMMFIRST.muscularMovement.motor.fingers'
        )} ${t('report.HMMFIRST.muscularMovement.motor.right')}`,
        name: 'rightPassiveFingers',
      },
      leftPassive: {
        label: ` ${t('report.HMMFIRST.muscularMovement.motor.passive')} ${t(
          'report.HMMFIRST.muscularMovement.motor.fingers'
        )} ${t('report.HMMFIRST.muscularMovement.motor.left')}`,
        name: 'leftPassiveFingers',
      },
    },
  ];
  const id = 'HMMFirst_MuscularMovement';

  return (
    <div id={id}>
      <Typography variant="subtitle1" gutterBottom align="left">
        {t('report.HMMFIRST.muscularMovement.header')}
      </Typography>
      {fields.map((field) => {
        return (
          <Grid
            container
            spacing={{ xs: 2, md: 4, lg: 4 }}
            key={field.name + field.rightActive.label}
            id={`${id}_Container`}
          >
            <Grid item xs={12} md={6} lg={6}>
              <FormikSelect
                id={`${id}_SelectInput_${field.rightActive.name}`}
                name={`data.muscularMovement.${field.rightActive.name}`}
                label={field.rightActive.label}
                formControl={sxFormControl}
              >
                {properOptions.map((option: string) => {
                  return (
                    <MenuItem key={option} value={option}>
                      {t(`global.form.select.${option}`)}
                    </MenuItem>
                  );
                })}
              </FormikSelect>
            </Grid>
            <Grid item xs={12} md={6} lg={6}>
              <FormikSelect
                id={`${id}_SelectInput_${field.leftActive.name}`}
                name={`data.muscularMovement.${field.leftActive.name}`}
                label={field.leftActive.label}
                formControl={sxFormControl}
              >
                {properOptions.map((option: string) => {
                  return (
                    <MenuItem key={option} value={option}>
                      {t(`global.form.select.${option}`)}
                    </MenuItem>
                  );
                })}
              </FormikSelect>
            </Grid>
            <Grid item xs={12} md={6} lg={6}>
              <FormikSelect
                id={`${id}_SelectInput_${field.rightPassive.name}`}
                name={`data.muscularMovement.${field.rightPassive.name}`}
                label={field.rightPassive.label}
                formControl={sxFormControl}
              >
                {properOptions.map((option: string) => {
                  return (
                    <MenuItem key={option} value={option}>
                      {t(`global.form.select.${option}`)}
                    </MenuItem>
                  );
                })}
              </FormikSelect>
            </Grid>
            <Grid item xs={12} md={6} lg={6}>
              <FormikSelect
                id={`${id}_SelectInput_${field.leftPassive.name}`}
                name={`data.muscularMovement.${field.leftPassive.name}`}
                label={field.leftPassive.label}
                formControl={sxFormControl}
              >
                {properOptions.map((option: string) => {
                  return (
                    <MenuItem key={option} value={option}>
                      {t(`global.form.select.${option}`)}
                    </MenuItem>
                  );
                })}
              </FormikSelect>
            </Grid>
            <Grid item xs={12} md={12} lg={12}>
              <Field
                id={`${id}_SelectInput_${field.name}`}
                fullWidth
                component={StyledTextField}
                type="text"
                name={`data.muscularMovement.${field.name}Notes`}
                label={`${t(`report.HMMFIRST.muscularMovement.motor.${field.name}`)} ${t(
                  'report.HMKFIRST.remarks'
                )}`}
              />
            </Grid>
          </Grid>
        );
      })}
    </div>
  );
};

export default MuscularMovementFields;
