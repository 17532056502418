import { Icon } from '@iconify/react';
import { t } from 'i18next';
import { getVisitStatusColumn } from '../../../common/columns/AllVisitsTableColumn';
import {
  getVisitPositionColumn,
  getEditAction,
  getVisitTypeColumn,
} from '../../../common/columns/ProgramVisits';
import { VISIT_STATUS } from '../../../common/Constants';
import MoreOptions from '../../../components/MoreOptions';
import {
  IEditedVisits,
  IPatientProgram,
  IPatientProgramFlat,
  IVisit,
} from '../../../interfaces/Models';
import { theme } from '../../../theme';
import { getDateFormatted } from '../../../utils/Helper';
import { checkIfVisitIsGeneralOrFirst } from '../../../utils/HospitalDaysHelper';
import LocalHospitalIcon from '@mui/icons-material/LocalHospital';
import CancelPresentationIcon from '@mui/icons-material/CancelPresentation';

const checkIfVisitCanceled = (visit: IVisit, toBeCanceledVisits: IVisit[]) => {
  const visitCanceled = toBeCanceledVisits.find(
    (visitCanceled: IVisit) => visitCanceled._id == visit._id
  );
  return !!visitCanceled;
};

export const checkIfVisitChanged = (
  visit: IVisit,
  editedVisits: IEditedVisits[],
  toBeCanceledVisits: IVisit[]
) => {
  const isVisitEdited = editedVisits.find(
    (editedVisit: IEditedVisits) => editedVisit.visitId == visit._id
  );
  const isVisitCanceled = checkIfVisitCanceled(visit, toBeCanceledVisits);
  return { isEdit: !!isVisitEdited, isVisitEdited, isCanceled: isVisitCanceled };
};

const getVisitDueDate = (
  visit: IVisit,
  editedVisits: IEditedVisits[],
  toBeCanceledVisits: IVisit[]
) => {
  const isDueDateChanged = checkIfVisitChanged(visit, editedVisits, toBeCanceledVisits);
  return isDueDateChanged.isEdit
    ? getDateFormatted(isDueDateChanged.isVisitEdited?.newDueDate || new Date())
    : '';
};

const getVisitActionTaken = (
  visit: IVisit,
  editedVisits: IEditedVisits[],
  toBeCanceledVisits: IVisit[]
) => {
  if (!!checkIfVisitCanceled(visit, toBeCanceledVisits)) {
    return t('global.dashboard.canceled');
  }

  const isVisitEdited = checkIfVisitChanged(visit, editedVisits, toBeCanceledVisits).isEdit;
  if (isVisitEdited) {
    return t(`program.hospitalDaysForm.actionTaken.dueDateChanged`);
  }

  return '';
};

export const getHospitalDayVisitActions = (
  toBeCanceledVisits: IVisit[],
  editedVisits: IEditedVisits[],
  handleOpenChangeVisitDueDateModal: (visit: IVisit) => void,
  handleOpenCancelVisitModal: (visit: IVisit) => void,
  removeVisitAction: (visit: IVisit) => void
) => {
  return {
    Header: t('clinic.table.actions'),
    accessor: (visit: any) => {
      const visitChanges = checkIfVisitChanged(visit, editedVisits, toBeCanceledVisits);

      return (
        <>
          <MoreOptions
            actions={[
              {
                ...getEditAction(handleOpenChangeVisitDueDateModal),
                name: t('program.hospitalDaysForm.editDueDate'),
              },
              {
                name: t('global.moreMenu.cancelVisit'),
                handler: handleOpenCancelVisitModal,
                icon: (
                  <Icon
                    icon="ic:baseline-cancel"
                    width={24}
                    height={24}
                    color={theme.palette.error.main}
                  />
                ),
                isVisible:
                  !checkIfVisitIsGeneralOrFirst(visit) &&
                  visit.status != VISIT_STATUS.CANCELED &&
                  !visitChanges.isCanceled,
                variant: theme.palette.error.main,
              },
              {
                name: t('program.hospitalDaysForm.removeVisitActions'),
                handler: removeVisitAction,
                icon: <CancelPresentationIcon width={24} height={24} color="error" />,
                isVisible: visitChanges.isEdit || visitChanges.isCanceled,
                variant: theme.palette.error.main,
              },
            ]}
            record={visit}
          />
        </>
      );
    },
  };
};
export const getHospitalDaysVisitsColumnsWithoutActions = (
  editedVisits: IEditedVisits[],
  toBeCanceledVisits: IVisit[]
) => {
  return [
    getVisitPositionColumn(),
    getVisitTypeColumn(),
    getVisitStatusColumn(),
    {
      id: 'previousDueDate',
      Header: t('program.hospitalDaysForm.previousDueDate'),
      accessor: (visit: any) => getDateFormatted(visit.dueDate),
    },
    {
      id: 'newDueDate',
      Header: t('program.hospitalDaysForm.newDueDate'),
      accessor: (visit: any) => getVisitDueDate(visit, editedVisits, toBeCanceledVisits),
    },
    {
      id: 'actionTaken',
      Header: t('program.hospitalDaysForm.actionTaken.header'),
      accessor: (visit: any) => getVisitActionTaken(visit, editedVisits, toBeCanceledVisits),
    },
  ];
};

export const getHospitalDaysActions = (
  program: IPatientProgramFlat | IPatientProgram,
  handleOpenAddHospitalDaysForm?: (value: IPatientProgramFlat | IPatientProgram) => void,
  updateAdminAndHmaAbility?: boolean
) => {
  return handleOpenAddHospitalDaysForm
    ? [
        {
          name: t('program.hospitalDaysForm.addHospitalDays'),
          handler: handleOpenAddHospitalDaysForm,
          icon: <LocalHospitalIcon width={24} height={24} color="warning" />,
          isVisible: updateAdminAndHmaAbility,
        },
      ]
    : [];
};
