/* eslint-disable new-cap */
import React from 'react';
import { visitAttachmentsAtom } from '../../../atoms/visitAttachmentsAtom';
import { useRecoilState } from 'recoil';
import { useTranslation } from 'react-i18next';
import ViewModal from '../../ViewModal';
import { Stack } from '@mui/material';
import ReportAttachmentsTable from './ReportAttachmentsTable';
import FetcherService from '../../../hooks/FetcherService';
import attachmentService from '../../../services/attachemntService';
import { IAttachment } from '../../../interfaces/attachment';

interface Props {
  handleCloseModal: () => void;
}
export default function ReportAttachmentsView({ handleCloseModal }: Props) {
  const { t } = useTranslation();
  const [visitAttachments, setVisitAttachments] = useRecoilState(visitAttachmentsAtom);

  const fileName = t('global.attachments.header');

  const handleDeleteAttachment = async (val: IAttachment) => {
    setVisitAttachments((prevFiles: IAttachment[]) => {
      const newAttachments = prevFiles.filter(
        (attachment: IAttachment) => attachment._id !== val?._id
      );
      return newAttachments;
    });
    await FetcherService({
      service: attachmentService.deleteByRecordId(String(val._id)),
      errorMessage: t('global.attachments.deleteError'),
    });
  };

  const isThereAttachments = visitAttachments?.length > 0;

  return (
    <ViewModal maxWidth="md" onClose={handleCloseModal} open={true} title={fileName}>
      <Stack direction={'column'} spacing={2} width={'100%'}>
        {isThereAttachments && (
          <ReportAttachmentsTable
            key={`attachments_table_${visitAttachments.length}`}
            attachments={visitAttachments}
            handleDeleteAttachment={handleDeleteAttachment}
          />
        )}
      </Stack>
    </ViewModal>
  );
}
