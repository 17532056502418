import { FormControl, FormHelperText, InputLabel, MenuItem, Select } from '@mui/material';
import React from 'react';

interface ISelectInput {
  value: string;
  onChangeValue: (value: string) => void;
  label: string;
  options?: string[];
  error?: string;
  children?: React.ReactNode;
}
const SelectInput = ({ value, onChangeValue, label, options, error, children }: ISelectInput) => {
  return (
    <FormControl
      sx={{
        minWidth: '100%',
      }}
      error={!!error && error.length > 0}
    >
      <InputLabel>{label}</InputLabel>
      <Select
        sx={{ width: '100%' }}
        id="Select_Filter"
        value={value}
        label={label}
        onChange={(e) => onChangeValue(e.target.value)}
      >
        {children}
        {options &&
          options.map((option) => {
            return (
              <MenuItem key={option} value={option}>
                {option}
              </MenuItem>
            );
          })}
      </Select>
      <FormHelperText>{error}</FormHelperText>
    </FormControl>
  );
};

export default SelectInput;
