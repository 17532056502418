import { IService, IQuery } from '../interfaces/index';
import { HTTP_METHODS } from '../common/APIHelper';
import { IReport } from '../interfaces/Models';

const BASE_PATH = '/api/report';

const add = (payload: IReport): IService => {
  return { route: '/api/report', method: HTTP_METHODS.POST, payload };
};

const getReportByVisitId = (visitId: string): IService => {
  return { route: `${BASE_PATH}/visit/${visitId}`, method: HTTP_METHODS.GET };
};

const edit = (id: string, payload: IReport): IService => {
  return { route: `${BASE_PATH}/visit/${id}`, method: HTTP_METHODS.PUT, payload };
};

const generateReportPdf = (visitId: string): IService => {
  return {
    route: `${BASE_PATH}/generate-report/${visitId}`,
    method: HTTP_METHODS.GET,
    responseType: 'blob',
  };
};

const generateGlobalReleaseReportPdf = (programId: string, query?: IQuery): IService => {
  return {
    route: `${BASE_PATH}/generate-global-release-report/${programId}`,
    method: HTTP_METHODS.GET,
    responseType: 'blob',
    query,
  };
};

const getRepeatedReportData = (visitId: string): IService => {
  return { route: `${BASE_PATH}/report-data/${visitId}`, method: HTTP_METHODS.GET };
};

const ReportService = {
  add,
  getReportByVisitId,
  edit,
  generateGlobalReleaseReportPdf,
  generateReportPdf,
  getRepeatedReportData,
};

export default ReportService;
