import React from 'react';
import GenericField from '../../sharedFields/GenericField';
import { useTranslation } from 'react-i18next';
import { therapeuticInterventionFields, bedSoreFields, painFields } from '../../../../common/HMA';

const OldWoundEstimateSection = () => {
  const { t } = useTranslation();

  return (
    <div id="Wound_Estimation">
      {' '}
      <GenericField
        id={`TherapeuticIntervention`}
        fields={therapeuticInterventionFields}
        subHeader={t('report.HMAFIRST.woundEstimate.therapeuticIntervention.header')}
      />
      <GenericField
        id={`Bedsore_Pain`}
        fields={[...bedSoreFields, ...painFields]}
        subHeader={t('report.HMAFIRST.woundEstimate.bedsore.header')}
      />
    </div>
  );
};

export default OldWoundEstimateSection;
