import React from 'react';
import GenericField from './GenericField';
import { useTranslation } from 'react-i18next';
import { releaseSummaryAndRecommendationsFields } from '../../../common/ReleaseFields';

interface ReleaseFieldsProps {
  id: string;
}
const ReleaseFields = ({ id }: ReleaseFieldsProps) => {
  const { t } = useTranslation();

  return (
    <>
      <GenericField
        id={`${id}_ReleaseFields`}
        fields={releaseSummaryAndRecommendationsFields}
        subHeader={t(`report.releaseSection.header`)}
      />
    </>
  );
};

export default ReleaseFields;
