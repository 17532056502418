import { IQuery, IService } from '../interfaces/index';
import { HTTP_METHODS } from '../common/APIHelper';
import { ILeumitMissingReferenceNumberPayload } from '../pages/leumitReports/missing-records/MissingLeumitRecordsTableHeader';

const BASE_PATH = '/api/reports';

const getEmployeesFinancialReports = (query?: IQuery): IService => {
  return { route: `${BASE_PATH}/financial/employees`, method: HTTP_METHODS.GET, query };
};

const getProgramsFinancialReports = (query?: IQuery): IService => {
  return { route: `${BASE_PATH}/financial/programs`, method: HTTP_METHODS.GET, query };
};

interface ILeumitFinancialReportsPayload {
  billingDate: Date;
  invoiceNumber: string;
}

const getLeumitFinancialReports = (payload: ILeumitFinancialReportsPayload): IService => {
  return { route: `${BASE_PATH}/clinic/leumit`, method: HTTP_METHODS.POST, payload };
};

const getEmployeeVisitsDelayFinancialReport = (query?: IQuery): IService => {
  return {
    route: `${BASE_PATH}/financial/employee/delay/calculation`,
    method: HTTP_METHODS.GET,
    query,
  };
};

const getLeumitProgramsMissingReferenceNumber = (
  payload: ILeumitMissingReferenceNumberPayload
): IService => {
  return {
    route: `${BASE_PATH}/leumit/programs/missing/reference-number`,
    method: HTTP_METHODS.POST,
    payload,
  };
};

const getLeumitVisitsMissingReferenceNumber = (
  payload: ILeumitMissingReferenceNumberPayload
): IService => {
  return {
    route: `${BASE_PATH}/leumit/visits/missing/reference-number`,
    method: HTTP_METHODS.POST,
    payload,
  };
};

const getLeumitVisitsMissingPaymentMode = (
  payload: ILeumitMissingReferenceNumberPayload
): IService => {
  return {
    route: `${BASE_PATH}/leumit/programs/missing/payment-mode`,
    method: HTTP_METHODS.POST,
    payload,
  };
};

const ReportsService = {
  getEmployeesFinancialReports,
  getProgramsFinancialReports,
  getLeumitFinancialReports,
  getEmployeeVisitsDelayFinancialReport,
  getLeumitProgramsMissingReferenceNumber,
  getLeumitVisitsMissingReferenceNumber,
  getLeumitVisitsMissingPaymentMode,
};

export default ReportsService;
