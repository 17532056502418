import { Card, Container } from '@mui/material';
import React from 'react';
import Page from '../../components/Page';
import PageHeader from '../../components/PageHeader';
import { useTranslation } from 'react-i18next';
import PatientTable from './PatientTable';

const Patient: React.FC = () => {
  const { t } = useTranslation();

  return (
    <>
      <Page title={t('patient.mainView.header')}>
        <Container>
          <PageHeader pageTitle={t('patient.mainView.header')} />
          <Card>
            <PatientTable />
          </Card>
        </Container>
      </Page>
    </>
  );
};

export default Patient;
