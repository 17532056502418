import { Avatar } from '@mui/material';
import React from 'react';

interface IUserAvatar {
  source: string;
  alt?: string;
  sx?: Object;
}
const UserAvatar: React.FC<IUserAvatar> = ({ source, alt, sx, children, ...other }) => {
  return (
    <Avatar src={source} alt={alt} sx={sx} {...other}>
      {children}
    </Avatar>
  );
};

export default UserAvatar;
