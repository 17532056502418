import React from 'react';
import { CircularProgress } from '@mui/material';
import { Autocomplete, AutocompleteRenderInputParams } from 'formik-mui';
import axios from 'axios';
import { Field, useFormikContext } from 'formik';
import { t } from 'i18next';
import { StyledTextInput } from '../../../styles/FormFieldsStyles';
import { toast } from 'react-toastify';

const DiseaseAutoComplete = ({ fieldName, label }: { fieldName: string; label?: string }) => {
  const [options, setOptions] = React.useState<string[]>([]);
  const [open, setOpen] = React.useState(false);
  const loading = open && options.length === 0;
  const { touched, errors }: any = useFormikContext();

  const getOptionsFormatted = (optionsValues: Array<any>) => {
    const newOptions: string[] = [];
    optionsValues.map((disease: string[]) => newOptions.push(disease[1]));
    setOptions(newOptions || []);
  };
  const onChangeHandle = async (value: string) => {
    axios
      .get('https://clinicaltables.nlm.nih.gov/api/icd10cm/v3/search', {
        params: {
          sf: 'code,name',
          terms: value,
          maxList: 20,
        },
      } as any)
      .then((res) => {
        getOptionsFormatted(res.data[3]);
      })
      .catch((err: Error) => {
        // eslint-disable-next-line no-console
        console.log(err);
        toast.error(err.message);
      });
  };

  React.useEffect(() => {
    if (!open) {
      setOptions([]);
    }
  }, [open]);

  return (
    <Field
      open={open}
      component={Autocomplete}
      name={fieldName}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      multiple
      freeSolo
      getOptionLabel={(option: string) => option}
      options={options}
      loading={loading}
      renderInput={(params: AutocompleteRenderInputParams) => (
        <StyledTextInput
          {...params}
          key={params.id}
          name={fieldName}
          variant="outlined"
          error={touched[fieldName] && !!errors[fieldName]}
          helperText={touched[fieldName] && errors[fieldName] ? t('clinic.form.required') : ''}
          label={label || t(`report.chronicDiseases.${fieldName}`)}
          onChange={(ev) => {
            // dont fire API if the user delete or not entered anything
            if (ev.target.value !== '' || ev.target.value !== null) {
              onChangeHandle(ev.target.value);
            }
          }}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
        />
      )}
    />
  );
};

export default DiseaseAutoComplete;
