import React, { useEffect } from 'react';
import { styled } from '@mui/system';
import { Box, Card, Typography } from '@mui/material';
import { theme } from '../../../theme';
import AddReportLayout from '../../../layouts/reports/AddReportLayout';
import EditReportLayout from '../../../layouts/EditReportLayout';
import { useParams } from 'react-router-dom';
import SLSCache from 'sls-cache';
import { IReport } from '../../../interfaces/Models';
import { visitAtom } from '../../../atoms/visitAtom';
import { useRecoilValue } from 'recoil';
import { getDateFormatted } from '../../../utils/Helper';
import { useTranslation } from 'react-i18next';
import { IVisitWithProgramTitle } from '../../../layouts/reports/ReportLayout';
import { toast } from 'react-toastify';

const StyledCard = styled(Card)({
  padding: theme.spacing(5),
});

interface Props {
  Outlet: React.FC<any>;
  visitData: IVisitWithProgramTitle;
  isViewMode: boolean;
  isEditMode: boolean;
}
export default function ReportLayoutCard({ Outlet, visitData, isViewMode, isEditMode }: Props) {
  const { t } = useTranslation();
  const currentVisit = useRecoilValue(visitAtom);
  const { visitId } = useParams();

  const failedPreviousSubmissionData = SLSCache.get<IReport>(visitId || 'empty');

  const isTherePreviousSubmitAttempt = failedPreviousSubmissionData && !isViewMode && !isEditMode;

  const isUseEditModeView = isEditMode || isViewMode || visitData.isDraft;

  useEffect(() => {
    if (failedPreviousSubmissionData && !isViewMode && !isEditMode) {
      toast.info(t('global.notifications.info.reportsPreviousData'));
    }
  }, []);

  return (
    <StyledCard
      sx={{
        background:
          !!currentVisit && typeof currentVisit.isDraft === 'boolean' && currentVisit.isDraft
            ? '#b5ddf45c'
            : '#FCFCFC',
      }}
    >
      <Box sx={{ width: '100%' }}>
        <Typography
          variant="body2"
          sx={{
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            color: 'text.primary',
            textOverflow: 'ellipsis',
          }}
          align="right"
        >
          {`${t('visit.table.dueDate')} : ${getDateFormatted(visitData.dueDate)}`}
        </Typography>
      </Box>
      {isTherePreviousSubmitAttempt ? (
        <Outlet context={failedPreviousSubmissionData} />
      ) : isUseEditModeView ? (
        <EditReportLayout
          Outlet={Outlet}
          failedPreviousSubmissionData={failedPreviousSubmissionData}
          isViewMode={isViewMode}
        />
      ) : (
        <AddReportLayout visitId={visitId} Outlet={Outlet} isDraft={visitData.isDraft} />
      )}
    </StyledCard>
  );
}
