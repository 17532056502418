import React from 'react';
import { IUserFlat } from '../../../interfaces/Models';
import { Stack } from '@mui/material';
import { userAtom } from '../../../atoms/userAtom';
import { useRecoilValue } from 'recoil';
import { checkIfUserIsAdmin } from '../../../utils/Helper';
import ErrorMessage from '../../../components/ErrorMessage';
import { useTranslation } from 'react-i18next';
import FormikAutocomplete from '../../../components/Form/FormikAutocomplete';
import FormikDatePickerField from '../../../components/Form/FormikDatePickerField';

interface Props {
  employees: IUserFlat[];
  isLoadingEmployees: boolean;
  error: boolean;
  errorMessage?: string;
}
export default function PhoneVisitFormFields({
  employees,
  isLoadingEmployees,
  error,
  errorMessage,
}: Props) {
  const { t } = useTranslation();
  const user = useRecoilValue(userAtom);
  const isUserAdmin = checkIfUserIsAdmin(user);

  const getUserLabel = (user: IUserFlat): string => user.fullName;

  if (error) {
    return <ErrorMessage error={errorMessage || ''} />;
  }
  return (
    <Stack width={'100%'} gap={2} sx={{ pt: 2 }}>
      {isUserAdmin && (
        <FormikAutocomplete
          options={employees}
          getOptionLabel={getUserLabel}
          isLoading={isLoadingEmployees}
          name={'employee'}
          label={t('global.roles.EMPLOYEE')}
        />
      )}
      <FormikDatePickerField label={t('user.form.endDate')} name="dueDate" />
    </Stack>
  );
}
