export const HMAValues = {
  vaccinations: '',
  essentialSigns: {
    bloodPressure: '',
    pulse: '',
    temperature: '',
    bloodPressureStanding: '',
    pulseStanding: '',
    sturgeon: '',
    sturgeonNote: '',
  },
  PainAssessment: {
    painDegrees: '',
    painNotes: '',
  },
  falls: {
    fallInYear: '',
    numberOfFalls: '',
    attention: '',
    remarks: '',
    dizziness: '',
    afraidOfFalls: '',
    estimate: '',
  },
  nursingIntervention: '',
  treatment: '',
  functionalAssessment: {
    IADL: {
      household: 0,
      cooking: 0,
      travel: 0,
      takingMedications: 0,
      financeManagement: 0,
      shopping: 0,
      phones: 0,
    },
    IADLNote: '',
    ADL: {
      eatingDrinking: 0,
      bathing: 0,
      clothing: 0,
      personalGarden: 0,
      urineControl: 0,
      stoolControl: 0,
      transitions: 0,
      mobility: 0,
    },
    ADLNote: '',
    IADLSum: 0,
    ADLSum: 0,
  },
  swallowing: {
    swallowLevel: '',
    swallowLevelNotes: '',
    afterSwallow: '',
    afterSwallowNotes: '',
    ingestion: '',
    ingestionNotes: '',
    chewSwallow: '',
    chewSwallowNotes: '',
    voiceSwallow: '',
    voiceSwallowNotes: '',
    airSwallow: '',
    airSwallowNotes: '',
    difficulties: '',
    difficultiesNotes: '',
    textureTest: '',
    textureTestNotes: '',
  },
  treatmentPlan: {
    pain: '',
    painNote: '',
    tiredness: '',
    tirednessNote: '',
    nausea: '',
    nauseaNote: '',
    depression: '',
    depressionNote: '',
    anxiety: '',
    anxietyNote: '',
    neighborhoods: '',
    neighborhoodsNote: '',
    appetite: '',
    appetiteNote: '',
    eneralFeeling: '',
    eneralFeelingNote: '',
    breath: '',
    breathNote: '',
  },
  testFeatures: {
    tests: {
      time: 0,
      place: 0,
      objects: 0,
      attention: 0,
      memory: 0,
      sentence: 0,
      instructions: 0,
      writtenInstructions: 0,
      writing: 0,
      drawing: 0,
    },
    testsSummary: 0,
    testsNotes: 0,
  },
  nortonEstimate: {
    physicalCondition: 0,
    consciousness: 0,
    mobility: 0,
    operations: 0,
    controlingOfExcretion: 0,
  },
  nortonEstimateSummary: 0,
  nortonEstimateRemarks: '',
  woundEstimate: {},
  woundEstimation: [],
  PHQ9: {
    lastVisitDate: null,
    followUpMeetingDate: null,
    problemsSum: 0,
  },
  nutritionalEstimate: {
    nutritionalEstimateSummery: 0,
  },
};
