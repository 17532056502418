/* eslint-disable new-cap */
import { Box, Card, Stack } from '@mui/material';
import React, { useCallback, useMemo, useState } from 'react';
import MissingLeumitRecordsTableHeader, {
  LEUMIT_REPORT_TYPES,
  LeumitReportType,
} from './MissingLeumitRecordsTableHeader';
import { IPatientProgram, IVisit } from '../../../interfaces/Models';
import ReportsService from '../../../services/FinancialReportsService';
import FetcherService from '../../../hooks/FetcherService';
import { IVisitWithProgramTitle } from '../../../layouts/reports/ReportLayout';
import MissingReferenceNumberVisitsTable, {
  sortLeumitVisits,
} from './MissingReferenceNumberVisitsTable';
import MissingProgramsTable, { sortLeumitPrograms } from './MissingProgramsTable';
import useSearchFilter from '../../../hooks/useSearchFilter';
import { URL_QUERY_PARAMS } from '../../../common/Constants';
import MissingLeumitRecordsReportHeader from './MissingLeumitRecordsReportHeader';

export default function MissingLeumitRecordsTableContainer() {
  const { pageInfo, deleteSearchQuery } = useSearchFilter();
  const { sort } = pageInfo;

  const [billingDate, setBillingDate] = useState<Date>(new Date());
  const [reportType, setReportType] = useState<LeumitReportType | undefined>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [tableTotalRecords, setTableTotalRecords] = useState<number>(0);
  const [missingPrograms, setMissingPrograms] = useState<IPatientProgram[]>([]);
  const [missingReferenceNumberVisits, setMissingReferenceNumberVisits] = useState<
    IVisitWithProgramTitle[]
  >([]);
  const [lastTableAction, setLastTableAction] = useState<string>('');

  const handleSaveLastActionValue = useCallback((value: string) => setLastTableAction(value), []);

  const handleUpdateProgramsTable = useCallback(
    (val?: IPatientProgram) => {
      if (!val) return;
      const remainingPrograms = missingPrograms.filter((program) => program._id !== val._id);
      setMissingPrograms(remainingPrograms);
      setTableTotalRecords(remainingPrograms.length);
    },
    [missingPrograms]
  );

  const handleUpdateMissingReferenceNumberVisitsTable = useCallback(
    (val?: IVisitWithProgramTitle | IVisit) => {
      if (!val) return;
      const remainingVisits = missingReferenceNumberVisits.filter((visit) => visit._id !== val._id);
      setMissingReferenceNumberVisits(remainingVisits);
      setTableTotalRecords(remainingVisits.length);
    },
    [missingReferenceNumberVisits]
  );

  const handleReportTypeChange = useCallback(
    async (newLeumitReportType: string, billingDate: Date) => {
      const oldReportType = reportType;
      let result: IPatientProgram[] | IVisitWithProgramTitle[] | undefined;
      setReportType(newLeumitReportType);
      const isMissingVisits =
        newLeumitReportType === LEUMIT_REPORT_TYPES.MISSING_VISITS_REFERENCE_NUMBER;
      const isMissingNumberPrograms =
        newLeumitReportType === LEUMIT_REPORT_TYPES.MISSING_PROGRAMS_REFERENCE_NUMBER;
      const isProgramsMissingPaymentMethods =
        newLeumitReportType === LEUMIT_REPORT_TYPES.MISSING_PAYMENT_METHOD;

      if (isProgramsMissingPaymentMethods) {
        result = await FetcherService<IPatientProgram[]>({
          service: ReportsService.getLeumitVisitsMissingPaymentMode({
            billingDate,
          }),
          setLoading: setIsLoading,
          showNotification: false,
        });
        setMissingPrograms(result);
      }

      if (isMissingVisits) {
        result = await FetcherService<IVisitWithProgramTitle[]>({
          service: ReportsService.getLeumitVisitsMissingReferenceNumber({
            billingDate,
          }),
          setLoading: setIsLoading,
          showNotification: false,
        });
        setMissingReferenceNumberVisits(result);
      }

      if (isMissingNumberPrograms) {
        result = await FetcherService<IPatientProgram[]>({
          service: ReportsService.getLeumitProgramsMissingReferenceNumber({
            billingDate,
          }),
          setLoading: setIsLoading,
          showNotification: false,
        });
        setMissingPrograms(result);
      }
      setTableTotalRecords(result?.length || 0);
      if (oldReportType !== newLeumitReportType) {
        deleteSearchQuery([URL_QUERY_PARAMS.SORT]);
      }
      setLastTableAction('');
    },
    [billingDate]
  );

  const handleReportBillingDate = useCallback(
    async (value: Date) => {
      setBillingDate(value);
      if (reportType) {
        await handleReportTypeChange(reportType, value);
      }
    },
    [reportType]
  );

  const isShowMissingReferenceNumberVisitsTable = useMemo(
    () => reportType === LEUMIT_REPORT_TYPES.MISSING_VISITS_REFERENCE_NUMBER,
    [reportType]
  );

  const sortedPrograms = sortLeumitPrograms({ programs: missingPrograms, sort });
  const sortedVisits = sortLeumitVisits({ visits: missingReferenceNumberVisits, sort });

  return (
    <Card sx={{ p: 3, mt: 3 }}>
      <MissingLeumitRecordsReportHeader
        visits={missingReferenceNumberVisits}
        programs={missingPrograms}
        reportType={reportType}
      />
      <Stack sx={{ width: '100%', mt: 2 }} alignItems={'center'}>
        <MissingLeumitRecordsTableHeader
          reportType={reportType}
          billingDate={billingDate}
          isLoading={isLoading}
          handleReportTypeChange={handleReportTypeChange}
          handleReportBillingDate={handleReportBillingDate}
          totalRecords={tableTotalRecords}
          lastTableAction={lastTableAction}
        />
        <Box sx={{ mt: 2, width: '100%' }}>
          {!isShowMissingReferenceNumberVisitsTable && (
            <MissingProgramsTable
              billingDate={billingDate}
              handleUpdateProgramsTable={handleUpdateProgramsTable}
              isLoading={isLoading}
              programs={sortedPrograms as IPatientProgram[]}
              reportType={reportType}
              sort={sort}
              handleSaveLastActionValue={handleSaveLastActionValue}
            />
          )}
          {isShowMissingReferenceNumberVisitsTable && (
            <MissingReferenceNumberVisitsTable
              sort={sort}
              isLoading={isLoading}
              visits={sortedVisits as IVisitWithProgramTitle[]}
              handleUpdateVisitsTable={handleUpdateMissingReferenceNumberVisitsTable}
              handleSaveLastActionValue={handleSaveLastActionValue}
            />
          )}
        </Box>
      </Stack>
    </Card>
  );
}
