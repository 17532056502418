import { Theme } from '@mui/material/styles';

export default function DatePicker(theme: Theme) {
  return {
    MuiCalendarPicker: {
      styleOverrides: {
        root: {},
      },
    },
  };
}
