import React from 'react';
import { IAttachment } from '../interfaces/attachment';
import { Box, Stack, Typography } from '@mui/material';
import { fileThumb, getFileType } from '../utils/cloudinary';

export default function AttachmentNameView({ attachment }: { attachment: IAttachment }) {
  const { fileName, publicId, format } = attachment;
  const fileFormat = getFileType({ public_id: publicId, format });
  return (
    <Stack direction="row" alignItems="center" spacing={2}>
      <Box
        component="img"
        src={fileThumb(fileFormat)}
        sx={{
          width: 32,
          height: 32,
          flexShrink: 0,
        }}
      />
      <Typography noWrap variant="inherit" sx={{ maxWidth: 360, cursor: 'pointer' }}>
        {fileName}
      </Typography>
    </Stack>
  );
}
