import { Theme } from '@mui/material/styles';

export default function StepLabel(theme: Theme) {
  return {
    MuiStepLabel: {
      styleOverrides: {
        label: {
          fontSize: theme.spacing(1.85),
        },
      },
    },
  };
}
