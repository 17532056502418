import { IQuery, IService } from '../interfaces/index';
import { HTTP_METHODS } from '../common/APIHelper';
import { IVisit } from '../interfaces/Models';

const BASE_PATH = '/api/visit';

const getAllByPatientIdOne = (id: string): IService => {
  return { route: `${BASE_PATH}/patient/${id}`, method: HTTP_METHODS.GET };
};

const add = (payload: IVisit): IService => {
  return { route: BASE_PATH, method: HTTP_METHODS.POST, payload };
};

const edit = (id: string, payload: IVisit | Object): IService => {
  return { route: `${BASE_PATH}/${id}`, method: HTTP_METHODS.PUT, payload };
};

const getOne = (id: string): IService => ({
  route: `${BASE_PATH}/${id}`,
  method: HTTP_METHODS.GET,
});

const getAll = (query?: IQuery): IService => {
  return { route: '/api/visit', method: HTTP_METHODS.GET, query };
};

const getMyVisits = (query?: IQuery): IService => {
  return { route: '/api/visit/my', method: HTTP_METHODS.GET, query };
};

const updateMany = (ids: string[], payload: IVisit | Object): IService => {
  return { route: `${BASE_PATH}/updateMany`, method: HTTP_METHODS.PUT, payload: { ids, payload } };
};

const swapEmployee = (id: string, { newEmployeeId }: { newEmployeeId: string }): IService => {
  return {
    route: `${BASE_PATH}/${id}/change-employee`,
    method: HTTP_METHODS.PUT,
    payload: { newEmployeeId },
  };
};

const VisitService = {
  getAllByPatientIdOne,
  add,
  edit,
  getOne,
  getAll,
  getMyVisits,
  updateMany,
  swapEmployee,
};

export default VisitService;
