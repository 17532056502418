import * as React from 'react';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import { useTranslation } from 'react-i18next';
import ActionTooltip from './ActionTooltip';

interface TablePaginationActionsProps {
  count: number;
  page: number;
  rowsPerPage: number;
  onPageChange: (event: React.MouseEvent<HTMLButtonElement>, newPage: number) => void;
}
const TablePaginationActions = ({
  count,
  page,
  rowsPerPage,
  onPageChange,
}: TablePaginationActionsProps) => {
  const { t } = useTranslation();

  const handleBackButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    onPageChange(event, page + 1);
  };

  const isNextButtonDisabled = page >= Math.ceil(count / rowsPerPage) - 1;
  const isPerviousButtonDisabled = page === 0;

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <ActionTooltip title={`${t('global.table.nextPage')}`} disabled={isNextButtonDisabled}>
        <IconButton
          color="inherit"
          onClick={handleNextButtonClick}
          disabled={isNextButtonDisabled}
          aria-label="next page"
        >
          <KeyboardArrowLeft />
        </IconButton>
      </ActionTooltip>
      <ActionTooltip
        title={`${t('global.table.previousPage')}`}
        disabled={isPerviousButtonDisabled}
      >
        <IconButton
          color="inherit"
          onClick={handleBackButtonClick}
          disabled={isPerviousButtonDisabled}
          aria-label="previous page"
        >
          <KeyboardArrowRight />
        </IconButton>
      </ActionTooltip>
    </Box>
  );
};

export default TablePaginationActions;
