import React, { useEffect } from 'react';
import { FastField as Field, useFormikContext, useField, getIn } from 'formik';
import { Grid } from '@mui/material';
import { StyledTextField } from '../../../styles/FormFieldsStyles';
import { useTranslation } from 'react-i18next';

export default function EssentialSignsBMIFields({ id }: { id: string }) {
  const { t } = useTranslation();
  const { values, setFieldValue } = useFormikContext();
  const weightValue = getIn(values, 'data.essentialSigns.weight');
  const heightValue = getIn(values, 'data.essentialSigns.height');
  const bmiValue = getIn(values, 'data.essentialSigns.bmi');

  const htmlId = `${id}_essential_signs`;

  useEffect(() => {
    if (weightValue && heightValue) {
      const bmi = (Number(weightValue) / (Number(heightValue) * Number(heightValue))).toFixed(2);
      setFieldValue('data.essentialSigns.bmi', bmi);
    } else {
      setFieldValue('data.essentialSigns.bmi', '');
    }
  }, [weightValue, heightValue]);

  const onChangeWeight = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    const value = e.target.value;
    if (Number(value) > 0) {
      setFieldValue('data.essentialSigns.weight', e.target.value);
    }
  };

  const onChangeHeight = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    const value = e.target.value;
    if (Number(value) > 0) {
      setFieldValue('data.essentialSigns.height', e.target.value);
    }
  };

  return (
    <Grid container id={`${htmlId}_bmi_fields`} spacing={{ xs: 0, md: 2, lg: 2 }}>
      <Grid item xs={12} md={4} lg={4}>
        <Field
          id={`${htmlId}_weight`}
          fullWidth
          component={StyledTextField}
          type="number"
          onChange={onChangeWeight}
          name="data.essentialSigns.weight"
          label={t('report.HMDFIRST.healthIndices.anthropometry.weight')}
        />
      </Grid>
      <Grid item xs={12} md={4} lg={4}>
        <Field
          id={`${htmlId}_height`}
          fullWidth
          component={StyledTextField}
          onChange={onChangeHeight}
          type="number"
          name="data.essentialSigns.height"
          label={t('report.HMDFIRST.healthIndices.anthropometry.height')}
        />
      </Grid>
      <Grid item xs={12} md={4} lg={4}>
        <Field
          id={`${htmlId}_bmi`}
          fullWidth
          component={StyledTextField}
          type="text"
          disabled
          value={`${bmiValue || ''} BMI`}
          name="data.essentialSigns.bmi"
        />
      </Grid>
    </Grid>
  );
}
