import { Grid, MenuItem, SxProps, Typography } from '@mui/material';
import { FastField as Field } from 'formik';
import { TextField } from 'formik-mui';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { theme } from '../../../theme';
import { styled } from '@mui/system';
import FormikSelect from '../../../components/FormikSelect';

const sxFormControl: SxProps = {
  width: '100%',
  mb: 2,
};

const StyledGrid = styled(Grid)({
  marginBottom: theme.spacing(2),
});

interface IExam {
  label: string;
  value: string;
}

interface PhysicalExaminationFieldsProps {
  header: string;
  Examinations: IExam[];
  id: string;
}

const MultipleSelectFields = ({ header, Examinations, id }: PhysicalExaminationFieldsProps) => {
  const { t } = useTranslation();

  return (
    <div id={id}>
      <Typography variant="subtitle1" gutterBottom align="left">
        {header}
      </Typography>
      {Examinations.map((test: IExam, idx: number) => {
        return (
          <StyledGrid
            container
            spacing={{ xs: 0, md: 4, lg: 4 }}
            key={test.label}
            id={`${id}_Conatiner_${idx}`}
          >
            <Grid item xs={12} md={6} lg={6}>
              <FormikSelect
                id={id + '_SelectField_' + test.value}
                name={test.value}
                formControl={sxFormControl}
                label={test.label}
              >
                <MenuItem value="yes">{t('global.form.select.yes')}</MenuItem>
                <MenuItem value="no">{t('global.form.select.no')}</MenuItem>
              </FormikSelect>
            </Grid>
            <Grid item xs={12} md={6} lg={6}>
              <Field
                id={id + '_TextInput_' + test.value}
                fullWidth
                component={TextField}
                type="text"
                name={`${test.value}Notes`}
                label={t('report.HMKFIRST.remarks')}
              />
            </Grid>
          </StyledGrid>
        );
      })}
    </div>
  );
};

export default MultipleSelectFields;
