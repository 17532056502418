import { Tooltip } from '@mui/material';
import React, { useEffect, useState } from 'react';
interface IActionTooltip {
  title: string;
  disabled?: boolean;
  children: React.ReactElement;
}
const ActionTooltip: React.FC<IActionTooltip> = ({ title, disabled, children }) => {
  const [open, setOpen] = useState(false);
  useEffect(() => {
    if (disabled && open) {
      setOpen(false);
    }
  }, [disabled, open]);

  return (
    <>
      <Tooltip
        title={title}
        arrow
        onOpen={() => {
          if (!disabled) {
            setOpen(true);
          }
        }}
        onClose={() => setOpen(false)}
        open={open}
      >
        {children}
      </Tooltip>
    </>
  );
};
export default ActionTooltip;
