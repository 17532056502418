/* eslint-disable new-cap */
import React, { useMemo } from 'react';
import { styled } from '@mui/system';
import { Box, Grid, Typography } from '@mui/material';
import { theme } from '../../theme';
import ErrorMessage from '../../components/ErrorMessage';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { IPatient } from '../../interfaces/Models';
import PatientService from '../../services/PatientService';
import useService from '../../hooks/HookService';
import Form from '../../components/Form/FormikForm';
import FetcherService from '../../hooks/FetcherService';
import * as Yup from 'yup';
import { isValidIdNumber } from '../../utils/Helper';
import PatientFormFields from './PatientFormFields';
import useAccess from '../../hooks/useAccess';
import { AbilitySubjects } from '../../casl/ability';
import OverlaySpinner from '../../components/OverlaySpinner';

const StyledBox = styled(Box, { name: 'StyledBox', slot: 'Wrapper' })({
  flexGrow: 1,
  paddingTop: theme.spacing(7),
});

const StyledTypography = styled(Typography)({
  textAlign: 'center',
  marginBottom: theme.spacing(3),
});

const PatientForm: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { patientId } = useParams();
  useAccess('create', AbilitySubjects.ADMIN_ABILITY);

  const { isLoading, data, error, errorMessage } = useService<IPatient>({
    service: PatientService.getOne(patientId || ''),
    deps: [],
  });

  const initialValues = useMemo(
    () =>
      patientId && data
        ? { ...data, isPassport: data.isPassport ? 'yes' : 'no' }
        : {
            idNumber: '',
            isPassport: 'no',
            personalDetails: {
              firstName: '',
              lastName: '',
              birthDate: null,
              primaryPhone: '',
              secondaryPhone: '',
              martialStatus: '',
              address: '',
              gender: '',
            },
            primaryContact: {
              firstName: '',
              lastName: '',
              birthDate: null,
              primaryPhone: '',
              secondaryPhone: '',
              martialStatus: '',
              address: '',
              gender: '',
            },
            clinic: {},
          },
    [isLoading]
  );

  const PatientSchema = Yup.object({
    idNumber: Yup.string()
      .min(9, t('global.errors.idNumberLength'))
      .test('idNumber', t('patient.form.inValidIdNumber'), function (value) {
        if (this.parent?.isPassport == 'yes' && value && value.length === 9) {
          return true;
        } else {
          return isValidIdNumber(value || '');
        }
      })
      .required(t('clinic.form.required')),
    isPassport: Yup.string().required(t('clinic.form.required')),
    personalDetails: Yup.object().shape({
      firstName: Yup.string().required(t('clinic.form.required')),
      lastName: Yup.string().required(t('clinic.form.required')),
      primaryPhone: Yup.string().required(t('clinic.form.required')),
    }),
    clinic: Yup.object()
      .shape({
        _id: Yup.string().required(t('clinic.form.required')),
      })
      .required(t('clinic.form.required')),
  });
  const formHeader = useMemo(
    () =>
      patientId
        ? `${t('patient.form.editPatient')}
          ${data?.personalDetails.firstName}
          ${data?.personalDetails.lastName}`
        : t('patient.form.addPatient'),
    [isLoading]
  );
  const handleAddEditClinic = async (record: IPatient): Promise<void> => {
    const isPassportValue =
      typeof record.isPassport == 'string' ? record.isPassport == 'yes' : record.isPassport;
    if (record._id) {
      await FetcherService<IPatient>({
        service: PatientService.edit(record._id as string, {
          ...record,
          idNumber: record.idNumber.toString(),
          clinic: record.clinic._id,
          isPassport: isPassportValue,
        }),
      });
    } else {
      await FetcherService<IPatient>({
        service: PatientService.add({
          ...record,
          idNumber: record.idNumber.toString(),
          clinic: record.clinic._id,
          isPassport: isPassportValue,
        }),
      });
    }

    navigate('/app/patient');
  };

  const handleCancel = () => {
    navigate(-1);
  };

  if (isLoading) {
    return <OverlaySpinner />;
  }
  if (error || !data) {
    return <ErrorMessage error={errorMessage} />;
  }
  return (
    <StyledBox>
      <StyledTypography variant="h3">{formHeader}</StyledTypography>

      <Grid container justifyContent="center">
        <Grid item xs={10} md={8} lg={6}>
          <Form
            onCancel={handleCancel}
            submit={handleAddEditClinic}
            initialValues={initialValues}
            validationSchema={PatientSchema}
            cancelText={t('user.form.goBack')}
            submitText={patientId ? t('clinic.form.edit') : t('clinic.form.add')}
          >
            <PatientFormFields />
          </Form>
        </Grid>
      </Grid>
    </StyledBox>
  );
};

export default PatientForm;
