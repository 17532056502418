import { Stack } from '@mui/material';
import React from 'react';
import { IUserFlat } from '../../../interfaces/Models';
import ErrorMessage from '../../../components/ErrorMessage';
import FormikAutocomplete from '../../../components/Form/FormikAutocomplete';
import { useTranslation } from 'react-i18next';
import { checkIfUserIsAdmin } from '../../../utils/Helper';
import { userAtom } from '../../../atoms/userAtom';
import { useRecoilValue } from 'recoil';
import { StyledDivider } from '../../../styles/FormFieldsStyles';
import PrescriptionListField from './PrescriptionListField';
import { getIn, useFormikContext } from 'formik';

interface Props {
  doctors: IUserFlat[];
  isLoadingDoctors: boolean;
  error: boolean;
  errorMessage: string;
}
export default function PrescriptionFormFields({
  doctors,
  isLoadingDoctors,
  error,
  errorMessage,
}: Props) {
  const { t } = useTranslation();
  const user = useRecoilValue(userAtom);
  const { values }: any = useFormikContext();
  const medicines = getIn(values, 'medicines');

  if (error) {
    return <ErrorMessage error={errorMessage} />;
  }
  const getUserLabel = (user: IUserFlat): string => user.fullName;

  const isUserAdmin = checkIfUserIsAdmin(user);

  return (
    <Stack width={'100%'} gap={2} sx={{ pt: 2 }}>
      {isUserAdmin && (
        <FormikAutocomplete
          options={doctors}
          getOptionLabel={getUserLabel}
          isLoading={isLoadingDoctors}
          name={'doctor'}
          label={t('global.positions.HMB')}
        />
      )}
      <PrescriptionListField value={medicines} id={'Prescription'} fieldName={'medicines'} />
      <StyledDivider />
    </Stack>
  );
}
