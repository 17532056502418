/* eslint-disable new-cap */
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { theme } from '../../../theme';
import download from '@iconify/icons-eva/download-outline';
import DeleteIcon from '@mui/icons-material/Delete';
import MoreOptions from '../../MoreOptions';
import FileView from '../../FileView';
import Table from '../../Table';
import { IAttachment } from '../../../interfaces/attachment';
import { Icon } from '@iconify/react';
import { Box } from '@mui/material';
import DialogActions from '../../DialogActions';
import { downloadCloudinaryAttachment, getFileType } from '../../../utils/cloudinary';
import isReportMode from '../../../hooks/isReportMode';
import editFill from '@iconify/icons-eva/edit-fill';
import EditAttachmentNameModal from '../../EditAttachmentNameModal';
import { visitAttachmentsAtom } from '../../../atoms/visitAttachmentsAtom';
import { useSetRecoilState } from 'recoil';
import attachmentService from '../../../services/attachemntService';
import FetcherService from '../../../hooks/FetcherService';
import AttachmentNameView from '../../AttachmentNameView';

export default function ReportAttachmentsTable({
  attachments,
  handleDeleteAttachment,
}: {
  handleDeleteAttachment: (val: IAttachment) => void;
  attachments: IAttachment[];
}) {
  const { t } = useTranslation();
  const setVisitAttachments = useSetRecoilState(visitAttachmentsAtom);
  const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false);
  const [record, setRecord] = useState<IAttachment | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [openChangeFileName, setOpenChangeFileName] = useState<boolean>(false);
  const [isLoadingNewFileName, setIsLoadingNewFileName] = useState<boolean>(false);

  const isViewMode = isReportMode('view');

  const onOpenDeleteModal = (value: IAttachment) => {
    setRecord(value);
    setOpenDeleteModal(true);
  };

  const onOpenChangeFileNameModal = (value: IAttachment) => {
    setRecord(value);
    setOpenChangeFileName(true);
  };

  const handleModalClose = () => {
    setOpenDeleteModal(false);
    setOpenChangeFileName(false);
    setRecord(null);
  };

  const deleteAttachmentHandler = async () => {
    setLoading(true);
    if (record) {
      await handleDeleteAttachment(record);
    }
    setLoading(false);
    handleModalClose();
  };

  const handleUpdateReportAttachment = async (data: IAttachment) => {
    const newAttachments = attachments.map((val: IAttachment) => {
      if (val.publicId === data.publicId) {
        return { ...val, ...data };
      }
      return val;
    });
    await FetcherService<any>({
      service: attachmentService.updateByRecordId(String(data?._id), {
        ...data,
        fileName: data.fileName,
      }),
      setLoading: setIsLoadingNewFileName,
    });
    setVisitAttachments(newAttachments);
    handleModalClose();
  };

  const columns = [
    {
      Header: `${t('global.attachments.fileName')}`,
      accessor: (val: any) => <AttachmentNameView attachment={val} />,
    },
    {
      Header: `${t('global.attachments.type')}`,
      accessor: (val: any) => {
        const { publicId, format } = val;
        const fileFomrat = getFileType({ public_id: publicId, format });
        return fileFomrat;
      },
    },
    {
      Header: `${t('global.attachments.view')}`,
      accessor: (val: any) => <FileView {...val} />,
    },
    {
      Header: t('clinic.table.actions'),
      accessor: (val: any) => {
        return (
          <MoreOptions
            actions={[
              {
                name: t('global.reportActions.downloadReport'),
                handler: () => downloadCloudinaryAttachment(val),
                icon: <Icon icon={download} width={24} height={24} color="#3E94A8" />,
              },
              {
                name: t('global.attachments.editName'),
                handler: onOpenChangeFileNameModal,
                icon: (
                  <Icon
                    icon={editFill}
                    width={24}
                    height={24}
                    color={theme.palette.secondary.main}
                  />
                ),
              },
              {
                name: t('global.moreMenu.delete'),
                handler: onOpenDeleteModal,
                icon: <DeleteIcon width={24} height={24} color="error" />,
                variant: theme.palette.error.main,
              },
            ]}
            record={val}
          />
        );
      },
    },
  ];
  return (
    <>
      {record && (
        <EditAttachmentNameModal
          open={openChangeFileName}
          handleCloseModal={handleModalClose}
          record={record}
          handleSubmitForm={handleUpdateReportAttachment}
          isLoading={isLoadingNewFileName}
        />
      )}
      <DialogActions
        onClose={handleModalClose}
        open={openDeleteModal}
        dialog={`${t('global.attachments.deleteAttachment')}`}
        action={deleteAttachmentHandler}
        loading={loading}
      />
      <Box>
        <Table
          isLoading={false}
          columns={columns}
          data={attachments}
          count={attachments.length}
          pageSizes={[10]}
          showFilters={false}
          showPagination={false}
        />
      </Box>
    </>
  );
}
