import React from 'react';
import { styled } from '@mui/system';
import { theme } from '../theme';
import { Grid, Divider } from '@mui/material';

const StyledGrid = styled(Grid)({
  marginTop: theme.spacing(2),
  marginBottom: theme.spacing(6),
});

const StyledDivider = styled(Divider)({
  borderBottomWidth: 'medium',
  color: '#E5E8EB',
  padding: 5,
});

const SectionDivider = () => {
  return (
    <StyledGrid container justifyContent="center">
      <Grid item xs={6}>
        <StyledDivider variant="middle" />
      </Grid>
    </StyledGrid>
  );
};

export default SectionDivider;
