/* eslint-disable new-cap */
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useParams, useOutletContext } from 'react-router-dom';
import * as Yup from 'yup';
import { ReportData } from '../../../interfaces/Models';
import AssessmentFields from './fields/AssessmentFields';
import {
  assessmentValues,
  getAssessmentSchema,
  assessmentFields,
} from '../../../common/assessment';
import ReportsForm from '../../../components/Form/ReportsForm';

interface IHMPTableProps {
  reportName?: string;
}
const HMPTable = ({ reportName }: IHMPTableProps) => {
  const { t } = useTranslation();
  const { visitId } = useParams();
  const data: ReportData = useOutletContext();

  if (data?.name && data.data.assessment) {
    assessmentFields.map((field: string, idx: number) => {
      data.data[`${field}Note`] = data.data.assessmentsNotes[idx];
    });
  }
  const initialValues =
    data && data.name && data.data.assessment
      ? data
      : {
          name: reportName ? reportName : 'empty',
          visit: visitId,
          data: data && data.assessment ? data : { assessment: assessmentValues },
        };

  const ReportSchema = Yup.object({ ...getAssessmentSchema(t) });

  const HtmlId = reportName ? reportName : data?.name || '';

  return (
    <ReportsForm
      initialValues={initialValues}
      visitId={visitId || ''}
      validationSchema={ReportSchema}
      hmpBetiReport={true}
    >
      <AssessmentFields id={HtmlId} />
    </ReportsForm>
  );
};

export default HMPTable;
