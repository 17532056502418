/* eslint-disable new-cap */
import { Box, Stack } from '@mui/material';
import { useFormik, FormikProvider } from 'formik';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { ApiResponse } from '../../../interfaces/ApiResponseType';
import { toast } from 'react-toastify';
import FetcherService from '../../../hooks/FetcherService';
import ReportsService from '../../../services/FinancialReportsService';
import { getLeumitReportFileName, generateTextFile } from '../../../utils/Helper';
import LeumitClinicReportFormFields from './LeumitClinicReportFormFields';
import LeumitClinicReportSummery from './LeumitClinicReportSummery';

export interface ILeumitReportDataSummery {
  totalProgramRows: number;
  totalVisitsRows: number;
  totalAmount: number;
  totalPatients: number;
}
interface ILeumitReportData extends ILeumitReportDataSummery {
  resultRows: string[];
}

interface IFormValues {
  invoiceNumber: string;
  billingDate: Date;
}
export default function LeumitClinicReportForm() {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [reportSummery, setReportSummery] = useState<ILeumitReportDataSummery | null>(null);

  const schema = Yup.object({
    invoiceNumber: Yup.number()
      .min(1)
      .required(`${t('clinic.form.required')}`),
    billingDate: Yup.date().required(`${t('clinic.form.required')}`),
  });

  const handleChangeReportSummery = (summery: ILeumitReportDataSummery | null) =>
    setReportSummery(summery);

  const onDownloadReport = async (values: IFormValues) => {
    const fileName = getLeumitReportFileName(values.billingDate);
    const onSuccess = (res: ApiResponse<ILeumitReportData>) => {
      const { resultRows, totalAmount, totalProgramRows, totalVisitsRows, totalPatients } =
        res.data.data;

      generateTextFile(resultRows, fileName);

      handleChangeReportSummery({
        totalProgramRows,
        totalVisitsRows,
        totalAmount,
        totalPatients,
      });
    };
    const onFailed = () => {
      toast.error(t('global.errors.report'));
    };

    const billingMonthDate = new Date(values.billingDate);
    const secondDayOfMonth = new Date(
      billingMonthDate.getFullYear(),
      billingMonthDate.getMonth(),
      2
    );
    await FetcherService<ILeumitReportData>({
      service: ReportsService.getLeumitFinancialReports({
        ...values,
        billingDate: secondDayOfMonth,
      }),
      onSuccess: onSuccess,
      onFailed: onFailed,
      setLoading: setIsLoading,
    });
  };

  const formik = useFormik({
    initialValues: { invoiceNumber: '', billingDate: new Date() } as any,
    validationSchema: schema,
    onSubmit: async (values: any, { setSubmitting }) => {
      await onDownloadReport(values);
      setSubmitting(false);
    },
  });

  return (
    <>
      <Box>
        <FormikProvider value={formik}>
          <form onSubmit={formik.handleSubmit}>
            <Stack direction={'column'} sx={{ mt: 2, pb: 2 }}>
              <LeumitClinicReportFormFields
                handleChangeReportSummery={handleChangeReportSummery}
                isLoading={isLoading}
              />
              {reportSummery && <LeumitClinicReportSummery summery={reportSummery} />}
            </Stack>
          </form>
        </FormikProvider>
      </Box>
    </>
  );
}
