/* eslint-disable new-cap */
import { useTranslation } from 'react-i18next';
import { useEffect, useMemo, useState } from 'react';
import { IPatient, IUserFlat, ReportData } from '../../../interfaces/Models';
import * as Yup from 'yup';
import FormModal from '../../../components/Modals/FormModal';
import Form from '../../../components/Form/FormikForm';
import prescriptionService from '../../../services/prescriptionService';
import FetcherService from '../../../hooks/FetcherService';
import PrescriptionFormFields from './PrescriptionFormFields';
import { userAtom } from '../../../atoms/userAtom';
import { useRecoilValue } from 'recoil';
import { EMPLOYEE_POSITIONS } from '../../../common/Constants';
import axios from 'axios';
import { checkIfUserIsAdmin } from '../../../utils/Helper';
import { IPatientPrescription } from '../../../interfaces/prescription';

interface Props {
  open: boolean;
  handleTableDataUpdate: () => void;
  handleCloseModal: () => void;
  patient: IPatient;
}
const PrescriptionForm = ({ open, handleCloseModal, handleTableDataUpdate, patient }: Props) => {
  const { t } = useTranslation();
  const user = useRecoilValue(userAtom);
  const [isLoadingForm, setIsLoading] = useState<boolean>(false);

  const [doctor, setDoctors] = useState<IUserFlat[]>([]);
  const [isDoctorsDataFetched, setIsDoctorsDataFetched] = useState<boolean>(false);
  const [isLoadingDoctors, setIsLoadingDoctors] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');

  useEffect(() => {
    const isUserAdmin = checkIfUserIsAdmin(user);
    if (!isDoctorsDataFetched && isUserAdmin && open && !isLoadingDoctors) {
      setIsLoadingDoctors(true);
      axios
        .get(`/api/user`, {
          params: {
            page: 0,
            limit: 1000,
            position: EMPLOYEE_POSITIONS.HMB,
          },
        })
        .then((motors) => {
          setIsDoctorsDataFetched(true);
          setDoctors(motors.data.data);
          setIsLoadingDoctors(false);
        })
        .catch((error) => {
          setErrorMessage(error.message);
          setError(true);
        });
    }
  }, [open]);

  const Schema = Yup.object({
    doctor: Yup.object()
      .shape({
        _id: Yup.string().required(t('clinic.form.required')),
      })
      .required(t('clinic.form.required')),
    medicines: Yup.array()
      .of(
        Yup.object().shape({
          name: Yup.string().required(t('clinic.form.required')),
          givingMethod: Yup.string().required(t('clinic.form.required')),
          quantity: Yup.string().required(t('clinic.form.required')),
          dosageUnit: Yup.string().required(t('clinic.form.required')),
          frequency: Yup.string().required(t('clinic.form.required')),
          frequencyPeriod: Yup.string().required(t('clinic.form.required')),
          MedicationPeriod: Yup.string().required(t('clinic.form.required')),
          MedicationPeriodUnit: Yup.string().required(t('clinic.form.required')),
          givingMethod1: Yup.string().required(t('clinic.form.required')),
          givingMethod1Unit: Yup.string().required(t('clinic.form.required')),
        })
      )
      .min(1)
      .required(t('global.errors.required')),
  });

  const initialValues = useMemo(() => {
    const isUserDoctor = !!user && user.position === EMPLOYEE_POSITIONS.HMB;
    return {
      medications: [],
      doctor: isUserDoctor ? { _id: user?._id } : undefined,
      patient: patient?._id,
    };
  }, [user, isLoadingDoctors]);

  const handleSubmit = async (values: ReportData) => {
    await FetcherService<any>({
      service: prescriptionService.add(values as IPatientPrescription),
      setLoading: setIsLoading,
    });
    handleCloseModal();
    handleTableDataUpdate();
  };

  return (
    <FormModal
      open={open}
      onClose={handleCloseModal}
      text={t('newPrescription')}
      loading={isLoadingForm}
      maxWidth="xl"
      key={user?._id}
    >
      <Form
        onCancel={handleCloseModal}
        submit={handleSubmit}
        initialValues={initialValues}
        validationSchema={Schema}
        cancelText={t('clinic.form.cancel')}
        submitText={t('clinic.form.add')}
        footerButtonFullWidth
      >
        <PrescriptionFormFields
          error={error}
          errorMessage={errorMessage}
          isLoadingDoctors={isLoadingDoctors}
          doctors={doctor}
        />
      </Form>
    </FormModal>
  );
};

export default PrescriptionForm;
