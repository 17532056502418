import React from 'react';
import { Box, Typography } from '@mui/material';

interface ErrorMessageProps {
  error: string;
}
const ErrorMessage: React.FC<ErrorMessageProps> = ({ error }) => {
  return (
    <Box sx={{ display: 'flex', justifyContent: 'center' }}>
      <Typography variant="h4" color="error">
        {error}
      </Typography>
    </Box>
  );
};

export default ErrorMessage;
