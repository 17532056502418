/* eslint-disable new-cap */
import React, { useMemo, useState } from 'react';
import { IPatient } from '../../interfaces/Models';
import useService from '../../hooks/HookService';
import { IPatientPrescriptionPop } from '../../interfaces/prescription';
import prescriptionService from '../../services/prescriptionService';
import { useAbility } from '@casl/react';
import { AbilityContext } from '../../casl/can';
import { AbilitySubjects } from '../../casl/ability';
import { useTranslation } from 'react-i18next';
import { StyledBox } from '../../styles/TabsStyles';
import { Card } from '@mui/material';
import Table from '../../components/Table';
import PrescriptionsTableHeader from './PrescriptionsTableHeader';
import ErrorMessage from '../../components/ErrorMessage';
import MoreOptions from '../../components/MoreOptions';
import { Icon } from '@iconify/react';
import { theme } from '../../theme';
import DeleteIcon from '@mui/icons-material/Delete';
import download from '@iconify/icons-eva/download-outline';
import { getDateFormatted, onDownloadPdf } from '../../utils/Helper';
import FetcherService from '../../hooks/FetcherService';
import { toast } from 'react-toastify';
import DialogActions from '../../components/DialogActions';
import { ApiResponse } from '../../interfaces/ApiResponseType';
import OverlaySpinner from '../../components/OverlaySpinner';

interface Props {
  patient: IPatient;
  isLoading?: boolean;
}
export default function PatientPrescriptionsTable({ patient, isLoading }: Props) {
  const { t } = useTranslation();
  const ability = useAbility(AbilityContext);
  const adminAbility = ability.can('manage', AbilitySubjects.ADMIN_ABILITY);

  const [shouldTableUpdate, setShouldTableUpdate] = useState<number>(0);
  const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false);
  const [record, setRecord] = useState<IPatientPrescriptionPop | null>(null);
  const [isLoadingDeletePrescription, setIsLoadingDeletePrescription] = useState<boolean>(false);
  const [isLoadingPdf, setIsLoadingPdf] = useState<boolean>(false);

  const handleModalClose = () => {
    setOpenDeleteModal(false);
  };

  const {
    isLoading: tableDataLoading,
    data,
    error,
    errorMessage,
  } = useService<IPatientPrescriptionPop[]>({
    service: prescriptionService.getAll({
      patient: patient?._id,
    }),
    deps: [shouldTableUpdate],
  });

  const onOpenDeleteModal = (value: IPatientPrescriptionPop) => {
    setRecord(value);
    setOpenDeleteModal(true);
  };

  const patientName = `${patient.personalDetails.firstName} ${patient.personalDetails.lastName}`;

  const handleDownloadPrescription = async (val: IPatientPrescriptionPop) => {
    const onSuccess = (res: ApiResponse<BlobPart>) => {
      onDownloadPdf(
        res,
        `${patientName}-${getDateFormatted(val.createdAt || new Date())}-מרשם-רופא`
      );
    };
    await FetcherService<any>({
      service: prescriptionService.downloadOneById(val?._id || ''),
      onSuccess: onSuccess,
      errorMessage: t('global.errors.report'),
      setLoading: setIsLoadingPdf,
    });
  };

  const columns = [
    {
      Header: `${t('global.positions.HMB')}`,
      accessor: (val: any) =>
        `${val.doctor.personalDetails.firstName} ${val.doctor.personalDetails.lastName}`,
    },
    {
      Header: `${t('issueDate')}`,
      accessor: (val: any) => getDateFormatted(val.createdAt),
    },
    {
      Header: `${t('expirationDate')}`,
      accessor: (val: any) => getDateFormatted(val.expirationDate),
    },
    {
      Header: t('clinic.table.actions'),
      accessor: (val: any) => {
        return (
          <MoreOptions
            actions={[
              {
                name: t('downloadPrescription'),
                handler: handleDownloadPrescription,
                icon: <Icon icon={download} width={24} height={24} color="#3E94A8" />,
              },
              {
                name: t('global.moreMenu.delete'),
                handler: onOpenDeleteModal,
                icon: <DeleteIcon width={24} height={24} color="error" />,
                variant: theme.palette.error.main,
                isVisible: adminAbility,
              },
            ]}
            record={val}
          />
        );
      },
    },
  ];
  const prescriptions = useMemo(() => data || [], [data]);

  const handleTableDataUpdate = () => setShouldTableUpdate((pre) => pre + 1);

  if (error) return <ErrorMessage error={errorMessage} />;

  const handleDeletePrescription = async () => {
    const onSuccess = () => {
      toast.success(t('deleteSuccess'), {
        rtl: true,
      });
    };
    await FetcherService({
      service: prescriptionService.deleteOneById(record?._id as string),
      errorMessage: t('deleteError'),
      onSuccess,
      setLoading: setIsLoadingDeletePrescription,
    });
    setOpenDeleteModal(false);
    setShouldTableUpdate((prev) => prev + 1);
  };

  return (
    <>
      <DialogActions
        onClose={handleModalClose}
        open={openDeleteModal}
        dialog={`${t('deletePrescriptionConfirmation')}`}
        action={handleDeletePrescription}
        loading={isLoadingDeletePrescription}
      />
      <Card>
        <StyledBox>
          <Table
            columns={columns}
            isLoading={isLoading || tableDataLoading}
            data={prescriptions}
            count={prescriptions.length}
            pageSizes={[10]}
            TableHeaderFilters={
              <PrescriptionsTableHeader
                patient={patient}
                handleTableDataUpdate={handleTableDataUpdate}
              />
            }
            showFilters={true}
            showPagination={true}
          />
        </StyledBox>
      </Card>
      {isLoadingPdf && <OverlaySpinner />}
    </>
  );
}
