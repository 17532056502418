/* eslint-disable new-cap */
import { t } from 'i18next';
import React, { useMemo } from 'react';
import { VISIT_STATUS } from '../../common/Constants';
import DialogActions from '../../components/DialogActions';
import FetcherService from '../../hooks/FetcherService';
import { IVisit } from '../../interfaces/Models';
import VisitService from '../../services/VisitService';
import { handleTableAtomChange } from '../../utils/Helper';
import EditVisitDueDateForm from './EditVisitDueDateForm';
import SwapVisitEmployeeForm from './swapEmployee/SwapVisitEmployeeForm';
import EditVisitReferenceNumberForm from './EditVisitReferenceNumberForm';

interface IVisitsActions {
  handelCloseModal: () => void;
  cancelVisitModal: boolean;
  visitToDoneModal: boolean;
  sentToClinicModal: boolean;
  sentToClinicDownloadModal: boolean;
  handelTableUpdate: () => void;
  record: IVisit | undefined;
  handelLoadingReport: (value: boolean) => void;
  downloadReport: (value?: IVisit) => void;
  openChangeVisitDueDataModal: boolean;
  openSwapVisitEmployeeModal: boolean;
  openChangeClinicReferenceNumber?: boolean;
}
const VisitsActions = ({
  handelCloseModal,
  cancelVisitModal,
  visitToDoneModal,
  sentToClinicModal,
  sentToClinicDownloadModal,
  handelTableUpdate,
  record,
  handelLoadingReport,
  downloadReport,
  openChangeVisitDueDataModal,
  openSwapVisitEmployeeModal,
  openChangeClinicReferenceNumber = false,
}: IVisitsActions) => {
  const handelUpdateVisit = async (update: object) => {
    handleTableAtomChange('visitId', record?._id);
    await FetcherService<any>({
      service: VisitService.edit(record?._id || '', update),
      setLoading: handelLoadingReport,
    });
  };

  const handelCancelVisit = async () => {
    handelCloseModal();
    await handelUpdateVisit({ status: VISIT_STATUS.CANCELED });
    handelTableUpdate();
  };

  const handelChangeVisitStatusToDone = async () => {
    handelCloseModal();
    await handelUpdateVisit({ status: VISIT_STATUS.DONE });
    handelTableUpdate();
  };

  const sentToClinicHandler = async () => {
    handelCloseModal();
    await handelUpdateVisit({ status: VISIT_STATUS.SENT_TO_CLINIC });
    handelTableUpdate();
  };

  const sentToClinicDownloadHandler = async () => {
    handelCloseModal();
    await handelUpdateVisit({ status: VISIT_STATUS.SENT_TO_CLINIC });
    await downloadReport(record);
    handelTableUpdate();
  };

  const getVisitsActionTranslationText = () => {
    if (cancelVisitModal) {
      return 'cancelVisit';
    }
    if (visitToDoneModal) {
      return 'visitStatusToDone';
    }
    return 'sentToClinicVisit';
  };

  const getActionHandler = () => {
    if (cancelVisitModal) {
      return handelCancelVisit;
    }
    if (visitToDoneModal) {
      return handelChangeVisitStatusToDone;
    }
    if (sentToClinicModal) {
      return sentToClinicHandler;
    }
    return sentToClinicDownloadHandler;
  };

  const originalEmployeeId = useMemo(() => {
    if (record?.employee) {
      return typeof record?.employee === 'string' ? record?.employee : record?.employee._id;
    }
    if (record) return record?.employeeId;
  }, [record]);

  return (
    <>
      <EditVisitDueDateForm
        record={record}
        openChangeVisitDueDate={openChangeVisitDueDataModal}
        handleCloseChangeVisitDueDateModal={handelCloseModal}
        handlePageUpdate={handelTableUpdate}
      />
      {record && openSwapVisitEmployeeModal ? (
        <SwapVisitEmployeeForm
          visit={record}
          handleTableUpdate={handelTableUpdate}
          handleCloseModal={handelCloseModal}
          originalEmployeeId={String(originalEmployeeId)}
        />
      ) : null}
      <EditVisitReferenceNumberForm
        record={record}
        openChangeClinicReferenceNumber={openChangeClinicReferenceNumber}
        handleCloseModal={handelCloseModal}
        handleVisitUpdate={handelTableUpdate}
      />
      <DialogActions
        onClose={handelCloseModal}
        open={
          cancelVisitModal || visitToDoneModal || sentToClinicModal || sentToClinicDownloadModal
        }
        dialog={t(`global.actionsConfirmation.${getVisitsActionTranslationText()}`)}
        action={getActionHandler()}
      />
    </>
  );
};

export default VisitsActions;
