import { useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { styled } from '@mui/material/styles';
import { Box, Button, Container, Typography } from '@mui/material';
import LogoOnlyLayout from '../../layouts/LogoOnlyLayout';
import Page from '../../components/Page';
import ForgotPasswordForm from './ForgotPasswordForm';
import SentIcon from '../../assets/icon_sent';

export const RootStyle = styled('div')(({ theme }) => ({
  display: 'flex',
  minHeight: '100%',
  alignItems: 'center',
  justifyContent: 'center',
  padding: theme.spacing(12, 0),
}));

export default function ForgotPassword() {
  const { t } = useTranslation();
  const [email, setEmail] = useState('');
  const [sent, setSent] = useState(false);

  const onSentEmail = () => {
    setSent(true);
  };
  const onGetEmail = (value: string) => {
    setEmail(value);
  };
  return (
    <Page title={t('global.resetPassword.forgotPassword')} sx={{ height: 1 }}>
      <RootStyle>
        <LogoOnlyLayout />

        <Container>
          <Box sx={{ maxWidth: 480, mx: 'auto' }}>
            {!sent ? (
              <>
                <Typography variant="h3" paragraph>
                  {t('global.resetPassword.forgot')}
                </Typography>
                <Typography sx={{ color: 'text.secondary', mb: 5 }}>
                  {t('global.resetPassword.formHeader')}
                </Typography>

                <ForgotPasswordForm onSent={onSentEmail} onGetEmail={onGetEmail} />

                <Button fullWidth size="large" component={RouterLink} to="/login" sx={{ mt: 1 }}>
                  {t('global.resetPassword.back')}
                </Button>
              </>
            ) : (
              <Box sx={{ textAlign: 'center' }}>
                <SentIcon sx={{ mb: 5, mx: 'auto', height: 160 }} />

                <Typography variant="h3" gutterBottom>
                  {t('global.resetPassword.sentRequest')}
                </Typography>
                <Typography>
                  {t('global.resetPassword.confirmEmail')} &nbsp;
                  <strong>{email}</strong>
                  <br />
                  {t('global.resetPassword.checkEmail')}
                </Typography>

                <Button
                  size="large"
                  variant="contained"
                  component={RouterLink}
                  to="/login"
                  sx={{ mt: 5 }}
                >
                  {t('global.resetPassword.back')}
                </Button>
              </Box>
            )}
          </Box>
        </Container>
      </RootStyle>
    </Page>
  );
}
