import React from 'react';
import { DatePicker } from '@mui/lab';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';

const RtlDatePicker = (props: any) => {
  return (
    <DatePicker
      {...props}
      components={{
        LeftArrowIcon: ChevronRightIcon,
        RightArrowIcon: NavigateBeforeIcon,
      }}
    />
  );
};

export default RtlDatePicker;
