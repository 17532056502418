import React from 'react';
import { styled } from '@mui/system';
import CircularProgress from './CircularProgress';

export const SpinnerWrapper = styled('div')({
  height: '100%',
  display: 'flex',
  justifyContent: 'center',
});

const LoadingScreen = () => {
  return (
    <SpinnerWrapper>
      <div>
        <CircularProgress />
      </div>
    </SpinnerWrapper>
  );
};

export default LoadingScreen;
