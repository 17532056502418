import React from 'react';
import { Select } from 'formik-mui';
import { FastField } from 'formik';
import { useTranslation } from 'react-i18next';
import { SxProps } from '@mui/system';
import GenericField from '../../sharedFields/GenericField';
import { MenuItem } from '@mui/material';

const sxFormControl: SxProps = {
  mb: 2,
  width: '100%',
};

const VisitSummaryFields = () => {
  const { t } = useTranslation();
  const id = 'HMDFirst_VisitSummary';
  return (
    <div id={id}>
      <GenericField
        id={`${id}_GeneralSummary`}
        header={t('report.HMDFIRST.summary.header')}
        fields={[
          {
            name: 'data.summary.generalSummary',
            label: 'report.HMDFIRST.summary.generalSummary',
            type: 'text',
            rows: 5,
            smallSize: 12,
            largeSize: 12,
          },
        ]}
      />
      <FastField
        component={Select}
        name="data.summary.reunion"
        label={t('report.HMDFIRST.summary.reunion.header')}
        formControl={{ sx: sxFormControl }}
      >
        <MenuItem value="scheduled">{t('report.HMDFIRST.summary.reunion.scheduled')}</MenuItem>
        <MenuItem value="noMonitoring">
          {t('report.HMDFIRST.summary.reunion.noMonitoring')}
        </MenuItem>
      </FastField>
    </div>
  );
};

export default VisitSummaryFields;
