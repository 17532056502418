/* eslint-disable no-unused-vars */
import { Card, CardHeader, Divider } from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { IVisitFlat, ReportData } from '../interfaces/Models';
import ShowMore from './ShowMore';
import { IFilter } from './Table';

export enum TableHeaderPositions {
  CENTER = 'CENTER',
  LEFT = 'LEFT',
  RIGHT = 'RIGHT',
}

export type FiltersPositions = TableHeaderPositions;

export interface IRecordList {
  showTablePagination?: boolean;
  pageSize?: number;
  status?: string;
  sortField?: string;
  typeOfColumns?: string;
  showFilters?: boolean;
  fromDate?: Date;
  toDate?: Date;
  todayVisits?: boolean;
  employee?: string;
  dataFilter?: (value: IVisitFlat[]) => IVisitFlat[];
  rate?: number;
  showPageLoading?: boolean;
  showStatusFilter?: boolean;
  pageSizes?: number[];
  customFilters?: IFilter[];
  filterPosition?: FiltersPositions;
  statusFilterValues?: ReportData;
  serverSidePagination?: boolean;
  initialSortValue?: string;
}

interface IWidgetContainer {
  title: string;
  sx?: Object;
  showMoreHandler?: Function;
  showMoreRoute?: string;
}
const WidgetContainer: React.FC<IWidgetContainer> = ({
  title,
  sx,
  showMoreHandler,
  showMoreRoute,
  children,
}) => {
  const navigate = useNavigate();
  const showMoreNavigator = () => {
    navigate(showMoreRoute || '/app/dashboard');
  };

  return (
    <>
      <Card sx={sx}>
        <CardHeader title={title} sx={{ mb: 1 }} />
        <Divider />
        <div>{children}</div>

        {showMoreRoute || showMoreHandler ? (
          <ShowMore showMoreRecords={showMoreRoute ? showMoreNavigator : showMoreHandler || null} />
        ) : null}
      </Card>
    </>
  );
};

export default WidgetContainer;
