import { LoadingButton } from '@mui/lab';
import { Stack } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { IPatient } from '../../interfaces/Models';
import AddIcon from '@mui/icons-material/Add';
import PrescriptionForm from './prescription/PrescriptionForm';
import { userAtom } from '../../atoms/userAtom';
import { useRecoilValue } from 'recoil';
import { checkIfUserIsAdmin } from '../../utils/Helper';
import { EMPLOYEE_POSITIONS } from '../../common/Constants';

interface Props {
  handleTableDataUpdate: () => void;
  patient: IPatient;
}
export default function PrescriptionsTableHeader({ handleTableDataUpdate, patient }: Props) {
  const { t } = useTranslation();
  const user = useRecoilValue(userAtom);

  const [openForm, setOpenForm] = React.useState<boolean>(false);

  const handleOpenForm = () => setOpenForm(true);
  const handleCloseForm = () => setOpenForm(false);
  const isUserAdmin = checkIfUserIsAdmin(user);

  const isUserAllowedToAdd = isUserAdmin || user?.position === EMPLOYEE_POSITIONS.HMB;

  return (
    <>
      <PrescriptionForm
        handleCloseModal={handleCloseForm}
        patient={patient}
        open={openForm}
        handleTableDataUpdate={handleTableDataUpdate}
      />
      <Stack width="100%" direction={'row'} justifyContent={'flex-end'} alignItems={'center'}>
        <LoadingButton
          disabled={!isUserAllowedToAdd}
          variant="contained"
          onClick={handleOpenForm}
          endIcon={<AddIcon />}
        >
          {t('newPrescription')}
        </LoadingButton>
      </Stack>
    </>
  );
}
