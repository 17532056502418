import React from 'react';
import { IPatientProgram } from '../../interfaces/Models';
import { Button, Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Icon } from '@iconify/react';
import plusFill from '@iconify/icons-eva/plus-fill';
import ProgramReferenceNumberForm from './ProgramReferenceNumberForm';

interface IProgramRefsTableHeader {
  program: IPatientProgram;
  handleUpdateProgramData: () => void;
}
export default function ProgramRefsTableHeader({
  program,
  handleUpdateProgramData,
}: IProgramRefsTableHeader) {
  const { t } = useTranslation();
  const [openRefModal, setOpenRefModal] = React.useState<boolean>(false);

  const handleOpenModal = () => setOpenRefModal(true);

  const handleCloseModal = () => setOpenRefModal(false);
  return (
    <>
      <ProgramReferenceNumberForm
        program={program}
        openModal={openRefModal}
        handleCloseModal={handleCloseModal}
        handleProgramUpdate={handleUpdateProgramData}
      />
      <Stack width="100%" direction={'row'} justifyContent={'flex-end'} alignItems={'center'}>
        <Button endIcon={<Icon icon={plusFill} />} variant="contained" onClick={handleOpenModal}>
          {t('clinic.form.add')}
        </Button>
      </Stack>
    </>
  );
}
