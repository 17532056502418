import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
} from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { IPractice } from '../../../interfaces/Models';

interface PracticeCategorySelectProps {
  practice: IPractice;
  onChangeCategories: (index: number, event: SelectChangeEvent<string>) => void;
  fieldIdx: number;
  fieldName: 'category' | 'subCategory';
  label: string;
  categories: string[];
  categoryPath: string;
}
const PracticeCategorySelect = ({
  practice,
  onChangeCategories,
  fieldIdx,
  fieldName,
  label,
  categories,
  categoryPath,
}: PracticeCategorySelectProps) => {
  const { t } = useTranslation();

  const getSelectInputError = () => {
    if (fieldName == 'category') {
      return practice.categoryErrors.length > 1 ? practice.categoryErrors : '';
    }
    return practice.subCategoryErrors.length > 1 ? practice.subCategoryErrors : '';
  };

  return (
    <FormControl
      sx={{
        width: '100%',
      }}
      error={
        fieldName == 'category'
          ? practice.categoryErrors.length > 1
          : practice.subCategoryErrors.length > 1
      }
    >
      <InputLabel>{label}</InputLabel>
      <Select
        sx={{ width: '100%' }}
        id={`${fieldName}_Filter`}
        disabled={practice.added}
        value={fieldName == 'category' ? practice.category : practice.subCategory}
        name={fieldName}
        label={label}
        onChange={(e) => onChangeCategories(fieldIdx, e)}
      >
        {categories.map((option: string, categoryOptionIdx: number) => {
          return (
            <MenuItem key={`${option}_${categoryOptionIdx}`} value={option}>
              {t(`report.practiceFields.${categoryPath}.${option}`)}
            </MenuItem>
          );
        })}
      </Select>
      <FormHelperText>{getSelectInputError}</FormHelperText>
    </FormControl>
  );
};

export default PracticeCategorySelect;
