/* eslint-disable new-cap */
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useParams, useOutletContext } from 'react-router-dom';
import * as Yup from 'yup';
import { IReport } from '../../../interfaces/Models';
import { reportTypes } from '../../../common/Constants';
import ErrorMessage from '../../../components/ErrorMessage';
import { HMDFirstValues } from '../../../common/reportsFieldsValues/hmd/HMDFirstValues';
import HMDFirstFields from './fields/HMDFirstFields';
import ReportsForm from '../../../components/Form/ReportsForm';

const HMDFirst = () => {
  const { t } = useTranslation();
  const { visitId } = useParams();
  const data: IReport = useOutletContext();

  const initialValues = data
    ? data
    : {
        name: reportTypes.HMD_FIRST,
        visit: visitId,
        data: HMDFirstValues,
      };
  const ReportSchema = Yup.object({
    name: Yup.string().required(t('clinic.form.required')),
    visit: Yup.string().required(t('clinic.form.required')),
    data: Yup.object().shape({}),
  });

  const HtmlId = 'HMDFirst';

  if (data && data.name != reportTypes.HMD_FIRST) {
    return <ErrorMessage error={t('global.errors.somethingWrong')} />;
  }

  return (
    <ReportsForm
      initialValues={initialValues}
      visitId={visitId || ''}
      validationSchema={ReportSchema}
    >
      <HMDFirstFields id={HtmlId} />
    </ReportsForm>
  );
};

export default HMDFirst;
