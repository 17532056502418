import React from 'react';
import { Table, TableBody, TableCell, TableHead, TableRow, TableSortLabel } from '@mui/material';
import { HeaderGroup, ColumnInstance, Row } from 'react-table';
import { URL_QUERY_PARAMS } from '../common/Constants';
import useSearchFilter from '../hooks/useSearchFilter';
import { ITableBaseSchema } from '../interfaces/table';
import GenericTableRow, { TableContentCommonProps } from './GenericTableRow';

interface IColumnWithSort extends ColumnInstance {
  sortDirection?: string;
}
interface TableContentProps<T> extends TableContentCommonProps<T> {
  headerGroups: Array<HeaderGroup>;
  customFooter?: JSX.Element | null;
  sx?: object;
  rows: Row<any>[];
  prepareRow: Function;
  getTableBodyProps: any;
  getTableProps: any;
  showFilters?: boolean;
}
function TableContent<T extends ITableBaseSchema>({
  headerGroups,
  getTableBodyProps,
  rows,
  prepareRow,
  getTableProps,
  onRowClick,
  customFooter,
  sx,
  showIndex,
  showFilters,
  ExpandCard,
}: TableContentProps<T>) {
  const { changeQueryParams } = useSearchFilter();

  const handelSortChange = (column: IColumnWithSort) => {
    if (column.sortDirection) {
      if (column.sortDirection == 'asc') {
        changeQueryParams([{ name: URL_QUERY_PARAMS.SORT, value: `-${column.id}` }]);
      } else {
        changeQueryParams([{ name: URL_QUERY_PARAMS.SORT, value: column.id }]);
      }
    }
  };

  return (
    <Table {...getTableProps()}>
      <TableHead>
        {headerGroups.map((headerGroup: any) => {
          return (
            <TableRow {...headerGroup.getHeaderGroupProps()}>
              {ExpandCard && <TableCell />}
              {showIndex && <TableCell>#</TableCell>}
              {headerGroup.headers.map((column: any) => (
                <TableCell
                  {...column.getHeaderProps()}
                  onClick={() => handelSortChange(column)}
                  align={column.headerPosition ? column.headerPosition : 'left'}
                >
                  {column.render('Header')}
                  {column.sortDirection && (
                    <TableSortLabel hideSortIcon active={true} direction={column.sortDirection} />
                  )}
                </TableCell>
              ))}
            </TableRow>
          );
        })}
      </TableHead>
      <TableBody {...getTableBodyProps()} sx={sx}>
        {rows.map((row: Row<{}>, i: number) => {
          prepareRow(row);
          return (
            <GenericTableRow
              numberOfColumns={headerGroups[0].headers.length}
              ExpandCard={ExpandCard}
              onRowClick={onRowClick}
              showIndex={showIndex}
              index={i}
              row={row}
              key={`Row-${i}`}
            />
          );
        })}
        {customFooter ? customFooter : null}
      </TableBody>
    </Table>
  );
}

export default TableContent;
