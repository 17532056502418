import { TextField } from 'formik-mui';
import { FastField } from 'formik';
import { useTranslation } from 'react-i18next';
import { Grid, MenuItem, SxProps, Typography } from '@mui/material';
import { styled } from '@mui/system';
import { theme } from '../../../../theme';
import { feedingFields, HabitsFields, severtyOptions } from '../../../../common/HMDFirst';
import FormikSelect from '../../../../components/FormikSelect';
import GenericField from '../../sharedFields/GenericField';

const sxFormControl: SxProps = {
  width: '100%',
};

const StyledGrid = styled(Grid)({
  marginBottom: theme.spacing(2),
});

const AdditionalInfoFields = () => {
  const { t } = useTranslation();
  const id = 'HMDFirst_HealthIndices_AdditionalInfo';

  return (
    <div id={id}>
      <Typography variant="subtitle2" gutterBottom align="left">
        {t('report.HMDFIRST.healthIndices.additionalInformation.header')}
      </Typography>
      <StyledGrid container spacing={{ xs: 2, md: 4, lg: 4 }} id={`${id}_Container`}>
        {feedingFields.map((field) => {
          return (
            <Grid item xs={12} md={6} lg={6} key={field.name}>
              <FormikSelect
                id={`${id}_${field.name}`}
                name={`data.healthIndices.additionalInformation.${field.name}`}
                label={t(`report.HMDFIRST.healthIndices.additionalInformation.${field.name}`)}
                formControl={sxFormControl}
              >
                {field.options.map((option) => {
                  return (
                    <MenuItem key={option} value={option}>
                      {t(`global.form.select.${option}`)}
                    </MenuItem>
                  );
                })}
              </FormikSelect>
            </Grid>
          );
        })}

        <Grid item xs={6} md={3} lg={3}>
          <FormikSelect
            id={`${id}_Bedsores`}
            name="data.healthIndices.additionalInformation.bedsores.bedsoresValue"
            label={t('report.HMDFIRST.healthIndices.additionalInformation.bedsores.header')}
            formControl={sxFormControl}
          >
            <MenuItem value="yes">{t(`global.form.select.yes`)}</MenuItem>
            <MenuItem value="no">{t(`global.form.select.no`)}</MenuItem>
          </FormikSelect>
        </Grid>
        <Grid item xs={6} md={3} lg={3}>
          <FastField
            id={`${id}_WoundTax`}
            fullWidth
            component={TextField}
            type="text"
            name="data.healthIndices.additionalInformation.bedsores.woundTax"
            label={t('report.HMDFIRST.healthIndices.additionalInformation.bedsores.woundTax')}
          />
        </Grid>
        <Grid item xs={6} md={3} lg={3}>
          <FastField
            id={`${id}_Location`}
            fullWidth
            component={TextField}
            type="text"
            name="data.healthIndices.additionalInformation.bedsores.location"
            label={t('report.HMDFIRST.healthIndices.additionalInformation.bedsores.location')}
          />
        </Grid>
        <Grid item xs={6} md={3} lg={3}>
          <FastField
            id={`${id}_Rank`}
            fullWidth
            component={TextField}
            type="text"
            name="data.healthIndices.additionalInformation.bedsores.rank"
            label={t('report.HMDFIRST.healthIndices.additionalInformation.bedsores.rank')}
          />
        </Grid>
        <Grid item xs={6} md={4} lg={4}>
          <FormikSelect
            id={`${id}_EdemaValue`}
            name="data.healthIndices.additionalInformation.edema.edemaValue"
            label={t('report.HMDFIRST.healthIndices.additionalInformation.edema.header')}
            formControl={sxFormControl}
          >
            <MenuItem value="yes">{t(`global.form.select.yes`)}</MenuItem>
            <MenuItem value="no">{t(`global.form.select.no`)}</MenuItem>
          </FormikSelect>
        </Grid>
        <Grid item xs={6} md={4} lg={4}>
          <FastField
            id={`${id}_Edmalocation`}
            fullWidth
            component={TextField}
            type="text"
            name="data.healthIndices.additionalInformation.edema.Location"
            label={t('report.HMDFIRST.healthIndices.additionalInformation.edema.Location')}
          />
        </Grid>
        <Grid item xs={6} md={4} lg={4}>
          <FormikSelect
            id={`${id}_EdemaSeverty`}
            name="data.healthIndices.additionalInformation.edema.severity"
            label={t('report.HMDFIRST.healthIndices.additionalInformation.edema.severity')}
            formControl={sxFormControl}
          >
            {severtyOptions.map((option) => {
              return (
                <MenuItem key={option} value={option}>
                  {t(`global.form.select.${option}`)}
                </MenuItem>
              );
            })}
          </FormikSelect>
        </Grid>
        {HabitsFields.map((field) => {
          return (
            <Grid item xs={12} md={4} lg={4} key={field.name}>
              <FormikSelect
                id={`${id}_${field.name}`}
                name={`data.healthIndices.additionalInformation.${field.name}`}
                label={t(`report.HMDFIRST.healthIndices.additionalInformation.${field.name}`)}
                formControl={sxFormControl}
              >
                {field.options.map((option) => {
                  return (
                    <MenuItem key={option} value={option}>
                      {t(`global.form.select.${option}`)}
                    </MenuItem>
                  );
                })}
              </FormikSelect>
            </Grid>
          );
        })}
      </StyledGrid>
      <GenericField
        id={`${id}_NutritionIndices`}
        fields={[
          {
            name: 'data.healthIndices.nutritionIndices',
            label: 'report.HMDFIRST.healthIndices.nutritionIndices',
            type: 'textareaWithEditor',
          },
        ]}
      />
    </div>
  );
};

export default AdditionalInfoFields;
