import axios, { AxiosRequestConfig } from 'axios';
import { toast } from 'react-toastify';
import { IService } from '../interfaces';
import { ApiResponse } from '../interfaces/ApiResponseType';
import { getBaseUrl, logOut } from '../common/APIHelper';
import { t } from 'i18next';

const baseURL = getBaseUrl();

interface IFetcherService<T> {
  service: IService;
  setLoading?: (loading: boolean) => void;
  onSuccess?: (res: ApiResponse<T>) => void;
  onFailed?: (err: ApiResponse<T>) => void;
  errorMessage?: string;
  showNotification?: boolean;
}

export default async function FetcherService<T>({
  service,
  setLoading,
  onSuccess,
  onFailed,
  errorMessage,
  showNotification = true,
}: IFetcherService<T>): Promise<T> {
  const request: AxiosRequestConfig = {
    url: service.route,
    method: service.method,
    params: service.query,
    data: service.payload,
    responseType: service.responseType,
  };
  const addMode = service.method == 'post';
  const editMode = service.method == 'put';

  try {
    if (setLoading) {
      setLoading(true);
    }

    const response: ApiResponse<T> = (await axios(request)) as any;

    if (onSuccess) {
      onSuccess(response);
    }

    if (((addMode && service.payload) || editMode) && showNotification) {
      toast.success(t(`global.reportModes.${addMode ? 'addMessage' : 'editMessage'}`), {
        rtl: true,
      });
    }
    return Promise.resolve(response.data.data);
  } catch (err: any) {
    if (err.status === 403 || err.response?.status === 403) {
      window.location.href = `${baseURL}/403`;
    }
    if (err.status === 401 || err.response?.status === 401) {
      logOut();
    }
    // TODO: handel global errors
    // TODO: Showing error message
    // eslint-disable-next-line no-console
    console.log('what is the type of this error::', err);
    toast.error(errorMessage || err.response?.data?.message || err.message || String(err), {
      rtl: true,
    });

    if (onFailed) {
      onFailed(err);
    }
    return Promise.resolve(err);
    // return Promise.reject(err);
  } finally {
    if (setLoading) {
      setLoading(false);
    }
  }
}
