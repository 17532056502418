import SectionDivider from '../../../../components/SectionDivider';
import MultipleSelectFields from '../../sharedFields/MultipleSelectFields';
import { useTranslation } from 'react-i18next';
import { getCurrentDifficulties, getSwallowingDifficulties } from '../../../../common/HMKFirst';
import GenericField from '../../sharedFields/GenericField';

interface HMKFirstFieldsProps {
  id: string;
}
const HMKFirstFields = ({ id }: HMKFirstFieldsProps) => {
  const { t } = useTranslation();

  const currentDifficulties = getCurrentDifficulties(t);
  const swallowingDifficulties = getSwallowingDifficulties(t);

  return (
    <>
      <MultipleSelectFields
        id={`${id}_CurrentDifficulties`}
        Examinations={currentDifficulties}
        header={t('report.HMKFIRST.currentDifficulties.header')}
      />
      <SectionDivider />
      <MultipleSelectFields
        id={`${id}_Swallowing`}
        Examinations={swallowingDifficulties}
        header={t('report.HMKFIRST.swallowing.header')}
      />
      <SectionDivider />
      <GenericField
        id={`${id}_oralTest`}
        fields={[
          {
            name: 'data.oralTest',
            label: 'report.HMKFIRST.oralTest',
            type: 'textareaWithEditor',
          },
          {
            name: 'data.rehabilitation',
            label: 'report.HMKFIRST.rehabilitation',
            type: 'textareaWithEditor',
          },
          {
            name: 'data.furtherTreatment',
            label: 'report.HMKFIRST.furtherTreatment',
            type: 'textareaWithEditor',
          },
        ]}
      />
      <SectionDivider />
    </>
  );
};

export default HMKFirstFields;
