/* eslint-disable new-cap */
import React, { useState } from 'react';
import { Card } from '@mui/material';
import { IPatientProgram, IPatientProgramFlat } from '../../interfaces/Models';
import useService from '../../hooks/HookService';
import PatientProgramService from '../../services/PatientProgramService';
import ErrorMessage from '../../components/ErrorMessage';
import Table from '../../components/Table';
import { useNavigate } from 'react-router-dom';
import FetcherService from '../../hooks/FetcherService';
import ReportService from '../../services/ReportService';
import { ApiResponse } from '../../interfaces/ApiResponseType';
import { handleTableAtomChange, onDownloadPdf } from '../../utils/Helper';
import { toast } from 'react-toastify';
import { getProgramsColumns } from '../../common/columns/Programs';
import OverlaySpinner from '../../components/OverlaySpinner';
import NoActiveProgram from '../program/NoActiveProgram';
import { TabTableBox } from '../../styles/TabsStyles';
import { PATIENT_PROGRAM_STATUS } from '../../common/Constants';
import { Row } from 'react-table';
import { t } from 'i18next';
import DialogActions from '../../components/DialogActions';
import TableSkeleton from '../../components/skeletons/TableSkeleton';

interface PatientProgramsProps {
  patientId: string;
}

const PatientPrograms = ({ patientId }: PatientProgramsProps) => {
  const navigate = useNavigate();
  const [isLoadingReport, setIsLoadingReport] = useState(false);
  const [shouldTableUpdate, setShouldTableUpdate] = useState<number>(0);
  const [openCompleteProgramModal, setOpenCompleteProgramModal] = useState(false);
  const [record, setRecord] = useState<IPatientProgram | IPatientProgramFlat | null>(null);

  const { isLoading, data, error, additionalData, errorMessage } = useService<IPatientProgram[]>({
    service: PatientProgramService.getAllByPatientId(patientId),
    deps: [shouldTableUpdate],
  });

  // eslint-disable-next-line no-unused-vars
  const handelEditReport = (program: IPatientProgramFlat | IPatientProgram) => {
    handleTableAtomChange('programId', program._id);
    navigate(`/app/patient-program/edit/${program._id}`);
  };

  const handleCompleteProgram = async () => {
    await FetcherService<any>({
      service: PatientProgramService.edit(record?._id || '', {
        status: PATIENT_PROGRAM_STATUS.DONE,
      }),
      setLoading: setIsLoadingReport,
    });
    setOpenCompleteProgramModal(false);
    setShouldTableUpdate((prev) => prev + 1);
  };
  const onDownloadGlobalReleaseReport = async (
    program: IPatientProgram | IPatientProgramFlat,
    reportName?: string
  ) => {
    const onSuccess = (res: ApiResponse<BlobPart>) => {
      onDownloadPdf(res, reportName || '');
    };
    const onFailed = () => {
      toast.error(t('global.errors.report'));
    };
    await FetcherService<any>({
      service: ReportService.generateGlobalReleaseReportPdf(program._id || 'empty', {
        reportName: reportName,
      }),
      onSuccess: onSuccess,
      onFailed: onFailed,
      setLoading: setIsLoadingReport,
    });
  };

  const handelOpenCompleteProgramModal = (program: IPatientProgramFlat | IPatientProgram) => {
    setRecord(program);
    setOpenCompleteProgramModal(true);
  };

  const columns = getProgramsColumns(
    onDownloadGlobalReleaseReport,
    handelOpenCompleteProgramModal,
    2
  );

  const onRowClick = (row: Row<IPatientProgram>) => {
    handleTableAtomChange('programId', row.original._id);
    navigate(`/app/patient-program/${row.original._id}`);
  };

  const handelClosingModals = () => {
    setOpenCompleteProgramModal(false);
  };

  if (isLoading) {
    return <TableSkeleton filtersLength={0} topPagination={false} bottomPagination={false} />;
  }
  if (error) {
    return <ErrorMessage error={errorMessage} />;
  }
  if (data?.length == 0) {
    return <NoActiveProgram />;
  }
  return (
    <>
      <DialogActions
        onClose={handelClosingModals}
        open={openCompleteProgramModal}
        dialog={t('global.actionsConfirmation.completeProgram')}
        action={handleCompleteProgram}
      />
      <Card>
        <TabTableBox flexGrow={1}>
          <Table
            columns={columns}
            data={data || []}
            count={additionalData.total || 0}
            isLoading={isLoading}
            onRowClick={onRowClick}
          />
        </TabTableBox>
      </Card>
      {isLoadingReport && <OverlaySpinner />}
    </>
  );
};

export default PatientPrograms;
