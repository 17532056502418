import { Card, Container } from '@mui/material';
import React from 'react';
import Page from '../../components/Page';
import PageHeader from '../../components/PageHeader';
import { useTranslation } from 'react-i18next';
import MyVisitTable from './MyVisitTable';
import useAccess from '../../hooks/useAccess';
import { AbilitySubjects } from '../../casl/ability';

const MyVisits = () => {
  const { t } = useTranslation();
  useAccess('read', AbilitySubjects.EMPLOYEES_ABILITY);

  return (
    <Page title={t('drawer.sidebar.visits')}>
      <Container>
        <PageHeader pageTitle={t('drawer.sidebar.visits')} />
        <Card>
          <MyVisitTable showFilters={true} showPageLoading={true} />
        </Card>
      </Container>
    </Page>
  );
};

export default MyVisits;
