import { Box, Card, CardHeader, Divider, Skeleton } from '@mui/material';
import React from 'react';

const PiChartWidgetSkeleton = () => {
  return (
    <Card sx={{ p: 0.5 }}>
      <Box sx={{ flexGrow: 1 }}>
        <Box sx={{ width: '60%', margin: 1 }}>
          <Skeleton width="100%">
            <CardHeader />
          </Skeleton>
        </Box>
      </Box>
      <Skeleton variant="rectangular" width="100%" height={320}>
        <div style={{ paddingTop: '90%' }} />
      </Skeleton>
      <Divider sx={{ width: '90%', margin: 1 }} />
      <Box sx={{ flexGrow: 1, display: 'flex', justifyContent: 'center' }}>
        <Box sx={{ width: '90%' }}>
          <Skeleton width="100%" sx={{ mt: 2, mb: 2 }}>
            <CardHeader />
          </Skeleton>
        </Box>
      </Box>
    </Card>
  );
};

export default PiChartWidgetSkeleton;
