import { Grid } from '@mui/material';
import React from 'react';
import useService from '../../../hooks/HookService';
import useSearchFilter from '../../../hooks/useSearchFilter';
import { IEmployeesFinancialReports } from '../../../interfaces/Reports';
import StatisticsService from '../../../services/StatisticsService';
import { getStartEndDateDep } from '../../../utils/Helper';
import EmployeesFinancialSummaryWidget from './EmployeesFinancialSummaryWidget';
import VisitAmountsReportSummaryWidget from './VisitAmountsReportSummaryWidget';

interface IEmployeeVisitsReportsWidgets {
  employee: string;
}
const EmployeeVisitsReportsWidgets = ({ employee }: IEmployeeVisitsReportsWidgets) => {
  const { pageInfo } = useSearchFilter();

  const { from, to, range, month, year } = pageInfo;
  const visitsFinancialSummary = useService<IEmployeesFinancialReports>({
    service: StatisticsService.getEmployeesFinancialChartData({
      fromDate: getStartEndDateDep(pageInfo),
      toDate: getStartEndDateDep(pageInfo, true),
      employee,
    }),
    deps: [from, to, range, month, year],
  });
  return (
    <Grid container spacing={3}>
      <Grid item xs={12} md={4}>
        <EmployeesFinancialSummaryWidget {...visitsFinancialSummary} />
      </Grid>
      <Grid item xs={12} md={8}>
        <VisitAmountsReportSummaryWidget {...visitsFinancialSummary} />
      </Grid>
    </Grid>
  );
};

export default EmployeeVisitsReportsWidgets;
