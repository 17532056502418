/* eslint-disable new-cap */
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useOutletContext, useParams } from 'react-router-dom';
import * as Yup from 'yup';
import { IMedicine, IReport } from '../../../interfaces/Models';
import { reportTypes } from '../../../common/Constants';
import ErrorMessage from '../../../components/ErrorMessage';
import { HMBGeneralValues } from '../../../common/reportsFieldsValues/hmb/HMBGeneralValues';
import HMBGeneralFields from './fields/HMBGeneralFields';
import ReportsForm from '../../../components/Form/ReportsForm';

const removeEmptyListItems = (reportData: IReport) => {
  const newReportData = { ...reportData };
  // remove empty sensitivity list items
  const sensitivityItems: string[] = reportData.data.sensitivity || [];

  newReportData.data['sensitivity'] = sensitivityItems.filter((item) => item.trim() !== '');

  return newReportData;
};

const HMBGeneral = () => {
  const { t } = useTranslation();
  const { visitId } = useParams();
  const data: IReport = useOutletContext();

  const initialValues = data
    ? data
    : {
        name: reportTypes.HMB_GENERAL,
        visit: visitId,
        data: HMBGeneralValues,
      };

  const ReportSchema = Yup.object().shape({
    name: Yup.string().required(t('clinic.form.required')),
    visit: Yup.string().required(t('clinic.form.required')),
    data: Yup.object().shape({
      chronicDiseases: Yup.object().shape({
        chronicMedications: Yup.array().of(
          Yup.object().shape({
            name: Yup.string().required(t('clinic.form.required')),
            quantity: Yup.string().required(t('clinic.form.required')),
            frequency: Yup.string().required(t('clinic.form.required')),
            MedicationPeriod: Yup.string().required(t('clinic.form.required')),
          })
        ),
      }),
    }),
  });

  const HtmlId = 'HMBGeneral';

  if (data && data.name != reportTypes.HMB_GENERAL) {
    return <ErrorMessage error={t('global.errors.somethingWrong')} />;
  }
  return (
    <ReportsForm
      initialValues={initialValues}
      visitId={visitId || ''}
      validationSchema={ReportSchema}
      mutateSubmitData={removeEmptyListItems}
    >
      <HMBGeneralFields id={HtmlId} />
    </ReportsForm>
  );
};

export default HMBGeneral;
