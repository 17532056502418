/* eslint-disable new-cap */
import { Box, Button } from '@mui/material';
import React from 'react';
import MoreActions from '../../components/MoreActions';
import FetcherService from '../../hooks/FetcherService';
import { ApiResponse } from '../../interfaces/ApiResponseType';
import { IPatientProgram, IPatientProgramFlat } from '../../interfaces/Models';
import ReportService from '../../services/ReportService';
import { onDownloadPdf } from '../../utils/Helper';
import { getReleaseReportHandler } from '../../common/columns/Programs';
import EditIcon from '@mui/icons-material/Edit';
import { Link } from 'react-router-dom';
import { t } from 'i18next';
import { ProgramEditorRoot } from '../../styles/ProgramStyles';
import ProgramActionsSkeleton from '../../components/skeletons/ProgramActionsSkeleton';
import ErrorMessage from '../../components/ErrorMessage';

interface IPatientActiveProgramActions {
  loadingHandler: (value: boolean) => void;
  activeProgram?: IPatientProgram;
  error: boolean;
  isLoading: boolean;
  errorMessage: string;
}
const PatientActiveProgramActions = ({
  loadingHandler,
  activeProgram,
  error,
  isLoading,
  errorMessage,
}: IPatientActiveProgramActions) => {
  const onDownloadGlobalReleaseReport = async (
    program: IPatientProgramFlat | IPatientProgram,
    reportName?: string
  ) => {
    const onSuccess = (res: ApiResponse<BlobPart>) => {
      onDownloadPdf(res, reportName || '');
    };
    await FetcherService<any>({
      service: ReportService.generateGlobalReleaseReportPdf(program._id || 'empty', {
        reportName: reportName,
      }),
      onSuccess: onSuccess,
      setLoading: loadingHandler,
    });
  };

  if (isLoading) {
    return <ProgramActionsSkeleton />;
  }
  if (error) {
    return <ErrorMessage error={errorMessage} />;
  }
  if (!activeProgram) {
    return null;
  }

  return (
    <>
      <Box sx={{ width: '100%%', display: 'flex', justifyContent: 'space-between' }}>
        <MoreActions
          key="Release_Actions"
          record={activeProgram}
          actions={getReleaseReportHandler(onDownloadGlobalReleaseReport)}
        />
        <ProgramEditorRoot>
          <Button
            color="secondary"
            variant="contained"
            component={Link}
            endIcon={<EditIcon />}
            to={`/app/patient-program/edit/${activeProgram?._id}`}
          >
            {t('patient.info.editProgram')}
          </Button>
        </ProgramEditorRoot>
      </Box>
    </>
  );
};

export default PatientActiveProgramActions;
