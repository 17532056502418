import React from 'react';
import { Button, Stack, StackProps, Typography } from '@mui/material';
import { Icon } from '@iconify/react';
import plusFill from '@iconify/icons-eva/plus-fill';
import { useTranslation } from 'react-i18next';
import FolderForm from './FolderForm';
import { AbilityContext } from '../../casl/can';
import { useAbility } from '@casl/react';
import { AbilitySubjects } from '../../casl/ability';

interface props extends StackProps {
  pageTitle: string;
  subTitle: string;
  handleUpdateList: () => void;
}

const FoldersPageHeader: React.FC<props> = ({
  pageTitle,
  subTitle,
  handleUpdateList,
  sx,
  ...other
}) => {
  const { t } = useTranslation();
  const ability = useAbility(AbilityContext);
  const [openNewFolderModal, setOpenNewFolderModal] = React.useState<boolean>(false);

  const openModal = () => setOpenNewFolderModal(true);

  const closeModal = () => setOpenNewFolderModal(false);

  const isUserAllowedToCreateFolder = ability.can('create', AbilitySubjects.FOLDER);
  return (
    <>
      <FolderForm
        isOpen={openNewFolderModal}
        closeModal={closeModal}
        handleFolderListUpdate={handleUpdateList}
      />
      <Stack
        direction="row"
        alignItems="center"
        justifyContent={'space-between'}
        sx={{ mb: 5, ...sx }}
        {...other}
      >
        <Stack flexGrow={1}>
          <Typography variant="h3"> {pageTitle} </Typography>
          <Typography variant="body1" sx={{ color: 'text.disabled', mt: 0.5 }}>
            {subTitle}
          </Typography>
        </Stack>
        <Button
          onClick={openModal}
          disabled={!isUserAllowedToCreateFolder}
          variant="contained"
          endIcon={<Icon icon={plusFill} />}
        >
          {t('clinic.form.add')}
        </Button>
      </Stack>
    </>
  );
};

export default FoldersPageHeader;
