import Label from '../../components/Label';
import { IPatientProgram, IPatientProgramFlat, IVisit } from '../../interfaces/Models';
import { getDateFormatted, getSortDirection } from '../../utils/Helper';
import { PROGRAMS_COLOR, VISIT_STATUS, PATIENT_PROGRAM_STATUS_TO_HEBREW } from '../Constants';
import { Icon } from '@iconify/react';
import download from '@iconify/icons-eva/download-outline';
import { t } from 'i18next';
import { getPatientNameColumn } from './MyVisitsColumns';
import ProgramsMoreOptions from '../../pages/program/ProgramsMoreOptions';
import ClinicNameLabel from '../../components/ClinicNameLabel';

export const checkAllProgramVisitsDone = (
  program: IPatientProgramFlat | IPatientProgram,
  isProgramDone?: boolean
) => {
  if (isProgramDone) {
    return isProgramDone;
  }
  if (program.visits) {
    const pendingVisits = program.visits.filter(
      (visit: IVisit) => visit.status == VISIT_STATUS.PENDING
    );

    return pendingVisits.length === 0;
  }
  return false;
};

export const getReleaseReportHandler = (
  onDownloadGlobalReleaseReport: (
    value: IPatientProgramFlat | IPatientProgram,
    reportName?: string
  ) => void
) => {
  return [
    {
      name: t('global.globalReleaseReportTypes.releaseReport'),
      handler: onDownloadGlobalReleaseReport,
      actionHandlerParam1: 'מכתב שחרור',
      icon: <Icon icon={download} width={24} height={24} color="3E94A8" />,
    },
    {
      name: t('global.globalReleaseReportTypes.extendingReport'),
      handler: onDownloadGlobalReleaseReport,
      actionHandlerParam1: 'בקשה להמשך טיפול',
      icon: <Icon icon={download} width={24} height={24} color="3E94A8" />,
    },
    {
      name: t('global.globalReleaseReportTypes.summaryReport'),
      handler: onDownloadGlobalReleaseReport,
      actionHandlerParam1: 'סיכום ביניים',
      icon: <Icon icon={download} width={24} height={24} color="3E94A8" />,
    },

    {
      name: t('global.globalReleaseReportTypes.hmmSummary'),
      handler: onDownloadGlobalReleaseReport,
      actionHandlerParam1: 'הערכת מרפא בעיסוק',
      icon: <Icon icon={download} width={24} height={24} color="3E94A8" />,
    },

    {
      name: t('global.globalReleaseReportTypes.hmpSummary'),
      handler: onDownloadGlobalReleaseReport,
      actionHandlerParam1: 'הערכת פיזיותרפיה',
      icon: <Icon icon={download} width={24} height={24} color="3E94A8" />,
    },
  ];
};

export const getProgramTitleColumn = (sort?: string) => {
  return {
    Header: `${t('patient.form.title')}`,
    accessor: 'title',
    sortType: sort ? 'basic' : undefined,
    sortDirection: sort ? getSortDirection(sort || '', 'title') : undefined,
  };
};

const getProgramVisitsStatusColumns = () => {
  return [
    {
      id: 'status',
      Header: `${t('patient.info.status')}`,
      accessor: (program: any) => {
        const statusText =
          PATIENT_PROGRAM_STATUS_TO_HEBREW[program.status.toString().toUpperCase()];
        return program.isExported ? (
          statusText
        ) : (
          <Label variant="ghost" color={PROGRAMS_COLOR[program.status]}>
            {statusText}
          </Label>
        );
      },
    },
    {
      id: 'visits',
      Header: `${t('visit.table.totalVisits')}`,
      accessor: (program: any) => program.visits.length,
    },
  ];
};

export const getEndDateColumn = (sort?: string) => {
  return {
    id: 'endDate',
    Header: t('patient.info.endDate'),
    accessor: (program: any) => getDateFormatted(program.endDate),
    sortType: sort ? 'basic' : undefined,
    sortDirection: sort ? getSortDirection(sort || '', 'endDate') : undefined,
  };
};

export const getProgramStartEndColumns = (sort?: string) => {
  return [
    {
      id: 'startDate',
      Header: t('patient.info.startDate'),
      accessor: (program: any) => getDateFormatted(program.startDate),
      sortType: sort ? 'basic' : undefined,
      sortDirection: sort ? getSortDirection(sort || '', 'startDate') : undefined,
    },
    getEndDateColumn(sort),
  ];
};

const getProgramCreatedAtActionsColumns = (
  onDownloadGlobalReleaseReport: (
    value: IPatientProgramFlat | IPatientProgram,
    reportName?: string
  ) => void,
  handleCompleteProgram: (value: IPatientProgramFlat | IPatientProgram) => void,
  handleOpenAddHospitalDaysForm?: (value: IPatientProgramFlat | IPatientProgram) => void
) => {
  return {
    Header: t('clinic.table.actions'),
    accessor: (program: any) => {
      return (
        <ProgramsMoreOptions
          program={program}
          tableSessionId="programId"
          handleCompleteProgram={handleCompleteProgram}
          handleOpenAddHospitalDaysForm={handleOpenAddHospitalDaysForm}
          onDownloadGlobalReleaseReport={onDownloadGlobalReleaseReport}
        />
      );
    },
    noExcelSupport: true,
  };
};
export const getProgramsColumns = (
  onDownloadGlobalReleaseReport: (
    value: IPatientProgramFlat | IPatientProgram,
    reportName?: string
  ) => void,
  handleCompleteProgram: (value: IPatientProgramFlat | IPatientProgram) => void,
  type: 1 | 2, // 2 = patient page view , 1 = main programs table view TODO: change to enums
  sort?: string,
  handleOpenAddHospitalDaysForm?: (value: IPatientProgramFlat | IPatientProgram) => void
) => {
  return type == 1
    ? [
        getProgramTitleColumn(),
        getPatientNameColumn(sort),
        {
          Header: `${t('patient.form.clinic')}`,
          accessor: (program: any) => <ClinicNameLabel clinicName={program.clinicName} />,
        },
        ...getProgramVisitsStatusColumns(),
        {
          id: 'completedVisits',
          Header: `${t('visit.table.completedVisits')}`,
          accessor: (program: any) =>
            program.visits.filter((visit: IVisit) => visit.status == VISIT_STATUS.DONE).length,
        },
        {
          id: 'sentToClinicVisits',
          Header: `${t('visit.table.sentToClinicVisits')}`,
          accessor: (program: any) =>
            program.visits.filter((visit: IVisit) => visit.status == VISIT_STATUS.SENT_TO_CLINIC)
              .length,
        },
        {
          id: 'pendingVisits',
          Header: t('visit.table.pendingVisits'),
          accessor: (program: any) =>
            program.visits.filter((visit: IVisit) => visit.status == VISIT_STATUS.PENDING).length,
        },
        ...getProgramStartEndColumns(sort),
        getProgramCreatedAtActionsColumns(
          onDownloadGlobalReleaseReport,
          handleCompleteProgram,
          handleOpenAddHospitalDaysForm
        ),
      ]
    : [
        getProgramTitleColumn(),
        ...getProgramVisitsStatusColumns(),
        ...getProgramStartEndColumns(),
        getProgramCreatedAtActionsColumns(onDownloadGlobalReleaseReport, handleCompleteProgram),
      ];
};
