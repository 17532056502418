/* eslint-disable new-cap */
import { Stack } from '@mui/material';
import React, { useMemo, useState } from 'react';
import FileUpload from './FileUpload';
import { IAttachment } from '../interfaces/attachment';
import attachmentService from '../services/attachemntService';
import FetcherService from '../hooks/FetcherService';
import { IPatient, IPatientProgram, IUser } from '../interfaces/Models';
import { AbilitySubjects } from '../casl/ability';
import { AbilityContext } from '../casl/can';
import { useAbility } from '@casl/react';
import SearchFilter from './SearchFilter';
import { IFolder } from '../interfaces/folder';
import { LoadingButton } from '@mui/lab';
import { useTranslation } from 'react-i18next';
import PatientProgramService from '../services/PatientProgramService';
import { ApiResponse } from '../interfaces/ApiResponseType';
import { toast } from 'react-toastify';
import CameraAltIcon from '@mui/icons-material/CameraAlt';

export const getPatientEmployeeFullName = (value: IUser | IPatient) => {
  return `${value.personalDetails.firstName.trim()} ${value.personalDetails.lastName.trim()}`;
};

interface IAttachmentsTableHeader {
  patient?: IPatient;
  employee?: IUser;
  program?: IPatientProgram;
  handleTableUpdate: () => void;
  isProfileEmployee?: boolean;
  folder?: IFolder;
  enableSearch?: boolean;
  uploadPermission?: boolean;
}
export default function AttachmentsTableHeader({
  patient,
  employee,
  program,
  handleTableUpdate,
  isProfileEmployee = false,
  folder,
  enableSearch = false,
  uploadPermission,
}: IAttachmentsTableHeader) {
  const { t } = useTranslation();
  const ability = useAbility(AbilityContext);
  const adminAbility = ability.can('manage', AbilitySubjects.ADMIN_ABILITY);
  const uploadFolderAttachmentPermission = ability.can(
    'create',
    AbilitySubjects.FOLDER_ATTACHMENTS
  );
  const [loadingScreenshot, setLoadingScreenshot] = useState<boolean>(false);
  const [formLoading, setFormLoading] = useState<boolean>(false);

  const handleUploadAttachment = async (val: IAttachment) => {
    await FetcherService<IAttachment>({
      service: attachmentService.add({
        patient,
        employee,
        program,
        folder,
        ...val,
      }),
      setLoading: setFormLoading,
    });
    handleTableUpdate();
  };

  const getFileFullPath = ({
    patient,
    employee,
    program,
    folder,
  }: {
    patient?: IPatient;
    employee?: IUser;
    program?: IPatientProgram;
    folder?: IFolder;
  }) => {
    if (folder) {
      return `${folder.name}`;
    }
    if (employee) {
      return `employees/${getPatientEmployeeFullName(employee)}`;
    }
    if (patient) {
      return `patients/${getPatientEmployeeFullName(patient)}-${patient.idNumber}`;
    }
    if (program) {
      return `program/${String(program._id)}/${program.title}`;
    }
    return 'general';
  };

  const fileFullPath = getFileFullPath({ patient, employee, program, folder });

  const isUploadButtonDisabled = useMemo(() => {
    if (folder) return !uploadFolderAttachmentPermission;
    if (typeof uploadPermission === 'boolean') return !uploadPermission;
    return !isProfileEmployee && !adminAbility;
  }, [isProfileEmployee, adminAbility]);

  const handleSaveProgramScreenshot = async () => {
    const onSuccess = (res: ApiResponse<string>) => {
      toast.success(t('global.screenshotSuccess'));
      handleTableUpdate();
    };
    await FetcherService<any>({
      service: PatientProgramService.takeProgramScreenshot(program?._id || 'empty'),
      onSuccess: onSuccess,
      setLoading: setLoadingScreenshot,
      errorMessage: t('global.errors.failedToTakeProgramScreenshot'),
    });
  };

  return (
    <Stack
      width="100%"
      direction={'row'}
      justifyContent={enableSearch ? 'space-between' : 'flex-end'}
      alignItems={'center'}
    >
      {enableSearch && <SearchFilter />}
      <Stack direction={'row'} alignItems={'center'} gap={2}>
        {program && (
          <LoadingButton
            loading={loadingScreenshot}
            variant="contained"
            onClick={handleSaveProgramScreenshot}
            endIcon={<CameraAltIcon />}
          >
            {t('global.takeScreenshot')}
          </LoadingButton>
        )}
        <FileUpload
          filePath={fileFullPath}
          isLoading={formLoading}
          submitFileHandler={handleUploadAttachment}
          size="medium"
          multiple
          disabled={isUploadButtonDisabled}
        />
      </Stack>
    </Stack>
  );
}
