import React from 'react';
import {
  getOccupationAndActivityFields,
  getSeatingfields,
  memoryFunctionsFields,
  occupationAndActivityOptions,
  orientationFunctionsFields,
  properOptions,
  socialInteractionsFields,
  visualPerceptionFunctionsFields,
  hmmFirstTreatmentFields,
  hmmReleaseTreatmentFields,
  hmmFirstFreeTextFields,
} from '../../../../common/HMMFirst';
import SectionDivider from '../../../../components/SectionDivider';
import GenericField from '../../sharedFields/GenericField';
import AbilitiesTest from './AbilitiesTest';
import DiagnosesSection from './DiagnosesSection';
import MuscularMovementFields from './MuscularMovementFields';
import RightLeftFields from './RightLeftFields';
import SelectNote from './SelectNote';
import { useTranslation } from 'react-i18next';
import BADLActivityFields from './FimActivityFields';
import { getIn, useFormikContext } from 'formik';
import { fallsfields } from '../../../../common/HMA';

export interface HMMFirstFieldsProps {
  id: string;
  isFirst?: boolean;
}
const HMMFirstFields = ({ id, isFirst }: HMMFirstFieldsProps) => {
  const { t } = useTranslation();
  const { values, setFieldValue }: any = useFormikContext();
  const fimValues = getIn(values, 'data.feelingOfPain.occupationAndActivity.fimAssessments.fields');
  const seatingFields = getSeatingfields();

  const occupationAndActivityFields = getOccupationAndActivityFields();

  return (
    <>
      <DiagnosesSection />
      <SectionDivider />
      <AbilitiesTest
        id={`${id}_SocialInteractions`}
        header={t('report.HMMFIRST.socialInteractions.header')}
        sectionName="socialInteractions"
        fields={socialInteractionsFields}
        reportName="HMMFIRST"
      />
      <SectionDivider />
      <AbilitiesTest
        id={`${id}_Orientation`}
        header={t('report.HMMFIRST.mentalFunctions.header')}
        subHeader={t('report.HMMFIRST.mentalFunctions.orientation.header')}
        sectionName="mentalFunctions.orientation"
        fields={orientationFunctionsFields}
        reportName="HMMFIRST"
      />
      <AbilitiesTest
        id={`${id}_Memory`}
        subHeader={t('report.HMMFIRST.mentalFunctions.memory.header')}
        sectionName="mentalFunctions.memory"
        fields={memoryFunctionsFields}
        reportName="HMMFIRST"
      />

      <AbilitiesTest
        id={`${id}_VisualPerception`}
        subHeader={t('report.HMMFIRST.mentalFunctions.visualPerception.header')}
        sectionName="mentalFunctions.visualPerception"
        fields={visualPerceptionFunctionsFields}
        reportName="HMMFIRST"
      />
      <SectionDivider />
      <MuscularMovementFields />
      <RightLeftFields />
      <SectionDivider />
      <RightLeftFields
        header={t('report.HMMFIRST.SensoryFunctions.header')}
        subHeader={t('report.HMMFIRST.SensoryFunctions.subHeader')}
      />
      <SectionDivider />
      <SelectNote
        id={`${id}_feelingOfPain`}
        subHeader={t('report.HMMFIRST.feelingOfPain.subHeader')}
        fields={seatingFields}
        options={properOptions}
      />
      <SelectNote
        id={`${id}_IADL`}
        header={t('report.HMMFIRST.feelingOfPain.occupationAndActivity.header')}
        subHeader="IADL"
        fields={occupationAndActivityFields}
        options={occupationAndActivityOptions}
      />
      <BADLActivityFields
        id={`${id}_BADL`}
        values={fimValues}
        onChangeFormikValue={setFieldValue}
      />
      <SectionDivider />

      <GenericField
        id={`${id}_RiskOfFalls'`}
        fields={fallsfields}
        subHeader={t('report.HMAFIRST.falls.header')}
      />
      <SectionDivider />

      <GenericField id={`${id}-free-text-fields`} fields={hmmFirstFreeTextFields} />
      <SectionDivider />
      {isFirst && (
        <GenericField
          header={t('report.HMMFIRST.treatmentPlan.header')}
          id={`${id}_Treatment_Plan`}
          fields={hmmFirstTreatmentFields}
        />
      )}
      {!isFirst && <GenericField id={`${id}_Release_Section`} fields={hmmReleaseTreatmentFields} />}
      <SectionDivider />
    </>
  );
};

export default HMMFirstFields;
