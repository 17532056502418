import { Box, Card, CardHeader, Skeleton } from '@mui/material';

const BarChartWidgetSkeleton = () => {
  return (
    <Card sx={{ p: 0.5 }}>
      <Box sx={{ flexGrow: 1 }}>
        <Box sx={{ width: '60%', margin: 1 }}>
          <Skeleton width="20%">
            <CardHeader />
          </Skeleton>
        </Box>
      </Box>
      <Skeleton variant="rectangular" width="100%" height={320}>
        <div style={{ paddingTop: '90%' }} />
      </Skeleton>
    </Card>
  );
};

export default BarChartWidgetSkeleton;
