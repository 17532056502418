import {
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { IField, IOption } from '../../../../common/HMA';
import { FlexDiv } from '../../../../styles/DateRangeFilterStyles';
import { StyledGrid } from '../../../../styles/FormFieldsStyles';
import { IWoundEstimateSection } from './WoundEstimateFields';
import isReportMode from '../../../../hooks/isReportMode';

import { Icon } from '@iconify/react';

interface IWoundEstimateSectionProps {
  header: string;
  onChangeFieldValue: (sectionIdx: number, fieldIdx: number, value: string) => void;
  sectionIdx: number;
  section: IWoundEstimateSection;
  fieldsOptions: IField[];
  therapeuticIntervention?: boolean;
}
const WoundEstimateSection = ({
  header,
  onChangeFieldValue,
  sectionIdx,
  section,
  fieldsOptions,
  therapeuticIntervention,
}: IWoundEstimateSectionProps) => {
  const { t } = useTranslation();
  const isViewMode = isReportMode('view');

  return (
    <div>
      {header && (
        <Typography variant="subtitle2" gutterBottom align="left">
          {header}
        </Typography>
      )}
      <StyledGrid container spacing={2} id={`Wound_Estimate_Section _Container`}>
        {fieldsOptions.map((field: IField, fieldIdx: number) => {
          const newValueIdx = therapeuticIntervention ? fieldIdx : fieldIdx + 5;
          const fieldValue = section.fields[newValueIdx];
          const isLastSubSectionLastField = fieldIdx == fieldsOptions.length - 1;
          const label = field.label;
          const options = field.options;
          return (
            <Grid
              item
              xs={12}
              md={isLastSubSectionLastField ? 12 : 6}
              key={`${label}_ ${fieldIdx}`}
            >
              {options ? (
                <>
                  <FormControl
                    sx={{
                      width: '100%',
                    }}
                  >
                    <InputLabel>{t(`${label}`)}</InputLabel>
                    <Select
                      sx={{ width: '100%' }}
                      id={`${label}`}
                      disabled={isViewMode}
                      value={fieldValue}
                      label={t(`${label}`)}
                      onChange={(e) => onChangeFieldValue(sectionIdx, newValueIdx, e.target.value)}
                      IconComponent={() => (
                        <FlexDiv sx={{ paddingRight: 1.3 }}>
                          {section.fields[fieldIdx].length > 0 && (
                            <IconButton
                              edge="end"
                              aria-label="delete"
                              onClick={() => onChangeFieldValue(sectionIdx, newValueIdx, '')}
                            >
                              <Icon icon="iconoir:cancel" />
                            </IconButton>
                          )}
                        </FlexDiv>
                      )}
                    >
                      {options?.map((option: IOption, fieldOptionIdx: number) => {
                        return (
                          <MenuItem key={`${option.value}_${fieldOptionIdx}`} value={option.value}>
                            {typeof option.value == 'number'
                              ? option.value
                              : t(
                                  `global.form.select.${option.label ? option.label : option.value}`
                                )}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                </>
              ) : (
                <>
                  <TextField
                    fullWidth
                    id={`${label}_${newValueIdx}`}
                    value={fieldValue}
                    type="text"
                    disabled={isViewMode}
                    label={t(`${label}`)}
                    onChange={(e) => onChangeFieldValue(sectionIdx, newValueIdx, e.target.value)}
                  />
                </>
              )}
            </Grid>
          );
        })}
      </StyledGrid>
    </div>
  );
};

export default WoundEstimateSection;
