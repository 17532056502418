/* eslint-disable new-cap */
import { Card } from '@mui/material';
import { t } from 'i18next';
import React from 'react';
import { getEditAction } from '../../common/columns/ProgramVisits';
import MoreOptions from '../../components/MoreOptions';
import Table from '../../components/Table';
import { IHospitalDays, IPatientProgram, IPatientProgramFlat } from '../../interfaces/Models';
import { StyledBox } from '../../styles/TabsStyles';
import { theme } from '../../theme';
import { getDateFormatted } from '../../utils/Helper';
import NoTabMessage from './NoActiveProgram';
import DeleteIcon from '@mui/icons-material/Delete';
import DialogActions from '../../components/DialogActions';
import FetcherService from '../../hooks/FetcherService';
import PatientProgramService from '../../services/PatientProgramService';

interface IHospitalDaysTable {
  handleOpenEditMode: (value: IHospitalDays) => void;
  program: IPatientProgram | IPatientProgramFlat;
  updateAdminAndHmaAbility: boolean;
  updateAdminAbility: boolean;
  handleTableUpdate: () => void;
}
const HospitalDaysTable = ({
  program,
  handleOpenEditMode,
  updateAdminAndHmaAbility,
  updateAdminAbility,
  handleTableUpdate,
}: IHospitalDaysTable) => {
  const [hospitalDaysPeriod, setHospitalDaysPeriod] = React.useState<IHospitalDays | undefined>(
    undefined
  );
  const [deleteHospitalDaysModal, setDeleteHospitalDaysModal] = React.useState<boolean>(false);

  const onOpenEditHospitalDaysPeriod = (value: IHospitalDays) => {
    handleOpenEditMode(value);
  };

  const handleModalClose = () => {
    setDeleteHospitalDaysModal(false);
  };

  const onOpenDeleteHospitalDaysModal = (value: IHospitalDays) => {
    setHospitalDaysPeriod(value);
    setDeleteHospitalDaysModal(true);
  };

  const handleDeleteHospitalDaysPeriod = async () => {
    await FetcherService<any>({
      service: PatientProgramService.deleteHospitalDays(
        String(program?._id),
        String(hospitalDaysPeriod?._id)
      ),
    });
    setHospitalDaysPeriod(undefined);
    handleModalClose();
    handleTableUpdate();
  };
  const columns = [
    {
      id: 'start',
      Header: t('patient.info.startDate'),
      accessor: (period: any) => getDateFormatted(period.start),
    },
    {
      id: 'end',
      Header: t('patient.info.endDate'),
      accessor: (period: any) => getDateFormatted(period.end),
    },
    {
      Header: t('clinic.table.actions'),
      accessor: (period: any) => {
        return (
          <MoreOptions
            actions={[
              getEditAction(onOpenEditHospitalDaysPeriod, updateAdminAndHmaAbility),
              {
                name: t('global.moreMenu.delete'),
                handler: onOpenDeleteHospitalDaysModal,
                icon: <DeleteIcon width={24} height={24} color="error" />,
                variant: theme.palette.error.main,
                isVisible: updateAdminAbility,
              },
            ]}
            record={period}
          />
        );
      },
    },
  ];

  if (!program.hospitalDays || program.hospitalDays.length < 1) {
    return <NoTabMessage message={t('program.hospitalDaysForm.noHospitalDays')} />;
  }
  return (
    <>
      <DialogActions
        onClose={handleModalClose}
        open={deleteHospitalDaysModal}
        dialog={`${t('program.hospitalDaysForm.deleteHospitalDays')}`}
        action={handleDeleteHospitalDaysPeriod}
      />
      <Card>
        <StyledBox flexGrow={1}>
          <Table
            columns={columns}
            data={program.hospitalDays}
            count={program.hospitalDays.length}
            isLoading={false}
            showIndex={true}
            showPagination={false}
          />
        </StyledBox>
      </Card>
    </>
  );
};

export default HospitalDaysTable;
