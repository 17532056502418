import { Box, Skeleton, Typography } from '@mui/material';
import React from 'react';

const ActiveProgramsSkeleton = () => {
  return (
    <>
      <Box sx={{ flexGrow: 1, p: 1, display: 'flex', justifyContent: 'space-between' }}>
        <Box sx={{ display: 'flex', flexDirection: 'column' }} width="40%">
          <Skeleton width="60%">
            <Typography>.</Typography>
          </Skeleton>

          <Skeleton width="60%">
            <Typography>.</Typography>
          </Skeleton>
        </Box>
        <Box width="20%" height="100%">
          <Skeleton width="60%" sx={{ top: '60%', left: '50%' }}>
            <Typography>.</Typography>
          </Skeleton>
        </Box>
      </Box>
      <Box sx={{ flexGrow: 1, p: 1, display: 'flex', justifyContent: 'space-between' }}>
        <Box sx={{ display: 'flex', flexDirection: 'column' }} width="40%">
          <Skeleton width="60%">
            <Typography>.</Typography>
          </Skeleton>

          <Skeleton width="60%">
            <Typography>.</Typography>
          </Skeleton>
        </Box>
        <Box width="20%" height="100%">
          <Skeleton width="60%" sx={{ top: '60%', left: '50%' }}>
            <Typography>.</Typography>
          </Skeleton>
        </Box>
      </Box>
      <Box sx={{ flexGrow: 1, p: 1, display: 'flex', justifyContent: 'space-between' }}>
        <Box sx={{ display: 'flex', flexDirection: 'column' }} width="40%">
          <Skeleton width="60%">
            <Typography>.</Typography>
          </Skeleton>

          <Skeleton width="60%">
            <Typography>.</Typography>
          </Skeleton>
        </Box>
        <Box width="20%" height="100%">
          <Skeleton width="60%" sx={{ top: '60%', left: '50%' }}>
            <Typography>.</Typography>
          </Skeleton>
        </Box>
      </Box>
      <Box sx={{ flexGrow: 1, p: 1, display: 'flex', justifyContent: 'space-between' }}>
        <Box sx={{ display: 'flex', flexDirection: 'column' }} width="40%">
          <Skeleton width="60%">
            <Typography>.</Typography>
          </Skeleton>

          <Skeleton width="60%">
            <Typography>.</Typography>
          </Skeleton>
        </Box>
        <Box width="20%" height="100%">
          <Skeleton width="60%" sx={{ top: '60%', left: '50%' }}>
            <Typography>.</Typography>
          </Skeleton>
        </Box>
      </Box>
      <Box sx={{ flexGrow: 1, p: 1, display: 'flex', justifyContent: 'space-between' }}>
        <Box sx={{ display: 'flex', flexDirection: 'column' }} width="40%">
          <Skeleton width="60%">
            <Typography>.</Typography>
          </Skeleton>

          <Skeleton width="60%">
            <Typography>.</Typography>
          </Skeleton>
        </Box>
        <Box width="20%" height="100%">
          <Skeleton width="60%" sx={{ top: '60%', left: '50%' }}>
            <Typography>.</Typography>
          </Skeleton>
        </Box>
      </Box>
    </>
  );
};

export default ActiveProgramsSkeleton;
