import { Grid, Typography, SxProps, MenuItem } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Field } from 'formik';
import { StyledTextField } from '../../../../styles/FormFieldsStyles';
import isReportMode from '../../../../hooks/isReportMode';
import FormikSelect from '../../../../components/FormikSelect';

const sxFormControl: SxProps = {
  mb: 2,
  width: '100%',
};

interface HabitsFieldsProps {
  id: string;
  isPatientSmoking: boolean;
}

const HabitsFields = ({ id, isPatientSmoking }: HabitsFieldsProps) => {
  const { t } = useTranslation();
  const dietOptions = ['normal', 'saltDoor', 'sugarDoor', 'glutenFree'];
  const isViewMode = isReportMode('view');

  return (
    <div id={id}>
      <Typography variant="subtitle1" gutterBottom align="left">
        {t('report.sensitivityHabitsSection.habits.habitSection')}
      </Typography>
      <Grid container spacing={{ xs: 0, md: 4, lg: 4 }} id={`${id}_Container`}>
        <Grid item xs={12} md={4} lg={4}>
          <FormikSelect
            id={`${id}_SelectInput_Smoking`}
            name="data.habits.smoking"
            label={t('report.sensitivityHabitsSection.habits.smoking')}
            formControl={sxFormControl}
          >
            <MenuItem value="yes">{t('global.form.select.yes')}</MenuItem>
            <MenuItem value="no">{t('global.form.select.no')}</MenuItem>
          </FormikSelect>
        </Grid>
        <Grid item xs={12} md={4} lg={4}>
          <Field
            id={`${id}_SmokingBoxes`}
            fullWidth
            disabled={isViewMode || !isPatientSmoking}
            component={StyledTextField}
            type="number"
            name="data.habits.smokingBoxes"
            label={t('report.sensitivityHabitsSection.habits.smokingBoxes')}
          />
        </Grid>
        <Grid item xs={12} md={4} lg={4}>
          <Field
            id={`${id}_SmokingYears`}
            fullWidth
            disabled={isViewMode || !isPatientSmoking}
            component={StyledTextField}
            type="number"
            name="data.habits.smokingYears"
            label={t('report.sensitivityHabitsSection.habits.smokingYears')}
          />
        </Grid>
        <Grid item xs={12} md={4} lg={4}>
          <FormikSelect
            id={`${id}_SelectInput_Exercise`}
            name="data.habits.exercise"
            label={t('report.sensitivityHabitsSection.habits.exercise')}
            formControl={sxFormControl}
          >
            {/*  TODO -- add values for exercise after becoming available */}
            <MenuItem value="yes">{t('global.form.select.yes')}</MenuItem>
            <MenuItem value="no">{t('global.form.select.no')}</MenuItem>
          </FormikSelect>
        </Grid>
        <Grid item xs={12} md={4} lg={4}>
          <FormikSelect
            id={`${id}_SelectInput_Diet`}
            name="data.habits.diet"
            label={t('report.sensitivityHabitsSection.habits.diet')}
            formControl={sxFormControl}
          >
            {dietOptions.map((option) => {
              return (
                <MenuItem key={option} value={option}>
                  {t(`global.form.select.${option}`)}
                </MenuItem>
              );
            })}
          </FormikSelect>
        </Grid>
        <Grid item xs={12} md={4} lg={4}>
          <Field
            id={`${id}_Dietnotes`}
            fullWidth
            component={StyledTextField}
            type="text"
            name="data.habits.dietNotes"
            label={t('report.sensitivityHabitsSection.habits.dietNotes')}
          />
        </Grid>
      </Grid>
    </div>
  );
};

export default HabitsFields;
