/* eslint-disable new-cap */
import React, { useMemo, useState } from 'react';
import HeaderBreadcrumbs, { IHeadersAction } from '../../HeaderBreadcrumbs';
import { IVisitWithProgramTitle } from '../../../layouts/reports/ReportLayout';
import { IPatient, IVisit } from '../../../interfaces/Models';
import {
  checkEmployeeReportAuthorization,
  getReportMode,
  getVisitType,
  onDownloadPdf,
} from '../../../utils/Helper';
import { ApiResponse, IAdditionalData } from '../../../interfaces/ApiResponseType';
import { VISIT_STATUS } from '../../../common/Constants';
import { useTranslation } from 'react-i18next';
import DownloadIcon from '@mui/icons-material/Download';
import EditIcon from '@mui/icons-material/Edit';
import ReportClinicStatusAction from '../../../sections/report/ReportClinicStatusAction';
import { Icon } from '@iconify/react';
import FetcherService from '../../../hooks/FetcherService';
import { useParams } from 'react-router-dom';
import { getReportName } from '../../../common/ReportsCommon';
import ReportService from '../../../services/ReportService';
import EditVisitDueDateForm from '../../../pages/visits/EditVisitDueDateForm';
import { getShowGeneralReportHeaderAction } from './report';
import ReportAttachmentsView from './ReportAttachmentsView';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { useRecoilValue } from 'recoil';
import { visitAttachmentsAtom } from '../../../atoms/visitAttachmentsAtom';
import EditVisitReferenceNumberForm from '../../../pages/visits/EditVisitReferenceNumberForm';
import { visitAtom } from '../../../atoms/visitAtom';
import { checkIfVisitTypeRequireReferenceNumber } from '../../../hooks/isReportMode';

const getEditRoute = () => {
  return location.pathname.replace('view', 'edit');
};

interface Props {
  visitData: IVisitWithProgramTitle;
  additionalData: IAdditionalData;
  patient: IPatient;
  handlePageUpdate: () => void;
  handleLoadingReport: (isLoading: boolean) => void;
  adminAbility: boolean;
  editVisitDueDateAbility: boolean;
  isViewMode: boolean;
}
export default function ReportLayoutHeader({
  visitData,
  additionalData,
  handlePageUpdate,
  handleLoadingReport,
  patient,
  adminAbility,
  editVisitDueDateAbility,
  isViewMode,
}: Props) {
  const { t } = useTranslation();
  const { visitId } = useParams();
  const currentVisit = useRecoilValue(visitAtom);
  const visitAttachments = useRecoilValue(visitAttachmentsAtom);
  const [openChangeVisitDueDate, setOpenChangeVisitDueDate] = useState<boolean>(false);
  const [openAttachmentsView, setOpenAttachmentsView] = useState<boolean>(false);
  const [openChangeClinicReferenceNumber, setOpenChangeClinicReferenceNumber] =
    useState<boolean>(false);

  const downloadReport = async () => {
    const onSuccess = (res: ApiResponse<BlobPart>) => {
      onDownloadPdf(res, getReportName(visitData));
    };
    await FetcherService<any>({
      service: ReportService.generateReportPdf(visitId || 'empty'),
      onSuccess: onSuccess,
      setLoading: handleLoadingReport,
    });
  };

  const handleOpenAttachmentsView = () => setOpenAttachmentsView(true);

  const handleCloseAttachmentsView = () => setOpenAttachmentsView(false);

  const handleOpenChangeVisitDueDateModal = () => setOpenChangeVisitDueDate(true);

  const handleOpenChangeClinicReferenceNumberModal = () => setOpenChangeClinicReferenceNumber(true);

  const handleCloseModals = () => {
    setOpenChangeVisitDueDate(false);
    setOpenChangeClinicReferenceNumber(false);
  };

  const getEditVisitDueDateAction = (): IHeadersAction<IVisit> => {
    return {
      name: 'editDueDate',
      tooltipTitle: t('program.hospitalDaysForm.editDueDate'),
      type: 'ActionTooltip',
      icon: <Icon icon="ic:baseline-update" width={24} height={24} />,
      color: 'secondary',
      isVisible: editVisitDueDateAbility,
      onClick: handleOpenChangeVisitDueDateModal,
    };
  };

  const isShowReferenceNumberAction = useMemo(() => {
    return (
      visitData.isLeumitVisit &&
      visitData.isVisitLevelPayment &&
      adminAbility &&
      checkIfVisitTypeRequireReferenceNumber(visitData)
    );
  }, [visitData]);

  const getActions = (): IHeadersAction<IVisit>[] => {
    const isVisitCanBeDownloaded =
      visitData.status === VISIT_STATUS.DONE ||
      visitData.status === VISIT_STATUS.SENT_TO_CLINIC ||
      visitData.isDraft;

    const actions: IHeadersAction<IVisit>[] = [
      {
        name: 'clinicReferenceNumber,',
        tooltipTitle: t('clinicReferenceNumber'),
        type: 'ActionTooltip',
        icon: <Icon icon="zondicons:book-reference" width={20} height={20} />,
        color: 'secondary',
        onClick: handleOpenChangeClinicReferenceNumberModal,
        isVisible: isShowReferenceNumberAction,
      },
      {
        name: 'attachment',
        tooltipTitle: t('global.attachments.header'),
        type: 'ActionTooltip',
        icon: <VisibilityIcon />,
        color: 'secondary',
        onClick: handleOpenAttachmentsView,
        isVisible: visitAttachments?.length > 0,
      },
      ...getShowGeneralReportHeaderAction({ t, visitData, additionalData }),
      {
        name: 'download',
        tooltipTitle: t('global.reportActions.downloadReport'),
        type: 'ActionTooltip',
        icon: <DownloadIcon />,
        color: 'secondary',
        onClick: downloadReport,
        record: visitData,
        isVisible: isVisitCanBeDownloaded,
      },
      getEditVisitDueDateAction(),
    ];
    if (isViewMode) {
      actions.push({
        name: 'edit',
        tooltipTitle: t('global.reportModes.edit'),
        href: getEditRoute(),
        type: 'LinkIcon',
        icon: <EditIcon />,
        color: 'secondary',
        isVisible:
          !!checkEmployeeReportAuthorization(visitData) &&
          (visitData.status == VISIT_STATUS.DONE || visitData.visitType.includes('GENERAL')),
      });
    }
    if (visitData.status == VISIT_STATUS.SENT_TO_CLINIC) {
      actions.push({
        name: 'Back To Done',
        type: 'Component',
        component: (
          <ReportClinicStatusAction
            handlePageUpdate={handlePageUpdate}
            adminAbility={adminAbility}
            visit={visitData}
          />
        ),
      });
    }

    actions.push({
      type: 'goBack',
    } as IHeadersAction<IVisit>);
    return actions;
  };

  const actions = getActions();

  return (
    <>
      {openAttachmentsView && (
        <ReportAttachmentsView handleCloseModal={handleCloseAttachmentsView} />
      )}
      <EditVisitReferenceNumberForm
        record={currentVisit}
        openChangeClinicReferenceNumber={openChangeClinicReferenceNumber}
        handleCloseModal={handleCloseModals}
      />
      <EditVisitDueDateForm
        record={visitData}
        openChangeVisitDueDate={openChangeVisitDueDate}
        handleCloseChangeVisitDueDateModal={handleCloseModals}
        handlePageUpdate={handlePageUpdate}
      />
      <HeaderBreadcrumbs
        heading={t(`global.reportModes.${getReportMode()}`)}
        links={[
          { name: visitData.programTitle },
          {
            name: `${patient?.personalDetails?.firstName} ${patient?.personalDetails?.lastName}`,
          },
          {
            name: t(`global.positions.${visitData?.visitType?.slice(0, 3)}`),
          },
          {
            name: t(`global.visitTypes.${getVisitType(visitData)}`),
          },
        ]}
        actions={actions}
      />
    </>
  );
}
