import React from 'react';
import { Chip, Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { IStaff, IUserFlat } from '../../../../interfaces/Models';
import { styled } from '@mui/system';
import { theme } from '../../../../theme';
import { getEmployeeName } from '../../../../utils/Helper';

const StyledGrid = styled(Grid)({
  marginBottom: theme.spacing(4),
});

const StyledGrid1 = styled(Grid)({
  paddingTop: theme.spacing(1),
  paddingBottom: theme.spacing(1),
  paddingLeft: theme.spacing(1),
  paddingRight: theme.spacing(1),
});

const StyledChip = styled(Chip)({
  margin: theme.spacing(1),
});

interface StaffViewProps {
  staffs: IStaff[];
  employees: IUserFlat[];
  handleDelete: (value: IStaff) => void;
}
const StaffView = ({ staffs, employees, handleDelete }: StaffViewProps) => {
  const { t } = useTranslation();

  return (
    <StyledGrid container justifyContent="center" spacing={0}>
      <StyledGrid1 item xs={10} md={10} lg={10}>
        {staffs.map((staffMember: IStaff) => {
          return (
            <StyledChip
              key={staffMember.employee + staffMember.position}
              label={`${getEmployeeName(staffMember.employee, employees)}  ${t(
                `global.positions.${staffMember.position}`
              )}`}
              color="info"
              onDelete={() => handleDelete(staffMember)}
            />
          );
        })}
      </StyledGrid1>
    </StyledGrid>
  );
};

export default StaffView;
