import React from 'react';
import { Field } from 'formik';

import { TextField, Switch } from 'formik-mui';
import { useTranslation } from 'react-i18next';
import { FormControl, InputLabel } from '@mui/material';

const ClinicFormFields: React.FC = () => {
  const { t } = useTranslation();

  return (
    <>
      <FormControl fullWidth sx={{ marginTop: 2 }}>
        <Field
          fullWidth
          component={TextField}
          type="text"
          label={t('clinic.table.clinicName')}
          name="name"
          disabled
        />
      </FormControl>
      <FormControl fullWidth sx={{ marginTop: 2 }}>
        <Field
          fullWidth
          component={TextField}
          type="text"
          label={t('clinic.table.clinicUsername')}
          name="username"
        />
      </FormControl>
      <FormControl fullWidth sx={{ marginTop: 2 }}>
        <Field
          fullWidth
          component={TextField}
          type="password"
          label={t('clinic.table.password')}
          name="password"
        />
      </FormControl>
      <FormControl fullWidth sx={{ marginTop: 2 }}>
        <Field
          fullWidth
          component={TextField}
          type="text"
          label={t('clinic.table.vaultLink')}
          name="vaultLink"
        />
      </FormControl>
      <FormControl fullWidth sx={{ marginTop: 3 }}>
        <InputLabel shrink={true} htmlFor="tags">
          {t('clinic.table.formateStatus')}
        </InputLabel>
        <Field component={Switch} name="isFormatNeeded" type="checkbox" />
      </FormControl>
    </>
  );
};

export default ClinicFormFields;
