import React from 'react';
import SectionDivider from '../../../../components/SectionDivider';
import GenericField from '../../sharedFields/GenericField';

interface HMKRepeatedFieldsProps {
  id: string;
}
const HMKRepeatedFields = ({ id }: HMKRepeatedFieldsProps) => {
  return (
    <>
      <GenericField
        id={`${id}_oralTest`}
        fields={[
          {
            name: 'data.medicalCondition',
            label: 'report.HMKREPEATED.medicalCondition',
            type: 'textareaWithEditor',
          },
          {
            name: 'data.therapeuticIntervention',
            label: 'report.HMKREPEATED.therapeuticIntervention',
            type: 'textareaWithEditor',
          },
          {
            name: 'data.treatmentPlan',
            label: 'report.HMKREPEATED.treatmentPlan',
            type: 'textareaWithEditor',
          },
        ]}
      />
      <SectionDivider />
    </>
  );
};

export default HMKRepeatedFields;
