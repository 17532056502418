/* eslint-disable new-cap */
import { Card, Stack } from '@mui/material';
import React from 'react';
import { LoadingButton } from '@mui/lab';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import FetcherService from '../../../hooks/FetcherService';
import { IResetPasswordCredentials, IUser } from '../../../interfaces/Models';
import UserService from '../../../services/UserService';
import { Field, Form, Formik } from 'formik';
import { StyledTextField } from '../../../styles/FormFieldsStyles';
import { toast } from 'react-toastify';
import PasswordField from '../PasswordField';
import { STRONG_PASSWORD_REGEX } from '../../../common/Constants';

const ProfileChangePassword = () => {
  const { t } = useTranslation();

  const passwordSchema = Yup.object({
    oldPassword: Yup.string()
      .min(6, t('user.form.passwordValidation'))
      .required(t('clinic.form.required')),
    newPassword: Yup.string()
      .required(t('clinic.form.required'))
      .matches(STRONG_PASSWORD_REGEX, t('user.form.strongPasswordValidation')),
    confirmNewPassword: Yup.string().oneOf(
      [Yup.ref('newPassword'), null],
      t('user.form.matchPasswords')
    ),
  });

  const handleResetPassword = async (
    values: IResetPasswordCredentials,
    setSubmitting: (value: boolean) => void,
    resetForm: (value: Object) => void
  ): Promise<void> => {
    try {
      await FetcherService<IUser>({
        service: UserService.resetPassword(values),
        showNotification: false,
      });
      resetForm({ values: '' });
      setSubmitting(false);
      toast.success(t('global.profile.passwordChangeSuccess'));
    } catch {
      resetForm({ values: '' });
      setSubmitting(false);
      toast.error(t('global.errors.wrongOldPassword'));
    }
  };

  return (
    <Card sx={{ p: 7 }}>
      <Stack spacing={3}>
        <Formik
          initialValues={{ oldPassword: '', newPassword: '', confirmNewPassword: '' }}
          onSubmit={(values: IResetPasswordCredentials, { setSubmitting, resetForm }) => {
            handleResetPassword(values, setSubmitting, resetForm);
          }}
          validationSchema={passwordSchema}
        >
          {({ isSubmitting }) => {
            return (
              <Form>
                <PasswordField label={t('global.profile.oldPassword')} name="oldPassword" />

                <PasswordField label={t('global.profile.newPassword')} name="newPassword" />

                <Field
                  fullWidth
                  component={StyledTextField}
                  type="password"
                  label={t('global.profile.confirmNewPassword')}
                  name="confirmNewPassword"
                />
                <Stack direction="row" justifyContent="flex-end">
                  <LoadingButton type="submit" variant="contained" loading={isSubmitting}>
                    {t('global.profile.saveChanges')}
                  </LoadingButton>
                </Stack>
              </Form>
            );
          }}
        </Formik>
      </Stack>
    </Card>
  );
};

export default ProfileChangePassword;
