import { IconButton, Tooltip } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import Iconify from '../../components/Iconify';

interface IDownloadReportTooltip {
  onDownloadReport: () => void;
}
const DownloadButtonWithTooltip = ({ onDownloadReport }: IDownloadReportTooltip) => {
  const { t } = useTranslation();
  return (
    <Tooltip title={`${t('financialReports.downloadReport')}`}>
      <IconButton onClick={onDownloadReport} color="primary">
        <Iconify icon="file-icons:microsoft-excel" />
      </IconButton>
    </Tooltip>
  );
};

export default DownloadButtonWithTooltip;
