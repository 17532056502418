import { Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import React from 'react';
import WidgetContainer from '../../components/WidgetContainer';
import MyVisitTable from '../visits/MyVisitTable';
import SummariesWidgets from './dashboardWidgets/SummariesWidgets';
import { useRecoilValue } from 'recoil';
import { userAtom } from '../../atoms/userAtom';
import EmployeeVisitsReportsWidgets from './dashboardWidgets/EmployeeVisitsReportsWidgets';
import ProgramsList from '../program/ProgramsList';
import { PATIENT_PROGRAM_STATUS } from '../../common/Constants';

const EmployeeDashboard: React.FC = () => {
  const { t } = useTranslation();
  const user = useRecoilValue(userAtom);

  if (!user?._id) {
    return null;
  }

  return (
    <>
      <Grid item xs={12} md={12} lg={12}>
        <SummariesWidgets employeeId={user?._id} />
      </Grid>
      <Grid item xs={12} md={8} lg={8}>
        <WidgetContainer title={t('drawer.sidebar.visits')}>
          <MyVisitTable initialSortValue={'dueDate'} pageSizes={[5]} />
        </WidgetContainer>
      </Grid>
      <Grid item xs={12} md={4}>
        <WidgetContainer title={t('global.dashboard.lastPrograms')}>
          <ProgramsList status={PATIENT_PROGRAM_STATUS.ACTIVE} sortField="-createdAt" />
        </WidgetContainer>
      </Grid>
      <Grid item xs={12}>
        <EmployeeVisitsReportsWidgets employee={user._id} />
      </Grid>
    </>
  );
};

export default EmployeeDashboard;
