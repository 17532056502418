import { IPatient, IPatientFlat } from '../interfaces/Models';
import { IQuery, IService } from '../interfaces/index';
import { HTTP_METHODS } from '../common/APIHelper';

const getAll = (query?: IQuery): IService => {
  return { route: '/api/patient', method: HTTP_METHODS.GET, query };
};

const getAllByEmployee = (query?: IQuery): IService => {
  return { route: '/api/patient/patients-by-employee', method: HTTP_METHODS.GET, query };
};

const getOne = (id: string): IService => {
  return { route: `/api/patient/${id}`, method: HTTP_METHODS.GET };
};

const add = (payload: IPatient): IService => {
  return { route: '/api/patient', method: HTTP_METHODS.POST, payload };
};

const edit = (id: string, payload: IPatientFlat | IPatient): IService => {
  return { route: `/api/patient/${id}`, method: HTTP_METHODS.PUT, payload };
};

const PatientService = {
  add,
  getAll,
  getAllByEmployee,
  getOne,
  edit,
};

export default PatientService;
