import { Box, Card, Skeleton, Typography } from '@mui/material';
import React from 'react';

const CounterWidgetSkeleton = () => {
  return (
    <>
      <Card sx={{ display: 'flex', alignItems: 'center', p: 0.5, minHeight: 100 }}>
        <Box sx={{ flexGrow: 1, mt: 0 }} height="100%">
          <Skeleton width="80%">
            <Typography>.</Typography>
          </Skeleton>

          <Skeleton width="30%">
            <Typography>.</Typography>
          </Skeleton>
        </Box>
        <Skeleton variant="rectangular" width="25%">
          <div style={{ width: '100%', padding: '60%' }} />
        </Skeleton>
      </Card>
    </>
  );
};

export default CounterWidgetSkeleton;
