import React from 'react';
import { IHospitalDays, IPatientProgram, IVisit } from '../../../interfaces/Models';
import { getProgramVisitsEvents } from '../../../utils/Helper';
import MultiMonthCalendar from '../../../components/MultiMonthCalendar';

import { styled } from '@mui/material/styles';
import { theme } from '../../../theme';
import { useTranslation } from 'react-i18next';
import ProgramScreenshotInfoCard from './ProgramScreenshotInfoCard';

const RootStyle = styled('div')({
  padding: theme.spacing(4),
  minHeight: '100%',
  width: '100%',
});

const getCurrentMonthStartDate = () => {
  const currentDate = new Date();
  const startDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
  return startDate;
};
// function to get dates from current month and above
const getActiveMonthsVisits = (visits: IVisit[]) => {
  const startDate = getCurrentMonthStartDate();
  const activeMonthsVisits = visits.filter((visit) => {
    const visitDueDate = new Date(visit.dueDate);
    return visitDueDate.getTime() >= startDate.getTime();
  });
  return activeMonthsVisits;
};

// function to get dates from current month and above
const getActiveMonthsHospitalDays = (hospitalDays: IHospitalDays[]) => {
  const startDate = getCurrentMonthStartDate();
  const activeMonthsVisits = hospitalDays.filter((period) => {
    const periodStart = new Date(period.start);
    return periodStart.getTime() >= startDate.getTime();
  });
  return activeMonthsVisits;
};

// function get unique months const of array of dates
const getUniqueMonthsCount = (dates: Date[]) => {
  const uniqueMonths = new Set();
  dates.forEach((date) => {
    uniqueMonths.add(date.getMonth());
  });
  return Array.from(uniqueMonths).length;
};

export const sortVisits = (visits: IVisit[]) => {
  return visits.sort((visit1, visit2) => (visit1.dueDate > visit2.dueDate ? 1 : -1));
};

export default function CalendarScreenshot({
  program,
  isEdit,
}: {
  program: IPatientProgram;
  isEdit: boolean;
}) {
  const { t } = useTranslation();
  const { visits, hospitalDays } = program;
  const sortedVisits: IVisit[] = sortVisits(visits);

  const filteredVisits = isEdit ? getActiveMonthsVisits(sortedVisits) : sortedVisits;
  const filteredHospitalDays = isEdit
    ? getActiveMonthsHospitalDays(hospitalDays || [])
    : hospitalDays || [];
  const events = getProgramVisitsEvents(t, filteredVisits, filteredHospitalDays);

  const eventsUniqueMonthsCount = getUniqueMonthsCount(events.map((event) => new Date(event.date)));

  const duration = eventsUniqueMonthsCount;

  return (
    <RootStyle>
      <ProgramScreenshotInfoCard isEdit={isEdit} program={program} />
      <MultiMonthCalendar
        initialDate={filteredVisits[0]?.dueDate}
        duration={duration}
        events={events}
      />
    </RootStyle>
  );
}
