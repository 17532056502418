import React, { useMemo } from 'react';
import ErrorMessage from '../../components/ErrorMessage';
import LoadingScreen from '../../components/LoadingScreen';
import useService from '../../hooks/HookService';
import { IClinic, IPatient, IPatientProgram, IUserFlat } from '../../interfaces/Models';
import UserService from '../../services/UserService';
import ProgramFields from './ProgramFields';

interface ProgramFormProps {
  patient?: IPatient;
  program?: IPatientProgram;
}

const ProgramForm = ({ patient, program }: ProgramFormProps) => {
  const { isLoading, data, error, errorMessage } = useService<IUserFlat[]>({
    service: UserService.getAll({ page: 0, limit: 1000 }),
    deps: [],
  });

  const clinic: IClinic = useMemo(() => {
    const editMode = !!program;

    const programClinic =
      editMode && typeof program.patient !== 'string' ? program.patient?.clinic : patient?.clinic;
    return programClinic;
  }, [data]);

  if (isLoading) {
    return <LoadingScreen />;
  }
  if (error || !data) {
    return <ErrorMessage error={errorMessage} />;
  }

  return <ProgramFields clinic={clinic} patient={patient} program={program} employees={data} />;
};

export default ProgramForm;
