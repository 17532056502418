import { VISIT_STATUS } from '../common/Constants';
import { IHospitalDays, IVisit } from '../interfaces/Models';
import { reportTypes } from '../common/Constants';
import { format } from 'date-fns';

export const checkIfVisitIsGeneralOrFirst = (visit: IVisit) => {
  return (
    visit.visitType == reportTypes.HMB_GENERAL ||
    visit.visitType == reportTypes.HMP_GENERAL ||
    visit.visitType == reportTypes.HMP_TABLE_FIRST ||
    visit.isFirst
  );
};

const checkIfVisitCanBeCanceled = (visit: IVisit) => {
  return !checkIfVisitIsGeneralOrFirst(visit) && visit.status == VISIT_STATUS.PENDING;
};

const checkIfVisitCanBeEdited = (visit: IVisit) => {
  return (
    visit.status == VISIT_STATUS.DONE ||
    visit.status == VISIT_STATUS.SENT_TO_CLINIC ||
    checkIfVisitIsGeneralOrFirst(visit)
  );
};

export const getVisitsActionsFromHospitalDays = (
  programVisits: IVisit[],
  hospitalDays?: IHospitalDays
) => {
  const visitsToBeCanceled: IVisit[] = [];
  const visitsToBeEdited: IVisit[] = [];

  programVisits.map((visit: IVisit) => {
    const today = new Date();
    // visit to be canceled if its status is pending and the visit is not general or first
    const isVisitToBeCanceled = checkIfVisitCanBeCanceled(visit);
    // visit to be edited if it is general or first  or its status is done or sent to clinic
    const isVisitToBeEdited = checkIfVisitCanBeEdited(visit);

    const visitDueDate = new Date(visit.dueDate).setHours(0, 0, 0, 0);
    if (hospitalDays) {
      const periodStart = new Date(hospitalDays.start || today).setHours(0, 0, 0, 0);
      const periodEnd = new Date(hospitalDays.end || today).setHours(0, 0, 0, 0);
      if (visitDueDate >= periodStart && visitDueDate <= periodEnd) {
        if (isVisitToBeEdited) {
          visitsToBeEdited.push(visit);
        }
        if (isVisitToBeCanceled) {
          visitsToBeCanceled.push(visit);
        }
      }
    }
  });

  return { visitsToBeCanceled, visitsToBeEdited };
};

export const getDatesInRange = (startDate: Date, endDate: Date) => {
  const date = new Date(startDate.getTime());

  const dates = [];

  while (date <= endDate) {
    dates.push(format(new Date(date), 'dd-MM-yyyy'));
    date.setDate(date.getDate() + 1);
  }

  return dates;
};

export const validateHospitalDays = (
  newHospitalDaysPeriod: IHospitalDays,
  programHospitalDays?: IHospitalDays[]
) => {
  const today = new Date();
  let isHospitalDaysValid = true;

  if (programHospitalDays) {
    const newPeriodStart = new Date(
      new Date(newHospitalDaysPeriod.start || today).setHours(0, 0, 0, 0)
    ).getTime();
    const newPeriodEnd = new Date(
      new Date(newHospitalDaysPeriod.end || today).setHours(0, 0, 0, 0)
    ).getTime();

    programHospitalDays.forEach((period: IHospitalDays) => {
      const periodStart = new Date(new Date(period.start).setHours(0, 0, 0, 0)).getTime();
      const periodEnd = new Date(new Date(period.end).setHours(0, 0, 0, 0)).getTime();

      if (
        period._id != newHospitalDaysPeriod._id &&
        ((newPeriodStart >= periodStart && newPeriodEnd <= periodEnd) ||
          (newPeriodStart < periodStart && newPeriodEnd > periodEnd))
      ) {
        isHospitalDaysValid = false;
      }
    });
  }

  return isHospitalDaysValid;
};
