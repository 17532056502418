import React, { useEffect } from 'react';
import MonthPicker from './MonthPicker';
import { useTranslation } from 'react-i18next';
import { DateRange } from '@mui/lab';
import { RangeFieldsContainer } from './DateRangeFilter';
import { useLocation } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import { autoOpenSpecificMonthPickerAtom } from '../atoms/autoOpenSpecificMonthPickerAtom';

interface Props {
  newDateRange: DateRange<Date>;
  onChangeCustomMonth: (value: Date | null) => void;
}
export default function DateFilterSpecificMonthPicker({
  newDateRange,
  onChangeCustomMonth,
}: Props) {
  const { t } = useTranslation();
  const location = useLocation();
  const [autoOpenSpecificMonthPicker, setAutoOpenSpecificMonthPicker] = useRecoilState(
    autoOpenSpecificMonthPickerAtom
  );

  useEffect(() => {
    setAutoOpenSpecificMonthPicker(false);
  }, [location]);

  return (
    <RangeFieldsContainer>
      <MonthPicker
        autoOpen={autoOpenSpecificMonthPicker}
        handleChange={onChangeCustomMonth}
        value={newDateRange[0]}
        label={t('month')}
        sx={{ mt: 2, maxWidth: 160 }}
      />
    </RangeFieldsContainer>
  );
}
