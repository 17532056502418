import React from 'react';
import { useTranslation } from 'react-i18next';
import { getVisitsFinancialSummaryChartLabels } from '../../../common/Charts';
import BarChart from '../../../components/BarChart';
import ErrorMessage from '../../../components/ErrorMessage';
import PiChartWidgetSkeleton from '../../../components/skeletons/PiChartWidgetSkeleton';
import { IServiceResponse } from '../../../hooks/HookService';
import { IEmployeesFinancialReports } from '../../../interfaces/Reports';

const VisitAmountsReportSummaryWidget = ({
  data,
  error,
  errorMessage,
  isLoading,
}: IServiceResponse<IEmployeesFinancialReports>) => {
  const { t } = useTranslation();
  if (isLoading) {
    return <PiChartWidgetSkeleton />;
  }
  if (error || !data) {
    return <ErrorMessage error={errorMessage} />;
  }

  const visitsAmountsData = [
    {
      name: t('financialReports.employees.totalAmount'),
      data: [
        data?.totalPendingVisitsCost || 0,
        data?.totalDoneVisitsCost || 0,
        data?.totalCost || 0,
      ],
    },
  ];

  const shekelTooltipFormatter = (val: number) => {
    return val + t('global.currencyUnits.nis');
  };

  return (
    <BarChart
      title={t('global.dashboard.visitsAmountSummary')}
      chartCategories={getVisitsFinancialSummaryChartLabels(t)}
      chartData={visitsAmountsData}
      yTooltipFormatter={shekelTooltipFormatter}
    />
  );
};

export default VisitAmountsReportSummaryWidget;
