/* eslint-disable new-cap */
import { Container } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import ErrorMessage from '../../components/ErrorMessage';
import Page from '../../components/Page';
import useService from '../../hooks/HookService';
import { IVisit } from '../../interfaces/Models';
import { Outlet } from 'react-router-dom';
import VisitService from '../../services/VisitService';
import OverlaySpinner from '../../components/OverlaySpinner';
import { useSetRecoilState } from 'recoil';
import { visitAtom } from '../../atoms/visitAtom';
import isReportMode from '../../hooks/isReportMode';
import ReportLayoutHeaderContainer from '../../components/layouts/report/ReportLayoutHeaderContainer';
import ReportLayoutCard from '../../components/layouts/report/ReportLayoutCard';

export interface IVisitWithProgramTitle extends IVisit {
  programTitle: string;
  isVisitLevelPayment?: boolean;
  isLeumitVisit?: boolean;
}

const ReportLayout: React.FC = () => {
  const { t } = useTranslation();
  const { visitId } = useParams();
  const location = useLocation();
  const [isLoadingReport, setIsLoadingReport] = useState<boolean>(false);
  const [shouldPageUpdate, setShouldPageUpdate] = useState<number>(0);
  const setCurrentVisit = useSetRecoilState(visitAtom);

  const isViewMode = isReportMode('view');
  const isEditMode = isReportMode('edit');

  const {
    isLoading,
    data: visitData,
    additionalData,
    error,
    errorMessage,
  } = useService<IVisitWithProgramTitle>({
    service: VisitService.getOne(visitId || 'empty'),
    deps: [location.pathname, shouldPageUpdate],
  });

  useEffect(() => {
    setCurrentVisit(visitData);
  }, [visitData]);

  const handlePageUpdate = () => {
    setShouldPageUpdate((pre) => pre + 1);
  };
  if (isLoading) {
    return <OverlaySpinner />;
  }
  if (error || !visitData || typeof visitData.patient === 'string') {
    return <ErrorMessage error={errorMessage} />;
  }

  return (
    <Page title={t('global.pages.report')}>
      <Container>
        <ReportLayoutHeaderContainer
          visitData={visitData}
          additionalData={additionalData}
          handlePageUpdate={handlePageUpdate}
          handleLoadingReport={setIsLoadingReport}
          patient={visitData.patient}
        />
        <ReportLayoutCard
          Outlet={Outlet}
          visitData={visitData}
          isEditMode={isEditMode}
          isViewMode={isViewMode}
        />
        {isLoadingReport && <OverlaySpinner />}
      </Container>
    </Page>
  );
};

export default ReportLayout;
