import React from 'react';
import { useTranslation } from 'react-i18next';
import Page from '../../components/Page';
import { RootStyle } from './ForgotPassword';
import LogoOnlyLayout from '../../layouts/LogoOnlyLayout';
import { Box, Button, Container, Typography } from '@mui/material';
import { Link as RouterLink, useParams } from 'react-router-dom';
import useService from '../../hooks/HookService';
import UserService from '../../services/UserService';
import LoadingScreen from '../../components/LoadingScreen';
import SentIcon from '../../assets/icon_sent';

const ResetPassword = () => {
  const { t } = useTranslation();
  const { token } = useParams();

  const { isLoading, error } = useService<string>({
    service: UserService.restPasswordByToken(token || 'empty'),
    deps: [],
  });

  return (
    <Page title={t('global.resetPassword.title')} sx={{ height: 1 }}>
      <RootStyle>
        <LogoOnlyLayout />

        <Container>
          <Box sx={{ maxWidth: 480, mx: 'auto' }}>
            <Box sx={{ textAlign: 'center' }}>
              {isLoading ? (
                <LoadingScreen />
              ) : error ? (
                <Typography variant="h4" color="error">
                  {t('global.errors.resetPasswordError')}
                </Typography>
              ) : (
                <>
                  <SentIcon sx={{ mb: 5, mx: 'auto', height: 160 }} />

                  <Typography variant="h3" gutterBottom>
                    {t('global.resetPassword.resetSuccess')}
                  </Typography>
                  <Typography>
                    {t('global.resetPassword.sentNewPassword')}
                    <br />
                    {t('global.resetPassword.checkEmail')}
                  </Typography>

                  <Button
                    size="large"
                    variant="contained"
                    component={RouterLink}
                    to="/login"
                    sx={{ mt: 5 }}
                  >
                    {t('global.resetPassword.back')}
                  </Button>
                </>
              )}
            </Box>
          </Box>
        </Container>
      </RootStyle>
    </Page>
  );
};

export default ResetPassword;
