import React from 'react';
import ErrorMessage from '../../components/ErrorMessage';
import useService from '../../hooks/HookService';
import { IPatient } from '../../interfaces/Models';
import PatientService from '../../services/PatientService';
import { useParams } from 'react-router-dom';
import ProgramForm from './ProgramForm';
import PageHeader from '../../components/PageHeader';
import { Container } from '@mui/material';
import Page from '../../components/Page';
import { useTranslation } from 'react-i18next';
import { AbilitySubjects } from '../../casl/ability';
import useAccess from '../../hooks/useAccess';
import OverlaySpinner from '../../components/OverlaySpinner';

const NewProgram = () => {
  const { t } = useTranslation();
  const { patientId } = useParams();
  useAccess('create', AbilitySubjects.ADMIN_ABILITY);

  const { isLoading, data, error, errorMessage } = useService<IPatient>({
    service: PatientService.getOne(patientId || ''),
    deps: [],
  });

  if (isLoading) {
    return <OverlaySpinner />;
  }
  if (error || !data) {
    return <ErrorMessage error={errorMessage} />;
  }
  return (
    <Page title={t('patient.mainView.program')}>
      <Container>
        <PageHeader pageTitle={t('patient.mainView.program')} mb={2} />
        <ProgramForm patient={data} />
      </Container>
    </Page>
  );
};

export default NewProgram;
