import {
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
} from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { ValueOf } from '../../../interfaces';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import i18n from 'i18next';
import { he, enUS } from 'date-fns/locale';
import { LoadingButton } from '@mui/lab';
import { theme } from '../../../theme';
import RtlDatePicker from '../../../components/RtlDatePicker';
import { toast } from 'react-toastify';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

export const LEUMIT_REPORT_TYPES = {
  MISSING_PAYMENT_METHOD: 'MISSING_PAYMENT_METHOD',
  MISSING_PROGRAMS_REFERENCE_NUMBER: 'MISSING_PROGRAMS_REFERENCE_NUMBER',
  MISSING_VISITS_REFERENCE_NUMBER: 'MISSING_VISITS_REFERENCE_NUMBER',
};

export type LeumitReportType = ValueOf<typeof LEUMIT_REPORT_TYPES>;

export interface ILeumitMissingReferenceNumberPayload {
  billingDate: Date;
}

interface Props extends ILeumitMissingReferenceNumberPayload {
  reportType?: LeumitReportType;
  billingDate: Date;
  isLoading: boolean;
  handleReportBillingDate: (value: Date) => void;
  handleReportTypeChange: (value: LeumitReportType, date: Date) => void;
  totalRecords: number;
  lastTableAction: string;
}

export default function MissingLeumitRecordsTableHeader({
  billingDate,
  reportType,
  isLoading,
  handleReportTypeChange,
  handleReportBillingDate,
  totalRecords,
  lastTableAction,
}: Props) {
  const { t } = useTranslation();

  const handleSelectReportType = async (event: SelectChangeEvent<string>) => {
    const value = event.target.value as LeumitReportType;
    await handleReportTypeChange(value, billingDate);
  };

  const handleCopyLastAction = async () => {
    if (!lastTableAction) return;
    let copiedValue: string | number = lastTableAction;
    if (!isNaN(parseInt(lastTableAction))) {
      copiedValue = Number(lastTableAction) + 1;
    }
    navigator.clipboard.writeText(copiedValue.toString());
    toast.success(t('copiedToClipboard'));
  };
  return (
    <Grid container spacing={2} alignItems={'center'} justifyContent={'space-between'}>
      <Grid item xs={12} sm={4}>
        <LocalizationProvider
          locale={i18n.language == 'he' ? he : enUS}
          dateAdapter={AdapterDateFns}
        >
          <RtlDatePicker
            onChange={handleReportBillingDate}
            value={billingDate}
            inputFormat="MM/yyyy"
            views={['year', 'month']}
            openTo="month"
            label={t('report.billingDate')}
            renderInput={(inputProps: any) => (
              <TextField
                label={t('report.billingDate')}
                fullWidth
                {...inputProps}
                name={name}
                direction="ltr"
              />
            )}
          />
        </LocalizationProvider>
      </Grid>
      <Grid item xs={12} sm={4}>
        <FormControl fullWidth>
          <InputLabel id="demo-simple-select-label">
            {t('report.leumitReportTypesLabel')}
          </InputLabel>
          <Select
            value={reportType}
            label={t('report.leumitReportTypesLabel')}
            onChange={handleSelectReportType}
          >
            {Object.values(LEUMIT_REPORT_TYPES).map((type) => {
              return (
                <MenuItem key={type} value={type}>
                  {t(`report.leumitReportTypes.${type}`)}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={12} sm={2}>
        <LoadingButton
          sx={{ py: theme.spacing(3.4) }}
          size="large"
          fullWidth
          variant="outlined"
          loading={isLoading}
        >
          {totalRecords}
        </LoadingButton>
      </Grid>
      <Grid item xs={12} sm={2}>
        <Button
          onClick={handleCopyLastAction}
          sx={{ py: theme.spacing(3.4) }}
          size="large"
          fullWidth
          variant="outlined"
          startIcon={lastTableAction ? <ContentCopyIcon /> : null}
        >
          {lastTableAction}
        </Button>
      </Grid>
    </Grid>
  );
}
