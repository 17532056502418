import React, { useEffect, useState } from 'react';
import { Card, Container } from '@mui/material';
import Page from '../../components/Page';
import PageHeader from '../../components/PageHeader';
import { useTranslation } from 'react-i18next';
import { USER_ROLES, VISIT_STATUS } from '../../common/Constants';
import VisitsTable from './VisitsTable';
import useAccess from '../../hooks/useAccess';
import { AbilitySubjects } from '../../casl/ability';
import { userAtom } from '../../atoms/userAtom';
import { useRecoilValue } from 'recoil';
import LoadingScreen from '../../components/LoadingScreen';
const IS_ADMIN = 'IS_ADMIN';

const PlannedVisits = () => {
  const { t } = useTranslation();
  const user = useRecoilValue(userAtom);
  const [employee, setEmployee] = useState<string>();
  useAccess('read', AbilitySubjects.ALL);
  useEffect(() => {
    if (user?.role === USER_ROLES.EMPLOYEE && user?._id) {
      setEmployee(user?._id);
    } else {
      setEmployee(IS_ADMIN);
    }
  }, [user]);

  if (!user || !employee) {
    return <LoadingScreen />;
  }

  return (
    <Page title={t('drawer.sidebar.plannedVisits')}>
      <Container>
        <PageHeader pageTitle={t('drawer.sidebar.plannedVisits')} />
        <Card>
          {employee && employee !== IS_ADMIN ? (
            <VisitsTable status={VISIT_STATUS.PENDING} employee={user._id} showPageLoading={true} />
          ) : (
            <VisitsTable status={VISIT_STATUS.PENDING} showPageLoading={true} />
          )}
        </Card>
      </Container>
    </Page>
  );
};

export default PlannedVisits;
