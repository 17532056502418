import { LazyLoadImage } from 'react-lazy-load-image-component';
import { styled } from '@mui/material/styles';
import { alpha, Box, Typography } from '@mui/material';
import { theme } from '../theme';
import UserAvatar from './UserAvatar';
import { IUser } from '../interfaces/Models';
import { getUserAvatar } from '../utils/Helper';

// ----------------------------------------------------------------------
const getBgBlur = (props: any) => {
  const color = theme?.palette.background.default;

  const blur = props?.blur || 6;
  const opacity = props?.opacity || 0.8;

  return {
    backdropFilter: `blur(${blur}px)`,
    WebkitBackdropFilter: `blur(${blur}px)`,
    backgroundColor: alpha(color, opacity),
  };
};
const RootStyle = styled('div')(({ theme }) => ({
  '&:before': {
    bgBlur: getBgBlur({ blur: 2, color: theme.palette.primary }),
    top: 0,
    zIndex: 9,
    content: "''",
    width: '100%',
    height: '100%',
    position: 'absolute',
    background: `linear-gradient(to top, rgba(0, 0, 0, 0.55), transparent)`,
  },
}));

const InfoStyle = styled('div')(({ theme }) => ({
  left: 0,
  right: 0,
  zIndex: 99,
  position: 'absolute',
  marginTop: theme.spacing(5),
  [theme.breakpoints.up('md')]: {
    right: 'auto',
    display: 'flex',
    alignItems: 'center',
    left: theme.spacing(3),
    bottom: theme.spacing(3),
  },
}));

// ----------------------------------------------------------------------
interface IProfileCover {
  user: IUser | null;
}
export default function ProfileCover({ user }: IProfileCover) {
  return (
    <RootStyle>
      <InfoStyle>
        <UserAvatar
          source={getUserAvatar(
            user?.personalDetails.firstName || '',
            user?.personalDetails.lastName || ''
          )}
          alt={`${user?.personalDetails.firstName} ${user?.personalDetails.lastName}`}
          sx={{
            mx: 'auto',
            borderWidth: 2,
            borderStyle: 'solid',
            borderColor: 'common.white',
            width: { xs: 80, md: 128 },
            height: { xs: 80, md: 128 },
          }}
        />
        <Box
          sx={{
            ml: { md: 3 },
            mt: { xs: 1, md: 0 },
            color: 'common.white',
            textAlign: { xs: 'center', md: 'left' },
          }}
        >
          <Typography variant="h4">{`${user?.personalDetails.firstName} ${user?.personalDetails.lastName}`}</Typography>
        </Box>
      </InfoStyle>
      <Box
        component="span"
        sx={{
          lineHeight: 0,
          display: 'block',
          overflow: 'hidden',
          '& .wrapper': { width: 1, height: 1, backgroundSize: 'cover !important' },
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
        }}
      >
        <Box
          component={LazyLoadImage}
          wrapperClassName="wrapper"
          effect="blur"
          placeholderSrc="https://images.unsplash.com/photo-1528459801416-a9e53bbf4e17?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2000&h=280&q=80"
          sx={{ width: 1, height: 1, objectFit: 'cover' }}
        />
      </Box>
    </RootStyle>
  );
}
