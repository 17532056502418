/* eslint-disable new-cap */
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { IPatientProgram, ReportData } from '../../../interfaces/Models';
import FetcherService from '../../../hooks/FetcherService';
import PatientProgramService from '../../../services/PatientProgramService';
import FormModal from '../../../components/Modals/FormModal';
import { Box } from '@mui/material';
import { StyledTextField } from '../../../styles/FormFieldsStyles';
import Form from '../../../components/Form/FormikForm';
import { Field } from 'formik';

export interface IMissingProgramReferenceNumberForm {
  openModal: boolean;
  handleCloseModal: () => void;
  record?: IPatientProgram;
  handleProgramsUpdate: (val: IPatientProgram) => void;
  handleSaveLastActionValue?: (value: string) => void;
  billingDate?: Date;
}
const MissingProgramReferenceNumberForm = ({
  openModal,
  handleCloseModal,
  record,
  handleProgramsUpdate,
  handleSaveLastActionValue,
  billingDate,
}: IMissingProgramReferenceNumberForm) => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const schema = Yup.object({
    ref: Yup.number().required(`${t('clinic.form.required')}`),
  });

  const handleChangeProgramClinicNumber = async (values: ReportData) => {
    const refDate = billingDate || new Date();

    const value = {
      ref: values.ref,
      month: refDate.getMonth() + 1,
      year: refDate.getFullYear(),
    };

    await FetcherService<any>({
      service: PatientProgramService.addProgramReference(String(record?._id), value),
      setLoading: setIsLoading,
    });

    handleCloseModal();
    if (handleSaveLastActionValue) {
      handleSaveLastActionValue(values.ref);
    }
    if (record) {
      handleProgramsUpdate(record);
    }
  };

  return (
    <FormModal
      open={openModal}
      onClose={handleCloseModal}
      text={t('editClinicReferenceNumber')}
      loading={isLoading}
    >
      <Form
        onCancel={handleCloseModal}
        submit={handleChangeProgramClinicNumber}
        initialValues={{ clinicReferenceNumber: record?.clinicReferenceNumber }}
        validationSchema={schema}
        cancelText={t('clinic.form.cancel')}
        submitText={t('clinic.form.edit')}
      >
        <Box sx={{ my: 1, width: '100%' }}>
          <Field
            fullWidth
            component={StyledTextField}
            type="number"
            name={'ref'}
            label={t('clinicReferenceNumber')}
          />
        </Box>
      </Form>
    </FormModal>
  );
};

export default MissingProgramReferenceNumberForm;
