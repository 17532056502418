import React from 'react';
import HDDCommonFields from './HHDCommonFields';
import SectionDivider from '../../../../../components/SectionDivider';

const HHDRepeatedFields = ({ id, visitId }: { id: string; visitId: string }) => {
  return (
    <>
      <HDDCommonFields id={`${id}_title`} />
      <SectionDivider />
    </>
  );
};

export default HHDRepeatedFields;
