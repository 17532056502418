import { Card, Container } from '@mui/material';
import React from 'react';
import Page from '../../components/Page';
import PageHeader from '../../components/PageHeader';
import { useTranslation } from 'react-i18next';
import UserTable from './UserTable';
import useAccess from '../../hooks/useAccess';
import { AbilitySubjects } from '../../casl/ability';

const User: React.FC = () => {
  const { t } = useTranslation();

  useAccess('read', AbilitySubjects.ADMIN_ABILITY);

  return (
    <>
      <Page title={t('user.mainView.header')}>
        <Container>
          <PageHeader pageTitle={t('user.mainView.header')} />
          <Card>
            <UserTable />
          </Card>
        </Container>
      </Page>
    </>
  );
};

export default User;
