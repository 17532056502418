import React from 'react';
import TextField from '@mui/material/TextField';
import { DateRange } from '@mui/lab/DateRangePicker';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import Box from '@mui/material/Box';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { MobileDateRangePicker } from '@mui/lab';
import { he, enUS } from 'date-fns/locale';
import i18n, { t } from 'i18next';

interface IDateRangeInput {
  dateRange: DateRange<Date>;
  onChangeDateRange: (value: DateRange<Date>) => void;
}

const renderInputs = (startProps: any, endProps: any) => (
  <React.Fragment>
    <TextField sx={{ direction: 'ltr' }} {...endProps} size="small" />
    <Box sx={{ mx: 2 }}> {t('global.filters.to')} </Box>
    <TextField sx={{ direction: 'ltr' }} {...startProps} size="small" />
  </React.Fragment>
);
const DateRangeInput = ({ dateRange, onChangeDateRange }: IDateRangeInput) => {
  return (
    <LocalizationProvider locale={i18n.language == 'he' ? he : enUS} dateAdapter={AdapterDateFns}>
      <MobileDateRangePicker
        startText={`${t('global.filters.fromDate')}`}
        endText={`${t('global.filters.toDate')}`}
        value={dateRange}
        onChange={onChangeDateRange}
        components={{
          LeftArrowIcon: KeyboardArrowRightIcon,
          RightArrowIcon: KeyboardArrowLeftIcon,
        }}
        cancelText={t('clinic.form.cancel')}
        okText={t('global.filters.ok')}
        renderInput={renderInputs}
        inputFormat="dd/MM/yyyy"
      />
    </LocalizationProvider>
  );
};

export default DateRangeInput;
