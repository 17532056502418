import { Stack } from '@mui/material';
import { IPatientProgramFlat } from '../../interfaces/Models';
import Scrollbar from '../../components/Scrollbar';
import useService from '../../hooks/HookService';
import PatientProgramService from '../../services/PatientProgramService';
import ErrorMessage from '../../components/ErrorMessage';
import ProgramItem from '../../components/ProgramItem';
import { IRecordList } from '../../components/WidgetContainer';
import ActiveProgramsSkeleton from '../../components/skeletons/ActiveProgramsSkeleton';
import { theme } from '../../theme';
import { addDays, format } from 'date-fns';

const ProgramsList = ({ sortField, status }: IRecordList) => {
  const today = new Date();
  const fromDate = format(addDays(today, -14), 'MM-dd-yyyy');
  const toDate = format(addDays(today, 1), 'MM-dd-yyyy');

  const { isLoading, data, error, errorMessage } = useService<IPatientProgramFlat[]>({
    service: PatientProgramService.getAll({
      page: 0,
      limit: 10,
      sort: sortField,
      status: status,
      fromDate,
      toDate,
      dateFilterField: 'createdAt',
    }),
    deps: [],
  });

  if (isLoading) {
    return <ActiveProgramsSkeleton />;
  }
  if (error || !data) {
    return <ErrorMessage error={errorMessage} />;
  }
  return (
    <Scrollbar>
      <Stack spacing={1} sx={{ p: theme.spacing(3), pr: 0 }}>
        {data.map((program) => (
          <ProgramItem key={`${program.title} ${program._id}`} program={program} />
        ))}
      </Stack>
    </Scrollbar>
  );
};
export default ProgramsList;
