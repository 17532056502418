import { Field } from 'formik';
import React from 'react';
import { StyledTextField } from '../../../../styles/FormFieldsStyles';
import { useTranslation } from 'react-i18next';
import GenericField from '../../sharedFields/GenericField';

interface HMSFirstFieldsProps {
  id: string;
}
const HMSFirstFields = ({ id }: HMSFirstFieldsProps) => {
  const { t } = useTranslation();

  return (
    <>
      <GenericField
        id={`${id}_SocioeconomicBackground`}
        fields={[
          {
            name: 'data.socioeconomicBackground',
            label: 'report.HMSFIRST.socioeconomicBackground',
            type: 'textareaWithEditor',
          },
          {
            name: 'data.socialSituation',
            label: 'report.HMSFIRST.socialSituation',
            type: 'textareaWithEditor',
          },
          {
            name: 'data.cognitiveStatus',
            label: 'report.HMSFIRST.cognitiveStatus',
            type: 'textareaWithEditor',
          },
          {
            name: 'data.socialSecurity',
            label: 'report.HMSFIRST.socialSecurity',
            type: 'textareaWithEditor',
          },
          {
            name: 'data.patientExpectations',
            label: 'report.HMSFIRST.patientExpectations',
            type: 'textareaWithEditor',
          },
          {
            name: 'data.expectations',
            label: 'report.HMSFIRST.expectations',
            type: 'textareaWithEditor',
          },
          {
            name: 'data.guidelines',
            label: 'report.HMSFIRST.guidelines',
            type: 'textareaWithEditor',
          },
          {
            name: 'data.workerInterventions',
            label: 'report.HMSFIRST.workerInterventions',
            type: 'textareaWithEditor',
          },
        ]}
      />
    </>
  );
};

export default HMSFirstFields;
