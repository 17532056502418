import React, { useEffect, useState } from 'react';
import Page from '../../components/Page';
import { useTranslation } from 'react-i18next';
import { USER_ROLES } from '../../common/Constants';
import VisitsTable from './VisitsTable';
import { useRecoilValue } from 'recoil';
import { userAtom } from '../../atoms/userAtom';
import LoadingScreen from '../../components/LoadingScreen';

const IS_ADMIN = 'IS_ADMIN';

const AllVisits = () => {
  const { t } = useTranslation();
  const user = useRecoilValue(userAtom);
  const [employee, setEmployee] = useState<string>();

  useEffect(() => {
    if (user?.role === USER_ROLES.EMPLOYEE && user?._id) {
      setEmployee(user?._id);
    } else {
      setEmployee(IS_ADMIN);
    }
  }, [user]);

  if (!user || !employee) {
    return <LoadingScreen />;
  }

  const pageSizes = [30, 50, 100, 200, 300];
  return (
    <Page title={t('drawer.sidebar.allVisits')}>
      {employee && employee !== IS_ADMIN ? (
        <VisitsTable
          pageSizes={pageSizes}
          employee={user?._id}
          showPageLoading={true}
          showStatusFilter={true}
        />
      ) : (
        <VisitsTable pageSizes={pageSizes} showPageLoading={true} showStatusFilter={true} />
      )}
    </Page>
  );
};

export default AllVisits;
