import { Card, Container, Grid } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Row } from 'react-table';
import { AbilitySubjects } from '../../casl/ability';
import ErrorMessage from '../../components/ErrorMessage';
import OverlaySpinner from '../../components/OverlaySpinner';
import Page from '../../components/Page';
import PageHeader from '../../components/PageHeader';
import Table from '../../components/Table';
import useService from '../../hooks/HookService';
import useSearchFilter from '../../hooks/useSearchFilter';
import { IEmployeeFinancialReport, IEmployeesFinancialReports } from '../../interfaces/Reports';
import ReportsService from '../../services/FinancialReportsService';
import { StyledTableCell, TotalTableRow } from '../../styles/TableStyles';
import { theme } from '../../theme';
import { getSortDirection, getStartEndDateDep, handleTableAtomChange } from '../../utils/Helper';
import { ISheetColumn } from '../../utils/ExcelReportUtils';
import EmployeesFinancialReportsHeader from './EmployeesFinancialReportsHeader';
import FinancialSummary from '../financialReports/FinancialSummary';
import StatisticsService from '../../services/StatisticsService';
import useAccess from '../../hooks/useAccess';

export const allValue = 'ALL';
export const allValueKey = 'הכל';

const FinancialReports = () => {
  useAccess('read', AbilitySubjects.VIEW_FINANCIAL_REPORTS);
  const navigate = useNavigate();
  const { t } = useTranslation();

  const { pageInfo } = useSearchFilter();
  const { from, to, range, sort = 'employeeName', month, year } = pageInfo;

  const { isLoading, data, additionalData, loadingNumber, error, errorMessage } = useService<
    IEmployeeFinancialReport[]
  >({
    service: ReportsService.getEmployeesFinancialReports({
      fromDate: getStartEndDateDep(pageInfo),
      toDate: getStartEndDateDep(pageInfo, true),
      sort: sort,
    }),
    deps: [from, to, range, sort, month, year],
  });

  const employeesGeneralReport = useService<IEmployeesFinancialReports>({
    service: StatisticsService.getEmployeesFinancialChartData({
      fromDate: getStartEndDateDep(pageInfo),
      toDate: getStartEndDateDep(pageInfo, true),
    }),
    deps: [from, to, range, month, year],
  });

  const fontWeight = 'bold';
  const basicCellStyle = { fontWeight };
  const pendingColor = theme.palette.info.main;
  const doneColor = theme.palette.success.dark;
  const totalColor = theme.palette.primary.dark;

  const pendingStyle = { ...basicCellStyle, color: pendingColor };
  const doneStyle = { ...basicCellStyle, color: doneColor };
  const totalStyle = { ...basicCellStyle, color: totalColor };

  const columns = [
    {
      id: 'employeeName',
      Header: `${t('global.roles.EMPLOYEE')}`,
      accessor: 'employeeName',
      sortType: 'basic',
      sortDirection: getSortDirection(sort, 'employeeName'),
    },
    {
      Header: `${t('user.form.visitRate')}`,
      accessor: 'employeeVisitRate',
    },
    {
      id: 'totalPendingVisits',
      Header: t('visit.table.pendingVisits'),
      accessor: (report: any) => report.totalPendingVisits,
      cellStyle: pendingStyle,
    },
    {
      id: 'pendingVisitsCost',
      Header: `${t('financialReports.pendingVisitsCost')}`,
      accessor: (report: any) => report.pendingVisitsCost,
      cellStyle: pendingStyle,
    },
    {
      id: 'completedVisits',
      Header: `${t('visit.table.completedVisits')}`,
      accessor: (report: any) => report.totalDoneVisits,
      cellStyle: doneStyle,
    },
    {
      id: 'completedVisitsCost',
      Header: `${t('financialReports.completedVisitsCost')}`,
      accessor: (report: any) => report.doneVisitsCost,
      cellStyle: doneStyle,
      duplicateHeader: true,
    },
    {
      Header: `${t('financialReports.totalVisits')}`,
      accessor: 'totalVisits',
      cellStyle: totalStyle,
    },
    {
      Header: `${t('financialReports.totalCost')}`,
      accessor: 'totalCost',
      cellStyle: totalStyle,
    },
  ];

  const TableFooter = () => {
    return (
      <TotalTableRow>
        <StyledTableCell>{t('financialReports.totalCost')}</StyledTableCell>
        <StyledTableCell>-</StyledTableCell>
        <StyledTableCell>{employeesGeneralReport.data?.totalPendingVisits}</StyledTableCell>
        <StyledTableCell>
          {employeesGeneralReport.data?.totalPendingVisitsCost} {t('global.currencyUnits.nis')}
        </StyledTableCell>
        <StyledTableCell>{employeesGeneralReport.data?.totalDoneVisits}</StyledTableCell>
        <StyledTableCell>
          {employeesGeneralReport.data?.totalDoneVisitsCost} {t('global.currencyUnits.nis')}
        </StyledTableCell>
        <StyledTableCell>{employeesGeneralReport.data?.totalVisits}</StyledTableCell>
        <StyledTableCell>
          {employeesGeneralReport.data?.totalCost} {t('global.currencyUnits.nis')}
        </StyledTableCell>
      </TotalTableRow>
    );
  };

  if (error || employeesGeneralReport.error) {
    return <ErrorMessage error={errorMessage || employeesGeneralReport.errorMessage} />;
  }
  if (
    (isLoading && loadingNumber == 1) ||
    (employeesGeneralReport.isLoading && employeesGeneralReport.loadingNumber == 1)
  ) {
    return <OverlaySpinner />;
  }

  const onRowClick = (row: Row<IEmployeeFinancialReport>) => {
    handleTableAtomChange('employeeReport', row.original._id);
    navigate(`/app/user/${row.original._id}`);
  };

  return (
    <Page title={t('financialReports.employees.header')}>
      <Container>
        <PageHeader
          pageTitle={t('financialReports.employees.header')}
          pageFilter={
            <EmployeesFinancialReportsHeader
              generalReport={employeesGeneralReport.data}
              employeesReports={data}
              columns={columns as ISheetColumn[]}
            />
          }
          smallScreenDirection="row"
        />
        <Grid container sx={{ mb: 4 }} spacing={2} justifyContent="space-between">
          <Grid item xs={12} md={4}>
            <FinancialSummary
              title={t('financialReports.employees.pendingVisits')}
              value1={`${employeesGeneralReport.data?.totalPendingVisits || 0}`}
              value2={`${employeesGeneralReport.data?.totalPendingVisitsCost || 0} ${t(
                'global.currencyUnits.nis'
              )}`}
              backgroundColor={theme.palette.info.light}
              isLoading={employeesGeneralReport.isLoading}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <FinancialSummary
              title={t('visit.table.completedVisits')}
              value1={`${employeesGeneralReport.data?.totalDoneVisits || 0}`}
              value2={`${employeesGeneralReport.data?.totalDoneVisitsCost || 0} ${t(
                'global.currencyUnits.nis'
              )}`}
              backgroundColor={theme.palette.success.light}
              isLoading={employeesGeneralReport.isLoading}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <FinancialSummary
              title={t('drawer.sidebar.allVisits')}
              value1={`${employeesGeneralReport.data?.totalVisits || 0}`}
              value2={`${employeesGeneralReport.data?.totalCost || 0} ${t(
                'global.currencyUnits.nis'
              )}`}
              backgroundColor={theme.palette.primary.light}
              isLoading={employeesGeneralReport.isLoading}
            />
          </Grid>
        </Grid>
        <Card>
          <Table
            columns={columns}
            data={data || []}
            count={additionalData?.total || 0}
            isLoading={isLoading}
            customFooter={<TableFooter />}
            showPagination={false}
            onRowClick={onRowClick}
          />
        </Card>
      </Container>
    </Page>
  );
};

export default FinancialReports;
