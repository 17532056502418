import { Grid, MenuItem, Typography } from '@mui/material';
import React from 'react';
import { styled, SxProps } from '@mui/system';
import { theme } from '../../../../theme';
import { FastField as Field } from 'formik';
import { useTranslation } from 'react-i18next';
import { TextField } from 'formik-mui';
import { properOptions } from '../../../../common/HMMFirst';
import FormikSelect from '../../../../components/FormikSelect';

const StyledTextField = styled(TextField)({
  marginBottom: theme.spacing(2),
});

const sxFormControl: SxProps = {
  width: '100%',
};

interface AbilitiesTestProps {
  header?: string;
  subHeader?: string;
  fields: string[];
  sectionName: string;
  reportName: string;
  id: string;
}

const AbilitiesTest = ({
  header,
  subHeader,
  fields,
  sectionName,
  reportName,
  id,
}: AbilitiesTestProps) => {
  const { t } = useTranslation();

  return (
    <div id={id}>
      {header && (
        <Typography variant="subtitle1" gutterBottom align="left">
          {header}
        </Typography>
      )}
      {subHeader && (
        <Typography variant="subtitle2" gutterBottom align="left">
          {subHeader}
        </Typography>
      )}
      {fields.map((fieldName) => {
        return (
          <Grid container spacing={{ xs: 2, md: 4, lg: 4 }} key={fieldName} id={`${id}_Container`}>
            <Grid item xs={12} md={6} lg={6}>
              <FormikSelect
                id={`${id}_Selectfield_${fieldName}`}
                name={`data.${sectionName}.${fieldName}`}
                label={t(`report.${reportName}.${sectionName}.${fieldName}`)}
                formControl={sxFormControl}
              >
                {properOptions.map((option: string) => {
                  return (
                    <MenuItem key={option} value={option}>
                      {t(`global.form.select.${option}`)}
                    </MenuItem>
                  );
                })}
              </FormikSelect>
            </Grid>
            <Grid item xs={12} md={6} lg={6}>
              <Field
                id={`${id}_TextInput_${fieldName}`}
                fullWidth
                component={StyledTextField}
                type="text"
                name={`data.${sectionName}.${fieldName}Notes`}
                label={`${t(`report.${reportName}.${sectionName}.${fieldName}`)} ${t(
                  'report.HMKFIRST.remarks'
                )}`}
              />
            </Grid>
          </Grid>
        );
      })}
    </div>
  );
};

export default AbilitiesTest;
