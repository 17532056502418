/* eslint-disable new-cap */
import merge from 'lodash/merge';
import ReactApexChart from 'react-apexcharts';
// @mui
import { Card, CardHeader } from '@mui/material';
import BaseOptionChart from './chart/BaseOptionChart';
import { theme } from '../theme';

export interface IBarChartData {
  name: string;
  data: Array<number | null>;
}
export interface IBarChart {
  chartCategories: string[];
  title: string;
  chartData: IBarChartData[];
  enableDataLabels?: boolean;
  yTooltipFormatter?: (value: number) => string;
}
const BarChart = ({
  title,
  chartData,
  chartCategories,
  yTooltipFormatter,
  enableDataLabels = true,
}: IBarChart) => {
  const chartOptions: any = merge(BaseOptionChart(), {
    chart: {
      type: 'bar',
      height: 450,
      stacked: true,
      toolbar: {
        show: false,
      },
      zoom: {
        enabled: true,
      },
    },
    responsive: [
      {
        breakpoint: 480,
        options: {
          legend: {
            position: 'bottom',
            offsetX: -10,
            offsetY: 0,
          },
          xaxis: {
            labels: {
              style: {
                fontSize: '10px',
              },
            },
          },
          yaxis: {
            labels: {
              style: {
                fontSize: '10px',
              },
            },
          },
        },
      },
    ],
    plotOptions: {
      bar: {
        horizontal: false,
        borderRadius: 2,
        dataLabels: {
          show: true,
          position: 'top', // top, center, bottom
          hideOverlappingLabels: false,
        },
      },
    },
    xaxis: {
      categories: chartCategories,
      labels: {
        style: {
          fontSize: '15px',
          fontWeight: 900,
          color: 'black',
        },
      },
    },
    yaxis: {
      labels: {
        style: {
          fontSize: '15px',
          fontWeight: 900,
          color: 'black',
        },
      },
    },
    legend: {
      position: 'top',
      horizontalAlign: 'left',
      offsetY: 15,
      offsetX: -15,
    },
    fill: {
      opacity: 1,
    },
    dataLabels: {
      enabled: enableDataLabels,
    },
    tooltip: {
      y: {
        formatter: yTooltipFormatter,
      },
    },
  });
  return (
    <Card>
      <CardHeader title={title} sx={{ mb: theme.spacing(1) }} />
      {/* @ts-ignore*/}
      <ReactApexChart type="bar" series={chartData} options={chartOptions} height={410} />
    </Card>
  );
};

export default BarChart;
