import { Card, Skeleton, Stack, Typography } from '@mui/material';
import React from 'react';
import { styled } from '@mui/system';
import { useTranslation } from 'react-i18next';

export const RowStyle = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
});

interface FinancialSummary {
  title: string;
  value1?: string;
  value2?: string;
  subTitle1?: string;
  subTitle2?: string;
  backgroundColor?: string;
  isLoading: boolean;
}

const FinancialSummary = ({
  title,
  value1,
  value2,
  subTitle1,
  subTitle2,
  backgroundColor = 'white',
  isLoading,
}: FinancialSummary) => {
  const { t } = useTranslation();
  return (
    <>
      <Card sx={{ p: 3, backgroundColor }}>
        <Stack spacing={2}>
          {isLoading ? (
            <Skeleton width="45%">
              <Typography>.</Typography>
            </Skeleton>
          ) : (
            <Typography variant="h6">{title}</Typography>
          )}
          {isLoading ? (
            <RowStyle>
              <Skeleton width="20%">
                <Typography>.</Typography>
              </Skeleton>
              <Skeleton width="20%">
                <Typography>.</Typography>
              </Skeleton>
            </RowStyle>
          ) : (
            <RowStyle>
              <Typography variant="subtitle1" sx={{ color: 'text.secondary' }}>
                {subTitle1 || t('financialReports.employees.totalCount')}
              </Typography>
              <Typography variant="subtitle1">{value1}</Typography>
            </RowStyle>
          )}

          {isLoading ? (
            <RowStyle>
              <Skeleton width="20%">
                <Typography>.</Typography>
              </Skeleton>
              <Skeleton width="20%">
                <Typography>.</Typography>
              </Skeleton>
            </RowStyle>
          ) : (
            <RowStyle>
              <Typography variant="subtitle1" sx={{ color: 'text.secondary' }}>
                {subTitle2 || t('financialReports.employees.totalAmount')}
              </Typography>
              <Typography variant="subtitle1">{value2}</Typography>
            </RowStyle>
          )}
        </Stack>
      </Card>
    </>
  );
};

export default FinancialSummary;
