import React from 'react';
import { CLINICS_RELEASE_REPORT_COLORS } from '../common/Constants';
import Label from './Label';

export default function ClinicNameLabel({ clinicName }: { clinicName: string }) {
  return (
    <Label
      variant="ghost"
      color={clinicName ? CLINICS_RELEASE_REPORT_COLORS[clinicName.trim()] : 'default'}
    >
      {clinicName}
    </Label>
  );
}
