import { Box, Divider, Typography } from '@mui/material';
import { t } from 'i18next';
import React, { useState } from 'react';
import CustomizedTable from '../../../components/CustomizedTable';
import Form from '../../../components/Form/FormikForm';
import FormModal from '../../../components/Modals/FormModal';
import { IEditedVisits, IVisit, ReportData } from '../../../interfaces/Models';
import { theme } from '../../../theme';
import * as Yup from 'yup';
import DueDateFormField from './DueDateFormField';
import DialogActions from '../../../components/DialogActions';
import {
  getHospitalDaysVisitsColumnsWithoutActions,
  getHospitalDayVisitActions,
} from './HospitalDaysColumns';

interface IHospitalDaysFormStep2 {
  toBeCanceledVisits: IVisit[];
  editedVisits: IEditedVisits[];
  handleEditedVisits: (value: IEditedVisits[]) => void;
  handleChangeOnToBeCanceledVisits: (value: IVisit[]) => void;
  visitsWithPossibleChanges: IVisit[];
}
const HospitalDaysFormStep2 = ({
  toBeCanceledVisits,
  editedVisits,
  handleEditedVisits,
  handleChangeOnToBeCanceledVisits,
  visitsWithPossibleChanges,
}: IHospitalDaysFormStep2) => {
  const [openChangeVisitDueDataModal, setOpenChangeVisitDueDataModal] = useState<boolean>(false);
  const [openCancelVisitModal, setOpenCancelVisitModal] = useState<boolean>(false);
  const [record, setRecord] = useState<IVisit | undefined>();

  const handleOpenChangeVisitDueDateModal = (visit: IVisit) => {
    const recordUpdated = editedVisits.find(
      (editedVisit: IEditedVisits) => editedVisit.visitId == visit._id
    );
    setRecord({ ...visit, dueDate: recordUpdated ? recordUpdated.newDueDate : visit.dueDate });
    setOpenChangeVisitDueDataModal(true);
  };

  const handleOpenCancelVisitModal = (visit: IVisit) => {
    setRecord(visit);
    setOpenCancelVisitModal(true);
  };

  const handleCancelVisit = () => {
    handleChangeOnToBeCanceledVisits([...toBeCanceledVisits, record || toBeCanceledVisits[0]]);
    handleEditedVisits(
      editedVisits.filter((editedVisits: IEditedVisits) => editedVisits.visitId != record?._id)
    );
    setRecord(undefined);
    handleModalClose();
  };

  const handleModalClose = () => {
    setOpenChangeVisitDueDataModal(false);
    setOpenCancelVisitModal(false);
  };

  const removeVisitAction = (visit: IVisit) => {
    handleChangeOnToBeCanceledVisits(
      toBeCanceledVisits.filter((canceledVisit: IVisit) => canceledVisit._id != visit._id)
    );
    handleEditedVisits(
      editedVisits.filter((editedVisits: IEditedVisits) => editedVisits.visitId != visit?._id)
    );
  };

  const handleChangeVisitDueDate = (values: ReportData) => {
    const recordUpdated = editedVisits.find(
      (editedVisit: IEditedVisits) => editedVisit.visitId == record?._id
    );
    if (recordUpdated) {
      const olDUpdatedVisits = editedVisits.filter(
        (editedVisit: IEditedVisits) => editedVisit.visitId != record?._id
      );
      handleEditedVisits([
        ...olDUpdatedVisits,
        { visitId: record?._id || '', newDueDate: values.dueDate },
      ]);
    } else {
      handleEditedVisits([
        ...editedVisits,
        { visitId: record?._id || '', newDueDate: values.dueDate },
      ]);
    }

    handleChangeOnToBeCanceledVisits(
      toBeCanceledVisits.filter((visit: IVisit) => visit._id != record?._id)
    );
    setRecord(undefined);
    handleModalClose();
  };

  const columns = [
    ...getHospitalDaysVisitsColumnsWithoutActions(editedVisits, toBeCanceledVisits),
    getHospitalDayVisitActions(
      toBeCanceledVisits,
      editedVisits,
      handleOpenChangeVisitDueDateModal,
      handleOpenCancelVisitModal,
      removeVisitAction
    ),
  ];

  const DueDateSchema = Yup.object({
    dueDate: Yup.date().required(`${t('clinic.form.required')}`),
  });

  return (
    <div>
      <DialogActions
        onClose={handleModalClose}
        open={openCancelVisitModal}
        dialog={t(`global.actionsConfirmation.cancelVisit`)}
        action={handleCancelVisit}
      />
      <FormModal
        open={openChangeVisitDueDataModal}
        onClose={handleModalClose}
        text={t('program.hospitalDaysForm.editVisitDueDate')}
      >
        <Form
          onCancel={handleModalClose}
          submit={handleChangeVisitDueDate}
          initialValues={{ dueDate: record?.dueDate }}
          validationSchema={DueDateSchema}
          cancelText={t('clinic.form.cancel')}
          submitText={t('clinic.form.edit')}
        >
          <DueDateFormField />
        </Form>
      </FormModal>
      <Box flexGrow={1} sx={{ mb: theme.spacing(3) }}>
        <Typography variant="subtitle2" gutterBottom sx={{ mt: 2 }}>
          {t('program.hospitalDaysForm.periodVisits')}
        </Typography>
        <CustomizedTable columns={columns} data={visitsWithPossibleChanges} />
      </Box>
      <Divider />
    </div>
  );
};

export default HospitalDaysFormStep2;
