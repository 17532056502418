import { format } from 'date-fns';
import MoreOptions from '../../components/MoreOptions';
import { IUser, IUserFlat } from '../../interfaces/Models';
import { getSortDirection } from '../../utils/Helper';
import BlockIcon from '@mui/icons-material/Block';
import LockResetIcon from '@mui/icons-material/LockReset';
import editFill from '@iconify/icons-eva/edit-fill';
import { Icon } from '@iconify/react';
import { getFullNameColumn } from './Patients';
import { t } from 'i18next';
import { theme } from '../../theme';
import { getDeletedRecordOriginalValue } from '../../utils/table';

export const getEmployeesColumns = ({
  sort,
  handelEditReport,
  onResetPassword,
  blockEmployeeHandler,
  activateEmployeeHandler,
  isAuthorizedToEdit,
  isAuthorizedToResetPassword,
  isAuthorizedToBlock,
}: {
  sort: string;
  handelEditReport: (user: IUserFlat) => void;
  onResetPassword: (user: IUserFlat) => void;
  blockEmployeeHandler: (user: IUserFlat) => void;
  activateEmployeeHandler: (user: IUserFlat) => void;
  isAuthorizedToEdit: boolean;
  isAuthorizedToResetPassword: boolean;
  isAuthorizedToBlock: boolean;
}) => {
  return [
    getFullNameColumn(sort),
    {
      Header: `${t('user.form.email')}`,
      accessor: (user: IUser) => getDeletedRecordOriginalValue(user.email),
    },
    {
      Header: `${t('user.form.role')}`,
      accessor: (User: any) => t(`global.roles.${User.role}`),
    },
    {
      Header: `${t('user.form.position')}`,
      accessor: (User: any) => (User.position ? t(`global.positions.${User.position}`) : ''),
    },
    {
      Header: `${t('user.form.employeeNumber')}`,
      accessor: 'employeeNumber',
    },
    {
      id: 'startDate',
      Header: `${t('user.form.startDate')}`,
      accessor: (User: any) => {
        if (User.startDate) {
          return format(new Date(User.startDate), 'dd-MM-yyyy');
        } else {
          return '';
        }
      },
      sortType: 'basic',
      sortDirection: getSortDirection(sort, 'startDate'),
    },
    {
      Header: t('clinic.table.actions'),
      accessor: (User: any) => {
        return (
          <MoreOptions
            actions={[
              {
                name: t('global.moreMenu.edit'),
                handler: handelEditReport,
                icon: (
                  <Icon
                    icon={editFill}
                    width={24}
                    height={24}
                    color={theme.palette.secondary.main}
                  />
                ),
                isVisible: User.active && isAuthorizedToEdit,
              },
              {
                name: t('global.moreMenu.reset'),
                handler: onResetPassword,
                icon: <LockResetIcon width={24} height={24} />,
                isVisible: User.active && isAuthorizedToResetPassword,
              },
              {
                name: t('global.moreMenu.blockUser'),
                handler: blockEmployeeHandler,
                icon: <BlockIcon width={24} height={24} color="error" />,
                variant: theme.palette.error.main,
                isVisible: User.active && isAuthorizedToBlock,
              },
              {
                name: t('global.moreMenu.activate'),
                handler: activateEmployeeHandler,
                icon: (
                  <Icon
                    icon={'icomoon-free:user-check'}
                    width={24}
                    height={24}
                    color={theme.palette.secondary.main}
                  />
                ),
                isVisible: !User.active && isAuthorizedToBlock,
              },
            ]}
            record={User}
          />
        );
      },
    },
  ];
};
