import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Typography } from '@mui/material';
import GenericField from '../../sharedFields/GenericField';
import { iadlFields, adlFields } from '../../../../common/HMA';
import SectionDivider from '../../../../components/SectionDivider';
import { sumValues } from '../../../../utils/Helper';

interface FunctionalAssessmentFieldsProps {
  id: string;
  IADLValues: Object;
  ADLValues: Object;
  onChangeFormikValue: (field: string, value: any, shouldValidate?: boolean) => void;
}
const FunctionalAssessmentFields = ({
  id,
  IADLValues,
  ADLValues,
  onChangeFormikValue,
}: FunctionalAssessmentFieldsProps) => {
  const { t } = useTranslation();
  const [iadl, setIadl] = useState<number>(0);
  const [adl, setAdl] = useState<number>(0);

  useEffect(() => {
    setIadl(sumValues(IADLValues));
    setAdl(sumValues(ADLValues));
    onChangeFormikValue('data.functionalAssessment.IADLSum', sumValues(IADLValues));
    onChangeFormikValue('data.functionalAssessment.ADLSum', sumValues(ADLValues));
  }, [ADLValues, IADLValues]);
  return (
    <div id={id}>
      <GenericField
        id={`${id}_IADL}`}
        fields={iadlFields}
        header={t('report.HMAFIRST.header')}
        subHeader={t('report.HMAFIRST.IADL.header')}
      />
      <Typography variant="subtitle1" gutterBottom>
        {t('report.HMAFIRST.summary')} [{iadl}]
      </Typography>

      <SectionDivider />
      <GenericField
        id={`${id}_ADL}`}
        fields={adlFields}
        subHeader={t('report.HMAFIRST.ADL.header')}
      />
      <Typography variant="subtitle1" gutterBottom>
        {t('report.HMAFIRST.summary')} [{adl}]
      </Typography>
    </div>
  );
};

export default FunctionalAssessmentFields;
