import React from 'react';
import { VisitsColumnsKeys } from '../../common/columns/AllVisitsTableColumn';
import {
  FILTERS,
  URL_QUERY_PARAMS,
  VISIT_STATUS,
  VISIT_STATUS_TO_HEBREW,
} from '../../common/Constants';
import DateRangeFilter from '../../components/DateRangeFilter';
import DropDownFilter from '../../components/DropDownFilter';
import { TableHeaderPositions } from '../../components/WidgetContainer';
import { filterGeneralReleaseVisits } from '../../utils/Helper';
import { allValue, allValueKey } from '../financialReports/EmployeesFinancialReports';
import VisitsTable from '../visits/VisitsTable';

interface IEmployeeAccountingTable {
  employeeId: string;
  employeeVisitRate: number;
}
const EmployeeAccountingTable = ({ employeeId, employeeVisitRate }: IEmployeeAccountingTable) => {
  return (
    <VisitsTable
      employee={employeeId}
      typeOfColumns={VisitsColumnsKeys.Accounting}
      status={`${VISIT_STATUS.DONE},${VISIT_STATUS.SENT_TO_CLINIC},${VISIT_STATUS.PENDING}`}
      dataFilter={filterGeneralReleaseVisits}
      rate={employeeVisitRate}
      showTablePagination={false}
      showPageLoading={false}
      filterPosition={TableHeaderPositions.LEFT}
      customFilters={[
        {
          filterName: FILTERS.DATE_RANGE,
          component: <DateRangeFilter resetPageAfterFilter={false} />,
        },
        {
          filterName: FILTERS.STATUS_FILTER,
          component: (
            <DropDownFilter
              values={[allValue, VISIT_STATUS.DONE, VISIT_STATUS.PENDING]}
              keys={[allValueKey, VISIT_STATUS_TO_HEBREW.DONE, VISIT_STATUS_TO_HEBREW.PENDING]}
              defaultValue={allValue}
              urlParam={URL_QUERY_PARAMS.STATUS}
              resetPageAfterFilter={false}
            />
          ),
        },
      ]}
      statusFilterValues={{
        ALL: `${VISIT_STATUS.DONE},${VISIT_STATUS.SENT_TO_CLINIC},${VISIT_STATUS.PENDING}`,
        DONE: `${VISIT_STATUS.DONE},${VISIT_STATUS.SENT_TO_CLINIC}`,
        PENDING: VISIT_STATUS.PENDING,
      }}
    />
  );
};

export default EmployeeAccountingTable;
