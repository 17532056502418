import { Grid } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import WidgetContainer from '../../components/WidgetContainer';
import SummariesWidgets from './dashboardWidgets/SummariesWidgets';
import ReleaseReportsWidget from './dashboardWidgets/ReleaseReportsWidget';
import ReportsWidgets from './dashboardWidgets/ReportsWidgets';
import ProgramsList from '../program/ProgramsList';
import { PATIENT_PROGRAM_STATUS } from '../../common/Constants';

const AdminDashboard: React.FC = () => {
  const { t } = useTranslation();

  return (
    <>
      <Grid item xs={12} md={12} lg={12}>
        <SummariesWidgets />
      </Grid>

      <Grid item xs={12} md={8}>
        <WidgetContainer
          showMoreRoute="/app/release-reports"
          title={t('global.dashboard.lastReleaseReports')}
        >
          <ReleaseReportsWidget />
        </WidgetContainer>
      </Grid>
      <Grid item xs={12} md={4}>
        <WidgetContainer showMoreRoute="/app/programs" title={t('global.dashboard.lastPrograms')}>
          <ProgramsList status={PATIENT_PROGRAM_STATUS.ACTIVE} sortField="-createdAt" />
        </WidgetContainer>
      </Grid>
      <Grid item xs={12}>
        <ReportsWidgets />
      </Grid>
    </>
  );
};

export default AdminDashboard;
