import React from 'react';
import useService from '../../hooks/HookService';
import { IVisit } from '../../interfaces/Models';
import VisitService from '../../services/VisitService';
import ErrorMessage from '../../components/ErrorMessage';
import Table from '../../components/Table';
import { FILTERS } from '../../common/Constants';
import { IRecordList } from '../../components/WidgetContainer';
import { Row } from 'react-table';
import { useNavigate } from 'react-router-dom';
import { getPageNumberFromUrlParam, getStartEndDateDep, onVisitClick } from '../../utils/Helper';
import { getMyVisitsColumns } from '../../common/columns/MyVisitsColumns';
import SearchFilter from '../../components/SearchFilter';
import DateRangeFilter from '../../components/DateRangeFilter';
import OverlaySpinner from '../../components/OverlaySpinner';
import useSearchFilter from '../../hooks/useSearchFilter';

const MyVisitTable: React.FC<IRecordList> = ({
  showTablePagination,
  showFilters,
  showPageLoading,
  status,
  pageSizes,
  initialSortValue,
}) => {
  const navigate = useNavigate();
  const { pageInfo } = useSearchFilter();

  const {
    page,
    limit = pageSizes ? 1000000 : 20,
    search,
    from,
    to,
    range,
    sort = initialSortValue || '-dueDate',
    month,
    year,
  } = pageInfo;

  const { isLoading, data, additionalData, loadingNumber, error, errorMessage } = useService<
    IVisit[]
  >({
    service: VisitService.getMyVisits({
      page: getPageNumberFromUrlParam(page),
      limit: limit,
      fromDate: getStartEndDateDep(pageInfo),
      toDate: getStartEndDateDep(pageInfo, true),
      search: search,
      status: status,
      sort: sort,
    }),
    deps: [page, limit, from, to, search, sort, range, month, year],
  });

  const columns = getMyVisitsColumns(sort);

  const onRowClick = (row: Row<IVisit>) => {
    onVisitClick(navigate, row.original);
  };

  if (error) {
    return <ErrorMessage error={errorMessage} />;
  }

  if (isLoading && showPageLoading && loadingNumber == 1) {
    return <OverlaySpinner />;
  }

  return (
    <>
      <Table
        columns={columns}
        data={data || []}
        count={additionalData?.total || 0}
        pageInfo={
          pageSizes
            ? undefined
            : {
                page: getPageNumberFromUrlParam(page),
                pageSize: Number(limit),
              }
        }
        isLoading={isLoading}
        showPagination={showTablePagination}
        onRowClick={onRowClick}
        filters={
          showFilters
            ? [
                {
                  filterName: FILTERS.SEARCH,
                  component: <SearchFilter />,
                },
                {
                  filterName: FILTERS.DATE_RANGE,
                  component: <DateRangeFilter />,
                },
              ]
            : []
        }
        pageSizes={pageSizes}
      />
    </>
  );
};

export default MyVisitTable;
