/* eslint-disable new-cap */
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useOutletContext, useParams } from 'react-router-dom';
import * as Yup from 'yup';
import { reportTypes } from '../../../common/Constants';
import ErrorMessage from '../../../components/ErrorMessage';
import { HMBGeneralValues } from '../../../common/reportsFieldsValues/hmb/HMBGeneralValues';
import HMBGeneralFields from '../general/fields/HMBGeneralFields';
import ReleaseFields from '../sharedFields/ReleaseFields';
import ReportsForm from '../../../components/Form/ReportsForm';
import SectionDivider from '../../../components/SectionDivider';

const HMBRelease = () => {
  const { t } = useTranslation();
  const { visitId } = useParams();
  const data: any = useOutletContext();

  const initialValues =
    data && data.name
      ? data
      : {
          name: reportTypes.HMB_RELEASE,
          visit: visitId,
          data: data ? data : HMBGeneralValues,
        };

  const ReportSchema = Yup.object().shape({
    name: Yup.string().required(t('clinic.form.required')),
    visit: Yup.string().required(t('clinic.form.required')),
    data: Yup.object().shape({}),
  });

  const HtmlId = 'HMBRelease';

  if (data?.name && data.name != reportTypes.HMB_RELEASE) {
    return <ErrorMessage error={t('global.errors.somethingWrong')} />;
  }
  return (
    <ReportsForm
      initialValues={initialValues}
      visitId={visitId || ''}
      validationSchema={ReportSchema}
    >
      <HMBGeneralFields id={HtmlId} />
      <ReleaseFields id={HtmlId} />
      <SectionDivider />
    </ReportsForm>
  );
};

export default HMBRelease;
