import React, { useState } from 'react';
import { IPatient, IPatientProgram } from '../../../interfaces/Models';
import { getProgramStartEndColumns, getProgramTitleColumn } from '../../../common/columns/Programs';
import Table from '../../../components/Table';
import { useNavigate } from 'react-router-dom';
import { Row } from 'react-table';
import { useTranslation } from 'react-i18next';
import { Stack } from '@mui/material';
import { Icon } from '@iconify/react';
import { theme } from '../../../theme';
import MissingProgramReferenceNumberForm from './MissingProgramReferenceNumberForm';
import { LEUMIT_REPORT_TYPES, LeumitReportType } from './MissingLeumitRecordsTableHeader';
import EditProgramPaymentModeForm from './EditProgramPaymentModeForm';
import TableActionButton from './TableActionButton';
import { getSortDirection } from '../../../utils/Helper';
import { IVisitWithProgramTitle } from '../../../layouts/reports/ReportLayout';

export const sortByPatientName = (
  data: IPatientProgram[] | IVisitWithProgramTitle[],
  sort: string
) => {
  const isDesc = sort.includes('-');
  return data.sort((a, b) => {
    const patientA = a.patient as IPatient;
    const patientB = b.patient as IPatient;

    const patientAName = `${patientA.personalDetails.firstName} ${patientA.personalDetails.lastName}`;
    const patientBName = `${patientB.personalDetails.firstName} ${patientB.personalDetails.lastName}`;

    return isDesc
      ? patientAName.localeCompare(patientBName)
      : patientBName.localeCompare(patientAName);
  });
};
export const sortLeumitPrograms = ({
  programs,
  sort = 'title',
}: {
  programs: IPatientProgram[];
  sort?: string;
}) => {
  const isSortedByStartDate = sort.includes('startDate');
  const isSortedByEndDate = sort.includes('endDate');

  const isSortedByProgramTitle = sort.includes('title');
  const isSortedByPatientName = sort.includes('patientName');

  if (isSortedByStartDate || isSortedByEndDate) {
    const sortKey = isSortedByStartDate ? 'startDate' : 'endDate';
    const isDesc = sort.includes('-');
    if (isDesc) {
      return programs.sort((a, b) => {
        return new Date(b[sortKey] || '').getTime() - new Date(a[sortKey] || '').getTime();
      });
    }
    return programs.sort((a, b) => {
      return new Date(a[sortKey] || '').getTime() - new Date(b[sortKey] || '').getTime();
    });
  }

  if (isSortedByProgramTitle) {
    const isDesc = sort.includes('-');
    if (isDesc) {
      return programs.sort((a, b) => {
        return a.title.localeCompare(b.title);
      });
    }
    return programs.sort((a, b) => {
      return b.title.localeCompare(a.title);
    });
  }

  if (isSortedByPatientName) {
    return sortByPatientName(programs, sort);
  }
  return programs;
};

export interface MissingReferenceNumberProgramsTableProps {
  isLoading: boolean;
  programs: IPatientProgram[];
  handleUpdateProgramsTable: (val: IPatientProgram) => void;
  reportType?: LeumitReportType;
  sort?: string;
  handleSaveLastActionValue: (value: string) => void;
  billingDate?: Date;
}
export default function MissingProgramsTable({
  isLoading,
  programs,
  handleUpdateProgramsTable,
  reportType,
  sort = 'startDate',
  handleSaveLastActionValue,
  billingDate,
}: MissingReferenceNumberProgramsTableProps) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [record, setRecord] = useState<IPatientProgram | undefined>(undefined);
  const [openChangeClinicReferenceNumber, setOpenChangeClinicReferenceNumber] =
    useState<boolean>(false);

  const [openChangePaymentModeModal, setOpenChangePaymentModeModal] = useState<boolean>(false);

  const handelCloseModal = () => {
    setOpenChangeClinicReferenceNumber(false);
    setOpenChangePaymentModeModal(false);
    setRecord(undefined);
  };

  const handleOpenChangePaymentModeModal = (value: IPatientProgram) => {
    setOpenChangePaymentModeModal(true);
    setRecord(value);
  };

  const handleOpenChangeClinicReferenceNumberModal = (value: IPatientProgram) => {
    setOpenChangeClinicReferenceNumber(true);
    setRecord(value);
  };

  const isShowMissingReferenceNumberProgramsTable =
    reportType === LEUMIT_REPORT_TYPES.MISSING_PROGRAMS_REFERENCE_NUMBER;

  const isShowProgramsMissingPaymentModeTable =
    reportType === LEUMIT_REPORT_TYPES.MISSING_PAYMENT_METHOD;

  const columns = [
    getProgramTitleColumn(sort),
    {
      id: 'patientName',
      Header: `${t('patient.info.patientName')}`,
      accessor: (val: any) =>
        typeof val.patient !== 'string'
          ? `${val.patient.personalDetails.firstName} ${val.patient.personalDetails.lastName}`
          : '',
      sortType: sort ? 'basic' : undefined,
      sortDirection: sort ? getSortDirection(sort || '', 'patientName') : undefined,
    },
    ...getProgramStartEndColumns(sort),
    {
      Header: t('clinic.table.actions'),
      accessor: (program: any) => {
        return (
          <Stack direction={'row'} alignItems={'center'} justifyContent={'flex-start'}>
            {isShowMissingReferenceNumberProgramsTable && (
              <TableActionButton
                icon={
                  <Icon
                    icon="zondicons:book-reference"
                    width={24}
                    height={24}
                    color={theme.palette.secondary.main}
                  />
                }
                onClick={() => handleOpenChangeClinicReferenceNumberModal(program)}
                label={t('clinicReferenceNumber')}
              />
            )}
            {isShowProgramsMissingPaymentModeTable && (
              <TableActionButton
                icon={
                  <Icon
                    icon="mdi:account-payment"
                    width={24}
                    height={24}
                    color={theme.palette.secondary.main}
                  />
                }
                onClick={() => handleOpenChangePaymentModeModal(program)}
                label={t('program.paymentMode')}
              />
            )}
          </Stack>
        );
      },
    },
  ];

  const onRowClick = (row: Row<IPatientProgram>) => {
    navigate(`/app/patient-program/${row.original._id}`);
  };

  const isThereNoData = programs.length === 0 && !isLoading;

  return (
    <>
      <MissingProgramReferenceNumberForm
        record={record}
        openModal={openChangeClinicReferenceNumber}
        handleCloseModal={handelCloseModal}
        handleProgramsUpdate={handleUpdateProgramsTable}
        handleSaveLastActionValue={handleSaveLastActionValue}
        billingDate={billingDate}
      />
      <EditProgramPaymentModeForm
        record={record}
        openModal={openChangePaymentModeModal}
        handleCloseModal={handelCloseModal}
        handleProgramsUpdate={handleUpdateProgramsTable}
        handleSaveLastActionValue={handleSaveLastActionValue}
      />
      {!isThereNoData && (
        <Table
          columns={columns}
          data={programs}
          count={programs.length}
          isLoading={isLoading}
          onRowClick={onRowClick}
          showPagination={false}
        />
      )}
    </>
  );
}
