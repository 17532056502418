import { format } from 'date-fns';
import * as XLSX from 'xlsx';
import { ReportData } from '../interfaces/Models';

export interface ISheetColumn {
  Header: string;
  accessor: string | ((value: ReportData) => void);
  duplicateHeader?: boolean;
  noExcelSupport?: boolean;
}

export const getSheetData = (
  tableData: ReportData[],
  columns: ISheetColumn[],
  totalValues?: Array<string | number>
) => {
  const sheetData: ReportData[] = [];

  tableData.forEach((data: ReportData) => {
    const sheetRow: any = {};
    columns.forEach((column: ISheetColumn) => {
      sheetRow[column.duplicateHeader ? `${column.Header} 1` : column.Header] =
        typeof column.accessor == 'string' ? data[column.accessor] : column.accessor(data);
    });
    sheetData.push(sheetRow);
  });

  if (sheetData.length > 1 && totalValues) {
    const totalRow: ReportData = {};
    const sheetFields = Object.keys(sheetData[0]);
    sheetFields.forEach((field: string, idx: number) => {
      totalRow[field] = totalValues[idx];
    });
    sheetData.push(totalRow);
  }

  return sheetData;
};

interface ISheets {
  sheetName: string;
  sheetData: ReportData[];
}

export const downloadExcelReport = (reportName: string, sheets: ISheets[]) => {
  const wb = XLSX.utils.book_new();
  const todayDateFormatted = format(new Date(), 'yyyy-MM-dd-HH-mm-ss');

  sheets.forEach((sheet: ISheets) => {
    const reportSheet = XLSX.utils.json_to_sheet(sheet.sheetData);
    XLSX.utils.book_append_sheet(wb, reportSheet, sheet.sheetName);
  });

  wb.Workbook = { Views: [{ RTL: true }] };

  XLSX.writeFile(wb, `${reportName}_${todayDateFormatted}.xlsx`);
};
