import React from 'react';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import { he, enUS } from 'date-fns/locale';
import i18n, { t } from 'i18next';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { Field } from 'formik';
import { TextField } from '@mui/material';
import { DatePicker } from 'formik-mui-lab';

interface IFormikMonthPickerProps {
  name: string;
  label: string;
}
export default function FormikMonthPicker({ name, label }: IFormikMonthPickerProps) {
  return (
    <LocalizationProvider locale={i18n.language == 'he' ? he : enUS} dateAdapter={AdapterDateFns}>
      <Field
        name={name}
        label={label}
        component={DatePicker}
        inputFormat="MM/yyyy" // Set the input format to display only month and year
        views={['year', 'month']} // Restrict the views to only year and month
        openTo="month" // Open the picker directly to the month view
        renderInput={(params: any) => <TextField label={label} {...params} fullWidth />}
      />
    </LocalizationProvider>
  );
}
