/* eslint-disable new-cap */
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useParams, useOutletContext } from 'react-router-dom';
import * as Yup from 'yup';
import { HMAValues } from '../../../common/reportsFieldsValues/hma/HMAValues';
import HMAFields from './fields/HMAFields';
import ReportsForm from '../../../components/Form/ReportsForm';

interface HMAProps {
  reportName?: string;
}
const HMA = ({ reportName }: HMAProps) => {
  const { t } = useTranslation();
  const { visitId } = useParams();
  const data: any = useOutletContext();

  const initialValues =
    data && data?.name
      ? data
      : {
          name: reportName ? reportName : 'empty',
          visit: visitId,
          data: data ? data : HMAValues,
        };
  const ReportSchema = Yup.object({
    name: Yup.string().required(t('clinic.form.required')),
    visit: Yup.string().required(t('clinic.form.required')),
    data: Yup.object().shape({
      PainAssessment: Yup.object().shape({
        painDegrees: Yup.string().required(t('clinic.form.required')),
      }),
    }),
  });

  const HtmlId = reportName ? reportName : data?.name || 'empty';

  return (
    <ReportsForm
      initialValues={initialValues}
      visitId={visitId || ''}
      validationSchema={ReportSchema}
      name={data?.name}
      resetForm={data && !data.name ? true : false}
      resetFormValues={data && !data.name ? { ...initialValues, data: HMAValues } : {}}
    >
      <HMAFields id={HtmlId} />
    </ReportsForm>
  );
};

export default HMA;
