/* eslint-disable new-cap */
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Form from '../../components/Form/FormikForm';
import FormModal from '../../components/Modals/FormModal';
import { IVisit, ReportData } from '../../interfaces/Models';
import * as Yup from 'yup';
import { IVisitWithProgramTitle } from '../../layouts/reports/ReportLayout';
import FetcherService from '../../hooks/FetcherService';
import VisitService from '../../services/VisitService';
import { visitAtom } from '../../atoms/visitAtom';
import { useSetRecoilState } from 'recoil';
import { Field } from 'formik';
import { StyledTextField } from '../../styles/FormFieldsStyles';
import { Box } from '@mui/material';

interface IEditVisitReferenceNumberForm {
  openChangeClinicReferenceNumber: boolean;
  handleCloseModal: () => void;
  record?: IVisitWithProgramTitle | IVisit;
  handleVisitUpdate?: (val?: IVisitWithProgramTitle | IVisit) => void;
  handleSaveLastActionValue?: (value: string) => void;
}
const EditVisitReferenceNumberForm = ({
  openChangeClinicReferenceNumber,
  handleCloseModal,
  record,
  handleVisitUpdate,
  handleSaveLastActionValue,
}: IEditVisitReferenceNumberForm) => {
  const { t } = useTranslation();
  const setCurrentVisit = useSetRecoilState(visitAtom);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const schema = Yup.object({
    clinicReferenceNumber: Yup.number().required(`${t('clinic.form.required')}`),
  });

  const handleChangeVisitDueDate = async (values: ReportData) => {
    await FetcherService<any>({
      service: VisitService.edit(record?._id || 'empty', {
        clinicReferenceNumber: values.clinicReferenceNumber,
      }),
      setLoading: setIsLoading,
    });
    handleCloseModal();
    if (handleSaveLastActionValue) {
      handleSaveLastActionValue(values.clinicReferenceNumber);
    }
    if (handleVisitUpdate) {
      handleVisitUpdate(record);
    } else {
      setCurrentVisit((pre: IVisitWithProgramTitle | undefined) => {
        if (pre) {
          return { ...pre, clinicReferenceNumber: values.clinicReferenceNumber };
        }
        return undefined;
      });
    }
  };

  return (
    <FormModal
      open={openChangeClinicReferenceNumber}
      onClose={handleCloseModal}
      text={t('editClinicReferenceNumber')}
      loading={isLoading}
    >
      <Form
        onCancel={handleCloseModal}
        submit={handleChangeVisitDueDate}
        initialValues={{ clinicReferenceNumber: record?.clinicReferenceNumber }}
        validationSchema={schema}
        cancelText={t('clinic.form.cancel')}
        submitText={t('clinic.form.edit')}
      >
        <Box sx={{ my: 1, width: '100%' }}>
          <Field
            fullWidth
            multiline
            component={StyledTextField}
            type="number"
            name={'clinicReferenceNumber'}
            label={t('clinicReferenceNumber')}
          />
        </Box>
      </Form>
    </FormModal>
  );
};

export default EditVisitReferenceNumberForm;
