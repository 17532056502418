/* eslint-disable new-cap */
import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Button from '@mui/material/Button';
import {
  IEditedVisits,
  IHospitalDays,
  IPatientProgram,
  IPatientProgramFlat,
  IVisit,
} from '../../interfaces/Models';
import { t } from 'i18next';
import HospitalDaysFormStep1 from './hospitalDays/HospitalDaysFormStep1';
import {
  getVisitsActionsFromHospitalDays,
  validateHospitalDays,
} from '../../utils/HospitalDaysHelper';
import HospitalDaysFormStep2 from './hospitalDays/HospitalDaysFormStep2';
import HospitalDaysFormStep3 from './hospitalDays/HospitalDaysFormStep3';
import FetcherService from '../../hooks/FetcherService';
import PatientProgramService from '../../services/PatientProgramService';
import LoadingScreen from '../../components/LoadingScreen';
import { theme } from '../../theme';
import { toast } from 'react-toastify';
import { HOSPITAL_DAYS_TYPE } from '../../common/Constants';

export interface IProgramHospitalDaysForm {
  handleFormClosing: () => void;
  handlePageUpdate: () => void;
  program?: IPatientProgram | IPatientProgramFlat;
  period?: IHospitalDays;
}
const ProgramHospitalDaysForm = ({
  handleFormClosing,
  handlePageUpdate,
  program,
  period,
}: IProgramHospitalDaysForm) => {
  const [isStepOneValid, setIsStepOneValid] = useState<boolean>(false);
  const [hospitalDays, setHospitalDays] = useState<IHospitalDays>();
  const [startHospitalDays, setStartHospitalDays] = useState<Date | null>(
    period ? period.start : null
  );

  const [periodType, setPeriodType] = useState(
    period?.type ? period?.type : HOSPITAL_DAYS_TYPE.HOSPITAL
  );
  const [endHospitalDays, setEndHospitalDays] = useState<Date | null>(period ? period.end : null);
  const [toBeCanceledVisits, setToBeCanceledVisits] = useState<IVisit[]>([]);
  const [visitsWithPossibleChanges, setVisitsWithPossibleChanges] = useState<IVisit[]>([]);
  const [editedVisits, setEditedVisits] = useState<IEditedVisits[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [activeStep, setActiveStep] = useState<number>(0);
  const [skipped, setSkipped] = useState(new Set<number>());

  const handleChangeHospitalDaysStart = (value: Date | null) => {
    setStartHospitalDays(value);
  };

  const handleChangeHospitalDaysEnd = (value: Date | null) => {
    setEndHospitalDays(value);
  };

  const handleChangeInHospitalDays = (value: IHospitalDays) => {
    const isHospitalDaysValid = validateHospitalDays(
      {
        start: value.start,
        end: value.end,
        _id: period?._id,
      },
      program?.hospitalDays
    );

    if (!isHospitalDaysValid) {
      toast.error(t('global.errors.notValidHospitalDaysPeriod'));
      handleChangeHospitalDaysStart(null);
      handleChangeHospitalDaysEnd(null);
    }

    if (isHospitalDaysValid) {
      setHospitalDays(value);
      setIsStepOneValid(true);
    }
  };

  const handleChangeOnToBeCanceledVisits = (value: IVisit[]) => {
    setToBeCanceledVisits(value);
  };

  const handleEditedVisits = (values: IEditedVisits[]) => {
    setEditedVisits(values);
  };
  const stepOneTitle = t(
    `program.hospitalDaysForm.${period ? 'editHospitalDays' : 'addHospitalDays'}`
  );
  const steps = [
    stepOneTitle,
    t('program.hospitalDaysForm.steps.step2'),
    t('program.hospitalDaysForm.steps.step3'),
  ];

  const isStepSkipped = (step: number) => {
    return skipped.has(step);
  };

  const handleNext = async () => {
    if ((activeStep == 0 && isStepOneValid) || (activeStep > 0 && activeStep < steps.length - 1)) {
      if (activeStep == 0 && periodType === HOSPITAL_DAYS_TYPE.HOSPITAL) {
        const changedVisits = getVisitsActionsFromHospitalDays(program?.visits || [], hospitalDays);
        setToBeCanceledVisits(changedVisits.visitsToBeCanceled);
        setVisitsWithPossibleChanges([
          ...changedVisits.visitsToBeCanceled,
          ...changedVisits.visitsToBeEdited,
        ]);
      }
      let newSkipped = skipped;
      if (isStepSkipped(activeStep)) {
        newSkipped = new Set(newSkipped.values());
        newSkipped.delete(activeStep);
      }
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
      setSkipped(newSkipped);
    } else {
      const canceledVisits: string[] = toBeCanceledVisits.map((visit: IVisit) => visit._id || '');
      await FetcherService<IPatientProgram>({
        service: PatientProgramService.hospitalDays(program?._id as string, {
          hospitalDays,
          editedVisits,
          canceledVisits,
          hospitalDaysId: period?._id,
        }),
        setLoading: setIsLoading,
        onFailed: handleFormClosing,
      });
      handleFormClosing();
      handlePageUpdate();
    }
  };

  const handleBack = () => {
    if (activeStep == 1) {
      setEditedVisits([]);
    }
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const formSteps = [
    <HospitalDaysFormStep1
      startHospitalDays={startHospitalDays}
      endHospitalDays={endHospitalDays}
      handleChangeHospitalDaysStart={handleChangeHospitalDaysStart}
      handleChangeHospitalDaysEnd={handleChangeHospitalDaysEnd}
      handleChangeInHospitalDays={handleChangeInHospitalDays}
      hospitalDays={program?.hospitalDays}
      title={stepOneTitle}
      editedPeriod={period}
      setPeriodType={setPeriodType}
      type={periodType}
    />,
    <HospitalDaysFormStep2
      toBeCanceledVisits={toBeCanceledVisits}
      editedVisits={editedVisits}
      handleEditedVisits={handleEditedVisits}
      handleChangeOnToBeCanceledVisits={handleChangeOnToBeCanceledVisits}
      visitsWithPossibleChanges={visitsWithPossibleChanges}
    />,
    <HospitalDaysFormStep3
      visitsWithPossibleChanges={visitsWithPossibleChanges}
      toBeCanceledVisits={toBeCanceledVisits}
      editedVisits={editedVisits}
    />,
  ];
  return (
    <Box sx={{ width: '100%', mt: theme.spacing(2) }}>
      {isLoading ? (
        <LoadingScreen />
      ) : (
        <>
          <Stepper
            activeStep={activeStep}
            sx={{
              mb: theme.spacing(3),
              '& .MuiStepConnector-line': {
                borderTopWidth: '4px',
              },
              '& .MuiStepConnector-root.Mui-active .MuiStepConnector-line': {
                borderColor: theme.palette.primary.main,
              },
              '& .MuiStepConnector-root.Mui-completed .MuiStepConnector-line': {
                borderColor: theme.palette.primary.main,
              },
            }}
          >
            {steps.map((label, index) => {
              const stepProps: { completed?: boolean } = {};
              const labelProps: {
                optional?: React.ReactNode;
              } = {};

              if (isStepSkipped(index)) {
                stepProps.completed = false;
              }
              return (
                <Step key={label} {...stepProps}>
                  <StepLabel
                    sx={{
                      '& .MuiStepLabel-label.Mui-active': {
                        fontWeight: 'bold',
                      },
                    }}
                    {...labelProps}
                  >
                    {label}
                  </StepLabel>
                </Step>
              );
            })}
          </Stepper>
          <React.Fragment>
            {formSteps[activeStep]}
            <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2, direction: 'rtl' }}>
              <Button
                variant="contained"
                color="error"
                disabled={activeStep === 0}
                onClick={handleBack}
                sx={{ mr: 1 }}
              >
                {t('global.resetPassword.back')}
              </Button>
              <Box sx={{ flex: '1 1 auto' }} />
              <Button
                variant="contained"
                onClick={handleNext}
                disabled={!((isStepOneValid && activeStep == 0) || activeStep > 0)}
              >
                {activeStep === steps.length - 1 ? t('global.form.submit') : t('global.form.next')}
              </Button>
            </Box>
          </React.Fragment>
        </>
      )}
    </Box>
  );
};

export default ProgramHospitalDaysForm;
