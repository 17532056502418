import { Box, Divider, Typography } from '@mui/material';
import React from 'react';
import { IHospitalDays } from '../../../interfaces/Models';
import HospitalDaysFormFields from './HospitalDaysFormFields';
import { theme } from '../../../theme';

interface IHospitalDaysFormStep1 {
  hospitalDays?: IHospitalDays[];
  startHospitalDays: Date | null;
  endHospitalDays: Date | null;
  handleChangeHospitalDaysStart: (value: Date | null) => void;
  handleChangeHospitalDaysEnd: (value: Date | null) => void;
  handleChangeInHospitalDays: (value: IHospitalDays) => void;
  title: string;
  editedPeriod?: IHospitalDays;
  type?: string;
  setPeriodType: (value: string) => void;
}
const HospitalDaysFormStep1 = ({
  hospitalDays,
  startHospitalDays,
  endHospitalDays,
  handleChangeHospitalDaysStart,
  handleChangeHospitalDaysEnd,
  handleChangeInHospitalDays,
  setPeriodType,
  title,
  editedPeriod,
  type,
}: IHospitalDaysFormStep1) => {
  const handleDatesChange = (name: string, value: Date | null) => {
    if (value) {
      if (name == 'start') {
        handleChangeHospitalDaysStart(new Date(value.setHours(0, 0, 0, 0)));
      } else {
        handleChangeHospitalDaysEnd(new Date(value.setHours(0, 0, 0, 0)));
      }
    }
    if (startHospitalDays && endHospitalDays) {
      handleChangeInHospitalDays({
        start: startHospitalDays,
        end: endHospitalDays,
        type: type,
      });
    }
  };

  return (
    <div>
      <Typography variant="subtitle2" gutterBottom sx={{ mt: 2 }}>
        {title}
      </Typography>
      <Box flexGrow={1} sx={{ mb: theme.spacing(3) }}>
        <HospitalDaysFormFields
          start={startHospitalDays}
          end={endHospitalDays}
          handleDatesChange={handleDatesChange}
          hospitalDays={hospitalDays}
          editedPeriodId={editedPeriod?._id}
          type={type}
          setPeriodType={setPeriodType}
        />
      </Box>
      <Divider />
    </div>
  );
};

export default HospitalDaysFormStep1;
