/* eslint-disable camelcase */
import { IAttachment } from '../interfaces/attachment';
import { saveAs } from 'file-saver';

export const downloadCloudinaryAttachment = (val: IAttachment) => {
  saveAs(val.url, val.fileName);
};

export const getFileType = ({ public_id, format }: { format?: string; public_id: string }) => {
  if (format) return format;
  const [other, fileFormat] = public_id.split('.');
  return fileFormat;
};

// Define more types here
const FORMAT_PDF = ['pdf'];
const FORMAT_TEXT = ['txt'];
const FORMAT_WORD = ['doc', 'docx'];
const FORMAT_EXCEL = ['xls', 'xlsx'];
const FORMAT_POWERPOINT = ['ppt', 'pptx'];
const FORMAT_IMG = ['jpg', 'jpeg', 'gif', 'bmp', 'png', 'svg'];

const iconUrl = (icon: string) => `/assets/icons/files/${icon}.svg`;

// ----------------------------------------------------------------------

export function fileFormat(originalFileFormat: string) {
  let format = originalFileFormat;

  if (FORMAT_TEXT.includes(originalFileFormat)) {
    format = 'txt';
  }
  if (FORMAT_IMG.includes(originalFileFormat)) {
    format = 'image';
  }

  if (FORMAT_WORD.includes(originalFileFormat)) {
    format = 'word';
  }

  if (FORMAT_EXCEL.includes(originalFileFormat)) {
    format = 'excel';
  }

  if (FORMAT_POWERPOINT.includes(originalFileFormat)) {
    format = 'powerpoint';
  }

  if (FORMAT_PDF.includes(originalFileFormat)) {
    format = 'pdf';
  }

  return format;
}

// ----------------------------------------------------------------------

export function fileThumb(originalFileFormat: string) {
  let thumb;

  switch (fileFormat(originalFileFormat)) {
    case 'txt':
      thumb = iconUrl('ic_txt');
      break;
    case 'word':
      thumb = iconUrl('ic_word');
      break;
    case 'excel':
      thumb = iconUrl('ic_excel');
      break;
    case 'powerpoint':
      thumb = iconUrl('ic_power_point');
      break;
    case 'pdf':
      thumb = iconUrl('ic_pdf');
      break;
    case 'image':
      thumb = iconUrl('ic_img');
      break;
    default:
      thumb = iconUrl('ic_img');
  }
  return thumb;
}
