import React from 'react';
import { Grid, TextField, Typography } from '@mui/material';
import { Autocomplete, AutocompleteRenderInputParams } from 'formik-mui';
import { FastField as Field } from 'formik';
import { useTranslation } from 'react-i18next';
import { styled } from '@mui/system';
import { theme } from '../../../theme';
import { FormFieldsProps } from '../../../components/Form/FormikForm';

const StyledTextInput = styled(TextField)({
  marginBottom: theme.spacing(2),
});

interface CategoriesFieldsProps extends FormFieldsProps {
  id: string;
}

const CategoriesFields = ({ id, touched, errors }: CategoriesFieldsProps) => {
  const { t } = useTranslation();

  const categories = [
    'Hospice',
    'dementia',
    'Complex',
    'symptomsBalancing',
    'Orthopedic',
    'Neurological',
    'Restoration',
    'pastimes',
    'interior',
    'doctorVisit',
    'fireplaceVisit',
    'physiotherapist',
    'clinician',
    'occupational',
    'dietitian',
    'socialWorker',
    'AcuteHospitalization',
  ];

  return (
    <div id={id}>
      <Typography variant="subtitle1" gutterBottom align="left">
        {t('report.categories.header')}
      </Typography>
      <Grid container id={`${id}_Container`}>
        <Grid item xs={12} md={12} lg={12}>
          <Field
            id={id + 'Category_AutoCompleteField'}
            name="data.categories"
            component={Autocomplete}
            options={categories}
            multiple
            freeSolo
            getOptionLabel={(option: string) =>
              categories.indexOf(option) > -1 ? t(`report.categories.${option}`) : option
            }
            renderInput={(params: AutocompleteRenderInputParams) => (
              <StyledTextInput
                {...params}
                name="data.categories"
                error={touched['data.categories'] && !!errors['data.categories']}
                helperText={
                  touched['data.categories'] && errors['data.categories']
                    ? t('clinic.form.required')
                    : ''
                }
                label={t('report.categories.header')}
                variant="outlined"
              />
            )}
          />
        </Grid>
      </Grid>
    </div>
  );
};

export default CategoriesFields;
