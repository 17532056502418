import { getIn, useFormikContext } from 'formik';
import React, { useEffect } from 'react';
import SectionDivider from '../../../../components/SectionDivider';
import ReportCategories from '../../sharedFields/CategoriesFields';
import ChronicDiseasesFields from '../../sharedFields/ChronicDiseasesFields';
import DynamicFields from '../../sharedFields/DynamicFields';
import HabitsFields from './HabitsFields';
import { useTranslation } from 'react-i18next';
import GenericField from '../../sharedFields/GenericField';

interface HMBGeneralFieldsProps {
  id: string;
}
const HMBGeneralFields = ({ id }: HMBGeneralFieldsProps) => {
  const { t } = useTranslation();
  const { values, setFieldValue, touched, errors }: any = useFormikContext();

  const isPatientSmooking = getIn(values, 'data.habits.smoking') == 'yes';
  const patientSensitivities = getIn(values, 'data.sensitivity');
  const chronicMedicationsValues = getIn(values, 'data.chronicDiseases.chronicMedications');

  useEffect(() => {
    if (!isPatientSmooking) {
      setFieldValue('data.habits.smokingBoxes', '');
      setFieldValue('data.habits.smokingYears', '');
    }
  }, [isPatientSmooking]);

  return (
    <>
      <GenericField
        id={`${id}_treatmentReason`}
        fields={[
          {
            name: 'data.treatmentReason',
            label: 'report.treatmentReason',
            type: 'select',
            options: [
              {
                value: 'accident',
                label: 'accident',
              },
              {
                value: 'sickness',
                label: 'sickness',
              },
            ],
            smallSize: 12,
            largeSize: 12,
          },
        ]}
      />
      <ReportCategories id={`${id}_Categories`} touched={touched} errors={errors} />
      <SectionDivider />
      <ChronicDiseasesFields
        id={`${id}_ChronicDiseases`}
        touched={touched}
        errors={errors}
        onChangeFormikValue={setFieldValue}
        chronicMedicationsValues={chronicMedicationsValues}
      />
      <SectionDivider />
      <DynamicFields
        id={`${id}_SensitivitySection`}
        onChangeFormikValue={setFieldValue}
        fieldName="data.sensitivity"
        label={t('report.sensitivityHabitsSection.sensitivity.sensitivitySection')}
        values={patientSensitivities}
      />
      <SectionDivider />
      <HabitsFields id={`${id}_Habits`} isPatientSmoking={isPatientSmooking} />
      <SectionDivider />
      <GenericField
        id={`${id}illnessHistory`}
        fields={[
          {
            name: 'data.illnessHistory',
            label: 'report.history',
            type: 'textareaWithEditor',
          },
        ]}
      />
      <SectionDivider />
    </>
  );
};

export default HMBGeneralFields;
