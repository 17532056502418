import React from 'react';
import { Grid, Typography, MenuItem } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { styled, SxProps } from '@mui/system';
import { theme } from '../../../theme';
import { FastField as Field } from 'formik';
import { TextField } from 'formik-mui';
import FormikSelect from '../../../components/FormikSelect';
import EssentialSignsBMIFields from './EssentialSignsBMIFields';

const sxFormControl: SxProps = {
  width: '100%',
};

const StyledTextField = styled(TextField)({
  marginBottom: theme.spacing(2),
});

interface EssentialSignsFieldsProps {
  id: string;
  onChangeFormikValue: (field: string, value: any, shouldValidate?: boolean) => void;
  bloodPressureValue: string;
  pressureStandingValue: string;
}

const EssentialSignsFields = ({
  id,
  onChangeFormikValue,
  bloodPressureValue,
  pressureStandingValue,
}: EssentialSignsFieldsProps) => {
  const { t } = useTranslation();

  const onChangeBloodPressure = (value: string) => {
    let out = value.replace(/\D/g, '');
    const len = out.length;

    if (len > 1 && len < 6) {
      if (out[0] == '1') {
        out = out.substring(0, 3) + '/' + out.substring(3, 5);
      } else {
        out = out.substring(0, 2) + '/' + out.substring(2, 5);
      }
    } else if (len >= 6) {
      if (out[0] == '1') {
        out = out.substring(0, 3) + '/' + out.substring(3, len);
      } else {
        out = out.substring(0, 2) + '/' + out.substring(2, len);
      }
    }
    return out;
  };

  const sturgeonOptions: string[] = ['roomAir', 'oxygen'];

  return (
    <div id={id}>
      <Typography variant="subtitle1" gutterBottom align="left">
        {t('report.essentialSigns.signs')}
      </Typography>
      <EssentialSignsBMIFields id={id} />
      <Grid container spacing={{ xs: 0, md: 2, lg: 2 }} id={`${id}_Container`}>
        <Grid item xs={12} md={6} lg={6}>
          <Grid item xs={12} md={12} lg={12}>
            <Field
              id={`${id}_BloodPressure`}
              fullWidth
              component={StyledTextField}
              type="text"
              name="data.essentialSigns.bloodPressure"
              label={t('report.essentialSigns.bloodPressure')}
              onChange={async (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
                const { value } = e.target;
                const newValue = onChangeBloodPressure(value);
                onChangeFormikValue(`data.essentialSigns.bloodPressure`, newValue);
              }}
            />
          </Grid>
          <Grid item xs={12} md={12} lg={12}>
            <Field
              id={`${id}_Pulse`}
              fullWidth
              component={StyledTextField}
              type="text"
              name="data.essentialSigns.pulse"
              label={t('report.essentialSigns.pulse')}
            />
          </Grid>
        </Grid>
        <Grid item xs={12} md={6} lg={6}>
          <Grid item xs={12} md={12} lg={12}>
            <Field
              id={`${id}_BloodPressureStanding`}
              fullWidth
              component={StyledTextField}
              type="text"
              name="data.essentialSigns.bloodPressureStanding"
              label={t('report.essentialSigns.bloodPressureStanding')}
              onChange={async (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
                const { value } = e.target;
                const newValue = onChangeBloodPressure(value);
                onChangeFormikValue(`data.essentialSigns.bloodPressureStanding`, newValue);
              }}
            />
          </Grid>
          <Grid item xs={12} md={12} lg={12}>
            <Field
              id={`${id}_PulseStanding`}
              fullWidth
              component={StyledTextField}
              type="text"
              name="data.essentialSigns.pulseStanding"
              label={t('report.essentialSigns.pulseStanding')}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid container spacing={{ xs: 0, md: 2, lg: 2 }}>
        <Grid item xs={12} md={4} lg={4}>
          <Field
            id={`${id}_Temperature`}
            fullWidth
            component={StyledTextField}
            type="text"
            name="data.essentialSigns.temperature"
            label={t('report.essentialSigns.temperature')}
          />
        </Grid>
        <Grid item xs={12} md={4} lg={4}>
          <FormikSelect
            id={`${id}_sturgeon`}
            name="data.essentialSigns.sturgeon"
            formControl={sxFormControl}
            label={t('report.essentialSigns.sturgeon')}
          >
            {sturgeonOptions.map((option) => {
              return (
                <MenuItem key={option} value={option}>
                  {t(`global.form.select.${option}`)}
                </MenuItem>
              );
            })}
          </FormikSelect>
        </Grid>
        <Grid item xs={12} md={4} lg={4}>
          <Field
            id={`${id}_SturgeonNote`}
            fullWidth
            component={StyledTextField}
            type="text"
            name="data.essentialSigns.sturgeonNote"
            label={`${t('report.essentialSigns.sturgeon')} ${t('report.HMKFIRST.remarks')}`}
          />
        </Grid>
      </Grid>
    </div>
  );
};

export default EssentialSignsFields;
