import React from 'react';
import { styled } from '@mui/system';
import { Box, Grid, Typography } from '@mui/material';

const StyledBox = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.grey[300],
  // ...theme.typography.body2,
  padding: theme.spacing(2),
  marginBottom: theme.spacing(1),
  color: theme.palette.text.primary,
  width: '100%',
}));

interface ISummaryMessage {
  messages: string[];
}
const SummaryMessage = ({ messages }: ISummaryMessage) => {
  return (
    <StyledBox sx={{ flexGrow: 1 }}>
      <Grid container spacing={2}>
        {messages.map((message: string) => {
          return (
            <Grid item xs={6} key={message}>
              <Typography>{message}</Typography>
            </Grid>
          );
        })}
      </Grid>
    </StyledBox>
  );
};

export default SummaryMessage;
