import { Button, ListItemIcon, ListItemText, Menu, MenuItem } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import BackupTableIcon from '@mui/icons-material/BackupTable';
import { IAction } from './MoreOptions';
import { IPatientProgram, IPatientProgramFlat } from '../interfaces/Models';

interface IMoreActions<T> {
  record: T;
  actions: IAction<T>[];
}

const MoreActions = <T extends IPatientProgram | IPatientProgramFlat>({
  record,
  actions,
}: IMoreActions<T>) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const { t } = useTranslation();
  const open = Boolean(anchorEl);

  const handleMenuAction = (action: Function, actionHandlerParam1?: string) => () => {
    action(record, actionHandlerParam1);
    setAnchorEl(null);
  };

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <Button variant="contained" endIcon={<BackupTableIcon />} onClick={handleClick}>
        {t('global.profile.globalRelease')}
      </Button>
      <Menu
        id="basic-menu"
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        {actions.map((action) => {
          const { isVisible = true, name, icon, handler, actionHandlerParam1 } = action;
          return (
            <div key={name}>
              {isVisible && (
                <MenuItem onClick={handleMenuAction(handler, actionHandlerParam1)}>
                  <ListItemIcon>{icon}</ListItemIcon>
                  <ListItemText primary={name} primaryTypographyProps={{ variant: 'body2' }} />
                </MenuItem>
              )}
            </div>
          );
        })}
      </Menu>
    </div>
  );
};

export default MoreActions;
