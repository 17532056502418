import { TableRow, TableCell } from '@mui/material';
import { styled } from '@mui/system';
import { theme } from '../theme';

export const StyledTableRow = styled(TableRow)`
  &:hover {
    cursor: pointer;
    background-color: ${theme.palette.action.selected};
  }
`;
export const StyledTableCell = styled(TableCell)({
  fontWeight: 'bold',
});

export const TotalTableRow = styled(TableRow)({
  backgroundColor: theme.palette.primary.lighter,
});

export const LoaderTableCell = styled(TableCell)({
  marginBottom: theme.spacing(4),
});
