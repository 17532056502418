/* eslint-disable new-cap */
import { useTranslation } from 'react-i18next';
import { IAttachment } from '../interfaces/attachment';
import FormModal from './Modals/FormModal';
import { StyledDiv } from '../styles/FormFieldsStyles';
import { Button, FormControl, TextField } from '@mui/material';
import { useState } from 'react';
import { LoadingButton } from '@mui/lab';

interface IEditAttachmentNameModal {
  open: boolean;
  handleCloseModal: () => void;
  record: IAttachment;
  handleSubmitForm: (val: IAttachment) => void;
  isLoading?: boolean;
}
const EditAttachmentNameModal = ({
  open,
  handleCloseModal,
  record,
  handleSubmitForm,
  isLoading = false,
}: IEditAttachmentNameModal) => {
  const { t } = useTranslation();
  const [fileName, setFileName] = useState<string>(record.fileName || '');

  return (
    <FormModal
      open={open}
      onClose={handleCloseModal}
      text={t('global.attachments.editName')}
      loading={isLoading}
    >
      <FormControl fullWidth sx={{ my: 2 }}>
        <TextField
          value={fileName}
          type="text"
          label={t('global.attachments.fileName')}
          name="fileName"
          fullWidth
          error={fileName.length < 1}
          helperText={fileName.length < 1 && `${t('clinic.form.required')}`}
          onChange={(e) => setFileName(e.target.value)}
        />
      </FormControl>
      <StyledDiv>
        <LoadingButton
          variant="contained"
          disabled={record.fileName === fileName || fileName.length < 1}
          size="large"
          type="submit"
          color="primary"
          loading={isLoading}
          onClick={async () => await handleSubmitForm({ ...record, fileName })}
        >
          {t('clinic.form.edit')}
        </LoadingButton>
        <Button variant="contained" size="large" onClick={() => handleCloseModal()} color="error">
          {t('clinic.form.cancel')}
        </Button>
      </StyledDiv>
    </FormModal>
  );
};

export default EditAttachmentNameModal;
