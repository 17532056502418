import { Button, Stack } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import AddIcon from '@mui/icons-material/Add';
import { IPatientProgram } from '../../../interfaces/Models';
import ReferralForm from './ReferralForm';

interface Props {
  handleTableDataUpdate: () => void;
  program: IPatientProgram;
}
export default function ReferralsTableHeader({ handleTableDataUpdate, program }: Props) {
  const { t } = useTranslation();
  const [openForm, setOpenForm] = React.useState<boolean>(false);

  const handleOpenForm = () => setOpenForm(true);
  const handleCloseForm = () => setOpenForm(false);

  return (
    <>
      <ReferralForm
        handleCloseModal={handleCloseForm}
        program={program}
        open={openForm}
        handleTableDataUpdate={handleTableDataUpdate}
      />
      <Stack width="100%" direction={'row'} justifyContent={'flex-end'} alignItems={'center'}>
        <Button variant="contained" onClick={handleOpenForm} endIcon={<AddIcon />}>
          {t('newReferral')}
        </Button>
      </Stack>
    </>
  );
}
