import { Card, CardContent, Grid, Typography } from '@mui/material';
import React, { useState } from 'react';
import useSearchFilter from '../../hooks/useSearchFilter';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { getStartEndDateDep, saveExcelFile } from '../../utils/Helper';
import axios from 'axios';
import { LoadingButton } from '@mui/lab';
import DownloadIcon from '@mui/icons-material/Download';
import { format } from 'date-fns';

export interface SystemReportItemProps {
  title: string;
  downloadUrl: string;
  index?: number;
  dataByDateRange?: boolean;
}

interface ReportCardProps {
  title: string;
  isLoading: boolean;
  onDownload: () => void;
}

const ReportCard = ({ title, onDownload, isLoading }: ReportCardProps) => {
  const { t } = useTranslation();
  return (
    <Card
      sx={{
        minWidth: 300,
        borderRadius: 4,
        boxShadow: 2,
        ':hover': {
          boxShadow: 20,
        },
        borderColor: '#1ee1c8',
        borderWidth: 1,
        borderStyle: 'solid',
      }}
    >
      <CardContent
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          flexDirection: 'column',
        }}
      >
        <Typography gutterBottom variant="body1" component="div">
          {t(title)}
        </Typography>

        <LoadingButton onClick={onDownload} variant="outlined" loading={isLoading}>
          <DownloadIcon />
        </LoadingButton>
      </CardContent>
    </Card>
  );
};

export default function SystemReportItem({
  title,
  downloadUrl,
  dataByDateRange = true,
  index,
}: SystemReportItemProps) {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { pageInfo } = useSearchFilter();
  const { from, to, range } = pageInfo;

  const onDownloadReport = async () => {
    const fromDate = getStartEndDateDep(pageInfo);
    const toDate = getStartEndDateDep(pageInfo, true);
    const toDateValue = format(
      new Date(new Date(new Date(toDate || '').setDate(new Date(toDate || '').getDate() - 1))),
      'MM-dd-yyyy'
    );

    const rangeDetails = dataByDateRange ? `$-${fromDate}-${toDateValue}` : '';
    const fileName = `${t(title)}_${Math.round(Date.now() / 100000)}${rangeDetails}`;
    setIsLoading(true);
    await axios
      .get(`${downloadUrl}?${`fromDate=${fromDate}&toDate=${toDate}`}`, {
        responseType: 'arraybuffer',
      })
      .then((response) => {
        saveExcelFile(response.data, fileName);
      })
      .catch((err: any) => toast.error(err.msg || err.message));
    setIsLoading(false);
  };

  return <ReportCard title={title} onDownload={onDownloadReport} isLoading={isLoading} />;
}

export const ReportCards = ({ reports }: { reports: SystemReportItemProps[] }) => {
  return (
    <Grid container spacing={4} direction="row" alignItems="center" justifyContent="center">
      {reports.map((report, idx) => {
        return (
          <Grid key={report.title} item xs={12} sm={6}>
            <SystemReportItem {...report} />
          </Grid>
        );
      })}
    </Grid>
  );
};
