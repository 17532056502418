import { t } from 'i18next';
import React from 'react';
import FormModal from '../../components/Modals/FormModal';
import { IHospitalDays, IPatientProgram, IPatientProgramFlat } from '../../interfaces/Models';
import ProgramHospitalDaysForm from './ProgramHospitalDaysForm';

interface IProgramHospitalDaysModal {
  handleCloseModal: () => void;
  openAddHospitalDaysModal: boolean;
  program: IPatientProgram | IPatientProgramFlat | undefined;
  handleTableUpdate: () => void;
  period?: IHospitalDays;
}
const ProgramHospitalDaysModal = ({
  handleCloseModal,
  openAddHospitalDaysModal,
  program,
  handleTableUpdate,
  period,
}: IProgramHospitalDaysModal) => {
  return (
    <FormModal
      text={t('program.hospitalDaysForm.formHeader')}
      maxWidth="lg"
      open={openAddHospitalDaysModal}
      onClose={handleCloseModal}
    >
      <ProgramHospitalDaysForm
        period={period}
        handlePageUpdate={handleTableUpdate}
        program={program}
        handleFormClosing={handleCloseModal}
      />
    </FormModal>
  );
};

export default ProgramHospitalDaysModal;
