import { styled } from '@mui/system';
import { APPBAR_DESKTOP } from '../layouts/dashboard/DashboardNavbar';
import { theme } from '../theme';

export const Overlay = styled('div')({
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  width: '100%',
  height: '100%',
  position: 'fixed',
  background: '#ffff',
  [theme.breakpoints.up('lg')]: {
    top: APPBAR_DESKTOP,
  },
});

export const OverlayInner = styled('div')({
  left: 0,
  top: 0,
  width: '100%',
  height: '100%',
  position: 'absolute',
});

export const OverlayContent = styled('div')({
  left: '25%',
  position: 'relative',
  zIndex: 9999,
  top: '35%',
  [theme.breakpoints.up('md')]: {
    left: '55%',
  },
});
