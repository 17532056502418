// icons
import { Icon, IconifyIcon } from '@iconify/react';
// @mui
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

interface IconifyProps {
  icon: string | IconifyIcon;
  sx?: Object;
  width?: number;
  height?: number;
  color?: string;
}

export default function Iconify({ icon, sx, color, ...other }: IconifyProps) {
  return <Box component={Icon} icon={icon} sx={sx} {...other} color={color} />;
}
